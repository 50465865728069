import FullAddressAccessor from './FullAddressAccessor';
const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 50,
    filterable: true,
    sortable: true,
  },
  {
    title: 'pages.url',
    accessor: slug => <FullAddressAccessor slug={slug} />,
    name: 'slug',
    filterable: true,
    sortable: false,
  },
  {
    title: 'pages.title',
    accessor: 'title',
    name: 'title',
    filterable: false,
    sortable: false,
  },
  {
    title: 'pages.metaDesc',
    accessor: 'metaDescription',
    name: 'metaDescription',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

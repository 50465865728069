const DICTIONARIES_TYPES = {
  REGULAR: 'regular',
  CONTAINERS: 'containers',
  WITH_POSITION: 'withPosition',
  BAG_CONTAINERS: 'bagContainers',
  CLIENT_TAGS: 'clientTags',
  WITH_ANTI_PREFERENCES: 'withAntiPreferences',
  WITH_POSITION_AND_ANTI_PREFERENCES: 'withPositionAndAntiPreferences',
};

export default DICTIONARIES_TYPES;

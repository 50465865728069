const extractComponents = (components, ingredientFieldName = 'name') => {
  return components
    .map(component => {
      if (component.ingredient) {
        return component.ingredient[ingredientFieldName] || false;
      }
      if (component.recipe) {
        if (component.recipe.ingredients.length > 0) {
          return (
            component.recipe.workName +
            `(${extractComponents(
              component.recipe.ingredients,
              ingredientFieldName
            )})`
          );
        } else {
          return component.recipe.workName || false;
        }
      }

      return false;
    })
    .filter(Boolean)
    .join(', ');
};

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: true,
    width: 50,
  },
  {
    title: 'form.field.nameForClient',
    name: 'nameForClient',
    accessor: 'nameForClient',
    filterable: true,
  },
  {
    title: 'form.field.workingName',
    name: 'workingName',
    accessor: 'workingName',
    filterable: true,
  },
  {
    title: 'form.field.ingredientNamesForClient',
    name: 'ingredientName',
    accessor: row => extractComponents(row.components),
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.ingredientWorkingNames',
    name: 'ingredientWorkingName',
    accessor: row => extractComponents(row.components, 'workName'),
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.mealTypes',
    name: 'types',
    accessor: row => row.types.map(type => type.name).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.tags',
    name: 'tags',
    accessor: row => row.tags.map(tag => tag.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.rateAVG',
    name: 'rate',
    accessor: row => (row?.rateAVG ? parseFloat(row.rateAVG).toFixed(2) : null),
    filterable: true,
    sortable: true,
  },
];

export default columnConfig;

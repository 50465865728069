import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '../../../assets/img/arrow-down.svg';
import ArrowRight from '../../../assets/img/arrow-right.svg';
import ArrowDiagonal from '../../../assets/img/arrow-diagonal.svg';
import { days as daysOfWeek } from '../../BrandConfiguration/consts';

const dayFields = ['newOrder', 'paymentDeadline'];

const translateDays = t => ({
  monday: t('common.monday', 'Poniedziałek'),
  tuesday: t('common.tuesday', 'Wtorek'),
  wednesday: t('common.wednesday', 'Środa'),
  thursday: t('common.thursday', 'Czwartek'),
  friday: t('common.friday', 'Piątek'),
  saturday: t('common.saturday', 'Sobota'),
  sunday: t('common.sunday', 'Niedziela'),
});

const translateFields = t => ({
  newOrder: t('brands.newBrandForm.translateFields.newOrder'),
  paymentDeadline: t('brands.newBrandForm.translateFields.paymentDeadline'),
});

const TimeSettings = ({ classes, returnTableCell }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <h3>{t('brandCfg.timeSettings')}</h3>
      <Divider style={{ marginBottom: '20px' }} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'250px'}>
              Aby dokonać [akcja] <img src={ArrowDown} alt="arrow" /> na [dzień]{' '}
              <img src={ArrowRight} alt="arrow" /> należy to zrobić przed{' '}
              <img src={ArrowDiagonal} alt="arrow" />
            </TableCell>
            {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
              return (
                <TableCell padding={'none'} key={dayOfWeekIndex}>
                  {translateDays(t)[dayOfWeek]}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dayFields.map((field, fieldIndex) => {
            return (
              <TableRow key={fieldIndex}>
                <TableCell>{translateFields(t)[field]}</TableCell>
                {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
                  return (
                    <TableCell padding={'none'} key={dayOfWeekIndex}>
                      <div
                        style={{
                          padding: '10px 5px',
                          minWidth: '120px',
                        }}
                      >
                        {returnTableCell(classes, dayOfWeek, field)}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default TimeSettings;

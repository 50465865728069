import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import { fetchDiets } from 'actions/Diets';
import { fetchPackage } from 'actions/Packages';

import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { withTranslation } from 'react-i18next';
import FormFiltersView from './FormFilters.view';
import TOAST_DURATIONS from 'helpers/toastDurations';

const FormFiltersContainer = ({
  match,
  location,
  fetchDiets,
  fetchPackage,
  openToast,
  history,
  t,
  diets,
  classes,
}) => {
  const [state, setState] = useState({
    modal: null,
    modalOptions: [],
    name: '',
    diets: [],
    image: null,
    position: 0,
    imageUrl: null,
    active: false,
    description: '',
    disabledDiets: [],
    mainImage: null,
    mainImageUrl: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const packageId = match.params.id;
  const isEdit = location.pathname.includes('edit');

  useEffect(async () => {
    await fetchDiets();

    if (isEdit) {
      fetchPackage(packageId).then(response => {
        const PACKAGE = response;

        setState(prev => ({
          ...prev,
          name: PACKAGE.name,
          image: PACKAGE.image?.['@id'] || null,
          diets: PACKAGE.diets,
          active: PACKAGE.active,
          position: PACKAGE.position,
          description: PACKAGE.description,
          disabledDiets: PACKAGE.disabledDiets || [],
          imageUrl: PACKAGE.image?.contentUrl || null,
          mainImage: PACKAGE.mainImage?.['@id'] || null,
          mainImageUrl: PACKAGE.mainImage?.contentUrl || null,
        }));
        setIsLoading(true);
      });
    }

    !isEdit && setIsLoading(true);
  }, []);

  const handleInputChange = (event, value) => {
    const newValue = event.target.value;
    setState(prev => ({
      ...prev,
      [event.target.name]: value ? value : newValue,
    }));
  };

  const handleSelect = ({ target: { name, value } }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getImage = (stateName, data) => {
    setState(prev => ({
      ...prev,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeImage = stateName => {
    setState(prev => ({
      ...prev,
      [stateName]: null,
      [`${stateName}Url`]: null,
    }));
  };

  const handleToggle = e => {
    setState(prev => ({ ...prev, [e.target.name]: !state[e.target.name] }));
  };

  const validateForm = () => {
    return state.name && state.diets.length > 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return openToast({
        messages: [t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
    if (state.position < 1) {
      return openToast({
        messages: [t('form.orderStarts')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const data = {
      name: state.name,
      position: parseInt(state.position),
      description: state.description,
      active: state.active,
      image: state.image,
      disabledDiets: state.disabledDiets,
      diets: state.diets,
      mainImage: state.mainImage,
    };

    const action = isEdit
      ? put(`/packages/${packageId}`, data)
      : post('/packages', data);

    action.then(() => history.push('/admin/packages'));
  };

  const props = {
    t,
    diets,
    classes,
    state,
    isLoading,
    isEdit,
    packageId,
    handleInputChange,
    handleSelect,
    getImage,
    removeImage,
    handleToggle,
    handleSubmit,
  };
  return <FormFiltersView props={props} />;
};
const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSizes: state.MealSizes.mealSizes,
    mealType: state.MealTypes.mealType,
    diets: state.Diets.diets,
  };
};

const mapDispatchToProps = {
  fetchPackage,
  fetchDiets,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(FormFiltersContainer)))
);

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: { marginBottom: '2px' },
});
const PlannerCardItem = ({ title = '', value = '', parameter = '' }) => {
  const classes = useStyles();
  return (
    <p className={classes.root}>
      {title}: {value} {parameter}
    </p>
  );
};

export default PlannerCardItem;

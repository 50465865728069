import { useRef } from 'react';
import { withToast } from 'material-ui-toast-redux';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import ModalButton from 'components/CustomButtons/Button';

import { orderStyles } from 'views/Orders/styles';

import { Close } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { compose } from 'redux';
import { connect } from 'react-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

const CopyPaymentLinkDialog = ({
  classes,
  history,
  isOpened,
  openToast,
  paymentLink,
  setIsOpened,
  paymentToPay = null,
  currency,
}) => {
  const { t } = useTranslation();

  const refMessage = useRef();

  const handleClose = () => {
    setIsOpened(false);
    history && history.push('/admin/orders');
    return openToast({
      messages: [
        t('bagChangeModal.orderCreated', '$$Zostało stworzone nowe zamówienie'),
      ],
      type: 'success',
      autoHideDuration: TOAST_DURATIONS.LG,
    });
  };

  return (
    <Dialog maxWidth={'sm'} open={isOpened} onClose={handleClose}>
      <DialogTitle className={classes.modalHeader}>
        <span data-cy="new-order_modal-title">
          {t(
            'bagChangeModal.copyLinkToClipboard',
            'Skopiuj link płatności do schowka'
          )}
        </span>
      </DialogTitle>
      <DialogContent
        className={classes.modalBody}
        style={{
          minHeight: '100px',
          alignItems: 'center',
          fontSize: '1.1em',
          fontWeight: 'bold',
        }}
      >
        <ModalButton
          style={orderStyles.closeButton}
          color={'transparent'}
          justIcon
          round
          onClick={handleClose}
        >
          <Close />
        </ModalButton>
        <p ref={refMessage} data-cy="new-order_modal_payment-link">
          {paymentLink}
        </p>
        {paymentToPay && (
          <p style={{ textAlign: 'center' }}>
            <Trans
              i18nKey="bagChangeModal.paymentToPrice"
              defaults="Do zapłaty: {{ price }} {{ currencySymbol}}"
              values={{
                price: paymentToPay,
                currencySymbol: currency,
              }}
            />
          </p>
        )}
      </DialogContent>
      <DialogActions
        className={classes.modalFooter}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          data-cy="new-order_modal_button"
          color="success"
          onClick={() => {
            navigator.clipboard.writeText(refMessage.current.innerText);
            handleClose();
          }}
        >
          {t('$*common.order.copy', 'Kopiuj')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    currency: state.Brands.brand.multinational.currency,
  };
};

const enhance = compose(connect(mapStateToProps), withToast);

export default enhance(CopyPaymentLinkDialog);

import React from 'react';

import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { compose } from 'redux';

import history from '../history';
import AdminLayout from '../layouts/Admin';
import AuthLayout from '../layouts/Auth';
import TOAST_DURATIONS from 'helpers/toastDurations';

const App = ({ token, openToast }) => {
  window.toast = ({ messages = [], type = 'error' }) =>
    openToast({
      messages,
      type,
      autoHideDuration: TOAST_DURATIONS.MD,
    });

  return (
    <Router history={history}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route
          path="/admin"
          render={props => {
            if (token) {
              return <AdminLayout {...props} />;
            } else {
              window.requestedUrl =
                window.location.pathname + history.location.search;
              return <Redirect to="/auth/login" />;
            }
          }}
        />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({ Auth }) => ({ token: Auth.token });

const enhance = compose(connect(mapStateToProps, null), withToast);

export default enhance(App);

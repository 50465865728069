import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  border: 1px solid ${props => (props.unsorted ? 'tomato' : 'lightgrey')};
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? '#A3D0F9' : 'white')};
  display: flex;
  justify-content: space-between;

  ${props =>
    props.unsorted &&
    `&::after {
    position: absolute;
    content: '${props.unsorted}';
    font-size: 10px;
    line-height: 1em;
    right: 0;
    bottom: 0;
    color: white;
    padding: 1px 2px;
    border-top-left-radius: 3px;
    background-color: tomato;
  }`}

  p {
    margin: 0;
  }
`;

export { Container };

import { Trans, useTranslation } from 'react-i18next';

import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import { Fragment } from 'react';
import Card from '../../../components/Card/Card';

import TimeSettings from './TimeSettings';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import SelectInput from '../../../components/FormSelect/SelectInput';
import Datetime from 'react-datetime';
import produce from 'immer';
import moment from 'moment';
import RegularCheckbox from 'components/Checkbox/RegularCheckbox';
import { FormControlLabel } from '@material-ui/core';

const DeliveriesAndOrders = ({ classes, state, setState }) => {
  const { t } = useTranslation();

  const getWeekdayOptions = date => {
    const options = [];

    const dayMap = {
      0: t('common.sunday'),
      1: t('common.monday'),
      2: t('common.tuesday'),
      3: t('common.wednesday'),
      4: t('common.thursday'),
      5: t('common.friday'),
      6: t('common.saturday'),
    };

    for (let i = 7; i > 0; i--) {
      let dateObject = moment(date).subtract(i, 'days');
      let dayName = dayMap[dateObject.day()];
      let obj = { label: dayName, value: dateObject.format('YYYY-MM-DD') };

      options.push(obj);
    }

    return options;
  };

  const handleDaySelect = (selectedDay, dayOfWeek, field) => {
    const newState = produce(state, draftState => {
      let dayWeek = draftState.chosenDateAndTime[dayOfWeek];
      dayWeek[field].day = selectedDay;
    });

    setState(newState);
  };

  const handleTimeSelect = (selectedTime, dayOfWeek, field) => {
    const newState = produce(state, draftState => {
      let dayWeek = draftState.chosenDateAndTime[dayOfWeek];
      dayWeek[field].time = selectedTime.format('HH:mm');
    });

    setState(newState);
  };

  const returnTableCell = (classes, dayOfWeek, field) => {
    return (
      <Fragment>
        <SelectInput
          noGrid
          classes={classes}
          mapBy="label"
          trackBy="value"
          name={dayOfWeek}
          value={state.chosenDateAndTime[dayOfWeek][field].day}
          options={getWeekdayOptions(state.tableHeaderDates[dayOfWeek])}
          handleChange={(ev, obj) => handleDaySelect(obj, dayOfWeek, field)}
          size={12}
        />

        <Datetime
          dateFormat={false}
          value={state.chosenDateAndTime[dayOfWeek][field].time}
          onChange={e => handleTimeSelect(e, dayOfWeek, field)}
          inputProps={{
            placeholder: t('zones.select'),
            readOnly: true,
          }}
        />
      </Fragment>
    );
  };

  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <div style={{ color: '#f44336' }}>
              <Trans
                i18nKey="views.diets.isEnabledCustomTimeSlots.info"
                defaults="$$UWAGA!! Globalne sloty czasowe, ustawia się w <0>konfiguracji marki</0>"
                components={[<a href="/admin/deliveries-and-orders" key="0" />]}
              />
            </div>
            <FormControlLabel
              control={
                <RegularCheckbox
                  name="extendDiet"
                  onClick={e => {
                    setState({
                      isEnabledCustomTimeSlots: !state.isEnabledCustomTimeSlots,
                    });
                  }}
                  checked={state.isEnabledCustomTimeSlots}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={t(
                'views.diets.isEnabledCustomTimeSlots',
                '$$Używaj indywidualnych slotów czasowych dla diety'
              )}
            />
          </GridItem>
          {state.isEnabledCustomTimeSlots && (
            <GridItem xs={12}>
              <TimeSettings
                classes={classes}
                returnTableCell={returnTableCell}
              />
            </GridItem>
          )}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles));

export default enhance(DeliveriesAndOrders);

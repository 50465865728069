import React from 'react';
import DataGrid from './DataGrid';
import roles from 'helpers/roles';
import { compose } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';

const PdfGenerator = () => {
  const { t } = useTranslation();

  return (
    <DataGrid
      title={t('reports.pdfReport')}
      excelUrl="reports/bag-stickers"
      pdfUrl="reports/bag-stickers-pdf"
      mimeType="application/vnd.ms-excel"
      role={roles.ROLE_SHOW_REPORT_BAG_STICKERS}
      pdfAvailable
    />
  );
};

const enhance = compose(withTranslation());

export default enhance(PdfGenerator);

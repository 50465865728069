import React, { useState } from 'react';
import { orderStyles } from 'views/Orders/styles';

import Button from '@material-ui/core/Button';
import Accordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import Addresses from 'views/Clients/FormTabs/Addresses/List';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import ModalButton from 'components/CustomButtons/Button';
import { get } from 'helpers/apiHelpers';
import { useTranslation } from 'react-i18next';

const DietDetailsAddress = ({
  setClientAddresses,
  clientAddresses,
  client,
}) => {
  const [addressesModalStatus, setAddressesModalStatus] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => {
    get(`${client['@id']}/addresses`).then(addresses => {
      setClientAddresses(addresses['hydra:member']);
      setAddressesModalStatus(false);
    });
  };

  let defaultAddress = clientAddresses.find(address => address.isDefault);

  return (
    <div
      style={{
        marginLeft: '5px',
        ...orderStyles.box,
      }}
    >
      {addressesModalStatus ? (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={addressesModalStatus}
          onClose={() => closeModal()}
        >
          <DialogContent>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => closeModal()}
            >
              <Close />
            </ModalButton>
            <Addresses noCancel userId={client.id} isChangeUrlLoader={false} />
          </DialogContent>
        </Dialog>
      ) : null}
      <Accordion
        active={0}
        collapses={[
          {
            title: t('clients.addresses', 'Adresy'),
            content: (
              <div style={{ width: '100%' }}>
                {defaultAddress ? (
                  <div>
                    <div style={orderStyles.boxParagraph}>
                      <b>{t('dietDetails.address.default', 'Domyślny')}</b>
                    </div>
                    <div style={orderStyles.boxParagraph}>
                      {defaultAddress.street} {defaultAddress.buildNumber}
                      {defaultAddress.placeNumber
                        ? `/${defaultAddress.placeNumber}`
                        : null}
                    </div>
                    <div style={orderStyles.boxParagraph}>
                      {defaultAddress.postCode} {defaultAddress.city}
                    </div>
                    <div style={orderStyles.boxParagraph}>
                      {defaultAddress?.zone?.name}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={orderStyles.boxParagraph}>
                      {t(
                        'dietDetails.address.noDefault',
                        'Brak adresu domyślnego'
                      )}
                    </div>
                  </div>
                )}
                <Divider style={{ marginBottom: '5px', marginTop: '5px' }} />
                <Button
                  style={{ width: '100%' }}
                  onClick={() => setAddressesModalStatus(true)}
                >
                  {t('dietDetails.address.seeAll', 'ZOBACZ WSZYSTKIE')}
                  {/* dietDetails.address.seeAll */}
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DietDetailsAddress;

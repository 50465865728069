import React from 'react';
import { withTranslation } from 'react-i18next';
import { TableCell } from '@material-ui/core';
import { Tooltip } from '@material-ui/core/index';
import { Help } from '@material-ui/icons';
class GlycemicIndexSumCell extends React.Component {
  getIngredientWomValue = (ingredient, value) => {
    const { [value]: ingredientValue, workingOnMachining, weight } = ingredient;

    return (
      (ingredientValue / 100) * (workingOnMachining / 100) * parseFloat(weight)
    );
  };

  render() {
    const { dishSizes, size } = this.props;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    const {
      dishCarbohydrates,
      dishGlycemicIndex,
    } = dishSizeBySize.ingredients.reduce(
      (acc, { ingredient = null, recipe = null, quantity }) => {
        const isRecipe = recipe !== null;
        const dishIngredient = isRecipe ? recipe : ingredient;

        const {
          macrosAfterProcessing: { carbohydrates, glycemicIndex },
          weight,
        } = dishIngredient;

        const ingredientCarbohydrates = isRecipe
          ? carbohydrates * quantity
          : (carbohydrates * weight * quantity) / 100;

        const ingredientGlycemicIndex =
          quantity > 0 ? glycemicIndex * ingredientCarbohydrates : 0;

        return {
          ...acc,
          dishCarbohydrates: acc.dishCarbohydrates + ingredientCarbohydrates,
          dishGlycemicIndex: acc.dishGlycemicIndex + ingredientGlycemicIndex,
        };
      },
      {
        dishCarbohydrates: 0,
        dishGlycemicIndex: 0,
      }
    );

    const glycemicIndex =
      dishGlycemicIndex && dishCarbohydrates
        ? parseFloat(dishGlycemicIndex / dishCarbohydrates).toFixed(2)
        : 0;

    const someIngredientNoGlycmicIndex = dishSizeBySize.ingredients.some(
      ({ ingredient = null, recipe = null }) => {
        const dishIngredient = recipe !== null ? recipe : ingredient;

        return dishIngredient?.macrosAfterProcessing?.glycemicIndex === null;
      }
    );

    return (
      <TableCell>
        {someIngredientNoGlycmicIndex ? (
          <Tooltip
            title={<h4>{this.props.t('glycemicIndex.mealIngredientNoIG')}</h4>}
          >
            <Help
              style={{
                fontSize: '16px',
                color: 'grey',
              }}
            />
          </Tooltip>
        ) : (
          glycemicIndex
        )}
      </TableCell>
    );
  }
}

export default withTranslation()(GlycemicIndexSumCell);

import CATERINGS_FLOW_STATUSES from './cateringsFlowStatuses';

const STATUS_LABELS = {
  CREATED: {
    translationKey: '$*cateringsFlow.status.created',
    defaultValue: 'Utworzono',
  },
  [CATERINGS_FLOW_STATUSES.WAITING]: {
    translationKey: '$*cateringsFlow.status.waiting',
    defaultValue: 'Oczekujące',
  },
  [CATERINGS_FLOW_STATUSES.IN_PROGRESS]: {
    translationKey: '$*cateringsFlow.status.inProgress',
    defaultValue: 'W toku',
  },
  [CATERINGS_FLOW_STATUSES.DONE_WAITING]: {
    translationKey: '$*cateringsFlow.status.toWait',
    defaultValue: 'Do odczekania',
  },
  [CATERINGS_FLOW_STATUSES.DONE]: {
    translationKey: '$*cateringsFlow.status.ready',
    defaultValue: 'Gotowe',
  },
  [CATERINGS_FLOW_STATUSES.EARN_EXTRA]: {
    translationKey: '$*cateringsFlow.status.earn',
    defaultValue: 'Do dorobienia',
  },
};

export default STATUS_LABELS;

import PropTypes from 'prop-types';

import Button from 'components/CustomButtons/Button';

const DownloadReportButton = ({ children, extension, onClick, isDisabled }) => {
  return (
    <Button
      className={`btn--generate-${extension}`}
      onClick={onClick}
      disabled={isDisabled}
      color="success"
      round
    >
      {children}
    </Button>
  );
};

DownloadReportButton.propTypes = {
  children: PropTypes.node.isRequired,
  extension: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default DownloadReportButton;

import StatusPicker from 'views/CateringsFlow/common/StatusPicker/StatusPicker';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
import { amountOfDoneSelectedColor } from 'views/CateringsFlow/utils/AmountOfDoneSelectedColor';
const columnConfig = ({ t }) => [
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.dishName',
      'Nazwa dania'
    ),
    name: 'name',
    accessor: row =>
      row?.dish?.nameForClient
        ? `(${row?.dish?.id}) ${row?.dish?.nameForClient}`
        : row?.name,
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countToDo',
      '$$Ilość do zrobienia'
    ),
    name: 'amountToMake',
    accessor: 'amountToMake',
    sortable: true,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countToDoAfterUpdate',
      '$$Ilość do zrobienia 2'
    ),
    name: 'amountToMakeAfterRefreshedAmounts',
    accessor: 'amountToMakeAfterRefreshedAmounts',
    sortable: true,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countDone',
      '$$Ilość zrobiona'
    ),
    name: 'amountOfDone',
    accessor: row => (
      <div style={{ color: amountOfDoneSelectedColor(row) }}>
        {row?.amountOfDone ?? 0}
      </div>
    ),
    sortable: true,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.subTask.note',
      '$$Notatka'
    ),
    name: 'comment',
    accessor: 'comment',
  },
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.subTask.status',
      '$$Status'
    ),
    name: 'status',
    width: 150,
    accessor: row => {
      return (
        <div style={{ maxWidth: '150px' }}>
          <StatusPicker initialStatus={row?.status} isPickingEnabled={false} />
        </div>
      );
    },
  },
];

export default columnConfig;

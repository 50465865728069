import moment from 'moment';

import _ from 'lodash';

import { createTheme, useMediaQuery } from '@mui/material';

import { calendarStyles } from 'views/Orders/styles';

import {
  getCurrentMonthAndYear,
  getCurrentMonthDates,
  getMonth,
  getStartOfMonth,
  isDateInArray,
} from 'helpers/dateHelpers';
import { goldColor } from 'assets/jss/material-dashboard-pro-react';

const Calendar = ({
  diet,
  selectDietDay,
  disabledDays,
  saturdayInclude,
  sundayInclude,
  month,
  secondMonth,
}) => {
  const theme = createTheme();
  const isSmallerXl = useMediaQuery(theme.breakpoints.down('1500'));

  const currMonth = getMonth(month);
  const secMonth = getMonth(secondMonth);
  const startOfMonth = getStartOfMonth(month);
  const startOfSeccondMonth = getStartOfMonth(secondMonth);

  const currentMonthDatesToDisplay = [
    ..._.range(1, startOfMonth).map(el => ''),
    ...getCurrentMonthDates(month),
  ];

  const secondMonthDatesToDisplay = [
    ..._.range(1, startOfSeccondMonth).map(el => ''),
    ...getCurrentMonthDates(secondMonth),
  ];

  const { selectedDays } = diet || {};
  return (
    <div
      style={
        isSmallerXl
          ? { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }
          : { display: 'flex', justifyContent: 'center' }
      }
    >
      <div>
        <div
          style={calendarStyles.month}
          data-cy="new-order_diet-length_current-month"
        >
          {getCurrentMonthAndYear(currMonth)}
        </div>
        <div
          style={
            isSmallerXl
              ? { ...calendarStyles.calendar, marginInline: '25px' }
              : { ...calendarStyles.calendar }
          }
        >
          {moment.weekdaysMin(true).map((el, index) => (
            <div
              data-cy={`new-order_diet-length_current-month_day-name-${index}`}
              key={index}
              style={calendarStyles.weekName}
            >
              {el}
            </div>
          ))}
          {currentMonthDatesToDisplay.map((el, index) => {
            const disabledDaysMap = disabledDays.map(d =>
              new Date(d).getTime()
            );
            const today = new Date(moment()).setHours(0, 0, 0, 0);
            return (
              <div
                data-cy={`new-order_diet-length_current-month_day-number-${index}`}
                style={
                  el && isDateInArray(el, selectedDays)
                    ? {
                        ...calendarStyles.selectedDay,
                        border: `2px solid ${goldColor}`,
                      }
                    : el &&
                      ((el.day() === 6 && !saturdayInclude) ||
                        (el.day() === 0 && !sundayInclude) ||
                        disabledDaysMap.some(
                          date => date === new Date(el).setHours(0, 0, 0, 0)
                        ))
                    ? calendarStyles.disabledDay
                    : calendarStyles.day
                }
                onClick={() => el && selectDietDay(el)}
                key={index}
                className="no-scrollbar"
              >
                <div
                  style={
                    today === new Date(el).setHours(0, 0, 0, 0)
                      ? calendarStyles.todayMark
                      : {}
                  }
                />
                <p
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: '2px',
                  }}
                >
                  {el && el.format('DD')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div style={isSmallerXl ? { marginTop: '25px' } : {}}>
        <div
          style={calendarStyles.month}
          data-cy="new-order_diet-length_second-month"
        >
          {getCurrentMonthAndYear(secMonth)}
        </div>
        <div
          style={
            isSmallerXl
              ? { ...calendarStyles.calendar, marginInline: '25px' }
              : { ...calendarStyles.calendar, marginLeft: '25px' }
          }
        >
          {moment.weekdaysMin(true).map((el, index) => (
            <div
              data-cy="new-order_diet-length_second-month_day-name"
              key={index}
              style={calendarStyles.weekName}
            >
              {el}
            </div>
          ))}
          {secondMonthDatesToDisplay.map((el, index) => {
            const disabledDaysMap = disabledDays.map(d =>
              new Date(d).getTime()
            );
            const today = new Date(moment()).setHours(0, 0, 0, 0);
            return (
              <div
                data-cy={`new-order_diet-length_second-month_day-number-${index}`}
                style={
                  el && isDateInArray(el, selectedDays)
                    ? {
                        ...calendarStyles.selectedDay,
                        border: `2px solid ${goldColor}`,
                      }
                    : el &&
                      ((el.day() === 6 && !saturdayInclude) ||
                        (el.day() === 0 && !sundayInclude) ||
                        disabledDaysMap.some(
                          date => date === new Date(el).setHours(0, 0, 0, 0)
                        ))
                    ? calendarStyles.disabledDay
                    : calendarStyles.day
                }
                onClick={() => el && selectDietDay(el)}
                key={index}
                className="no-scrollbar"
              >
                <div
                  style={
                    today === new Date(el).setHours(0, 0, 0, 0)
                      ? calendarStyles.todayMark
                      : {}
                  }
                />
                <p
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: '2px',
                  }}
                >
                  {el && el.format('DD')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';

const TaskStatus = ({ status }) => {
  const { t } = useTranslation();

  const statusSettings = {
    CREATED: {
      translation: t('$*cateringsFlow.status.created', 'Utworzono'),
      color: '#000000',
      backgroundColor: '#ffffff',
    },
    [CATERINGS_FLOW_STATUSES.WAITING]: {
      translation: t('$*cateringsFlow.status.waiting', 'Oczekujące'),
      color: '#000000',
      backgroundColor: '#ffffff',
    },
    [CATERINGS_FLOW_STATUSES.IN_PROGRESS]: {
      translation: t('$*cateringsFlow.status.inProgress', 'W toku'),
      color: '#ffffff',
      backgroundColor: '#828282',
    },
    [CATERINGS_FLOW_STATUSES.DONE_WAITING]: {
      translation: t('$*cateringsFlow.status.toWait', 'Do odczekania'),
      color: '#ffffff',
      backgroundColor: '#00ACC2',
    },
    [CATERINGS_FLOW_STATUSES.DONE]: {
      translation: t('$*cateringsFlow.status.ready', 'Gotowe'),
      color: '#ffffff',
      backgroundColor: '#3FA64D',
    },
  };

  const statusButtonColor =
    statusSettings?.[status]?.color || statusSettings['CREATED'].color;
  const statusButtonBackgroundColor =
    statusSettings?.[status]?.backgroundColor ||
    statusSettings['CREATED'].backgroundColor;
  const statusTranslation =
    statusSettings?.[status]?.translation ||
    statusSettings['CREATED'].translation;

  if (!status) {
    return null;
  }

  return (
    <Button
      style={{
        color: statusButtonColor,
        backgroundColor: statusButtonBackgroundColor,
      }}
      fullWidth
    >
      {statusTranslation}
    </Button>
  );
};

export default TaskStatus;

import React from 'react';
import { useTranslation } from 'react-i18next';

import BasicInfoCard from 'components/CMS/BasicInfoCard';
import DescriptionCard from 'components/CMS/DescriptionCard';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

const DishSubpage = ({
  classes,
  urlSlug,
  isLoading,
  metaTitle,
  friendlyName,
  handleSubmit,
  dishDescription,
  metaDescription,
  handleCmsInputChange = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: '5px' }}>
        <BasicInfoCard
          urlSlug={urlSlug}
          metaTitle={metaTitle}
          friendlyName={friendlyName}
          handleChange={handleCmsInputChange}
          metaDescription={metaDescription}
          subpage="/dishes/"
        />
        <DescriptionCard
          value={dishDescription}
          setValue={value =>
            handleCmsInputChange({
              target: { name: 'dishDescription', value },
            })
          }
          title={t('views.dishes.dishSubpage.dishDescription')}
        />
        {!isLoading && (
          <FormControlButtons
            classes={classes}
            discardText={t('form.cancel')}
            submitText={t('form.saveAndClose')}
            cancelPath="/admin/meals"
            secondarySubmitText={t('form.save')}
            handleSubmit={e => handleSubmit(e, true)}
            handleSecondarySubmit={e => handleSubmit(e, false)}
          />
        )}
      </div>
    </>
  );
};

export default DishSubpage;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from 'components/CustomButtons/Button';
import DataGrid from 'components/DataGrid/DataGrid';

import columnConfig from './columnConfig';

const TasksListDetailsModal = ({
  params = [],
  comments = '',
  isOpened = false,
  closeModal = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={isOpened}
        onClose={closeModal}
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>{t('dataGrid.visibleColumns')}</DialogTitle>
        <DialogContent>
          <DataGrid
            data={params}
            minRows={1}
            actions={false}
            columns={columnConfig(t)}
            paginationTop={false}
            noRefresh={true}
            disableTotal={true}
            paginationBottom={false}
            showPageSizeOptions={false}
            customDefaultPageSize={params?.length ?? 1}
          />
        </DialogContent>
        {comments && (
          <div style={{ padding: '0 25px' }}>
            <h4>{`${t('common.notes', 'Notatki')}:`}</h4>
            <p>{comments}</p>
          </div>
        )}
        <DialogActions>
          <Button onClick={closeModal}>{t('form.close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TasksListDetailsModal;

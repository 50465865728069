import React from 'react';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { combineStyles } from 'helpers/helpers';
import { get } from 'helpers/apiHelpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { ShoppingBasket } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import BasketRows from './BasketRows';
import PriceBlock from './Common/PriceBlock';

class BasketDetails extends React.Component {
  state = {
    basketDetailsDialog: false,
    loading: false,
    basket: null,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  closeModal = () =>
    this.setState(prevState => ({
      ...prevState,
      basketDetailsDialog: false,
    }));

  openModal = async () => {
    this.setState(prevState => ({
      ...prevState,
      basketDetailsDialog: true,
    }));

    this.setLoading(true);
    try {
      const basket = await get('/baskets/' + this.props.basketId);
      this.setState(prevState => ({ ...prevState, basket: basket }));
    } catch (e) {
      this.setLoading(false);
    }
  };

  dialogBody = () => {
    const { t } = this.props;
    const {
      basket: { id, client, price, rows, placedOrder },
    } = this.state;
    return (
      <DialogContent>
        <GridContainer>
          <GridItem sm={12}>
            <h4>
              {t('basket.list')} ID: {id}
            </h4>
          </GridItem>
          <GridItem sm={12}>
            <GridContainer>
              <GridItem md={6}>
                <h4>
                  <b>{t('common.client')}</b>
                </h4>
                <div
                  style={{
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: '#dedede',
                    padding: '8px',
                  }}
                >
                  <p>
                    <b>{t('orders.fullname')}</b>: {client?.firstName}{' '}
                    {client?.lastName}
                  </p>
                  <p>
                    <b>{t('clients.emailAddress')}</b>: {client?.email}
                  </p>
                  <p>
                    <b>{t('clients.phone')}</b>:{' '}
                    <a href={`tel:${client?.phone?.number}`}>
                      {client?.phone?.number}
                    </a>
                  </p>
                  <p>
                    <b>{t('basket.details.defaultAddress')}</b>:
                    {client?.defaultAddress?.postCode}{' '}
                    {client?.defaultAddress?.city},{' '}
                    {client?.defaultAddress?.street}{' '}
                    {client?.defaultAddress?.buildNumber}
                    {client?.defaultAddress?.placeNumber &&
                      `\\${client?.defaultAddress?.placeNumber}`}
                  </p>
                </div>
              </GridItem>
              <GridItem md={6}>
                <GridContainer>
                  <GridItem md={6}>
                    <h4>
                      <b>{t('basket.details.cost')}</b>
                    </h4>
                    <div
                      style={{
                        borderRadius: '5px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: '#dedede',
                        padding: '8px',
                      }}
                    >
                      <PriceBlock price={price} />
                    </div>
                  </GridItem>
                  <GridItem md={6}>
                    <h4>
                      <b>{t('orders.order')}</b>
                    </h4>
                    <div
                      style={{
                        borderRadius: '5px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: '#dedede',
                        padding: '8px',
                      }}
                    >
                      {!placedOrder && (
                        <div style={{ textAlign: 'center' }}>
                          <b>{t('basket.details.noOrder')}</b>
                        </div>
                      )}
                      {placedOrder && (
                        <div>
                          <p>ID: {placedOrder.id}</p>
                          <p>
                            {t(
                              'ecommerceBags.fields.bag.clientDiet.order.status'
                            )}
                            : {placedOrder.status.value}
                          </p>
                        </div>
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem sm={12}>
            <h4>
              <b>{t('basket.rows')}</b>
            </h4>
          </GridItem>
          {rows
            .filter(el => el.price.beforeDiscount > 0)
            .map((row, index) => (
              <>
                <GridItem sm={4} key={index}>
                  <div
                    style={{
                      borderRadius: '5px',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: '#dedede',
                    }}
                  >
                    {this.renderBasketRow(row)}
                  </div>
                </GridItem>
              </>
            ))}
        </GridContainer>
      </DialogContent>
    );
  };

  renderBasketRow = row => {
    if (!BasketRows.hasOwnProperty(row['@type'])) {
      return 'Undefined BasketRow: ' + row['@type'];
    }

    const Component = BasketRows[row['@type']];

    return <Component row={row} />;
  };

  render() {
    return (
      <>
        <Dialog
          open={this.state.basketDetailsDialog}
          onClose={this.closeModal}
          maxWidth={'lg'}
          fullWidth={true}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          {this.state.basket && this.dialogBody()}
        </Dialog>
        <Tooltip
          onClick={this.openModal}
          title={this.props.t('basket.show.tooltip')}
          placement={'top'}
        >
          <ShoppingBasket />
        </Tooltip>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(BasketDetails))
);

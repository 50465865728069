const CATERINGS_FLOW_STATUSES = {
  CREATED: 'CREATED',
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE_WAITING: 'DONE_WAITING',
  DONE: 'DONE',
  EARN_EXTRA: 'EARN_EXTRA',
};

export default CATERINGS_FLOW_STATUSES;

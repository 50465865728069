import React from 'react';
import { ROLE_EDIT_COMPANY } from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import ReportConfigurator from '../../components/Report/ReportConfigurator';
import { connect } from 'react-redux';
import withRole from 'components/Security/withRole';

class ReportsGlobalConfiguration extends React.Component {
  render() {
    const { company } = this.props;
    const companyId = parseInt(company.split('/').pop());

    const SecuredReportConfigurator = withRole(
      ROLE_EDIT_COMPANY,
      ReportConfigurator
    );

    return (
      <SecuredReportConfigurator
        forceOpen={true}
        companyId={companyId}
        title={'common.mainMenu.reportsGlobalConfig'}
        report={'ReportsGlobal'}
        fields={[
          {
            size: 12,
            field: 'ingredientAlertNote',
            inputType: 'text',
          },
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  company: state.Auth.user.company,
});

export default connect(
  mapStateToProps,
  null
)(withTranslation()(ReportsGlobalConfiguration));

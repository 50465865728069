import React from 'react';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { Replay } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import LogView from 'components/History/LogView';

class SubscriptionHistory extends React.Component {
  state = {
    subscriptionOrdersModal: false,
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  closeModal = () =>
    this.setState(prevState => ({
      ...prevState,
      subscriptionOrdersModal: false,
    }));

  openModal = () =>
    this.setState(prevState => ({
      ...prevState,
      subscriptionOrdersModal: true,
    }));

  render() {
    return (
      <>
        <Dialog
          fullWidth={true}
          open={this.state.subscriptionOrdersModal}
          onClose={this.closeModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <LogView
              iri={this.props.row['@id']}
              historyOpenDefault={true}
              hideButtonHidden={true}
            />
          </DialogContent>
        </Dialog>
        <Tooltip
          onClick={this.openModal}
          title={this.props.t('subscription.dialog.history.tooltip.title')}
          placement={'top'}
        >
          <Replay />
        </Tooltip>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(SubscriptionHistory))
);

import React from 'react';
import Moment from 'moment';

import Success from 'components/Typography/Success';
import Danger from 'components/Typography/Danger';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import DateFilter from 'components/Grid/Filter/Date';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    width: 80,
  },
  {
    title: 'clients.active',
    accessor: row =>
      row.active ? (
        <Success>
          <b>{t('clients.active')}</b>
        </Success>
      ) : (
        <Danger>{t('clients.inactive')}</Danger>
      ),
    name: 'active',
    width: 100,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: true,
    filterable: true,
  },
  {
    title: 'clients.firstDeliveryDate',
    accessor: row => {
      return row.firstDeliveryDate
        ? new Moment(row.firstDeliveryDate).format('YYYY-MM-DD')
        : '-';
    },
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    name: 'firstDeliveryDate',
    sortable: true,
    filterable: true,
  },
  {
    title: 'clients.lastDeliveryDate',
    accessor: row => {
      return row.lastDeliveryDate
        ? new Moment(row.lastDeliveryDate).format('YYYY-MM-DD')
        : '-';
    },
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    name: 'lastDeliveryDate',
    sortable: true,
    filterable: true,
  },
  {
    title: 'clients.orderDays',
    accessor: 'dietLength',
    name: 'dietLength',
    width: 75,
    filterable: true,
    sortable: true,
  },
  {
    title: 'clients.idorder',
    accessor: 'order.id',
    name: 'order.id',
  },
  {
    title: 'clients.calories',
    accessor: 'calorific.name',
    name: 'calorific.name',
  },
  {
    title: 'clients.diet',
    accessor: 'diet.name',
    name: 'diet.name',
  },
  {
    title: 'clients.variant',
    accessor: 'variant.name',
    name: 'variant.name',
  },
  {
    title: 'clients.addr',
    accessor: row => {
      return row.address
        ? `${row.address.street} ${row.address.buildNumber}, ${row.address.postCode} ${row.address.city}`
        : row.pickUpPoint
        ? row.pickUpPoint.value
        : '-';
    },
    name: 'address',
    sortable: true,
    filterable: true,
  },
];

export default columnConfig;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Info } from '@material-ui/icons';
import { Checkbox, Tooltip } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';

const AdditionalModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'AdditionalModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridItem xs={12}>
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          {t('brandCfg.payments.additional')}
          <span style={{ marginLeft: '10px' }}>
            <Tooltip
              title={<h4>{t(`brandCfg.payments.additional.tooltip`)}</h4>}
              placement="right"
            >
              <Info
                fontSize="small"
                style={{ color: 'grey', display: 'block' }}
              />
            </Tooltip>
          </span>
        </h3>
      </GridItem>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({
                ...module,
                cateromarket: !module.cateromarket,
              })
            }
            checked={module.cateromarket}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('paymentType.cateromarket')}</span>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({ ...module, dietly: !module.dietly })
            }
            checked={module.dietly}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('paymentType.dietly')}</span>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({ ...module, dine2door: !module.dine2door })
            }
            checked={module.dine2door}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('paymentType.dine2door')}</span>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({ ...module, groupon: !module.groupon })
            }
            checked={module.groupon}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('paymentType.groupon')}</span>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({ ...module, transfer: !module.transfer })
            }
            checked={module.transfer}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('paymentType.transferBok')}</span>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({ ...module, other: !module.other })
            }
            checked={module.other}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('paymentType.other')}</span>
        </div>
      </GridItem>
    </>
  );
};

export default AdditionalModule;

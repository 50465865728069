import React from 'react';
import subscriptionColumnConfig from './subscriptionColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';
import { withTranslation } from 'react-i18next';

class Subscription extends React.Component {
  render() {
    return (
      <DataGrid
        actions={true}
        paginationTop={true}
        paginationBottom={false}
        url="/ecommerce-diets"
        reportName={'subscriptions'}
        role={'ORDER'}
        columns={subscriptionColumnConfig(this.props.t)}
        minRows={18}
        manipulateQuery={(requestData, query) => {
          query['client.id[]'] = this.props.userId;
          query['isSubscription'] = true;
          return query;
        }}
      />
    );
  }
}

export default withTranslation()(Subscription);

import React from 'react';

const DishesPackhouse = Component => {
  const WrappedComponent = props => {
    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default DishesPackhouse;

import GridItem from '../../../components/Grid/GridItem';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';

const PayPoModule = ({ classes, module, handleModuleUpdate }) => {
  const { t } = useTranslation();
  return (
    <>
      <GridContainer>
        <GridItem xs={11}>
          <h3 style={{ marginLeft: '15px' }}>
            {t('paymentType.title.PayPo', 'Ustawienia PayPo')}
          </h3>
        </GridItem>
        <GridItem xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
          <div
            style={{
              height: '100%',
              width: '55%',
              background: 'RGB(204,0,0)',
            }}
          ></div>
        </GridItem>
      </GridContainer>
      <GridItem xs={4}>
        <FormTextInput
          label={t('brandCfg.securityUrl')}
          classes={classes}
          name="payPoUrl"
          value={module.url}
          handleChange={e => {
            handleModuleUpdate({
              ...module,
              url: e.target.value,
            });
          }}
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={4}>
        <FormTextInput
          label="PayPo ID"
          classes={classes}
          name="payPoId"
          value={module.merchantId}
          handleChange={e => {
            handleModuleUpdate({
              ...module,
              merchantId: e.target.value,
            });
          }}
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={4}>
        <FormTextInput
          label={t('brandCfg.securityCode')}
          classes={classes}
          name="payPoToken"
          value={module.token}
          handleChange={e => {
            handleModuleUpdate({
              ...module,
              token: e.target.value,
            });
          }}
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayPoModule;

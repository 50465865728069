import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import BasketDetails from './Dialogs/BasketDetails';
import ListHeader from './ListHeader';

class List extends Component {
  customActions = row => {
    return [
      {
        action: () => {},
        icon: <BasketDetails basketId={row?.id} />,
        color: 'primary',
        simple: true,
        round: false,
      },
    ];
  };

  render() {
    return (
      <AdminTable title={<ListHeader />}>
        <DataGrid
          actions={true}
          customActions={this.customActions}
          remove={false}
          export={false}
          paginationTop={true}
          hideArchive={true}
          paginationBottom={false}
          defaultHiddenColumns={['id']}
          url="baskets"
          reportName={'basket'}
          columns={columnConfig(this.props.t)}
          defaultSorted={[{ id: 'createdAt', desc: true }]}
          role="BASKET"
          manipulateQuery={(requestData, query) => {
            if (this.props.defaultQuery) {
              query = { ...query, ...this.props.defaultQuery };
            }
            if (query['client']) {
              const splicedName = query['client'].split(' ');
              let _andX = [
                [
                  {
                    'client.firstName': splicedName[0],
                    'client.lastName': splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0]['client.lastName'] = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query['client.firstName'];
              query[operator] = _andX;
            }

            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;

              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }
            if (query['price']) {
              const filters = query['price'];
              delete query['price'];
              if (filters.valueFrom) {
                query['price.priceAfterDiscount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['price.priceAfterDiscount[lte]'] = filters.valueTo;
              }
            }
            if (
              query['placedOrder.status.systemValue'] &&
              query['placedOrder.status.systemValue'] === 'EMPTY'
            ) {
              delete query['placedOrder.status.systemValue'];
              query['exists[placedOrder]'] = 0;
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

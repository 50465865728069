import React from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import InputsIndex from './InputsIndex';
import produce from 'immer';
import { Divider } from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';

const ShareRecipesSettings = ({
  classes,
  shopModule,
  setShopModule,
  configClientPanelModule,
  setConfigClientPanelModule,
}) => {
  const { t } = useTranslation();

  const getValueHandlerByStateKey = (getter, setter, stateKey) => {
    const fieldProps = {
      value: getter?.[stateKey],
      handler: () => {
        setter(
          produce(draft => {
            draft[stateKey] = !draft[stateKey];
          })
        );
      },
    };

    return fieldProps;
  };

  const fields = [
    [
      {
        label: t(
          '$*shop.shareRecipesSettings.printRecipe',
          '$$Drukuj instrukcję przygotowania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'allowPrintRecipe'
        ),
      },
      {
        label: t(
          '$*shop.shareRecipesSettings.downloadRecipe',
          '$$Pobierz instrukcję przygotowania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'allowDownloadRecipe'
        ),
      },
      {
        label: t(
          '$*shop.shareRecipesSettings.showRecipePage',
          '$$Szczegóły dania'
        ),
        type: 'checkbox',
        tooltip: t(
          '$*shop.shareRecipesSettings.showRecipePage.tooltip',
          '$$Funkcja dostępna tylko po włączeniu “Podstrony Dania” w CMS'
        ),
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'allowShowRecipe'
        ),
      },
      {
        label: t(
          '$*shop.shareRecipesSettings.showRecipe',
          '$$Instrukcja przygotowania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'allowPageRecipe'
        ),
      },
    ],
    [
      {
        label: t(
          '$*shop.shareRecipesSettings.printRecipe',
          '$$Drukuj instrukcję przygotowania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          configClientPanelModule,
          setConfigClientPanelModule,
          'allowPrintRecipe'
        ),
      },
      {
        label: t(
          '$*shop.shareRecipesSettings.downloadRecipe',
          '$$Pobierz instrukcję przygotowania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          configClientPanelModule,
          setConfigClientPanelModule,
          'allowDownloadRecipe'
        ),
      },
      {
        label: t(
          '$*shop.shareRecipesSettings.showRecipePage',
          '$$Szczegóły dania'
        ),
        type: 'checkbox',
        tooltip: t(
          '$*shop.shareRecipesSettings.showRecipePage.tooltip',
          '$$Funkcja dostępna tylko po włączeniu “Podstrony Dania” w CMS'
        ),
        ...getValueHandlerByStateKey(
          configClientPanelModule,
          setConfigClientPanelModule,
          'allowShowRecipe'
        ),
      },
      {
        label: t(
          '$*shop.shareRecipesSettings.showRecipe',
          '$$Instrukcja przygotowania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          configClientPanelModule,
          setConfigClientPanelModule,
          'allowPageRecipe'
        ),
      },
    ],
  ];

  const [fieldsLeft, fieldsRight] = fields;

  return (
    <CardWrapper
      title={t(
        'brands.newBrandForm.shareRecipesWithClients',
        'Udostępnianie przepisów klientom'
      )}
    >
      <Divider style={{ marginBottom: '20px' }} />
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <h4>
              {t(
                '$*shop.shareRecipesSettings.showOptionsInOrderForm',
                '$$Pokazuj opcje w formularzu zamówienia'
              )}
            </h4>
            {fieldsLeft.map(field => (
              <GridContainer>
                <GridItem xs={12}>
                  <InputsIndex {...field} />
                </GridItem>
              </GridContainer>
            ))}
          </GridItem>
          <GridItem xs={6}>
            <h4>
              {t(
                '$*shop.shareRecipesSettings.showOptionsInClientPanel',
                '$$Pokazuj opcje w panelu klienta'
              )}
            </h4>
            {fieldsRight.map(field => (
              <GridContainer>
                <GridItem xs={12}>
                  <InputsIndex {...field} />
                </GridItem>
              </GridContainer>
            ))}
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default ShareRecipesSettings;

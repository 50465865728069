import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { get } from 'helpers/apiHelpers';

import AdminTable from 'layouts/AdminTable';

import GridItem from 'components/Grid/GridItem';
import Accordion from 'components/Accordion/Accordion';
import GridContainer from 'components/Grid/GridContainer';
import { SelectInput } from 'components';
import { DialogLoader } from 'components/DialogLoader';

import { Label } from './styled';

const TargetBrandMappingCard = ({
  classes,
  targets = [],
  targetBrand = {},
  sourceBrand = {},
  sourceDiets = [],
  sourceVariants = [],
  sourceMealTypes = [],
  setTargets = () => {},
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [targetDietOptions, setTargetDietOptions] = useState([]);
  const [targetVariantOptions, setTargetVariantOptions] = useState([]);
  const [targetMealTypeOptions, setTargetMealTypeOptions] = useState([]);

  const dontAssingOption = {
    name: t('$*form.menuPlanning.dontAssign', '$$Nie kopiuj'),
    '@id': -1,
  };

  useEffect(() => {
    Promise.all([
      get(`/meal-types?selectInBrands[]=${targetBrand?.['@id']}`, {
        pagination: false,
      }),
      get(`/diets?selectInBrands[]=${targetBrand?.['@id']}`, {
        pagination: false,
      }),
      get(`/variants?selectInBrands[]=${targetBrand?.['@id']}`, {
        pagination: false,
      }),
    ]).then(res => {
      const { 'hydra:member': targetMealTypes } = res[0];
      const { 'hydra:member': targetDiets } = res[1];
      const { 'hydra:member': targetVariants } = res[2];

      setTargetDietOptions([dontAssingOption, ...targetDiets]);
      setTargetMealTypeOptions([dontAssingOption, ...targetMealTypes]);
      setTargetVariantOptions([dontAssingOption, ...targetVariants]);

      initializeTargetsForCurrentBrand({
        targetDiets,
        targetMealTypes,
        targetVariants,
      });
      setIsLoading(false);
    });
  }, []);

  const initializeTargetsForCurrentBrand = ({
    targetDiets,
    targetVariants,
    targetMealTypes,
  }) => {
    let initialTargets = {
      ...targets,
      [targetBrand?.['@id']]: {
        isOpened: true,
        dietMappings: {},
        mealTypeMapping: {},
      },
    };

    // Iterating over source meal types
    sourceMealTypes.forEach(sourceMealType => {
      const matchingTargetMealType = findSoureElementInTargetElements(
        sourceMealType,
        targetMealTypes
      );

      if (matchingTargetMealType) {
        initialTargets = {
          ...initialTargets,
          [targetBrand?.['@id']]: {
            isOpened: initialTargets[targetBrand?.['@id']]?.isOpened,
            dietMappings: initialTargets[targetBrand?.['@id']].dietMappings,
            mealTypeMapping: {
              ...initialTargets[targetBrand?.['@id']].mealTypeMapping,
              [sourceMealType?.['@id']]: matchingTargetMealType,
            },
          },
        };
      }
    });

    // Iterating over diets and its variants
    sourceDiets.forEach(sourceDiet => {
      const matchingTargetDiet = findSoureElementInTargetElements(
        sourceDiet,
        targetDiets
      );

      if (matchingTargetDiet) {
        const currentDietSourceVariants = sourceVariants.filter(
          ({ '@id': iri }) => (sourceDiet?.variants ?? []).includes(iri)
        );

        currentDietSourceVariants.forEach(sourceVariant => {
          // We should look for available variants only in previously found targetDiet.variants
          const availableTargetVariants = targetVariants.filter(
            ({ '@id': iri }) =>
              (matchingTargetDiet?.variants ?? []).includes(iri)
          );
          const matchingTargetVariant = findSoureElementInTargetElements(
            sourceVariant,
            availableTargetVariants
          );

          if (matchingTargetVariant) {
            initialTargets = {
              ...initialTargets,
              [targetBrand?.['@id']]: {
                isOpened: initialTargets[targetBrand?.['@id']]?.isOpened,
                dietMappings: {
                  ...initialTargets[targetBrand?.['@id']].dietMappings,
                  [sourceDiet?.['@id']]: {
                    targetDiet: matchingTargetDiet,
                    variantMappings: {
                      ...initialTargets[targetBrand?.['@id']].dietMappings?.[
                        sourceDiet?.['@id']
                      ]?.variantMappings,
                      [sourceVariant?.['@id']]: matchingTargetVariant,
                    },
                  },
                },
                mealTypeMapping:
                  initialTargets[targetBrand?.['@id']].mealTypeMapping,
              },
            };
          }
        });
      }
    });

    for (const [key] of Object.entries(initialTargets)) {
      initialTargets[key].isOpened =
        key === targetBrand?.['@id'] ? true : false;
    }

    setTargets(initialTargets);
  };

  const findSoureElementInTargetElements = (sourceElement, targetElements) => {
    const elementByCode = targetElements.find(
      targetElement =>
        (targetElement.shortName ?? targetElement.code) ===
        (sourceElement.shortName ?? sourceElement.code)
    );
    const elementByName = targetElements.find(
      targetElement => targetElement.name === sourceElement.name
    );

    return elementByCode ?? elementByName ?? null;
  };

  const setTargetDiet = (source, target) => {
    let newTargets = {};

    if (target?.['@id'] === -1) {
      newTargets = { ...targets };

      delete newTargets[targetBrand?.['@id']]?.dietMappings?.[source];

      return setTargets(newTargets);
    }

    newTargets = {
      ...targets,
      [targetBrand?.['@id']]: {
        isOpened: targets?.[targetBrand?.['@id']]?.isOpened,
        dietMappings: {
          ...targets?.[targetBrand?.['@id']]?.dietMappings,
          [source]: {
            targetDiet: target,
            variantMappings: {
              ...targets?.[targetBrand?.['@id']]?.dietMappings?.[source]
                ?.variantMappings,
            },
          },
        },
        mealTypeMapping: {
          ...targets?.[targetBrand?.['@id']]?.mealTypeMapping,
        },
      },
    };

    setTargets(newTargets);
  };

  const setTargetVariant = (sourceDiet, sourceVariant, target) => {
    let newTargets = {};

    if (target?.['@id'] === -1) {
      newTargets = { ...targets };

      delete newTargets[targetBrand?.['@id']]?.dietMappings?.[sourceDiet]
        ?.variantMappings?.[sourceVariant];

      return setTargets(newTargets);
    }

    newTargets = {
      ...targets,
      [targetBrand?.['@id']]: {
        isOpened: targets?.[targetBrand?.['@id']]?.isOpened,
        dietMappings: {
          ...targets?.[targetBrand?.['@id']]?.dietMappings,
          [sourceDiet]: {
            targetDiet:
              targets?.[targetBrand?.['@id']]?.dietMappings?.[sourceDiet]
                ?.targetDiet,
            variantMappings: {
              ...targets?.[targetBrand?.['@id']]?.dietMappings?.[sourceDiet]
                ?.variantMappings,
              [sourceVariant]: target,
            },
          },
        },
        mealTypeMapping: {
          ...targets?.[targetBrand?.['@id']]?.mealTypeMapping,
        },
      },
    };

    setTargets(newTargets);
  };

  const setTargetMealType = (source, target) => {
    let newTargets = {};

    if (target?.['@id'] === -1) {
      newTargets = { ...targets };

      delete newTargets[targetBrand?.['@id']]?.mealTypeMapping?.[source];

      return setTargets(newTargets);
    }

    newTargets = {
      ...targets,
      [targetBrand?.['@id']]: {
        isOpened: targets?.[targetBrand?.['@id']]?.isOpened,
        dietMappings: { ...targets?.[targetBrand?.['@id']]?.dietMappings },
        mealTypeMapping: {
          ...targets?.[targetBrand?.['@id']]?.mealTypeMapping,
          [source]: target,
        },
      },
    };

    setTargets(newTargets);
  };

  const currentTargetBrandSettings = targets?.[targetBrand?.['@id']];

  return (
    <>
      {isLoading ? (
        <DialogLoader
          loaderState={isLoading}
          text={t('common.shared.isLoading')}
        />
      ) : (
        <AdminTable style={{ paddingBottom: '25px' }}>
          <Accordion
            active={currentTargetBrandSettings?.isOpened ? 0 : -1}
            style={{ margin: '15px' }}
            collapses={[
              {
                title: t('$*form.menuPlanning.copyFromTo', {
                  defaultValue: `$$Kopiowanie z ${sourceBrand?.name} do ${targetBrand?.name}`,
                  replace: {
                    sourceBrand: sourceBrand?.name,
                    targetBrand: targetBrand?.name,
                  },
                }),
                content: (
                  <GridItem>
                    <GridContainer>
                      <GridItem sm={12}>
                        <h4>
                          {t(
                            '$*form.menuPlanning.mealTypesMapping',
                            '$$Mapowanie typów posiłków'
                          )}
                          :
                        </h4>
                      </GridItem>
                      {sourceMealTypes.map(mealType => {
                        const currentMealTypeMatch =
                          currentTargetBrandSettings?.mealTypeMapping?.[
                            mealType?.['@id']
                          ];
                        return (
                          <GridItem md={6} lg={4}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px',
                              }}
                            >
                              <div style={{ width: '150px' }}>
                                <Label
                                  isMatched={!isEmpty(currentMealTypeMatch)}
                                >
                                  {mealType?.name}
                                </Label>
                              </div>
                              <div style={{ padding: '0 10px' }}>{'==>'}</div>
                              <div
                                style={{ width: '150px', marginLeft: '15px' }}
                              >
                                <SelectInput
                                  name={'clientFontLibrary'}
                                  style={{ padding: 0 }}
                                  value={
                                    currentMealTypeMatch ?? dontAssingOption
                                  }
                                  mapBy="name"
                                  noGrid
                                  trackBy="@id"
                                  classes={classes}
                                  options={targetMealTypeOptions}
                                  handleChange={(ev, option) => {
                                    setTargetMealType(
                                      mealType?.['@id'],
                                      option
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </GridItem>
                        );
                      })}
                    </GridContainer>
                    <GridContainer>
                      <GridItem sm={12}>
                        <h4>
                          {t(
                            '$*form.menuPlanning.dietsAndVariantsMapping',
                            '$$Mapowanie diet i wariantów'
                          )}
                          :
                        </h4>
                      </GridItem>

                      {sourceDiets.map(diet => {
                        const currentDietMatch =
                          currentTargetBrandSettings?.dietMappings?.[
                            diet?.['@id']
                          ]?.targetDiet;

                        return (
                          <GridItem md={6} lg={4}>
                            <div style={{ marginBottom: '5px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ width: '150px' }}>
                                  <Label isMatched={!isEmpty(currentDietMatch)}>
                                    {diet?.name}
                                  </Label>
                                </div>
                                <div style={{ padding: '0 20px' }}>{'==>'}</div>
                                <div
                                  style={{
                                    width: '150px',
                                    marginLeft: '15px',
                                  }}
                                >
                                  <SelectInput
                                    style={{ padding: 0 }}
                                    noGrid
                                    classes={classes}
                                    mapBy="name"
                                    trackBy="@id"
                                    name={'clientFontLibrary'}
                                    value={currentDietMatch ?? dontAssingOption}
                                    options={targetDietOptions}
                                    handleChange={(ev, option) =>
                                      setTargetDiet(diet?.['@id'], option)
                                    }
                                  />
                                </div>
                              </div>
                              {!isEmpty(currentDietMatch) && (
                                <>
                                  {(diet?.variants ?? []).map(variantIri => {
                                    const variant = (sourceVariants ?? []).find(
                                      ({ '@id': iri }) => iri === variantIri
                                    );
                                    const currentVariantMatch =
                                      targets?.[targetBrand?.['@id']]
                                        ?.dietMappings?.[diet?.['@id']]
                                        ?.variantMappings?.[variant?.['@id']];

                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingTop: '5px',
                                            paddingLeft: '15px',
                                          }}
                                        >
                                          <div style={{ width: '150px' }}>
                                            <Label
                                              isMatched={
                                                !isEmpty(currentVariantMatch)
                                              }
                                            >
                                              {variant?.name}
                                            </Label>
                                          </div>
                                          <div style={{ padding: '0 20px' }}>
                                            {'==>'}
                                          </div>
                                          <div
                                            style={{
                                              width: '150px',
                                              marginLeft: '15px',
                                            }}
                                          >
                                            <SelectInput
                                              style={{ padding: 0 }}
                                              noGrid
                                              classes={classes}
                                              mapBy="name"
                                              trackBy="@id"
                                              name={'clientFontLibrary'}
                                              value={
                                                currentVariantMatch ??
                                                dontAssingOption
                                              }
                                              options={targetVariantOptions}
                                              handleChange={(ev, option) =>
                                                setTargetVariant(
                                                  diet?.['@id'],
                                                  variant?.['@id'],
                                                  option
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </GridItem>
                        );
                      })}
                    </GridContainer>
                  </GridItem>
                ),
              },
            ]}
          />
          <div style={{ height: '15px' }}></div>
        </AdminTable>
      )}
    </>
  );
};

export default TargetBrandMappingCard;

import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchPermissions = () => {
  return dispatch => {
    return get('/permission-groups', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_PERMISSIONS,
        payload: {
          permissions: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

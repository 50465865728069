import React from 'react';
import Moment from 'moment';

import { Star, StarBorder } from '@material-ui/icons';

const getBag = row => {
  if (row.type === 'MEAL') {
    return row.dishItem.bag || {};
  }

  return row.bag || {};
};

const columnConfig = ({ t }) => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 75,
    filterable: false,
    sortable: false,
    headerStyles: { display: 'inline-block', margin: '0 0 20px 0' },
  },
  {
    title: 'dashboard.whatIsFor',
    accessor: row => {
      switch (row?.type) {
        case 'DELIVERY':
          return t('dashboard.deliveryReview');
        case 'MEAL':
          return row?.dishItem?.dish?.nameForClient ?? '-';

        default:
          return '';
      }
    },
    name: 'dishName',
    width: 180,
    filterable: false,
    sortable: false,
  },
  {
    title: 'dashboard.commentsDate',
    accessor: row => Moment(row.updatedAt).format('YYYY-MM-DD HH:mm'),
    name: 'createdAt',
    width: 180,
    filterable: false,
    sortable: false,
  },
  {
    title: 'dashboard.user',
    accessor: row => {
      const bag = getBag(row);

      return `${bag?.clientDiet?.client?.firstName} ${bag?.clientDiet?.client?.lastName}`;
    },
    name: 'user',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: 'dashboard.rate',
    accessor: row => {
      if (row.type === 'DAY') {
        return '';
      }
      let stars = [];
      let fullStars = row.rate;
      let borderedStars = 5 - row.rate;

      while (fullStars-- > 0) {
        stars.push(<Star style={{ color: '#DECF00' }} />);
      }

      while (borderedStars-- > 0) {
        stars.push(<StarBorder style={{ color: '#DECF00' }} />);
      }

      return (
        <div>
          {stars.map((el, key) => (
            <span key={key}>{el}</span>
          ))}
        </div>
      );
    },
    width: 130,
    name: 'rate',
    filterable: false,
    sortable: false,
  },
  {
    title: 'dashboard.content',
    accessor: 'comment',
    name: 'comment',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';

import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import MIME_TYPES from './constants/mimeTypes';

class ActiveDietsTable extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportActiveDietsTable');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="/reports/active-diets-table"
        mimeType={MIME_TYPES.xlsx}
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_ACTIVE_DIET}
        pdfAvailable={false}
        excelAvailable={true}
        dateType="range"
        archiveReportNames={['ACTIVE_DIETS_TABLE']}
        useZoneCategories={true}
        isSalesMarketLangViewEnabled={true}
      />
    );
  }
}

export default withTranslation()(ActiveDietsTable);

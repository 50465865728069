const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: 'myCompany.mainDomain',
    accessor: 'mainDomain',
    name: 'mainDomain',
    filterable: false,
    sortable: false,
  },
  {
    title: 'myCompany.short',
    accessor: 'shortName',
    name: 'shortName',
    filterable: false,
    sortable: false,
  },
  {
    title: 'myCompany.name',
    accessor: 'name',
    name: 'name',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

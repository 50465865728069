import { Fragment, useState } from 'react';
import { TextField } from '@mui/material';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Button from 'components/CustomButtons/Button.jsx';

const SimpleWysywig = ({ value, handleChange, numberRows }) => {
  const [isRawHTML, setIsRawHTML] = useState(false);

  return (
    <Fragment>
      <Button
        onClick={() => setIsRawHTML(!isRawHTML)}
        style={{ marginBottom: '16px' }}
        round
        color="primary"
      >
        {isRawHTML ? 'Wysywig' : 'html'}
      </Button>

      {isRawHTML ? (
        <div>
          <TextField
            id="html"
            label="Raw HTML"
            multiline
            rows={numberRows ? numberRows : 4}
            fullWidth
            value={value}
            onChange={handleChange}
          />
        </div>
      ) : (
        <DefaultEditor value={value} onChange={handleChange} />
      )}
    </Fragment>
  );
};

export default SimpleWysywig;

import styled from 'styled-components';

const SelectContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  margin-top: 10px;

  > div {
    flex: 1;
  }
`;

const DriversTablesContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 4px 4px 4px;
  overflow-x: auto;

  > div {
    flex: 0 0 250px;
  }
`;

const ClocksContainer = styled.table`
  color: #3c4858;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;

  td {
    padding: 0.25rem 0.25rem 0.25rem 0;
    font-weight: 500;
  }
`;

const AnimatedColon = styled.span`
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }

  animation: blink 1s steps(2, start) infinite;
`;

const TimeWarning = styled.span`
  color: #f44336;
`;

const routeManagerStyles = {
  drivers: {
    display: 'flex',
    flexDirection: 'column',
  },
  driversLabel: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
};

export {
  TimeWarning,
  AnimatedColon,
  SelectContainer,
  ClocksContainer,
  routeManagerStyles,
  DriversTablesContainer,
};

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Info } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from 'components/Grid/GridItem';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';

import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import ColorPicker from 'components/BlockColorPicker/BlockColorPicker';
import Button from 'components/CustomButtons/Button.jsx';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';

const AddressFinderBanner = ({
  showBannerTitle,
  classes,
  getImage,
  removeImage,
  infoBannerTooltip,
  infoBannerCheckbox,
  infoBannerMobileUrl,
  handleCheckboxChange,
  infoBannerDesktopUrl,
  isColorMobile = false,
  isColorDesktop = false,
  setZones,
  handleChangeMobileBackgroundColor = () => {},
  handleChangeDesktopBackgroundColor = () => {},
  backgroundMobile = '',
  backgroundDesktop = '',
  content = '',
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <GridItem lg={6}>
        <LabeledCheckbox
          onClick={handleCheckboxChange}
          checked={infoBannerCheckbox}
          label={
            <div style={{ display: 'flex' }}>
              {showBannerTitle ?? t('cms.showInfoBanner')}{' '}
              {infoBannerTooltip && (
                <div style={{ marginLeft: '10px' }}>
                  <Tooltip
                    title={
                      <div>
                        <h4>{infoBannerTooltip}</h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              )}
            </div>
          }
        />
        <p
          style={{
            margin: '20px 0 10px',
            color: 'rgba(0,0,0,.46)',
            fontWeight: 400,
          }}
        >
          {t('pages.pageContent')} *
        </p>
        <SimpleWysiwyg
          handleChange={e => {
            setZones(prev => ({
              ...prev,
              addressFinder: {
                ...prev.addressFinder,
                content: e.target.value,
              },
            }));
          }}
          value={content}
        />
      </GridItem>

      <GridItem lg={6}>
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              textAlign: 'center',
            }}
          >
            <GridItem sm={6}>
              {isColorDesktop ? (
                <GridItem style={{ marginBottom: '24px' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px' }}
                  >
                    {t(
                      'brandCfg.backgroundColor.desktop',
                      'Kolor tła dla urządzeń desktopowych (panoramicznych)'
                    )}
                  </FormLabel>
                  <GridItem
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <ColorPicker
                      color={backgroundDesktop ? backgroundDesktop : '#ffffff'}
                      handleChange={color =>
                        handleChangeDesktopBackgroundColor(color)
                      }
                    />
                  </GridItem>
                </GridItem>
              ) : (
                <GridItem>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t(
                      'brandCfg.bannerPanorama',
                      'Banner dla urządzeń desktopowych (panoramiczny)'
                    )}
                  </FormLabel>
                  <FormImageUpload
                    classes={classes}
                    stateName="infoBannerDesktop"
                    buttonText={t('common.shared.select', 'Wybierz')}
                    imgId="desktop-banner"
                    tooltipImageSize={t('brandCfg.widthMinPanorama')}
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={infoBannerDesktopUrl ?? null}
                  />
                </GridItem>
              )}
              <Button
                color="info"
                round={false}
                onClick={() => {
                  setZones(prev => ({
                    ...prev,
                    addressFinder: {
                      ...prev.addressFinder,
                      isColorDesktop: !prev.addressFinder.isColorDesktop,
                    },
                  }));
                }}
              >
                {isColorDesktop
                  ? t(
                      'brandCfg.clientPanelDesign.changeToImage',
                      'Zmień na grafikę'
                    )
                  : t(
                      'brandCfg.clientPanelDesign.changeToColor',
                      'Zmień na kolor'
                    )}
              </Button>
            </GridItem>
            <GridItem sm={6}>
              {isColorMobile ? (
                <GridItem style={{ marginBottom: '24px' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px' }}
                  >
                    {t(
                      'brandCfg.backgroundColor.mobile',
                      'Kolor tła dla urządzeń mobilnych (portretowy)'
                    )}
                  </FormLabel>
                  <GridItem
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <ColorPicker
                      color={backgroundMobile ? backgroundMobile : '#ffffff'}
                      handleChange={color =>
                        handleChangeMobileBackgroundColor(color)
                      }
                    />
                  </GridItem>
                </GridItem>
              ) : (
                <div>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t(
                      'brandCfg.bannerPortrait',
                      'Banner dla urządzeń mobilnych (portretowy)'
                    )}
                  </FormLabel>
                  <FormImageUpload
                    classes={classes}
                    stateName="infoBannerMobile"
                    buttonText={t('common.shared.select', 'Wybierz')}
                    imgId="mobile-banner"
                    tooltipImageSize={t('brandCfg.widthPortrait')}
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={infoBannerMobileUrl}
                  />
                </div>
              )}
              <Button
                color="info"
                round={false}
                onClick={() => {
                  setZones(prev => ({
                    ...prev,
                    addressFinder: {
                      ...prev.addressFinder,
                      isColorMobile: !prev.addressFinder.isColorMobile,
                    },
                  }));
                }}
              >
                {isColorMobile
                  ? t(
                      'brandCfg.clientPanelDesign.changeToImage',
                      'Zmień na grafikę'
                    )
                  : t(
                      'brandCfg.clientPanelDesign.changeToColor',
                      'Zmień na kolor'
                    )}
              </Button>
            </GridItem>
          </div>
        </>
      </GridItem>
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(AddressFinderBanner);

import React from 'react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';

const HtmlContent = ({ t, classes, state, setState }) => {
  const toggleEnabled = () => {
    setState(prev => ({ ...prev, enabled: !prev.enabled }));
  };

  const handleContentChange = event => {
    const content = event.target?.value;

    setState(prev => ({ ...prev, content }));
  };

  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleEnabled}
                  checked={state.enabled}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={
                <div>
                  {t('cms.homePage.htmlContent.enable', 'Użyj samego HTML')}
                </div>
              }
            />
          </GridItem>

          <GridItem md={12}>
            <SimpleWysiwyg
              handleChange={handleContentChange}
              value={state.content}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(HtmlContent);

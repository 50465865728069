import YesNoFilter from 'components/Grid/Filter/YesNo';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    width: 50,
  },
  {
    title: 'form.field.name',
    name: 'name',
    accessor: 'name',
    filterable: true,
  },
  {
    title: 'form.field.workingName',
    name: 'workName',
    accessor: 'workName',
    filterable: true,
  },
  {
    title: 'form.field.dietCode',
    name: 'code',
    accessor: 'code',
    filterable: true,
    width: 100,
  },
  {
    title: 'form.field.order',
    name: 'position',
    accessor: 'position',
    filterable: true,
    width: 100,
  },
  {
    title: 'form.field.description',
    name: 'description',
    accessor: 'description',
    filterable: false,
    sortable: false,
  },
  {
    title: 'diets.possibleToOrder',
    name: 'active',
    accessor: row => <BooleanView value={row.active} />,
    Filter: ({ onChange, filter }) => {
      return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
    },
    filterable: true,
    sortable: true,
    width: 200,
  },
];

export default columnConfig;

import { FormTextInput } from 'components';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import AdminTable from 'layouts/AdminTable';
import LabelWithTooltip from 'views/PagesCreator/LabelWithTooltip';
import LettersCounter from 'views/PagesCreator/LettersCounter';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import { FormLabel } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { get, post, put } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PresentationUrl from 'components/CMS/PresentationUrl';
import TOAST_DURATIONS from 'helpers/toastDurations';
const initialState = {
  createdBy: '',
  slug: '',
  title: '',
  metaDescription: '',
  content: '',
  image: '',
  imageUrl: '',
};
const Form = ({
  match,
  classes,
  history,
  openToast,
  isEdit = false,
  employeesIri,
}) => {
  const postId = match?.params?.id;
  const { t } = useTranslation();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      createdBy: employeesIri,
    }));

    postId &&
      get(`/blog-posts/${postId}`).then(res => {
        setState(prev => ({
          ...prev,
          slug: res?.slug,
          title: res?.title,
          metaDescription: res?.metaDescription,
          content: res?.content,
          image: res?.image?.['@id'],
          imageUrl: res?.image?.contentUrl,
        }));
      });
  }, []);

  const handleChange = e => {
    if (e.target.name === 'urlSlug') {
      e.target.name = 'slug';
    }

    setState(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const getImage = (name, data) => {
    setState(prev => ({
      ...prev,
      image: data?.['@id'],
      imageUrl: data?.contentUrl,
    }));
  };

  const removeImage = () => {
    setState(prev => ({ ...prev, image: null, imageUrl: null }));
  };

  const handleSubmit = () => {
    if (
      !state.slug.length ||
      state.content.length === 0 ||
      !state.title.length
    ) {
      return openToast({
        messages: [t('blog.validation.info')],
        type: 'info',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const date = {
      createdBy: state.createdBy,
      slug: state.slug,
      title: state.title,
      metaDescription: state.metaDescription,
      content: state.content,
      ...(state.image ? { image: state.image } : []),
      ...(state.imageUrl ? { imageUrl: state.imageUrl } : []),
    };

    const action = isEdit
      ? put(`/blog-posts/${postId}`, date)
      : post('/blog-posts', date);

    action.then(
      res => {
        return history.push('/admin/blog');
      },
      error => {
        return openToast({
          messages: [t('blog.cannotSave')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };
  return (
    <AdminTable title={t('pages.addNew')}>
      <GridContainer>
        <GridItem sm={6}>
          <PresentationUrl
            subpage="/blog"
            editInput={true}
            value={state?.slug}
            handleChange={handleChange}
            size={'12'}
          />
          <GridItem sm={12}>
            <FormTextInput
              label={
                <LabelWithTooltip
                  label={t('pages.title') + '*'}
                  tooltip={t('pages.title.tooltip')}
                />
              }
              classes={classes}
              name="title"
              value={state.title}
              handleChange={handleChange}
              inputSize={12}
              maxLength={60}
            />
          </GridItem>
          <GridItem sm={12}>
            <FormTextInput
              label={
                <LettersCounter
                  label={t('pages.metaDesc')}
                  wordsCount={state.metaDescription.length}
                />
              }
              classes={classes}
              name="metaDescription"
              value={state.metaDescription}
              handleChange={handleChange}
              inputSize={12}
              maxLength={300}
              rows={2}
              multiline
            />
          </GridItem>
        </GridItem>
        <GridItem sm={6}>
          <FormLabel
            className={classes.labelHorizontal}
            style={{ marginTop: '15px', marginBottom: '24px' }}
          >
            {t('brandCfg.bannerPanorama')}
          </FormLabel>
          <FormImageUpload
            classes={classes}
            stateName="banner"
            imgId="banner"
            tooltipImageSize={t('brandCfg.widthMinPanorama')}
            getImage={getImage}
            removeImage={removeImage}
            previewUrl={state?.imageUrl}
          />
        </GridItem>
      </GridContainer>

      <p
        style={{
          margin: '20px 0 10px',
          color: 'rgba(0,0,0,.46)',
          fontWeight: 400,
        }}
      >
        {t('pages.pageContent')} *
      </p>
      <SimpleWysiwyg
        name="content"
        handleChange={e => {
          setState(prev => ({
            ...prev,
            content: e.target.value,
          }));
        }}
        value={state.content}
      />

      <FormControlButtons
        classes={classes}
        discardText={t('common.shared.cancel')}
        submitText={t('common.shared.save')}
        cancelPath="/admin/blog"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const mapStateToProps = state => {
  return {
    employeesIri: state.Auth.user.id,
  };
};

const enhance = compose(
  withToast,
  withStyles(combineStyles(extendedFormsStyle)),
  connect(mapStateToProps)
);

export default enhance(Form);

import React, { Component } from 'react';
import MySelect from './SelectAll.jsx';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

const Option = props => {
  const styles = {
    color: props.isFocused ? 'white' : '#333',
  };
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label style={styles}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = t => ({
  label: t('common.shared.checkAll', 'Zaznacz wszystko'),
  value: '*',
});

const ValueContainer = ({ children, ...props }) => {
  const { t } = useTranslation();
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption(t).value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  const { t } = useTranslation();

  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption(t).value) {
    labelToBeDisplayed =
      props.selectProps.selectAllLabel || t('common.all', 'Wszystkie');
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export default class SelectAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
    };
  }

  logChange = selected => {
    console.log(selected);
  };

  render() {
    const options = this.props.options.hasOwnProperty('length')
      ? this.props.options.map(option => {
          return {
            label: this.props.customLabel
              ? this.props.customLabel(option)
              : option[this.props.mapBy],
            value: option[this.props.trackBy],
          };
        })
      : [];
    return (
      <MySelect
        className={this.props.className}
        selectAllLabel={this.props.selectAllLabel}
        options={options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          MultiValue,
          ValueContainer,
        }}
        isClearable={
          this.props.isClearable !== undefined ? this.props.isClearable : true
        }
        onChange={this.props.handleChange || this.logChange}
        allowSelectAll={
          this.props.allowSelectAll !== undefined
            ? this.props.selectAllLabel
            : true
        }
        value={this.props.optionSelected}
        placeholder={this.props.placeholder ? this.props.placeholder : ''}
      />
    );
  }
}

import React from 'react';
import moment from 'moment';

import DateFilter from 'components/Grid/Filter/Date';

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 100,
    filterable: true,
    sortable: true,
  },
  {
    title: 'common.shared.date',
    accessor: date =>
      moment(date.date).format(moment.localeData().longDateFormat('L')),
    name: 'date',
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    width: 180,
    filterable: true,
    sortable: true,
  },
  {
    title: 'common.shared.content',
    accessor: content => (
      <div dangerouslySetInnerHTML={{ __html: content.content }} />
    ),
    name: 'user',
    filterable: true,
    sortable: false,
  },
];

export default columnConfig;

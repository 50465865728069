import React from 'react';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import RemoveMenuTemplate from './RemoveMenuTemplate';
import PreviewTemplate from './PreviewTemplate';
import ApplyMenuTemplate from './ApplyMenuTemplate';
import { GetApp } from '@material-ui/icons';
import moment from 'moment';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

class UseMenuTemplate extends React.Component {
  state = {
    useMenuTemplateModal: false,
  };

  closeUseTemplateModal = () =>
    this.setState(prevState => ({ ...prevState, useMenuTemplateModal: false }));

  openUseTemplateModal = () =>
    this.setState(prevState => ({ ...prevState, useMenuTemplateModal: true }));

  onFinishAssignMenu = data => {
    return new Promise(async (resolve, reject) => {
      let type, message;
      if (data.success) {
        type = 'success';
        await this.props.onFinishAssign();
      } else {
        type = 'error';
      }
      message = this.props.t(`menuPlanner.action.template.use.${type}`);

      this.props.openToast({
        messages: [message],
        type: type,
        autoHideDuration: TOAST_DURATIONS.SM,
      });

      this.closeUseTemplateModal();
      resolve({});
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.useMenuTemplateModal}
          onClose={this.openUseTemplateModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeUseTemplateModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="dense"
                    style={{ borderBottom: 0, textAlign: 'center' }}
                  >
                    {this.props.t('menuPlanner.action.template.use.template')}
                  </TableCell>
                  <TableCell
                    padding="dense"
                    style={{ borderBottom: 0, textAlign: 'center' }}
                  >
                    {this.props.t('menuPlanner.action.template.use.preview')}
                  </TableCell>
                  <TableCell
                    padding="dense"
                    style={{ borderBottom: 0, textAlign: 'center' }}
                  >
                    {this.props.t('menuPlanner.action.template.use.use')}
                  </TableCell>
                  <TableCell
                    padding="dense"
                    style={{ borderBottom: 0, textAlign: 'center' }}
                  >
                    {this.props.t('menuPlanner.action.template.use.remove')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.menuTemplates.map(
                  (menuTemplate, menuTemplateKey) => (
                    <TableRow key={menuTemplateKey}>
                      <TableCell padding="dense" style={{ borderBottom: 0 }}>
                        {menuTemplate.brandName} -
                        {this.props.t('menuPlanner.action.template.use.name', {
                          name: menuTemplate.name,
                          date: moment(menuTemplate.date).format('ll'),
                        })}
                      </TableCell>
                      <TableCell padding="dense" style={{ borderBottom: 0 }}>
                        <PreviewTemplate date={moment(menuTemplate.date)} />
                      </TableCell>
                      <TableCell padding="dense" style={{ borderBottom: 0 }}>
                        <ApplyMenuTemplate
                          id={menuTemplate.id}
                          date={this.props.date}
                          onFinishAssign={this.onFinishAssignMenu}
                        />
                      </TableCell>
                      <TableCell padding="dense" style={{ borderBottom: 0 }}>
                        <RemoveMenuTemplate
                          id={menuTemplate.id}
                          content={' '}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
        <BottomNavigationAction
          label={this.props.t('menuPlanner.action.template.use.actionName')}
          showLabel={true}
          icon={<GetApp style={{ color: '#01a8bc' }} />}
          onClick={this.openUseTemplateModal}
        />
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => {
  return {
    menuTemplates: state.MenuTemplates.menuTemplates,
  };
};

export default withTranslation()(
  withStyles(combinedStyles)(
    connect(mapStateToProps, null)(withToast(UseMenuTemplate))
  )
);

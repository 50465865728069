import React from 'react';
import Moment from 'moment';
import DateFilter from 'components/Grid/Filter/Date';
import { ActionTypeCell } from './ActionTypeCell';

import { ModalLogBlock } from 'components/History/LogView';
const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    width: 100,
  },
  {
    title: 'clients.date',
    accessor: row => {
      return row.loggedAt
        ? new Moment(row.loggedAt).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    name: 'loggedAt',
    width: 120,
    sortable: false,
    filterable: false,
  },
  {
    title: 'clients.whoDid',
    accessor: (row, plug) => {
      let value = row.actorRawInfo;

      if (row.impersonator) {
        value = `${row.impersonator.firstName} ${row.impersonator.lastName} (${
          row.impersonator.email
        }) ${plug.t(
          'logView.actorInfo.loggedOnAccount',
          'zalogowany na koncie'
        )} ${value}`;
      }

      return value;
    },
    name: 'actorRawInfo',
  },
  {
    Cell: ActionTypeCell,
    title: 'clients.actType',
    accessor: 'actionType',
    name: 'actionType',
  },
  {
    title: 'clients.changedObject',
    accessor: 'objectName',
    name: 'objectName',
  },
  {
    title: 'clients.objectId',
    accessor: 'objectId',
    name: 'objectId',
  },
  {
    sortable: false,
    filterable: false,
    title: 'common.details',
    accessor: row => <ModalLogBlock row={row} />,
    name: 'activityDetails',
  },
];

export default columnConfig;

import React from 'react';
import bagsColumnConfig from './bagsColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';
import { withTranslation } from 'react-i18next';
class Bags extends React.Component {
  render() {
    return (
      <DataGrid
        hideArchive={true}
        actions={true}
        paginationTop={true}
        paginationBottom={false}
        url="/ecommerce-bags-view"
        reportName={'bags'}
        role={'ORDER'}
        columns={bagsColumnConfig(this.props.t)}
        minRows={18}
        manipulateQuery={(requestData, query) => {
          query['clientId[]'] = this.props.userId;

          return query;
        }}
      />
    );
  }
}

export default withTranslation()(Bags);

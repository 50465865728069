import React from 'react';
import FormVariantsContainer from './components/FormVariants.container';

const Form = ({ match, location, history }) => {
  return (
    <FormVariantsContainer
      match={match}
      location={location}
      history={history}
    />
  );
};

export default Form;

import React from 'react';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from '../columnConfigs/columnConfig';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_INGREDIENT,
  ROLE_REMOVE_INGREDIENT,
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_IMPORT_INGREDIENT,
  ROLE_IMPORT_INGREDIENT_PRICE,
  ROLE_EXPORT_INGREDIENT_PRICE,
  ROLE_IMPORT_INGREDIENT_TRANSLATION,
  ROLE_EXPORT_INGREDIENT_TRANSLATION,
} from 'helpers/roles';
import Button from 'components/CustomButtons/Button';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import { currencyToSymbol } from 'utils/currencies';
import { Dialog } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';

const ListIngredientsView = ({ props }) => {
  const {
    classes,
    state,
    setState,
    t,
    language,
    currency,
    newSize,
    renderCustomRemoveBody,
    toggleModal,
    importIngredients,
    loadExample,
    loadPricesExample,
    loadTranslationsExample,
    massDeleteHandleMultipleCheckboxes,
    checkForOccurrenceIngredientWithDishes,
    massDeleteSubmit,
    exportPrices,
    exportTranslations,
    massDeleteHandleSingleCheckbox,
  } = props;
  return (
    <AdminTable title={t('list.ingredientsList')}>
      <InfoModal
        modalState={state.openedModal}
        handleClose={() => toggleModal('')}
        modalContent={
          <div>
            {state.modalMode === 'ingredients' && (
              <span>
                <h1>{t('ingredients.ingredientsImport')}</h1>
                <p style={{ textAlign: 'left' }}>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: t('ingredients.importerModalInfo', {
                        importCSVExample: loadExample(language),
                        interpolation: { escapeValue: false },
                        currency: currencyToSymbol(currency),
                      }),
                    }}
                  />
                </p>
              </span>
            )}
            {state.modalMode === 'prices' && (
              <span>
                <h1>
                  {t('ingredients.pricesImport', 'Import cen składników')}
                </h1>
                <p style={{ textAlign: 'left' }}>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: t('ingredients.prices.importerModalInfo', {
                        importCSVExample: loadPricesExample(language),
                        interpolation: { escapeValue: false },
                        currency: currencyToSymbol(currency),
                      }),
                    }}
                  />
                </p>
              </span>
            )}

            {state.modalMode === 'translations' && (
              <span>
                <h1>
                  {t(
                    'form.ingredients.import.translation.title',
                    'Import tłumaczeń'
                  )}
                </h1>
                <p style={{ textAlign: 'left' }}>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: t('form.ingredients.translation.modalInfo', {
                        importCSVExample: loadTranslationsExample(language),
                        interpolation: { escapeValue: false },
                        currency: currencyToSymbol(currency),
                      }),
                    }}
                  />
                </p>
              </span>
            )}

            <MediaUpload
              file={state}
              setFile={file => setState(prev => ({ ...prev, file: file }))}
              buttonText={t('form.selectCSVFile')}
              addButtonProps={{
                color: 'info',
                round: false,
              }}
              changeButtonProps={{
                color: 'info',
                round: false,
              }}
              removeButtonProps={{
                color: 'danger',
                round: false,
              }}
            />
            <div>
              {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                isGranted(ROLE_IMPORT_INGREDIENT) && (
                  <Button
                    color={state.file ? 'success' : 'default'}
                    disabled={
                      !state.file ||
                      state.isImporting ||
                      state.importButtonClicked
                    }
                    onClick={importIngredients}
                  >
                    {t('form.import')}
                  </Button>
                )}
              <Button onClick={() => toggleModal('')}>{t('form.close')}</Button>
            </div>
          </div>
        }
        closeButtonText={t('form.close')}
        hideDefaultCloseButton
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
          isGranted(ROLE_IMPORT_INGREDIENT) && (
            <Button
              style={{ margin: '5px', width: '158px' }}
              color={'info'}
              onClick={() => toggleModal('ingredients')}
            >
              {t('form.importIngredients', 'Importuj składniki')}
            </Button>
          )}

        {isGranted(ROLE_REMOVE_INGREDIENT) && (
          <Button
            data-cy="__remove_dictionary_button"
            color="danger"
            onClick={() => {
              checkForOccurrenceIngredientWithDishes();
            }}
            disabled={state?.checkedRowIds?.length === 0}
          >
            {t(
              'dictionary.deleteDictionaryElement',
              'Usuń zaznaczone elementy'
            )}
          </Button>
        )}

        {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
          isGranted(ROLE_IMPORT_INGREDIENT_PRICE) && (
            <Button
              style={{ margin: '5px', width: '158px' }}
              color={'info'}
              onClick={() => toggleModal('prices')}
            >
              {t('form.importPrices', 'Importuj ceny')}
            </Button>
          )}
        {isGranted(ROLE_EXPORT_INGREDIENT_PRICE) && (
          <Button
            data-cy="__export_prices"
            color="info"
            onClick={() => {
              exportPrices();
            }}
          >
            {t('dictionary.exportPrices', 'Eksportuj ceny')}
          </Button>
        )}

        {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
          isGranted(ROLE_IMPORT_INGREDIENT_TRANSLATION) && (
            <Button
              style={{ margin: '5px', width: '158px' }}
              color={'info'}
              onClick={() => toggleModal('translations')}
            >
              {t(
                'form.ingredients.import.translations',
                'Importuj tłumaczenia'
              )}
            </Button>
          )}

        {isGranted(ROLE_EXPORT_INGREDIENT_TRANSLATION) && (
          <Button
            data-cy="__export_prices"
            color="info"
            onClick={() => {
              exportTranslations();
            }}
          >
            {t('form.ingredients.export.translations', 'Eksportuj tłumaczenia')}
          </Button>
        )}
        <div
          style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}
        >
          {isGranted(ROLE_CREATE_INGREDIENT) && (
            <Button data-cy="__export_prices" color="success" onClick={newSize}>
              {`${t('form.addIngredient')} +`}
            </Button>
          )}
        </div>
      </div>
      <DataGrid
        refresh={state.refreshList}
        massDelete={true}
        massDeleteItemsIds={state.checkedRowIds}
        massDeleteHandleSingleCheckbox={massDeleteHandleSingleCheckbox}
        massDeleteHandleMultipleCheckboxes={massDeleteHandleMultipleCheckboxes}
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        defaultHiddenColumns={['shoppingName']}
        paginationBottom={false}
        url="ingredients"
        reportName={'ingredient'}
        columns={columnConfig}
        role="INGREDIENT"
        renderCustomRemoveBody={renderCustomRemoveBody}
        manipulateQuery={(table, query) => {
          query['properties'] = [
            'id',
            'name',
            'workName',
            'shoppingName',
            'price',
            'inventoryAmount',
          ];
          query['properties[categories]'] = ['value'];
          query['properties[allergens]'] = ['value'];

          return query;
        }}
      />
      <Dialog fullWidth={true} open={state.isConfirmModalOpened}>
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={t('clients.areYouSure')}
          onConfirm={massDeleteSubmit}
          onCancel={() =>
            setState(prevState => ({
              ...prevState,
              isConfirmModalOpened: !prevState.isConfirmModalOpened,
            }))
          }
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        >
          {state.recipesWithIngredientArray.length > 0 ? (
            <b>
              {t(
                'ingredients.informations.ingredientWithRecipes',
                'Składniki wystepują w przepisach: '
              )}
            </b>
          ) : (
            <b>
              {t(
                'ingredients.informations.ingredientWithoutRecipes',
                'Wybrane składniki nie wystepują w żadnych przepisach: '
              )}
            </b>
          )}
          {state.recipesWithIngredientArray}
        </SweetAlert>
      </Dialog>
    </AdminTable>
  );
};

export default ListIngredientsView;

import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import BaseDatetime from 'react-datetime';

class DateTime extends React.Component {
  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.props.showClear && (
          <Button
            style={{
              padding: 4,
              paddingRight: 2,
              paddingLeft: 8,
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-70%)',
              zIndex: 10,
              borderRadius: '100%',
            }}
            onClick={() =>
              typeof this.props.onClear === 'function'
                ? this.props.onClear()
                : () => {}
            }
          >
            <i className="fas fa-times" style={{ fontSize: 12 }} />
            &nbsp;
          </Button>
        )}
        <BaseDatetime {...this.props} />
      </div>
    );
  }
}

export default DateTime;

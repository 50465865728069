import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Tooltip } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import Card from 'components/Card/Card';
import React from 'react';
import { FormTextInput } from 'components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Check, Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import classNames from 'classnames';
const useStyles = makeStyles({
  formLabel: { marginTop: '15px', marginBottom: '24px' },
  toolip: { marginLeft: '5px' },
  div: { marginLeft: '-15px' },
});

const LogoSettings = ({
  classes: propClasses,
  t,
  getImage,
  removeImage,
  state,
  handleInputChange,
}) => {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem sm={6} md={3}>
              <FormLabel
                className={classNames(
                  propClasses.labelHorizontal,
                  classes.formLabel
                )}
              >
                {t('brandCfg.logoMobile')}
                <Tooltip
                  className={classes.toolip}
                  title={
                    <div>
                      <h4>{t('brandCfg.logoMobileDescriptionTooltip')}</h4>
                      <h4>
                        <ul>
                          <Trans
                            i18nKay="brandCfg.logoMobileDescriptionTooltip.list"
                            defaults="Zalecane rozmiary i formaty: <l>.svg dla grafik o dowolnej proporcji</l><l>.png dla grafik pionowych oraz kwadratowych - szerokość min. 160 px</l><l>.png dla grafik poziomych - szerokość min. 400 px i wysokość wynikająca z proporcji logo</l>"
                            components={{ l: <li /> }}
                          />
                        </ul>
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <div className={classes.div}>
                <FormImageUpload
                  classes={propClasses}
                  stateName="logoMobile"
                  buttonText={t('brandCfg.selectLogo')}
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.logoMobileUrl}
                />
              </div>
            </GridItem>
            <GridItem sm={6} md={3}>
              <FormLabel
                className={classNames(
                  propClasses.labelHorizontal,
                  classes.formLabel
                )}
              >
                {t('brandCfg.logoDesktop')}
                <Tooltip
                  className={classes.toolip}
                  title={
                    <div>
                      <h4>{t('brandCfg.logoDesktopDescriptionTooltip')}</h4>
                      <h4>
                        <ul>
                          <Trans
                            i18nKay="brandCfg.logoDesktopDescriptionTooltip.list"
                            defaults="Zalecane rozmiary i formaty: <l>.svg dla grafik o dowolnej proporcji</l><l>.png dla grafik pionowych oraz kwadratowych - szerokość min. 160 px</l><l>.png dla grafik poziomych - szerokość min. 400 px i wysokość wynikająca z proporcji logo</l>"
                            components={{ l: <li /> }}
                          />
                        </ul>
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <div className={classes.div}>
                <FormImageUpload
                  classes={propClasses}
                  stateName="logoDesktop"
                  buttonText={t('brandCfg.selectLogo')}
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.logoDesktopUrl}
                />
              </div>
            </GridItem>
            <GridItem sm={6} md={3}>
              <FormLabel
                className={classNames(
                  propClasses.labelHorizontal,
                  classes.formLabel
                )}
              >
                {t('brandCfg.logoMail')}
                <Tooltip
                  className={classes.toolip}
                  title={
                    <div>
                      <h4>
                        {t('common.height')} {t('common.any')}
                        {'; '}
                        {t('common.width')}
                        {' 200px'}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <div className={classes.div}>
                <FormImageUpload
                  classes={propClasses}
                  stateName="logoMail"
                  buttonText={t('brandCfg.selectLogo')}
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.logoMailUrl}
                />
              </div>
            </GridItem>
            <GridItem sm={6} md={3}>
              {' '}
              <FormLabel
                className={classNames(
                  propClasses.labelHorizontal,
                  classes.formLabel
                )}
              >
                {t('brandCfg.iconTabBrowser')}
              </FormLabel>
              <FormImageUpload
                classes={propClasses}
                stateName="favicon"
                buttonText={t('brandCfg.selectIcon')}
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={state.faviconUrl}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={4}>
              <FormTextInput
                label={t('brandCfg.addrDestLogo')}
                classes={propClasses}
                name="logoLink"
                value={state.logoLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              {state.logoLink.includes('https://') && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="logoLinkOpenNewCard"
                      onClick={() => {
                        handleInputChange({
                          target: {
                            name: 'logoLinkOpenNewCard',
                            value: !state.logoLinkOpenNewCard,
                          },
                        });
                      }}
                      checked={state.logoLinkOpenNewCard}
                      checkedIcon={
                        <Check className={propClasses.checkedIcon} />
                      }
                      icon={<Check className={propClasses.uncheckedIcon} />}
                      classes={{
                        checked: propClasses.checked,
                        root: propClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: propClasses.label,
                  }}
                  label={t('brandCfg.address.logo.openNewLink')}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

export default LogoSettings;

import React, { useCallback } from 'react';
import Row from './Row';

const style = {
  width: '100%',
};

const Container = ({
  calories,
  variant,
  getSizeName,
  getRowSum,
  openModal,
  handleVariantName,
  handleVariantWorkName,
  toggleCalorificActivness,
  removeRow,
  setCalories,
}) => {
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = calories[dragIndex];
      setCalories(dragIndex, hoverIndex, dragRow);
    },
    [calories]
  );

  const renderRow = (row, index) => {
    return (
      <Row
        key={row.id}
        index={index}
        id={row.id}
        calorie={row}
        variant={variant}
        getSizeName={getSizeName}
        getRowSum={getRowSum}
        openModal={openModal}
        handleVariantName={handleVariantName}
        handleVariantWorkName={handleVariantWorkName}
        toggleCalorificActivness={toggleCalorificActivness}
        removeRow={removeRow}
        moveCard={moveRow}
        {...row}
      />
    );
  };

  return (
    <>
      <div style={style}>{calories.map((row, i) => renderRow(row, i))}</div>
    </>
  );
};
export default Container;

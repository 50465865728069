import types from 'helpers/constants';

const initialState = {
  permissions: [],
};

function Permissions(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    default:
      return state;
  }
}

export default Permissions;

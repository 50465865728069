import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/CustomButtons/Button';
import SubscriptionHistory from '../../Subscriptions/Dialogs/SubscriptionHistory';
import SubscriptionOrders from '../../Subscriptions/Dialogs/SubscriptionOrders';
import SubscriptionPause from '../../Subscriptions/Dialogs/SubscriptionPause';
import SubscriptionMarkToRemove from '../../Subscriptions/Dialogs/SubscriptionMarkToRemove';

import { FormLabel } from '@material-ui/core';

const BigCalendarHeaderSubscriptionButtons = ({
  dietInfo,
  fetchOnDietChange,
}) => {
  const { t } = useTranslation();
  const row = dietInfo?.subscription;
  if (!row) return null;
  return (
    <div style={{ margin: '1rem 1.5rem 0' }}>
      <FormLabel>{t('subscription.management.label')}:</FormLabel>

      <Button simple justIcon round size="sm" color="primary">
        <SubscriptionHistory row={row} />
      </Button>
      <Button simple justIcon round size="sm" color="primary">
        <SubscriptionOrders row={row} />
      </Button>
      {!row.markedToRemove && (
        <Button
          style={{ fontSize: '12px' }}
          justIcon
          round
          size="sm"
          color="transparent"
        >
          <SubscriptionPause
            row={row}
            onUpdate={() => fetchOnDietChange(dietInfo.id)}
          />
        </Button>
      )}
      <Button justIcon round size="sm" color="transparent">
        <SubscriptionMarkToRemove
          row={row}
          onUpdate={() => fetchOnDietChange(dietInfo.id)}
        />
      </Button>
    </div>
  );
};

export default BigCalendarHeaderSubscriptionButtons;

import { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import SelectInput from '../../components/FormSelect/SelectInput';
import { combineStyles } from '../../helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import Button from '../../components/CustomButtons/Button';
import { fetchDrivers } from 'actions/Drivers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const styles = () => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

class AssignDriverModal extends Component {
  state = {
    givenZoneHasDrivers: false,
  };

  componentDidMount() {
    this.props.fetchDrivers();
  }

  render() {
    const {
      modalOpen,
      classes,
      selectedAddress,
      selectedDriver,
      handleDriverSelect,
      handleDriverChange,
      isLoading,
      drivers,
      t,
    } = this.props;

    const addressHasZone = selectedAddress && selectedAddress.zone;

    return (
      selectedAddress && (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={modalOpen}
          onClose={this.props.closeModal}
        >
          <DialogContent>
            {isLoading ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={this.props.closeModal}
                >
                  <CloseIcon />
                </IconButton>
                <h2>
                  {t('addresses.zone', 'Strefa')}:{' '}
                  <b>
                    {addressHasZone
                      ? selectedAddress.zone.name
                      : t('addresses.noZone', 'Brak strefy')}
                  </b>
                </h2>
                {addressHasZone && (
                  <>
                    <SelectInput
                      classes={classes}
                      options={drivers}
                      mapBy="name"
                      trackBy="@id"
                      label={t('addresses.noDriver', 'Przypisany kierowca')}
                      value={selectedDriver}
                      handleChange={(ev, driver) => {
                        handleDriverSelect(ev, driver);
                      }}
                    />
                    <Button
                      color={'primary'}
                      disabled={!selectedDriver || isLoading}
                      onClick={handleDriverChange}
                    >
                      {isLoading
                        ? t('common.loading', 'Ładowanie')
                        : t('addresses.changeDriver', 'Zmień kierowcę')}
                    </Button>
                  </>
                )}
                {!selectedAddress.zone && (
                  <h2>
                    {t(
                      'addresses.addresWithNoZone',
                      'Ten adres nie ma przypisanej strefy'
                    )}
                  </h2>
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      )
    );
  }
}

const combinedStyles = combineStyles(styles, buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => ({
  drivers: state.Drivers.drivers,
});

const mapDispatchToProps = {
  fetchDrivers,
};

const enhance = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(combinedStyles)
);

export default enhance(AssignDriverModal);

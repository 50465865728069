import React from 'react';
import { withTranslation } from 'react-i18next';

class ItemTypeOrder extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <select
        style={{ padding: '0', width: '100%' }}
        onChange={e => {
          this.props.onChange(e.target.value === '' ? '' : e.target.value);
        }}
        value={this.props?.value}
      >
        <option value="">
          {t('common.orderFilter.choiceFilter', 'Wybierz filtr')}
        </option>
        <option value="DIET">{t('common.orderFilter.diet', 'Diety')}</option>
        <option value="DAY_DISH">
          {t('common.orderFilter.dish', 'Dania')}
        </option>
        <option value="DAY_ADDON">
          {t('common.orderFilter.addon', 'Dodatki')}
        </option>
        <option value="DAY_MODIFICATION">
          {t('common.orderFilter.modification', 'Modyfikacja')}
        </option>
        <option value="BUY_BAG">
          {t('common.orderFilter.bag', 'Odnowienie subskrypcji')}
        </option>
      </select>
    );
  }
}

export default withTranslation()(ItemTypeOrder);

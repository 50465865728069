import { NavLink } from 'react-router-dom';
import React from 'react';
import Moment from 'moment';
import { isEmpty } from 'helpers/helpers';
import Select from '../../../components/Grid/Filter/Select';
import DateFilter from '../../../components/Grid/Filter/Date';
import SelectFilter from '../../../components/DataGrid/Filter/SelectFilter';

const columnsConfig = ({ t, tags, mealTypes = [], displayedDate }) => {
  const now = Moment(displayedDate).set({ hour: 0, minute: 0 });
  const config = [
    {
      accessor: 'id',
      name: 'id',
      width: 50,
      sortable: true,
    },
    {
      name: 'nameForClient',
      accessor: 'nameForClient',
      sortable: true,
    },
    {
      name: 'workingName',
      accessor: 'workingName',
      sortable: true,
      filterable: true,
      extractFiltersByComma: false,
    },
    {
      name: 'ingredientName',
      accessor: row => {
        return row.components
          .map(component =>
            component.recipe
              ? component.recipe.workName
              : (component.ingredient && component.ingredient.name) || false
          )
          .filter(Boolean)
          .join(', ');
      },
      sortable: false,
    },
    {
      name: 'allergens',
      accessor: row => {
        return row.allergens
          .map(({ value = false }) => value)
          .filter(Boolean)
          .join(', ');
      },
      sortable: false,
    },
    {
      name: 'tags',
      accessor: row => {
        return row.tags.map(el => el.value).join(', ');
      },
      sortable: false,
      filterable: true,
      extractFiltersByComma: false,
      ...(tags?.length > 6
        ? { filterable: true }
        : {
            Filter: ({ onChange, filter }) => {
              const options = !isEmpty(tags)
                ? tags.map(tag => ({
                    '@id': tag['value'],
                    name: tag['value'],
                  }))
                : [];

              return (
                <Select
                  options={options}
                  value={filter?.value}
                  onChange={onChange}
                  multiple={false}
                />
              );
            },
          }),
    },
    {
      name: 'lastUsedDate',
      accessor: row => {
        let latestUsage = null;

        if (row.menuPlannerInfo.latestUsage) {
          latestUsage = Moment(row.menuPlannerInfo.latestUsage);
        }
        return latestUsage
          ? latestUsage.isSame(now)
            ? t('common.shared.today')
            : latestUsage.from(now)
          : '';
      },
      Filter: ({ onChange, filter }) => {
        return <DateFilter onChange={onChange} value={filter?.value} />;
      },
      sortable: true,
      filterable: true,
      extractFiltersByComma: false,
    },
    {
      name: 'featureUsedDate',
      accessor: row => {
        let featureUsage = null;

        if (row.menuPlannerInfo.featureUsage) {
          featureUsage = Moment(row.menuPlannerInfo.featureUsage);
        }
        return featureUsage
          ? featureUsage.isSame(now)
            ? t('common.shared.today')
            : featureUsage.from(now)
          : '';
      },
      Filter: ({ onChange, filter }) => {
        return <DateFilter onChange={onChange} value={filter?.value} />;
      },
      sortable: true,
      filterable: true,
      extractFiltersByComma: false,
    },
    {
      name: 'servingSuggestion',
      accessor: 'servingSuggestion.value',
      sortable: true,
    },
    {
      name: 'types',
      accessor: row => {
        return row.types.map(el => el.name).join(', ');
      },
      ...(mealTypes?.length > 6
        ? { filterable: true }
        : {
            Filter: ({ onChange, filter }) => {
              const options = !isEmpty(mealTypes)
                ? mealTypes.map(mealType => ({
                    '@id': mealType,
                    name: mealType,
                  }))
                : [];

              return (
                <SelectFilter
                  options={options}
                  value={filter?.value ?? []}
                  onChange={onChange}
                  multiple={true}
                />
              );
            },
          }),
      sortable: true,
      extractFiltersByComma: false,
    },
    {
      name: 'rate',
      accessor: row => {
        return row.rateAVG !== null ? row.rateAVG.toFixed(2) : '-';
      },
      filterable: false,
      sortable: true,
    },
    {
      name: 'actions',
      accessor: row => {
        return (
          <NavLink to={`/admin/meals/edit/${row.id}`}>
            {t('menuPlanner.dishesGrid.goToDish')}
          </NavLink>
        );
      },
      filterable: false,
      sortable: false,
    },
  ];

  return config.map(row => {
    row.title = t(`menuPlanner.dishesGrid.columns.${row.name}`);

    return row;
  });
};

export default columnsConfig;

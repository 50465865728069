import React from 'react';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const FormSelectMultiple = ({
  label,
  name,
  id,
  classes,
  options,
  value,
  handleChange,
  mapBy,
  size,
  disabled,
  dontShowInline,
  placeholder,
}) => {
  return (
    <GridContainer alignItems="center">
      <GridItem xs={size ? size : 12}>
        <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
        <FormControl fullWidth className={classes.selectFormControl}>
          {placeholder && (
            <InputLabel className={classes.selectLabel}>
              {placeholder}
            </InputLabel>
          )}
          <Select
            multiple
            value={value}
            onChange={event => handleChange(event)}
            name={name}
            renderValue={selectedItems =>
              !dontShowInline
                ? selectedItems.map(({ value }) => value).join(', ')
                : ''
            }
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{
              name: name,
              id: id,
              disabled: disabled ? disabled : false,
            }}
          >
            <MenuItem
              disabled
              value={label}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {label}
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem
                key={index}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value={option}
              >
                {option[mapBy]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
};

export default FormSelectMultiple;

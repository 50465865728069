import React from 'react';
import BaseBrandAndDateReport from './BaseBrandReport';
import roles, { ROLE_SHOW_VARIANT } from 'helpers/roles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { fetchVariants } from 'actions/Variants';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Archive from '../../components/Archive/Archive';
import SelectInput from '../../components/FormSelect/SelectInput';
import getReportFileName from 'helpers/getReportFileName';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';

class MacroIngredients extends React.Component {
  url = 'reports/macro-ingredients';

  state = {
    variant: '',
    date: '',
    isGenerating: false,
  };
  getFileName = getReportFileName(
    this.props.t('reports.reportMacroIngredients')
  );

  componentDidMount() {
    this.props.fetchVariants();
  }

  validateDate = () => {
    return this.state.date;
  };

  validateVariant = () => {
    return this.state.variant;
  };

  handleSelectChange = (event, value) => {
    this.setState(() => {
      return {
        variant: value,
      };
    });
  };

  handleGenerate = title => {
    this.setState({ isGenerating: true });

    if (!this.validateVariant()) {
      this.setState({ isGenerating: false });

      return this.props.openToast({
        messages: [this.props.t('reports.selectVariant')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (!this.validateDate()) {
      this.setState({ isGenerating: false });

      return this.props.openToast({
        messages: [this.props.t('reports.selectDate')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const params = {
      date: this.state.date,
      variant: this.state.variant.id,
    };

    axios
      .get(this.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/vnd.ms-excel' },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.getFileName(this.state)}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(this.props.t('reports.noAccess'));
            this.setState({ isGenerating: false });
          }
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t('reports.cannotGenerate'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  render() {
    let { variants, classes, t } = this.props;

    return isGranted(roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS) ? (
      <>
        <CardWrapper title={t('reports.reportMacroIngredients')}>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{this.props.t('reports.generate')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardBody>
            <GridContainer justify="center">
              {isGranted(ROLE_SHOW_VARIANT) && (
                <GridItem sm={4}>
                  <SelectInput
                    classes={classes}
                    options={variants.filter(variant => variant.active)}
                    mapBy="name"
                    trackBy="@id"
                    value={this.state.variant}
                    handleChange={this.handleSelectChange}
                    label={this.props.t('reports.selectVariant')}
                  />
                </GridItem>
              )}
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('reports.selectDate')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.date)}
                    onChange={e =>
                      this.setState({ date: e.format('YYYY-MM-DD') })
                    }
                    inputProps={{ readOnly: true }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  onClick={this.handleGenerate}
                  disabled={this.state.isGenerating}
                  color={'success'}
                  round
                >
                  {this.props.t('reports.gReport')}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive type={['REPORT']} reportName={['MACRO_INGREDIENTS']} />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  variants: state.Variants.variants,
});

const mapDispatchToProps = {
  fetchVariants,
};

BaseBrandAndDateReport.propTypes = {
  brands: PropTypes.array,
  classes: PropTypes.any,
  fileName: PropTypes.func.isRequired,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withTranslation(),
  withStyles(combinedStyles)
);

export default enhance(MacroIngredients);

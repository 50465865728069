import React from 'react';
import produce from 'immer';
import { useSelector } from 'react-redux';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InputsIndex from './InputsIndex';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { SelectInput } from '../../components';
import isEmpty from 'lodash/isEmpty';
import CardWrapper from 'components/Card/CardWrapper';

const ShopMeals = ({ classes, shopModule, setShopModule }) => {
  const { t } = useTranslation();
  const currency = useSelector(
    state => state.Brands.brand.multinational.currency
  );

  return (
    <CardWrapper title={t('$*shop.shopMeals.title', '$$Podstawowe')}>
      <CardBody>
        <GridContainer>
          <GridItem xs={4}>
            <GridContainer>
              <GridItem xs={12}>
                <h4 className={classes.cardIconTitle}>
                  {t('$*shop.shopMeals.dishAsOverDish', '$$Oferta Sklepu')}
                </h4>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputsIndex
                    label={t(
                      '$*shop.shopMeals.dishesFromPlanning',
                      '$$Oferuj Dania ze zmiennej oferty '
                    )}
                    type="checkbox"
                    value={shopModule?.allowDishesFromPlaning}
                    handler={() => {
                      setShopModule(
                        produce(draft => {
                          draft.allowDishesFromPlaning =
                            !draft.allowDishesFromPlaning;
                        })
                      );
                    }}
                  />
                  <Tooltip
                    title={
                      <>
                        <h4>
                          {t(
                            '$*shop.shopMeals.dishesFromPlanning.tooltip',
                            'W Sklepie dostępne będą Dania, które są zaplanowane w dietach na danym dniu.'
                          )}
                        </h4>
                      </>
                    }
                  >
                    <Info
                      style={{
                        fontSize: '16px',
                        color: 'grey',
                        marginLeft: '4px',
                        marginBottom: '-3px',
                      }}
                    />
                  </Tooltip>
                </div>
                {shopModule?.allowDishesFromPlaning && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SelectInput
                      classes={classes}
                      label={t('$*shop.shopMeals.dishesFromPlaningMergeMode')}
                      options={[
                        {
                          val: 'NO_MERGE',
                          name: t(
                            '$*shop.shopMeals.dishesFromPlaningMergeMode.NO_MERGE'
                          ),
                        },
                        {
                          val: 'LOWER_COST',
                          name: t(
                            '$*shop.shopMeals.dishesFromPlaningMergeMode.LOWER_COST'
                          ),
                        },
                        {
                          val: 'HIGHER_COST',
                          name: t(
                            '$*shop.shopMeals.dishesFromPlaningMergeMode.HIGHER_COST'
                          ),
                        },
                      ]}
                      value={shopModule?.dishesFromPlaningMergeMode}
                      trackBy="val"
                      mapBy="name"
                      handleChange={({ target: { value } }) => {
                        setShopModule(
                          produce(draft => {
                            draft.dishesFromPlaningMergeMode = value;
                          })
                        );
                      }}
                      labelSm={3}
                      selectSm={4}
                    />
                  </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputsIndex
                    label={t(
                      '$*shop.shopMeals.dishesFromOffer',
                      '$$Oferuj Dania ze stałej oferty'
                    )}
                    type="checkbox"
                    value={shopModule?.allowDishesFromOffer}
                    handler={() => {
                      setShopModule(
                        produce(draft => {
                          draft.allowDishesFromOffer =
                            !draft.allowDishesFromOffer;
                        })
                      );
                    }}
                  />
                  <Tooltip
                    title={
                      <>
                        <h4>
                          {t(
                            '$*shop.shopMeals.dishesFromOffer.tooltip',
                            '$$W Sklepie dostępne będą Dania, które mają włączoną opcję “Włącz do stałej oferty sklepu“.'
                          )}
                        </h4>
                      </>
                    }
                  >
                    <Info
                      style={{
                        fontSize: '16px',
                        color: 'grey',
                        marginLeft: '4px',
                        marginBottom: '-3px',
                      }}
                    />
                  </Tooltip>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={4}>
            <GridContainer>
              <GridItem xs={12}>
                <h4>
                  {t('$*shop.shopMeals.rangeDishOrderPrice', {
                    defaultValue:
                      '$$Dopuszczalna wartość zamówienia per torba ({{currency}})',
                    replace: { currency },
                  })}
                </h4>
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.from', '$$od')}
                  value={shopModule?.minimumDailyOrderCost}
                  handler={e => {
                    const value = e.target.value;
                    setShopModule(
                      produce(draft => {
                        draft.minimumDailyOrderCost = isEmpty(value)
                          ? null
                          : value;
                      })
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.to', '$$do')}
                  value={shopModule?.maximumDailyOrderCost}
                  handler={e => {
                    const value = e.target.value;
                    setShopModule(
                      produce(draft => {
                        draft.maximumDailyOrderCost = isEmpty(value)
                          ? null
                          : value;
                      })
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <div>
                  {t(
                    '$*shop.shopMeals.rangeDishAmount',
                    '$$Dopuszczalna ilość elementów per torba'
                  )}
                  <Tooltip
                    title={
                      <>
                        <h4>
                          {t(
                            '$*shop.shopMeals.rangeDishAmount.tooltip',
                            '$$W przypadku chęci zamówienia przez klienta większej ilości dań niż jest dopuszczona, klient musi zrobić kolejne zamówienie. Ograniczenie stosuje się do ilości Dań zamawianych jedynie przez Sklep! Nie uwzględnia ilości dań jakie są zamówione jeszcze w ramach diety.'
                          )}
                        </h4>
                      </>
                    }
                  >
                    <Info
                      style={{
                        fontSize: '16px',
                        color: 'grey',
                        marginLeft: '4px',
                        marginBottom: '-3px',
                      }}
                    />
                  </Tooltip>
                </div>
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.from', '$$od')}
                  value={shopModule?.minimumDailyDishes}
                  handler={e => {
                    const value = e.target.value;
                    setShopModule(
                      produce(draft => {
                        draft.minimumDailyDishes = isEmpty(value)
                          ? null
                          : value;
                      })
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.to', '$$do')}
                  value={shopModule?.maximumDailyDishes}
                  handler={e => {
                    const value = e.target.value;
                    setShopModule(
                      produce(draft => {
                        draft.maximumDailyDishes = isEmpty(value)
                          ? null
                          : value;
                      })
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default ShopMeals;

import { useTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';

import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';

const ShortAddressFormConfirmModal = ({
  classes,
  isOpened = false,
  handleClose = () => {},
  handleConfirm = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot,
        paper: classes.modal,
      }}
      open={isOpened}
      keepMounted
      onClose={handleClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
        style={{ fontSize: '25px', fontWeight: 'bold' }}
      >
        <h4 className={classes.modalTitle}>
          {t('menuPlanner.action.warning.groupTitle')}
        </h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
        style={{ width: '100%', maxWidth: '400px', fontSize: '15px' }}
      >
        <div>
          {t('brands.newBrandForm.useMiniFormAddress.enableModal.copy')}
        </div>
      </DialogContent>
      <div
        style={{
          padding: '0 25px 15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={handleClose} color="danger">
          {t('common.shared.cancel')}
        </Button>
        <Button onClick={handleConfirm} color="success">
          {t('common.shared.confirm')}
        </Button>
      </div>
    </Dialog>
  );
};

export default withStyles(notificationsStyle)(ShortAddressFormConfirmModal);

import { get, remove } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchDiets = (requestParams = {}) => {
  return dispatch => {
    return get('/diets', { pagination: false, ...requestParams }).then(
      response => {
        dispatch({
          type: types.FETCH_DIETS,
          payload: {
            diets: response['hydra:member'],
          },
        });
      }
    );
  };
};

export const fetchDiet = id => {
  return dispatch => {
    return get(`/diets/${id}`).then(response => {
      dispatch({
        type: types.FETCH_DIET,
        payload: {
          id: response.id,
          name: response.name,
          shortName: response.shortName,
          active: response.active,
          code: response.code,
          color: response.color,
        },
      });
      return response;
    });
  };
};

export const removeDiet = id => {
  return dispatch =>
    remove(`/diets/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_DIET,
        payload: {
          dietId: id,
        },
      });
    });
};

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip } from '@material-ui/core/index';
import { Info } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

import ContainerCell from './GlycemicIndexSumCell';

class GlycemicIndexSumRow extends React.Component {
  render() {
    const { classes, mealTypeSizes, dishSizes, rowType, t } = this.props;

    return (
      <TableRow>
        <TableCell>
          {t('glycemicIndex.label')}{' '}
          <Tooltip title={<h4>{t('glycemicIndex.tooltip')}</h4>}>
            <Info
              style={{
                fontSize: '16px',
                color: 'grey',
                marginLeft: '4px',
                marginBottom: '-3px',
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell />
        <TableCell />
        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => {
          return (
            <ContainerCell
              rowType={rowType}
              key={mealTypeSizeKey}
              classes={classes}
              size={mealTypeSize.size}
              mealTypeSize={mealTypeSize}
              dishSizes={dishSizes}
            />
          );
        })}
      </TableRow>
    );
  }
}

export default withTranslation()(GlycemicIndexSumRow);

import React from 'react';
import { useTranslation } from 'react-i18next';

const AddonRow = ({ row, hasManyRows }) => {
  return (
    <div
      style={{
        margin: '15px 0 0',
        borderBottom: hasManyRows ? '1px solid #e2e2e2' : 'none',
        paddingBottom: '2px',
        textAlign: 'left',
        minWidth: '300px',
      }}
    >
      {row?.addon?.name} x{row?.quantity}
    </div>
  );
};

export const AddonsContent = ({ orderId, diet }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>
        {t('$*addons.addonsWithDeliveryId', {
          defaultValue: 'Dodatki do zamówienia {{orderId}}',
          replace: { orderId },
        })}
      </h1>
      <h2>
        {t('$*addons.dietId', {
          defaultValue: 'Dieta {{dietId}}',
          replace: { dietId: diet.id },
        })}
      </h2>
      <h3
        style={{
          textAlign: 'left',
          fontWeight: '500',
          marginBottom: '5px',
        }}
      >
        {t('$*addons.withFirstDeliveryOnly', 'Do tylko pierwszej dostawy')}:
      </h3>
      {diet.addons
        .filter(a => a.type === 'FOR_DIET' || a.type === 'FIRST')
        .map((purchaseAddon, index, arr) => {
          return <AddonRow row={purchaseAddon} hasManyRows={arr.length > 1} />;
        })}
      <h3
        style={{
          textAlign: 'left',
          fontWeight: '500',
          marginBottom: '5px',
        }}
      >
        {t('$*addons.withEveryDelivery', 'Do każdej dostawy')}:
      </h3>
      {diet.addons
        .filter(a => a.type === 'FOR_BAG' || a.type === 'EVERY')
        .map((purchaseAddon, index, arr) => {
          return <AddonRow row={purchaseAddon} hasManyRows={arr.length > 1} />;
        })}
    </div>
  );
};

import {
  HotJarModule,
  MyLeadModule,
  SalesManagoModule,
  GetResponseModule,
  IpressoModule,
  UserComModule,
  FacebookPixelModule,
  PollsterTrackModule,
  ClickOnMetricsModule,
  GoogleAnalyticsModule,
  GoogleTagManagerModule,
  BitrixModule,
  TrustedShopsModule,
  ThuliumModule,
  OmniconvertModule,
  ScanAndSortModule,
} from '../modules';

import {
  ROLE_SHOW_MODULE_GOOGLE_ANALYTICS,
  ROLE_SHOW_MODULE_GOOGLE_TAG_MANAGER,
  ROLE_EDIT_MODULE_HOTJAR,
  ROLE_EDIT_MODULE_GOOGLE_ANALYTICS,
  ROLE_EDIT_MODULE_GOOGLE_TAG_MANAGER,
  ROLE_SHOW_MODULE_FACEBOOK_PIXEL,
  ROLE_EDIT_MODULE_FACEBOOK_PIXEL,
  ROLE_SHOW_MODULE_HOTJAR,
  ROLE_SHOW_MODULE_MY_LEAD,
  ROLE_EDIT_MODULE_SALES_MANAGO,
  ROLE_SHOW_MODULE_SALES_MANAGO,
  ROLE_EDIT_MODULE_MY_LEAD,
  ROLE_SHOW_MODULE_GET_RESPONSE,
  ROLE_EDIT_MODULE_GET_RESPONSE,
  ROLE_SHOW_MODULE_IPRESSO,
  ROLE_EDIT_MODULE_USER_COM,
  ROLE_SHOW_MODULE_USER_COM,
  ROLE_EDIT_MODULE_IPRESSO,
  ROLE_EDIT_MODULE_CLICK_ON_METRICS,
  ROLE_SHOW_MODULE_POLLSTER_TRACK,
  ROLE_SHOW_MODULE_CLICK_ON_METRICS,
  ROLE_EDIT_MODULE_POLLSTER_TRACK,
  ROLE_SHOW_MODULE_BITRIX_24,
  ROLE_EDIT_MODULE_BITRIX_24,
  ROLE_SHOW_MODULE_TRUSTED_SHOPS,
  ROLE_EDIT_MODULE_TRUSTED_SHOPS,
  ROLE_SHOW_MODULE_THULIUM,
  ROLE_EDIT_MODULE_THULIUM,
  ROLE_SHOW_MODULE_OMNICONVERT,
  ROLE_EDIT_MODULE_OMNICONVERT,
  ROLE_SHOW_MODULE_SCAN_AND_SORT,
  ROLE_EDIT_MODULE_SCAN_AND_SORT,
} from 'helpers/roles';

export const columnConfigFunction = t => {
  return [
    {
      moduleName: 'GoogleAnalytics',
      moduleShowTitle: t('common.rolesForm.integrations.googleAnalytics'),
      component: 'GoogleAnalyticsModule',
      roleShow: ROLE_SHOW_MODULE_GOOGLE_ANALYTICS,
      roleEdit: ROLE_EDIT_MODULE_GOOGLE_ANALYTICS,
    },

    {
      moduleName: 'GoogleTagManager',
      moduleShowTitle: t('common.rolesForm.integrations.googleTagMenager'),
      component: 'GoogleTagManagerModule',
      roleShow: ROLE_SHOW_MODULE_GOOGLE_TAG_MANAGER,
      roleEdit: ROLE_EDIT_MODULE_GOOGLE_TAG_MANAGER,
    },

    {
      moduleName: 'HotJar',
      moduleShowTitle: t('common.rolesForm.integrations.hotjar'),
      component: 'HotJarModule',
      roleShow: ROLE_SHOW_MODULE_HOTJAR,
      roleEdit: ROLE_EDIT_MODULE_HOTJAR,
    },

    {
      moduleName: 'FacebookPixel',
      moduleShowTitle: t('common.rolesForm.integrations.facebookPixel'),
      component: 'FacebookPixelModule',
      roleShow: ROLE_SHOW_MODULE_FACEBOOK_PIXEL,
      roleEdit: ROLE_EDIT_MODULE_FACEBOOK_PIXEL,
    },

    {
      moduleName: 'MyLead',
      moduleShowTitle: t('common.rolesForm.integrations.mylead'),
      component: 'MyLeadModule',
      roleShow: ROLE_SHOW_MODULE_MY_LEAD,
      roleEdit: ROLE_EDIT_MODULE_MY_LEAD,
    },

    {
      moduleName: 'SalesManago',
      moduleShowTitle: t('common.rolesForm.integrations.salesManago'),
      component: 'SalesManagoModule',
      roleShow: ROLE_SHOW_MODULE_SALES_MANAGO,
      roleEdit: ROLE_EDIT_MODULE_SALES_MANAGO,
    },

    {
      moduleName: 'GetResponse',
      moduleShowTitle: t('common.rolesForm.integrations.getResponse'),
      component: 'GetResponseModule',
      roleShow: ROLE_SHOW_MODULE_GET_RESPONSE,
      roleEdit: ROLE_EDIT_MODULE_GET_RESPONSE,
    },

    {
      moduleName: 'Ipresso',
      moduleShowTitle: t('common.rolesForm.integrations.ipresso'),
      component: 'IpressoModule',
      roleShow: ROLE_SHOW_MODULE_IPRESSO,
      roleEdit: ROLE_EDIT_MODULE_IPRESSO,
    },

    {
      moduleName: 'UserCom',
      moduleShowTitle: t('common.rolesForm.integrations.usercom'),
      component: 'UserComModule',
      roleShow: ROLE_SHOW_MODULE_USER_COM,
      roleEdit: ROLE_EDIT_MODULE_USER_COM,
    },

    {
      moduleName: 'ClickOnMetrics',
      moduleShowTitle: t('common.rolesForm.integrations.clickOnMetrics'),
      component: 'ClickOnMetricsModule',
      roleShow: ROLE_SHOW_MODULE_CLICK_ON_METRICS,
      roleEdit: ROLE_EDIT_MODULE_CLICK_ON_METRICS,
    },

    {
      moduleName: 'PollsterTrack',
      moduleShowTitle: t('common.rolesForm.integrations.pollsterTrack'),
      component: 'PollsterTrackModule',
      roleShow: ROLE_SHOW_MODULE_POLLSTER_TRACK,
      roleEdit: ROLE_EDIT_MODULE_POLLSTER_TRACK,
    },
    {
      moduleName: 'Bitrix24',
      moduleShowTitle: t('common.rolesForm.integrations.bitrix'),
      component: 'BitrixModule',
      roleShow: ROLE_SHOW_MODULE_BITRIX_24,
      roleEdit: ROLE_EDIT_MODULE_BITRIX_24,
    },
    {
      moduleName: 'TrustedShops',
      moduleShowTitle: t('common.rolesForm.integrations.trustedShops'),
      component: 'TrustedShopsModule',
      roleShow: ROLE_SHOW_MODULE_TRUSTED_SHOPS,
      roleEdit: ROLE_EDIT_MODULE_TRUSTED_SHOPS,
    },
    {
      moduleName: 'Thulium',
      moduleShowTitle: t('common.rolesForm.integrations.thulium'),
      component: 'ThuliumModule',
      roleShow: ROLE_SHOW_MODULE_THULIUM,
      roleEdit: ROLE_EDIT_MODULE_THULIUM,
    },
    {
      moduleName: 'Omniconvert',
      moduleShowTitle: t('common.rolesForm.integrations.omniconvert'),
      component: 'OmniconvertModule',
      roleShow: ROLE_SHOW_MODULE_OMNICONVERT,
      roleEdit: ROLE_EDIT_MODULE_OMNICONVERT,
    },
    {
      moduleName: 'ScanAndSort',
      moduleShowTitle: t('common.rolesForm.integrations.scanAndSort'),
      component: 'ScanAndSortModule',
      roleShow: ROLE_SHOW_MODULE_SCAN_AND_SORT,
      roleEdit: ROLE_EDIT_MODULE_SCAN_AND_SORT,
    },
  ];
};
export const setAnalystComponent = (
  name,
  classes,
  handleModuleUpdate,
  module
) => {
  return (
    <>
      {name === 'GoogleAnalyticsModule' && (
        <GoogleAnalyticsModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'GoogleTagManagerModule' && (
        <GoogleTagManagerModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'HotJarModule' && (
        <HotJarModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'FacebookPixelModule' && (
        <FacebookPixelModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'MyLeadModule' && (
        <MyLeadModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'SalesManagoModule' && (
        <SalesManagoModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'GetResponseModule' && (
        <GetResponseModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'IpressoModule' && (
        <IpressoModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'UserComModule' && (
        <UserComModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'ClickOnMetricsModule' && (
        <ClickOnMetricsModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'PollsterTrackModule' && (
        <PollsterTrackModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'BitrixModule' && (
        <BitrixModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}

      {name === 'TrustedShopsModule' && (
        <TrustedShopsModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'ThuliumModule' && (
        <ThuliumModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'OmniconvertModule' && (
        <OmniconvertModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
      {name === 'ScanAndSortModule' && (
        <ScanAndSortModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={module}
        />
      )}
    </>
  );
};

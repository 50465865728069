import produce from 'immer';

import types from 'helpers/constants';

const initialState = () => ({
  sexes: [],
  howKnow: [],
  productionTags: [],
  smsClientTags: [],
  isLoading: {
    sexes: false,
    howKnow: false,
    productionTags: false,
    smsClientTags: false,
  },
});

const DictionaryReducer = (state = initialState(), { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.FETCH_SEXES: {
        draft.isLoading.sexes = true;
        break;
      }

      case types.FETCH_SEXES_SUCCESS: {
        draft.sexes = payload.sexes;
        break;
      }

      case types.FETCH_SEXES_FAILED: {
        draft.isLoading.sexes = false;
        break;
      }

      case types.FETCH_HOW_KNOW: {
        draft.isLoading.howKnow = true;
        break;
      }

      case types.FETCH_HOW_KNOW_SUCCESS: {
        draft.howKnow = payload.howKnow;
        break;
      }

      case types.FETCH_HOW_KNOW_FAILED: {
        draft.isLoading.howKnow = false;
        break;
      }

      case types.FETCH_PRODUCTION_TAGS: {
        draft.isLoading.productionTags = true;
        break;
      }

      case types.FETCH_PRODUCTION_TAGS_SUCCESS: {
        draft.productionTags = payload.productionTags;
        draft.isLoading.productionTags = false;
        break;
      }

      case types.FETCH_PRODUCTION_TAGS_FAILED: {
        draft.isLoading.productionTags = false;
        break;
      }

      case types.FETCH_SMS_TAGS: {
        draft.isLoading.smsClientTags = true;
        break;
      }

      case types.FETCH_SMS_TAGS_SUCCESS: {
        draft.smsClientTags = payload.smsClientTags;
        break;
      }

      case types.FETCH_SMS_TAGS_FAILED: {
        draft.isLoading.smsClientTags = false;
        break;
      }

      default:
        return draft;
    }
  });

export default DictionaryReducer;

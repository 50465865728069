import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoBanner from './InfoBanner';
import CardWrapper from 'components/Card/CardWrapper';

const InfoBannerCard = ({ title, ...props }) => {
  const { t } = useTranslation();

  return (
    <CardWrapper title={title && t('cms.infoBanner')}>
      <CardBody>
        <GridContainer>
          <InfoBanner {...props} />
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default InfoBannerCard;

import isEmpty from 'lodash/isEmpty';
import DateFilter from 'components/Grid/Filter/Date';

import Moment from 'moment';

const columnConfig = () => {
  return [
    {
      title: 'employee.workHistory.date',
      accessor: row => new Moment(row?.startAt).format('YYYY-MM-DD HH:mm:ss'),
      name: 'startAt',
      sortable: true,
      filterable: true,
      Filter: ({ onChange, filter }) => {
        return <DateFilter onChange={onChange} value={filter?.value} />;
      },
    },
    {
      title: 'employee.workHistory.workName',
      name: 'task.name',
      accessor: row =>
        !isEmpty(row?.task?.recipe)
          ? `(${row?.task?.recipe?.id}) ${row?.task?.recipe?.workName}`
          : row?.task?.name,
      sortable: true,
      filterable: true,
    },
    {
      title: 'employee.workHistory.amountToPrepare',
      accessor: row =>
        `${(
          ((row?.task?.amountToMake ?? 0) *
            (row?.task?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
            1000 ?? 0
        ).toFixed(2)} kg`,
      name: 'task.amountToMake',
      sortable: false,
      filterable: false,
    },
    {
      title: 'employee.workHistory.amountPrepared',
      accessor: row => {
        return `${(
          ((row?.task?.amountOfDone ?? 0) *
            (row?.task?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
            1000 ?? 0
        ).toFixed(2)} kg`;
      },
      name: 'task.amountOfDone',
      sortable: false,
      filterable: false,
    },
    {
      title: 'employee.workHistory.timeSpent',
      accessor: row => {
        const endAt = row?.endAt ? new Date(row?.endAt ?? null) : new Date();
        const duration = endAt.getTime() - new Date(row?.startAt).getTime();
        let seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return `${hours > 0 ? hours + ' h' : ''} ${
          minutes > 0 ? minutes + ' min' : ''
        } ${seconds > 0 ? seconds + ' s' : ''}`;
      },
      name: 'timeSpent',
      sortable: false,
      filterable: false,
    },
    {
      title: 'employee.workHistory.kitchen',
      accessor: 'task.recipe.kitchen.value',
      name: 'task.recipe.kitchen.id',
      sortable: true,
      filterable: true,
    },
    {
      title: 'employee.workHistory.department',
      accessor: 'task.recipe.department.value',
      name: 'recipe.department.id',
      sortable: true,
      filterable: true,
    },
  ];
};

export default columnConfig;

import React from 'react';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { dayFields, days as daysOfWeek } from '../consts';
import { DialogLoader } from 'components/DialogLoader';
import SlotsSettings from './components/SlotsSettings';
import OrderLengthSettings from './components/OrderLengthSettings';
import OrderLengthPeriodSettings from './components/OrderLengthPeriodSettings';

const DeliveryAndOrdersView = ({ props }) => {
  const {
    classes,
    t,
    translateDays,
    translateFields,
    myStyle,
    state,
    setState,
    modules,
    setModules,
    isLoading,
    handleRatingTimeChange,
    returnTableCell,
    handleRatingDayChange,
    descriptionDays,
    handleModuleUpdate,
    handleDays,
    handleInputChange,
    handleSubmit,
  } = props;
  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <SlotsSettings
            t={t}
            daysOfWeek={daysOfWeek}
            dayFields={dayFields}
            classes={classes}
            state={state}
            handleRatingTimeChange={handleRatingTimeChange}
            descriptionDays={descriptionDays}
            handleRatingDayChange={handleRatingDayChange}
            handleModuleUpdate={handleModuleUpdate}
            modules={modules}
            translateDays={translateDays}
            translateFields={translateFields}
            myStyle={myStyle}
            setState={setState}
            returnTableCell={returnTableCell}
            handleInputChange={handleInputChange}
          />

          <OrderLengthSettings
            t={t}
            classes={classes}
            state={state}
            setState={setState}
            setModules={setModules}
            modules={modules}
            handleDays={handleDays}
            handleInputChange={handleInputChange}
          />

          <OrderLengthPeriodSettings
            t={t}
            classes={classes}
            modules={modules}
            handleModuleUpdate={handleModuleUpdate}
          />

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default DeliveryAndOrdersView;

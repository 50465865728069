import React from 'react';
import ordersColumnConfig from './ordersColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ORDER } from 'helpers/roles';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import History from '@material-ui/icons/History';
import LogView from 'components/History/LogView';
import InfoModal from 'components/InfoModal/InfoModal';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class Orders extends React.Component {
  newOrder = () =>
    this.props.history.push(`/admin/orders/add?userId=${this.props.userId}`);

  constructor(props) {
    super(props);
    this.grid = React.createRef();
  }

  state = {
    orderHistoryId: null,
    modalContent: '',
    isModalOpened: false,
  };

  render() {
    const { t } = this.props;
    const customActions = row => {
      return [
        {
          action: props => window.open(props.row.invoicePDF, '_blank'),
          icon: row.invoicePDF ? <VerticalAlignBottom /> : false,
          disabled: row.invoicePDF === null,
          color: row.invoicePDF ? 'primary' : 'tumblr',
          buttonText:
            row.invoicePDF !== null
              ? t('orders.downloadInvoice', 'Pobierz fakturę')
              : t('orders.noInvoice', 'Brak faktury'),
          simple: true,
          round: false,
          style: {
            width: 'max-content',
          },
        },
        {
          action: props => this.setState({ orderHistoryId: props.row['@id'] }),
          icon: <History />,
          disabled: false,
          color: 'warning',
          buttonText: t('orders.orderHistory', 'Historia zamówienia'),
          simple: true,
          round: false,
          style: {
            width: 'max-content',
          },
        },
      ];
    };

    return (
      <div>
        {isGranted(ROLE_CREATE_ORDER) && (
          <FormControlButtons
            classes={this.props.classes}
            handleSubmit={() => this.newOrder()}
            submitText={t('clients.newOrder')}
          />
        )}
        <InfoModal
          modalState={this.state.isModalOpened}
          modalContent={this.state.modalContent}
          closeButtonText={t('form.close', 'Zamknij')}
          diets={this.props.diets}
          variants={this.props.variants}
          handleClose={isModalOpened => this.setState({ isModalOpened })}
        />
        <DataGrid
          ref={this.grid}
          refreshOnFetch
          actions={true}
          paginationTop={true}
          paginationBottom={false}
          minRows={18}
          url="/orders"
          reportName={'order'}
          role={'ORDER'}
          columns={ordersColumnConfig(this.grid, modalContent =>
            this.setState({ modalContent, isModalOpened: true })
          )}
          customActions={customActions}
          customActionsColumnDirection=""
          customActionsWidth={200}
          manipulateQuery={(requestData, query) => {
            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;
              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }
            if (query.priceAfterDiscount) {
              const filters = query.priceAfterDiscount;
              delete query.priceAfterDiscount;
              if (filters.valueFrom) {
                query['priceAfterDiscount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['priceAfterDiscount[lte]'] = filters.valueTo;
              }
            }

            query['client.id[]'] = this.props.userId;

            return query;
          }}
        />
        <InfoModal
          modalState={this.state.orderHistoryId}
          handleClose={() => this.setState({ orderHistoryId: null })}
          modalContent={
            this.state.orderHistoryId && (
              <LogView iri={this.state.orderHistoryId} historyOpenDefault />
            )
          }
          closeButtonText={t('form.close', 'Zamknij')}
        />
      </div>
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(Orders);

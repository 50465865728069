const adobeFonts = [
  { label: '210 Byulbitcha', value: '210-byulbitcha' },
  { label: '210 Computersetak', value: '210-computersetak' },
  { label: '210 Dongwhacaek', value: '210-dongwhacaek' },
  { label: '210 Everybody', value: '210-everybody' },
  { label: '210 Gulim', value: '210-gulim' },
  { label: '210 Hayanbaram', value: '210-hayanbaram' },
  { label: '210 Jamak', value: '210-jamak' },
  { label: '210 Misslee', value: '210-misslee' },
  { label: '210 Ohneuleun', value: '210-ohneuleun' },
  { label: '210 OmniGothic', value: '210-omnigothic' },
  { label: '210 Pyeonghwa', value: '210-pyeonghwa' },
  { label: '210 Ssanai', value: '210-ssanai' },
  { label: '210 Supersize', value: '210-supersize' },
  { label: '210 Yeonaesidae', value: '210-yeonaesidae' },
  { label: '210 Yeonpilsketch', value: '210-yeonpilsketch' },
  { label: 'A-OTF Futo Go B101 Pr6N', value: 'a-otf-futo-go-b101-pr6n' },
  { label: 'A-OTF Futo Min A101 Pr6N', value: 'a-otf-futo-min-a101-pr6n' },
  { label: 'A-OTF Gothic BBB Pr6N', value: 'a-otf-gothic-bbb-pr6n' },
  { label: 'A-OTF Jun Pro', value: 'a-otf-jun-pro' },
  { label: 'A-OTF Midashi Go MB31 Pr6N', value: 'a-otf-midashi-go-mb31-pr6n' },
  { label: 'A-OTF Midashi Min MA31 Pr6N', value: 'a-otf-midashi-mi-ma31-pr6n' },
  { label: 'A-OTF Ryumin Pr6N', value: 'a-otf-ryumin-pr6n' },
  { label: 'A-OTF UD Reimin Pr6N', value: 'a-otf-ud-reimin-pr6n' },
  { label: 'A-OTF UD Shin Go Pr6N', value: 'a-otf-ud-shin-go-pr6n' },
  { label: 'A-OTF UD Shin Maru Go Pr6N', value: 'a-otf-ud-shin-maru-go-pr6n' },
  { label: 'Aaux Next', value: 'aaux-next' },
  { label: 'AB Ajimin Chiku C/EB', value: 'abajimin-chikuceb' },
  { label: 'AB Ajimin Chiku/EB', value: 'abajimin-chikueb' },
  { label: 'AB Ajimin Gyo/EB', value: 'abajimin-gyoeb' },
  { label: 'AB Ajimin Ko/EB', value: 'abajimin-koheb' },
  { label: 'AB Ajimin Min/EB', value: 'abajimin-mineb' },
  { label: 'AB Ajimin Modern Chiku C/EB', value: 'abajiminm-chikuceb' },
  { label: 'AB Ajimin Modern Chiku/EB', value: 'abajiminm-chikueb' },
  { label: 'AB Ajimin Modern Gyo/EB', value: 'abajiminm-gyoeb' },
  { label: 'AB Ajimin Modern Ko/EB', value: 'abajiminm-koheb' },
  { label: 'AB Ajimin Modern Min/EB', value: 'abajiminm-mineb' },
  { label: 'AB Ajimin Modern Ryo/EB', value: 'abajiminm-ryoeb' },
  { label: 'AB Ajimin Modern So/EB', value: 'abajiminm-soeb' },
  { label: 'AB Ajimin Modern Syu L/EB', value: 'abajiminm-syuleb' },
  { label: 'AB Ajimin Modern Syu V/EB', value: 'abajiminm-syuveb' },
  { label: 'AB Ajimin Modern Syu/EB', value: 'abajiminm-syueb' },
  { label: 'AB Ajimin Ryo/EB', value: 'abajimin-ryoeb' },
  { label: 'AB Ajimin So/EB', value: 'abajimin-soeb' },
  { label: 'AB Ajimin Syu L/EB', value: 'abajimin-syuleb' },
  { label: 'AB Ajimin Syu V/EB', value: 'abajimin-syuveb' },
  { label: 'AB Ajimin Syu/EB', value: 'abajimin-syueb' },
  { label: 'AB Babywalk', value: 'ab-babywalk' },
  { label: 'AB Booing', value: 'ab-booing' },
  { label: 'AB Countryroad', value: 'ab-countryroad' },
  { label: 'AB Digicomb', value: 'ab-digicomb' },
  { label: 'AB Donmai', value: 'ab-donmai' },
  { label: 'AB Doramin', value: 'ab-doramin' },
  { label: 'AB Gagaku B', value: 'ab-gagaku-b' },
  { label: 'AB Hasefude', value: 'ab-hasefude' },
  { label: 'AB Hasemomo R', value: 'ab-hasemomo-r' },
  { label: 'AB Intore', value: 'ab-intore' },
  { label: 'AB Itaikoku', value: 'ab-itaikoku' },
  { label: 'AB Karuta Bold', value: 'ab-karuta-bold' },
  { label: 'AB Kesera', value: 'ab-kesera' },
  { label: 'AB Kikori', value: 'ab-kikori' },
  { label: 'AB Kiraku L', value: 'ab-kiraku-l' },
  { label: 'AB Kirigirisu', value: 'ab-kirigirisu' },
  { label: 'AB Kokoro No2', value: 'ab-kokoro-no2' },
  { label: 'AB Kotatsu', value: 'ab-kotatsu' },
  { label: 'AB Kotodama U', value: 'ab-kotodama-u' },
  { label: 'AB Kotsubu', value: 'ab-kotsubu' },
  { label: 'AB Kumiki B', value: 'ab-kumiki-b' },
  { label: 'AB Marusan', value: 'ab-marusan' },
  { label: 'AB Mayuminwalk', value: 'ab-mayuminwalk' },
  { label: 'AB Nara', value: 'ab-nara' },
  { label: 'AB Neuron', value: 'ab-neuron' },
  { label: 'AB Omusubi', value: 'ab-omusubi' },
  { label: 'AB Plus', value: 'ab-plus' },
  { label: 'AB Polcadot', value: 'ab-polcadot' },
  { label: 'AB Quadra', value: 'ab-quadra' },
  { label: 'AB Roman', value: 'ab-roman' },
  { label: 'AB Ryusen Fuyu', value: 'ab-ryusen-fuyu' },
  { label: 'AB Sanpobito', value: 'ab-sanpobito' },
  { label: 'AB Sekka', value: 'ab-sekka' },
  { label: 'AB Shiguma', value: 'ab-shiguma' },
  { label: 'AB Shoutenmaru', value: 'ab-shoutenmaru' },
  { label: 'AB Suzume', value: 'ab-suzume' },
  { label: 'AB Tanteidan', value: 'ab-tanteidan' },
  { label: 'AB Tsurumaru', value: 'ab-tsurumaru' },
  { label: 'AB Tyuusyobokunenn', value: 'ab-tyuusyobokunenn' },
  { label: 'AB Yoshienoryokan B', value: 'ab-yoshienoryokan-b' },
  { label: 'AB Yoshienoryokan DB', value: 'ab-yoshienoryokan-db' },
  { label: 'AB Yoshienoryokan M', value: 'ab-yoshienoryokan-m' },
  { label: 'AB Yurumin', value: 'ab-yurumin' },
  { label: 'Abadi', value: 'abadi' },
  { label: 'Abigail', value: 'abigail' },
  { label: 'Abolition', value: 'abolition' },
  { label: 'Abril', value: 'abril' },
  { label: 'Abril Titling', value: 'abril-titling' },
  { label: 'Acier BAT', value: 'acier-bat' },
  { label: 'Acme Gothic', value: 'acme-gothic' },
  { label: 'AcroterionJF', value: 'acroterionjf' },
  { label: 'Active', value: 'active' },
  { label: 'Acumin', value: 'acumin' },
  { label: 'Acuta', value: 'acuta' },
  { label: 'Adage Script JF', value: 'adage-script-jf' },
  { label: 'Adaptive Mono', value: 'adaptive-mono' },
  { label: 'Address Sans', value: 'address-sans' },
  { label: 'Adelle', value: 'adelle' },
  { label: 'Adelle Sans', value: 'adelle-sans' },
  { label: 'Adelle Sans Arabic', value: 'adelle-sans-arabic' },
  { label: 'Adhesive Nr. Seven', value: 'adhesive-nr-seven' },
  { label: 'Adobe Arabic', value: 'adobe-arabic' },
  { label: 'Adobe Bengali', value: 'adobe-bengali' },
  { label: 'Adobe Caslon', value: 'adobe-caslon' },
  { label: 'Adobe Devanagari', value: 'adobe-devanagari' },
  { label: 'Adobe Fan Heiti', value: 'adobe-fan-heiti' },
  { label: 'Adobe Fangsong', value: 'adobe-fangsong' },
  { label: 'Adobe Garamond', value: 'adobe-garamond' },
  { label: 'Adobe Gothic', value: 'adobe-gothic' },
  { label: 'Adobe Gujarati', value: 'adobe-gujarati' },
  { label: 'Adobe Gurmukhi', value: 'adobe-gurmukhi' },
  { label: 'Adobe Handwriting', value: 'adobe-handwriting' },
  { label: 'Adobe Hebrew', value: 'adobe-hebrew' },
  { label: 'Adobe Heiti', value: 'adobe-heiti' },
  { label: 'Adobe Jenson', value: 'adobe-jenson' },
  { label: 'Adobe Kaiti', value: 'adobe-kaiti' },
  { label: 'Adobe Kannada', value: 'adobe-kannada' },
  { label: 'Adobe Ming', value: 'adobe-ming' },
  { label: 'Adobe Myungjo', value: 'adobe-myungjo' },
  { label: 'Adobe Naskh', value: 'adobe-naskh' },
  { label: 'Adobe Song', value: 'adobe-song' },
  { label: 'Adobe Tamil', value: 'adobe-tamil' },
  { label: 'Adobe Telugu', value: 'adobe-telugu' },
  { label: 'Adobe Text', value: 'adobe-text' },
  { label: 'Adobe Thai', value: 'adobe-thai' },
  { label: 'Adonis', value: 'adonis' },
  { label: 'Adore You', value: 'adore-you' },
  { label: 'Adorn', value: 'adorn' },
  { label: 'Adorn Engraved', value: 'adorn-engraved' },
  { label: 'Adorn Ornaments', value: 'adorn-ornaments' },
  { label: 'Adorn Sans', value: 'adorn-sans' },
  { label: 'Adorn Serif', value: 'adorn-serif' },
  { label: 'Adorn Slab Serif', value: 'adorn-slab-serif' },
  { label: 'Adorn Smooth', value: 'adorn-smooth' },
  { label: 'Adriane Text', value: 'adriane-text' },
  { label: 'Adrianna', value: 'adrianna' },
  { label: 'Adso', value: 'adso' },
  { label: 'Adventure', value: 'adventure' },
  { label: 'Aesthet Nova', value: 'aesthet-nova' },
  { label: 'AG Ahnsangsoo 2012', value: 'ag-ahnsangsoo-2012' },
  { label: 'AG Choijeongho Screen', value: 'ag-choijeongho-screen' },
  { label: 'AG Namsangjun', value: 'ag-namsangjun' },
  { label: 'Agenda', value: 'agenda' },
  { label: 'Aglet Mono', value: 'aglet-mono' },
  { label: 'Aglet Sans', value: 'aglet-sans' },
  { label: 'Aglet Slab', value: 'aglet-slab' },
  { label: 'Aileron', value: 'aileron' },
  { label: 'Ainsdale', value: 'ainsdale' },
  { label: 'Ainslie Sans', value: 'ainslie-sans' },
  { label: 'Airy', value: 'airy' },
  { label: 'Aisha', value: 'aisha' },
  { label: 'Akagi', value: 'akagi' },
  { label: 'Aktiv Grotesk', value: 'aktiv-grotesk' },
  { label: 'Aktiv Grotesk Arabic', value: 'aktiv-grotesk-arabic' },
  { label: 'Aktiv Grotesk Devanagari', value: 'aktiv-grotesk-devanagari' },
  { label: 'Aktiv Grotesk Hebrew', value: 'aktiv-grotesk-hebrew' },
  { label: 'Aktiv Grotesk Thai', value: 'aktiv-grotesk-thai' },
  { label: 'Al Fresco', value: 'al-fresco' },
  { label: 'Alana', value: 'alana' },
  { label: 'Alana Smooth', value: 'alana-smooth' },
  { label: 'Alber New', value: 'alber-new' },
  { label: 'Albertan', value: 'albertan' },
  { label: 'Albiona', value: 'albiona' },
  { label: 'Alda', value: 'alda' },
  { label: 'Alegreya', value: 'alegreya' },
  { label: 'Alegreya Sans', value: 'alegreya-sans' },
  { label: 'Alex Brush', value: 'alex-brush' },
  { label: 'Alexa', value: 'alexa' },
  { label: 'Alexander Quill', value: 'rtf-alexander-quill' },
  { label: 'Alga', value: 'alga' },
  { label: 'Alhambra', value: 'alhambra' },
  { label: 'Alibaba PuHuiTi', value: 'alibaba-puhuiti' },
  { label: 'Alibaba Sans', value: 'alibaba-sans' },
  { label: 'Alisha', value: 'alisha' },
  { label: 'Alize', value: 'alize' },
  { label: 'All Round Gothic', value: 'all-round-gothic' },
  { label: 'Aller', value: 'aller' },
  { label: 'Aller Display', value: 'aller-display' },
  { label: 'Allotrope', value: 'allotrope' },
  { label: 'Allumi', value: 'allumi' },
  { label: 'Allura', value: 'allura' },
  { label: 'Almaq', value: 'almaq' },
  { label: 'Alphatier', value: 'alphatier' },
  { label: 'Alpine Script', value: 'alpine-script' },
  { label: 'Alta California', value: 'alta-california' },
  { label: 'Alternate Gothic', value: 'alternate-gothic' },
  { label: 'Altivo', value: 'altivo' },
  { label: 'Alverata', value: 'alverata' },
  { label: 'Alwyn New', value: 'alwyn-new' },
  { label: 'Amador', value: 'amador' },
  { label: 'Amaranth', value: 'amaranth' },
  { label: 'Amatic', value: 'amatic' },
  { label: 'Amboy', value: 'amboy' },
  { label: 'Ambroise', value: 'ambroise' },
  { label: 'American Scribe', value: 'american-scribe' },
  { label: 'Americane', value: 'americane' },
  { label: 'Amoretta', value: 'amoretta' },
  { label: 'Anago', value: 'anago' },
  { label: 'Anaqa', value: 'anaqa' },
  { label: 'Anca', value: 'anca' },
  { label: 'Angie Sans', value: 'angie-sans' },
  { label: 'Anisette', value: 'anisette' },
  { label: 'Aniuk', value: 'aniuk' },
  { label: 'Anivers', value: 'anivers' },
  { label: 'Anjali Old Lipi', value: 'anjali-old-lipi' },
  { label: 'Annabelle', value: 'annabelle' },
  { label: 'Annabelle JF', value: 'annabelle-jf' },
  { label: 'Anonymous', value: 'anonymous' },
  { label: 'Antarctican', value: 'antarctican' },
  { label: 'Antiquarian', value: 'antiquarian' },
  { label: 'Antiquarian Scribe', value: 'antiquarian-scribe' },
  { label: 'Antique Olive', value: 'antique-olive' },
  { label: 'Anton', value: 'anton' },
  { label: 'Anugrom', value: 'anugrom' },
  { label: 'Anzeigen Grotesk', value: 'anzeigen-grotesk' },
  { label: 'Anziano', value: 'anziano' },
  { label: 'Apertura', value: 'apertura' },
  { label: 'Aphrosine', value: 'aphrosine' },
  { label: 'Apolline', value: 'apolline' },
  { label: 'Apotek', value: 'apotek' },
  { label: 'AR FangXinShuH7B5Std', value: 'ar-fangxinshuh7b5std' },
  { label: 'AR FangXinShuH7GBStd', value: 'ar-fangxinshuh7gbstd' },
  { label: 'AR MingB5Std', value: 'ar-mingb5std' },
  { label: 'AR ShuLinSongGBStd', value: 'ar-shulinsonggbstd' },
  { label: 'AR UDJingXiHeiB5Pro', value: 'ar-udjingxiheib5pro' },
  { label: 'AR UDJingXiHeiGBStd', value: 'ar-udjingxiheigbstd' },
  { label: 'AR WeiBeiB5Std', value: 'ar-weibeib5std' },
  { label: 'AR WeiBeiGBStd', value: 'ar-weibeigbstd' },
  { label: 'AR YuanB5Std', value: 'ar-yuanb5std' },
  { label: 'AR YuanGBStd ', value: 'ar-yuangbstd' },
  { label: 'Arbitrary', value: 'arbitrary' },
  { label: 'Arboria', value: 'arboria' },
  { label: 'Arbotek', value: 'arbotek' },
  { label: 'Archetype', value: 'archetype' },
  { label: 'Archivo Black', value: 'archivo-black' },
  { label: 'Ardoise', value: 'ardoise' },
  { label: 'Arek', value: 'arek' },
  { label: 'Arek Armenian', value: 'arek-armenian' },
  { label: 'Argumentum', value: 'argumentum' },
  { label: 'Aria Text', value: 'aria-text' },
  { label: 'Ariana', value: 'ariana' },
  { label: 'Arimo', value: 'arimo' },
  { label: 'Arlette', value: 'arlette' },
  { label: 'Arlette Thai', value: 'arlette-thai' },
  { label: 'Armada', value: 'armada' },
  { label: 'Armstrong', value: 'armstrong' },
  { label: 'Arno', value: 'arno' },
  { label: 'Arpona', value: 'arpona' },
  { label: 'Arpona Sans', value: 'arpona-sans' },
  { label: 'Array', value: 'array' },
  { label: 'Artigo', value: 'artigo' },
  { label: 'Artigo Display', value: 'artigo-display' },
  { label: 'Arvo', value: 'arvo' },
  { label: 'Arya', value: 'arya' },
  { label: 'Arzachel', value: 'arzachel' },
  { label: 'Asap', value: 'asap' },
  { label: 'Ashemore', value: 'ashemore' },
  { label: 'AsimovSans', value: 'asimovsans' },
  { label: 'Asphalt', value: 'asphalt' },
  { label: 'Astoria', value: 'astoria' },
  { label: 'Astoria Sans', value: 'astoria-sans' },
  { label: 'Astounder Squared BB', value: 'astounder-squared-bb' },
  { label: 'ATF Alternate Gothic', value: 'atf-alternate-gothic' },
  {
    label: 'ATF Alternate Gothic Compressed',
    value: 'atf-alternate-gothic-compressed',
  },
  {
    label: 'ATF Alternate Gothic Condensed',
    value: 'atf-alternate-gothic-condensed',
  },
  {
    label: 'ATF Alternate Gothic Extra Condensed',
    value: 'atf-alternate-gothic-extra-condensed',
  },
  { label: 'ATF Brush', value: 'atf-brush' },
  { label: 'ATF Franklin Gothic', value: 'atf-franklin-gothic' },
  { label: 'ATF Garamond Micro', value: 'atf-garamond-micro' },
  { label: 'ATF Garamond Subhead', value: 'atf-garamond-subhead' },
  { label: 'ATF Garamond Text', value: 'atf-garamond-text' },
  { label: 'ATF Headline Gothic', value: 'atf-headline-gothic' },
  { label: 'ATF Livermore Script', value: 'atf-livermore-script' },
  { label: 'ATF Poster Gothic ', value: 'atf-poster-gothic' },
  {
    label: 'ATF Poster Gothic Condensed',
    value: 'atf-poster-gothic-condensed',
  },
  {
    label: 'ATF Poster Gothic Extra Condensed',
    value: 'atf-poster-gothic-extra-condensed',
  },
  { label: 'ATF Poster Gothic Round', value: 'atf-poster-gothic-round' },
  {
    label: 'ATF Poster Gothic Round Condensed',
    value: 'atf-poster-gothic-round-condensed',
  },
  {
    label: 'ATF Poster Gothic Round Extra Condensed',
    value: 'atf-poster-gothic-round-extra-condensed',
  },
  { label: 'ATF Railroad Gothic', value: 'atf-railroad-gothic' },
  { label: 'ATF Wedding Gothic', value: 'atf-wedding-gothic' },
  { label: 'ATF Wedding Gothic Wide', value: 'atf-wedding-gothic-wide' },
  { label: 'Athelas', value: 'athelas' },
  { label: 'Athelas Arabic', value: 'athelas-arabic' },
  { label: 'Atocha', value: 'atocha' },
  { label: 'Atrament', value: 'atrament' },
  { label: 'Attic Antique', value: 'attic-antique' },
  { label: 'Aurea Ultra', value: 'aurea-ultra' },
  { label: 'Auster', value: 'auster' },
  { label: 'Austin Pen', value: 'austin-pen' },
  { label: 'Auto', value: 'auto' },
  { label: 'Automate', value: 'automate' },
  { label: 'Avalanche', value: 'avalanche' },
  { label: 'Avalon', value: 'avalon' },
  { label: 'Avebury', value: 'avebury' },
  { label: 'Aviano', value: 'aviano' },
  { label: 'Aviano Didone', value: 'aviano-didone' },
  { label: 'Aviano Flare', value: 'aviano-flare' },
  { label: 'Aviano Future', value: 'aviano-future' },
  { label: 'Aviano Royale', value: 'aviano-royale' },
  { label: 'Aviano Sans', value: 'aviano-sans' },
  { label: 'Aviano Serif', value: 'aviano-serif' },
  { label: 'Aviano Slab', value: 'aviano-slab' },
  { label: 'Aviano Wedge', value: 'aviano-wedge' },
  { label: 'Avory', value: 'avory' },
  { label: 'AW Conqueror Carved', value: 'aw-conqueror-carved' },
  { label: 'AW Conqueror Didot', value: 'aw-conqueror-didot' },
  { label: 'AW Conqueror Inline', value: 'aw-conqueror-inline' },
  { label: 'AW Conqueror Sans', value: 'aw-conqueror' },
  { label: 'AW Conqueror Slab', value: 'aw-conqueror-slab' },
  { label: 'AwanZaman', value: 'awanzaman' },
  { label: 'Axia', value: 'axia' },
  { label: 'Aya Script', value: 'aya-script' },
  { label: 'Azo Sans', value: 'azo-sans' },
  { label: 'Azote', value: 'azote' },
  { label: 'Backspacer', value: 'backspacer' },
  { label: 'BadaBoom', value: 'badaboom' },
  { label: 'BadDog', value: 'baddog' },
  { label: 'BadTyp', value: 'badtyp' },
  { label: 'Bagatela', value: 'bagatela' },
  { label: 'Baileywick JF Gothic', value: 'baileywick-jf-gothic' },
  { label: 'Baker Signet', value: 'baker-signet' },
  { label: 'Balboa', value: 'balboa' },
  { label: 'Balboa Plus', value: 'balboa-plus' },
  { label: 'Ballare', value: 'ballare' },
  { label: 'Ballers Delight', value: 'ballers-delight' },
  { label: 'Ballinger', value: 'ballinger' },
  { label: 'Ballinger Condensed', value: 'ballinger-condensed' },
  { label: 'Ballinger Mono', value: 'ballinger-mono' },
  { label: 'Ballinger X-Condensed', value: 'ballinger-x-condensed' },
  { label: 'Balloon URW', value: 'balloon-urw' },
  { label: 'Baltica', value: 'baltica' },
  { label: 'Balzano', value: 'balzano' },
  { label: 'Banana', value: 'banana' },
  { label: 'Banshee', value: 'banshee' },
  { label: 'Barbieri', value: 'barbieri' },
  { label: 'Barge', value: 'barge' },
  { label: 'Baroque Text JF', value: 'baroque-text-jf' },
  { label: 'Barricada', value: 'barricada' },
  { label: 'Barteldes', value: 'barteldes' },
  { label: 'Base 9 & 12', value: 'base-9-12' },
  { label: 'Base 900', value: 'base-900' },
  { label: 'Base Mono', value: 'base-mono' },
  { label: 'Basel Neue', value: 'basel-neue' },
  { label: 'Basic Sans', value: 'basic-sans' },
  { label: 'Baskerville PT', value: 'baskerville-pt' },
  { label: 'Baskerville URW', value: 'baskerville-urw' },
  { label: 'Battery Park', value: 'battery-park' },
  { label: 'Battlefin', value: 'battlefin' },
  { label: 'Battleslab', value: 'battleslab' },
  { label: 'BattleStation', value: 'battlestation' },
  { label: 'Baucher Gothic URW', value: 'baucher-gothic-urw' },
  { label: 'BC Alphapipe', value: 'bc-alphapipe' },
  { label: 'BC Liguria', value: 'bc-liguria' },
  { label: 'BC Mikser', value: 'bc-mikser' },
  { label: 'BC Novatica', value: 'bc-novatica' },
  { label: 'BC Rebecca', value: 'bc-rebecca' },
  { label: 'BC Sklonar', value: 'bc-sklonar' },
  { label: 'BD Brick', value: 'bd-brick' },
  { label: 'BD Colonius', value: 'bd-colonius' },
  { label: 'BD Geminis', value: 'bd-geminis' },
  { label: 'BD Jupiter', value: 'bd-jupiter' },
  { label: 'BD Kickrom Mono', value: 'bd-kickrom-mono' },
  { label: 'BD Micron Font', value: 'bd-micron-font' },
  { label: 'BD Nippori', value: 'bd-nippori' },
  { label: 'BD Plakatbau', value: 'bd-plakatbau' },
  { label: 'BD Retrocentric', value: 'bd-retrocentric' },
  { label: 'BD Viewmaster', value: 'bd-viewmaster' },
  { label: 'BDR A3MIK', value: 'bdr-a3mik' },
  { label: 'BDR Mono', value: 'bdr-mono' },
  { label: 'Beastly', value: 'beastly' },
  { label: 'Bebas Kai', value: 'bebas-kai' },
  { label: 'Bebas Neue', value: 'bebas-neue' },
  {
    label: 'Bebas Neue by Fontfabric (based on v1.4)',
    value: 'bebas-neue-by-fontfabric-based-on-v14',
  },
  { label: 'Bebas Neue Pro', value: 'bebas-neue-pro' },
  { label: 'Bebas Neue Rounded', value: 'bebas-neue-rounded' },
  { label: 'Bebas Neue SemiRounded', value: 'bebas-neue-semirounded' },
  { label: 'Bebas Neue v1.4 (deprecated)', value: 'bebas-neue-v14-deprecated' },
  { label: 'Bee', value: 'bee' },
  { label: 'Belda', value: 'belda' },
  { label: 'Bell Centennial Std', value: 'bell-centennial-std' },
  { label: 'Bello', value: 'bello' },
  { label: 'Beloved', value: 'beloved' },
  { label: 'Bely', value: 'bely' },
  { label: 'Bennet Banner', value: 'bennet-banner' },
  { label: 'Bennet Display', value: 'bennet-display' },
  { label: 'Bennet Text', value: 'bennet-text' },
  { label: 'Bernhard Gothic URW', value: 'bernhard-gothic-urw' },
  { label: 'Bickham Script', value: 'bickham-script' },
  { label: 'Bicyclette', value: 'bicyclette' },
  { label: 'Big Cheese', value: 'big-cheese' },
  { label: 'Big City Grotesque', value: 'big-city-grotesque' },
  { label: 'Bigfish', value: 'bigfish' },
  { label: 'BigSmalls', value: 'bigsmalls' },
  { label: 'Bilo', value: 'bilo' },
  { label: 'Bio Sans', value: 'bio-sans' },
  { label: 'Birch', value: 'birch' },
  { label: 'Birra', value: 'birra' },
  { label: 'Bistro Script', value: 'bistro-script' },
  { label: 'Bitter', value: 'bitter' },
  { label: 'Black Han Sans', value: 'black-han-sans' },
  { label: 'BlackBeard', value: 'blackbeard' },
  { label: 'Blackcurrant', value: 'blackcurrant' },
  { label: 'Blackoak', value: 'blackoak' },
  { label: 'Blakely', value: 'blakely' },
  { label: 'Blambot', value: 'blambot' },
  { label: 'Blambot Casual', value: 'blambot-casual' },
  { label: 'Blambot FX', value: 'blambot-fx' },
  { label: 'Blazeface Hangeul', value: 'blazeface-hangeul' },
  { label: 'Blenny', value: 'blenny' },
  { label: 'Blenny Thai', value: 'blenny-thai' },
  { label: 'Bligh', value: 'bligh' },
  { label: 'Blockhead', value: 'blockhead' },
  { label: 'Blockhead Illustrations', value: 'blockhead-illustrations' },
  { label: 'Blonde Fraktur', value: 'blonde-fraktur' },
  { label: 'Blow Up', value: 'blow-up' },
  { label: 'Blue Goblet', value: 'blue-goblet' },
  {
    label: 'Bluemlein Script Collection',
    value: 'bluemlein-script-collection',
  },
  { label: 'Bodega Sans', value: 'bodega-sans' },
  { label: 'Bodega Serif', value: 'bodega-serif' },
  { label: 'Bodoni URW', value: 'bodoni-urw' },
  { label: 'BokutohRera', value: 'bokutohrera' },
  { label: 'BokutohRuika', value: 'bokutohruika' },
  { label: 'Bombarda', value: 'bombarda' },
  { label: 'Bookeyed', value: 'bookeyed' },
  { label: 'Bookman JF', value: 'bookman-jf' },
  { label: 'Bookmania', value: 'bookmania' },
  { label: 'Boreal', value: 'boreal' },
  { label: 'Botanika', value: 'botanika' },
  { label: 'Botanika Mono', value: 'botanika-mono' },
  { label: 'Boucherie Block', value: 'boucherie-block' },
  { label: 'Boucherie Cursive', value: 'boucherie-cursive' },
  { label: 'Boucherie Flared', value: 'boucherie-flared' },
  { label: 'Boucherie Sans', value: 'boucherie-sans' },
  { label: 'Bouffant', value: 'bouffant' },
  { label: 'Bounce Script', value: 'bounce-script' },
  { label: 'Bourbon', value: 'bourbon' },
  { label: 'Bowman', value: 'bowman' },
  { label: 'Braisetto', value: 'braisetto' },
  { label: 'Brandon Grotesque', value: 'brandon-grotesque' },
  { label: 'Breakers', value: 'breakers' },
  { label: 'Breakers Slab', value: 'breakers-slab' },
  { label: 'Bree', value: 'bree' },
  { label: 'Bree Serif', value: 'bree-serif' },
  { label: 'Bremen', value: 'bremen' },
  { label: 'Bressay', value: 'bressay' },
  { label: 'Bressay Arabic', value: 'bressay-arabic' },
  { label: 'Brevia', value: 'brevia' },
  { label: 'Brevier', value: 'brevier' },
  { label: 'Brigade', value: 'brigade' },
  { label: 'Briller', value: 'briller' },
  { label: 'Brim Narrow', value: 'brim-narrow' },
  { label: 'Brim Narrow Combined', value: 'brim-narrow-combined' },
  { label: 'Brioso', value: 'brioso' },
  { label: 'Brix Slab', value: 'brix-slab' },
  { label: 'Broadacre', value: 'broadacre' },
  { label: 'Broadsheet', value: 'broadsheet' },
  { label: 'Bronson Gothic JF', value: 'bronson-gothic-jf' },
  { label: 'Brother 1816', value: 'brother-1816' },
  { label: 'Brother XS&XL', value: 'brother-xsxl' },
  { label: 'Brothers', value: 'brothers' },
  { label: 'Brush Script', value: 'brush-script' },
  { label: 'Brushland', value: 'brushland' },
  { label: 'Bubblegum Pop', value: 'bubblegum-pop' },
  { label: 'Buckboard', value: 'buckboard' },
  { label: 'Buckley', value: 'buckley' },
  { label: 'Buena Park JF', value: 'buena-park-jf' },
  { label: 'Bufalino', value: 'bufalino' },
  { label: 'Buffet Script', value: 'buffet-script' },
  { label: 'Bungee', value: 'bungee' },
  { label: 'Bunyan', value: 'bunyan' },
  { label: 'Burnaby', value: 'burnaby' },
  { label: 'Byker', value: 'byker' },
  { label: 'Cabazon', value: 'cabazon' },
  { label: 'Cabrito', value: 'cabrito' },
  { label: 'Cabrito Didone', value: 'cabrito-didone' },
  { label: 'Cabrito Sans', value: 'cabrito-sans' },
  { label: 'Cadogan', value: 'cadogan' },
  { label: 'Cafeteria', value: 'cafeteria' },
  { label: 'Caflisch Script', value: 'caflisch-script' },
  { label: 'Cairo', value: 'cairo' },
  { label: 'Calder', value: 'calder' },
  { label: 'Calendula', value: 'calendula' },
  { label: 'Calgary Script', value: 'calgary-script' },
  { label: 'Caliban', value: 'caliban' },
  { label: 'Calibri', value: 'calibri' },
  { label: 'Calling Code', value: 'calling-code' },
  { label: 'Calluna', value: 'calluna' },
  { label: 'Calluna Sans', value: 'calluna-sans' },
  { label: 'Calmetta', value: 'calmetta' },
  { label: 'Campaign', value: 'campaign' },
  { label: 'Campaign Slab', value: 'campaign-slab' },
  { label: 'Cantarell', value: 'cantarell' },
  { label: 'Cantiga', value: 'cantiga' },
  { label: 'Canto', value: 'canto' },
  { label: 'Cantoni', value: 'cantoni' },
  { label: 'Capellina', value: 'capellina' },
  { label: 'Capirera', value: 'capirera' },
  { label: 'Capitol', value: 'capitol' },
  { label: 'Capitolina', value: 'capitolina' },
  { label: 'Capitolium 2', value: 'capitolium-2' },
  { label: 'Caprizant', value: 'caprizant' },
  { label: 'Captain Comic', value: 'captain-comic' },
  { label: 'Capucine', value: 'capucine' },
  { label: 'Carbon', value: 'carbon' },
  { label: 'Cardea', value: 'cardea' },
  { label: 'Cardigan', value: 'cardigan' },
  { label: 'Cardo', value: 'cardo' },
  { label: 'Carilliantine', value: 'carilliantine' },
  { label: 'Carol Gothic', value: 'carol-gothic' },
  { label: 'Carta Marina', value: 'carta-marina' },
  { label: 'Casablanca URW', value: 'casablanca-urw' },
  { label: 'Casey', value: 'casey' },
  { label: 'Catalpa', value: 'catalpa' },
  { label: 'Catamaran', value: 'catamaran' },
  { label: 'Catnip', value: 'catnip' },
  { label: 'Catseye', value: 'catseye' },
  { label: 'Catwing', value: 'catwing' },
  { label: 'CC Biff Bam Boom', value: 'cc-biff-bam-boom' },
  { label: 'CC Clobberin Time', value: 'cc-clobberin-time' },
  { label: 'CC Meanwhile', value: 'cc-meanwhile' },
  { label: 'CC Monster Mash', value: 'cc-monster-mash' },
  { label: 'CC Sign Language', value: 'cc-sign-language' },
  { label: 'Century Gothic', value: 'century-gothic' },
  { label: 'Century Old Style', value: 'century-old-style' },
  { label: 'Chainprinter', value: 'chainprinter' },
  { label: 'Chalfont', value: 'chalfont' },
  { label: 'Chalky', value: 'chalky' },
  { label: 'Chaloops', value: 'chaloops' },
  { label: 'Chandler 42', value: 'chandler-42' },
  { label: 'Changeling Neo', value: 'changeling-neo' },
  { label: 'Chantal', value: 'chantal' },
  { label: 'Chaparral', value: 'chaparral' },
  { label: 'Chapman', value: 'chapman' },
  { label: 'Charbroiled', value: 'charbroiled' },
  { label: 'Charcuterie', value: 'charcuterie' },
  { label: 'Charcuterie Block', value: 'charcuterie-block' },
  { label: 'Charcuterie Contrast', value: 'charcuterie-contrast' },
  { label: 'Charcuterie Cursive', value: 'charcuterie-cursive' },
  { label: 'Charcuterie Ornaments', value: 'charcuterie-ornaments' },
  { label: 'Charcuterie Sans', value: 'charcuterie-sans' },
  { label: 'Charcuterie Serif', value: 'charcuterie-serif' },
  { label: 'Charlemagne', value: 'charlemagne' },
  { label: 'Chauncy', value: 'chauncy' },
  { label: 'Cheap Pine', value: 'cheap-pine' },
  { label: 'Cheee', value: 'cheee' },
  { label: 'Chelsea Market', value: 'chelsea-market' },
  { label: 'Chennai', value: 'chennai' },
  { label: 'Chennai Slab', value: 'chennai-slab' },
  { label: 'Chevin Pro', value: 'chevin-pro' },
  { label: 'Chiavettieri', value: 'chiavettieri' },
  { label: 'Chilanka', value: 'chilanka' },
  { label: 'Chinchilla', value: 'chinchilla' },
  { label: 'Chinese Rocks', value: 'chinese-rocks' },
  { label: 'Chippewa Falls', value: 'chippewa-falls' },
  { label: 'Cholla', value: 'cholla' },
  { label: 'Chowdown', value: 'chowdown' },
  { label: 'Chuck', value: 'chuck' },
  { label: 'Chunder', value: 'chunder' },
  { label: 'Chypre', value: 'chypre' },
  { label: 'Ciabatta', value: 'ciabatta' },
  { label: 'Cinque Donne ', value: 'cinque-donne' },
  { label: 'Circe', value: 'circe' },
  { label: 'Circe Slab', value: 'circe-slab' },
  { label: 'Citadel', value: 'citadel' },
  { label: 'Citizen', value: 'citizen' },
  { label: 'Civane', value: 'civane' },
  { label: 'Clarendon Text', value: 'clarendon-text' },
  { label: 'Clarendon URW', value: 'clarendon-urw' },
  { label: 'Clarendon Wide', value: 'clarendon-wide' },
  { label: 'Classic Comic', value: 'classic-comic' },
  { label: 'Classica', value: 'classica' },
  { label: 'Classico URW', value: 'classico-urw' },
  { label: 'Clavo', value: 'clavo' },
  { label: 'Click Clack', value: 'click-clack' },
  { label: 'Clicker', value: 'clicker' },
  { label: 'Clone Rounded', value: 'clone-rounded' },
  { label: 'Co', value: 'co' },
  { label: 'Co Arabic', value: 'co-arabic' },
  { label: 'Code Saver', value: 'code-saver' },
  { label: 'Coldsmith', value: 'coldsmith' },
  { label: 'Collector Comic', value: 'collector-comic' },
  { label: 'Collier', value: 'collier' },
  { label: 'Colosseum', value: 'colosseum' },
  { label: 'Colt', value: 'colt' },
  { label: 'Colt Soft', value: 'colt-soft' },
  { label: 'Columbia Titling', value: 'columbia-titling' },
  { label: 'Comenia Sans', value: 'comenia-sans' },
  { label: 'Commerce', value: 'commerce' },
  { label: 'Commuters Sans', value: 'commuters-sans' },
  { label: 'Como', value: 'como' },
  { label: 'Compendium', value: 'compendium' },
  { label: 'Concave Tuscan', value: 'concave-tuscan' },
  { label: 'Conceal', value: 'conceal' },
  { label: 'Condor', value: 'condor' },
  { label: 'Congenial', value: 'congenial' },
  { label: 'Conglomerate', value: 'conglomerate' },
  { label: 'Coniferous', value: 'coniferous' },
  { label: 'Continuo', value: 'continuo' },
  { label: 'Coolvetica', value: 'coolvetica' },
  { label: 'Cooper Black', value: 'cooper-black' },
  { label: 'Copacabana', value: 'copacabana' },
  { label: 'Copal', value: 'copal' },
  { label: 'Copperplate', value: 'copperplate' },
  { label: 'Copse', value: 'copse' },
  { label: 'Coquette', value: 'coquette' },
  { label: 'Cora', value: 'cora' },
  { label: 'Coranto 2', value: 'coranto-2' },
  { label: 'Cordale', value: 'cordale' },
  { label: 'Cordale Arabic', value: 'cordale-arabic' },
  { label: 'Cormorant Garamond', value: 'cormorant-garamond' },
  { label: 'CornDog', value: 'corndog' },
  { label: 'Corner Store JF', value: 'corner-store-jf' },
  { label: 'Coronette', value: 'coronette' },
  { label: 'Corporate A', value: 'corporate-a' },
  { label: 'Corporate E', value: 'corporate-e' },
  { label: 'Corporate S', value: 'corporate-s' },
  { label: 'Corpulent', value: 'corpulent' },
  { label: 'Cortado', value: 'cortado' },
  { label: 'Corundum Text', value: 'corundum-text' },
  { label: 'Cosmopolitan', value: 'cosmopolitan' },
  { label: 'Costa', value: 'costa' },
  { label: 'Cotton', value: 'cotton' },
  { label: 'Cottonwood', value: 'cottonwood' },
  { label: 'Council', value: 'council' },
  { label: 'Countach', value: 'countach' },
  { label: 'Courier', value: 'courier' },
  { label: 'Courier Prime', value: 'courier-prime' },
  { label: 'Covik Sans ', value: 'covik-sans' },
  { label: 'Covik Sans Mono', value: 'covik-sans-mono' },
  { label: 'Cowboyslang', value: 'cowboyslang' },
  { label: 'Crackly', value: 'crackly' },
  { label: 'Crassula', value: 'crassula' },
  { label: 'Craw Modern URW', value: 'craw-modern-urw' },
  { label: 'Cresta', value: 'cresta' },
  { label: 'Crete', value: 'crete' },
  { label: 'Critter', value: 'critter' },
  { label: 'Cronos', value: 'cronos' },
  { label: 'Cubano', value: 'cubano' },
  { label: 'Cuisine', value: 'cuisine' },
  { label: 'Curve', value: 'curve' },
  { label: 'Custard', value: 'custard' },
  { label: 'Cy', value: 'cy' },
  { label: 'Dalliance', value: 'dalliance' },
  { label: 'Dapifer', value: 'dapifer' },
  { label: 'Darkmode Off', value: 'darkmode-off' },
  { label: 'Darkmode On', value: 'darkmode-on' },
  { label: 'Davis', value: 'davis' },
  { label: 'Davis Sans', value: 'davis-sans' },
  { label: 'Dazzle Unicase', value: 'dazzle-unicase' },
  { label: 'DDT', value: 'ddt' },
  { label: 'Dead History', value: 'dead-history' },
  { label: 'Dederon', value: 'dederon' },
  { label: 'Dederon Sans', value: 'dederon-sans' },
  { label: 'Dedica', value: 'dedica' },
  { label: 'Default Gothic', value: 'default-gothic' },
  { label: 'Degular', value: 'degular' },
  { label: 'DejaRip', value: 'dejarip' },
  { label: 'Delaney', value: 'delaney' },
  { label: 'Delicato', value: 'delicato' },
  { label: 'DeLittle Chromatic', value: 'delittle-chromatic' },
  { label: 'Delve Hand', value: 'delve-hand' },
  { label: 'Democratica', value: 'democratica' },
  { label: 'Demos Next', value: 'demos-next' },
  { label: 'Depot New', value: 'depot-new' },
  { label: 'Despina', value: 'despina' },
  { label: 'Dessert Script', value: 'dessert-script' },
  { label: 'Deva Ideal', value: 'deva-ideal' },
  { label: 'Dharma Gothic', value: 'dharma-gothic' },
  { label: 'Diavlo', value: 'diavlo' },
  { label: 'Dic Sans', value: 'dicsans' },
  { label: 'Dico Code One', value: 'dico-code-one' },
  { label: 'Dico Code Two', value: 'dico-code-two' },
  { label: 'Dico Mono', value: 'dico-mono' },
  { label: 'Dico Mono Slab', value: 'dico-mono-slab' },
  { label: 'Dico Sans', value: 'dico-sans' },
  { label: 'Dico Sans Soft', value: 'dico-sans-soft' },
  { label: 'Dico Slab', value: 'dico-slab' },
  { label: 'Dico Typewriter', value: 'dico-typewriter' },
  { label: 'Didoni URW', value: 'didoni-urw' },
  { label: 'Dienstag', value: 'dienstag' },
  { label: 'Dimensions', value: 'dimensions' },
  { label: 'DIN 1451 Pro', value: 'din-1451-pro' },
  { label: 'DIN 2014', value: 'din-2014' },
  { label: 'DIN Condensed', value: 'din-condensed' },
  { label: 'DINosaur', value: 'dinosaur' },
  { label: 'Dirtstorm', value: 'dirtstorm' },
  { label: "Dirty Baker's Dozen", value: 'dirty-bakers-dozen' },
  { label: 'Discourse', value: 'discourse' },
  { label: 'District', value: 'district' },
  { label: 'Dita', value: 'dita' },
  { label: 'Ditch', value: 'ditch' },
  { label: 'Divenire', value: 'divenire' },
  { label: 'Divulge', value: 'divulge' },
  { label: 'DNP Shuei 4go Std', value: 'dnp-shuei-4go-std' },
  { label: 'DNP Shuei 4goB Std', value: 'dnp-shuei-4gob-std' },
  { label: 'DNP Shuei Anti Std', value: 'dnp-shuei-anti-std' },
  { label: 'DNP Shuei Gothic Gin Std', value: 'dnp-shuei-gothic-gin-std' },
  { label: 'DNP Shuei Gothic Kin Std', value: 'dnp-shuei-gothic-kin-std' },
  { label: 'DNP Shuei Mgothic Std', value: 'dnp-shuei-mgothic-std' },
  { label: 'DNP Shuei Mincho Pr6', value: 'dnp-shuei-mincho-pr6' },
  { label: 'DNP Shuei Mincho Pr6N', value: 'dnp-shuei-mincho-pr6n' },
  { label: 'DNP Shuei ShogoMincho Std', value: 'dnp-shuei-shogomincho-std' },
  { label: 'DNP Shuei Ymincho Std', value: 'dnp-shuei-ymincho-std' },
  { label: 'Dogma', value: 'dogma' },
  { label: 'Dolce', value: 'dolce' },
  { label: 'Dolly', value: 'dolly' },
  { label: 'DomLovesMary', value: 'domlovesmary' },
  { label: 'Domyouji', value: 'domyouji' },
  { label: 'DotGothic16', value: 'dotgothic16' },
  { label: 'Downtempo', value: 'downtempo' },
  { label: 'DreamTeam', value: 'dreamteam' },
  { label: 'Droid Sans', value: 'droid-sans' },
  { label: 'Droid Sans Mono', value: 'droid-sans-mono' },
  { label: 'Droid Serif', value: 'droid-serif' },
  { label: 'Drunk Cowboy', value: 'drunk-cowboy' },
  { label: 'Dry Cowboy', value: 'dry-cowboy' },
  { label: 'Duality', value: 'duality' },
  { label: 'Dublin', value: 'dublin' },
  { label: 'Duende', value: 'duende' },
  { label: 'Dulcian', value: 'dulcian' },
  { label: 'Dunbar', value: 'dunbar' },
  { label: 'Dunkel Sans', value: 'dunkel-sans' },
  { label: 'Duos', value: 'duos' },
  { label: 'Duper', value: 'duper' },
  { label: 'Dutch Mediaeval', value: 'dutch-mediaeval' },
  { label: 'Dystopian', value: 'dystopian' },
  { label: 'EB Garamond', value: 'eb-garamond' },
  { label: 'Ebony', value: 'ebony' },
  { label: 'Eckmannpsych', value: 'eckmannpsych' },
  { label: 'Eco', value: 'eco' },
  { label: 'Economica', value: 'economica' },
  { label: 'Ecru', value: 'ecru' },
  { label: "Ed's Market ", value: 'eds-market' },
  { label: 'Edita', value: 'edita' },
  { label: 'Effra', value: 'effra' },
  { label: 'Effra Arabic', value: 'effra-arabic' },
  { label: 'Egizio URW', value: 'egizio-urw' },
  { label: 'Eidetic Neo', value: 'eidetic-neo' },
  { label: 'Eigerdals', value: 'eigerdals' },
  { label: 'Eldwin Script', value: 'eldwin-script' },
  { label: 'Elektrix', value: 'elektrix' },
  { label: 'Elena', value: 'elena' },
  { label: 'Elevon', value: 'elevon' },
  { label: 'Elfreth', value: 'elfreth' },
  { label: 'Elido', value: 'elido' },
  { label: 'Elina', value: 'elina' },
  { label: 'Elliots', value: 'elliots' },
  { label: 'Eloquent JF', value: 'eloquent-jf' },
  { label: 'Embryo', value: 'embryo' },
  { label: 'Embury Text', value: 'embury-text' },
  { label: 'Emily Austin', value: 'emily-austin' },
  { label: 'Emploi', value: 'emploi' },
  { label: 'Enchanted', value: 'enchanted' },
  { label: 'English Grotesque', value: 'english-grotesque' },
  { label: 'Epitaph', value: 'epitaph' },
  { label: 'Erbaum', value: 'erbaum' },
  { label: 'Escoffier Capitaux', value: 'escoffier-capitaux' },
  { label: 'Eskapade', value: 'eskapade' },
  { label: 'Eskorte', value: 'eskorte' },
  { label: 'Essay Text', value: 'essay-text' },
  { label: 'Essential PragmataPro', value: 'essential-pragmatapro' },
  { label: 'Essonnes', value: 'essonnes' },
  { label: 'Ethnocentric', value: 'ethnocentric' },
  { label: 'Etna', value: 'etna' },
  { label: 'Europa', value: 'europa' },
  { label: 'Eurostile', value: 'eurostile' },
  { label: 'Exo Soft', value: 'exo-soft' },
  { label: 'Exocet', value: 'exocet' },
  { label: 'Expo Sans', value: 'expo-sans' },
  { label: 'Expo Serif', value: 'expo-serif' },
  { label: 'Export', value: 'export' },
  { label: 'Expressway', value: 'expressway' },
  { label: 'Facto', value: 'facto' },
  { label: 'Factoria', value: 'factoria' },
  { label: 'Fairplex', value: 'fairplex' },
  { label: 'Fairwater', value: 'fairwater' },
  { label: 'Fairwater Sans', value: 'fairwater-sans' },
  { label: 'Fairwater Script', value: 'fairwater-script' },
  { label: 'Fairway', value: 'fairway' },
  { label: 'Fairweather', value: 'fairweather' },
  { label: 'Fairy Tale JF', value: 'fairy-tale-jf' },
  { label: 'Fakir', value: 'fakir' },
  { label: 'Falcon Script', value: 'falcon-script' },
  { label: 'Fāng Zhèng Fǎng Sòng', value: 'fang-zheng-fang-song' },
  { label: 'Fāng Zhèng Hēi Tǐ', value: 'fang-zheng-hei-ti' },
  { label: 'Fāng Zhèng Kǎi Tǐ', value: 'fang-zheng-ki-ti' },
  { label: 'Fāng Zhèng Shū Sòng', value: 'fang-zheng-shu-song' },
  { label: 'Faricy New', value: 'faricy-new' },
  { label: 'Farmhand', value: 'farmhand' },
  { label: 'Farmhand Sans', value: 'farmhand-sans' },
  { label: 'FatFrank', value: 'fatfrank' },
  { label: 'Fave', value: 'fave' },
  { label: 'Felt Tip', value: 'felt-tip' },
  { label: 'Felt Tip Senior', value: 'felt-tip-senior' },
  { label: 'Felt Tip Woman', value: 'felt-tip-woman' },
  { label: 'Feltro', value: 'feltro' },
  { label: 'Fenway Park JF', value: 'fenway-park-jf' },
  { label: 'Fenwick', value: 'fenwick' },
  { label: 'Fertigo', value: 'fertigo' },
  { label: 'Fertigo Script', value: 'fertigo-script' },
  { label: 'FF Amman Sans', value: 'ff-amman-sans' },
  { label: 'FF Amman Serif', value: 'ff-amman-serif' },
  { label: 'FF Angie', value: 'ff-angie' },
  { label: 'FF Attribute Mono', value: 'ff-attribute-mono' },
  { label: 'FF Attribute Text', value: 'ff-attribute-text' },
  { label: 'FF Avance', value: 'ff-avance' },
  { label: 'FF Basic Gothic', value: 'ff-basic-gothic' },
  { label: 'FF Brokenscript', value: 'ff-brokenscript' },
  { label: 'FF Carina', value: 'ff-carina' },
  { label: 'FF Casus', value: 'ff-casus' },
  { label: 'FF Chambers Sans', value: 'ff-chambers-sans' },
  { label: 'FF Cocon', value: 'ff-cocon' },
  { label: 'FF Dagny', value: 'ff-dagny' },
  { label: 'FF Dax', value: 'ff-dax' },
  { label: 'FF Enzo', value: 'ff-enzo' },
  { label: 'FF Ernestine', value: 'ff-ernestine' },
  { label: 'FF Folk', value: 'ff-folk' },
  { label: 'FF Ginger', value: 'ff-ginger' },
  { label: 'FF Good', value: 'ff-good' },
  { label: 'FF Good Headline', value: 'ff-good-headline' },
  { label: 'FF Info', value: 'ff-info' },
  { label: 'FF Karbid', value: 'ff-karbid' },
  { label: 'FF Karbid Slab', value: 'ff-karbid-slab' },
  { label: 'FF Kava', value: 'ff-kava' },
  { label: 'FF Mach', value: 'ff-mach' },
  { label: 'FF Market', value: 'ff-market' },
  { label: 'FF Meta', value: 'ff-meta' },
  { label: 'FF Meta Headline', value: 'ff-meta-headline' },
  { label: 'FF Meta Serif', value: 'ff-meta-serif' },
  { label: 'FF More', value: 'ff-more' },
  { label: 'FF Nexus Mix', value: 'ff-nexus-mix' },
  { label: 'FF Nexus Sans', value: 'ff-nexus-sans' },
  { label: 'FF Nexus Serif', value: 'ff-nexus-serif' },
  { label: 'FF Nexus Typewriter', value: 'ff-nexus-typewriter' },
  { label: 'FF Nort', value: 'ff-nort' },
  { label: 'FF Nuvo', value: 'ff-nuvo' },
  { label: 'FF Nuvo Mono', value: 'ff-nuvo-mono' },
  { label: 'FF Prater', value: 'ff-prater' },
  { label: 'FF Providence', value: 'ff-providence' },
  { label: 'FF Providence Sans', value: 'ff-providence-sans' },
  { label: 'FF Real', value: 'ff-real' },
  { label: 'FF Scala', value: 'ff-scala' },
  { label: 'FF Scala Sans', value: 'ff-scala-sans' },
  { label: 'FF Seria ', value: 'ff-seria' },
  { label: 'FF Seria Sans', value: 'ff-seria-sans' },
  { label: 'FF Speak', value: 'ff-speak' },
  { label: 'FF Spinoza', value: 'ff-spinoza' },
  { label: 'FF Tisa', value: 'ff-tisa' },
  { label: 'FF Tisa Sans', value: 'ff-tisa-sans' },
  { label: 'FF Typestar', value: 'ff-typestar' },
  { label: 'FF Uberhand', value: 'ff-uberhand' },
  { label: 'FF Unit', value: 'ff-unit' },
  { label: 'FF Unit Rounded', value: 'ff-unit-rounded' },
  { label: 'FF Unit Slab', value: 'ff-unit-slab' },
  { label: 'FF Utility', value: 'ff-utility' },
  { label: 'FF Zwo', value: 'ff-zwo' },
  { label: 'Fieldwork', value: 'fieldwork' },
  { label: 'Fight to the Finish BB', value: 'fight-to-the-finish-bb' },
  { label: 'Filosofia', value: 'filosofia' },
  { label: 'Filson', value: 'filson' },
  { label: 'Fino', value: 'fino' },
  { label: 'Fino Sans', value: 'fino-sans' },
  { label: 'Fira Mono', value: 'fira-mono' },
  { label: 'Fira Sans', value: 'fira-sans' },
  { label: 'Fit', value: 'fit' },
  { label: 'Fleisch', value: 'fleisch' },
  { label: 'Flood', value: 'flood' },
  { label: 'Flower Power', value: 'flower-power' },
  { label: 'FLW Eaglefeather', value: 'p22-eaglefeather' },
  { label: 'Flyswim', value: 'flyswim' },
  { label: 'Foco', value: 'foco' },
  { label: 'Force', value: 'force' },
  { label: 'Forgotten Futurist', value: 'forgotten-futurist' },
  { label: 'Forma DJR Banner', value: 'forma-djr-banner' },
  { label: 'Forma DJR Deck', value: 'forma-djr-deck' },
  { label: 'Forma DJR Display', value: 'forma-djr-display' },
  { label: 'Forma DJR Micro', value: 'forma-djr-micro' },
  { label: 'Forma DJR Text', value: 'forma-djr-text' },
  { label: 'FOT-Cezanne ProN', value: 'fot-cezanne-pron' },
  { label: 'FOT-Klee Pro', value: 'fot-klee-pro' },
  { label: 'FOT-Matisse ProN', value: 'fot-matisse-pron' },
  { label: 'FOT-Rodin ProN', value: 'fot-rodin-pron' },
  { label: 'FOT-Seurat ProN', value: 'fot-seurat-pron' },
  { label: 'FOT-TsukuARdGothic Std', value: 'fot-tsukuardgothic-std' },
  { label: 'FOT-TsukuBRdGothic Std', value: 'fot-tsukubrdgothic-std' },
  { label: 'FOT-UDKakugo Large Pr6N', value: 'fot-udkakugo-large-pr6n' },
  { label: 'FOT-UDMarugo Large Pr6N', value: 'fot-udmarugo-large-pr6n' },
  { label: 'FP Dancer', value: 'fp-dancer' },
  { label: 'FP Dancer Serif', value: 'fp-dancer-serif' },
  { label: 'FP Head', value: 'fp-head' },
  { label: 'Fragile Bombers', value: 'fragile-bombers' },
  { label: 'Franklin Gothic URW', value: 'franklin-gothic-urw' },
  { label: 'Fredericka the Greatest', value: 'fredericka-the-greatest' },
  { label: 'Freehouse', value: 'freehouse' },
  { label: 'Freight', value: 'freight' },
  { label: 'Freight Neo', value: 'freight-neo' },
  { label: 'Freight Sans', value: 'freight-sans' },
  { label: 'Freight Text', value: 'freight-text' },
  { label: 'French Octagon', value: 'french-octagon' },
  { label: 'French Roast', value: 'french-roast' },
  { label: 'Fresno', value: 'fresno' },
  { label: 'Freude', value: 'freude' },
  { label: 'Funkydori', value: 'funkydori' },
  { label: 'Fusaka', value: 'fusaka' },
  { label: 'Futura PT', value: 'futura-pt' },
  { label: 'Gala', value: 'gala' },
  { label: 'Galahad', value: 'galahad' },
  { label: 'Galette', value: 'galette' },
  { label: 'Ganache', value: 'ganache' },
  { label: 'Garage Gothic', value: 'garage-gothic' },
  { label: 'Garamond Premier', value: 'garamond-premier' },
  { label: 'Garvis', value: 'garvis' },
  { label: 'Gastromond', value: 'gastromond' },
  { label: 'Gaultier', value: 'gaultier' },
  { label: 'Gautreaux', value: 'gautreaux' },
  { label: 'Gelo', value: 'gelo' },
  { label: 'Gemeli Mono', value: 'gemeli-mono' },
  { label: 'Geneo', value: 'geneo' },
  { label: 'Geographica', value: 'geographica' },
  { label: 'Geom Graphic', value: 'geom-graphic' },
  { label: 'Germania One', value: 'germania-one' },
  { label: 'Gesta', value: 'gesta' },
  { label: 'Gibbs', value: 'gibbs' },
  { label: 'Gibson', value: 'gibson' },
  { label: 'Giddyup', value: 'giddyup' },
  { label: 'Gigalypse', value: 'gigalypse' },
  { label: 'Gilbert', value: 'gilbert' },
  { label: 'Gill Sans Nova', value: 'gill-sans-nova' },
  { label: 'Gimlet Display', value: 'gimlet-display' },
  { label: 'Gimlet Micro', value: 'gimlet-micro' },
  { label: 'Gimlet Text', value: 'gimlet-text' },
  { label: 'Gin', value: 'gin' },
  { label: 'Gineso', value: 'gineso' },
  { label: 'Gioviale', value: 'gioviale' },
  { label: 'Gira Sans', value: 'gira-sans' },
  { label: 'Gitan', value: 'gitan' },
  { label: 'Giulia', value: 'giulia' },
  { label: 'Givry', value: 'givry' },
  { label: 'Gizmo', value: 'gizmo' },
  { label: 'Gloria Hallelujah', value: 'gloria-hallelujah' },
  { label: 'Gloss Drop', value: 'gloss-drop' },
  { label: 'Gnuolane', value: 'gnuolane' },
  { label: 'Goldenbook', value: 'goldenbook' },
  { label: 'Gomme Sans', value: 'gomme-sans' },
  { label: 'Good Bad Man', value: 'good-bad-man' },
  { label: 'Good Times', value: 'good-times' },
  { label: 'GoodDog New', value: 'gooddog-new' },
  { label: 'GoodKitty', value: 'goodkitty' },
  { label: 'Goodlife', value: 'goodlife' },
  { label: 'Gopher', value: 'gopher' },
  { label: 'Gothicus', value: 'gothicus' },
  { label: 'Goudy Bookletter 1911', value: 'goudy-bookletter-1911' },
  { label: 'Goudy Old Style', value: 'goudy-old-style' },
  { label: 'Grad', value: 'grad' },
  { label: 'Graffiti', value: 'graffiti' },
  { label: 'Grafolita Script', value: 'grafolita-script' },
  { label: 'Grand Central', value: 'grand-central' },
  { label: 'Grandma', value: 'grandma' },
  { label: 'Grange', value: 'grange' },
  { label: 'Granville', value: 'granville' },
  { label: 'Graphie', value: 'graphie' },
  { label: 'Graphite', value: 'graphite' },
  { label: 'Gratitude Script', value: 'gratitude-script' },
  { label: 'Graveblade', value: 'graveblade' },
  { label: 'Gravesend Sans', value: 'gravesend-sans' },
  { label: 'Great Vibes', value: 'great-vibes' },
  { label: 'Grecian Light Face', value: 'grecian-light-face' },
  { label: 'Griffith Gothic', value: 'griffith-gothic' },
  { label: 'Griffith Gothic Condensed', value: 'griffith-gothic-condensed' },
  { label: 'Griffon', value: 'griffon' },
  { label: 'Grover', value: 'grover' },
  { label: 'Grover Slab', value: 'grover-slab' },
  { label: 'Grueber', value: 'grueber' },
  { label: 'Guanabara Sans', value: 'guanabara-sans' },
  { label: 'Guapa', value: 'guapa' },
  { label: 'Gunplay', value: 'gunplay' },
  { label: 'Gurkner', value: 'gurkner' },
  { label: 'Gyparody', value: 'gyparody' },
  { label: 'Haboro', value: 'haboro' },
  { label: 'Haboro Contrast', value: 'haboro-contrast' },
  { label: 'Haboro Serif', value: 'haboro-serif' },
  { label: 'Haboro Soft', value: 'haboro-soft' },
  { label: 'Hachura', value: 'hachura' },
  { label: 'Hack', value: 'hack' },
  { label: 'Hackman', value: 'hackman' },
  { label: 'Halcom', value: 'halcom' },
  { label: 'Halogen', value: 'halogen' },
  { label: 'Halyard', value: 'halyard' },
  { label: 'Handel Gothic', value: 'handel-gothic' },
  { label: 'HappyRuika', value: 'happyruika' },
  { label: 'Harfang', value: 'harfang' },
  { label: 'Harlean', value: 'harlean' },
  { label: 'Harri', value: 'harri' },
  { label: 'Harri Text', value: 'harri-text' },
  { label: 'Harvester', value: 'harvester' },
  { label: 'Hatch', value: 'hatch' },
  { label: 'Hayate', value: 'hayate' },
  { label: 'Headlight', value: 'headlight' },
  { label: 'Hedley New', value: 'hedley-new' },
  { label: 'Heebo', value: 'heebo' },
  { label: 'Heimat Didone ', value: 'heimat-didone' },
  { label: 'Heimat Display', value: 'heimat-display' },
  { label: 'Heimat Mono', value: 'heimat-mono' },
  { label: 'Heimat Sans', value: 'heimat-sans' },
  { label: 'Heimat Stencil', value: 'heimat-stencil' },
  { label: 'Heisei Kaku Gothic', value: 'heisei-kaku-gothic-std' },
  { label: 'Heisei Kaku Gothic StdN', value: 'heisei-kaku-gothic-stdn' },
  { label: 'Heisei Maru Gothic', value: 'heisei-maru-gothic-std' },
  { label: 'Heisei Mincho', value: 'heisei-mincho-std' },
  { label: 'Heisei Mincho StdN', value: 'heisei-mincho-stdn' },
  { label: 'Hellenic Wide JF', value: 'hellenic-wide-jf' },
  { label: 'Hello My Love', value: 'hello-my-love' },
  { label: 'HelloFont Fang Hua Ti', value: 'hellofont-fanghuati' },
  { label: 'HelloFont ID Chun Yi Ti', value: 'hellofont-id-chunyiti' },
  { label: 'HelloFont ID Chun Zhen Ti', value: 'hellofont-id-chun-zhen-ti' },
  { label: 'HelloFont ID Da Zi Bao', value: 'hellofont-id-da-zi-bao' },
  { label: 'HelloFont ID Dao Cao Ren', value: 'hellofont-id-daocaoren' },
  { label: 'HelloFont ID Jian Song', value: 'hellofont-id-jian-song' },
  {
    label: 'HelloFont ID Jianghu Zhaopaihei',
    value: 'hellofont-id-jianghuzhaopaihei',
  },
  { label: 'HelloFont ID Jue Jiang Hei', value: 'hellofont-id-juejianghei' },
  { label: 'HelloFont ID Le Yuan Ti', value: 'hellofont-id-leyuanti' },
  { label: 'HelloFont ID Lie Jin Ti', value: 'hellofont-id-lie-jin-ti' },
  { label: 'HelloFont ID Mei Ling Ti', value: 'hellofont-id-meilingti' },
  { label: 'HelloFont ID Pinocchio', value: 'hellofont-id-pinocchio' },
  {
    label: 'HelloFont ID Qing Hua Xing Kai',
    value: 'hellofont-id-qinghuaxingkai',
  },
  { label: 'HelloFont ID QQ Sugar', value: 'hellofont-id-qqsugar' },
  {
    label: 'HelloFont ID Shou Xie Tong Zhen Ti',
    value: 'hellofont-id-shouxietongzhenti',
  },
  { label: 'HelloFont ID Tong Zhi Ti', value: 'hellofont-id-tong-zhi-ti' },
  {
    label: 'HelloFont ID Xiao Xiong Mao',
    value: 'hellofont-id-xiao-xiong-mao',
  },
  {
    label: 'HelloFont ID Xuan Zhen Song',
    value: 'hellofont-id-xuan-zhen-song',
  },
  { label: 'HelloFont ID You Man Ti', value: 'hellofont-id-you-man-ti' },
  { label: 'HelloFont Wen Yi Hei', value: 'hellofont-wenyihei' },
  { label: 'Henderson Sans', value: 'henderson-sans' },
  { label: 'Henderson Slab', value: 'henderson-slab' },
  { label: 'Henriette', value: 'henriette' },
  { label: 'Herencia', value: 'herencia' },
  {
    label: 'Hidden Treasures of the Bauhaus Dessau',
    value: 'hidden-treasures',
  },
  { label: 'Highgate', value: 'highgate' },
  { label: 'Hightower', value: 'hightower' },
  { label: 'Hilde Sharp', value: 'hilde-sharp' },
  { label: 'Hind', value: 'hind' },
  { label: 'Hind Siliguri', value: 'hind-siliguri' },
  { label: 'Hit', value: 'hit' },
  { label: 'Hobeaux', value: 'hobeaux' },
  { label: 'Hobeaux Rococeaux', value: 'hobeaux-rococeaux' },
  { label: 'Hobeaux Rococeaux Borders', value: 'hobeaux-rococeaux-borders' },
  { label: 'Hobo', value: 'hobo' },
  { label: 'Hoffmann', value: 'hoffmann' },
  { label: 'Homemade Apple Pro', value: 'homemade-apple-pro' },
  { label: 'Hooligan JF', value: 'hooligan-jf' },
  { label: 'Hoosier Daddy', value: 'hoosier-daddy' },
  { label: 'Hot Salsa', value: 'hot-salsa' },
  { label: 'Hotel', value: 'hotel' },
  { label: 'Hothouse', value: 'hothouse' },
  { label: 'Houschka Pro', value: 'houschka-pro' },
  { label: 'Houschka Rounded', value: 'houschka-rounded' },
  { label: 'House of cards', value: 'house-of-cards' },
  { label: 'Hoverunit', value: 'hoverunit' },
  { label: 'HT Neon', value: 'ht-neon' },
  { label: 'HucklebuckJF', value: 'hucklebuckjf' },
  { label: 'Hummingbird', value: 'hummingbird' },
  { label: 'HVD Bodedo', value: 'hvd-bodedo' },
  { label: 'HVD Comic Serif', value: 'hvd-comic-serif' },
  { label: 'HVD Edding 780', value: 'hvd-edding-780' },
  { label: 'HVD Poster Clean', value: 'hvd-poster-clean' },
  { label: 'HVD Rowdy', value: 'hvd-rowdy' },
  { label: 'HVD Steinzeit', value: 'hvd-steinzeit' },
  { label: 'HWT Aetna', value: 'hwt-aetna' },
  { label: 'HWT American', value: 'hwt-american' },
  { label: 'HWT Antique Tuscan 9', value: 'hwt-antique-tuscan-9' },
  { label: 'HWT Arabesque', value: 'hwt-arabesque' },
  { label: 'HWT Archimedes Pro', value: 'hwt-archimedes-pro' },
  { label: 'HWT Artz', value: 'hwt-artz' },
  { label: 'HWT Bon Air', value: 'hwt-bon-air' },
  { label: 'HWT Borders One', value: 'hwt-borders-one' },
  { label: 'HWT Brylski', value: 'hwt-brylski' },
  { label: 'HWT Bulletin Script', value: 'hwt-bulletin-script' },
  { label: 'HWT Catchwords', value: 'hwt-catchwords' },
  { label: 'HWT Etta', value: 'hwt-etta' },
  { label: 'HWT Geometric', value: 'hwt-geometric' },
  { label: 'HWT Gothic Round', value: 'hwt-gothic-round' },
  { label: 'HWT Lustig', value: 'hwt-lustig-elements' },
  { label: 'HWT Mardell', value: 'hwt-mardell' },
  { label: 'HWT Republic Gothic', value: 'hwt-republic-gothic' },
  { label: 'HWT Roman Extended', value: 'hwt-roman-extended' },
  { label: 'HWT Slab', value: 'hwt-slab' },
  { label: 'HWT Star Ornaments', value: 'hwt-star-ornaments' },
  { label: 'HWT Tuscan Extended', value: 'hwt-tuscan-extended' },
  { label: 'HWT Unit Gothic', value: 'hwt-unit-gothic' },
  { label: 'HWT VanLanen', value: 'hwt-vanlanen' },
  { label: 'Hydrophilia', value: 'hydrophilia' },
  { label: 'Hypatia Sans', value: 'hypatia-sans' },
  { label: 'Hypocrite', value: 'hypocrite' },
  { label: 'IBM Plex Arabic', value: 'ibm-plex-arabic' },
  { label: 'IBM Plex Devanagari', value: 'ibm-plex-devanagari' },
  { label: 'IBM Plex Mono', value: 'ibm-plex-mono' },
  { label: 'IBM Plex Sans', value: 'ibm-plex-sans' },
  { label: 'IBM Plex Sans Hebrew', value: 'ibm-plex-sans-hebrew' },
  { label: 'IBM Plex Sans Thai Looped', value: 'ibm-plex-sans-thai-looped' },
  { label: 'IBM Plex Serif', value: 'ibm-plex-serif' },
  { label: 'IBM Plex Thai', value: 'ibm-plex-thai' },
  { label: 'IM FELL Double Pica', value: 'im-fell-double-pica' },
  { label: 'IM FELL DW Pica', value: 'im-fell-dw-pica' },
  { label: 'IM FELL English', value: 'im-fell-english' },
  { label: 'IM FELL French Canon', value: 'im-fell-french-canon' },
  { label: 'IM FELL Great Primer', value: 'im-fell-great-primer' },
  { label: 'ImaginaryFriend BB', value: 'imaginaryfriend-bb' },
  { label: 'Immi Five O Five', value: 'immi-five-o-five' },
  { label: 'Imperial URW', value: 'imperial-urw' },
  { label: 'Impetus', value: 'impetus' },
  { label: 'Indie Flower', value: 'indie-flower' },
  { label: 'Industry', value: 'industry' },
  { label: 'Industry Inc', value: 'industry-inc' },
  { label: 'Informa', value: 'informa' },
  { label: 'Ingeborg', value: 'ingeborg' },
  { label: 'Ingra', value: 'ingra' },
  { label: 'Input Mono', value: 'input-mono' },
  { label: 'Input Sans', value: 'input-sans' },
  { label: 'Input Serif', value: 'input-serif' },
  { label: 'InterFace', value: 'interface' },
  { label: 'InterFace Arabic', value: 'interface-arabic' },
  { label: 'Interstate', value: 'interstate' },
  { label: 'Interstate Mono', value: 'interstate-mono' },
  { label: 'Interstate Pi', value: 'interstate-pi' },
  { label: 'Ironstrike', value: 'ironstrike' },
  { label: 'Ironwood', value: 'ironwood' },
  { label: 'Iskra', value: 'iskra' },
  { label: 'Isonorm', value: 'isonorm' },
  { label: 'ITC American Typewriter', value: 'itc-american-typewriter' },
  { label: 'ITC Avant Garde Gothic', value: 'itc-avant-garde-gothic' },
  { label: 'ITC Benguiat', value: 'itc-benguiat' },
  { label: 'ITC Fenice', value: 'itc-fenice' },
  { label: 'ITC Flora', value: 'itc-flora' },
  { label: 'ITC Franklin Gothic', value: 'itc-franklin-gothic' },
  { label: 'ITC Galliard', value: 'itc-galliard' },
  { label: 'ITC Giovanni', value: 'itc-giovanni' },
  { label: 'ITC Officina Sans', value: 'itc-officina-sans' },
  { label: 'ITC Slimbach', value: 'itc-slimbach' },
  { label: 'ITC Zapf International', value: 'itc-zapf-international' },
  { label: 'Ivy Journal', value: 'ivy-journal' },
  { label: 'Ivy Style Sans', value: 'ivy-style-sans' },
  { label: 'Ivy Style TW', value: 'ivy-style-tw' },
  { label: 'IvyMode', value: 'ivymode' },
  { label: 'IvyPresto Display', value: 'ivypresto-display' },
  { label: 'IvyPresto Headline', value: 'ivypresto-headline' },
  { label: 'IvyPresto Text', value: 'ivypresto-text' },
  { label: 'JAF Bernini Sans', value: 'jaf-bernini' },
  { label: 'JAF Domus', value: 'jaf-domus' },
  { label: 'JAF Domus Titling', value: 'jaf-domus-titling' },
  { label: 'JAF Facit', value: 'jaf-facit' },
  { label: 'JAF Herb', value: 'jaf-herb' },
  { label: 'JAF Johannes', value: 'jaf-johannes' },
  { label: 'JAF Lapture', value: 'jaf-lapture' },
  { label: 'JAF Mashine', value: 'jaf-mashine' },
  { label: 'JAF Peacock', value: 'jaf-peacock' },
  { label: 'JAF Zalamander', value: 'jaf-zalamander' },
  { label: 'Jakob', value: 'jakob' },
  { label: 'Jana Thork', value: 'jana-thork' },
  { label: 'Jay Gothic URW', value: 'jay-gothic-urw' },
  { label: 'Jeanne Moderno', value: 'jeanne-moderno' },
  { label: 'Jeff Script', value: 'jeff-script' },
  { label: 'Jesaya', value: 'jesaya' },
  { label: 'Jinky', value: 'jinky' },
  { label: 'Joanna Nova', value: 'joanna-nova' },
  { label: 'Joanna Sans Nova', value: 'joanna-sans-nova' },
  { label: 'JohnDoe', value: 'johndoe' },
  { label: 'Joost', value: 'joost' },
  { label: 'Josefin Sans', value: 'josefin-sans' },
  { label: 'Josefin Slab', value: 'josefin-slab' },
  { label: 'Journal', value: 'journal' },
  { label: 'Jubilat', value: 'jubilat' },
  { label: 'Jumble', value: 'jumble' },
  { label: 'Junegull', value: 'junegull' },
  { label: 'Juniper', value: 'juniper' },
  { label: 'Justus', value: 'justus' },
  { label: 'Kade', value: 'kade' },
  { label: 'Kadwa', value: 'kadwa' },
  { label: 'Kaffeesatz', value: 'kaffeesatz' },
  { label: 'Kallisto', value: 'kallisto' },
  { label: 'Kan412Typos Std', value: 'kan412typos-std' },
  { label: 'Kan415Typos Std', value: 'kan415typos-std' },
  { label: 'Kan48Typos Std', value: 'kan48typos-std' },
  { label: 'Kandal', value: 'kandal' },
  { label: 'Kandin', value: 'kandin' },
  { label: 'Kaneda Gothic', value: 'kaneda-gothic' },
  { label: 'Kanit', value: 'kanit' },
  { label: 'Kapelka New', value: 'kapelka-new' },
  { label: 'Kari', value: 'kari' },
  { label: 'Karmina', value: 'karmina' },
  { label: 'Karmina Sans', value: 'karmina-sans' },
  { label: 'Karumbi', value: 'karumbi' },
  { label: 'Katarine', value: 'katarine' },
  { label: 'Kautiva', value: 'kautiva-pro' },
  { label: 'Kazimir', value: 'kazimir' },
  { label: 'Kazimir Text', value: 'kazimir-text' },
  { label: 'Kazuraki SP2N', value: 'kazuraki-sp2n' },
  { label: 'Keedy Sans', value: 'keedy-sans' },
  { label: 'Kegger', value: 'kegger' },
  { label: 'Kelvingrove', value: 'kelvingrove' },
  { label: 'Kepler', value: 'kepler' },
  { label: 'Keraleeyam', value: 'keraleeyam' },
  { label: 'Kestrel Script', value: 'kestrel-script' },
  { label: 'Kewl Script', value: 'kewl-script' },
  { label: 'Khula', value: 'khula' },
  { label: 'Kinescope', value: 'kinescope' },
  { label: 'Kinesis', value: 'kinesis' },
  { label: "King's Caslon", value: 'kings-caslon' },
  { label: 'Kinuta Donguri', value: 'donguri' },
  { label: 'Kinuta Donguri Kana', value: 'donguri-kana' },
  { label: 'Kinuta iroha 21popura StdN', value: 'iroha-21popura-stdn' },
  { label: 'Kinuta iroha 22momi StdN', value: 'iroha-22momi-stdn' },
  { label: 'Kinuta iroha 23kaede StdN', value: 'iroha-23kaede-stdn' },
  { label: 'Kinuta iroha 24matu StdN', value: 'iroha-24matu-stdn' },
  { label: 'Kinuta iroha 25icho StdN', value: 'iroha-25icho-stdn' },
  { label: 'Kinuta iroha 26tubaki StdN', value: 'iroha-26tubaki-stdn' },
  { label: 'Kinuta iroha 27keyaki StdN', value: 'iroha-27keyaki-stdn' },
  { label: 'Kinuta iroha 28kiri StdN', value: 'iroha-28kiri-stdn' },
  { label: 'Kinuta iroha 29ume StdN', value: 'iroha-29ume-stdn' },
  { label: 'Kinuta iroha 30momiji StdN', value: 'iroha-30momiji-stdn' },
  { label: 'Kinuta iroha 31nire StdN', value: 'iroha-31nire-stdn' },
  { label: 'Kinuta iroha 32sakura Kana', value: 'iroha-32sakura-kana' },
  { label: 'Kinuta iroha 32sakura StdN', value: 'iroha-32sakura-stdn' },
  { label: 'Kinuta Kiriko Kana', value: 'kiriko-kana' },
  { label: 'Kinuta Maru Maru Gothic A', value: 'maru-maru-gothic-a' },
  { label: 'Kinuta Maru Maru Gothic B', value: 'maru-maru-gothic-b' },
  { label: 'Kinuta Maru Maru Gothic C', value: 'maru-maru-gothic-c' },
  { label: 'Kinuta Marumin Fuji StdN', value: 'marumin-fuji-stdn' },
  { label: 'Kinuta Marumin Katura StdN', value: 'marumin-katura-stdn' },
  { label: 'Kinuta Marumin Kiso StdN', value: 'marumin-kiso-stdn' },
  { label: 'Kinuta Marumin Old StdN', value: 'marumin-old-stdn' },
  { label: 'Kinuta Marumin Shinano StdN', value: 'marumin-shinano-stdn' },
  { label: 'Kinuta Marumin Tikuma StdN', value: 'marumin-tikuma-stdn' },
  { label: 'Kinuta Marumin Yoshino StdN', value: 'marumin-yoshino-stdn' },
  { label: 'Kinuta Mincho StdN', value: 'kinuta-mincho-stdn' },
  { label: 'Kinuta Reiwa Kana', value: 'reiwa-kana' },
  { label: 'Kinuta Shin StdN', value: 'shin-stdn' },
  { label: 'Kinuta Yamamotoan Classic StdN', value: 'yamamotoan-classic-stdn' },
  { label: 'Kinuta Yamamotoan StdN', value: 'yamamotoan-stdn' },
  { label: 'Kiro', value: 'kiro' },
  { label: 'Klee One', value: 'klee-one' },
  { label: 'Kobenhavn', value: 'kobenhavn' },
  { label: 'Kobenhavn C', value: 'kobenhavn-c' },
  { label: 'Kobenhavn C Stencil', value: 'kobenhavn-c-stencil' },
  { label: 'Kobenhavn CS', value: 'kobenhavn-cs' },
  { label: 'Kobenhavn Sans', value: 'kobenhavn-sans' },
  { label: 'Kobenhavn Sans Stencil', value: 'kobenhavn-sans-stencil' },
  { label: 'Kobenhavn Stencil', value: 'kobenhavn-stencil' },
  { label: 'Komet', value: 'komet' },
  { label: 'Komu', value: 'komu' },
  { label: 'Kon Tiki Aloha JF', value: 'kon-tiki-aloha-jf' },
  { label: 'Kon Tiki Lounge JF', value: 'kon-tiki-lounge-jf' },
  { label: 'Kopius', value: 'kopius' },
  { label: 'Korolev', value: 'korolev' },
  { label: 'Kozuka Gothic Pr6N', value: 'kozuka-gothic-pr6n' },
  { label: 'Kozuka Gothic Pro', value: 'kozuka-gothic-pro' },
  { label: 'Kozuka Mincho Pr6N', value: 'kozuka-mincho-pr6n' },
  { label: 'Kozuka Mincho Pro', value: 'kozuka-mincho-pro' },
  { label: 'Kremlin', value: 'kremlin' },
  { label: 'Krete', value: 'krete' },
  { label: 'Krok', value: 'krok' },
  { label: 'Kudryashev Display', value: 'kudryashev-display' },
  { label: 'Kulturista', value: 'kulturista' },
  { label: 'Kumlien', value: 'kumlien' },
  { label: 'Kyrial', value: 'kyrial' },
  { label: 'Laca', value: 'laca' },
  { label: 'Laca Text', value: 'laca-text' },
  { label: 'Lady Dodo', value: 'lady-dodo' },
  { label: 'Lakeside', value: 'lakeside' },
  { label: 'Lamar Pen', value: 'lamar-pen' },
  { label: 'Landa', value: 'landa' },
  { label: 'Larabiefont', value: 'larabiefont' },
  { label: 'Laski Sans', value: 'laski-sans' },
  { label: 'Laski Slab', value: 'laski-slab' },
  { label: 'Latex', value: 'latex' },
  { label: 'Latienne', value: 'latienne' },
  { label: 'Latinaires', value: 'latinaires' },
  { label: 'Latino URW', value: 'latino-urw' },
  { label: 'Lato', value: 'lato' },
  { label: 'Lavigne', value: 'lavigne' },
  { label: 'Le Havre', value: 'le-havre' },
  { label: 'Le Monde Courrier', value: 'le-monde-courrier' },
  { label: 'Le Monde Journal', value: 'le-monde-journal' },
  { label: 'Le Monde Livre', value: 'le-monde-livre' },
  { label: 'Le Monde Livre Classic', value: 'le-monde-livre-classic' },
  { label: 'Le Monde Sans', value: 'le-monde-sans' },
  { label: 'League Gothic', value: 'league-gothic' },
  { label: 'Leander Script', value: 'leander-script' },
  { label: 'Learning Curve', value: 'learning-curve' },
  { label: 'Leather', value: 'leather' },
  { label: 'Legitima', value: 'legitima' },
  { label: 'Lehmann', value: 'lehmann' },
  { label: 'Leksa', value: 'leksa' },
  { label: 'Leksa Sans', value: 'leksa-sans' },
  { label: 'Lemance', value: 'lemance' },
  { label: 'Lemongrass', value: 'lemongrass' },
  { label: 'Leo', value: 'leo' },
  { label: 'Letter Gothic', value: 'letter-gothic' },
  { label: 'Lexia', value: 'lexia' },
  { label: 'Lexia Mono', value: 'lexia-mono' },
  { label: 'LFT Etica', value: 'lft-etica' },
  { label: 'LFT Etica Mono', value: 'lft-etica-mono' },
  { label: 'LFT Iro Sans', value: 'lft-iro-sans' },
  { label: 'Liam', value: 'liam' },
  { label: 'Liana', value: 'liana' },
  { label: 'Liberation Sans', value: 'liberation-sans' },
  { label: 'Liberation Serif', value: 'liberation-serif' },
  { label: 'Libertad', value: 'libertad' },
  { label: 'Liberteen', value: 'liberteen' },
  { label: 'Libre Franklin', value: 'libre-franklin' },
  { label: 'LiebeDoni', value: 'liebedoni' },
  { label: 'LiebeErika', value: 'liebeerika' },
  { label: 'LiebeGerda', value: 'liebegerda' },
  { label: 'LiebeRuth', value: 'lieberuth' },
  { label: 'Ligurino', value: 'ligurino' },
  { label: 'Linden Hill', value: 'linden-hill' },
  { label: 'Linotype Didot', value: 'linotype-didot' },
  { label: 'Lint McCree', value: 'lint-mccree' },
  { label: 'Lipa Agate', value: 'lipa-agate' },
  { label: 'Liquorstore', value: 'liquorstore' },
  { label: 'Lisbeth', value: 'lisbeth' },
  { label: 'Litania', value: 'litania' },
  { label: 'Lithos', value: 'lithos' },
  { label: 'Livory', value: 'livory' },
  { label: 'Liza', value: 'liza' },
  { label: 'Lo-Res', value: 'lo-res' },
  { label: 'Look Script', value: 'look-script' },
  { label: 'Looking Flowers', value: 'looking-flowers' },
  { label: 'Lora', value: 'lora' },
  { label: 'Lorimer No. 2', value: 'lorimer-no-2' },
  { label: 'Los Feliz', value: 'los-feliz' },
  { label: 'LosLana Niu Pro', value: 'loslana-niu-pro' },
  { label: 'Lourdes', value: 'lourdes' },
  { label: 'Louvette Banner', value: 'louvette-banner' },
  { label: 'Louvette Deck', value: 'louvette-deck' },
  { label: 'Louvette Display', value: 'louvette-display' },
  { label: 'Louvette Text', value: 'louvette-text' },
  { label: 'LTC Bodoni 175', value: 'ltc-bodoni-175' },
  { label: 'LTC Broadway', value: 'ltc-broadway' },
  { label: 'LTC Caslon Pro', value: 'ltc-caslon-pro' },
  { label: 'LTC Globe Gothic', value: 'ltc-globe-gothic' },
  { label: 'LTC Goudy Oldstyle Pro', value: 'ltc-goudy-oldstyle-pro' },
  { label: 'LTC Goudy Ornate', value: 'ltc-goudy-ornate' },
  { label: 'LTC Hess Monoblack', value: 'ltc-hess-monoblack' },
  { label: 'LTC Italian Old Style', value: 'ltc-italian-old-style' },
  { label: 'LTC Kennerley', value: 'ltc-kennerley' },
  { label: 'LTC Pabst Oldstyle', value: 'ltc-pabst-oldstyle' },
  { label: 'LTC SquareFace', value: 'ltc-squareface' },
  { label: 'Luke', value: 'luke' },
  { label: 'Luminance', value: 'luminance' },
  { label: 'Lunatix', value: 'lunatix' },
  { label: 'Lush', value: 'lush' },
  { label: 'Lust', value: 'lust' },
  { label: 'Lust Sans', value: 'lust-sans' },
  { label: 'Lust Script', value: 'lust-script' },
  { label: 'Lust Stencil', value: 'lust-stencil' },
  { label: 'Lust Text', value: 'lust-text' },
  { label: 'Luxus Brut', value: 'luxus-brut' },
  { label: 'Luxus Brut Sparkling', value: 'luxus-brut-sparkling' },
  { label: 'Lynda', value: 'lynda' },
  { label: 'M Plus 1c', value: 'm-plus-1c' },
  { label: 'M Plus 1m', value: 'm-plus-1m' },
  { label: 'M Plus 1mn', value: 'm-plus-1mn' },
  { label: 'M Plus 1p', value: 'm-plus-1p' },
  { label: 'M Plus 2c', value: 'm-plus-2c' },
  { label: 'M Plus 2m', value: 'm-plus-2m' },
  { label: 'M Plus 2p', value: 'm-plus-2p' },
  { label: 'M Plus Rounded 1c', value: 'm-plus-rounded-1c' },
  { label: 'M Plus Rounded 1m', value: 'm-plus-rounded-1m' },
  { label: 'M Plus Rounded 1mn', value: 'm-plus-rounded-1mn' },
  { label: 'M Plus Rounded 1p', value: 'm-plus-rounded-1p' },
  { label: 'M Plus Rounded 2c', value: 'm-plus-rounded-2c' },
  { label: 'M Plus Rounded 2m', value: 'm-plus-rounded-2m' },
  { label: 'M Plus Rounded 2p', value: 'm-plus-rounded-2p' },
  { label: 'Macha', value: 'macha' },
  { label: 'Macho', value: 'macho' },
  { label: 'MachoModular', value: 'machomodular' },
  { label: 'Madawaska', value: 'madawaska' },
  { label: 'Madre Script', value: 'madre-script' },
  { label: 'Madrone', value: 'madrone' },
  { label: 'Maecenas', value: 'maecenas' },
  { label: 'Magallanes', value: 'magallanes' },
  { label: 'Magasin', value: 'magasin' },
  { label: 'Magion', value: 'magion' },
  { label: 'Magistral', value: 'magistral' },
  { label: 'Magneta', value: 'magneta' },
  { label: 'Magneto', value: 'magneto' },
  { label: 'Magpie', value: 'magpie' },
  { label: 'Mahalia', value: 'mahalia' },
  { label: 'Maiola', value: 'maiola' },
  { label: 'Malaga', value: 'malaga' },
  { label: 'Mama Script', value: 'mama-script' },
  { label: 'Mandevilla ', value: 'mandevilla' },
  { label: 'Mandrel', value: 'mandrel' },
  { label: 'Manicotti', value: 'manicotti' },
  { label: 'Manjari', value: 'manjari' },
  { label: 'Manometer', value: 'manometer' },
  { label: 'Manometer Sans', value: 'manometer-sans' },
  { label: 'Manteiga Gorda', value: 'manteiga-gorda' },
  { label: 'Maple', value: 'maple' },
  { label: 'Marcia', value: 'marcia' },
  { label: 'Marco', value: 'marco' },
  { label: 'Mariné', value: 'marine' },
  { label: 'Marshmallow', value: 'marshmallow-positype' },
  { label: 'Marvin', value: 'marvin' },
  { label: 'Maryam', value: 'maryam' },
  { label: 'Marydale', value: 'marydale' },
  { label: 'Mason Sans', value: 'mason-sans' },
  { label: 'Mason Serif', value: 'mason-serif' },
  { label: 'Masqualero', value: 'masqualero' },
  { label: 'Mati', value: 'mati' },
  { label: 'Matrix II', value: 'matrix-ii' },
  { label: 'MattB', value: 'mattb' },
  { label: 'Mauritius', value: 'mauritius' },
  { label: 'Maxular', value: 'maxular' },
  { label: 'Mayence', value: 'mayence' },
  { label: 'Meatball', value: 'meatball' },
  { label: 'Meera', value: 'meera' },
  { label: 'Megrim', value: 'megrim' },
  { label: 'Meloche', value: 'meloche' },
  { label: 'Memimas Pro', value: 'memimas-pro' },
  { label: 'Memoriam', value: 'memoriam' },
  { label: 'Mencken', value: 'mencken' },
  { label: 'Menco', value: 'menco' },
  { label: 'Meno Banner', value: 'meno-banner' },
  { label: 'Meno Display', value: 'meno-display' },
  { label: 'Meno Text', value: 'meno-text' },
  { label: 'Mentone', value: 'mentone' },
  { label: 'Merel', value: 'merel' },
  { label: 'Merengue Script', value: 'merengue-script' },
  { label: 'Merriweather', value: 'merriweather' },
  { label: 'Mesquite', value: 'mesquite' },
  { label: 'Metalista', value: 'metalista' },
  { label: 'Metallophile Sp8', value: 'metallophile-sp8' },
  { label: 'Metronic Slab', value: 'metronic-slab' },
  { label: 'Mezz', value: 'mezz' },
  { label: 'Mic 32 New', value: 'mic-32-new' },
  { label: 'Might Makes Right BB', value: 'might-makes-right-bb' },
  { label: 'Mighty Slab', value: 'mighty-slab' },
  { label: 'Milibus', value: 'milibus' },
  { label: 'Milk Script', value: 'milk-script' },
  { label: 'Milka', value: 'milka' },
  { label: 'Millesime', value: 'millesime' },
  { label: 'Millettre', value: 'millettre' },
  { label: 'Milonguita', value: 'milonguita' },
  { label: 'Mina', value: 'mina' },
  { label: 'Minerva Modern', value: 'minerva-modern' },
  { label: 'Mingler', value: 'mingler' },
  { label: 'Minion', value: 'minion' },
  { label: 'Minion 3', value: 'minion-3' },
  { label: 'Minion 3 Caption', value: 'minion-3-caption' },
  { label: 'Minion 3 Display', value: 'minion-3-display' },
  { label: 'Minion 3 Subhead', value: 'minion-3-subhead' },
  { label: 'Ministry', value: 'ministry' },
  { label: 'Mislab', value: 'mislab' },
  { label: 'Missionary', value: 'missionary' },
  { label: 'Mitigate', value: 'mitigate' },
  { label: 'Mittwoch', value: 'mittwoch' },
  { label: 'Mobley Sans', value: 'mobley-sans' },
  { label: 'Mobley Sans Condensed', value: 'mobley-sans-condensed' },
  { label: 'Mobley Serif', value: 'mobley-serif' },
  { label: 'Mobley Serif Condensed', value: 'mobley-serif-condensed' },
  { label: 'Moby', value: 'moby' },
  { label: 'Modesto', value: 'modesto' },
  { label: 'Modish', value: 'modish' },
  { label: 'Modula', value: 'modula' },
  { label: 'Modula Round', value: 'modula-round' },
  { label: 'Mojito', value: 'mojito' },
  { label: 'Mokoko', value: 'mokoko' },
  { label: 'Molto', value: 'molto' },
  { label: 'Monarcha', value: 'monarcha' },
  { label: 'Mongoose', value: 'mongoose' },
  { label: 'Moniker', value: 'moniker-basic' },
  { label: 'Mono45 Headline', value: 'mono45-headline' },
  { label: 'Monotalic', value: 'monotalic' },
  { label: 'Monotype Grotesque', value: 'monotype-grotesque' },
  { label: 'Monotype Modern Display', value: 'monotype-modern-display' },
  { label: 'Monsal Gothic', value: 'monsal-gothic' },
  { label: 'Montag', value: 'montag' },
  { label: 'Montana', value: 'montana' },
  { label: 'Montserrat', value: 'montserrat' },
  { label: 'Moret', value: 'moret' },
  { label: 'Mortise', value: 'mortise' },
  { label: 'Mostra Nuova', value: 'mostra-nuova' },
  { label: 'Motion', value: 'motion' },
  { label: 'Motiva Sans', value: 'motiva-sans' },
  { label: 'Motor', value: 'motor' },
  { label: 'Mr Darcy', value: 'mr-darcy' },
  { label: 'Mr Eaves Sans', value: 'mr-eaves-sans' },
  { label: 'Mr Eaves XL', value: 'mr-eaves-xl' },
  { label: 'Mrs Eaves', value: 'mrs-eaves' },
  { label: 'Mrs Eaves XL', value: 'mrs-eaves-xl' },
  { label: 'Mufferaw', value: 'mufferaw' },
  { label: 'Muffin', value: 'muffin' },
  { label: 'Muli', value: 'muli' },
  { label: 'Multi Display', value: 'multi-display' },
  { label: 'Multi Text', value: 'multi-text' },
  { label: 'Muscle', value: 'muscle' },
  { label: 'Museo', value: 'museo' },
  { label: 'Museo Sans', value: 'museo-sans' },
  { label: 'Museo Slab', value: 'museo-slab' },
  { label: 'MVB Aunt Mildred', value: 'mvb-aunt-mildred' },
  { label: 'MVB Bossa Nova', value: 'mvb-bossa-nova' },
  { label: 'MVB Bovine', value: 'mvb-bovine' },
  { label: 'MVB Cafe Mimi', value: 'mvb-cafe-mimi' },
  { label: 'MVB Calliope', value: 'mvb-calliope' },
  { label: 'MVB Celestia Antiqua', value: 'mvb-celestia-antiqua' },
  { label: "MVB Chanson d'Amour", value: 'mvb-chanson-damour' },
  { label: 'MVB Diazo Condensed', value: 'mvb-diazo' },
  { label: 'MVB Dovetail', value: 'mvb-dovetail' },
  { label: 'MVB Embarcadero Pro', value: 'mvb-embarcadero-pro' },
  {
    label: 'MVB Embarcadero Pro Condensed',
    value: 'mvb-embarcadero-pro-condensed',
  },
  { label: 'MVB Emmascript', value: 'mvb-emmascript' },
  { label: 'MVB Fantabular', value: 'mvb-fantabular' },
  { label: 'MVB Fantabular Sans', value: 'mvb-fantabular-sans' },
  { label: 'MVB Grenadine', value: 'mvb-grenadine' },
  { label: 'MVB Greymantle', value: 'mvb-greymantle' },
  { label: 'MVB Gryphius', value: 'mvb-gryphius' },
  { label: 'MVB Hotsy Totsy', value: 'mvb-hotsy-totsy' },
  { label: 'MVB Magnesium', value: 'mvb-magnesium' },
  { label: 'MVB Magnolia', value: 'mvb-magnolia' },
  { label: 'MVB Margin', value: 'mvb-margin' },
  { label: 'MVB Mascot', value: 'mvb-mascot' },
  { label: 'MVB Peccadillo', value: 'mv-peccadillo' },
  { label: 'MVB Pedestria', value: 'mvb-pedestria' },
  { label: 'MVB Pedestria Pict', value: 'mvb-pedestria-pict' },
  { label: 'MVB Pinecone', value: 'mvb-pinecone' },
  { label: 'MVB Sacre Bleu', value: 'mvb-sacre-bleu' },
  { label: 'MVB Sirenne', value: 'mvb-sirenne' },
  { label: 'MVB Solano Gothic Pro', value: 'mvb-solano-gothic-pro' },
  { label: 'MVB Solitaire Pro', value: 'mvb-solitaire-pro' },
  { label: 'MVB Verdigris Pro', value: 'mvb-verdigris-pro' },
  { label: 'Myriad', value: 'myriad' },
  { label: 'Myriad Arabic', value: 'myriad-arabic' },
  { label: 'Myriad Bengali', value: 'myriad-bengali' },
  { label: 'Myriad Devanagari', value: 'myriad-devanagari' },
  { label: 'Myriad Hebrew', value: 'myriad-hebrew' },
  { label: 'Myriad Wild', value: 'myriad-wild' },
  { label: 'Mythos', value: 'mythos' },
  { label: 'Nagomi', value: 'nagomi' },
  { label: 'Nanum Brush Script', value: 'nanum-brush-script' },
  { label: 'Nanum Gothic', value: 'nanum-gothic' },
  { label: 'Nanum Gothic Coding', value: 'nanum-gothic-coding' },
  { label: 'Nanum Myeongjo', value: 'nanum-myeongjo' },
  { label: 'Nanum Pen Script', value: 'nanum-pen-script' },
  { label: 'Naresuan', value: 'naresuan' },
  { label: 'Narly', value: 'narly' },
  { label: 'Nasalization', value: 'nasalization' },
  { label: 'Nassim', value: 'nassim' },
  { label: 'Nassim Arabic', value: 'nassim-arabic' },
  { label: 'Natalya', value: 'natalya' },
  { label: 'Natura', value: 'natura' },
  { label: 'Nautica', value: 'nautica' },
  { label: 'Navigo', value: 'navigo' },
  { label: 'Nazare', value: 'nazare' },
  { label: 'Neil Bold', value: 'neil-bold' },
  { label: 'Nelson ', value: 'nelson' },
  { label: 'Neo Sans', value: 'neo-sans' },
  { label: 'Neonoir', value: 'neonoir' },
  { label: 'NeonStream', value: 'neonstream' },
  { label: 'Neplus', value: 'neplus' },
  { label: 'Neue Aachen', value: 'neue-aachen' },
  { label: 'Neue Haas Grotesk', value: 'neue-haas-grotesk' },
  { label: 'Neue Haas Unica', value: 'neue-haas-unica' },
  { label: 'Neue Kabel', value: 'neue-kabel' },
  { label: 'Neuropol', value: 'neuropol' },
  { label: 'Neusa Next', value: 'neusa-next' },
  { label: 'Neuzeit Grotesk', value: 'neuzeit-grotesk' },
  { label: 'Neuzon', value: 'neuzon' },
  { label: 'New Atten', value: 'new-atten' },
  { label: 'New Atten Round ', value: 'new-atten-round' },
  { label: 'New Farm', value: 'farm-new' },
  { label: 'New Frank', value: 'frank-new' },
  { label: 'New Herman', value: 'new-herman' },
  { label: 'New Hero', value: 'new-hero' },
  { label: 'New Kansas', value: 'new-kansas' },
  { label: 'New Reason', value: 'new-reason' },
  { label: 'New Rubrik', value: 'new-rubrik' },
  { label: 'New Rubrik Edge', value: 'new-rubrik-edge' },
  { label: 'New Spirit', value: 'new-spirit' },
  { label: 'New Zen', value: 'zen-new' },
  { label: 'Newbery Sans', value: 'newbery-sans' },
  { label: 'Newcomen', value: 'newcomen' },
  { label: 'News Gothic', value: 'news-gothic' },
  { label: 'Niagara', value: 'niagara' },
  { label: 'Nimbus Roman', value: 'nimbus-roman' },
  { label: 'Nimbus Sans', value: 'nimbus-sans' },
  { label: 'Nimbus Sans Devanagari', value: 'nimbus-sans-devanagari' },
  { label: 'NitalagoRera', value: 'nitalagorera' },
  { label: 'NitalagoRuika', value: 'nitalagoruika' },
  { label: 'Niveau Grotesk', value: 'niveau-grotesk' },
  { label: 'Noam Text', value: 'noam-text' },
  { label: 'Nobel', value: 'nobel' },
  { label: 'Nocturne Serif', value: 'nocturne-serif' },
  { label: 'Noh Optique Display', value: 'optique-display' },
  { label: 'Noh Sori', value: 'noh-sori' },
  { label: 'Noort', value: 'noort' },
  { label: 'NotCaslon', value: 'notcaslon' },
  { label: 'Nothing', value: 'nothing' },
  { label: 'Noto Sans', value: 'noto-sans' },
  { label: 'Noto Sans Arabic', value: 'noto-sans-arabic' },
  { label: 'Noto Sans Arabic Condensed', value: 'noto-sans-arabic-condensed' },
  {
    label: 'Noto Sans Arabic Extra Condensed',
    value: 'noto-sans-arabic-extra-condensed',
  },
  {
    label: 'Noto Sans Arabic Semi Condensed',
    value: 'noto-sans-arabic-semi-condensed',
  },
  { label: 'Noto Sans CJK JP', value: 'noto-sans-cjk-jp' },
  { label: 'Noto Sans CJK KR', value: 'noto-sans-cjk-kr' },
  { label: 'Noto Sans Display', value: 'noto-sans-display' },
  { label: 'Noto Sans Hebrew', value: 'noto-sans-hebrew' },
  { label: 'Noto Sans Hebrew Condensed', value: 'noto-sans-hebrew-condensed' },
  {
    label: 'Noto Sans Hebrew Extra Condensed',
    value: 'noto-sans-hebrew-extra-condensed',
  },
  {
    label: 'Noto Sans Hebrew Semi Condensed',
    value: 'noto-sans-hebrew-semi-condensed',
  },
  { label: 'Noto Serif', value: 'noto-serif' },
  { label: 'Nouvelle Vague', value: 'nouvelle-vague' },
  { label: 'Nove', value: 'nove' },
  { label: 'Novecento Carved', value: 'novecento-carved' },
  { label: 'Novecento Sans', value: 'novecento-sans' },
  { label: 'Novecento Slab', value: 'novecento-slab' },
  { label: 'Novel', value: 'novel' },
  { label: 'Novel Display', value: 'novel-display' },
  { label: 'Novel Mono', value: 'novel-mono' },
  { label: 'Novel Sans ', value: 'novel-sans' },
  { label: 'Novel Sans Hair', value: 'novel-sans-hair' },
  { label: 'Nubb', value: 'nubb' },
  { label: 'Nudista', value: 'nudista' },
  { label: 'Nueva', value: 'nueva' },
  { label: 'Number Five ', value: 'number-five' },
  { label: 'Nunito', value: 'nunito' },
  { label: 'Nunito Sans', value: 'nunito-sans' },
  { label: 'Nutcracker', value: 'nutcracker' },
  { label: 'Objektiv', value: 'objektiv' },
  { label: 'Obliqua Sans', value: 'obliqua-sans' },
  { label: 'Oblong', value: 'oblong' },
  { label: 'Obvia', value: 'obvia' },
  { label: 'Obviously', value: 'obviously' },
  { label: 'OCR A', value: 'ocr-a' },
  { label: 'OCR B', value: 'ocr-b' },
  { label: 'Octin', value: 'octin' },
  { label: 'Octynaz', value: 'octynaz' },
  { label: 'Ode', value: 'ode' },
  { label: 'Odile', value: 'odile' },
  { label: 'Odisseia', value: 'odisseia' },
  { label: 'OFL Sorts Mill Goudy', value: 'ofl-sorts-mill-goudy' },
  { label: 'Ohno Blazeface', value: 'ohno-blazeface' },
  { label: 'Ohno Fatface', value: 'ohno-fatface' },
  { label: 'Old Man Eloquent', value: 'old-man-eloquent' },
  { label: 'Old Standard', value: 'old-standard' },
  { label: 'Olicana', value: 'olicana' },
  { label: 'Olidia', value: 'olidia' },
  { label: 'Omnes', value: 'omnes' },
  { label: 'Omnes Arabic', value: 'omnes-arabic' },
  { label: 'Omnes Cyrillic', value: 'omnes-cyrillic' },
  { label: 'Omnium', value: 'omnium' },
  { label: 'Omnium Tagline', value: 'omnium-tagline' },
  { label: 'Opake', value: 'opake' },
  { label: 'Open Sans', value: 'open-sans' },
  { label: 'Operetta', value: 'operetta' },
  { label: 'Orator', value: 'orator' },
  { label: 'Orbe', value: 'orbe' },
  { label: 'Orbitron', value: 'orbitron' },
  { label: 'Orgovan', value: 'orgovan' },
  { label: 'Origin Super Condensed', value: 'origin-super-condensed' },
  { label: 'Origins', value: 'origins' },
  { label: 'Orpheus', value: 'orpheus' },
  { label: 'Oscine', value: 'oscine' },
  { label: 'Oskar', value: 'oskar' },
  { label: 'Oskar Inline', value: 'oskar-inline' },
  { label: 'Oswald', value: 'oswald' },
  { label: 'Ottomat', value: 'ottomat' },
  { label: 'Outwest', value: 'outwest' },
  { label: 'Oxtail', value: 'oxtail' },
  { label: 'Oxygen', value: 'oxygen' },
  { label: 'P22 Allyson Pro', value: 'p22-allyson-pro' },
  { label: 'P22 Aragon', value: 'p22-aragon' },
  { label: 'P22 Arts and Crafts', value: 'p22-arts-and-crafts' },
  { label: 'P22 Brass Script Pro', value: 'p22-brass-script-pro' },
  { label: 'P22 Cezanne', value: 'p22-cezanne' },
  { label: 'P22 Dearest', value: 'p22-dearest' },
  { label: 'P22 FLW Exhibition', value: 'p22-flw-exhibition' },
  { label: 'P22 FLW Midway', value: 'p22-flw-midway' },
  { label: 'P22 FLW Terracotta', value: 'p22-flw-terracotta' },
  { label: 'P22 Franklin Caslon', value: 'p22-franklin-caslon' },
  { label: 'P22 Hopper', value: 'p22-hopper' },
  { label: 'P22 Klauss Kursiv', value: 'p22-klauss-kursiv' },
  { label: 'P22 Lucilee Pro', value: 'p22-lucilee-pro' },
  { label: 'P22 Mackinac', value: 'p22-mackinac' },
  { label: 'P22 Marcel', value: 'p22-marcel' },
  { label: 'P22 Muschamp Pro', value: 'p22-muschamp-pro' },
  { label: 'P22 Pooper Black', value: 'p22-pooper-black' },
  { label: 'P22 Pouty Pro', value: 'p22-pouty-pro' },
  { label: 'P22 Sneaky Pro', value: 'p22-sneaky-pro' },
  { label: 'P22 Stanyan', value: 'p22-stanyan' },
  { label: 'P22 Stickley', value: 'p22-stickley' },
  { label: 'P22 Sweepy Pro', value: 'p22-sweepy-pro' },
  { label: 'P22 Underground', value: 'p22-underground' },
  { label: 'P22 Zaner', value: 'p22-zaner' },
  { label: 'Pacifico', value: 'pacifico' },
  { label: 'Paganini', value: 'paganini' },
  { label: 'Pakenham', value: 'pakenham' },
  { label: 'Paralucent', value: 'paralucent' },
  { label: 'Parisine', value: 'parisine' },
  { label: 'Parisine Office', value: 'parisine-office' },
  { label: 'Parisine Plus', value: 'parisine-plus' },
  { label: 'Park Lane', value: 'park-lane' },
  { label: 'Parkside', value: 'parkside' },
  { label: 'Parkway', value: 'parkway' },
  { label: 'Parppim', value: 'parppim' },
  { label: 'Pauline', value: 'pauline' },
  { label: 'Peachy Keen JF', value: 'peachy-keen-jf' },
  { label: 'Pelago', value: 'pelago' },
  { label: 'Pepperwood', value: 'pepperwood' },
  { label: 'Peregroy JF', value: 'peregroy-jf' },
  { label: 'Permanent Marker', value: 'permanent-marker' },
  { label: 'Permanent Marker Pro', value: 'permanent-marker-pro' },
  { label: 'Pesto Fresco', value: 'pesto-fresco' },
  { label: 'Petala', value: 'petala' },
  { label: 'Petersburg', value: 'petersburg' },
  { label: 'Philosopher', value: 'philosopher' },
  { label: 'Phoreus Cherokee', value: 'phoreus-cherokee' },
  { label: 'Piepie', value: 'piepie' },
  { label: 'Pika Ultra Script', value: 'pika-ultra-script' },
  { label: 'Pilar', value: 'pilar' },
  { label: 'Pill Gothic', value: 'pill-gothic' },
  { label: 'Pilsner', value: 'pilsner' },
  { label: 'Pinot Grigio Modern', value: 'pinot-grigio-modern' },
  { label: 'Pique', value: 'pique' },
  { label: 'Pirulen', value: 'pirulen' },
  { label: 'Pitch', value: 'pitch' },
  { label: 'Pitchfork', value: 'pitchfork' },
  { label: 'Plantin', value: 'plantin' },
  { label: 'Platelet', value: 'platelet' },
  { label: 'Plau', value: 'plau' },
  { label: 'Playfair Display', value: 'playfair' },
  { label: 'Plume', value: 'plume' },
  { label: 'Plumero Script', value: 'plumeroscript' },
  { label: 'PMN Caecilia', value: 'pmn-caecilia' },
  { label: 'PMN Caecilia Sans', value: 'pmn-caecilia-sans' },
  { label: 'Poetica', value: 'poetica' },
  { label: 'Poiret One', value: 'poiret-one' },
  { label: 'Poleno', value: 'poleno' },
  { label: 'Politica', value: 'politica' },
  { label: 'Pollen', value: 'pollen' },
  { label: 'Ponderosa Std', value: 'ponderosa-std' },
  { label: 'Poplar', value: 'poplar' },
  { label: 'Poppi', value: 'poppi' },
  { label: 'Poppins', value: 'poppins' },
  { label: 'Portada', value: 'portada' },
  { label: 'Postino', value: 'postino' },
  { label: 'Potta One', value: 'potta-one' },
  { label: 'Powerlift', value: 'powerlift' },
  { label: 'PragmataPro Fraktur', value: 'pragmatapro-fraktur' },
  { label: 'Pragmatica', value: 'pragmatica' },
  { label: 'Pragmatica Slabserif', value: 'pragmatica-slabserif' },
  { label: 'Praxis Next', value: 'praxis-next' },
  { label: 'Prenton', value: 'prenton' },
  { label: 'Presicav', value: 'presicav' },
  { label: 'Presley Slab', value: 'presley-slab' },
  { label: 'Pressio', value: 'pressio' },
  { label: 'Pressio Compressed', value: 'pressio-compressed' },
  { label: 'Pressio Condensed', value: 'pressio-condensed' },
  { label: 'Pressio X-Compressed', value: 'pressio-x-compressed' },
  { label: 'Prestige Elite', value: 'prestige-elite' },
  { label: 'Primot', value: 'primot' },
  { label: 'Printf', value: 'printf' },
  { label: 'Priori Acute', value: 'priori-acute' },
  { label: 'Priori Sans', value: 'priori-sans' },
  { label: 'Priori Serif', value: 'priori-serif' },
  { label: 'Private Sans', value: 'private-sans' },
  { label: 'Productus', value: 'productus' },
  { label: 'Professor', value: 'professor' },
  { label: 'Professor Minty', value: 'professor-minty' },
  { label: 'Profile', value: 'profile' },
  { label: 'Proforma', value: 'proforma' },
  { label: 'Program', value: 'program' },
  { label: 'Prohibition', value: 'prohibition' },
  { label: 'Prometo', value: 'prometo' },
  { label: 'Prosaic', value: 'prosaic' },
  { label: 'Protipo', value: 'protipo' },
  { label: 'Proxima Nova', value: 'proxima-nova' },
  { label: 'Proxima Soft', value: 'proxima-soft' },
  { label: 'PS Fournier', value: 'ps-fournier' },
  { label: 'PT Mono', value: 'pt-mono' },
  { label: 'PT Sans', value: 'pt-sans' },
  { label: 'PT Sans Pro', value: 'pt-sans-pro' },
  { label: 'PT Serif', value: 'pt-serif' },
  { label: 'PT Serif Pro', value: 'pt-serif-pro' },
  { label: 'Pulpo', value: 'pulpo' },
  { label: 'Pulpo Rust', value: 'pulpo-rust' },
  { label: 'Pupcat', value: 'pupcat' },
  { label: 'Purista', value: 'purista' },
  { label: 'Quador', value: 'quador' },
  { label: 'Quador Display', value: 'quador-display' },
  { label: 'Quagmire', value: 'quagmire' },
  { label: 'Quake', value: 'quake' },
  { label: 'Quara', value: 'quara' },
  { label: 'Quasimoda', value: 'quasimoda' },
  { label: 'Quatro', value: 'quatro' },
  { label: 'Quatro Slab', value: 'quatro-slab' },
  { label: 'Questa', value: 'questa' },
  { label: 'Questa Grande', value: 'questa-grande' },
  { label: 'Questa Sans', value: 'questa-sans' },
  { label: 'Questa Slab', value: 'questa-slab' },
  { label: 'Quiche Display', value: 'quiche-display' },
  { label: 'Quiche Sans', value: 'quiche-sans' },
  { label: 'Quiche Text', value: 'quiche-text' },
  { label: 'Quicksand', value: 'quicksand' },
  { label: 'Quiet Sans', value: 'quiet-sans' },
  { label: 'Quimby', value: 'quimby' },
  { label: 'Quire Sans', value: 'quire-sans' },
  { label: 'Quiroh', value: 'quiroh' },
  { label: 'Quita', value: 'quita' },
  { label: 'Raceway', value: 'raceway' },
  { label: 'Rachana', value: 'rachana' },
  { label: 'Rad', value: 'rad' },
  { label: 'Rajdhani', value: 'rajdhani' },
  { label: 'Raleway', value: 'raleway' },
  { label: 'Raleway v2.0 (deprecated)', value: 'raleway-v20-deprecated' },
  { label: 'Rama Gothic', value: 'rama-gothic' },
  { label: 'Rama Slab', value: 'rama-slab' },
  { label: 'Rampart One', value: 'rampart-one' },
  { label: 'Rasa', value: 'rasa' },
  { label: 'Ratio', value: 'ratio' },
  { label: 'Ratio Modern', value: 'ratio-modern' },
  { label: 'Reagan', value: 'reagan' },
  { label: 'Realist', value: 'realist' },
  { label: 'Recherche', value: 'recherche' },
  { label: 'Reed', value: 'reed' },
  { label: 'Reenie Beanie', value: 'reenie-beanie' },
  { label: 'Refrigerator Deluxe', value: 'refrigerator-deluxe' },
  { label: 'Reggae One', value: 'reggae-one' },
  { label: 'Regulator Nova', value: 'regulator-nova' },
  { label: 'Reiner Script', value: 'reiner-script' },
  { label: 'Reklame Script', value: 'reklame-script' },
  { label: 'Relation', value: 'relation' },
  { label: 'Remedy', value: 'remedy' },
  { label: 'Remora Sans', value: 'remora-sans' },
  { label: 'Renata', value: 'renata' },
  { label: 'Report', value: 'report' },
  { label: 'Report School', value: 'report-school' },
  { label: 'RePublic', value: 'republic' },
  { label: 'Rera', value: 'rera' },
  { label: 'Restore', value: 'restore' },
  { label: 'Retiro', value: 'retiro' },
  { label: 'Revolution Gothic', value: 'revolution-gothic' },
  { label: 'Rexlia', value: 'rexlia' },
  { label: 'Rialto dF', value: 'rialto-df' },
  { label: 'Rieven Uncial', value: 'rieven-uncial' },
  { label: 'Rift', value: 'rift' },
  { label: 'Rift Soft', value: 'rift-soft' },
  { label: 'Rig Shaded', value: 'rig-shaded' },
  { label: 'Rig Solid', value: 'rig-solid' },
  { label: 'Rigatoni', value: 'rigatoni' },
  { label: 'Rigid Square', value: 'rigid-square' },
  { label: 'Rinse', value: 'rinse' },
  { label: 'Rival', value: 'rival' },
  { label: 'Rival Sans', value: 'rival-sans' },
  { label: 'Rix Busan Station', value: 'rixbusanstation' },
  { label: 'Rix Citrus Life', value: 'rixcitruslife' },
  { label: 'Rix Cute Cat', value: 'rixcutecat' },
  { label: 'Rix Dokdo', value: 'dokdo' },
  { label: 'Rix Dongnim Gothic', value: 'rixdongnimgothic' },
  { label: 'Rix Gwangalli', value: 'rix-gwangalli' },
  { label: 'Rix Jangs', value: 'rix-jangs' },
  { label: 'Rix MMj SE', value: 'rix-mmjse' },
  { label: 'Rix Rak Sans', value: 'rix-rak-sans' },
  { label: 'Rix Rak Serif', value: 'rix-rak-serif' },
  { label: 'Rix Sejongro Gulim', value: 'rix-sejongro-gulim' },
  { label: 'Rix Sin Go SE ', value: 'rix-sin-go-se' },
  { label: 'Rix Sin Head', value: 'rix-sin-head' },
  { label: 'Rix Starlight', value: 'rix-starlight' },
  { label: 'Rix Straight', value: 'rix-straight' },
  { label: 'Rix Toy Story', value: 'rix-toy-story' },
  { label: 'Rizado Script', value: 'rizado-script' },
  { label: 'Ro Brush Std', value: 'ro-brush-std' },
  { label: 'Ro Nikkatsu Sei Kai Std', value: 'ro-nikkatsu-sei-kai-std' },
  { label: 'Ro San Std', value: 'ro-san-std' },
  { label: 'Ro Shino Std', value: 'ro-shino-std' },
  { label: 'Roboto', value: 'roboto' },
  { label: 'Roboto Slab', value: 'roboto-slab' },
  { label: 'Roc Grotesk', value: 'roc-grotesk' },
  { label: 'Rochester Pro', value: 'rochester-pro' },
  { label: 'Rock Salt Pro', value: 'rock-salt-pro' },
  { label: 'Rocket', value: 'rocket' },
  { label: 'RocknRoll One', value: 'rocknroll-one' },
  { label: 'Rockwell', value: 'rockwell' },
  { label: 'Rockwell Nova', value: 'rockwell-nova' },
  { label: 'Rodchenko', value: 'rodchenko' },
  { label: 'Rogliano', value: 'rogliano' },
  { label: 'Rogue Sans', value: 'rogue-sans' },
  { label: 'Rogue Serif', value: 'rogue-serif' },
  { label: 'Rokkitt', value: 'rokkitt' },
  { label: 'Rollerscript', value: 'rollerscript' },
  { label: 'Roma', value: 'roma' },
  { label: 'Ronnia', value: 'ronnia' },
  { label: 'Rooney', value: 'rooney' },
  { label: 'Rooney Sans', value: 'rooney-sans' },
  { label: 'Ropa Mix', value: 'ropa-mix' },
  { label: 'Ropa Sans', value: 'ropa-sans' },
  { label: 'Ropa Soft', value: 'ropa-soft' },
  { label: 'Rosarian', value: 'rosarian' },
  { label: 'Rosario', value: 'rosario' },
  { label: 'Rosella', value: 'rosella' },
  { label: 'Rosewood', value: 'rosewood' },
  { label: 'Rothwood', value: 'rothwood' },
  { label: 'Royale', value: 'royale' },
  { label: 'Rubik', value: 'rubik' },
  { label: 'RuckSack', value: 'rucksack' },
  { label: 'Rufina', value: 'rufina' },
  { label: 'Rufina Stencil', value: 'rufina-stencil' },
  { label: 'Ruika', value: 'ruika' },
  { label: 'Rukou', value: 'rukou' },
  { label: 'Rumba', value: 'rumba' },
  { label: 'Runda', value: 'runda' },
  { label: 'Ryo Display PlusN', value: 'ryo-display-plusn' },
  { label: 'Ryo Gothic PlusN', value: 'ryo-gothic-plusn' },
  { label: 'Ryo Text PlusN', value: 'ryo-text-plusn' },
  { label: 'Sabbath Black', value: 'sabbath-black' },
  { label: 'Saber', value: 'saber' },
  { label: 'Sabon', value: 'sabon' },
  { label: 'Saffran', value: 'saffran' },
  { label: 'Sail', value: 'sail' },
  { label: 'Salamat', value: 'salamat' },
  { label: 'Salden', value: 'salden' },
  { label: 'Salsbury', value: 'salsbury' },
  { label: 'Salsero', value: 'salsero' },
  { label: 'Salvation', value: 'salvation' },
  { label: 'Samantha', value: 'samantha' },
  { label: 'San Rafael', value: 'san-rafael' },
  { label: 'Sandoll 02', value: 'sandoll-02' },
  { label: 'Sandoll 4BYeonpil', value: 'sandoll-4byeonpil' },
  { label: 'Sandoll Achim', value: 'sandoll-achim' },
  { label: 'Sandoll Andante', value: 'sandoll-andante' },
  { label: 'Sandoll BaikzongyulPil', value: 'sandoll-baikzongyulpil' },
  { label: 'Sandoll BlueNight', value: 'sandoll-bluenight' },
  { label: 'Sandoll CoffeeAndDonut', value: 'sandoll-coffeeanddonut' },
  { label: 'Sandoll GongbyunggakTab', value: 'sandoll-gongbyunggaktab' },
  { label: 'Sandoll GothicNeo1', value: 'sandoll-gothicneo1' },
  { label: 'Sandoll GothicNeo2', value: 'sandoll-gothicneo2' },
  { label: 'Sandoll GothicNeo3', value: 'sandoll-gothicneo3' },
  { label: 'Sandoll GreenTeaLatte', value: 'sandoll-greentealatte' },
  { label: 'Sandoll Jebi', value: 'sandoll-jebi' },
  { label: 'Sandoll Jebi2', value: 'sandoll-jebi2' },
  { label: 'Sandoll KwangsooTwo', value: 'sandoll-kwangsootwo' },
  { label: 'Sandoll Kwangya', value: 'sandoll-kwangya' },
  { label: 'Sandoll Mojito', value: 'sandoll-mojito' },
  { label: 'Sandoll MyeongjoNeo1', value: 'sandoll-myeongjoneo1' },
  { label: 'Sandoll Objet', value: 'sandoll-objet' },
  { label: 'Sandoll Ongothic', value: 'sandoll-ongothic' },
  { label: 'Sandoll Press', value: 'sandoll-press' },
  { label: 'Santino', value: 'santino' },
  { label: 'Sanvito', value: 'sanvito' },
  { label: 'Sarah Script', value: 'sarah-script' },
  { label: 'Sarina', value: 'sarina' },
  { label: 'Satisfy', value: 'satisfy' },
  { label: 'Saucy Millionaire', value: 'saucy-millionaire' },
  { label: 'Sauna', value: 'sauna-pro' },
  { label: 'Savanna Script', value: 'savanna-script' },
  { label: 'Sawarabi Gothic', value: 'sawarabi-gothic' },
  { label: 'Sawarabi Mincho', value: 'sawarabi-mincho' },
  { label: 'Scandia', value: 'scandia' },
  { label: 'Scarlet', value: 'scarlet' },
  { label: 'Scarlet Script', value: 'scarlet-script' },
  { label: 'Scarlet Wood', value: 'scarlet-wood' },
  { label: 'Scheme', value: 'scheme' },
  { label: 'Schnebel Sans', value: 'schnebel-sans' },
  { label: 'Schnebel Sans ME', value: 'schnebel-sans-me' },
  { label: 'Schoolbell Pro', value: 'schoolbell-pro' },
  { label: 'SchoolBook', value: 'schoolbook' },
  { label: 'Schwager Sans', value: 'schwager-sans' },
  { label: 'Scriptorama Markdown JF', value: 'scriptorama-markdown-jf' },
  { label: 'Scriptorama Tradeshow JF', value: 'scriptorama-tradeshow-jf' },
  { label: 'Scrivano', value: 'scrivano' },
  { label: 'Seashore Pro', value: 'seashore-pro' },
  { label: 'Seasoned Hostess', value: 'seasoned-hostess' },
  { label: 'Seaweed Script Pro', value: 'seaweed-script-pro' },
  { label: 'Semplicita', value: 'semplicita' },
  { label: 'Senator', value: 'senator' },
  { label: 'September', value: 'september' },
  { label: 'September N', value: 'september-n' },
  { label: 'Serenity', value: 'serenity' },
  { label: 'Serifa', value: 'serifa' },
  { label: 'Servus Slab', value: 'servus-slab' },
  { label: 'Setimo', value: 'setimo' },
  { label: 'Sewn', value: 'sewn' },
  { label: 'Shabby Chic', value: 'shabby-chic' },
  { label: 'Shackleton', value: 'shackleton' },
  { label: 'Shadows Into Light', value: 'shadows-into-light' },
  { label: 'Sharik Sans', value: 'sharik-sans' },
  { label: 'Sharktooth', value: 'sharktooth' },
  { label: 'Sheepman', value: 'sheepman' },
  { label: 'Sheila', value: 'sheila' },
  { label: 'Shelby', value: 'shelby' },
  { label: 'Shimano', value: 'shimano' },
  { label: 'Shippori Mincho', value: 'shippori-mincho' },
  { label: 'Shiva', value: 'shiva' },
  { label: 'Shlop', value: 'shlop' },
  { label: 'Shogun', value: 'shogun' },
  { label: 'Shrub', value: 'shrub' },
  { label: 'Shuriken Boy', value: 'shuriken-boy' },
  { label: 'Signo', value: 'signo' },
  { label: 'Silicone', value: 'silicone' },
  { label: 'Silverstein', value: 'silverstein' },
  { label: 'Single Day', value: 'single-day' },
  { label: 'Sinter', value: 'sinter' },
  { label: 'Sirba', value: 'sirba' },
  { label: 'Sirin Stencil', value: 'sirin-stencil' },
  { label: 'Siruca', value: 'siruca' },
  { label: 'Sister Frisky', value: 'sister-frisky' },
  { label: 'Sisters', value: 'sisters' },
  { label: 'Six Hands', value: 'six-hands' },
  { label: 'Sketchnote', value: 'sketchnote' },
  { label: 'Skippy Sharp', value: 'skippy-sharp' },
  { label: 'Skolar', value: 'skolar' },
  { label: 'Skolar Devanagari', value: 'skolar-devanagari' },
  { label: 'Skolar Gujarati', value: 'skolar-gujarati' },
  { label: 'Skolar Sans', value: 'skolar-sans' },
  { label: 'Skolar Sans Arabic', value: 'skolar-sans-arabic' },
  { label: 'Skope', value: 'skope' },
  { label: 'Slabo', value: 'slabo' },
  { label: 'Slack Casual', value: 'slack-casual' },
  { label: 'Sloop Script', value: 'sloop-script' },
  { label: 'Smoothy', value: 'smoothy' },
  { label: 'Sneakers', value: 'sneakers' },
  { label: 'Sneakers Script', value: 'sneakers-script' },
  { label: 'Snicker', value: 'snicker' },
  { label: 'Sniglet', value: 'sniglet' },
  { label: 'Soap', value: 'soap' },
  { label: 'Social Gothic', value: 'social-gothic' },
  { label: 'Soda Script', value: 'soda-script' },
  { label: 'Sofachrome', value: 'sofachrome' },
  { label: 'Sofia', value: 'sofia' },
  { label: 'Sol', value: 'sol' },
  { label: 'Soleil', value: 'soleil' },
  { label: 'Soleto', value: 'soleto' },
  { label: 'Solex', value: 'solex' },
  { label: 'Solitas Serif', value: 'solitas-serif' },
  { label: 'Sommet', value: 'sommet' },
  { label: 'Sommet Slab', value: 'sommet-slab' },
  { label: 'Sophisto', value: 'sophisto' },
  { label: 'Source Code', value: 'source-code-pro' },
  {
    label: 'Source Han Sans CJK Hong Kong',
    value: 'source-han-sans-cjk-hong-kong',
  },
  {
    label: 'Source Han Sans CJK Japanese',
    value: 'source-han-sans-cjk-japanese',
  },
  { label: 'Source Han Sans CJK Korean', value: 'source-han-sans-cjk-korean' },
  {
    label: 'Source Han Sans CJK Simplified Chinese',
    value: 'source-han-sans-cjk-simplified-chinese',
  },
  {
    label: 'Source Han Sans CJK Traditional Chinese',
    value: 'source-han-sans-cjk-traditional-chinese',
  },
  { label: 'Source Han Sans Hong Kong', value: 'source-han-sans-hong-kong' },
  { label: 'Source Han Sans Japanese', value: 'source-han-sans-japanese' },
  { label: 'Source Han Sans Korean', value: 'source-han-sans-korean' },
  {
    label: 'Source Han Sans Simplified Chinese',
    value: 'source-han-sans-simplified-chinese',
  },
  {
    label: 'Source Han Sans Traditional Chinese',
    value: 'source-han-sans-traditional-chinese',
  },
  { label: 'Source Han Serif Japanese', value: 'source-han-serif-japanese' },
  { label: 'Source Han Serif Korean', value: 'source-han-serif-korean' },
  {
    label: 'Source Han Serif Simplified Chinese',
    value: 'source-han-serif-simplified-chinese',
  },
  {
    label: 'Source Han Serif Traditional Chinese',
    value: 'source-han-serif-traditional-chinese',
  },
  { label: 'Source Sans', value: 'source-sans' },
  { label: 'Source Serif', value: 'source-serif' },
  { label: 'Source Serif 4', value: 'source-serif-4' },
  { label: 'Source Serif 4 Caption', value: 'source-serif-4-caption' },
  { label: 'Source Serif 4 Display', value: 'source-serif-4-display' },
  { label: 'Source Serif 4 Small Text', value: 'source-serif-4-small-text' },
  { label: 'Source Serif 4 Subhead', value: 'source-serif-4-subhead' },
  { label: 'Sovba', value: 'sovba' },
  { label: 'Spade', value: 'spade' },
  { label: 'Span', value: 'span' },
  { label: 'Spartacus', value: 'spartacus' },
  { label: 'Sporty Pro', value: 'sporty-pro' },
  { label: 'Spumante', value: 'spumante' },
  { label: 'Stack', value: 'stack' },
  { label: 'Stalemate', value: 'stalemate' },
  { label: 'Stencil', value: 'stencil' },
  { label: 'Stencil Creek', value: 'stencil-creek' },
  { label: 'Stenciletta', value: 'stenciletta' },
  { label: 'Stereo', value: 'stereo' },
  { label: 'Stern Pro', value: 'rtf-stern-pro' },
  { label: 'Stevie Sans', value: 'stevie-sans' },
  { label: 'Stick', value: 'stick' },
  { label: 'STIX', value: 'stix' },
  { label: 'Stolzl', value: 'stolzl' },
  { label: 'Storefront Pro', value: 'storefront-pro' },
  { label: 'Story Tales', value: 'story-tales' },
  { label: 'Stratos', value: 'stratos' },
  { label: 'Streamline', value: 'streamline' },
  { label: 'Strenuous', value: 'strenuous' },
  { label: 'Stroudley', value: 'stroudley' },
  { label: 'Strumpf', value: 'strumpf' },
  { label: 'Stud', value: 'stud' },
  { label: 'Study', value: 'study' },
  { label: 'Stymie', value: 'stymie' },
  { label: 'Suave Script Pro', value: 'suave-script-pro' },
  { label: 'Suburban', value: 'suburban' },
  { label: 'Subway', value: 'subway' },
  { label: 'Sudestada', value: 'sudestada' },
  { label: 'Sui Generis', value: 'sui-generis' },
  { label: 'Sukhumvit Dot', value: 'sukhumvit-dot' },
  { label: 'Summa', value: 'summa' },
  { label: 'Sunflower', value: 'sunflower' },
  { label: 'Sunshine', value: 'sunshine' },
  { label: 'Superclarendon', value: 'superclarendon' },
  { label: 'Supria Sans', value: 'supria-sans' },
  { label: 'Surce Sns Missing Types', value: 'surce-sns-missing-types' },
  { label: 'Suruma', value: 'suruma' },
  { label: 'Susan', value: 'susan' },
  { label: 'Sutro', value: 'sutro' },
  { label: 'Sutturah', value: 'sutturah' },
  { label: 'Swear Display', value: 'swear-display' },
  { label: 'Swear Text', value: 'swear-text' },
  { label: 'Sweater School', value: 'sweater-school' },
  { label: 'Sweet Fancy Script', value: 'sweet-fancy-script' },
  { label: 'Sweet Gothic', value: 'sweet-gothic' },
  { label: 'Sweet Gothic Serif', value: 'sweet-gothic-serif' },
  { label: 'Sweet Sans Pro', value: 'sweet-sans-pro' },
  { label: 'Sweet Square Pro', value: 'sweet-square-pro' },
  { label: 'Sweet Titling', value: 'sweet-titling' },
  { label: 'Sweet Upright Script', value: 'sweet-upright-script' },
  { label: 'Swing King', value: 'swing-king' },
  { label: 'Swing King Icons', value: 'swing-king-icons' },
  { label: 'Swingdancer', value: 'swingdancer' },
  { label: 'Swister', value: 'swister' },
  { label: 'Sybarite', value: 'sybarite' },
  { label: 'Synthemesc', value: 'synthemesc' },
  { label: 'Synthese', value: 'synthese' },
  { label: 'Sys', value: 'sys' },
  { label: 'SysFalso', value: 'sysfalso' },
  { label: 'T.26 Carbon', value: 't26-carbon' },
  { label: 'TA Aki', value: 'ta-aki' },
  { label: 'TA Dansyaku', value: 'ta-dansyaku' },
  { label: 'TA Engeifude', value: 'ta-engeifude' },
  { label: 'TA Fuga Fude', value: 'ta-fuga-fude' },
  { label: 'TA Kaku Shadow', value: 'ta-kaku-shadow' },
  { label: 'TA Kasanemarugo', value: 'ta-kasanemarugo' },
  { label: 'TA Koigokoro', value: 'ta-koigokoro' },
  { label: 'TA Madam', value: 'ta-madam' },
  { label: 'TA Souiimei', value: 'ta-souiimei' },
  { label: 'TA Yugemeijin', value: 'ta-yugemeijin' },
  { label: 'TA Yuka', value: 'ta-yuka' },
  { label: 'TA-candy', value: 'ta-candy' },
  { label: 'TA-F1blockline', value: 'ta-f1blockline' },
  { label: 'TA-houdate M', value: 'ta-houdate-m500' },
  { label: 'TA-hougan K500', value: 'ta-hougan-k500' },
  { label: 'TA-kai', value: 'ta-kai' },
  { label: 'TA-kotodama', value: 'ta-kotodama' },
  { label: 'TA-kouran', value: 'ta-kouran' },
  { label: 'TA-rb', value: 'ta-rb' },
  { label: 'Tablet Gothic', value: 'tablet-gothic' },
  { label: 'Taboo', value: 'taboo' },
  { label: 'Tachyon', value: 'tachyon' },
  { label: 'Tacitus', value: 'tacitus' },
  { label: 'Talon', value: 'talon' },
  { label: 'Tamarillo JF', value: 'tamarillo-jf' },
  { label: 'Tandelle', value: 'tandelle' },
  { label: 'Tangelo', value: 'tangelo' },
  { label: 'Tangerine', value: 'tangerine' },
  { label: 'Tangier', value: 'tangier' },
  { label: 'Tangly', value: 'tangly' },
  { label: 'Tannakone', value: 'tannakone' },
  { label: 'Tarzana', value: 'tarzana' },
  { label: 'Tasman', value: 'tasman' },
  { label: 'Taurunum', value: 'taurunum' },
  { label: 'Taurunum Ferrum', value: 'taurunum-ferrum' },
  { label: 'TBCGothic Std', value: 'tbcgothic-std' },
  { label: 'TBChibiRGothicPlusK Pro', value: 'tbchibirgothicplusk-pro' },
  { label: 'TBCineRGothic Std', value: 'tbcinergothic-std' },
  { label: 'TBNPGothic Std', value: 'tbnpgothic-std' },
  { label: 'TBNPMincho Std', value: 'tbnpmincho-std' },
  { label: 'TBUDGothic Std', value: 'tbudgothic-std' },
  { label: 'TBUDMincho Std', value: 'tbudmincho-std' },
  { label: 'TBUDRGothic Std', value: 'tbudrgothic-std' },
  { label: 'Teeshirt', value: 'teeshirt' },
  { label: 'Teimer', value: 'teimer' },
  { label: 'Tejuela', value: 'tejuela' },
  { label: 'Teko', value: 'teko' },
  { label: 'Tekton', value: 'tekton' },
  { label: 'Temeraire', value: 'temeraire' },
  { label: 'Template Gothic', value: 'template-gothic' },
  { label: 'Ten Mincho', value: 'ten-mincho' },
  { label: 'Ten Mincho Text', value: 'ten-mincho-text' },
  { label: 'Ten Oldstyle', value: 'ten-oldstyle' },
  { label: 'Tenby', value: 'tenby' },
  { label: 'Tenez', value: 'tenez' },
  { label: 'Tenon', value: 'tenon' },
  { label: 'Tenso', value: 'tenso' },
  { label: 'Tephra', value: 'tephra' },
  { label: 'Terfens', value: 'terfens' },
  { label: 'Termina', value: 'termina' },
  { label: 'Termtem', value: 'termtem' },
  { label: 'Texas Hero', value: 'texas-hero' },
  { label: 'Textbook New', value: 'textbook-new' },
  { label: 'The Becker Gothics', value: 'the-becker-gothics' },
  { label: 'The Tipoteca Series', value: 'tipoteca-series' },
  { label: 'Thingbat', value: 'thingbat' },
  { label: 'Thonglor', value: 'thonglor' },
  { label: 'Thongterm', value: 'thongterm' },
  { label: 'Thunderhouse', value: 'thunderhouse' },
  { label: 'Tight', value: 'tight' },
  { label: 'Tilden Sans', value: 'tilden-sans' },
  { label: 'Timberline', value: 'timberline' },
  { label: 'Tinos', value: 'tinos' },
  { label: 'Titillium Web', value: 'titillium-web' },
  { label: 'Titular', value: 'titular' },
  { label: 'Tomarik', value: 'tomarik' },
  { label: 'Tomarik Display', value: 'tomarik-display' },
  { label: 'Tondo', value: 'tondo' },
  { label: 'Toppan Bunkyu Gothic Pr6N', value: 'toppan-bunkyu-gothic-pr6n' },
  {
    label: 'Toppan Bunkyu Midashi Go Std',
    value: 'toppan-bunkyu-midashi-go-std',
  },
  {
    label: 'Toppan Bunkyu Midashi Go StdN',
    value: 'toppan-bunkyu-midashi-go-stdn',
  },
  {
    label: 'Toppan Bunkyu Midashi Min Std',
    value: 'toppan-bunkyu-midashi-min-std',
  },
  {
    label: 'Toppan Bunkyu Midashi Min StdN',
    value: 'toppan-bunkyu-midashi-min-stdn',
  },
  { label: 'Toppan Bunkyu Mincho Pr6N', value: 'toppan-bunkyu-mincho-pr6n' },
  { label: 'Tornac', value: 'tornac' },
  { label: 'Totally Glyphic', value: 'totally-glyphic' },
  { label: 'Totally Gothic', value: 'totally-gothic' },
  { label: 'Trade Gothic Next', value: 'trade-gothic-next' },
  { label: 'Trailmade', value: 'trailmade' },
  { label: 'Train One', value: 'train-one' },
  { label: 'Trajan', value: 'trajan' },
  { label: 'Trajan Color', value: 'trajan-color' },
  { label: 'Trajan Sans', value: 'trajan-sans' },
  { label: 'Transat', value: 'transat' },
  { label: 'Transat Text', value: 'transat-text' },
  { label: 'Transmute', value: 'transmute' },
  { label: 'Trasandina ', value: 'trasandina' },
  { label: 'Trevor', value: 'trevor' },
  { label: 'Tribute', value: 'tribute' },
  { label: 'Trilby', value: 'trilby' },
  { label: 'Triplex Sans', value: 'triplex-sans' },
  { label: 'Triplex Serif', value: 'triplex-serif' },
  { label: 'Tripper', value: 'tripper-pro' },
  { label: 'Trump', value: 'trump' },
  { label: 'Tuna', value: 'tuna' },
  { label: 'Turbinado', value: 'turbinado' },
  { label: 'Turnip', value: 'turnip' },
  { label: 'Turnip RE', value: 'turnip-re' },
  { label: 'Turquoise', value: 'turquoise' },
  { label: 'Tussilago', value: 'tussilago' },
  { label: 'TwentyFourNinetyOne', value: 'twentyfourninetyone' },
  { label: 'Typeka', value: 'typeka' },
  { label: 'Tzimmes', value: 'tzimmes' },
  { label: 'Ubuntu', value: 'ubuntu' },
  { label: 'Ubuntu Mono', value: 'ubuntu-mono' },
  { label: 'Ultra', value: 'ultra' },
  { label: 'Unibody 8', value: 'unibody-8' },
  { label: 'Unitext', value: 'unitext' },
  { label: 'Univia Pro', value: 'univia-pro' },
  { label: 'Uniwars', value: 'uniwars' },
  { label: 'Upgrade', value: 'upgrade' },
  { label: 'Uppercut Angle', value: 'uppercut-angle' },
  { label: 'Urbana', value: 'urbana' },
  { label: 'Urbane', value: 'urbane' },
  { label: 'Urbane Adscript', value: 'urbane-adscript' },
  { label: 'Urbane Rounded', value: 'urbane-rounded' },
  { label: 'Uroob', value: 'uroob' },
  { label: 'URW Antiqua', value: 'urw-antiqua' },
  { label: 'URW DIN', value: 'urw-din' },
  { label: 'URW Form', value: 'urw-form' },
  { label: 'Usual', value: 'usual' },
  { label: 'Usurp', value: 'usurp' },
  { label: 'Utile', value: 'utile' },
  { label: 'Utile Display', value: 'utile-display' },
  { label: 'Utile Narrow', value: 'utile-narrow' },
  { label: 'Utopia', value: 'utopia' },
  { label: 'Utopian', value: 'utopian' },
  { label: 'Vaccine', value: 'vaccine' },
  { label: 'VAG Rundschrift D', value: 'vag-rundschrift-d' },
  { label: 'Variex', value: 'variex' },
  { label: 'Vatican', value: 'vatican' },
  { label: 'VDL-ADMin', value: 'vdl-admin' },
  { label: 'VDL-GigaG', value: 'vdl-gigag' },
  { label: 'VDL-GigaJr', value: 'vdl-gigajr' },
  { label: 'VDL-GigaMaru', value: 'vdl-gigamaru' },
  { label: 'VDL-GigaMaruJr', value: 'vdl-gigamarujr' },
  { label: 'VDL-GothicMincho', value: 'vdl-gothicmincho' },
  { label: 'VDL-KuroMincho', value: 'vdl-kuromincho' },
  { label: 'VDL-KyoSensya', value: 'vdl-kyosensya' },
  { label: 'VDL-LineG', value: 'vdl-lineg' },
  { label: 'VDL-LineGR', value: 'vdl-linegr' },
  { label: 'VDL-LogoG', value: 'vdl-logog' },
  { label: 'VDL-LogoJr', value: 'vdl-logojr' },
  { label: 'VDL-LogoJrBlack', value: 'vdl-logojrblack' },
  { label: 'VDL-LogoMaru', value: 'vdl-logomaru' },
  { label: 'VDL-LogoMaru Jr', value: 'vdl-logomaru-jr' },
  { label: 'VDL-Logona', value: 'vdl-logona' },
  { label: 'VDL-MegaG', value: 'vdl-megag' },
  { label: 'VDL-MegaMaru', value: 'vdl-megamaru' },
  { label: 'VDL-PenGentle', value: 'vdl-pengentle' },
  { label: 'VDL-PenLady', value: 'vdl-penlady' },
  { label: 'VDL-PenLetter', value: 'vdl-penletter' },
  { label: 'VDL-PetaG', value: 'vdl-petag' },
  { label: 'VDL-TeraG', value: 'vdl-terag' },
  { label: 'VDL-V7Gothic', value: 'vdl-v7gothic' },
  { label: 'VDL-V7MaruGothic', value: 'vdl-v7marugothic' },
  { label: 'VDL-V7Mincho', value: 'vdl-v7mincho' },
  { label: 'VDL-YotaG', value: 'vdl-yotag' },
  { label: 'Vectora', value: 'vectora' },
  { label: 'Vektra', value: 'vektra' },
  { label: 'Vendetta', value: 'vendetta' },
  { label: 'Verveine', value: 'verveine' },
  { label: 'Vibro', value: 'vibro' },
  { label: 'Victorian Orchid', value: 'victorian-orchid' },
  { label: 'Vidange', value: 'vidange' },
  { label: 'Video', value: 'video' },
  { label: 'Viktor Script', value: 'viktor-script' },
  { label: 'Viktorie', value: 'viktorie' },
  { label: 'Vincente', value: 'vincente' },
  { label: 'Vintage Deco', value: 'vintage-deco' },
  { label: 'Vinyl', value: 'vinyl' },
  { label: 'Virna', value: 'virna' },
  { label: 'Vista Sans', value: 'vista-sans' },
  { label: 'Vista Slab', value: 'vista-slab' },
  { label: 'Viva', value: 'viva' },
  { label: 'VM74', value: 'vm74' },
  { label: 'Voguing', value: 'voguing' },
  { label: 'Volina', value: 'volina' },
  { label: 'Vollkorn', value: 'vollkorn' },
  { label: 'Voltage', value: 'voltage' },
  { label: 'Voluta Script', value: 'voluta-script' },
  { label: 'Vortice', value: 'vortice' },
  { label: 'Vox', value: 'vox' },
  { label: 'Wak', value: 'wak' },
  { label: 'Walnut', value: 'walnut' },
  { label: 'WanpakuRera', value: 'wanpakurera' },
  { label: 'WanpakuRuika', value: 'wanpakuruika' },
  { label: 'Warnock', value: 'warnock' },
  { label: 'Westsac', value: 'westsac' },
  { label: 'Whomp', value: 'whomp' },
  { label: 'Wigwag', value: 'wigwag' },
  { label: 'Willow', value: 'willow' },
  { label: 'Winco', value: 'winco' },
  { label: 'Winsome', value: 'winsome' },
  { label: 'Wordy Diva', value: 'wordy-diva' },
  { label: 'Work Sans', value: 'work-sans' },
  { label: 'Worker', value: 'worker' },
  { label: 'Worker 3D', value: 'worker-3d' },
  { label: 'Worthington Arcade', value: 'worthington-arcade' },
  { label: 'Wreath', value: 'wreath' },
  { label: 'WTR Fatboy', value: 'wtr-fatboy' },
  {
    label: 'WTR French Clarendon Ornamented',
    value: 'wtr-french-clarendon-ornamented',
  },
  { label: 'WTR Gothic Open Shaded', value: 'wtr-gothic-open-shaded' },
  { label: 'WTR Roycroft', value: 'wtr-roycroft' },
  { label: 'Xenara', value: 'xenara' },
  { label: 'Yana', value: 'yana' },
  { label: 'Yantramanav', value: 'yantramanav' },
  { label: 'Yellowtail', value: 'yellowtail' },
  { label: 'Yoon A Meolijeongche', value: 'yoon-a-meolijeongche' },
  { label: 'Yoon A Meolikullim', value: 'yoon-a-meolikullim' },
  { label: 'Yoon A Meolippichim', value: 'yoon-a-meolippichim' },
  { label: 'Yoon A Yoonche', value: 'yoon-a-yoonche' },
  { label: 'Yorkten Slab', value: 'yorkten-slab' },
  { label: 'Youngblood', value: 'youngblood' },
  { label: 'Yrsa', value: 'yrsa' },
  { label: 'Ysans', value: 'ysans' },
  { label: 'Ysans Mondrian', value: 'ysans-mondrian' },
  {
    label: 'Yu-Minchotai 36-pt kana R 2nd Ed.',
    value: 'yu-minchotai-36-pt-kana-r-2nd-ed',
  },
  {
    label: 'Yu-Minchotai 5-go kana R 2nd Ed.',
    value: 'yu-minchotai-5-go-kana-r-2nd-ed',
  },
  { label: 'Yu-Minchotai Pr6 R', value: 'yu-minchotai-pr6-r' },
  { label: 'Yu-Minchotai Pr6N R', value: 'yu-minchotai-pr6n-r' },
  { label: 'Yusei Magic', value: 'yusei-magic' },
  { label: 'Zalderdash', value: 'zalderdash' },
  { label: 'Zebrawood', value: 'zebrawood' },
  { label: 'ZeitGuys', value: 'zeitguys' },
  { label: 'Zeitung', value: 'zeitung' },
  { label: 'Zekton', value: 'zekton' },
  { label: 'Zenon', value: 'zenon' },
  { label: 'Zipline', value: 'zipline' },
  { label: 'Zooja', value: 'zooja' },
  { label: 'Zsazsa Galore', value: 'zsazsa-galore' },
  { label: 'Zubilo', value: 'zubilo' },
];

export default adobeFonts;

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_KITCHEN } from 'helpers/roles';

import SelectFilter from 'components/Grid/Filter/Select';
import StatusPicker from 'views/CateringsFlow/common/StatusPicker/StatusPicker';
import EmployeePicker from 'views/CateringsFlow/common/EmployeePicker/EmployeePicker';
import StatusChangeCountdown from 'views/CateringsFlow/common/StatusChangeCountdown/StatusChangeCountdown';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import CATERINGS_FLOW_STATUSES_LABELS from 'views/CateringsFlow/consts/cateringsFlowStatusesLabels';
import {
  RecipeTypeController,
  roundAndParse,
} from 'views/CateringsFlow/Kitchen/components/AmountOfDoneModal/AmountOfDoneModal';

import { amountOfDoneSelectedColorKitchen } from 'views/CateringsFlow/utils/AmountOfDoneSelectedColor';

const columnConfig = ({
  t,
  kitchens,
  employees,
  departments,
  onStatusChangeAction,
  onEmployeeChangeAction,
}) => [
  {
    title: 'ID',
    name: 'id',
    accessor: 'id',
    filterable: true,
    sortable: true,
  },
  {
    title: t('$*cateringsFlow.kitchen.recipiesList.column.name', '$$ID Nazwa'),
    name: 'name',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    title: t('$*cateringsFlow.kitchen.recipiesList.column.tags', '$$Tagi'),
    name: 'recipe.tags.value',
    accessor: row => {
      return (row?.recipe?.tags ?? [])
        .map(({ value }) => `#${value}`)
        .join(' ');
    },
    filterable: true,
    sortable: false,
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countToDo',
      '$$Ilość do zrobienia'
    ),
    name: 'amountToMake',
    accessor: row => (
      <RecipeTypeController
        recipeTaskInfo={row}
        piece={<>{roundAndParse(row?.amountToMake ?? 0, 2)} szt</>}
        divider={'/'}
        weight={
          <>
            {roundAndParse(
              ((row?.amountToMake ?? 0) *
                (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
                1000 ?? 0,
              2
            )}{' '}
            {t('$*common.kg', 'kg')}
          </>
        }
      />
    ),
    filterable: false,
    sortable: false,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countToDoAfterUpdate',
      '$$Ilość do zrobienia 2'
    ),
    name: 'amountToMakeAfterRefreshedAmounts',
    accessor: row => (
      <RecipeTypeController
        recipeTaskInfo={row}
        piece={
          <>
            {roundAndParse(row?.amountToMakeAfterRefreshedAmounts ?? 0, 2)} szt
          </>
        }
        divider={'/'}
        weight={
          <>
            {roundAndParse(
              ((row?.amountToMakeAfterRefreshedAmounts ?? 0) *
                (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
                1000 ?? 0,
              2
            )}{' '}
            {t('$*common.kg', 'kg')}
          </>
        }
      />
    ),
    filterable: false,
    sortable: false,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },

  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countDone',
      '$$Ilość zrobiona'
    ),
    name: 'amountOfDone',
    accessor: row => {
      const color = amountOfDoneSelectedColorKitchen(row);
      return (
        <div style={{ color: color.color }}>
          <RecipeTypeController
            recipeTaskInfo={row}
            piece={<>{roundAndParse(row?.amountOfDone ?? 0, 2)} szt</>}
            divider={'/'}
            weight={
              <>
                {roundAndParse(
                  ((row?.amountOfDone ?? 0) *
                    (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
                    1000 ?? 0,
                  2
                )}{' '}
                {t('$*common.kg', 'kg')}
              </>
            }
          />
        </div>
      );
    },
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.difference',
      '$$Róźnica'
    ),
    name: 'diff',
    accessor: row =>
      `${(
        (((row?.amountOfDone ?? 0) -
          (row?.amountToMakeAfterRefreshedAmounts
            ? row?.amountToMakeAfterRefreshedAmounts
            : row?.amountToMake ?? 0)) *
          (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
          1000 ?? 0
      ).toFixed(2)} kg`,
    filterable: false,
    sortable: false,
  },
  {
    title: t('$*cateringsFlow.kitchen.recipiesList.column.note', '$$Notatka'),
    name: 'comment',
    accessor: 'comment',
    filterable: true,
    sortable: false,
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.kitchen',
      '$$Kuchnia'
    ),
    name: 'recipe.kitchen.id',
    accessor: 'recipe.kitchen.value',
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      const options = (kitchens ?? []).map(kitchen => {
        return {
          '@id': kitchen?.id,
          name: kitchen?.value,
        };
      });

      return (
        <SelectFilter
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.department',
      '$$Dział'
    ),
    name: 'recipe.department.id',
    accessor: 'recipe.department.value',
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      const options = (departments ?? []).map(department => {
        return {
          '@id': department?.id,
          name: department?.value,
        };
      });

      return (
        <SelectFilter
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
  },
  {
    title: t('$*cateringsFlow.kitchen.recipiesList.column.status', '$$Status'),
    name: 'status',
    accessor: row => {
      const isStatusChangeSheduled =
        !isEmpty(row?.plannedStatusChangeAt) &&
        moment(new Date()).isBefore(
          moment(new Date(row?.plannedStatusChangeAt))
        );

      const onCountdownTimeout = async () => {
        try {
          await onStatusChangeAction({
            row,
            newStatus: row?.plannedStatus,
            ignoreAmountsModal: true,
          });

          window.location.reload();
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <>
          {isStatusChangeSheduled && (
            <StatusChangeCountdown
              endDate={new Date(row?.plannedStatusChangeAt)}
              startDate={new Date(row?.plannedStatusAt)}
              plannedStatus={row?.plannedStatus}
              onCountdownTimeout={onCountdownTimeout}
            />
          )}
          <StatusPicker
            row={row}
            initialStatus={row?.status}
            isPickingEnabled={
              row?.status !== 'CREATED' && isGranted(ROLE_EDIT_KITCHEN)
            }
            onStatusChangeAction={onStatusChangeAction}
          />
        </>
      );
    },
    width: 150,
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      const options = (Object.values(CATERINGS_FLOW_STATUSES) ?? []).map(
        status => {
          const translation = CATERINGS_FLOW_STATUSES_LABELS[status];

          return {
            '@id': status,
            name: t(translation?.translationKey, translation?.defaultValue),
          };
        }
      );

      return (
        <SelectFilter
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.employee',
      '$$Pracownik'
    ),
    name: 'assignedEmployee',
    accessor: row => {
      return (
        <EmployeePicker
          row={row}
          employees={employees.map(employee => ({
            contentUrl: employee?.contentUrl,
            label: employee?.value,
            value: employee?.['@id'],
          }))}
          isPickingEnabled={isGranted(ROLE_EDIT_KITCHEN)}
          initialEmployee={
            isEmpty(row?.assignedEmployee)
              ? {}
              : {
                  contentUrl: row?.assignedEmployee?.image?.contentUrl,
                  label: `${row?.assignedEmployee?.firstName} ${row?.assignedEmployee?.lastName}`,
                  value: row?.assignedEmployee?.['@id'],
                }
          }
          onEmployeeChangeAction={onEmployeeChangeAction}
        />
      );
    },
    filterable: true,
    sortable: true,
    width: 85,
  },
];

export default columnConfig;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import DishesPackedPerDayChart from './components/DishesPackedPerDayChart';

const DishFlowStatistics = ({ dishId }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '5px' }}>
      {/* AMOUNT OF PACKED DIETS PER DAY */}
      <Card>
        <CardBody style={{ paddingBottom: '3rem' }}>
          <h3>
            {t(
              '$*mealFormComponent.dishFlowStatistics.dishesPackedPerDay',
              'Ilość spakowanych tacek na poszczególne dni'
            )}
          </h3>
          <DishesPackedPerDayChart dishId={dishId} />
        </CardBody>
      </Card>
    </div>
  );
};

export default DishFlowStatistics;

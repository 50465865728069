import React, { useEffect, useState } from 'react';
import Select from '../../Grid/Filter/Select';

const SelectFilter = ({ value, multiple, options, onChange }) => {
  const [controlledValue, setControlledValue] = useState(
    value ? value : multiple ? [] : null
  );

  useEffect(() => {
    if (
      JSON.stringify(controlledValue.sort()) !== JSON.stringify(value.sort())
    ) {
      onChange(controlledValue);
    }
  }, [controlledValue]);

  return (
    <Select
      options={options}
      value={controlledValue}
      onChange={setControlledValue}
      multiple={multiple}
    />
  );
};

export default SelectFilter;

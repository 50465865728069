const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    width: 50,
  },
  {
    title: 'form.field.defaultName',
    name: 'name',
    accessor: 'name',
    filterable: true,
  },
  {
    title: 'form.field.nameForClient',
    name: 'nameForClient',
    accessor: 'nameForClient',
    filterable: true,
  },
  {
    title: 'columns.order',
    name: 'position',
    accessor: 'position',
    filterable: false,
  },
];

export default columnConfig;

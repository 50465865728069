import types from 'helpers/constants';

export const initializeMenuPlanners = settings => {
  return dispatch =>
    dispatch({
      type: types.INITIALIZE_MENU_PLANNERS,
      payload: {
        ...settings,
      },
    });
};

export const updateVisibleVariants = (newVariants, brandIri) => {
  return dispatch =>
    dispatch({
      type: types.UPDATE_VISIBLE_VARIANTS,
      payload: {
        brandIri: brandIri,
        visibleVariants: newVariants,
      },
    });
};

export const updateVisibleDiets = (newDiets, brandIri) => {
  return dispatch =>
    dispatch({
      type: types.UPDATE_VISIBLE_DIETS,
      payload: {
        brandIri: brandIri,
        visibleDiets: newDiets,
      },
    });
};

import React from 'react';

import TagsInput from 'react-tagsinput';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FormLabel from '@material-ui/core/FormLabel';

const FormTags = ({
  label,
  classes,
  state,
  handleChange,
  disabled,
  tagProps,
}) => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
      </GridItem>
      <GridItem
        xs={12}
        sm={6}
        style={{ display: 'flex', alignItems: 'flex-end' }}
      >
        <TagsInput
          disabled={disabled}
          value={state}
          onChange={handleChange}
          tagProps={
            tagProps
              ? tagProps
              : {
                  className: disabled
                    ? 'react-tagsinput-tag-disabled danger'
                    : 'react-tagsinput-tag info',
                }
          }
          inputProps={{ placeholder: disabled ? '' : 'Dodaj tag' }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default FormTags;

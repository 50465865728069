import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { withToast } from 'material-ui-toast-redux';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import RegularButton from 'components/CustomButtons/Button.jsx';

import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { put } from 'helpers/apiHelpers';
import { isGranted } from 'helpers/helpers';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import roles from 'helpers/roles';
import GridItem from 'components/Grid/GridItem';
import produce from 'immer';
import TOAST_DURATIONS from 'helpers/toastDurations';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
});

const MailNotificationClientToggleableCard = ({
  mailingOptions,
  clientMailing,
  openToast,
  mailing,
  adminMailing,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({ mailing });

  return (
    <Card>
      <CardBody>
        <GridItem md={12}>
          <h3>{t('mailNotifications.cardTitle.clientAllowToToggle')}</h3>
        </GridItem>
        <div style={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('notify.whatMail')}</TableCell>
                <TableCell>{t('notify.allowClientToToggle')}</TableCell>
                {isGranted(roles.ROLE_EDIT_MAIL_CONFIGURATION) && (
                  <TableCell>{t('notify.actions')}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.mailing.map(mailingOption => {
                const [clientEmailStatus] = clientMailing?.filter(
                  el => mailingOption['@id'] === el['@id']
                );

                const [adminEmailStatus] = adminMailing?.filter(
                  el => mailingOption['@id'] === el['@id']
                );

                const mainEmailStatus = clientEmailStatus || adminEmailStatus;
                return (
                  <TableRow>
                    <TableCell>
                      {`${
                        mailingOptions(t)[mailingOption?.mailKey] ||
                        mailingOption?.mailKey
                      }`}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={
                          mainEmailStatus?.enabledForClient
                            ? mailingOption?.allowClientToToggle
                            : false
                        }
                        onChange={e => {
                          if (mainEmailStatus?.hasChanged) {
                            openToast({
                              messages: [
                                t(
                                  'mailNotification.userSettings.secondEmail.blocked'
                                ),
                              ],
                              type: 'warning',
                              autoHideDuration: TOAST_DURATIONS.SM,
                            });
                            return;
                          }

                          if (!mainEmailStatus?.enabledForClient) {
                            openToast({
                              messages: [
                                t(
                                  'mailNotification.userSettings.mainEmail.blocked'
                                ),
                              ],
                              type: 'error',
                              autoHideDuration: TOAST_DURATIONS.SM,
                            });
                            return;
                          }

                          const updatedMailing = produce(
                            state?.mailing,
                            draftMailing => {
                              const optionToChange = draftMailing?.find(
                                newMailingOption =>
                                  newMailingOption?.id === mailingOption?.id
                              );

                              optionToChange.allowClientToToggle =
                                !mailingOption?.allowClientToToggle;
                              optionToChange.hasChanged = true;
                            }
                          );

                          setState({
                            mailing: updatedMailing,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    </TableCell>
                    {isGranted(roles.ROLE_EDIT_MAIL_CONFIGURATION) && (
                      <TableCell>
                        <RegularButton
                          disabled={
                            !mainEmailStatus?.enabledForClient
                              ? true
                              : !mailingOption?.hasChanged
                          }
                          color={'success'}
                          onClick={() => {
                            if (mainEmailStatus?.hasChanged) {
                              openToast({
                                messages: [
                                  t(
                                    'mailNotification.userSettings.secondEmail.blocked'
                                  ),
                                ],
                                type: 'warning',
                                autoHideDuration: TOAST_DURATIONS.SM,
                              });
                              return;
                            }
                            put(mailingOption['@id'], {
                              allowClientToToggle:
                                mailingOption?.allowClientToToggle,
                            }).then(
                              res => {
                                const updatedMailing = produce(
                                  state?.mailing,
                                  draftMailing => {
                                    const optionToChange = draftMailing?.find(
                                      newMailingOption =>
                                        newMailingOption?.id ===
                                        mailingOption?.id
                                    );

                                    optionToChange.hasChanged = false;
                                  }
                                );

                                setState({
                                  mailing: updatedMailing,
                                });

                                openToast({
                                  messages: [t('notify.changesSaved')],
                                  type: 'success',
                                  autoHideDuration: TOAST_DURATIONS.SM,
                                });
                                window.location.reload();
                              },
                              error =>
                                openToast({
                                  messages: [t('notify.cannotSave')],
                                  type: 'error',
                                  autoHideDuration: TOAST_DURATIONS.SM,
                                })
                            );
                          }}
                        >
                          {t('common.shared.save')}
                        </RegularButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default withToast(MailNotificationClientToggleableCard);

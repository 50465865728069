import Select from 'react-select';
import { goldColor, grayColor } from 'assets/jss/material-dashboard-pro-react';

const SelectInput = ({
  label,
  name,
  value,
  options = [],
  multiple,
  isDisabled = false,
  customStyle = {},
  handleChange,
  isClearable,
} = {}) => {
  const handleSelectChange = selected => handleChange(selected);

  return (
    <Select
      name={name}
      value={value}
      isMulti={multiple}
      options={options ?? []}
      onChange={handleSelectChange}
      placeholder={label}
      isClearable={isClearable}
      isDisabled={isDisabled}
      styles={{
        container: baseStyle => ({ ...baseStyle, ...customStyle }),
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            cursor: 'pointer',
            borderColor: state.isFocused ? goldColor : grayColor[4],
            boxShadow: state.isFocused && `0 0 0 1px ${goldColor}`,
            '&:hover': {
              borderColor: goldColor,
            },
          };
        },
        menu: baseStyles => {
          return {
            ...baseStyles,
            zIndex: 9999999999,
          };
        },
        option: baseStyles => {
          return {
            ...baseStyles,
            cursor: 'pointer',
          };
        },
      }}
    />
  );
};

export default SelectInput;

import { Droppable } from 'react-beautiful-dnd';
import { Container, Title, RouteList } from './Table.styles';
import DriverRoute from '../DriverRoute';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const Table = ({ table, routes, t, isDragDisabled }) => {
  return (
    <Container>
      <Title>{t(`routePlanner.${table.id}`)}</Title>
      <Droppable droppableId={table.id.toString()}>
        {(provided, snapshot) => (
          <RouteList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {routes.map((route, index) => (
              <DriverRoute
                key={route.id}
                route={route}
                index={index}
                isDragDisabled={isDragDisabled}
              />
            ))}
            {provided.placeholder}
          </RouteList>
        )}
      </Droppable>
    </Container>
  );
};

const enhance = compose(withTranslation());

export default enhance(Table);

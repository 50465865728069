import React from 'react';
import Moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import DateFilter from 'components/Grid/Filter/Date';

const selectOptions = [
  '*',
  'PLANED',
  'PROCESSED',
  'FINISHED',
  'FAILED',
  'PARTIAL_SUCCESS',
];

const statusesTranslations = t => ({
  '*': t('massEmail.columnConfig.All', 'Wszystkie'),
  PLANED: t('massEmail.columnConfig.planned', 'Zaplanowano'),
  PROCESSED: t('massEmail.columnConfig.inProgress', 'W toku'),
  FINISHED: t('massEmail.columnConfig.finished', 'Zakończono'),
  FAILED: t('massEmail.columnConfig.failure', 'Niepowodzenie'),
  PARTIAL_SUCCESS: t(
    'massEmail.columnConfig.partialFail',
    'Częściowe niepowodzenie'
  ),
});

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    width: 50,
  },
  {
    title: 'email.processAt',
    name: 'processAt',
    accessor: row => Moment(row.processAt).format('YYYY-MM-DD HH:mm'),
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    filterable: true,
    sortable: true,
    width: 200,
  },
  {
    title: 'email.title',
    name: 'subject',
    accessor: 'subject',
    filterable: true,
    sortable: true,
  },
  {
    Header: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {t('clients.status', 'Status')}
        <Tooltip
          title={
            <h4>
              {t(
                'massEmail.columnConfig.statusInfo',
                'Status nie informuje o wysyłce Emaili, ale o tym czy zostały utworzone i przekazane do wysyłki.'
              )}
            </h4>
          }
          placement="right"
        >
          <Info style={{ fontSize: '16px', marginLeft: '4px' }} />
        </Tooltip>
      </div>
    ),
    name: 'status',
    accessor: row => statusesTranslations(t)[row.status],
    filterable: true,
    sortable: false,
    Filter: ({ onChange, filter }) => {
      return (
        <select
          style={{ height: '30px', width: '100%', padding: '0' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value={''}>
            {statusesTranslations(t)[selectOptions[0]]}
          </option>
          <option value={selectOptions[1]}>
            {statusesTranslations(t)[selectOptions[1]]}
          </option>
          <option value={selectOptions[2]}>
            {statusesTranslations(t)[selectOptions[2]]}
          </option>
          <option value={selectOptions[3]}>
            {statusesTranslations(t)[selectOptions[3]]}
          </option>
          <option value={selectOptions[4]}>
            {statusesTranslations(t)[selectOptions[4]]}
          </option>
          <option value={selectOptions[5]}>
            {statusesTranslations(t)[selectOptions[5]]}
          </option>
        </select>
      );
    },
  },
  {
    title: 'email.sendCount',
    name: 'stats.preparedEmail',
    accessor: 'stats.preparedMail',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

import React, { Component } from 'react';
import { ChromePicker } from 'react-color';

class ColorPicker extends Component {
  render() {
    return (
      <ChromePicker
        disableAlpha
        color={this.props.color}
        onChange={color => this.props.handleChange(color)}
      />
    );
  }
}

export default ColorPicker;

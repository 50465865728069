import React from 'react';
import { put } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import InfoModal from '../../../components/InfoModal/InfoModal';
import Button from '../../../components/CustomButtons/Button';
import BooleanView from '../../../components/DataGrid/ValueViews/BooleanView';
import TOAST_DURATIONS from 'helpers/toastDurations';

class PaymentNeedPayButton extends React.Component {
  state = {
    showModal: false,
    paymentNeedVerification: false,
  };

  componentDidMount() {
    this.setState({
      paymentNeedVerification: this.props.paymentNeedVerification,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.paymentNeedVerification !== this.props.paymentNeedVerification
    ) {
      this.setState({
        paymentNeedVerification: this.props.paymentNeedVerification,
      });
    }
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  changeStatus = () => {
    this.toggleModal();
  };

  handleStatusChange = () => {
    const { t } = this.props;

    put(this.props.orderId, {
      paymentNeedVerification: !this.state.paymentNeedVerification,
    })
      .then(() => {
        this.setState({
          paymentNeedVerification: !this.state.paymentNeedVerification,
        });
        this.props.openToast({
          messages: [t('success.statusUpdated', 'Status został zmieniony')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      })
      .catch(error => {
        if (error.hasOwnProperty('response')) {
          const {
            response: { data },
          } = error;
          if (data.hasOwnProperty('violations')) {
            this.props.openToast({
              messages: data.violations.map(el => el.message),
              type: 'error',
              autoHideDuration: TOAST_DURATIONS.SM,
            });
          }
        }
      });

    if (this.state.showModal) {
      this.toggleModal();
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div onClick={() => this.changeStatus()}>
          <BooleanView value={this.state.paymentNeedVerification} />
        </div>

        <InfoModal
          modalState={this.state.showModal}
          handleClose={() => this.toggleModal}
          modalContent={
            <>
              <p>{t('orders.modalPaymentNeedVerification.text')}</p>
              <div>
                <Button onClick={() => this.handleStatusChange()}>
                  {t('orders.modal.button.yes')}
                </Button>
                <Button onClick={this.toggleModal}>
                  {t('orders.modal.button.no')}
                </Button>
              </div>
            </>
          }
        />
      </>
    );
  }
}

const enhance = compose(
  withStyles(extendedFormsStyle),
  withToast,
  withTranslation()
);

export default enhance(PaymentNeedPayButton);

const ArrowRightButtonIcon = ({
  width = '32',
  height = '32',
  title = '',
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <rect
      width={width}
      height={height}
      rx="16"
      transform="matrix(-1 0 0 1 32 0)"
      fill="transparent"
    />
    <rect
      x="-0.5"
      y="0.5"
      width="31"
      height="31"
      rx="15.5"
      transform="matrix(-1 0 0 1 31 0)"
      stroke={fill}
      stroke-opacity="0.5"
    />
    <path
      d="M22.0735 17.067C22.8387 16.3556 22.888 15.6442 22.0733 14.9328L15.9675 8.88774C15.6698 8.59303 15.1873 8.59303 14.8896 8.88774C14.7412 9.03563 14.666 9.22862 14.666 9.42075C14.666 9.61392 14.7402 9.80695 14.8896 9.9539L20.9957 15.9997L14.8896 22.0451C14.7412 22.193 14.666 22.3861 14.666 22.5792C14.666 22.7724 14.7402 22.9656 14.8896 23.1124C15.1873 23.407 15.6698 23.407 15.9675 23.1124L22.0735 17.067Z"
      fill={fill}
    />
    <path
      d="M20.667 15.9999L10.0003 15.9999"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export default ArrowRightButtonIcon;

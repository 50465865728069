import React from 'react';
import { useTranslation } from 'react-i18next';

import CardBody from 'components/Card/CardBody';

import withStyles from '@material-ui/core/styles/withStyles';
import DashboardIcon from '@material-ui/icons/DeleteForever';

import { combineStyles } from 'helpers/helpers';

import SummarySection from './components/SummarySection/SummarySection';
import SelectUserSection from './components/SelectUserSection/SelectUserSection';
import CopyPaymentLinkDialog from './components/CopyPaymentLinkDialog/CopyPaymentLinkDialog.jsx';
import DietConfigurationSection from './components/DietConfigurationSection/DietConfigurationSection';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import CardWrapper from 'components/Card/CardWrapper';

const NewOrder = ({
  diets,
  history,
  classes,
  promoCode,
  paymentType,
  useMoneyBox,
  usePromoCode,
  isSubmitting,
  isMoreThanOneDiet,
  sendConfirmation,
  selectedCustomer,
  calendarSettings,
  isInvoiceIncluded,
  orderCost = {},
  paymentLink = '',
  pickUpPoints = [],
  deliveryTypes = [],
  paymentMethods = [],
  availableAddons = [],
  customerAddresses = [],
  customerInvoiceData = {},
  isCopyPaymentLinkDialogOpened = false,
  isAllowedEcoContainer = false,
  isAllowedPremium = false,
  isAllowedTestDays = false,
  useTestMode = false,
  brandInfo = {},
  addDiet = () => {},
  removeDiet = () => {},
  updateDiet = () => {},
  getDietCost = () => {},
  handleSubmit = () => {},
  promiseOptions = () => {},
  setUseMoneyBox = () => {},
  setUsePromoCode = () => {},
  selectPromoCode = () => {},
  promisePromoCodes = () => {},
  selectPaymentType = () => {},
  selectCurrentUser = () => {},
  setSendConfirmation = () => {},
  setIsInvoiceIncluded = () => {},
  setCustomerInvoiceData = () => {},
  updateCustomerAddresses = () => {},
  setIsCopyPaymentLinkDialogOpened = () => {},
  setUseTestMode = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CopyPaymentLinkDialog
        classes={classes}
        history={history}
        isOpened={isCopyPaymentLinkDialogOpened}
        paymentLink={paymentLink}
        setIsOpened={setIsCopyPaymentLinkDialogOpened}
      />
      <div>
        <h1>{t('orders.newOrderForm')}</h1>
        {/* CUSTOMER SECTION */}
        <CardWrapper title={t('orders.client', '$$1. Klient')}>
          <CardBody style={{ zIndex: 100 }}>
            <SelectUserSection
              classes={classes}
              promiseOptions={promiseOptions}
              selectedCustomer={selectedCustomer}
              selectCurrentUser={selectCurrentUser}
            />
          </CardBody>
        </CardWrapper>
        {selectedCustomer && (
          <>
            {/* DIET SECTION */}
            {diets.map((diet, index) => (
              <>
                <CardWrapper
                  title={
                    index > 0 ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h2>
                            {`${index + 2}. ` +
                              t('orders.sections.diet', '') +
                              ``}
                          </h2>
                          <div
                            onClick={() => removeDiet(index)}
                            style={{ color: 'red', cursor: 'pointer' }}
                          >
                            <DashboardIcon />
                          </div>
                        </div>
                      </>
                    ) : (
                      <h2>
                        {`${index + 2}. ` + t('orders.sections.diet', '') + ``}
                      </h2>
                    )
                  }
                >
                  <CardBody>
                    <DietConfigurationSection
                      diet={diet}
                      brandInfo={brandInfo}
                      classes={classes}
                      dietCost={getDietCost(index)}
                      dietIndex={index}
                      updateDiet={updatedDiet => updateDiet(updatedDiet, index)}
                      pickUpPoints={pickUpPoints}
                      deliveryTypes={deliveryTypes}
                      availableAddons={availableAddons}
                      calendarSettings={calendarSettings}
                      selectedCustomer={selectedCustomer}
                      customerAddresses={customerAddresses}
                      updateCustomerAddresses={updateCustomerAddresses}
                      isAllowedEcoContainer={isAllowedEcoContainer}
                      isAllowedPremium={isAllowedPremium}
                      isAllowedTestDays={isAllowedTestDays}
                      useTestMode={useTestMode}
                      setUseTestMode={setUseTestMode}
                      isMoreThanOneDiet={isMoreThanOneDiet}
                    />
                  </CardBody>
                </CardWrapper>
              </>
            ))}
            {/* SUMMARY SECTION */}
            <CardWrapper
              title={
                `${diets.length + 2}. ` +
                t('orders.sections.summary', '$$Podsumowanie')
              }
            >
              <CardBody>
                <SummarySection
                  diets={diets}
                  classes={classes}
                  promoCode={promoCode}
                  orderCost={orderCost}
                  paymentType={paymentType}
                  useMoneyBox={useMoneyBox}
                  usePromoCode={usePromoCode}
                  setUseMoneyBox={setUseMoneyBox}
                  paymentMethods={paymentMethods}
                  selectPromoCode={selectPromoCode}
                  setUsePromoCode={setUsePromoCode}
                  sendConfirmation={sendConfirmation}
                  selectPaymentType={selectPaymentType}
                  promisePromoCodes={promisePromoCodes}
                  isInvoiceIncluded={isInvoiceIncluded}
                  setSendConfirmation={setSendConfirmation}
                  customerInvoiceData={customerInvoiceData}
                  setIsInvoiceIncluded={setIsInvoiceIncluded}
                  setCustomerInvoiceData={setCustomerInvoiceData}
                />
              </CardBody>
            </CardWrapper>
            <FormControlStickyButton
              classes={classes}
              discardText={t('orders.cancelOrder')}
              submitText={t('orders.createNewOrder', 'Stwórz nowe zamówienie')}
              cancelPath="/admin/orders"
              submitDisabled={isSubmitting}
              handleSubmit={handleSubmit}
              customButtonsLeft={
                !useTestMode
                  ? [
                      {
                        label: t('orders.addDiet', 'Dodaj dietę +'),
                        style: { margin: '5px' },
                        className: classes.marginLeft,
                        onClick: addDiet,
                      },
                    ]
                  : []
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(NewOrder);

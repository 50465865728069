import types from 'helpers/constants';

const initialState = {
  kitchens: [],
  loading: true,
};

function Kitchens(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_KITCHENS:
      return {
        ...state,
        kitchens: action.payload.kitchens,
        loading: false,
      };
    case types.REMOVE_ALLERGEN:
      return {
        ...state,
        kitchens: state.kitchens.filter(
          allergen => allergen.id !== action.payload.removedItemId
        ),
      };
    case types.LOADING_KITCHENS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default Kitchens;

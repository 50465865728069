import React from 'react';
import roles from 'helpers/roles';
import MIME_TYPES from './constants/mimeTypes';
import CombinedReport from './CombinedReport/CombinedReport';
import { get, post } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';
import { isGranted } from 'helpers/helpers';

class ShoppingList extends React.Component {
  state = {
    fakturowniaWarehouseModule: {},
  };

  componentDidMount() {
    this.initShoppingList();
  }

  initShoppingList = async () => {
    const index = this.props.user.company.lastIndexOf('/') + 1;
    const companyId = this.props.user.company.slice(
      index,
      this.props.user.company.length
    );
    if (isGranted(roles.ROLE_EDIT_COMPANY)) {
      const { configuration } = await get(`/company/${companyId}/modules`);

      this.setState({
        fakturowniaWarehouseModule: configuration?.['FakturowniaWarehouse'],
      });
    }
  };

  bookInWarehouse = parameters => {
    return post('/fakturownia/warehouse/reservation', { params: parameters });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <CombinedReport
          name="SHOPPING_LIST"
          title={t('reports.reportShoppingList')}
          url={'/reports/shopping'}
          mimeTypes={[MIME_TYPES.xlsx]}
          role={roles.ROLE_SHOW_REPORT_SHOPPING}
          reportConfiguration={{
            report: 'Shopping',
            fields: [
              {
                size: 12,
                field: 'reportType',
                inputType: 'select',
                inputProps: {
                  multiple: false,
                  options: [
                    'WEIGHT_SUMMARY',
                    'QUANTITY_SUMMARY',
                    'ALL_SUMMARY',
                  ].map(key => ({
                    value: key,
                    label: t(`reports.reportShoppingList.reportType.${key}`),
                  })),
                },
              },
              {
                size: 12,
                field: 'roundDecimalPoints',
                inputType: 'number',
              },
            ],
          }}
          fileName={params =>
            getReportFileNameWithMultiplier(
              params,
              this.props.t('common.mainMenu.reportsShoppingList')
            )
          }
          useDateRange={true}
          useZoneCategories={true}
          showMultiplier={true}
          validateBrands={false}
          validateDatePicker={true}
          usePost={true}
          useRecipeTags={true}
          singleSelectRecipeTags={true}
          useWarehouse={this.state.fakturowniaWarehouseModule?.enabled}
          singleSelectWarehouse={this.state.fakturowniaWarehouseModule?.enabled}
          customActions={
            this.state.fakturowniaWarehouseModule?.enabled
              ? [
                  {
                    onClick: this.bookInWarehouse,
                    label: t(
                      '$*reports.shoppingList.bookInWarehose',
                      '$$Rezerwuj w magazynie'
                    ),
                  },
                ]
              : []
          }
          archiveReportNames={['SHOPPING_LIST']}
          isSalesMarketLangViewEnabled={true}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Auth.user,
});

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(ShoppingList);

import React from 'react';
import { Router, Route, Switch } from 'react-router';

import withRole from 'components/Security/withRole';
import ProductFeedModule from './modules/ProductFeedModule';
import ProductFeedForm from './modules/ProductFeedForm';
import {
  ROLE_SHOW_FEED,
  ROLE_EDIT_FEED,
  ROLE_CREATE_FEED,
} from 'helpers/roles';

const ProductFeed = props => {
  const { history, match } = props;
  const ProductFeedList = withRole(ROLE_SHOW_FEED, ProductFeedModule);
  const ProductFeedEditForm = withRole(ROLE_EDIT_FEED, ProductFeedForm);
  const ProductFeedCreateForm = withRole(ROLE_CREATE_FEED, ProductFeedForm);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ProductFeedList {...props} {...parentProps} />
          )}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => (
            <ProductFeedEditForm {...props} {...parentProps} />
          )}
        />
        <Route
          exact
          path={`${match.url}/add`}
          render={parentProps => (
            <ProductFeedCreateForm {...props} {...parentProps} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default ProductFeed;

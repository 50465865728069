const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    sortable: false,
    width: 100,
  },
  {
    title: 'myCompany.roleName',
    accessor: 'name',
    name: 'name',
    filterable: false,
    sortable: false,
  },
  {
    title: 'myCompany.roleShort',
    accessor: 'shortName',
    name: 'shortName',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

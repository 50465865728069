const defaultState = {
  referenceCode: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  plainPassword: '',
  plainPasswordMatch: '',
  sexOptions: [],
  sex: '',
  invoiceCompany: '',
  invoiceVatNumber: '',
  invoiceAddress: '',
  invoiceComments: '',
  knowAboutUsOptions: [],
  knowAboutUs: '',
  knowAboutUsOther: '',
  marketingTerms: false,
  marketingTermsContent: '',
  messagesTerms: true,
  messagesTermsContent: '',
  smsClientTags: [],
};

export default defaultState;

import { Dialog, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';

const AppDialogLoader = ({ text, excludedPaths = [], appIsLoading }) => {
  const currentPath = window.location.pathname;

  const dialogIsExcluded = excludedPaths.some(path => {
    if (path.includes(':id')) {
      let currentPathSplitted = currentPath.split('/');
      const newCurrentPath = [];
      const pathSplitted = path.split('/');

      pathSplitted.map((item, index) => {
        if (item.includes(':id')) {
          newCurrentPath.push(':id');
        } else {
          newCurrentPath.push(currentPathSplitted[index]);
        }
        return null;
      });

      return newCurrentPath.join('/').includes(pathSplitted.join('/'));
    }

    return currentPath.includes(path);
  });

  const pathnameAsArray = currentPath.split('/');

  const isAForm =
    pathnameAsArray.includes('edit') || pathnameAsArray.includes('add');

  return dialogIsExcluded || !isAForm ? null : (
    <Dialog open={appIsLoading}>
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <h1>{text}</h1>
          <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    appIsLoading: state.App.appIsLoading,
  };
};

export default connect(mapStateToProps, null)(AppDialogLoader);

import types from 'helpers/constants';

const initialState = {
  deliveryTypes: [],
  loading: true,
};

function DeliveryTypes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DELIVERYTYPES:
      return {
        ...state,
        deliveryTypes: action.payload.deliveryTypes,
        loading: false,
      };
    case types.LOADING_DELIVERYTYPES:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default DeliveryTypes;

import React from 'react';
import Success from 'components/Typography/Success';
import Moment from 'moment';
import { callTypes } from './ItemTypes';
import Warning from 'components/Typography/Warning';
import DateFilter from 'components/Grid/Filter/Date';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    width: 80,
  },
  {
    title: t('clients.calls.kind', 'Rodzaj'),
    accessor: (row, { t }) => {
      const ItemTypes = callTypes(t);
      row.type === ItemTypes[0].value ? (
        <Success>{ItemTypes[0].name}</Success>
      ) : (
        <Warning>{ItemTypes[1].name}</Warning>
      );
    },
    name: 'type',
    sortable: true,
    filterable: true,
    wrapFilter: true,
    Filter: ({ onChange, filter }, { t }) => {
      const ItemTypes = callTypes(t);
      return (
        <select
          style={{ height: '30px', width: '100%' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value}
        >
          <option value={''}>{t('common.all', 'Wszystkie')}</option>
          {ItemTypes.map(item => {
            return <option value={item.value}>{item.name}</option>;
          })}
        </select>
      );
    },
  },
  {
    title: t('clients.calls.worker', 'Pracownik'),
    accessor: row => {
      return `${row.createdBy.firstName} ${row.createdBy.lastName}`;
    },
    name: 'createdBy',
    sortable: true,
    filterable: true,
  },
  {
    title: t('clients.calls.startDate', 'Data rozpoczęcia'),
    Filter: ({ onChange, filter }) => (
      <DateFilter onChange={onChange} value={filter?.value} />
    ),
    accessor: row => new Moment(row.startAt).format('YYYY-MM-DD HH:mm:ss'),
    name: 'startAt',
    sortable: true,
    filterable: true,
  },
  {
    title: t('clients.calls.endDate', 'Data zakończenia'),
    Filter: ({ onChange, filter }) => (
      <DateFilter onChange={onChange} value={filter?.value} />
    ),
    accessor: row => new Moment(row.endAt).format('YYYY-MM-DD HH:mm:ss'),
    name: 'endAt',
    sortable: true,
    filterable: true,
  },
  {
    title: t('clients.calls.duration', 'Czas trwania[m]'),
    accessor: row =>
      Moment.duration(new Moment(row.endAt).diff(row.startAt)).asMinutes(),
    name: 'duration',
    sortable: true,
    filterable: true,
  },
  {
    title: t('columns.comment', 'Komentarz'),
    accessor: 'comment',
    name: 'comment',
  },
];

export default columnConfig;

import React from 'react';
import { withTranslation } from 'react-i18next';

class NumberRangeFilter extends React.Component {
  state = {
    valueFrom: this.props.value?.valueFrom || null,
    valueTo: this.props.value?.valueTo || null,
  };

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <input
          type="number"
          placeholder={this.props.t('clients.from', 'od')}
          value={this.state.valueFrom}
          onChange={async ev => {
            await this.setState({
              valueFrom: ev.target.value,
            });
            this.props.onFilterChange(this.state);
          }}
        />
        <input
          type="number"
          placeholder={this.props.t('clients.to', 'do')}
          value={this.state.valueTo}
          onChange={async ev => {
            await this.setState({
              valueTo: ev.target.value,
            });
            this.props.onFilterChange(this.state);
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(NumberRangeFilter);

import { ROLE_SHOW_REPORT_PURCHASED_ADDONS } from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';

const AddonsByClientReport = () => {
  const { t } = useTranslation();

  return (
    <BaseBrandReport
      archiveReportNames={['PURCHASED_ADDONS_BY_CLIENT']}
      title={t('common.mainMenu.reportsAddonsByClientList')}
      excelUrl="/reports/purchased-addons-by-client"
      excelAvailable={true}
      role={ROLE_SHOW_REPORT_PURCHASED_ADDONS}
      fileName={getReportFileName(
        t('common.mainMenu.reportsAddonsByClientList')
      )}
      dateType="range"
    />
  );
};

export default AddonsByClientReport;

import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import { get, put } from '../../helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../components/DialogLoader';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import { withToast } from 'material-ui-toast-redux';
import InfoBanner from 'components/CMS/InfoBanner';
import PresentationUrl from 'components/CMS/PresentationUrl';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';

const mealsInitialState = {
  enabled: false,
  showComponents: false,
  showAllergens: false,
  showNutritionalValues: false,
  showGlycemicIndex: false,
  showClientTags: false,
  showAvgRate: false,
  showComments: false,
  showBanner: false,
  bannerTitle: '',
  bannerSubTitle: '',
  bannerDesktop: false,
  bannerMobile: false,
};

const listPageMealsInitialState = {
  enabled: false,
  showBanner: false,
  bannerTitle: '',
  bannerSubTitle: '',
  bannerDesktop: false,
  bannerMobile: false,
};

const MealsCmsPage = ({ classes, t, selectedBrand, openToast }) => {
  const [meals, setMeals] = useState(mealsInitialState);
  const [listPageMeals, setListPageMeals] = useState(listPageMealsInitialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [module, setModule] = useState({});
  const [enabledStateWithApi, setEnabledStateWithApi] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const {
        configuration: { SeoDish },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoDish`);
      const modulesConfig = await get(`brands/${selectedBrand}/modules`);

      const getBanner = type =>
        SeoDish[type] ? SeoDish['@resources'][SeoDish[type]] : null;

      const mealsData = {
        enabled: SeoDish.enabled,
        showComponents: SeoDish.showComponents,
        showAllergens: SeoDish.showAllergens,
        showNutritionalValues: SeoDish.showNutritionalValues,
        showGlycemicIndex: SeoDish.showGlycemicIndex,
        showClientTags: SeoDish.showClientTags,
        showAvgRate: SeoDish.showAvgRate,
        showComments: SeoDish.showComments,
        showBanner: SeoDish.showBanner,
        bannerTitle: SeoDish.bannerTitle,
        bannerSubTitle: SeoDish.bannerSubTitle,
        bannerDesktop: getBanner('bannerDesktop'),
        bannerMobile: getBanner('bannerMobile'),
      };

      const listPageMealsData = {
        enabled: SeoDish.listPageEnabled,
        showBanner: SeoDish.listPageShowBanner,
        bannerTitle: SeoDish.listPageBannerTitle,
        bannerSubTitle: SeoDish.listPageBannerSubTitle,
        bannerDesktop: getBanner('listPageBannerDesktop'),
        bannerMobile: getBanner('listPageBannerMobile'),
      };

      setModule(modulesConfig);
      setMeals(mealsData);
      setListPageMeals(listPageMealsData);
      setEnabledStateWithApi(SeoDish.listPageEnabled);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const {
        configuration: { SeoDish },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoDish`);
      setEnabledStateWithApi(SeoDish.listPageEnabled);
    };
    fetchData();
  }, [isSubmitting]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoDish',
        configuration: {
          SeoCmsDishSide: module.configuration.SeoCmsDishSide,
          SeoDish: {
            ...meals,
            bannerMobile: meals.bannerMobile?.['@id'],
            bannerDesktop: meals.bannerDesktop?.['@id'],

            listPageEnabled: listPageMeals.enabled,
            listPageShowBanner: listPageMeals.showBanner,
            listPageBannerTitle: listPageMeals.bannerTitle,
            listPageBannerSubTitle: listPageMeals.bannerSubTitle,
            listPageBannerDesktop: listPageMeals.bannerDesktop?.['@id'],
            listPageBannerMobile: listPageMeals.bannerMobile?.['@id'],
          },
        },
      }).then(response => {
        openToast({
          messages: [
            t('status.success.save', 'Zmiany zostały pomyślnie zapisane.'),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
        setIsSubmitting(false);
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getImage = (name, data, setState) => {
    if (name === 'infoBannerDesktop') {
      setState(prev => ({ ...prev, bannerDesktop: data }));
    }

    if (name === 'infoBannerMobile') {
      setState(prev => ({ ...prev, bannerMobile: data }));
    }
  };

  const removeImage = (name, data, setState) => {
    if (name === 'infoBannerDesktop') {
      setState(prev => ({ ...prev, bannerDesktop: null }));
    }

    if (name === 'infoBannerMobile') {
      setState(prev => ({ ...prev, bannerMobile: null }));
    }
  };

  const handleBannerTextChange = (target, setState) => {
    if (target?.name === 'bannerHeader') {
      setState(prev => ({ ...prev, bannerTitle: target?.value }));
    }

    if (target?.name === 'bannerText') {
      setState(prev => ({ ...prev, bannerSubTitle: target?.value }));
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <CardWrapper title={t('cms.meals.mealsPage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setListPageMeals(prev => ({
                        ...prev,
                        enabled: !prev.enabled,
                      }));
                    }}
                    checked={listPageMeals.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('cms.meals.showMealsPage')}
              />
            </GridItem>
            {listPageMeals.enabled && (
              <GridItem xs={12}>
                <PresentationUrl
                  subpage="/dishes"
                  cmsShowActionButton={enabledStateWithApi}
                />
              </GridItem>
            )}
            <InfoBanner
              getImage={(name, data) => {
                getImage(name, data, setListPageMeals);
              }}
              bannerText={listPageMeals.bannerSubTitle}
              removeImage={(name, data) => {
                removeImage(name, data, setListPageMeals);
              }}
              bannerHeader={listPageMeals.bannerTitle}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, setListPageMeals);
              }}
              infoBannerCheckbox={listPageMeals.showBanner}
              infoBannerMobileUrl={listPageMeals.bannerMobile?.contentUrl}
              infoBannerDesktopUrl={listPageMeals.bannerDesktop?.contentUrl}
              handleCheckboxChange={() => {
                setListPageMeals(prev => ({
                  ...prev,
                  showBanner: !prev.showBanner,
                }));
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.meals.mealsSubpage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({ ...prev, enabled: !prev.enabled }))
                    }
                    checked={meals.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showMealsSubpage')}{' '}
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('cms.diets.showDietSubpage.tooltip')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem md={12}>
              <h3>{t('cms.zones.infoVisibility')}</h3>
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showComponents: !prev.showComponents,
                      }))
                    }
                    checked={meals.showComponents}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showDishComposition')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showAllergens: !prev.showAllergens,
                      }))
                    }
                    checked={meals.showAllergens}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showAllergens')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showNutritionalValues: !prev.showNutritionalValues,
                      }))
                    }
                    checked={meals.showNutritionalValues}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showNutritionalValues')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showGlycemicIndex: !prev.showGlycemicIndex,
                      }))
                    }
                    checked={meals.showGlycemicIndex}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showGlycemicIndex')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showClientTags: !prev.showClientTags,
                      }))
                    }
                    checked={meals.showClientTags}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showTags')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showAvgRate: !prev.showAvgRate,
                      }))
                    }
                    checked={meals.showAvgRate}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showAverageRating')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setMeals(prev => ({
                        ...prev,
                        showComments: !prev.showComments,
                      }))
                    }
                    checked={meals.showComments}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showComments')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <h2>{t('cms.infoBanner')}</h2>
            </GridItem>

            <InfoBanner
              getImage={(name, data) => {
                getImage(name, data, setMeals);
              }}
              bannerText={meals.bannerSubTitle}
              removeImage={(name, data) => {
                removeImage(name, data, setMeals);
              }}
              bannerHeader={meals.bannerTitle}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, setMeals);
              }}
              infoBannerTooltip={t('cms.showInfoBanner.tooltip.addons')}
              infoBannerCheckbox={meals.showBanner}
              infoBannerMobileUrl={meals.bannerMobile?.contentUrl}
              infoBannerDesktopUrl={meals.bannerDesktop?.contentUrl}
              handleCheckboxChange={() => {
                setMeals(prev => ({ ...prev, showBanner: !prev.showBanner }));
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      {module?.configuration?.SeoCmsDishSide && (
        <SEOSettingsCms
          state={module}
          setState={setModule}
          section="SeoCmsDishSide"
        />
      )}
      <FormControlButtons
        classes={classes}
        handleSubmit={handleSubmit}
        submitText={t('form.save')}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(MealsCmsPage);

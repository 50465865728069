import BuyBag from './BuyBag';
import v2Types from './v2Types';
import DietItem from './DietItem';
import OrderItemDiet from './OrderItemDiet';
import BagModification from './BagModification';

const components = {
  BuyBag,
  v2Types,
  DietItem,
  OrderItemDiet,
  BagModification,
};

export default components;

import Moment from 'moment';
import React from 'react';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    sortable: false,
    width: 50,
  },
  {
    title: 'zones.name',
    name: 'name',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    title: 'zones.shortName',
    name: 'shortName',
    accessor: 'shortName',
    filterable: true,
    sortable: true,
  },
  {
    title: 'zones.category',
    name: 'category.name',
    accessor: row => row.category?.name,
    filterable: true,
    sortable: true,
  },
  {
    title: 'zones.assignedDrivers',
    name: 'drivers.name',
    accessor: row => row.drivers?.map(driver => driver?.name).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'zones.codesQty',
    name: 'postCodes.code',
    accessor: row => row.postCodesLength,
    filterable: true,
    sortable: false,
  },
  {
    title: 'zones.deliveryType',
    name: 'deliveryType.value',
    accessor: row => row.deliveryType?.value,
    filterable: true,
    sortable: true,
  },
  {
    title: 'zones.hours',
    name: 'delivery-hours',
    accessor: row => {
      return row.deliveryHours.map(deliveryHourConfig => {
        switch (true) {
          case null !== deliveryHourConfig.hourFrom &&
            null !== deliveryHourConfig.hourTo:
            return (
              <p>
                {new Moment(deliveryHourConfig.hourFrom).format('HH:mm')} -{' '}
                {new Moment(deliveryHourConfig.hourTo).format('HH:mm')}
              </p>
            );
          case null !== deliveryHourConfig.hourFrom:
            return (
              <p>
                {t('$*common.from')}{' '}
                {new Moment(deliveryHourConfig.hourFrom).format('HH:mm')}
              </p>
            );
          case null !== deliveryHourConfig.hourTo:
            return (
              <p>
                {t('$*common.to')}{' '}
                {new Moment(deliveryHourConfig.hourTo).format('HH:mm')}
              </p>
            );
          default:
            return '-';
        }
      });
    },
    filterable: false,
    sortable: false,
  },
  {
    title: 'zones.priority',
    name: 'priority',
    accessor: row => row.priority,
    filterable: true,
    sortable: true,
  },
];

export default columnConfig;

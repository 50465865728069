import { Droppable } from 'react-beautiful-dnd';
import { Container, Title, RouteList } from './Table.styles';
import DriverRoute from '../DriverRoute';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const Table = ({ driver, routes, isDragDisabled }) => (
  <Container>
    <Title>{driver?.name}</Title>
    <Droppable droppableId={driver?.id}>
      {(provided, snapshot) => (
        <RouteList
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
        >
          {routes.map((route, index) => (
            <DriverRoute
              key={route.id}
              route={route}
              index={index}
              isDragDisabled={isDragDisabled}
            />
          ))}
          {provided.placeholder}
        </RouteList>
      )}
    </Droppable>
  </Container>
);

const enhance = compose(withTranslation());

export default enhance(Table);

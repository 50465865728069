const defaultState = {
  name: '',
  volume: 0,
  brandVat: 0,
  clientVat: 0,
  brandCost: 0,
  clientCost: 0,
};

export default defaultState;

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ExtensionIcon from '@material-ui/icons/Extension';
import makeStyles from '@material-ui/styles/makeStyles';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ADDON } from 'helpers/roles';

import columnConfig from './columnConfig';

const useStyles = makeStyles({
  formButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const AddonsCategoriesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const newCategory = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable
      title={t('views.addons.addonsCategories.list')}
      iconComponent={<ExtensionIcon />}
    >
      <div className={classes.formButton}>
        {isGranted(ROLE_CREATE_ADDON) && (
          <FormControlButtons
            handleSubmit={newCategory}
            submitText={`${t('views.addons.addonsCategories.addCategory')} +`}
          />
        )}
      </div>
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/addon-categories"
        reportName={'addoncategory'}
        columns={columnConfig.map(({ title, ...rest }) => ({
          ...rest,
          title: t(`columns.${title}`),
        }))}
        role="ADDON"
      />
    </AdminTable>
  );
};

export default AddonsCategoriesList;

import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import InputsIndex from './InputsIndex';
import produce from 'immer';
import { Info } from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';

const ClientPanel = ({ shopModule, setShopModule }) => {
  const { t } = useTranslation();

  const getValueHandlerByStateKey = (getter, setter, stateKey) => {
    const fieldProps = {
      value: getter?.[stateKey],
      handler: () => {
        setter(
          produce(draft => {
            draft[stateKey] = !draft[stateKey];
          })
        );
      },
    };

    return fieldProps;
  };

  const fields = [
    [
      {
        label: t(
          '$*shop.clientPanel.clientPanelShowOnNewOrder',
          '$$Pozwalaj zamawiać podczas kupowania diety'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'clientPanelShowOnNewOrder'
        ),
      },
      {
        label: t(
          '$*shop.clientPanel.clientPanelShowOnCalendarDay',
          '$$Pozwalaj domawiać na istniejących dniach diety'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'clientPanelShowOnCalendarDay'
        ),
      },
    ],
  ];

  const [fieldsLeft] = fields;

  return (
    <CardWrapper
      title={t('$*shop.clientPanel', '$$Ścieżki zamawiania (Dieta + Sklep)')}
    >
      <CardBody>
        <Divider style={{ marginBottom: '20px' }} />
        <GridContainer>
          <GridItem xs={6}>
            {fieldsLeft.map(field => (
              <GridContainer>
                <GridItem xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputsIndex {...field} />
                    {field.tooltip && (
                      <Tooltip
                        title={
                          <div>
                            <h4>{field.tooltip}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            ))}
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default ClientPanel;

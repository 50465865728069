import types from 'helpers/constants';

const initialState = {
  menuTemplates: [],
  menuTemplate: { name: '' },
  loading: 0,
};

function MenuTemplates(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MENU_TEMPLATES:
    case types.FETCH_MENU_TEMPLATE:
    case types.RESET_MENU_TEMPLATES_STATE:
    case types.RESET_MENU_TEMPLATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.REMOVE_MENU_TEMPLATE:
      return {
        ...state,
        menuTemplates: []
          .concat(state.menuTemplates)
          .filter(
            menuTemplate => menuTemplate.id !== action.payload.menuTemplateId
          ),
        menuTemplate: { name: '' },
      };
    case types.CREATE_MENU_TEMPLATE:
      let newMenuTemplates = [].concat(state.menuTemplates);
      newMenuTemplates.push(action.payload);

      return {
        ...state,
        menuTemplates: newMenuTemplates,
        menuTemplate: action.payload,
      };
    default:
      return state;
  }
}

export default MenuTemplates;

import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const CheckBoxForm = ({
  name,
  classes,
  checked,
  handleToggle,
  label,
  size = 12,
  disabled = false,
  tooltip = false,
}) => (
  <FormControlLabel
    control={
      <Checkbox
        tabIndex={-1}
        disabled={disabled}
        onClick={() => handleToggle(name)}
        checked={!disabled && checked}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />
    }
    classes={{
      label: classes.label,
    }}
    label={
      <>
        {label}
        {tooltip && (
          <Tooltip
            title={
              <div
                style={{ fontSize: '16px', lineHeight: 1, padding: 6 }}
                dangerouslySetInnerHTML={{ __html: tooltip }}
              />
            }
            placement="right"
          >
            <div style={{ marginLeft: '10px' }}>
              <Info fontSize={'small'} />
            </div>
          </Tooltip>
        )}
      </>
    }
  />
);

export default CheckBoxForm;

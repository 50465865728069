import React, { useState } from 'react';
import { isGranted } from 'helpers/helpers';
import { ROLE_SHOW_MONEY_BOX_HISTORY } from 'helpers/roles';

import { orderStyles } from '../../styles';
import Accordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Close from '@material-ui/icons/Close';
import Moneybox from 'views/Clients/FormTabs/Moneybox/Moneybox';
import { get } from 'helpers/apiHelpers';
import { useTranslation } from 'react-i18next';

const DietDetailsMoneybox = ({ client, fetchUser, setClient }) => {
  const [moneyboxModalStatus, setMoneyboxModalStatus] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => {
    get(client['@id']).then(client => {
      setClient(client);
      setMoneyboxModalStatus(false);
    });
  };

  return (
    <div
      style={{
        marginLeft: '5px',
        ...orderStyles.box,
      }}
    >
      {moneyboxModalStatus ? (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={moneyboxModalStatus}
          onClose={() => closeModal()}
        >
          <DialogContent style={{ maxHeight: '700px' }}>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => closeModal()}
            >
              <Close />
            </ModalButton>
            <Moneybox noCancel fetchUser={fetchUser} userId={client.id} />
          </DialogContent>
        </Dialog>
      ) : null}
      <Accordion
        active={0}
        collapses={[
          {
            title: t('clients.moneybox', 'Skarbonka'),
            content: (
              <div style={{ width: '100%' }}>
                <h5>
                  {t('clients.moneybox.accountBalance', 'Stan konta')}:{' '}
                  <b>
                    {client.moneyBox} {t('clients.moneybox', 'pkt')}
                  </b>
                </h5>
                {isGranted(ROLE_SHOW_MONEY_BOX_HISTORY) && (
                  <div>
                    <Divider
                      style={{ marginBottom: '5px', marginTop: '15px' }}
                    />
                    <Button
                      style={{ width: '100%' }}
                      onClick={() => setMoneyboxModalStatus(true)}
                    >
                      {t('common.more', 'Więcej')}
                    </Button>
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DietDetailsMoneybox;

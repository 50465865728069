import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { Divider } from '@material-ui/core';
import Card from 'components/Card/Card';
import React from 'react';

const ScriptsSettings = ({ t, classes, state, handleInputChange }) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem md={6}>
            <h4>{t('brandCfg.headTags', 'Znaczniki head')}</h4>
            <Divider style={{ marginBottom: '20px' }} />
            <FormTextInput
              multiline
              classes={classes}
              name="headTags"
              value={state.headTags}
              maxLength={5000}
              rows={5}
              handleChange={handleInputChange}
              inputSize={12}
            />

            <h4>{t('brandCfg.bodyTags', 'Znaczniki przed </body>')}</h4>
            <Divider style={{ marginBottom: '20px' }} />
            <FormTextInput
              multiline
              classes={classes}
              name="bodyTags"
              value={state.bodyTags}
              maxLength={5000}
              rows={5}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>

          <GridItem md={6}>
            <h4>{t('brandCfg.scripts', 'Skrypty')}</h4>
            <Divider style={{ marginBottom: '20px' }} />
            <FormTextInput
              multiline
              classes={classes}
              name="customScripts"
              value={state.customScripts}
              maxLength={5000}
              rows={15}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default ScriptsSettings;

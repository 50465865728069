import Card from 'components/Card/Card';
import { FontsModule } from '../../modules';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const FontSettings = ({
  classes,
  handleModuleUpdate,
  modules,
  modulesNames,
}) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem sm={12}>
            <FontsModule
              classes={classes}
              handleModuleUpdate={handleModuleUpdate}
              module={modules[modulesNames.Fonts]}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default FontSettings;

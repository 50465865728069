import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormLabel from '@material-ui/core/FormLabel';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import ColorPicker from 'components/BlockColorPicker/BlockColorPicker';
import Card from 'components/Card/Card';
import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import TOAST_DURATIONS from 'helpers/toastDurations';

const BackgroundSettings = ({
  t,
  state,
  classes,
  setState,
  isColor,
  setIsColor,
  Wrapper,
  handleColorPicker,
  getImage,
  removeImage,
  openToast,
}) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem sm={12}>
            <Wrapper>
              <div>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginTop: '15px' }}
                >
                  {t('brandCfg.baseColor')}
                </FormLabel>
                <div style={{ marginTop: '30px' }}>
                  <ColorPicker
                    color={state.mainColor}
                    handleChange={color =>
                      handleColorPicker(color, 'mainColor')
                    }
                  />
                </div>
              </div>
              <div>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginTop: '15px' }}
                >
                  {t('brandCfg.secondaryColor')}
                </FormLabel>
                <div style={{ marginTop: '30px' }}>
                  <ColorPicker
                    color={state.secondaryColor}
                    handleChange={color =>
                      handleColorPicker(color, 'secondaryColor')
                    }
                  />
                </div>
              </div>
              <div>
                {isColor ? (
                  <div>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginTop: '15px' }}
                    >
                      {t('brandCfg.backgroundColor', 'Kolor tła')}
                    </FormLabel>
                    <div style={{ marginTop: '30px' }}>
                      <ColorPicker
                        color={state.backgroundColor}
                        handleChange={color =>
                          handleColorPicker(color, 'backgroundColor')
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{
                          marginTop: '15px',
                          marginBottom: '24px',
                        }}
                      >
                        {t('brandCfg.backgroundPic')}
                      </FormLabel>
                      <div style={{ marginLeft: '-15px' }}>
                        <FormImageUpload
                          classes={classes}
                          stateName="backgroundImage"
                          buttonText={t('brandCfg.selectBackground')}
                          getImage={getImage}
                          removeImage={removeImage}
                          previewUrl={state.backgroundImageUrl}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        !state.backgroundImage &&
                        openToast({
                          messages: [
                            'Aby użyć obrazka jako tło strony klienckiej, załaduj obrazek',
                          ],
                          type: 'warning',
                          autoHideDuration: TOAST_DURATIONS.LG,
                        })
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.useImageAsBackground}
                            onChange={e => {
                              setState(prevState => ({
                                ...prevState,
                                useImageAsBackground:
                                  !state.useImageAsBackground,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            disabled={!state.backgroundImage}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t('brandCfg.useImage')}
                      />
                    </div>
                  </div>
                )}

                <Button
                  color="info"
                  round={false}
                  fullWidth={true}
                  onClick={() => setIsColor(!isColor)}
                >
                  {isColor
                    ? t(
                        'brandCfg.clientPanelDesign.changeToImage',
                        'Zmień na grafikę'
                      )
                    : t(
                        'brandCfg.clientPanelDesign.changeToColor',
                        'Zmień na kolor'
                      )}
                </Button>
              </div>
            </Wrapper>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default BackgroundSettings;

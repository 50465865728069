import isEmpty from 'lodash/isEmpty';
import StatusPicker from 'views/CateringsFlow/common/StatusPicker/StatusPicker';
import EmployeePicker from 'views/CateringsFlow/common/EmployeePicker/EmployeePicker';
import { getIntegerIfIntegerOrFixed } from 'helpers/helpers';
import { amountOfDoneSelectedColor } from '../../../utils/AmountOfDoneSelectedColor';

const columnConfig = ({ t }) => [
  {
    title: t(
      '$*cateringsFlow.dishesPackhouse.dishesList.column.recipeName',
      'Nazwa przepisu'
    ),
    name: 'name',
    accessor: row =>
      row?.recipe?.workName
        ? `(${row?.recipe?.id}) ${row?.recipe?.workName}`
        : row?.name,
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.subTask.amountToMake',
      '$$Ilość do zrobienia'
    ),
    name: 'amountToMake',
    accessor: row =>
      `${getIntegerIfIntegerOrFixed(
        ((row?.amountToMake ?? 0) *
          (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
          1000 ?? 0
      )} kg`,
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countToDoAfterUpdate',
      '$$Ilość do zrobienia 2'
    ),
    name: 'amountToMakeAfterRefreshedAmounts',
    accessor: row =>
      `${getIntegerIfIntegerOrFixed(
        ((row?.amountToMakeAfterRefreshedAmounts ?? 0) *
          (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
          1000 ?? 0
      )} kg`,
  },

  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countDone',
      '$$Ilość zrobiona'
    ),
    name: 'amountOfDone',
    accessor: row => (
      <div
        style={{ color: amountOfDoneSelectedColor(row) }}
      >{`${getIntegerIfIntegerOrFixed(
        ((row?.amountOfDone ?? 0) *
          (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
          1000 ?? 0
      )} kg`}</div>
    ),
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.subTask.note',
      '$$Notatka'
    ),
    name: 'comment',
    accessor: 'comment',
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.subTask.status',
      '$$Status'
    ),
    name: 'status',
    width: 150,
    accessor: row => {
      return (
        <div style={{ maxWidth: '150px' }}>
          <StatusPicker initialStatus={row?.status} isPickingEnabled={false} />
        </div>
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.subTask.assignedEmployee',
      '$$Pracownik'
    ),
    name: 'assignedEmployee',

    accessor: row => {
      return (
        <EmployeePicker
          row={row}
          employees={[]}
          isPickingEnabled={false}
          initialEmployee={
            isEmpty(row?.assignedEmployee)
              ? {}
              : {
                  contentUrl: row?.assignedEmployee?.image?.contentUrl,
                  label: `${row?.assignedEmployee?.firstName} ${row?.assignedEmployee?.lastName}`,
                  value: row?.assignedEmployee?.['@id'],
                }
          }
        />
      );
    },
    width: 85,
  },
];

export default columnConfig;

import { useState } from 'react';

import { yesNoOptions } from 'utils/yesNoOptions';

import SelectInput from 'components/FormSelect/SelectInput';

const ProcessingSelect = ({ t, row, classes, changeRow = () => {} }) => {
  const [selected, setSelected] = useState(
    yesNoOptions({ t })[row?.weightIsAfterMachining ? 1 : 0]
  );
  const rowIsIngredient = !row.recipe;

  return (
    <div style={{ width: '50px' }}>
      <SelectInput
        noGrid
        classes={classes}
        name="weightIsAfterMachining"
        mapBy="name"
        trackBy="value"
        value={selected}
        options={yesNoOptions({ t })}
        disabled={!rowIsIngredient}
        handleChange={(ev, obj) => {
          if (obj.value === selected.value) {
            return;
          }
          changeRow(row, obj.value === 1, 'weightIsAfterMachining');
          changeRow(row, 0, 'amount');
          changeRow(row, 0, 'lossWeight');
          setSelected(obj);
        }}
        id="weightIsAfterMachiningSelect"
      />
    </div>
  );
};

export default ProcessingSelect;

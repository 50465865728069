import { FormTextInput } from 'components';
import CardWrapper from 'components/Card/CardWrapper';
import GridItem from 'components/Grid/GridItem';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { combineStyles } from 'helpers/helpers';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { isEmpty } from 'lodash';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Button from 'components/CustomButtons/Button';
import { Info } from '@material-ui/icons';

import Tooltip from '@material-ui/core/Tooltip';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

const useStyles = makeStyles({
  gridItem: { margin: '0' },
  input: { border: 'none', borderBottom: '1px solid #d2d2d2', width: '95%' },
  inputLabel: { fontSize: '14px', color: '#8a8a8a', fontWeight: '400' },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px',
  },
  dialogTitle: { fontWeight: '400' },
  paragraph: { marginBottom: '0', fontWeight: 'bold' },
  tooltip: { marginLeft: '5px' },
});

const CopyingBrandSettingsView = ({
  classes: propClasses,
  loginData,
  handleChange,
  handleLogin,
  brandList,
  sourceBrandInformation,
  selectedBrandToCopy,
  setSelectedBrandToCopy,
  handleCopySettings,
  isModalOpen,
  handleShowModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      {isEmpty(brandList) ? (
        <CardWrapper title={t('brandCfg.copyingBrandSettings.title')}>
          <GridItem sm={6}>
            <FormTextInput
              label={
                <>
                  {t('brandCfg.copyingBrandSettings.apiAddress')}
                  <Tooltip
                    className={classes.tooltip}
                    title={
                      <div>
                        <h4>{t('brandCfg.copyingBrandSettings.tooltip')}</h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </>
              }
              classes={propClasses}
              name="apiAddress"
              value={loginData.apiAddress}
              handleChange={handleChange}
              inputSize={12}
              noAutoComplete={true}
            />{' '}
          </GridItem>
          <GridItem sm={6}>
            <FormTextInput
              label={t('brandCfg.copyingBrandSettings.mail')}
              classes={propClasses}
              name="email"
              type="email"
              value={loginData.email}
              handleChange={handleChange}
              inputSize={12}
              noAutoComplete={true}
            />
          </GridItem>
          <GridItem sm={6}>
            <FormTextInput
              label={t('brandCfg.copyingBrandSettings.password')}
              classes={propClasses}
              name="password"
              type="password"
              value={loginData.password}
              handleChange={handleChange}
              inputSize={12}
              noAutoComplete={true}
            />
          </GridItem>
          <GridItem sm={12}>
            <FormControlButtons
              classes={classes}
              handleSubmit={handleLogin}
              submitText={t('brandCfg.copyingBrandSettings.login')}
            />
          </GridItem>
        </CardWrapper>
      ) : (
        <CardWrapper
          title={`${t('brandCfg.copyingBrandSettings.title2')} ${
            sourceBrandInformation.name
          }`}
        >
          {brandList.map(brand => {
            return (
              <GridItem key={brand.id} className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedBrandToCopy['@id'] === brand['@id']}
                      onChange={() => {
                        setSelectedBrandToCopy(brand);
                      }}
                      name="custom"
                      icon={
                        <FiberManualRecord
                          className={propClasses.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord
                          className={propClasses.radioChecked}
                        />
                      }
                      classes={{
                        checked: propClasses.radio,
                        root: propClasses.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: propClasses.label,
                  }}
                  label={brand.name}
                />
              </GridItem>
            );
          })}
          <GridItem sm={12}>
            <FormControlButtons
              submitDisabled={!selectedBrandToCopy}
              classes={classes}
              handleSubmit={handleShowModal}
              submitText={t('brandCfg.copyingBrandSettings.save')}
            />
          </GridItem>
        </CardWrapper>
      )}

      <Dialog
        open={isModalOpen}
        onClose={handleShowModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {t('brandCfg.copyingBrandSettings.modal.title')}
        </DialogTitle>
        <DialogContent>
          <p className={classes.paragraph}>
            <Trans
              i18nKey="brandCfg.copyingBrandSettings.modal.content1"
              defaults="Kopiujesz konfigurację z marki {{newBrand}} na markę {{sourceBrand}}."
              values={{
                newBrand: selectedBrandToCopy.name,
                sourceBrand: sourceBrandInformation.name,
              }}
            />
          </p>
          <p className={classes.paragraph}>
            {t('brandCfg.copyingBrandSettings.modal.content2')}
          </p>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="danger" onClick={handleShowModal}>
            {t('brandCfg.copyingBrandSettings.modal.accept')}
          </Button>
          <Button
            color="success"
            onClick={() => {
              handleShowModal();
              handleCopySettings();
            }}
          >
            {t('brandCfg.copyingBrandSettings.modal.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles));

export default enhance(CopyingBrandSettingsView);

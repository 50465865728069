import React from 'react';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import { ROLE_CREATE_BRAND_ACCESS_KEY } from 'helpers/roles';
import DataGrid from 'components/DataGrid/DataGrid';
import { isGranted } from 'helpers/helpers';
import { post } from 'helpers/apiHelpers';

const generateNewBrandKey = () => post('/brand-access-keys', {});

const columnConfig = [
  {
    title: 'columns.name',
    accessor: 'name',
    name: 'name',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: 'brandCfg.accessKey',
    accessor: 'key',
    name: 'key',
    filterable: false,
    sortable: false,
  },
];

const AccessKeysModule = ({ classes, t }) => {
  const canCreate = isGranted(ROLE_CREATE_BRAND_ACCESS_KEY);

  return (
    <Card>
      <CardBody>
        <DataGrid
          actions={true}
          remove={true}
          export
          paginationTop={true}
          paginationBottom={false}
          url="/brand-access-keys"
          editPath={`${window.location.pathname}/edit`}
          reportName={'brandaccesskey'}
          columns={columnConfig}
          role="BRAND_ACCESS_KEY"
        />
        {canCreate && (
          <Button
            style={{ margin: '5px' }}
            color={'success'}
            className={classes.marginLeft}
            onClick={e =>
              generateNewBrandKey(e).then(() => window.location.reload())
            }
          >
            {t('brandCfg.accessKeyGenerateNew')}
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default connect(
  ({ Brands: { brandKeys } }) => ({ brandKeys }),
  null
)(withStyles(extendedFormsStyle)(withTranslation()(AccessKeysModule)));

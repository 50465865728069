import React from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_VARIANT, ROLE_REMOVE_VARIANT } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const List = ({ classes, t, history }) => {
  const newSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable title={t('list.variantsList')}>
      {isGranted(ROLE_CREATE_VARIANT) && (
        <FormControlButtons
          classes={classes}
          handleSubmit={newSize}
          submitText={t('form.addVariant') + ' +'}
        />
      )}
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={isGranted(ROLE_REMOVE_VARIANT)}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="variants"
        reportName={'variant'}
        columns={columnConfig(t)}
        role="VARIANT"
      />
    </AdminTable>
  );
};

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

const defaultState = {
  firstName: '',
  lastName: '',
  plainPassword: '',
  plainPasswordMatch: '',
  phone: '',
  phoneCountryCode: '',
  email: '',
  image: '',
  imageUrl: '',
};

export default defaultState;

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import classNames from 'classnames';

import { blackColor } from 'assets/jss/material-dashboard-pro-react';

const SIZES = {
  s: 's',
  m: 'm',
};

const useStyles = size =>
  makeStyles({
    tooltip: {
      width: `${size === SIZES.s ? 240 : 300}px`,
      padding: '12px 8px',
    },
    title: {
      display: 'grid',
      gap: '4px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      width: 'fit-content',
      padding: '2px 0',
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: '500',
      pointerEvents: 'all',
      textDecoration: 'underline',
      '&:hover': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
    openInNewIcon: {
      fontSize: '16px',
    },
    infoIcon: {
      fill: blackColor,
      opacity: '0.46',
    },
  })();

const InfoWithTooltip = ({ link, title, className, size = SIZES.m }) => {
  const { t } = useTranslation();

  const classes = useStyles(size);

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      interactive
      title={
        <div className={classes.title}>
          <div>{title}</div>
          <a
            className={classes.link}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common.findOutMore')}
            <OpenInNewIcon className={classes.openInNewIcon} />
          </a>
        </div>
      }
    >
      <InfoIcon
        className={classNames(classes.infoIcon, className)}
        fontSize="small"
      />
    </Tooltip>
  );
};

InfoWithTooltip.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default InfoWithTooltip;

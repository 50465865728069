import React from 'react';
import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';

import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from 'components/CustomButtons/Button';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';

const ConfirmationDialog = ({
  classes,
  isOpened = false,
  message = '',
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth={'sm'} open={isOpened} onClose={onClose}>
      <DialogTitle className={classes.modalHeader}>
        {t(
          '$*common.confirmationModal.title',
          '$$Czy na pewno chcesz to zrobić?'
        )}
      </DialogTitle>
      <DialogContent
        className={classes.modalBody}
        style={{ minHeight: '100px' }}
      >
        <p>{message}</p>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={onClose}>{t('common.no', 'Nie')}</Button>
        <Button onClick={onSubmit} color="success">
          {t('common.yes', 'Tak')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const combinedStyles = combineStyles(notificationsStyle, buttonsStyle);

export default withStyles(combinedStyles)(ConfirmationDialog);

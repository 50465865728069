import { Dialog, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export const DialogLoader = ({ loaderState, text }) => {
  return (
    <Dialog open={loaderState}>
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <h1>{text}</h1>
          <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
};

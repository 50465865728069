import types from 'helpers/constants';

const initialState = {
  mealTypes: [],
  mealType: {},
  loading: true,
};

function MealTypes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MEALTYPES:
      return {
        ...state,
        mealTypes: action.payload.mealTypes,
        loading: false,
      };
    case types.CLEAR_MEALTYPES:
      return {
        ...state,
        mealTypes: [],
      };
    case types.FETCH_MEALTYPE:
      return {
        ...state,
        loading: false,
        mealType: action.payload,
      };
    case types.REMOVE_MEALTYPE:
      return {
        ...state,
        mealTypes: state.mealTypes.filter(
          mealType => mealType.id !== action.payload.mealTypeId
        ),
      };
    default:
      return state;
  }
}

export default MealTypes;

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import Datetime from 'react-datetime';
import Check from '@material-ui/icons/Check';
import { modulesNames } from '../../../consts';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/styles/makeStyles';
import EXTERNAL_URLS from 'constants/externalUrls';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import classNames from 'classnames';
import { infoTooltipSpacing } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles({
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: infoTooltipSpacing,
    marginTop: '50px',
  },
  divider: {
    marginBottom: '20px',
  },
  checkRoot: {
    paddingLeft: 0,
  },
});

const DisabledDaysSectionSettings = ({
  state,
  classes: propClasses,
  t,
  setState,
  handleModuleUpdate,
  modules,
}) => {
  const classes = useStyles();

  return (
    <>
      <h4 className={classes.heading}>
        {t('brandCfg.daysOut')}
        <InfoWithTooltip
          title={t('brandCfg.daysOutTooltip')}
          link={EXTERNAL_URLS.daysOut}
        />
      </h4>
      <Divider className={classes.divider} />
      <Datetime
        className={'customRdtPickerStyle'}
        timeFormat={false}
        input={false}
        open={true}
        value={'0'}
        disabled={true}
        renderDay={(props, currentDate) => {
          let disabledDays = [...state.disabledDays];
          const isSelected = disabledDays.includes(
            currentDate.format('YYYY-MM-DD')
          );
          const key = currentDate.locale('en').format('dddd').toLowerCase();

          props.className += ' customTdStyle';
          let customDayStyle = 'customDayStyle';

          if (state[key].shouldBeDeliveredIn === 0) {
            customDayStyle = 'customDisabledClick';
          }
          if (state[key].shouldBeDeliveredInBlock === 0) {
            customDayStyle = 'customDisabledClick';
          }
          if (isSelected) {
            props.className += ' dayExcluded';
            customDayStyle += ' isSelected';
          }
          return (
            <td {...props}>
              <div className={customDayStyle}>{currentDate.date()}</div>
            </td>
          );
        }}
        onChange={ev => {
          const key = ev.locale('en').format('dddd').toLowerCase();
          if (state[key] === 0) {
            return;
          }
          let disabledDays = [...state.disabledDays];
          if (disabledDays.includes(ev.format('YYYY-MM-DD'))) {
            setState(prevState => ({
              ...prevState,
              disabledDays: state.disabledDays.filter(
                date => date !== ev.format('YYYY-MM-DD')
              ),
            }));
          } else {
            disabledDays.push(ev.format('YYYY-MM-DD'));

            setState(prevState => ({
              ...prevState,
              disabledDays: disabledDays,
            }));
            return false;
          }
        }}
      />
      <GridContainer>
        <GridItem md={12}>
          <Checkbox
            onClick={() =>
              handleModuleUpdate({
                ...modules?.[modulesNames.ConfigClientPanel],
                hideDisabledDaysOnClientCalendar:
                  !modules?.[modulesNames.ConfigClientPanel]
                    .hideDisabledDaysOnClientCalendar,
              })
            }
            checked={
              modules?.[modulesNames.ConfigClientPanel]
                .hideDisabledDaysOnClientCalendar
            }
            checkedIcon={<Check className={propClasses.checkedIcon} />}
            icon={<Check className={propClasses.uncheckedIcon} />}
            classes={{
              checked: propClasses.checked,
              root: classNames(propClasses.checkRoot, classes.checkRoot),
            }}
          />
          <span>{t('brandCfg.hideDisabledDays')}</span>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default DisabledDaysSectionSettings;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { goldColor, hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import cardIconStyle from 'assets/jss/material-dashboard-pro-react/components/cardIconStyle.jsx';

const CardIcon = ({ classes, className, children, color, ...rest }) => {
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined,
  });
  return (
    <div
      className={cardIconClasses}
      style={{
        background: goldColor,
        boxShadow:
          '0 4px 20px 0 rgba(' +
          hexToRgb(goldColor) +
          ',.14), 0 7px 10px -5px rgba(' +
          hexToRgb(goldColor) +
          ',.4)',
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
    'caterings',
  ]),
};

export default withStyles(cardIconStyle)(CardIcon);

import styled from 'styled-components';

const SelectContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  margin-top: 10px;

  > div {
    flex: 1;
  }
`;

const TableContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  margin-bottom: 20px; // do zmiany
  display: flex;
  padding: 20px;
  gap: 20px;
`;

// const TableHeader = styled.div`
//   display: flex;
//   padding: 10px;
//   border-bottom: 1px solid black;
//
//   > div {
//     flex: 1;
//   }
// `;
//
// const TableBody = styled.ul`
//   min-height: 100px;
//   padding: 10px 0;
//   margin: 0;
// `;
//
// const TableRow = styled.li`
//   padding: 10px;
//   display: flex;
//   cursor: move;
//
//   &:hover {
//     background-color: #f6f6f6;
//   }
//
//   > div {
//     flex: 1;
//   }
// `;

export { SelectContainer, TableContainer };

import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import PostCodeRow from './PostCodeRow';
import { v4 as uuidv4 } from 'uuid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { AddCircle } from '@material-ui/icons';
import ModalButton from '../../../../components/CustomButtons/Button';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlButtons from '../../../../components/FormControlButtons/FormControlButtons';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../../../../helpers/helpers';
import extendedFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import produce from 'immer';
import { useEffect } from 'react';

const defaultRow = () => ({
  _key: uuidv4(),
  postCode: '',
  city: '',
  street: '',
  buildingNumber: '',
});

const PostCodeModal = ({
  t,
  classes,
  postCodes,
  shortFormEnabled,
  postCodeModalStatus,
  togglePostCodeModal,
  setMergedAddresses,
  postCodeValidator,
}) => {
  const [postCodesData, setPostCodesData] = useState([defaultRow()]);
  const [errors, setErrors] = useState({});

  useEffect(() => {}, []);

  const addNextRow = () => {
    setPostCodesData(prevState => [...prevState, defaultRow()]);
  };

  const removeRow = row => {
    setPostCodesData(prevState =>
      prevState.filter(parameter => parameter._key !== row._key)
    );
  };

  const updateRow = (id, e) => {
    const postCodesDataCopy = JSON.parse(JSON.stringify(postCodesData));
    const rowIndex = postCodesDataCopy.findIndex(
      postCodeData => postCodeData._key === id
    );
    postCodesDataCopy[rowIndex][e.target.name] = e.target.value.toUpperCase();
    setPostCodesData(postCodesDataCopy);
  };

  const copyRow = row => {
    const copiedRow = { ...row, _key: uuidv4() };
    setPostCodesData(prevState => [...prevState, copiedRow]);
  };

  const setError = (rowId, property, message) => {
    setErrors(prevState => ({
      ...prevState,
      [rowId]: { ...prevState[rowId], [property]: message },
    }));
  };

  const removeError = (rowId, property) => {
    const copiedState = { ...errors };
    if (
      copiedState.hasOwnProperty(rowId) &&
      copiedState[rowId].hasOwnProperty(property)
    ) {
      delete copiedState[rowId][property];
      if (Object.keys(copiedState[rowId]).length === 0) {
        delete copiedState[rowId];
      }
      setErrors(copiedState);
    }
  };

  const trimExtraSpaces = (text = '') => {
    return text.replace(/\s+/g, ' ').trim();
  };

  const validateInput = (addressData, property) => {
    if (addressData[property] === '') {
      setError(addressData._key, property, t('zones.requiredField'));
      return false;
    } else if (!postCodeValidator(trimExtraSpaces(addressData[property]))) {
      setError(addressData._key, property, t('zones.typeValidPostcode'));
      return false;
    } else {
      return true;
    }
  };

  const mergeAddresses = () => {
    const areAddressesValid = [];
    postCodesData.forEach(address => {
      const isPostCodeValid = validateInput(address, 'postCode');
      areAddressesValid.push(isPostCodeValid);
    });

    if (areAddressesValid.some(x => x === false)) {
      return;
    }

    const postCodesCopy = [...postCodes];
    const mergedPostCodes = postCodesData.reduce((acc, item) => {
      const nextState = produce(acc, draftState => {
        const existsPostCodeIndex = draftState.findIndex(
          postCode =>
            trimExtraSpaces(postCode.code) === trimExtraSpaces(item.postCode)
        );

        if (existsPostCodeIndex !== -1) {
          const postCodeCities = draftState[existsPostCodeIndex].cities;
          const existsCityIndex = postCodeCities.findIndex(
            city => trimExtraSpaces(city.name) === trimExtraSpaces(item.city)
          );

          if (existsCityIndex !== -1) {
            const postCodeStreets =
              draftState[existsPostCodeIndex].cities[existsCityIndex].streets;

            const existsStreetIndex = postCodeStreets.findIndex(
              street =>
                trimExtraSpaces(street.name) === trimExtraSpaces(item.street)
            );

            if (existsStreetIndex !== -1) {
              const postCodeBuildings =
                draftState[existsPostCodeIndex].cities[existsCityIndex].streets[
                  existsStreetIndex
                ].buildings;
              const existsBuildingIndex = postCodeBuildings.findIndex(
                buildingName => buildingName === item.buildingNumber
              );

              if (existsBuildingIndex === -1) {
                if (!isEmpty(item.buildingNumber)) {
                  draftState[existsPostCodeIndex].cities[
                    existsCityIndex
                  ].streets[existsStreetIndex].buildings.push(
                    item.buildingNumber
                  );
                }
              }
            } else {
              if (!isEmpty(item.street)) {
                draftState[existsPostCodeIndex].cities[
                  existsCityIndex
                ].streets.push({
                  name: trimExtraSpaces(item.street),
                  buildings: item.buildingNumber.length
                    ? [item.buildingNumber]
                    : [],
                });
              }
            }
          } else {
            if (!isEmpty(item.city))
              draftState[existsPostCodeIndex].cities.push({
                name: trimExtraSpaces(item.city),
                streets: item.street.length
                  ? [
                      {
                        name: trimExtraSpaces(item.street),
                        buildings: [item.buildingNumber],
                      },
                    ]
                  : [],
              });
          }
        } else {
          draftState.push({
            index: acc[acc.length - 1]?.index + 1,
            code: trimExtraSpaces(item.postCode),
            cities:
              item.city.length > 0
                ? [
                    {
                      name: trimExtraSpaces(item.city),
                      streets:
                        item.street.length > 0
                          ? [
                              {
                                name: trimExtraSpaces(item.street),
                                buildings:
                                  item.buildingNumber.length > 0
                                    ? [item.buildingNumber]
                                    : [],
                              },
                            ]
                          : [],
                    },
                  ]
                : [],
          });
        }
      });

      return nextState;
    }, postCodesCopy);

    setMergedAddresses(mergedPostCodes);
    setPostCodesData([defaultRow()]);
    togglePostCodeModal();
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={false}
      open={postCodeModalStatus}
      onClose={togglePostCodeModal}
    >
      <DialogContent>
        <ModalButton
          data-cy="new-zones_post-codes-modal_close-button"
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            cursor: 'pointer',
          }}
          color={'transparent'}
          justIcon
          round
          onClick={togglePostCodeModal}
        >
          <Close />
        </ModalButton>
        <GridContainer>
          <GridItem xs={12}>
            {postCodesData?.map((postCodeData, index) => (
              <PostCodeRow
                key={postCodeData._key}
                copyRow={copyRow}
                removeRow={removeRow}
                updateRow={updateRow}
                removeError={removeError}
                postCodeData={postCodeData}
                canBeRemoved={index !== 0}
                shortFormEnabled={shortFormEnabled}
                errors={errors[postCodeData._key]}
              />
            ))}
          </GridItem>

          <GridItem xs={12}>
            <Button
              data-cy="new-zones_post-codes-modal_add-next-row"
              onClick={addNextRow}
              disabled={false}
              color={'default'}
              round
              justIcon
            >
              <AddCircle />
            </Button>
          </GridItem>

          <GridItem xs={12}>
            <FormControlButtons
              dataCy="new-zones_post-codes-modal_confirm-button"
              classes={classes}
              submitText={t('zones.add', 'Dodaj')}
              handleSubmit={mergeAddresses}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(PostCodeModal);

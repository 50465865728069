import DataGrid from 'components/DataGrid/DataGrid';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';

const Bmi = ({ userId }) => {
  const { t } = useTranslation();

  const bmiColumnConfig = [
    {
      title: t('columns.date', 'Data'),
      name: 'createdAt',
      Filter: ({ onChange, filter }) => {
        return <DateRangeFilter onChange={onChange} value={filter?.value} />;
      },
      accessor: row => dateTimeCell(row.createdAt),
      width: 180,
    },
    {
      title: t('clients.gender', 'Płeć'),
      name: 'gender',
      accessor: ({ gender }) => {
        switch (gender) {
          case 'male':
            return t('clients.man', 'Mężczyzna');
          case 'female':
            return t('clients.woman', 'Kobieta');
          default:
            return '';
        }
      },
      Filter: ({ onChange, filter }) => (
        <select
          style={{ height: '30px', width: '100%', padding: '0' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value="">{t('common.all', 'Wszystkie')}</option>
          <option value="male">{t('clients.man', 'Mężczyzna')}</option>
          <option value="female">{t('clients.woman', 'Kobieta')}</option>
        </select>
      ),
    },
    {
      title: t('columns.age', 'Wiek'),
      name: 'age',
      accessor: 'age',
    },
    {
      title: t('columns.height', 'Wzrost (cm)'),
      name: 'height',
      accessor: 'height',
    },
    {
      title: t('columns.weight', 'Waga (kg)'),
      name: 'weight',
      accessor: 'weight',
    },
    {
      title: t('columns.bmi', 'Wynik BMI'),
      name: 'bmi',
      accessor: 'bmi',
    },
  ];

  return (
    <DataGrid
      refreshOnFetch
      actions={true}
      remove={true}
      paginationTop={true}
      paginationBottom={false}
      export={false}
      url="/client-metrics"
      role="CLIENT_METRIC"
      columns={bmiColumnConfig}
      defaultSorted={[{ id: 'createdAt', desc: true }]}
      manipulateQuery={(_, query) => {
        query['client.id'] = userId;

        if (query.createdAt) {
          const filters = query.createdAt;
          delete query.createdAt;
          if (filters.after) {
            query['createdAt[after]'] = filters.after;
          }
          if (filters.before) {
            query['createdAt[before]'] = filters.before;
          }
        }

        return query;
      }}
    />
  );
};

export default Bmi;

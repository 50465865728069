import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';
import List from 'views/Products/List/List';
import Form from 'views/Products/Form';
import withRole from 'components/Security/withRole';
import { ROLE_EDIT_PRODUCT, ROLE_SHOW_PRODUCT } from 'helpers/roles';

const Products = props => {
  const ListSecured = withRole(ROLE_SHOW_PRODUCT, List);
  const EditForm = withRole(ROLE_EDIT_PRODUCT, Form);

  return (
    <Router history={props.history}>
      <Switch>
        <Route
          exact
          path={`${props.match.url}/`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${props.match.url}/edit/:id`}
          render={parentProps => (
            <EditForm {...props} {...parentProps} key="edit" />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(Products);

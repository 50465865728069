import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchUnits = () => {
  return dispatch =>
    get('/units', { pagination: false }).then(units => {
      dispatch({
        type: types.FETCH_UNITS,
        payload: {
          units: units['hydra:member'],
        },
      });
    });
};

import React from 'react';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';

const FormIngredientsMacronutients = ({
  classes,
  handleChange,
  caloriesErrorDetector,
  options,
  t,
}) => {
  return (
    <GridItem xs={12}>
      <CardWrapper title={t('form.field.macronutrients')}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={10}>
              <GridContainer>
                {options.map((option, index) => {
                  return (
                    <GridItem md={3} key={index}>
                      <FormLabel className={classes.labelHorizontal}>
                        {option.label}{' '}
                      </FormLabel>
                      <CustomInput
                        id={option.id}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: option.type,
                          name: option.name,
                          onChange: handleChange,
                          value: option.value,
                          ...(option.inputProps ?? {}),
                        }}
                      />
                    </GridItem>
                  );
                })}
                <GridItem md={3}>{caloriesErrorDetector()}</GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </GridItem>
  );
};

export default FormIngredientsMacronutients;

import React from 'react';
import { TableCell } from '@material-ui/core';
import Danger from 'components/Typography/Danger';
import Warning from 'components/Typography/Warning';

class CodeServingCell extends React.Component {
  render() {
    const { dishSizes, size } = this.props;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );
    const { code } = dishSizeBySize;

    const getText = text => {
      if (text === null) {
        return <Danger>Błąd - brak kodu</Danger>;
      }

      if (text === undefined) {
        return <Warning>Kod zostanie wyświetlony po zapisie</Warning>;
      }

      return text;
    };

    return <TableCell>{getText(code)}</TableCell>;
  }
}

export default CodeServingCell;

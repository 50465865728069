import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ['Auth', 'Theme', 'MenuPlanners'],
};

const pReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
export const persistor = persistStore(store);

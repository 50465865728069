import React from 'react';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: 'employees.brand',
    accessor: row => row.brand?.name,
    name: 'brand',
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.user',
    accessor: row => {
      return `${row.client.firstName} ${row.client.lastName}`;
    },
    name: 'user',
    width: 200,
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.rate',
    accessor: row => {
      if (row.type === 'DAY') {
        return '';
      }
      let stars = [];
      let fullStars = row.rate;
      let borderedStars = 5 - row.rate;

      while (fullStars-- > 0) {
        stars.push(<Star style={{color: '#decf00'}}/>);
      }

      while (borderedStars-- > 0) {
        stars.push(<StarBorder style={{color: '#decf00'}}/>);
      }

      return (
          <div>
            {stars.map((el, key) => (
                <span key={key}>{el}</span>
            ))}
          </div>
      );
    },
    width: 130,
    name: 'rate',
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.content',
    accessor: 'comment',
    name: 'comment',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

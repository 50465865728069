import RecordFieldHistory from 'components/History/RecordFieldHistory';
import React from 'react';

const FormIngredientRecordFieldHistorySection = ({ match, t, currency }) => {
  return (
    <RecordFieldHistory
      iri={`/ingredients/${match.params.id}`}
      defaultField="price"
      fields={[
        {
          name: t('form.field.pricePerKgCurr', {
            replace: { currency: currency },
          }),
          val: 'price',
        },
        {
          name: t('form.field.weightBeforeProcessing'),
          val: 'weight',
        },
        { name: t('form.field.fat'), val: 'fat' },
        {
          name: t('form.field.absorbableCarbohydrates'),
          val: 'carbohydrates',
        },
        { name: t('form.field.simpleSugars'), val: 'sugar' },
        { name: t('form.field.protein'), val: 'protein' },
        { name: t('form.field.fiber'), val: 'roughage' },
        { name: t('form.field.salt'), val: 'salt' },
        {
          name: t('form.field.nkt'),
          val: 'saturatedFattyAcids',
        },
        {
          name: t('form.field.glycemicIndex.label'),
          val: 'glycemicIndex',
        },
      ]}
    />
  );
};

export default FormIngredientRecordFieldHistorySection;

import React, { Component } from 'react';
import translateColumnConfig from './callsColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_CLIENT_CALL } from 'helpers/roles';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { Divider } from '@material-ui/core';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import Form from './Form.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class Calls extends Component {
  state = {
    refreshList: false,
  };

  userId = this.props.userId ? this.props.userId : this.props.match.params.id;

  refreshList = () => {
    this.setState({ refreshList: !this.state.refreshList });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          {isGranted(ROLE_CREATE_CLIENT_CALL) && (
            <FormControlButtons
              classes={classes}
              cancelPath={this.props.noCancel ? '' : '/admin/clients'}
              discardText={this.props.t('clients.backToList')}
              handleSubmit={this.openModal}
              submitText={this.props.t('clients.add')}
            />
          )}
        </div>
        <Divider />
        <DataGrid
          refresh={this.state.refreshList}
          actions={true}
          editAction={this.openModal}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url={`clients/${this.userId}/calls`}
          columns={translateColumnConfig(t)}
          role="CLIENT_CALL"
          manipulateQuery={(requestData, query) => {
            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;
              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }

            query['client.id[]'] = this.props.userId;
            return query;
          }}
        />
        <Form
          openModal={e => (this.openModal = e)}
          refreshList={this.refreshList}
        />
      </React.Fragment>
    );
  }
}

const enhance = compose(
  withStyles(buttonsStyle),
  withTranslation(),
  withRouter
);

export default enhance(Calls);

import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';

import GridItem from 'components/Grid/GridItem.jsx';
import IconUpload from 'components/CustomUpload/IconUpload.jsx';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import gastro from 'helpers/gastro';

const MAX_ICON_SIZE = 500 * 1024; // 500 kB

let uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}/uploaded-media/`;

const FormIconUpload = ({
  label,
  classes,
  getImage,
  removeImage,
  removeIcon,
  stateName,
  previewUrl,
  file,
  buttonText,
  isIcon,
  tooltip,
  imgId,
  additionalText,
  margin,
  padding,
  btnOnlyIcon,
  section,
  index,
}) => {
  const uploadImage = async (state, image) => {
    let data = new FormData();
    data.append('file', image);

    const response = await gastro.post('/media', data);
    getImage(state, response.data, section ?? null, index ?? null);
  };

  return (
    <React.Fragment>
      {label && (
        <GridItem style={{ alignSelf: 'center' }} xs={8}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', 'flex-direction': 'column' }}>
              <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
              {additionalText && (
                <small style={{ color: 'rgba(0, 0, 0, 0.46)' }}>
                  {additionalText}
                </small>
              )}
            </div>
            {tooltip && (
              <Tooltip
                style={{ marginLeft: '10px', color: 'grey' }}
                title={
                  <div>
                    <h4>{tooltip}</h4>
                  </div>
                }
                placement="right"
              >
                <Info fontSize={'small'} />
              </Tooltip>
            )}
          </div>
        </GridItem>
      )}
      <GridItem
        style={{ alignSelf: 'center', margin: margin ? '1rem' : null }}
        xs={12}
      >
        <IconUpload
          btnOnlyIcon={btnOnlyIcon}
          padding={padding}
          isIcon={isIcon}
          stateName={stateName}
          getImage={uploadImage}
          removeImage={removeImage}
          removeIcon={removeIcon}
          section={section}
          index={index}
          previewUrl={
            previewUrl
              ? section
                ? `${previewUrl}`
                : `${uploadedContentBaseURL}${previewUrl}`
              : null
          }
          buttonText={buttonText}
          maxUploadedFileSize={MAX_ICON_SIZE}
          imgId={imgId}
          file={file}
          addButtonProps={{
            color: 'info',
            round: false,
          }}
          changeButtonProps={{
            color: 'info',
            round: false,
          }}
          removeButtonProps={{
            color: 'danger',
            round: false,
          }}
        />
      </GridItem>
    </React.Fragment>
  );
};

export default FormIconUpload;

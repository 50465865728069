import React, { Component } from 'react';
import { Route, Router, Switch, withRouter } from 'react-router';

import List from './Diets.container';
import Form from './Details/DietDetails.container.js';
import withRole from 'components/Security/withRole';
import { ROLE_EDIT_ORDER, ROLE_SHOW_ORDER } from 'helpers/roles';

class Diets extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_ORDER, List);
    const EditForm = withRole(ROLE_EDIT_ORDER, Form);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/preview/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Diets);

import React from 'react';
import Moment from 'moment';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Select from 'components/Grid/Filter/Select';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.commentDate',
    accessor: row => Moment(row.updated_at).format('YYYY-MM-DD HH:mm'),
    name: 'createdAt',
    Filter: ({ onChange, filter }) => {
      return <DateRangeFilter onChange={onChange} value={filter?.value} />;
    },
    width: 180,
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.category',
    name: 'type',
    accessor: ({ type }) =>
      t(`comments.category.${type.toLowerCase()}`).toUpperCase(),
    filterable: false,
    Filter: ({ onChange, filter }) => {
      const options = [
        { '@id': 'DAY', name: t('comments.category.day') },
        { '@id': 'MEAL', name: t('comments.category.meal') },
        { '@id': 'DELIVERY', name: t('comments.category.delivery') },
        { '@id': 'MEAL_SHOP', name: t('comments.category.meal_shop') },
      ];

      return (
        <Select
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
    width: 150,
    sortable: false,
  },
  {
    title: 'comments.dish',
    accessor: row => {
      if (row.type === 'MEAL') {
        return row?.name_for_client;
      }
    },
    name: 'dishItem.dish.nameForClient',
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.rate',
    accessor: row => {
      if (row.type === 'DAY') {
        return '';
      }
      let stars = [];
      let fullStars = row.rate;
      let borderedStars = 5 - row.rate;

      while (fullStars-- > 0) {
        stars.push(<Star style={{ color: '#DECF00' }} />);
      }

      while (borderedStars-- > 0) {
        stars.push(<StarBorder style={{ color: '#DECF00' }} />);
      }

      return (
        <div>
          {stars.map((el, key) => (
            <span key={key}>{el}</span>
          ))}
        </div>
      );
    },
    width: 130,
    name: 'rate',
    filterable: false,
    sortable: false,
  },
  {
    title: 'comments.content',
    accessor: 'comment',
    name: 'comment',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

import React from 'react';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import CardWrapper from 'components/Card/CardWrapper';

const HeaderSettings = ({ t, classes, modules, setModules, modulesNames }) => {
  return (
    <CardWrapper
      title={t(
        'brandCfg.clientPanelDesign.headerNavbarConfig',
        'Konfiguracja nagłówka / paska nawigacji'
      )}
    >
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="allowClientSeeOwnId"
                  onClick={() =>
                    setModules(prevState => ({
                      ...prevState,
                      [modulesNames.ConfigClientPanel]: {
                        ...prevState[modulesNames.ConfigClientPanel],
                        allowClientSeeOwnId:
                          !modules[modulesNames.ConfigClientPanel]
                            .allowClientSeeOwnId,
                      },
                    }))
                  }
                  checked={
                    modules[modulesNames.ConfigClientPanel].allowClientSeeOwnId
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t(
                'brandCfg.clientPanelDesign.showIdOnNavbar',
                'Włącz pokazywanie ID na pasku nawigacji'
              )}
            />
          </GridItem>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    modules[modulesNames.ConfigClientPanel]
                      .allowClientSeeMoneyBox
                  }
                  onChange={() => {
                    setModules(prevState => ({
                      ...prevState,
                      [modulesNames.ConfigClientPanel]: {
                        ...prevState[modulesNames.ConfigClientPanel],
                        allowClientSeeMoneyBox:
                          !modules[modulesNames.ConfigClientPanel]
                            .allowClientSeeMoneyBox,
                      },
                    }));
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t(
                'brands.newBrandForm.allowClientSeeMoneyBox',
                'Włącz pokazywanie skarbonki na pasku nawigacji'
              )}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default HeaderSettings;

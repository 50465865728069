import React from 'react';
import { TableCell } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';

class ShopAvailabilityCell extends React.Component {
  render() {
    const {
      size,
      dishSizes,
      handleSizeShopAvailabilityChange,
      classes,
    } = this.props;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    return (
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={dishSizeBySize.allowedInShop}
              onChange={e => {
                handleSizeShopAvailabilityChange(
                  !dishSizeBySize.allowedInShop,
                  dishSizeBySize
                );
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
        />
      </TableCell>
    );
  }
}

export default withTranslation()(ShopAvailabilityCell);

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RegularCheckbox from 'components/Checkbox/RegularCheckbox';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

const LabeledCheckbox = ({
  checked = false,
  onClick,
  label,
  disabled = false,
  name,
  classes,
}) => {
  return (
    <FormControlLabel
      control={
        <RegularCheckbox
          name={name}
          checked={checked}
          onClick={onClick}
          disabled={disabled}
        />
      }
      label={label}
      classes={{
        label: classes.label,
      }}
    />
  );
};

export default withStyles(extendedFormsStyle)(LabeledCheckbox);

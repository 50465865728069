import { calcRecipeCountInDishTask } from './calculator';

export const amountOfDoneSelectedColor = row => {
  let difference =
    row.amountOfDone -
    (row.amountToMakeAfterRefreshedAmounts || row.amountToMake);

  if (difference === 0) {
    return '#008000';
  } else if (difference < 0) {
    return '#FF0000';
  } else {
    return '#e46f01';
  }
};
export const amountOfDoneSelectedColorKitchenSetMainColor = row => {
  const sumAmountOfDoneAfterUpdate = row?.amountToMakeAfterRefreshedAmounts;
  const amountOfDone = row?.amountDetails.map(el => el?.amountOfDone);
  const amountToMake = row?.dishTasks.map(el =>
    calcRecipeCountInDishTask({
      dishTaskMetadata: el?.taskMetadata,
      recipeId: row?.recipe?.id,
    })
  );

  const sumAmountOfDone = amountOfDone.reduce((sum, el) => sum + el, 0);
  const sumAmountToMake = amountToMake.reduce((sum, el) => sum + el, 0);

  const color =
    (sumAmountOfDoneAfterUpdate
      ? sumAmountOfDone.toFixed(4) - sumAmountOfDoneAfterUpdate.toFixed(4)
      : sumAmountOfDone.toFixed(4) - sumAmountToMake.toFixed(4)) === 0
      ? 'green'
      : (sumAmountOfDoneAfterUpdate
          ? sumAmountOfDone - sumAmountOfDoneAfterUpdate
          : sumAmountOfDone - sumAmountToMake) > 0
      ? 'orange'
      : 'red';

  return { color };
};

export const amountOfDoneSelectedColorKitchen = row => {
  if (row?.amountDetails.length > 1) {
    return amountOfDoneSelectedColorKitchenSetMainColor(row);
  }
  const doneAmount = row?.amountOfDone.toFixed(4);
  const toMakeAmount = row?.amountToMake.toFixed(4);
  const refreshedAmounts = row?.amountToMakeAfterRefreshedAmounts;
  if (refreshedAmounts) {
    const refreshedAmount = refreshedAmounts.toFixed(4);
    if (doneAmount - refreshedAmount === 0) return { color: 'green' };
    if (doneAmount - refreshedAmount < 0) return { color: 'red' };
  } else {
    if (doneAmount - toMakeAmount === 0) return { color: 'green' };
    if (doneAmount - toMakeAmount < 0) return { color: 'red' };
  }
  return { color: 'orange' };
};

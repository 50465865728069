import { get, remove } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchZoneCategories = () => {
  return dispatch => {
    return get('/zone-categories', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_ZONE_CATEGORIES,
        payload: {
          zoneCategories: response['hydra:member'],
        },
      });
    });
  };
};

export const fetchZoneCategory = id => {
  return dispatch => {
    return get(`/zone-categories/${id}`).then(response => {
      dispatch({
        type: types.FETCH_ZONE_CATEGORY,
        payload: {
          name: response.name,
        },
      });
      return response;
    });
  };
};

export const removeZoneCategory = id => {
  return dispatch =>
    remove(`/zone-categories/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_ZONE_CATEGORY,
        payload: {
          zoneCategoryId: id,
        },
      });
    });
};

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class YesNoCell extends React.Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  render() {
    const { classes, t } = this.props;

    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={this.state.value}
          onChange={ev => {
            this.props.onChange(ev);
            this.setState({
              value: ev.target.value,
            });
          }}
          inputProps={{
            name: 'simpleSelect',
            id: 'simple-select',
          }}
        >
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={true}
          >
            {this.props.trueLabel || t('common.yes', 'Tak')}
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={false}
          >
            {this.props.falseLabel || t('common.no', 'Nie')}
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
}

const enhance = compose(
  withToast,
  withStyles(extendedFormsStyle),
  withTranslation()
);

export default enhance(YesNoCell);

const translateDefaultUsage = {
  FOR_BAG: 'form.field.defaultUsage.option.forBag',
  FOR_DIET: 'form.field.defaultUsage.option.forDiet',
};
const translateExcludedPlaces = {
  NEW_ORDER: 'columns.excludedPlaces.newOrder',
  EXTEND_BAG: 'columns.excludedPlaces.extendBag',
};

const columnConfig = ({ t }) => [
  {
    title: t('columns.id'),
    accessor: 'id',
    name: 'id',
    filterable: false,
    sortable: false,
    width: 50,
  },
  {
    title: t('columns.name'),
    name: 'name',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    title: t('columns.code'),
    name: 'code',
    accessor: 'code',
    filterable: true,
    sortable: true,
  },
  {
    title: t('columns.category'),
    name: 'category',
    accessor: ({ category }) => category?.name || '-',
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.defaultUsage'),
    name: 'defaultUsage',
    accessor: ({ defaultUsage }) =>
      t(translateDefaultUsage[defaultUsage]) || '-',
    filterable: false,
    sortable: false,
    translateAccessor: true,
  },
  {
    title: t('columns.excludedPlaces'),
    name: 'excludedPlaces',
    accessor: ({ excludedPlaces }) =>
      excludedPlaces
        .map(excludedPlace => t(translateExcludedPlaces[excludedPlace]))
        .join(', '),
    filterable: false,
    sortable: false,
    translateAccessor: true,
  },
  {
    title: t('columns.cost'),
    name: 'cost',
    accessor: row => row.cost,
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.clientCost'),
    name: 'clientCost',
    accessor: row => row.clientCost,
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.clientVat'),
    name: 'clientVat',
    accessor: row => row.clientVat,
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.contractor'),
    name: 'contractor',
    accessor: ({ contractor }) => contractor?.name || '-',
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.stock'),
    name: 'stock',
    accessor: row => row.stock,
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.active'),
    name: 'active',
    accessor: (row, { t }) =>
      row.active ? t('common.yes', 'Tak') : t('common.no', 'Nie'),
    filterable: false,
    sortable: false,
  },
  {
    title: t('columns.priority'),
    name: 'position',
    accessor: row => row.position,
    filterable: false,
    sortable: true,
  },
];

export default columnConfig;

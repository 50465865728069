import React from 'react';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import CombinedReport from './CombinedReport/CombinedReport';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';
import MIME_TYPES from './constants/mimeTypes';

class RecipesChecklist extends React.Component {
  state = {
    includeSubscriptions: false,
  };

  render() {
    const { t } = this.props;

    return (
      <CombinedReport
        name="CHECK_LIST_RECIPES"
        title={t('reports.reportRecipesChecklist')}
        url="reports/checklist-recipes"
        mimeTypes={[MIME_TYPES.pdf, MIME_TYPES.xlsx]}
        role={roles.ROLE_SHOW_REPORT_CHECKLIST_RECIPES}
        fileName={params =>
          getReportFileNameWithMultiplier(
            params,
            this.props.t('common.mainMenu.reportsRecipesChecklist')
          )
        }
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        useSubBrands={true}
        useZoneCategories={true}
        useRecipeTags={true}
        singleSelectRecipeTags={true}
        isSalesMarketLangViewEnabled={true}
        archiveReportNames={['CHECK_LIST_RECIPES']}
        reportConfiguration={{
          report: 'ChecklistRecipesCompanyConfiguration',
          fields: [
            {
              size: 12,
              field: `roundDecimalPoints`,
              inputType: 'number',
            },
            {
              size: 12,
              field: `forceKg`,
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              field: `sortMode`,
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'SORT_BY_RECIPE_CARDS',
                    label: this.props.t(
                      'reports.checklistRecipe.sorbByRecipeCards',
                      'Sortowanie według kolejności przepisów'
                    ),
                  },
                  {
                    value: 'SORT_BY_RECIPE_ID',
                    label: this.props.t(
                      'reports.checklistRecipe.sorbByRecipeId',
                      'Sortowanie według kolejności id'
                    ),
                  },
                ],
              },
            },
          ],
        }}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(RecipesChecklist);

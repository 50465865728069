const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    sortable: false,
    width: 50,
  },
  {
    title: 'zoneCategories.name',
    name: 'name',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    title: 'zoneCategories.zones',
    name: 'zones',
    accessor: row => row.zones.length,
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

import { get, remove } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchRecipes = ({
  pageSize = 30,
  page = 0,
  sorted = [],
  filtered = [],
}) => {
  const filters = filtered.reduce((curr, next) => {
    curr[next.id] = next.value;
    return curr;
  }, {});

  return dispatch =>
    get('/recipes', {
      itemsPerPage: pageSize,
      page: page + 1,
      ...filters,
      id: filters.id,
      clientName: filters.name,
      'ingredients.ingredient.name': filters.ingredients,
      'allergens.value': filters.allergens,
      'tags.value': filters.tags,
      partial: false,
    }).then(recipes => {
      dispatch({
        type: types.FETCH_RECIPES,
        payload: {
          recipes: recipes['hydra:member'],
          pages: Math.ceil(recipes['hydra:totalItems'] / pageSize),
        },
      });

      return recipes['hydra:member'];
    });
};

export const removeRecipe = id => {
  return dispatch =>
    remove(`/recipes/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_RECIPE,
        payload: {
          removedItemId: id,
        },
      });
    });
};

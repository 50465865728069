import { grayColor } from 'assets/jss/material-dashboard-pro-react';

const InputMaskStyle = {
  defaultMask: {
    marginTop: '10px',
    border: 'none',
    borderBottom: `1px solid ${grayColor[4]}`,
    padding: '5px',
  },
};

export default InputMaskStyle;

import DateFilter from 'components/Grid/Filter/Date';
import StatusPicker from '../StatusPicker/StatusPicker';
import SelectFilter from 'components/Grid/Filter/Select';

import Moment from 'moment';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import CATERINGS_FLOW_STATUSES_LABELS from 'views/CateringsFlow/consts/cateringsFlowStatusesLabels';

const columnConfig = t => [
  {
    title: '$*cateringsFlow.generatedTasksTable.generatedBy',
    accessor: row =>
      `${row?.generatedBy?.firstName} ${row?.generatedBy?.lastName}`,
    name: 'generatedBy.firstName',
    filterable: true,
    width: 200,
  },
  {
    title: '$*cateringsFlow.generatedTasksTable.generatedAt',
    accessor: row => new Moment(row?.generatedAt).format('YYYY-MM-DD HH:mm:ss'),
    name: 'generatedAt',
    sortable: true,
    filterable: true,
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    width: 150,
  },
  {
    title: '$*cateringsFlow.generatedTasksTable.comment',
    name: 'note',
    accessor: 'note',
    filterable: true,
  },
  {
    title: '$*cateringsFlow.generatedTasksTable.kitchenStatus',
    name: 'taskListStatuses.cookRecipe',
    accessor: row => {
      return (
        <StatusPicker
          initialStatus={row?.taskListStatuses?.cookRecipe}
          isPickingEnabled={false}
        />
      );
    },
    filterable: true,
    sortable: true,
    width: 120,
    Filter: ({ onChange, filter }) => {
      const options = (Object.values(CATERINGS_FLOW_STATUSES) ?? []).map(
        status => {
          const translation = CATERINGS_FLOW_STATUSES_LABELS[status];

          return {
            '@id': status,
            name: t(translation?.translationKey, translation?.defaultValue),
          };
        }
      );

      return (
        <SelectFilter
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
  },
  {
    title: '$*cateringsFlow.generatedTasksTable.dishesPackhouse',
    name: 'taskListStatuses.packDish',
    accessor: row => {
      return (
        <StatusPicker
          initialStatus={row?.taskListStatuses?.packDish}
          isPickingEnabled={false}
        />
      );
    },
    filterable: true,
    sortable: true,
    width: 120,
    Filter: ({ onChange, filter }) => {
      const options = (Object.values(CATERINGS_FLOW_STATUSES) ?? []).map(
        status => {
          const translation = CATERINGS_FLOW_STATUSES_LABELS[status];

          return {
            '@id': status,
            name: t(translation?.translationKey, translation?.defaultValue),
          };
        }
      );

      return (
        <SelectFilter
          value={filter?.value}
          options={options}
          multiple={false}
          onChange={onChange}
        />
      );
    },
  },
  {
    title: '$*cateringsFlow.generatedTasksTable.bagsPackhouse',
    name: 'taskListStatuses.packDiet',
    accessor: row => {
      return (
        <StatusPicker
          initialStatus={row?.taskListStatuses?.packDiet}
          isPickingEnabled={false}
        />
      );
    },
    filterable: true,
    sortable: true,
    width: 120,
    Filter: ({ onChange, filter }) => {
      const options = (Object.values(CATERINGS_FLOW_STATUSES) ?? []).map(
        status => {
          const translation = CATERINGS_FLOW_STATUSES_LABELS[status];

          return {
            '@id': status,
            name: t(translation?.translationKey, translation?.defaultValue),
          };
        }
      );

      return (
        <SelectFilter
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
  },
];

export default columnConfig;

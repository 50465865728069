import React from 'react';
import PanelConfigurationContext from './PanelConfiguration.context';
import PanelConfigurationView from './PanelConfiguration.view';

const PanelConfiguration = props => {
  return (
    <PanelConfigurationContext>
      <PanelConfigurationView {...props} />
    </PanelConfigurationContext>
  );
};

export default PanelConfiguration;

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withToast } from 'material-ui-toast-redux';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import EndOfDietReminders from './EndOfDietReminders';

import { ROLE_EDIT_SMSING } from 'helpers/roles';
import { get, post, put, remove } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from 'helpers/helpers';
import TOAST_DURATIONS from 'helpers/toastDurations';

class Reminders extends React.Component {
  state = {
    smsing: {
      end_of_diet_reminder_config: [],
    },
    checkboxState: false,
    confirmModal: null,
  };

  componentDidMount() {
    Promise.all([
      get('sms-reminders?pagination=false'),
      get(`brands/${this.props.brand}/smsing`),
    ]).then(([reminders, smsing]) => {
      this.setState({
        smsing: {
          end_of_diet_reminder_config: reminders['hydra:member'].map(
            config => ({
              ...config,
              hasChanged: false,
            })
          ),
        },
        checkboxState: smsing.smsing.end_of_diet_reminder,
      });
    });
  }

  renderAlert = (onConfirm, onCancel) => {
    const { t, classes } = this.props;

    const confirmModal = (
      <SweetAlert
        warning
        title={t(
          'reminders.mailReminders.deleteMessage',
          'Czy na pewno chcesz usunąć to przypomnienie?'
        )}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('common.shared.yes')}
        cancelBtnText={t('common.shared.no')}
        showCancel
      />
    );

    this.setState({ confirmModal });
  };

  render() {
    const { t, openToast } = this.props;
    return (
      <Card>
        <CardBody>
          {this.state.confirmModal}
          {isGranted(ROLE_EDIT_SMSING) && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label={t('notify.notifyUsers')}
                control={
                  <Checkbox
                    checked={this.state.checkboxState}
                    onChange={e => {
                      const newCheckboxState = !this.state.checkboxState;
                      this.setState({ checkboxState: newCheckboxState });
                      put(`brands/${this.props.brand}/smsing`, {
                        smsing: { end_of_diet_reminder: newCheckboxState },
                      }).then(() => {
                        openToast({
                          messages: [
                            t('notify.changesSaved', 'Zmiany zostały zapisane'),
                          ],
                          type: 'success',
                          autoHideDuration: TOAST_DURATIONS.MD,
                        });
                      });
                    }}
                    checkedIcon={
                      <Check className={this.props.classes.checkedIcon} />
                    }
                    icon={
                      <Check className={this.props.classes.uncheckedIcon} />
                    }
                    classes={{
                      checked: this.props.classes.checked,
                      root: this.props.classes.checkRoot,
                    }}
                  />
                }
              />
              <Tooltip
                title={
                  <div style={{ fontSize: '12px' }}>
                    <h4>
                      {t(
                        '$*smsReminder.EndOfDietSMSReminder.tooltip',
                        '$$Ten checkbox odpowiada za uruchomienie całego modułu wysyłania przypomnień SMS.'
                      )}
                    </h4>
                  </div>
                }
                placement={'right'}
              >
                <Info style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          )}

          <EndOfDietReminders
            classes={this.props.classes}
            smsing={this.state.smsing}
            handleCheckbox={() => {
              this.setState(prevState => ({
                ...prevState,
                smsing: {
                  ...prevState.smsing,
                  end_of_diet_reminder: !prevState.smsing.end_of_diet_reminder,
                },
              }));
            }}
            addRow={() => {
              const newRow = {
                hours: 0,
                template: '',
                ignoreClientMarketingAgreement: false,
              };
              this.setState(prevState => {
                return {
                  ...prevState,
                  smsing: {
                    ...prevState.smsing,
                    end_of_diet_reminder_config:
                      prevState.smsing.end_of_diet_reminder_config.concat(
                        newRow
                      ),
                  },
                };
              });
            }}
            saveRow={index => {
              const row = this.state.smsing.end_of_diet_reminder_config[index];

              if (
                !row.acceptedClientMarketingAgreement &&
                !row.ignoreClientMarketingAgreement
              ) {
                this.props.openToast({
                  messages: [t('notify.cannotSavePleaseCheckOneOption')],
                  type: 'error',
                  autoHideDuration: TOAST_DURATIONS.SM,
                });
              } else {
                const action = row.id ? put : post;

                action(action === put ? row['@id'] : 'sms-reminders', {
                  ...row,
                  hours: parseInt(row.hours),
                  ignoreClientMarketingAgreement:
                    row.ignoreClientMarketingAgreement,
                }).then(
                  () => {
                    const { smsing } = this.state;

                    smsing.end_of_diet_reminder_config[
                      index
                    ].hasChanged = false;

                    this.setState({ smsing });

                    this.props.openToast({
                      messages: [t('notify.changesSaved')],
                      type: 'success',
                      autoHideDuration: TOAST_DURATIONS.SM,
                    });
                  },
                  error =>
                    this.props.openToast({
                      messages: [t('notify.cannotSave')],
                      type: 'error',
                      autoHideDuration: TOAST_DURATIONS.SM,
                    })
                );
              }
            }}
            removeRow={index => {
              this.renderAlert(
                () => {
                  const configAfterDelete =
                    this.state.smsing.end_of_diet_reminder_config.filter(
                      (el, ind) => ind !== index
                    );
                  const row =
                    this.state.smsing.end_of_diet_reminder_config[index];
                  row.id && remove(row['@id']);
                  this.setState(prevState => {
                    return {
                      ...prevState,
                      confirmModal: false,
                      smsing: {
                        ...prevState.smsing,
                        end_of_diet_reminder_config: configAfterDelete,
                      },
                    };
                  });
                },
                () => this.setState({ confirmModal: false })
              );
            }}
            handlePrefix={(event, index) => {
              const { smsing } = this.state;
              const { value } = event.target;
              const configValue =
                smsing.end_of_diet_reminder_config[index].hours;
              let newNumber;

              if (value === '0') {
                newNumber = 0;
              } else if (value === '-') {
                configValue > 0
                  ? (newNumber = -1 * configValue)
                  : (newNumber = configValue);
              } else {
                configValue < 0
                  ? (newNumber = -1 * configValue)
                  : (newNumber = configValue);
              }

              smsing.end_of_diet_reminder_config[index].hours = newNumber;

              this.setState({ smsing });
            }}
            handleRow={(event, index) => {
              const { name, value } = event.target;
              const { smsing } = this.state;

              smsing.end_of_diet_reminder_config[index][name] = value;
              smsing.end_of_diet_reminder_config[index].hasChanged = true;

              this.setState({ smsing });
            }}
            handleEditor={(code, name, index) => {
              const { smsing } = this.state;

              smsing.end_of_diet_reminder_config[index][name] = code;
              smsing.end_of_diet_reminder_config[index].hasChanged = true;

              this.setState({ smsing });
            }}
            handleToggle={(event, index) => {
              const { name } = event.target;
              const { smsing } = this.state;

              smsing.end_of_diet_reminder_config[index][name] =
                !smsing.end_of_diet_reminder_config[index][name];
              smsing.end_of_diet_reminder_config[index].hasChanged = true;

              this.setState({ smsing });
            }}
          />
        </CardBody>
      </Card>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withToast(withStyles(combinedStyles)(withTranslation()(Reminders))));

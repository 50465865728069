const DEFAULT_TASK = {
  dateTo: new Date(),
  dateFrom: new Date(),
  multiplier: 1,
  selectedBrands: [],
  selectedSubBrands: [],
  includeSubscriptions: false,
};

export default DEFAULT_TASK;

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    sortable: false,
    width: 50,
  },
  {
    title: 'columns.name',
    name: 'name',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    title: 'clients.street',
    name: 'street',
    accessor: 'street',
    filterable: false,
    sortable: false,
  },
  {
    title: 'addresses.city',
    name: 'city',
    accessor: 'city',
    filterable: false,
    sortable: false,
  },
  {
    title: 'clients.email',
    name: 'email',
    accessor: 'email',
    filterable: false,
    sortable: false,
  },
  {
    title: 'clients.phone',
    name: 'phoneNumber',
    accessor: 'phoneNumber',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

const emojiConfig = [
  {
    name: 'key',
    symbol: '🔑',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP ///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '48.2143% 80.3571%',
  },
  {
    name: 'bell',
    symbol: '🔔',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '48.2143% 85.7143%',
  },
  {
    name: 'keycap_star',
    symbol: '*️⃣',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '0% 1.78571%',
  },
  {
    name: 'hash',
    symbol: '#️⃣',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '0% 0%',
  },
  {
    name: 'arrow_right',
    symbol: '➡️',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '98.2143% 57.1429%',
  },
  {
    name: 'ok',
    symbol: '🆗',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '0% 48.2143%',
  },
  {
    name: 'telephone_receiver',
    symbol: '📞',
    image:
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    size: '5700% 5700%',
    position: '46.4286% 92.8571%',
  },
];

export default emojiConfig;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import List from '@material-ui/core/List';
import OpenInNew from '@material-ui/icons/OpenInNew';
import SettingsApplications from '@material-ui/icons/SettingsApplications';

import { isGranted } from 'helpers/helpers';

import SidebarRoute from 'components/Sidebar/components/SidebarRoute';
import SidebarSubRoute from 'components/Sidebar/components/SidebarSubRoute';

const SidebarWrapper = ({
  links = [],
  routes,
  classes,
  rtlActive,
  selectedBrand,
  isMenuExpanded,
}) => {
  const { t } = useTranslation();

  const [filteredRoutes, setFilteredRoutes] = useState([]);

  useEffect(() => {
    getFilteredRoutes();
  }, [routes]);

  const getFilteredRoutes = () => {
    let newRoutes = routes
      .filter(filterRoutes)
      .map(route => {
        let tmp = { ...route };
        if (route.hasOwnProperty('views')) {
          tmp.views = [...route.views];
          tmp.views = tmp.views.filter(filterRoutes);
        }

        return tmp;
      })
      .filter(route => {
        if (route.hasOwnProperty('views')) {
          return route.views.length > 0;
        }

        return true;
      });

    const shortLinks = links.filter(
      shortLink =>
        shortLink.brands.length === 0 ||
        shortLink.brands.includes('/brands/' + selectedBrand)
    );

    let adminNavLinks = shortLinks.map(shortLink => ({
      externalLink: true,
      path: shortLink.link,
      name: shortLink.label,
      mini: shortLink.symbol,
    }));

    adminNavLinks.push({
      path: '/my-links',
      name: 'common.mainMenu.navLinks.myNavLinks',
      icon: SettingsApplications,
      layout: '/admin',
    });

    newRoutes.splice(6, 0, {
      collapse: true,
      name: t('common.mainMenu.navLinks.tabName', 'Skróty'),
      icon: <OpenInNew />,
      views: adminNavLinks,
    });

    setFilteredRoutes(newRoutes);
  };

  const filterRoutes = route => {
    if (
      route.hasOwnProperty('granted') &&
      typeof route.granted === 'function'
    ) {
      return route.granted();
    }

    if (route.hasOwnProperty('role')) {
      return isGranted(route.role);
    }

    if (route.hasOwnProperty('roles')) {
      return !route.roles.some(multipleRole => !isGranted(multipleRole));
    }

    return true;
  };

  const sidebarWrapper = classNames(
    classes.sidebarWrapper,
    classes.blackBackgroundScrollbar,
    {
      [classes.sidebarMini]: !isMenuExpanded,
    }
  );

  return (
    <div className={sidebarWrapper}>
      <List className={classes.list}>
        {(filteredRoutes ?? []).map(({ collapse, ...routeProps }, key) => {
          return (
            <>
              {collapse ? (
                <SidebarRoute
                  key={key}
                  classes={classes}
                  rtlActive={rtlActive}
                  isMenuExpanded={isMenuExpanded}
                  path={routeProps?.path}
                  state={routeProps?.state}
                  {...routeProps}
                />
              ) : (
                <SidebarSubRoute
                  key={key}
                  classes={classes}
                  rtlActive={rtlActive}
                  isMenuExpanded={isMenuExpanded}
                  {...routeProps}
                />
              )}
            </>
          );
        })}
      </List>
    </div>
  );
};

export default SidebarWrapper;

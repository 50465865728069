import moment from 'moment';

import DateFilter from 'components/Grid/Filter/Date';

const columnConfig = t => [
  {
    Header: 'ID',
    accessor: 'id',
    width: 100,
  },
  {
    id: 'createdAt',
    Header: t('columns.date', 'Data'), //columns.date
    accessor: row => new moment(row.createdAt).format('YYYY-MM-DD') || '',
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    width: 100,
  },
  {
    Header: t('common.shared.content', 'Treść'), //common.shared.content
    accessor: 'content',
  },
  {
    Header: t('dataGrid.actions'),
    accessor: 'actions',
    width: 100,
  },
];

export default columnConfig;

import React from 'react';

// @material-ui/core components
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_SHOW_RATE,
  ROLE_SHOW_STATISTICS_SEX,
  ROLE_SHOW_STATISTICS_KNOW_ABOUT,
  ROLE_SHOW_STATISTICS_CORE_DAY_USERS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_BAGS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_BAGS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_INCOME,
  ROLE_SHOW_STATISTICS_CORE_DAY_MONEY_BOXES,
  ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_INCOME,
} from 'helpers/roles';

import BarChartBags from './DashboardCards/BarCharts/BarChartBags';
import BarChartBagsIncome from './DashboardCards/BarCharts/BarChartBagsIncome';
import BarChartIncome from './DashboardCards/BarCharts/BarChartIncome';
import Comments from './DashboardCards/Comments/Comments';
import PieChartClientSource from './DashboardCards/PieCharts/PieChartClientSource';
import PieChartSex from './DashboardCards/PieCharts/PieChartSex';
import DailyAddedPointsCard from './DashboardCards/QuickStats/DailyAddedPoints';
import DailyDeliveredBagsCard from './DashboardCards/QuickStats/DailyDeliveredBags';
import DailyIncomeCard from './DashboardCards/QuickStats/DailyIncome';
import DailyNewCustomersCard from './DashboardCards/QuickStats/DailyNewCustomers';

class Dashboard extends React.Component {
  render() {
    return (
      <div style={{ marginTop: '30px', position: 'relative' }}>
        <Button
          onClick={() => window.location.reload()}
          style={{ position: 'absolute', top: '-35px', right: '0' }}
        >
          {this.props.t('$*common.refresh', 'Odśwież')}
        </Button>
        <GridContainer>
          {isGranted(ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_INCOME) && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <DailyIncomeCard />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_BAGS) && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <DailyDeliveredBagsCard />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_CORE_DAY_MONEY_BOXES) && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <DailyAddedPointsCard />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_CORE_DAY_USERS) && (
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <DailyNewCustomersCard />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_BAGS) && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <BarChartBagsIncome />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_INCOME) && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <BarChartIncome />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_BAGS) && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <BarChartBags />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_RATE) && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Comments />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_SEX) && (
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <PieChartSex />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_STATISTICS_KNOW_ABOUT) && (
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <PieChartClientSource />
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(Dashboard));

export const orderStyles = {
  buttonContainer: {
    margin: '20px 20px 0',
    display: 'inline-block',
  },
  margin: {
    margin: '20px 20px 0',
  },
  centered: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  label: {
    display: 'block',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  justifyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputBtn: {
    marginTop: '12px',
    width: '100px',
  },
  box: {
    background: '#f3f3f3',
    padding: '10px 10px 5px 10px',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  boxCentered: {
    textAlign: 'center',
  },
  spacing: {
    margin: '10px 0',
  },
  textField: {
    marginLeft: '20px',
    marginRight: '20px',
    width: '100%',
  },
  moreBtn: {
    marginTop: '20px',
    marginLeft: 'auto',
    display: 'flex',
  },
  infoPill: {
    margin: '0 5px 5px 0',
    borderRadius: '35px',
    padding: '8px',
    backgroundColor: '#00b8d4',
    height: 'auto',
    display: 'inline-block',
    justifyContent: 'center',
    color: 'white',
    fontWeight: '500',
    boxShadow: '2px 4px 5px 0 rgba(34, 34, 34, 0.08)',
  },
  boxParagraph: {
    fontSize: '12px',
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
  usedDiscountHeader: {
    fontWeight: 900,
  },
  usedDiscountListItem: {
    fontSize: '12px',
  },
};

export const calendarStyles = {
  calendar: {
    width: '500px',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  month: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: '20px',
    maxWidth: '750px',
    margin: '20px auto',
  },
  day: {
    width: '60px',
    height: '60px',
    margin: '3px',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 6px 20px 0 rgba(34, 34, 34, 0.08)',
    borderRadius: '10px',
    padding: '10px',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
  },
  selectedDay: {
    width: '60px',
    height: '60px',
    margin: '3px',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #9c27b0',
    boxShadow: '0 6px 20px 0 rgba(34, 34, 34, 0.08)',
    borderRadius: '10px',
    padding: '10px',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
  },
  disabledDay: {
    width: '60px',
    height: '60px',
    margin: '3px',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    color: 'grey',
    boxShadow: '0 6px 20px 0 rgba(34, 34, 34, 0.08)',
    borderRadius: '10px',
    padding: '10px',
    cursor: 'not-allowed',
    userSelect: 'none',
    position: 'relative',
  },
  weekName: {
    width: '66px',
    padding: '6px 10px',
    marginBottom: '5px',
    textAlign: 'center',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  todayMark: {
    height: '0',
    width: '0',
    position: 'absolute',
    top: '0',
    left: '0',
    borderLeft: '20px solid #4caf50',
    borderRight: '20px solid transparent',
    borderBottom: '20px solid transparent',
  },
};

export const singleDayStyles = {
  addonsTitle: {
    margin: '5px 0 0 48px',
    color: 'rgba(0,0,0,0.54)',
  },
  addonElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    color: 'rgba(0,0,0,0.48)',
    maxWidth: '300px',
  },
};

export const asyncSelectStyles = {
  control: styles => {
    return {
      ...styles,
      border: 'none',
      fontWeight: '400',
      borderRadius: '0',
      borderBottom: '1px solid #d2d2d2',
      backgroundColor: 'white',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none',
      },
    };
  },
  dropdownIndicator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  noOptionsMessage: styles => ({
    ...styles,
    textAlign: 'left',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? 'white' : 'black',
    backgroundColor: isFocused ? '#9c27b0' : 'white',
    ':active': {
      backgroundColor: '#9c27b0',
    },
  }),
};

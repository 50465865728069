import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import { get, post, put } from '../../helpers/apiHelpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import LettersCounter from './LettersCounter';
import LabelWithTooltip from './LabelWithTooltip';
import TOAST_DURATIONS from 'helpers/toastDurations';

class Form extends Component {
  state = {
    label: '',
    slug: '',
    title: '',
    metaDescription: '',
    content: '',
    inFooter: false,
    position: 0,
  };

  pageId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount() {
    this.pageId &&
      get(`pages/${this.pageId}`).then(response => this.setState(response));
  }

  handleContent = event => {
    const content = event.target.value;
    this.setState({ content });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.slug && this.state.title && this.state.content;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('pages.fillAllFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const { position, ...restState } = this.state;
    const data = {
      ...restState,
      ...(restState.inFooter ? { position: parseInt(position) } : {}),
    };

    const action = this.isEdit
      ? put(`/pages/${this.pageId}`, data)
      : post('/pages', data);

    action.then(
      res => {
        return this.props.history.push('/admin/pages-creator');
      },
      error => {
        if (error.response.data.violations) {
          return this.props.openToast({
            messages: error.response.data.violations.map(violation => {
              return `${violation.propertyPath} - ${violation.message}`;
            }),
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        } else {
          return this.props.openToast({
            messages: [this.props.t('pages.cannotSave')],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      }
    );
  };

  render() {
    const { classes, t } = this.props;
    return (
      <AdminTable title={t('pages.addNew')}>
        <FormTextInput
          label={
            <LabelWithTooltip
              label={t('pages.pageUrl') + '*'}
              tooltip={t('pages.pageUrl.tooltipWarning')}
            />
          }
          classes={classes}
          name="slug"
          value={this.state.slug}
          handleChange={this.handleInputChange}
          inputSize={12}
          maxLength={64}
          helpText={t('pages.pageUrl.example', 'np. "about-us"')}
        />
        <FormTextInput
          label={
            <LabelWithTooltip
              label={t('pages.title') + '*'}
              tooltip={t('pages.title.tooltip')}
            />
          }
          classes={classes}
          name="title"
          value={this.state.title}
          handleChange={this.handleInputChange}
          inputSize={12}
          maxLength={60}
        />
        <FormTextInput
          label={
            <LettersCounter
              label={t('pages.metaDesc')}
              wordsCount={this.state.metaDescription.length}
            />
          }
          classes={classes}
          name="metaDescription"
          value={this.state.metaDescription}
          handleChange={this.handleInputChange}
          inputSize={12}
          maxLength={300}
          rows={2}
          multiline
        />
        <p
          style={{
            margin: '20px 0 10px',
            color: 'rgba(0,0,0,.46)',
            fontWeight: 400,
          }}
        >
          {t('pages.pageContent')} *
        </p>
        <SimpleWysiwyg
          handleChange={this.handleContent}
          value={this.state.content}
        />
        <FormControlButtons
          classes={classes}
          discardText={this.props.t('common.shared.cancel')}
          submitText={this.props.t('common.shared.save')}
          cancelPath="/admin/pages-creator"
          handleSubmit={this.handleSubmit}
        />
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Form);

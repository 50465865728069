import React from 'react';
import historyColumnConfig from './historyColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';

class History extends React.Component {
  render() {
    return (
      <div>
        <DataGrid
          refreshOnFetch
          actions={false}
          paginationTop={true}
          paginationBottom={false}
          export={false}
          minRows={18}
          url="/entity-logs"
          role={'ORDER'}
          columns={historyColumnConfig}
          manipulateQuery={(requestData, query) => {
            query['associatedClient.id[]'] = this.props.userId;
            query['associatedClient.id[_operator]'] = 'eq';

            return query;
          }}
        />
      </div>
    );
  }
}

export default History;

import { get, post, put, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchBrandsList = () => {
  return dispatch => {
    return get('/brands').then(response => {
      dispatch({
        type: types.FETCH_BRANDSLIST,
        payload: {
          brandsList: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchBrands = () => {
  return dispatch => {
    return get('/brands').then(response => {
      dispatch({
        type: types.FETCH_BRANDS,
        payload: {
          brands: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchBrand = id => {
  return dispatch => {
    return get(`/brands/${id}`).then(response => {
      dispatch({
        type: types.FETCH_BRAND,
        payload: response,
      });
      return response;
    });
  };
};

export const createBrand = data => {
  return dispatch => {
    return post('/brands', data).then(response => {
      dispatch({
        type: types.FETCH_BRAND,
        payload: response,
      });

      return response;
    });
  };
};

export const updateBrand = (selectedBrand, data) => {
  return dispatch => {
    return put(`/brands/${selectedBrand}`, data).then(response => {
      dispatch({
        type: types.FETCH_BRAND,
        payload: response,
      });

      return response;
    });
  };
};

export const fetchBrandConfigModules =
  ({ brandId, qs }) =>
  dispatch => {
    return get(`/brands/${brandId}/modules`, { ...qs }).then(response => {
      dispatch({
        type: `${types.FETCH_BRAND_CONFIG_MODULE}_SUCCESS`,
      });

      return response;
    });
  };

export const fetchBrandConfigModulesPack = (brandId, moduleNames) => {
  const qs = moduleNames.map(m => `module[]=${m}`).join('&');
  return dispatch => {
    return get(`/brands/${brandId}/modules?${qs}`).then(response => {
      dispatch({
        type: `${types.FETCH_BRAND_CONFIG_MODULE}S_SUCCESS`,
        payload: response.configuration ?? {},
      });

      return response;
    });
  };
};

export const updateBrandConfigModule = (brandId, moduleName, configuration) => {
  return put(`/brands/${brandId}/module`, {
    module: moduleName,
    configuration,
  });
};

export const updateBrandConfigModulesPack = (brandId, modules) => {
  return dispatch => {
    return put(`/brands/${brandId}/modules`, {
      configuration: modules,
    });
  };
};

export const removeBrand = id => {
  return dispatch =>
    remove(`/brands/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_BRAND,
        payload: {
          brandId: id,
        },
      });
    });
};

export const fetchPaymentTypes = selectedBrandId => {
  return dispatch => {
    return get(
      `brands/${selectedBrandId}/modules?module[]=ItemPaymentTypes`
    ).then(response => {
      dispatch({
        type: `${types.FETCH_PAYMET_TYPES}_SUCCESS`,
        payload: response.configuration.ItemPaymentTypes,
      });
    });
  };
};

export const selectDefaultPaymentType = (order, paymentType) => {
  return dispatch =>
    dispatch({
      type: 'SET_DEFAULT_PAYMENT_TYPE',
      payload: { order, paymentType },
    });
};

export const setEnabledPaymentTypes = (order, paymentTypes) => {
  return dispatch =>
    dispatch({
      type: 'SET_ENABLED_PAYMENT_TYPES',
      payload: { order, paymentTypes },
    });
};

export const fetchCountryInformations = () => {
  return dispatch => {
    return get(`/country-informations`).then(response => {
      dispatch({
        type: `${types.FETCH_COUNTRY_INFORMATIONS}_SUCCESS`,
        payload: response,
      });
    });
  };
};

export const setMultinational = multinational => dispatch => {
  dispatch({
    type: 'SET_MULTINATIONAL',
    payload: multinational,
  });
};

import React from 'react';

import RegularButton from 'components/CustomButtons/Button.jsx';
import { put } from 'helpers/apiHelpers';
import { TableCell } from '@material-ui/core';
import TOAST_DURATIONS from 'helpers/toastDurations';

const TableButtonSaveSection = ({
  mailingOption,
  t,
  openToast,
  state,
  setState,
}) => {
  return (
    <TableCell>
      <RegularButton
        disabled={!mailingOption.hasChanged}
        color={'success'}
        onClick={() => {
          put(mailingOption['@id'], mailingOption)
            .then(
              res => {
                const newMailing = [...state.mailing];

                let optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.hasChanged = false;
                setState({
                  mailing: newMailing,
                });
                openToast({
                  messages: [
                    t('notify.changesSaved', 'Zmiany zostały zapisane'),
                  ],
                  type: 'success',
                  autoHideDuration: TOAST_DURATIONS.SM,
                });
              },
              error =>
                openToast({
                  messages: [
                    t('notify.cannotSave', 'Nie udało się zapisać zmian'),
                  ],
                  type: 'error',
                  autoHideDuration: TOAST_DURATIONS.SM,
                })
            )
            .finally(() => window.location.reload());
        }}
      >
        {t('common.shared.save', 'Zapisz')}
      </RegularButton>
    </TableCell>
  );
};

export default TableButtonSaveSection;

import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import routes from 'routes';
import error from '../assets/custom/errorPage.jpeg';
import login from '../assets/custom/loginBackgroundAlt.jpeg';
import loginFormImg from '../assets/img/login-form.png';
import register from '../assets/img/register.jpeg';
import pagesStyle from '../assets/jss/material-dashboard-pro-react/layouts/authStyle';
import { store } from '../store';
import S from '../views/Pages/AuthStyled';

class Pages extends React.Component {
  componentDidMount() {
    store.dispatch({
      type: 'LOGOUT',
    });
    document.body.style.overflow = 'unset';
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBgImage = () => {
    if (window.location.pathname.indexOf('/auth/register') !== -1) {
      return register;
    } else if (window.location.pathname.indexOf('/auth/login') !== -1) {
      return login;
    } else if (window.location.pathname.indexOf('/auth/error') !== -1) {
      return error;
    } else {
      return login;
    }
  };
  getActiveRoute = routes => {
    let activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i]?.nameFallback ?? routes[i]?.name;
        }
      }
    }
    return activeRoute;
  };
  setDocTitle = () => {
    document.title = this.getActiveRoute(routes);
  };
  render() {
    this.setDocTitle();
    const hasToken = store.getState().Auth.token;
    const hasRoles = store.getState().Roles.roles.length > 0;

    if (hasToken && hasRoles) {
      return <Redirect to="/admin/dashboard" />;
    }

    return (
      <S.Background img={loginFormImg}>
        <S.Wrapper>
          <S.LeftColumn>
            <img src={loginFormImg} alt="" />
          </S.LeftColumn>

          <S.RightColumn>
            <Switch>{this.getRoutes(routes)}</Switch>
          </S.RightColumn>
        </S.Wrapper>
      </S.Background>
    );
  }
}

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(withStyles(pagesStyle)(Pages));

import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { fetchUser } from 'actions/Users';
import { fetchMealTypes } from 'actions/MealTypes';
import { connect } from 'react-redux';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';

class AntiPreferences extends Component {
  state = {
    antiPreferences: [],
    mealTypes: [],
  };
  userId = this.props.userId ? this.props.userId : this.props.match.params.id;

  componentDidMount() {
    this.props.fetchMealTypes().then(mealTypes => {
      this.setState({
        mealTypes: mealTypes.sort((a, b) => (a.position > b.position ? 1 : -1)),
      });
    });
    if (this.userId) {
      this.props.fetchUser(this.userId).then(user => {
        this.setState({
          antiPreferences: user.antiPreferences,
        });
      });
    }
  }

  getUniqueAntiPreferencesCount() {
    let uniqueAntiPreferences = [];

    this.state.antiPreferences.forEach(ap => {
      if (
        uniqueAntiPreferences.filter(
          uap =>
            uap.ingredientCategory === ap.ingredientCategory &&
            uap.clientTag === ap.clientTag &&
            uap.allergen === ap.allergen
        ).length === 0
      ) {
        uniqueAntiPreferences.push(ap);
      }
    });

    return uniqueAntiPreferences.length;
  }

  getAntiPreferenceNames(mealType, antiPreferenceType) {
    return this.state.antiPreferences
      .filter(
        antiPreference =>
          antiPreference[antiPreferenceType] &&
          antiPreference.mealType === mealType
      )
      .map(ap => ap[antiPreferenceType].value)
      .join(', ');
  }

  render() {
    const { t, classes } = this.props;

    return (
      <CardWrapper title={t('clients.antiPreferences.sum')}>
        <CardHeader style={{ background: 'red' }}>
          <h3 className={classes.cardIconTitle}>
            {this.getUniqueAntiPreferencesCount()}
          </h3>
        </CardHeader>
        <CardBody>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('clients.antiPreferences.mealTypes')}</TableCell>
                <TableCell>{t('clients.antiPreferences.allergens')}</TableCell>
                <TableCell>
                  {t('clients.antiPreferences.ingredientCategories')}
                </TableCell>
                <TableCell>{t('clients.antiPreferences.clientTags')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>{t('clients.antiPreferences.all')}</b>
                </TableCell>
                <TableCell>
                  <b>{this.getAntiPreferenceNames(null, 'allergen')}</b>
                </TableCell>
                <TableCell>
                  <b>
                    {this.getAntiPreferenceNames(null, 'ingredientCategory')}
                  </b>
                </TableCell>
                <TableCell>
                  <b>{this.getAntiPreferenceNames(null, 'clientTag')}</b>
                </TableCell>
              </TableRow>
              {this.state.mealTypes.map(mealType => (
                <TableRow>
                  <TableCell>{mealType.name}</TableCell>
                  <TableCell>
                    <b>{this.getAntiPreferenceNames(null, 'allergen')}</b>{' '}
                    {this.getAntiPreferenceNames(mealType['@id'], 'allergen')}
                  </TableCell>
                  <TableCell>
                    <b>
                      {this.getAntiPreferenceNames(null, 'ingredientCategory')}
                    </b>{' '}
                    {this.getAntiPreferenceNames(
                      mealType['@id'],
                      'ingredientCategory'
                    )}
                  </TableCell>
                  <TableCell>
                    <b>{this.getAntiPreferenceNames(null, 'clientTag')}</b>{' '}
                    {this.getAntiPreferenceNames(mealType['@id'], 'clientTag')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </CardWrapper>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Users.user,
});

const mapDispatchToProps = {
  fetchUser,
  fetchMealTypes,
};

const enhance = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AntiPreferences);

import api from './gastro';

export const post = (endpoint, payload, config = {}) =>
  api
    .post(endpoint, JSON.stringify(payload), config)
    .then(res => res && res.data);
export const put = (endpoint, payload, config = {}) =>
  api
    .put(endpoint, JSON.stringify(payload), config)
    .then(res => res && res.data);
export const patch = (endpoint, payload, config = {}) =>
  api
    .patch(endpoint, JSON.stringify(payload), config)
    .then(res => res && res.data);
export const get = (endpoint, params = {}, config = {}) =>
  api.get(endpoint, { params, ...config }).then(res => res && res.data);
export const remove = (endpoint, config = {}) =>
  api.delete(endpoint, config).then(res => res.data);

import { goldColor } from 'assets/jss/material-dashboard-pro-react';

const HomeIcon = ({ color = goldColor, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#HomeIcon)">
      <path
        fill={color}
        d="M12.775.267a1.128 1.128 0 0 1 1.458 0l12.375 10.5a1.123 1.123 0 1 1-1.453 1.716l-1.153-.975v8.742a3.749 3.749 0 0 1-3.75 3.75h-13.5a3.749 3.749 0 0 1-3.75-3.75v-8.742l-1.149.975a1.123 1.123 0 0 1-1.584-.131A1.127 1.127 0 0 1 .4 10.767L12.775.267Zm.727 2.335L5.252 9.6v10.65c0 .83.67 1.5 1.5 1.5h2.25v-7.125c0-1.036.839-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875v7.125h2.25c.83 0 1.5-.67 1.5-1.5V9.6l-8.25-6.998Zm-2.25 19.148h4.5V15h-4.5v6.75Z"
      />
    </g>
    <defs>
      <clipPath id="HomeIcon">
        <path fill="#fff" d="M0 0h27v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default HomeIcon;

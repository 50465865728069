import types from 'helpers/constants';

const initialState = {
  selectedLanguage: 'pl',
};

function Translatable(state = initialState, action) {
  switch (action.type) {
    case types.TRANSLATABLE_SELECT_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload.selectedLanguage,
      };
    default:
      return state;
  }
}

export default Translatable;

import React from 'react';
import PropTypes from 'prop-types';

import Preview from './FontPreview.styled';

const FontPreview = ({ children, fontFamily, fontLibrary = 'google' }) => {
  return <Preview fontFamily={fontFamily}>{children}</Preview>;
};

FontPreview.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default FontPreview;

const defaultRecord = () => ({
  name: '',
  workName: '',
  shoppingName: '',
  shortName: '',
  weight: '',
  calories: '',
  fat: '',
  carbohydrates: '',
  monosaccharide: '',
  proteins: '',
  dietaryFiber: '',
  salt: '',
  nkt: '',
  note: null,
  homeIngredient: false,
  allert: false,
  wot: 100,
  wom: 100,
  price: '',
  pricePerUnit: '',
  pricePerKg: '',
  inventoryAmount: '',
  hermesCardIndex: '',
  glycemicIndex: '',
  allergens: [],
  unit: null,
  categories: [],
  wholesaler: null,
  products: [],
  minerals: {
    sodium: null,
    potassium: null,
    calcium: null,
    phosphorus: null,
    magnesium: null,
    iron: null,
    zinc: null,
    copper: null,
    iodine: null,
    manganese: null,
  },
  vitamins: {
    carotene: null,
    vitaminA: null,
    vitaminB1: null,
    vitaminB2: null,
    vitaminB3: null,
    vitaminB6: null,
    vitaminB9: null,
    vitaminB12: null,
    vitaminC: null,
    vitaminD: null,
    vitaminE: null,
  },
  fattyAcids: {
    cholesterol: null,
    dha: null,
    dpa: null,
    epa: null,
    n3: null,
    n3ToN6Rate: null,
    n6: null,
  },
});

const defaultState = {
  __originalRecord: defaultRecord(),
  ...defaultRecord(),
  historyHidden: true,
  confirmNestedUpdatesModal: false,
};

export default defaultState;

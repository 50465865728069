import React from 'react';

import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import AdminTable from 'layouts/AdminTable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const AccessDenied = () => {
  return (
    <AdminTable alignItems={'center'}>
      <GridContainer>
        <GridItem xs={12} align="center">
          <SnackbarContent
            color="danger"
            message="Brak uprawnień do wyświetlenia tej strony"
          />
        </GridItem>
      </GridContainer>
    </AdminTable>
  );
};

export default AccessDenied;

import React from 'react';
import { withTranslation } from 'react-i18next';

const TitleLettersCounter = ({ t, wordsCount }) => {
  let color;
  switch (true) {
    case wordsCount <= 65 && wordsCount > 35:
      color = 'green';
      break;
    case wordsCount <= 70 && wordsCount > 65:
      color = 'orange';
      break;
    case wordsCount > 70:
      color = 'red';
      break;
    default:
      break;
  }

  return (
    <span>
      {t('seo.metaTitle')} |
      <span style={{ color }}>
        {' '}
        {t('brandCfg.charCount')}: {wordsCount}
      </span>
    </span>
  );
};

export default withTranslation()(TitleLettersCounter);

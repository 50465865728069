import types from 'helpers/constants';

const initialState = {
  brandsList: [],
  brand: {},
  loading: true,
  selectedRegionForValidations: '',
  paymentTypes: null,
  modules: {},
};

function Brands(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_BRANDS:
      return {
        ...state,
        brands: action.payload.brands,
        loading: false,
      };
    case types.FETCH_BRANDSLIST:
      return {
        ...state,
        brandsList: action.payload.brandsList,
        loading: false,
      };
    case types.FETCH_BRAND:
      return {
        ...state,
        loading: false,
        brand: action.payload,
      };
    case types.REMOVE_BRAND:
      return {
        ...state,
        brands: state.brands.filter(
          brand => brand.id !== action.payload.brandId
        ),
      };
    case `${types.FETCH_PAYMET_TYPES}_SUCCESS`:
      return {
        ...state,
        paymentTypes: action.payload,
      };
    case 'SET_DEFAULT_PAYMENT_TYPE':
      return {
        ...state,
        paymentTypes: {
          ...state.paymentTypes,
          [action.payload.order]: {
            ...state.paymentTypes[action.payload.order],
            defaultPaymentType: action.payload.paymentType,
          },
        },
      };
    case 'SET_ENABLED_PAYMENT_TYPES':
      return {
        ...state,
        paymentTypes: {
          ...state.paymentTypes,
          [action.payload.order]: {
            ...state.paymentTypes[action.payload.order],
            enabledPaymentTypes: action.payload.paymentTypes,
            defaultPaymentType:
              action.payload.order === 'dietSubscription'
                ? action.payload.paymentTypes.length
                  ? action.payload.paymentTypes[0]
                  : null
                : state.paymentTypes[action.payload.order].defaultPaymentType,
          },
        },
      };
    case `${types.FETCH_COUNTRY_INFORMATIONS}_SUCCESS`:
      return {
        ...state,
        countryInformations: action.payload['hydra:member'],
      };
    case types.SET_REGION_FOR_VALIDATIONS:
      return {
        ...state,
        selectedRegionForValidations: action.payload,
      };
    case `${types.FETCH_BRAND_CONFIG_MODULE}S_SUCCESS`:
      return { ...state, modules: action.payload };
    case 'SET_MULTINATIONAL':
      return {
        ...state,
        brand: { ...state.brand, multinational: action.payload },
      };
    default:
      return state;
  }
}

export default Brands;

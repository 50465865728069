import { useState, useEffect } from 'react';

import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';

import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

import withStyles from '@material-ui/core/styles/withStyles';

import UserBar from 'components/Sidebar/components/UserBar.jsx';
import BrandBar from 'components/Sidebar/components/BrandBar.jsx';
import SidebarWrapper from 'components/Sidebar/components/SidebarWrapper.jsx';
import SidebarMobileLinks from 'components/Sidebar/components/SidebarMobileLinks';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import { ChangeSidebarStatus } from 'actions/SidebarStatus';
import { compose } from 'recompose';

const Sidebar = ({
  open,
  routes,
  classes,
  logoText,
  rtlActive,
  minimizeSidebar,
  miniActive: miniModeActive,
  handleDrawerToggle,
  ChangeSidebarStatus,
}) => {
  const { user, selectedBrand, links } =
    useSelector(({ Auth: { user, selectedBrand, links } }) => ({
      user,
      selectedBrand,
      links,
    })) ?? {};

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    handleMenuModeChange();
    ChangeSidebarStatus({ isMenuExpanded, miniModeActive });
  }, [miniModeActive]);

  useEffect(() => {
    ChangeSidebarStatus({ isMenuExpanded, miniModeActive });
  }, [isMenuExpanded]);

  const handleMenuModeChange = () => {
    if (miniModeActive) {
      return setIsMenuExpanded(false);
    }

    return setIsMenuExpanded(true);
  };

  const paperClasses = classNames(
    classes.drawerPaper,
    classes.blackBackground,
    {
      [classes.drawerPaperMini]: !isMenuExpanded,
      [classes.drawerPaperRTL]: rtlActive,
    }
  );

  return (
    <div>
      {/* MOBILE VERSION */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? 'left' : 'right'}
          open={open}
          classes={{
            paper: paperClasses,
          }}
          onClose={handleDrawerToggle}
        >
          <BrandBar
            classes={classes}
            logoText={logoText}
            rtlActive={rtlActive}
            miniModeActive={false}
            isMenuExpanded={true}
            showMinimizeButton={false}
          />
          <UserBar
            user={user}
            classes={classes}
            rtlActive={rtlActive}
            isMenuExpanded={true}
          />
          <Hidden smUp implementation="css">
            <SidebarMobileLinks rtlActive={rtlActive} />
          </Hidden>
          <SidebarWrapper
            links={links}
            routes={routes}
            classes={classes}
            rtlActive={rtlActive}
            selectedBrand={selectedBrand}
            miniModeActive={false}
            isMenuExpanded={true}
            showHeaderLinks={true}
          />
        </Drawer>
      </Hidden>

      {/* TABLET/DESKTOP VERSION */}
      <Hidden smDown implementation="css">
        <Drawer
          anchor={rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: paperClasses,
          }}
          onMouseOver={() => {
            if (miniModeActive && !isMenuExpanded) {
              setIsMenuExpanded(true);
              ChangeSidebarStatus({ isMenuExpanded: true, miniModeActive });
            }
          }}
          onMouseLeave={() => {
            if (miniModeActive && isMenuExpanded) {
              setIsMenuExpanded(false);
              ChangeSidebarStatus({ isMenuExpanded: false, miniModeActive });
            }
          }}
        >
          <BrandBar
            classes={classes}
            logoText={logoText}
            rtlActive={rtlActive}
            isMenuExpanded={isMenuExpanded}
            miniModeActive={miniModeActive}
            minimizeSidebar={minimizeSidebar}
            setIsMenuExpanded={setIsMenuExpanded}
            showMinimizeButton={true}
          />
          <UserBar
            user={user}
            classes={classes}
            rtlActive={rtlActive}
            isMenuExpanded={isMenuExpanded}
          />
          <SidebarWrapper
            links={links}
            routes={routes}
            classes={classes}
            rtlActive={rtlActive}
            selectedBrand={selectedBrand}
            showHeaderLinks={true}
            miniModeActive={miniModeActive}
            isMenuExpanded={isMenuExpanded}
            setIsMenuExpanded={setIsMenuExpanded}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = {
  ChangeSidebarStatus,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sidebarStyle)
);

export default enhance(Sidebar);

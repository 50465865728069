import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import LogView from 'components/History/LogView';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import ConfirmNestedUpdatesModal from '../auixiliumFunctions/ConfirmNestedUpdatesModal';
import { columnsMinerals } from '../List/columnConfigs/columnConfigMinerals';
import { columnVitamins } from '../List/columnConfigs/columnConfigVitamins';
import { columnConfigFattyAcids } from '../List/columnConfigs/columnConfigFattyAcids';
import FormIngredientHeaderSection from './FormIngredientsViewSection/FormIngredientHeaderSection';
import FormIngredientBasicInformationSection from './FormIngredientsViewSection/FormIngredientBasicInformationSection';
import FormIngredientsMacronutients from './FormIngredientsViewSection/FormIngredientsMacronutients';
import FormIngredientsMineralsSection from './FormIngredientsViewSection/FormIngredientsMineralsSection';
import FormIngredientsVitamins from './FormIngredientsViewSection/FormIngredientsVitamins';
import FormIngredientFattyAcids from './FormIngredientsViewSection/FormIngredientFattyAcids';
import FormIngredientDefaultTreatment from './FormIngredientsViewSection/FormIngredientDefaultTreatment';
import FormIngredientsShopingInformation from './FormIngredientsViewSection/FormIngredientsShopingInformation';
import FormIngredientsProduct from './FormIngredientsViewSection/FormIngredientsProduct';
import FormIngredientsNotice from './FormIngredientsViewSection/FormIngredientsNotice';
import FormIngredientsUseInRecipe from './FormIngredientsViewSection/FormIngredientsUseInRecipe';
import FormIngredientRecordFieldHistorySection from './FormIngredientsViewSection/FormIngredientRecordFieldHistorySection';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';

const FormIngredientsView = ({ props }) => {
  const {
    match,
    t,
    currency,
    classes,
    state,
    handleToggle,
    closeConfirmNestedUpdatesModal,
    caloriesErrorDetector,
    setDefaultLanguage,
    submitForm,
    submitData,
    handleChange,
    handleNutrients,
    handleWeightBeforeProcessingChange,
    handleWeightChange,
    basicOptions,
    options,
    processingOptions,
    salesOptions,
    additionalSalesOptions,
    salesSelectsOptions,
    selectsOptions,
  } = props;
  return (
    <form
      id="newIngredientForm"
      onSubmit={submitForm}
      style={{
        opacity: !state.isLoading ? 0 : 1,
        transition: '0.3s all',
      }}
    >
      <GridContainer>
        <GridItem xs={12}>
          <FormIngredientHeaderSection state={state} t={t} match={match} />

          <FormIngredientBasicInformationSection
            classes={classes}
            basicOptions={basicOptions}
            handleChange={handleChange}
            setDefaultLanguage={setDefaultLanguage}
            selectsOptions={selectsOptions}
            handleToggle={handleToggle}
            handleWeightBeforeProcessingChange={
              handleWeightBeforeProcessingChange
            }
            t={t}
            state={state}
          />
        </GridItem>
        <FormIngredientsMacronutients
          classes={classes}
          handleChange={handleChange}
          caloriesErrorDetector={caloriesErrorDetector}
          options={options}
          t={t}
        />
        <FormIngredientsMineralsSection
          t={t}
          columnsMinerals={columnsMinerals}
          classes={classes}
          handleNutrients={handleNutrients}
          state={state}
        />
        <FormIngredientsVitamins
          columnVitamins={columnVitamins}
          classes={classes}
          state={state}
          t={t}
          handleNutrients={handleNutrients}
        />
        <FormIngredientFattyAcids
          t={t}
          columnConfigFattyAcids={columnConfigFattyAcids}
          handleNutrients={handleNutrients}
          state={state}
          classes={classes}
        />
        <FormIngredientDefaultTreatment
          t={t}
          processingOptions={processingOptions}
          classes={classes}
          handleChange={handleChange}
        />
        <FormIngredientsShopingInformation
          t={t}
          salesOptions={salesOptions}
          classes={classes}
          state={state}
          handleWeightChange={handleWeightChange}
          salesSelectsOptions={salesSelectsOptions}
          handleChange={handleChange}
          currency={currency}
          additionalSalesOptions={additionalSalesOptions}
        />
        <FormIngredientsProduct t={t} classes={classes} state={state} />

        <FormIngredientsNotice
          t={t}
          classes={classes}
          handleChange={handleChange}
          state={state}
        />
      </GridContainer>

      {match.params.id && (
        <>
          <FormIngredientsUseInRecipe match={match} t={t} />

          <FormIngredientRecordFieldHistorySection
            currency={currency}
            match={match}
            t={t}
          />
        </>
      )}

      {!state.edit && (
        <FormControlStickyButton
          t={t}
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath={'/admin/ingredients'}
          handleSubmit={submitForm}
          history={false}
        />
      )}

      {state.edit && (
        <LogView
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath={'/admin/ingredients'}
          handleSubmit={submitForm}
          iri={`/ingredients/${match.params.id}`}
        />
      )}
      <Dialog
        maxWidth={'xl'}
        open={state.confirmNestedUpdatesModal}
        onClose={() => {}}
      >
        <DialogContent>
          <h1
            style={{ paddingRight: 20, textAlign: 'center', marginBottom: 0 }}
          >
            {t(
              'form.ingredients.womModal.h1',
              '$$Zdecyduj w jakich przepisach i/lub daniach chcesz zmienić WOM?'
            )}
          </h1>
          <h3 style={{ paddingRight: 20, textAlign: 'center', marginTop: 0 }}>
            <small>
              {t(
                'form.ingredients.womModal.h3',
                '$$Widzisz to okno ponieważ zmieniłeś wartość WOM'
              )}{' '}
              {`"<b>{${state.__originalRecord.wom}}%</b>" => "
                <b>{${state.wom}}%</b>"`}
            </small>
          </h3>
          {state.confirmNestedUpdatesModal && (
            <ConfirmNestedUpdatesModal
              classes={classes}
              ingredientId={state.edit ? match?.params?.id : 0}
              ingredientState={state}
              onSave={nestedUpdates => {
                submitData(nestedUpdates);
                closeConfirmNestedUpdatesModal();
              }}
              onCancel={closeConfirmNestedUpdatesModal}
            />
          )}
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default FormIngredientsView;

import axios from 'axios';

export const getOtherBrand = ({ url, token, brand }) => {
  return axios({
    method: 'GET',
    url: url,
    headers: {
      authorization: `Bearer ${token}`,
      'x-g-brand': brand,
    },
  });
};

export const postOtherBrand = ({ url, username, password }) => {
  return axios({
    method: 'POST',
    url,
    data: {
      username,
      password,
    },
  });
};

import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import withStyles from '@material-ui/core/styles/withStyles';
import RateReview from '@material-ui/icons/RateReview';

import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import chartsStyle from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import { get } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({ classes, selectedBrand }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    labels: ['', '', '', '', '', ''],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(64, 50, 51, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(64, 50, 51, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const options = {
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: context => {
            return `${context.label}`;
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => (sum += data));
          let percentage = ((value * 100) / sum).toFixed(0) + '%';
          return percentage;
        },
        color: '#000',
      },
    },
  };

  useEffect(() => {
    getTotalClients();
  }, []);

  const getTotalClients = () => {
    get('/admin/statistics/know-about-us', {
      brandId: [selectedBrand],
    }).then(res => getData(res));
  };

  const getData = array => {
    if (!isEmpty(array)) {
      const newChartData = {
        labels: array.map(({ knowAboutUs }) =>
          knowAboutUs ? knowAboutUs : t('dashboard.other')
        ),
        datasets: [
          {
            data: array.map(({ amount }) => amount),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(64, 50, 51, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(64, 50, 51, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

      setChartData(newChartData);
    }
  };

  return (
    <Card>
      <CardHeader color="danger" stats icon>
        <CardIcon color="danger">
          <RateReview />
        </CardIcon>
        <p style={{ paddingTop: '10px' }} className={classes.cardCategory}>
          <a href={'/admin/clients'}>{t('dashboard.goToClientList')}</a>
        </p>
        <h3 className={classes.cardTitle}>{t('dashboard.clientsWhere')}</h3>
      </CardHeader>
      <CardBody>
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <Pie options={options} data={chartData} />
        </div>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(dashboardStyle, chartsStyle);

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(PieChart));

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ElementSumCell from './ElementSumCell';

class ElementSumRow extends React.Component {
  render() {
    const {
      classes,
      mealTypeSizes,
      dishSizes,
      rowType,
      elementRowName,
      element,
      elementConstant,
      subtractElement,
    } = this.props;

    return (
      <TableRow>
        <TableCell>{elementRowName}</TableCell>
        <TableCell />
        <TableCell />
        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => {
          return (
            <ElementSumCell
              rowType={rowType}
              key={mealTypeSizeKey}
              classes={classes}
              size={mealTypeSize.size}
              mealTypeSize={mealTypeSize}
              dishSizes={dishSizes}
              element={element}
              subtractElement={subtractElement}
              elementConstant={elementConstant}
            />
          );
        })}
      </TableRow>
    );
  }
}

export default ElementSumRow;

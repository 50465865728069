import List from './List';
import withRole from 'components/Security/withRole';
import { ROLE_SHOW_ORDER } from 'helpers/roles';

const Baskets = () => {
  const ListSecured = withRole(ROLE_SHOW_ORDER, List);

  return <ListSecured />;
};

export default Baskets;

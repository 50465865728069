import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import DietsPackedPerDayChart from './sections/statistics/DietsPackedPerDayChart';

const DietFlowStatistics = ({ dietId }) => {
  const { t } = useTranslation();

  return (
    <div>
      {/* AMOUNT OF PACKED DIETS PER DAY */}
      <Card>
        <CardBody style={{ paddingBottom: '3rem' }}>
          <h3>
            {t(
              'views.diets.dietFlowStatistics.dietsPackedPerDay',
              '$$Ilość spakowanych diet na kolejne dni'
            )}
          </h3>
          <DietsPackedPerDayChart dietId={dietId} />
        </CardBody>
      </Card>
    </div>
  );
};

export default DietFlowStatistics;

import React from 'react';

import AccessDenied from './AccessDenied';
import { useSelector } from 'react-redux';

const SecuredView = ({ role, children, returnNull }) => {
  const roles = useSelector(state => state.Auth?.user?.roles ?? []);

  if (!roles.includes(role)) {
    if (returnNull === true) {
      return <></>;
    } else {
      return <AccessDenied />;
    }
  }

  return <>{children}</>;
};

export default SecuredView;

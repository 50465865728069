import types from 'helpers/constants';

const initialState = {
  users: [],
  user: {},
  loading: true,
};

function Employees(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
      };
    case types.FETCH_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case types.CLEAR_USERS:
      return {
        ...state,
        users: [],
      };
    case types.CLEAR_USER:
      return {
        ...state,
        user: action.payload,
      };
    case types.REMOVE_USER:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload.userId),
      };
    default:
      return state;
  }
}

export default Employees;

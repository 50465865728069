import PropTypes from 'prop-types';
import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
import { isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';

//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';
import ReportConfigurator from 'components/Report/ReportConfigurator';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core';

//styles
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import AccessDenied from 'components/Security/AccessDenied';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import getReportFileName from 'helpers/getReportFileName';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from '../DownloadReportButtons/DownloadReportButtons';
import MIME_TYPES from '../constants/mimeTypes';
import MIME_TYPES_DATA from '../constants/mimeTypesData';
import TOAST_DURATIONS from 'helpers/toastDurations';

class BaseBrandAndDateReport extends React.Component {
  state = {
    selectedBrands: [],
    date: '',
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
    report: [],
    generated: false,
    includeSubscriptions: false,
  };

  setReport = report => this.setState(prevState => ({ ...prevState, report }));

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.props.useDateRange
      ? this.state.dateFrom && this.state.dateTo
      : this.state.date;
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  fetchReport = () => {
    this.setReport([]);
    this.setState({ isGenerating: true, generated: false });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          this.props.t(
            'reports.sDate',
            'Wybierz datę, dla której chcesz wygenerować raport'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          this.props.t(
            'reports.selectBrand',
            'Wybierz przynajmniej jedną markę'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const params = {
      ...(this.props.useDateRange
        ? {
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
          }
        : { date: this.state.date }),
      includeSubscriptions: this.state.includeSubscriptions,
      brands: this.state.selectedBrands.map(brand => brand.value),
    };

    axios
      .get('reports/dish-labels-pdf', { params: params })
      .then(
        response => {
          if (response.data) {
            this.setReport(response.data['hydra:member']);
            this.setState({ isGenerating: false, generated: true });
          } else {
            alert(
              this.props.t(
                'reports.noAccess',
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              )
            );
          }
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t(
                'reports.cannotGenerate',
                'Nie udało się wygenerować raportu'
              ),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  handleGenerate = ({ mimeType, lang, dishName, url }) => {
    this.setState({ isGenerating: true });

    axios
      .get(url, {
        responseType: 'blob',
        headers: { accept: mimeType },
        forcedLanguage: lang,
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${getReportFileName(dishName)(this.state)}.${
                MIME_TYPES_DATA[mimeType].extension
              }`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(
              this.props.t(
                'reports.noAccess',
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              )
            );
          }
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t(
                'reports.cannotGenerate',
                'Nie udało się wygenerować raportu'
              ),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  render() {
    const { classes, title, company, reportConfiguration, t } = this.props;
    const companyId = parseInt(company.split('/').pop());
    const reportKey = reportConfiguration?.report || false;
    const fields = reportConfiguration?.fields;

    return isGranted(this.props.role || true) ? (
      <CardWrapper
        title={
          <>
            {title}{' '}
            {reportKey && (
              <ReportConfigurator
                companyId={companyId}
                report={reportKey}
                fields={fields}
              />
            )}
          </>
        }
      >
        <Dialog open={this.state.isGenerating}>
          <DialogContent>
            <div style={{ textAlign: 'center' }}>
              <h1>
                {this.props.t('reports.generate', 'Trwa generowanie raportu')}
              </h1>
              <CircularProgress />
            </div>
          </DialogContent>
        </Dialog>
        <CardBody>
          <GridContainer justify="flex-end">
            <GridItem sm={6}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('reports.selectBrands')} *
              </FormLabel>
              <SelectAll
                options={this.props.brands}
                trackBy={['@id']}
                mapBy={'name'}
                optionSelected={this.state.selectedBrands}
                handleChange={selected => {
                  this.setState({
                    selectedBrands: selected,
                  });
                }}
              />
            </GridItem>
            {this.props.useDateRange && (
              <>
                <GridItem sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('reports.dateFrom')} *
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      isValidDate={isDatepickerValidDay}
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(this.state.dateFrom)}
                      onChange={ev =>
                        this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('reports.dateTo')} *
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      isValidDate={isDatepickerValidDay}
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(this.state.dateTo)}
                      onChange={ev =>
                        this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
            {!this.props.useDateRange && (
              <>
                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('reports.selectDate')} *
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      isValidDate={isDatepickerValidDay}
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(this.state.date)}
                      onChange={ev =>
                        this.setState({ date: ev.format('YYYY-MM-DD') })
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
            <GridItem sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.includeSubscriptions}
                    onChange={e => {
                      this.setState({
                        includeSubscriptions: !this.state.includeSubscriptions,
                      });
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('reports.includeSubscriptions')}
              />
            </GridItem>
            <GridItem>
              <Button
                onClick={this.fetchReport}
                disabled={this.state.isGenerating}
                color={'success'}
                round
              >
                {this.props.t('reports.generatePreview', 'Generuj podgląd')}
              </Button>
            </GridItem>
          </GridContainer>
          {this.state.generated && (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('reports.nameOfRecipe', 'Name of Recipe')}
                    </TableCell>
                    <TableCell>{t('reports.size', 'Size')}</TableCell>
                    <TableCell>{t('reports.quiantity', 'Quantity')}</TableCell>
                    <TableCell>{t('reports.action', 'Action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.report.map(({ dishName, sizes }, index) => (
                    <>
                      {sizes.map(
                        (
                          { sizeName, quantity, downloadLinkPattern },
                          sizeIndex
                        ) => (
                          <TableRow key={`${index}_${sizeIndex}`}>
                            {sizeIndex === 0 && (
                              <TableCell rowSpan={sizes.length}>
                                {dishName}
                              </TableCell>
                            )}
                            <TableCell>{sizeName}</TableCell>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>
                              <DownloadReportButtons
                                isSalesMarketLangViewEnabled={true}
                                mimeTypes={[MIME_TYPES.pdf]}
                                mimeTypesData={{
                                  ...MIME_TYPES_DATA,
                                  [MIME_TYPES.pdf]: {
                                    ...MIME_TYPES_DATA[MIME_TYPES.pdf],
                                    translationKey: 'reports.pdf',
                                  },
                                }}
                                handleGenerate={(mimeType, lang) => {
                                  this.handleGenerate({
                                    mimeType,
                                    lang,
                                    dishName,
                                    url: downloadLinkPattern,
                                  });
                                }}
                                areButtonsDisabled={this.state.isGenerating}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </>
                  ))}
                  {this.state.report.length === 0 && (
                    <>
                      <TableRow>
                        <TableCell>
                          {t('reports.noResults', 'No results')}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </CardBody>
      </CardWrapper>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  company: state.Auth.user.company,
});

BaseBrandAndDateReport.propTypes = {
  brands: PropTypes.array,
  classes: PropTypes.any,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  useDateRange: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(BaseBrandAndDateReport);

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PriceSumCell from './PriceSumCell';

class PriceSumRow extends React.Component {
  render() {
    const { classes, mealTypeSizes, dishSizes, rowType, name } = this.props;

    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => {
          return (
            <PriceSumCell
              rowType={rowType}
              key={mealTypeSizeKey}
              classes={classes}
              size={mealTypeSize.size}
              mealTypeSize={mealTypeSize}
              dishSizes={dishSizes}
            />
          );
        })}
      </TableRow>
    );
  }
}

export default PriceSumRow;

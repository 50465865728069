import React, { useState, useEffect } from 'react';
import { get } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  adminMailingOrder,
  clientMailingOrder,
  clientToggleable,
  mailingOptions,
} from './constans';
import MailNotificationView from './MailNotification.view';
import TOAST_DURATIONS from 'helpers/toastDurations';

const MailNotificationContainer = ({ t, openToast, user, brand }) => {
  const [state, setState] = useState({
    adminMailing: [],
    clientMailing: [],
    clientToggleableMailing: [],
    allowToChangedElements: false,
    mainEmailIsChanged: false,
  });

  useEffect(() => {
    fetchMailConfigurations();
    get(user.company, {
      properties: ['defaultConfigFromBrand'],
    }).then(res => {
      setState(prev => ({
        ...prev,
        defaultConfigFromBrand: res.defaultConfigFromBrand,
      }));
    });
  }, []);

  const sortMailing = (mailings, sort) => {
    return mailings.sort((a, b) => {
      const aVal = parseInt(
        Object.keys(sort).find(key => sort[key] === a.mailKey)
      );
      const bVal = parseInt(
        Object.keys(sort).find(key => sort[key] === b.mailKey)
      );

      if (aVal === undefined || bVal === undefined) {
        return 1;
      }

      return aVal > bVal ? 1 : -1;
    });
  };

  const setAllowToChangedElements = () => {
    setState(prev => ({
      ...prev,
      allowToChangedElements: !state.allowToChangedElements,
    }));
  };

  const fetchMailConfigurations = () => {
    get('mail-configurations').then(
      res => {
        const configurations = res['hydra:member'].map(configuration => ({
          ...configuration,
          hasChanged: false,
          isHidden: true,
          contentType:
            configuration.managedBySystem ||
            (configuration.enabledForOffice && !configuration.enabledForClient)
              ? 'office'
              : 'client',
        }));

        setState(prev => ({
          ...prev,
          adminMailing: sortMailing(
            configurations.filter(
              c => c.managedBySystem || c.mailKey === 'employee_welcome'
            ),
            adminMailingOrder
          ),
          clientMailing: sortMailing(
            configurations.filter(
              c => !c.managedBySystem && c.mailKey !== 'employee_welcome'
            ),
            clientMailingOrder
          ),
          clientToggleableMailing: configurations.filter(c =>
            clientToggleable.includes(c.mailKey)
          ),
        }));
      },
      error =>
        openToast({
          messages: [t('notify.cantGetCfg')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        })
    );
  };

  const props = {
    t,
    state,
    setState,
    mailingOptions,
    setAllowToChangedElements,
  };

  return <MailNotificationView props={props} />;
};

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
    user: state.Auth.user,
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps, null));
export default enhance(MailNotificationContainer);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AddonsContent } from './AddonsContent';

const Component = ({ orderId, item, fillModal }) => {
  const { t } = useTranslation();
  const { offerDiet, diet, bag } = item;

  const subscriptionSuffix =
    item?.paymentMode === 'SUBSCRIPTION_PAYMENT'
      ? `(${t('diets.isSubscription')})`
      : '';
  const id = diet?.id ?? bag?.clientDiet?.id ?? null;

  return (
    <>
      {id ? (
        <NavLink to={`/admin/ecommerce-diets/preview/${id}`}>
          {offerDiet?.name} (#{id}){subscriptionSuffix}
        </NavLink>
      ) : (
        <span>{offerDiet?.name}</span>
      )}
      {item?.addons?.length > 0 && (
        <span
          style={{ color: '#89229b', cursor: 'pointer' }}
          onClick={() =>
            fillModal(
              <AddonsContent orderId={orderId} diet={item}></AddonsContent>
            )
          }
        >
          {' '}
          {t('common.mainMenu.addons', 'Dodatki')}
        </span>
      )}
    </>
  );
};
export default Component;

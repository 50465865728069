import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import axios from 'helpers/gastro';
import AdminTable from 'layouts/AdminTable';

import { isGranted } from 'helpers/helpers';

import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import ImagePreviewCustomAction from 'components/ImagePreviewCustomAction/ImagePreviewCustomAction';

import Button from 'components/CustomButtons/Button';
import importCSVExampleTranslations from '../exampleFile/import-csv-example-translations.csv';
import columnConfig from '../columnConfig';
import {
  ROLE_CREATE_DISH,
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_EDIT_MODULE_DISH_PDF_GENERATOR,
  ROLE_EXPORT_DISH_TRANSLATION,
  ROLE_IMPORT_DISH_TRANSLATION,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_SHOW_KWG_GROUP,
  ROLE_SHOW_MODULE_DISH_PDF_GENERATOR,
} from 'helpers/roles';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import gastro from 'helpers/gastro';
import { post } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';
class List extends Component {
  state = {
    openedModal: false,
    file: '',
    isImporting: false,
    importButtonClicked: false,
  };

  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  settings = () =>
    this.props.history.push(`${window.location.pathname}/settings`);
  kwgGroup = () => {
    this.props.history.push(`${window.location.pathname}/kwg_group`);
  };
  toggleModal = () => {
    this.setState({
      openedModal: !this.state.openedModal,
    });
  };

  uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  postImporterTask = (file, mode) => {
    this.setState(prev => ({ ...prev, isImporting: true }));

    const data = {
      type: 'DISH_TRANSLATIONS',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          openedModal: false,
          file: null,
          importButtonClicked: false,
        });
        this.setState(prev => ({ ...prev, isImporting: false }));
        return this.props.openToast({
          messages: [
            this.props.t('form.importerInfo26'),
            this.props.t('form.importerInfo23'),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      },
      error => {
        this.setState(prev => ({ ...prev, isImporting: false }));
        return this.props.openToast({
          messages: [this.props.t('form.importerInfo25')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };

  importIngredients = () => {
    this.setState(prev => ({ ...prev, importButtonClicked: true }));
    this.uploadFile(this.state.file).then(file => {
      this.postImporterTask(file, this.state.modalMode);
    });
  };

  loadTranslationsExample = language => {
    switch (language) {
      case 'fi':
        return importCSVExampleTranslations;
      default:
        return importCSVExampleTranslations;
    }
  };

  customActions = row => {
    if (isEmpty(row?.productionImage)) {
      return;
    }

    return [
      {
        action: () => {},
        icon: <ImagePreviewCustomAction image={row?.productionImage} />,
        color: 'primary',
        simple: true,
        round: false,
      },
    ];
  };

  exportTranslations = () => {
    const fileName = 'dish_translations_export_file';
    const endpoint = '/dish-translations-export';
    const format = 'xlsx';
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Dodajemy 1, ponieważ miesiące są numerowane od 0 (0 - styczeń, 1 - luty, itd.).
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const date = `${day}-${month}-${year}_${hours}-${minutes}`;
    const exportedName = `${fileName}_${date}`;

    const params = {
      'order[id]': 'DESC',
    };

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: params,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
          this.setState(prev => ({ ...prev, exporting: false }));
        },
        error => {
          this.setState(prev => ({ ...prev, exporting: false }));
          return this.props.openToast({
            messages: [
              this.props.t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      );
  };

  render() {
    const { classes } = this.props;
    const canEditSettings =
      isGranted(ROLE_EDIT_MODULE_DISH_PDF_GENERATOR) || true;
    const canSeeSettings =
      isGranted(ROLE_SHOW_MODULE_DISH_PDF_GENERATOR) || true;

    return (
      <AdminTable title={this.props.t('list.mealsList')}>
        <InfoModal
          modalState={this.state.openedModal}
          handleClose={() => this.toggleModal()}
          modalContent={
            <div>
              <span>
                <h1>
                  {this.props.t(
                    'form.ingredients.import.dishes.title',
                    'Import tłumaczeń'
                  )}
                </h1>
                <p style={{ textAlign: 'left' }}>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: this.props.t(
                        'form.ingredients.dishes.modalInfo',
                        {
                          importCSVExample: this.loadTranslationsExample(
                            this.props.language
                          ),
                          interpolation: { escapeValue: false },
                        }
                      ),
                    }}
                  />
                </p>
              </span>

              <MediaUpload
                file={this.state}
                setFile={file =>
                  this.setState(prev => ({ ...prev, file: file }))
                }
                buttonText={this.props.t('form.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                  isGranted(ROLE_CREATE_DISH) && (
                    <Button
                      color={this.state.file ? 'success' : 'default'}
                      disabled={
                        !this.state.file ||
                        this.state.isImporting ||
                        this.state.importButtonClicked
                      }
                      onClick={this.importIngredients}
                    >
                      {this.props.t('form.import')}
                    </Button>
                  )}
                <Button onClick={() => this.toggleModal('')}>
                  {this.props.t('form.close')}
                </Button>
              </div>
            </div>
          }
          closeButtonText={this.props.t('form.close')}
          hideDefaultCloseButton
        />

        {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
          isGranted(ROLE_IMPORT_DISH_TRANSLATION) && (
            <Button
              style={{ margin: '5px', width: '158px' }}
              color={'info'}
              onClick={() => this.toggleModal()}
            >
              {this.props.t(
                'form.ingredients.import.dishes',
                'Importuj tłumaczenia'
              )}
            </Button>
          )}

        {isGranted(ROLE_EXPORT_DISH_TRANSLATION) && (
          <Button
            data-cy="__export_prices"
            color="info"
            onClick={() => {
              this.exportTranslations();
            }}
          >
            {this.props.t(
              'form.ingredients.export.dishes',
              'Eksportuj tłumaczenia'
            )}
          </Button>
        )}

        {isGranted(ROLE_CREATE_DISH) && (
          <>
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newSize}
              submitText={this.props.t('form.addMeal') + ' +'}
              secondarySubmitText={this.props.t(
                'views.recipeDatabase.meal.mealPdfSettings'
              )}
              secondarySubmitDisabled={!(canSeeSettings || canEditSettings)}
              handleSecondarySubmit={this.settings}
              tertiarySubmitText={
                isGranted(ROLE_SHOW_KWG_GROUP)
                  ? this.props.t(
                      'views.recipeDatabase.meal.configCardFinishedProduct'
                    )
                  : ''
              }
              handleTertiarySubmit={this.kwgGroup}
            />
          </>
        )}

        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/dishes"
          reportName={'dish'}
          columns={columnConfig}
          role="DISH"
          customActions={this.customActions}
          manipulateQuery={(table, query) => {
            query['properties'] = [
              'id',
              'nameForClient',
              'workingName',
              'rateAVG',
              'productionImage',
            ];
            query['properties[productionImage]'] = ['contentUrl'];
            query['properties[tags]'] = ['value'];
            query['properties[components][recipe]'] = [
              'workName',
              'ingredients',
            ];
            query['properties[components][ingredient]'] = ['name', 'workName'];
            query['properties[types]'] = ['name'];
            query['properties[components][recipe][ingredients]'] = [
              'ingredient',
            ];
            query['properties[components][recipe][ingredients][ingredient]'] = [
              'name',
              'workName',
            ];

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.Brands.brand.multinational.adminDefaultLanguage,
  };
};

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(buttonsStyle),
  connect(mapStateToProps)
);

export default enhance(List);

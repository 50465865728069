import React from 'react';

import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import CardWrapper from 'components/Card/CardWrapper';

const DescriptionCard = ({ value, setValue, title }) => {
  return (
    <CardWrapper title={title}>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <SimpleWysiwyg
              handleChange={e => setValue(e.target.value)}
              value={value}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default DescriptionCard;

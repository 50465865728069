import types from 'helpers/constants';

const initialState = {
  packages: [],
};

function Packages(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PACKAGES:
      console.log(action.payload);
      return {
        ...state,
        packages: action.payload.packages,
        loading: false,
      };
    default:
      return state;
  }
}

export default Packages;

import React from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_PACKAGE, ROLE_REMOVE_PACKAGE } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const List = ({ classes, t, history }) => {
  const newSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable title={t('list.filtersList')}>
      {isGranted(ROLE_CREATE_PACKAGE) && (
        <FormControlButtons
          classes={classes}
          handleSubmit={newSize}
          submitText={t('form.addFilter') + ' +'}
        />
      )}
      <DataGrid
        actions={true}
        export={true}
        editPath={`${window.location.pathname}/edit`}
        remove={isGranted(ROLE_REMOVE_PACKAGE)}
        paginationTop={true}
        paginationBottom={false}
        url="packages"
        reportName={'package'}
        columns={columnConfig(t)}
        role="PACKAGE"
      />
    </AdminTable>
  );
};

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

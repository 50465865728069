import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './List';
import Form from './Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_ZONE,
  ROLE_EDIT_ZONE,
  ROLE_SHOW_ZONE,
} from 'helpers/roles';

class Zones extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_ZONE, List);
    const CreateForm = withRole(ROLE_CREATE_ZONE, Form);
    const EditForm = withRole(ROLE_EDIT_ZONE, Form);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Zones);

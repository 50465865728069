import RowImageUpload from 'components/RowImageUpload/RowImageUpload';
import { ROLE_EDIT_DISH_PHOTO_GALLERY } from 'helpers/roles';

export const columnConfig = (
  t,
  classes,
  updateFunction,
  customButton,
  customButtonAddIcon,
  customButtonRemoveIcon,
  isEdit,
  setIsEdit,
  responsibleWidthDialogModal,
  rowData
) => [
  ...(!responsibleWidthDialogModal
    ? [
        {
          title: 'ID',
          accessor: 'id',
          name: 'id',
          width: 80,
          filterable: false,
          sortable: false,
        },
        {
          title: t(
            'common.dishesPhoto.dishes.nameForClient',
            'Nazwa dania dla klienta'
          ),
          accessor: row => {
            return row?.nameForClient;
          },
          name: 'nameForClient',
          filterable: false,
          sortable: false,
        },
      ]
    : []),
  {
    title: t('common.dishesPhoto.dishes.photo', 'Zdjęcie'),
    accessor: row => {
      if (rowData.includes(row)) {
        return null;
      }
      return (
        <RowImageUpload
          row={row}
          updateFunction={updateFunction}
          customButton={customButton}
          roleEdit={ROLE_EDIT_DISH_PHOTO_GALLERY}
          customButtonAddIcon={customButtonAddIcon}
          customButtonRemoveIcon={customButtonRemoveIcon}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      );
    },
    name: 'dishesImage',
    filterable: false,
    sortable: false,
  },
  ...(responsibleWidthDialogModal
    ? [
        {
          title: 'ID',
          accessor: 'id',
          name: 'id',
          width: 80,
          filterable: false,
          sortable: false,
        },
        {
          title: t(
            'common.dishesPhoto.dishes.nameForClient',
            'Nazwa dania dla klienta'
          ),
          accessor: 'nameForClient',
          name: 'nameForClient',
          filterable: false,
          sortable: false,
        },
      ]
    : []),
];

export const columnConfigMobile = (
  t,
  updateFunction,
  customButton,
  customButtonAddIcon,
  customButtonRemoveIcon,
  isEdit,
  setIsEdit
) => [
  {
    title: t('common.dishesPhoto.dishes.photo', 'Zdjęcie'),
    accessor: row => {
      return (
        <RowImageUpload
          row={row}
          updateFunction={updateFunction}
          customButton={customButton}
          roleEdit={ROLE_EDIT_DISH_PHOTO_GALLERY}
          customButtonAddIcon={customButtonAddIcon}
          customButtonRemoveIcon={customButtonRemoveIcon}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      );
    },
    name: 'dishesImage',
    filterable: false,
    sortable: false,
    width: 200,
  },
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 80,
    filterable: false,
    sortable: false,
  },
  {
    title: t(
      'common.dishesPhoto.dishes.nameForClient',
      'Nazwa dania dla klienta'
    ),
    accessor: 'nameForClient',
    name: 'nameForClient',
    filterable: false,
    sortable: false,
  },
];

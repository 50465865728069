import React from 'react';
import { ROLE_SHOW_REPORT_DISH_LIST } from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';

const DishesToProduction = () => {
  const { t } = useTranslation();

  return (
    <BaseBrandReport
      archiveReportNames={['DISH_LIST']}
      title={t('reports.reportDishesToProd')}
      excelUrl="/reports/dishes-list"
      excelAvailable={true}
      role={ROLE_SHOW_REPORT_DISH_LIST}
      fileName={getReportFileName(t('reports.reportDishesToProd'))}
      dateType="range"
      useZoneCategories={true}
    />
  );
};

export default DishesToProduction;

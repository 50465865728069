import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const ModalRemoveAdditionalDishes = ({
  classes,
  isOpened,
  setIsOpened,
  changeDay,
  bagInfo,
}) => {
  const { t } = useTranslation();

  const refMessage = useRef();

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <Dialog maxWidth={'sm'} open={isOpened} onClose={handleClose}>
      <DialogTitle className={classes.modalHeader}>
        {t(
          'bagEdition.modal.removeAdditionalDishes.title',
          'Usunięcie dodatkowego posiłku'
        )}
      </DialogTitle>
      <DialogContent
        className={classes.modalBody}
        style={{
          minHeight: '100px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.1em',
          fontWeight: 'bold',
        }}
      >
        <p ref={refMessage}>
          {t(
            'bagEdition.modal.removeAdditionalDishes.desciption',
            'Czy na pewno chcesz to zrobić?'
          )}
        </p>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          color="primary"
          onClick={() => {
            handleClose();
          }}
        >
          {t('bagEdition.returnToCost.button.cancel', 'Anuluj')}
        </Button>
        <Button
          color="success"
          onClick={() => {
            changeDay(bagInfo, false);
            handleClose();
          }}
        >
          {t('bagEdition.returnToCost.button.accept', 'Akceptuj')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalRemoveAdditionalDishes;

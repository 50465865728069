import React from 'react';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';

const FormIngredientsNotice = ({ t, classes, handleChange, state }) => {
  return (
    <GridItem xs={12}>
      <CardWrapper title={t('form.field.note')}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={10}>
                  <FormLabel className={classes.labelHorizontal}>
                    {' '}
                    {t('form.field.note')}
                  </FormLabel>
                  <CustomInput
                    id="noteInput"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      id: 'noteInput',
                      type: 'text',
                      name: 'note',
                      onChange: handleChange,
                      value: state.note,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </GridItem>
  );
};

export default FormIngredientsNotice;

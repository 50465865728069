const translateColumnConfig = (t, useAddressesWithLessFields) => {
  const idObject = {
    Header: 'ID',
    accessor: 'id',
    width: 100,
  };
  const cityObject = {
    Header: t('clients.city'),
    accessor: 'city',
  };

  const postcodeObject = {
    Header: t('clients.postCode'),
    accessor: 'postCode',
  };

  if (useAddressesWithLessFields) {
    return [
      idObject,
      cityObject,
      postcodeObject,
      {
        Header: t('clients.addressLine1'),
        accessor: 'addressLine1',
      },
      {
        Header: t('clients.addressLine2'),
        accessor: 'addressLine2',
      },
    ];
  }

  return [
    idObject,
    cityObject,
    {
      Header: t('clients.street'),
      accessor: 'street',
    },
    {
      Header: t('clients.buildingNo'),
      accessor: 'buildNumber',
    },
    {
      Header: t('clients.localNo'),
      accessor: 'placeNumber',
    },
    postcodeObject,
    {
      Header: t('clients.zoneName'),
      accessor: 'zoneName',
    },
  ];
};

export default translateColumnConfig;

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import React from 'react';
import DefinedLengthsSectionSettiongs from './componentsOrderLengthSettings/DefinedLengthsSectionSettiongs';
import MinAndMaksOrderLengthSectionSettings from './componentsOrderLengthSettings/MinAndMaksOrderLengthSectionSettings';
import TestDaysSectionSettings from './componentsOrderLengthSettings/TestDaysSectionSettings';
import CardWrapper from 'components/Card/CardWrapper';

const OrderLengthSettings = ({
  t,
  classes,
  state,
  setState,
  setModules,
  modules,
  handleDays,
  handleInputChange,
}) => {
  return (
    <>
      <CardWrapper title={t('brandCfg.orderLength')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h3>{}</h3>
              <Divider />
            </GridItem>

            <DefinedLengthsSectionSettiongs
              t={t}
              classes={classes}
              state={state}
              setState={setState}
              handleDays={handleDays}
            />

            <MinAndMaksOrderLengthSectionSettings
              classes={classes}
              t={t}
              state={state}
              setState={setState}
              handleInputChange={handleInputChange}
            />
          </GridContainer>

          <TestDaysSectionSettings
            t={t}
            state={state}
            setState={setState}
            classes={classes}
            handleInputChange={handleInputChange}
            modules={modules}
            setModules={setModules}
          />
        </CardBody>
      </CardWrapper>
    </>
  );
};

export default OrderLengthSettings;

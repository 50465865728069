import DietPricesContainer from './Diets/DietPrices.container';
import DietPricesComponent from './Diets/DietPrices.component';

import DietDeliveryContainer from './Delivery/DietDelivery.container';
import DietDeliveryComponent from './Delivery/DietDelivery.component';

import DietDiscountsWrapper from './Discounts';

import AdditionalPricesContainer from './Additional/AdditionalPrices.container';

import MoneyBoxConfigurationsContainer from './MoneyBoxConfigurations/MoneyBoxConfigurations.container';

export const DietPrices = DietPricesContainer(DietPricesComponent);
export const DietDelivery = DietDeliveryContainer(DietDeliveryComponent);
export const DietDiscounts = DietDiscountsWrapper;
export const AdditionalPrices = AdditionalPricesContainer;
export const MoneyBoxConfigurations = MoneyBoxConfigurationsContainer;

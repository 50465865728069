export const transformBrandData = data => {
  return {
    ...data,
    name: undefined,
    '@id': undefined,
    '@type': undefined,
    id: undefined,
    logoMobile: undefined,
    logoDesktop: undefined,
    logoMail: undefined,
    favicon: undefined,
    monday: {
      ...data.monday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    tuesday: {
      ...data.tuesday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    wednesday: {
      ...data.wednesday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    thursday: {
      ...data.thursday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    friday: {
      ...data.friday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    sunday: {
      ...data.sunday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    saturday: {
      ...data.saturday,
      '@id': undefined,
      '@type': undefined,
      id: undefined,
    },
    company: undefined,
    multinational: {
      ...data.multinational,
      '@type': undefined,
    },
    backgroundImage: undefined,
    fonts: undefined,
    showInformation: {
      ...data.showInformation,
    },
    newOrderInfoBannerDesktop: undefined,
    newOrderInfoBannerMobile: undefined,
    logo: undefined,
  };
};

export const transformModulesData = (data, sourceBrandData) => {
  const configuration = {
    configuration: {
      AdditionalPayment: {
        ...data?.AdditionalPayment,
      },
      Addons: {
        ...data?.Addons,
      },
      AppMode: {
        ...data?.AppMode,
        '@resources': undefined,
      },
      AuthServices: {
        ...data?.AuthServices,
        facebook: {
          ...data?.AuthServices?.facebook,
          '@type': undefined,
          '@id': undefined,
          id: sourceBrandData?.AuthServices?.facebook?.id,
          secret: sourceBrandData?.AuthServices?.facebook?.secret,
        },
        google: {
          ...data?.AuthServices?.google,
          '@type': undefined,
          '@id': undefined,
          id: sourceBrandData?.AuthServices?.google?.id,
          secret: sourceBrandData?.AuthServices?.google?.secret,
        },
        apple: {
          ...data?.AuthServices?.apple,
          '@type': undefined,
          '@id': undefined,
          teamId: sourceBrandData?.AuthServices?.apple?.teamId,
          clientId: sourceBrandData?.AuthServices?.apple?.clientId,
          keyFileId: sourceBrandData?.AuthServices?.apple?.keyFileId,
          keyFileContent: sourceBrandData?.AuthServices?.apple?.keyFileContent,
        },
      },
      Bitrix24: {
        ...data?.Bitrix24,
        fieldClientEmail: sourceBrandData?.Bitrix24?.fieldClientEmail,
        fieldClientId: sourceBrandData?.Bitrix24?.fieldClientId,
        fieldClientPhone: sourceBrandData?.Bitrix24?.fieldClientPhone,
        fieldCreatedAt: sourceBrandData?.Bitrix24?.fieldCreatedAt,
        fieldMarketingTerms: sourceBrandData?.Bitrix24?.fieldMarketingTerms,
        fieldOrderId: sourceBrandData?.Bitrix24?.fieldOrderId,
        webhookClient: sourceBrandData?.Bitrix24?.webhookClient,
        webhookEvent: sourceBrandData?.Bitrix24?.webhookEvent,
      },
      Blog: {
        ...data?.Blog,
        '@resources': undefined,
        bannerDesktop: undefined,
        bannerDesktopUrl: undefined,
        bannerMobile: undefined,
        bannerMobileUrl: undefined,
        image: undefined,
        imageUrl: undefined,
      },
      BlueMedia: {
        ...data?.BlueMedia,
        serviceId: sourceBrandData?.BlueMedia?.serviceId,
        serviceKey: sourceBrandData?.BlueMedia?.serviceKey,
        hashMethod: sourceBrandData?.BlueMedia?.hashMethod,
        separator: sourceBrandData?.BlueMedia?.separator,
        gatewayUrl: sourceBrandData?.BlueMedia?.gatewayUrl,
      },

      Caching: {
        ...data?.Caching,
      },
      ConfigClientPanel: {
        ...data?.ConfigClientPanel,
        '@resources': undefined,
        initialBasketId: undefined,
        calendarIconDelivered: undefined,
        calendarIconRated: undefined,
        calendarIconRatedPartial: undefined,
        calendarIconCanBeRated: undefined,
        calendarIconDetails: undefined,
        calendarIconChangeMenu: undefined,
        calendarIconMenuSoon: undefined,
        calendarIconSubscriptionOrdered: undefined,
        calendarIconSubscriptionAddDelivery: undefined,
        dishImagePlaceholder: undefined,
        dishImageBackground: undefined,
        dishImageBackgroundEnabled: undefined,
      },
      FacebookPixel: {
        ...data?.FacebookPixel,
        id: sourceBrandData?.FacebookPixel?.id,
      },
      Fakturownia: {
        ...data?.Fakturownia,
        host: sourceBrandData?.Fakturownia?.host,
        token: sourceBrandData?.Fakturownia?.token,
        department: sourceBrandData?.Fakturownia?.department,
        dietItemName: sourceBrandData?.Fakturownia?.dietItemName,
      },
      FakturowniaWarehouse: {
        ...data?.FakturowniaWarehouse,
        host: sourceBrandData?.FakturowniaWarehouse?.host,
        token: sourceBrandData?.FakturowniaWarehouse?.token,
        apiToken: sourceBrandData?.FakturowniaWarehouse?.apiToken,
      },
      GetResponse: {
        ...data?.GetResponse,
        apiKey: sourceBrandData?.GetResponse?.apiKey,
        apiDomain: sourceBrandData?.GetResponse?.apiDomain,
      },
      Goodspeed: {
        ...data?.Goodspeed,
        apiUrl: sourceBrandData?.Goodspeed?.apiUrl,
        token: sourceBrandData?.Goodspeed?.token,
      },
      GoogleAnalytics: {
        ...data?.GoogleAnalytics,
        code: sourceBrandData?.GoogleAnalytics?.code,
      },
      GoogleTagMenager: {
        ...data?.GoogleTagMenager,
        code: sourceBrandData?.GoogleTagMenager?.code,
      },
      HotJar: {
        ...data?.HotJar,
        id: sourceBrandData?.HotJar?.id,
      },
      Hover: {
        ...data?.Hover,
        '@resources': undefined,
        image: undefined,
      },
      Ipresso: {
        ...data?.Ipresso,
        apiUrl: sourceBrandData?.Ipresso?.apiUrl,
        apiCustomerKey: sourceBrandData?.Ipresso?.apiCustomerKey,
        apiLogin: sourceBrandData?.Ipresso?.apiLogin,
        apiPassword: sourceBrandData?.Ipresso?.apiPassword,
      },
      ItemPaymentTypes: {
        ...data?.ItemPaymentTypes,
        enabledPaymentTypes: data.ItemPaymentTypes.enabledPaymentTypes,
        enabledCardPayments: data.ItemPaymentTypes.enabledCardPayments,
        enabledOnlinePayments: data.ItemPaymentTypes.enabledOnlinePayments,
        standard: {
          ...data?.ItemPaymentTypes.standard,
          '@type': undefined,
          '@id': undefined,
          defaultPaymentType: data.ItemPaymentTypes.standard.defaultPaymentType,
          enabledPaymentTypes:
            data.ItemPaymentTypes.standard.enabledPaymentTypes,
        },
        subscription: {
          ...data?.ItemPaymentTypes.subscription,
          '@type': undefined,
          '@id': undefined,
          defaultPaymentType:
            data.ItemPaymentTypes.subscription.defaultPaymentType,
          enabledPaymentTypes:
            data.ItemPaymentTypes.subscription.enabledPaymentTypes,
        },
      },
      MenuPage: {
        ...data?.MenuPage,
        '@resources': undefined,
        bannerDesktop: undefined,
        bannerMobile: undefined,
        defaultDiet: sourceBrandData?.MenuPage?.defaultDiet,
        defaultVariant: sourceBrandData?.MenuPage?.defaultVariant,
      },
      MenuSelectionAssistant: {
        ...data?.MenuSelectionAssistant,
        allergens: undefined,
        ingredientCategories: undefined,
        clientTags: undefined,
        diets: undefined,
      },
      MyLead: {
        ...data?.MyLead,
        providerHash: sourceBrandData?.MyLead?.providerHash,
      },
      Omniconvert: {
        ...data?.Omniconvert,
        id: sourceBrandData?.Omniconvert?.apiToken,
        url: sourceBrandData?.Omniconvert?.url,
      },
      PayNow: {
        ...data?.PayNow,
        apiKey: sourceBrandData?.PayNow?.apiKey,
        signatureKey: sourceBrandData?.PayNow?.signatureKey,
      },
      PayPo: {
        ...data?.PayPo,
        merchantId: sourceBrandData?.PayPo?.merchantId,
        token: sourceBrandData?.PayPo?.token,
        url: sourceBrandData?.PayPo?.url,
      },
      PayU: {
        ...data?.PayU,
        clientId: sourceBrandData?.PayU?.clientId,
        posId: sourceBrandData?.PayU?.posId,
        md5Key: sourceBrandData?.PayU?.md5Key,
        clientSecret: sourceBrandData?.PayU?.clientSecret,
        googlePayMerchantId: sourceBrandData?.PayU?.googlePayMerchantId,
      },
      PaymentModesCopy: {
        ...data?.PaymentModesCopy,
        single: {
          ...data?.PaymentModesCopy.single,
          '@type': undefined,
          '@id': undefined,
        },
        subscription: {
          ...data?.PaymentModesCopy.subscription,
          '@type': undefined,
          '@id': undefined,
        },
      },
      PollsterTrack: {
        ...data?.PollsterTrack,
        GID: sourceBrandData?.PollsterTrack?.GID,
      },
      PowerBI: {
        ...data?.AppMode,
      },
      SalesManago: {
        ...data?.SalesManago,
        apiKey: sourceBrandData?.SalesManago?.apiKey,
        apiSecret: sourceBrandData?.SalesManago?.apiSecret,
        clientId: sourceBrandData?.SalesManago?.clientId,
      },
      Seo: {
        ...data?.Seo,
        '@resources': undefined,
        ogImage: undefined,
      },
      SeoAddon: {
        ...data?.SeoAddon,
        '@resources': undefined,
        bannerMobile: undefined,
        bannerDesktop: undefined,
        listPageBannerMobile: undefined,
        listPageBannerDesktop: undefined,
      },
      SeoCmsAddonsSide: {
        ...data?.SeoCmsAddonsSide,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsClientPanelLogin: {
        ...data?.SeoCmsClientPanelLogin,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsClientPanelMyAccount: {
        ...data?.SeoCmsClientPanelMyAccount,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsClientPanelRegister: {
        ...data?.SeoCmsClientPanelRegister,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsDietSide: {
        ...data?.SeoCmsDietSide,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsDishSide: {
        ...data?.SeoCmsDishSide,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsMainSite: {
        ...data?.SeoCmsMainSite,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsMenuSubpage: {
        ...data?.SeoCmsMenuSubpage,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoCmsZonesSubpage: {
        ...data?.SeoCmsZonesSubpage,
        '@resources': undefined,
        image: undefined,
        imageUrl: undefined,
      },
      SeoDiet: {
        ...data?.SeoDiet,
        '@resources': undefined,
        listPageBannerDesktop: undefined,
      },
      SeoDish: {
        ...data?.SeoDish,
        '@resources': undefined,
        bannerMobile: undefined,
        bannerDesktop: undefined,
        listPageBannerMobile: undefined,
        listPageBannerDesktop: undefined,
      },
      SeoZone: {
        ...data?.SeoZone,
        '@resources': undefined,
        banners: {
          ...data?.SeoZone?.banners,
          first: {
            ...data?.SeoZone?.banners?.first,
            '@type': undefined,
            '@id': undefined,
            mobile: undefined,
            desktop: undefined,
          },
          second: {
            ...data?.SeoZone?.banners?.second,
            '@type': undefined,
            '@id': undefined,
            mobile: undefined,
            desktop: undefined,
          },
          third: {
            ...data?.SeoZone?.banners?.third,
            '@type': undefined,
            '@id': undefined,
            mobile: undefined,
            desktop: undefined,
          },
          fourth: {
            ...data?.SeoZone?.banners?.fourth,
            '@type': undefined,
            '@id': undefined,
            mobile: undefined,
            desktop: undefined,
          },
          fifth: {
            ...data?.SeoZone?.banners?.fifth,
            '@type': undefined,
            '@id': undefined,
            mobile: undefined,
            desktop: undefined,
          },
          sixth: {
            ...data?.SeoZone?.banners?.sixth,
            '@type': undefined,
            '@id': undefined,
            mobile: undefined,
            desktop: undefined,
          },
        },
        addressFinder: {
          ...data?.SeoZone?.addressFinder,
          '@type': undefined,
          '@id': undefined,
          mobile: undefined,
          desktop: undefined,
        },
      },
      Settlements: {
        ...data?.Settlements,
      },
      Shop: {
        ...data?.Shop,
      },
      Stripe: {
        ...data?.Stripe,
        publishableKey: sourceBrandData?.Stripe?.publishableKey,
        secretKey: sourceBrandData?.Stripe?.secretKey,
        webhookSecret: sourceBrandData?.Stripe?.webhookSecret,
      },
      Thulium: {
        ...data?.Thulium,
        url: sourceBrandData?.Thulium?.url,
        login: sourceBrandData?.Thulium?.urllogin,
        password: sourceBrandData?.Thulium?.urlpassword,
      },
      Tpay: {
        ...data?.Tpay,
        auth: sourceBrandData?.Tpay?.auth,
        id: sourceBrandData?.Tpay?.id,
      },
      TrustedShops: {
        ...data?.TrustedShops,
        tsId: sourceBrandData?.TrustedShops?.tsId,
      },
      UserCom: {
        ...data?.UserCom,
        apiUrl: sourceBrandData?.UserCom?.apiUrl,
        apiKey: sourceBrandData?.UserCom?.apiKey,
        apiToken: sourceBrandData?.UserCom?.apiToken,
      },
    },
  };

  Object.entries(configuration.configuration).forEach(([key, value]) => {
    if (!data[key]) {
      delete configuration.configuration[key];
    } else {
      configuration.configuration[key] = {
        ...value,
        '@type': undefined,
        '@id': undefined,
      };
    }
  });
  return configuration;
};

import React from 'react';
import { TableCell } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles, countDecimal, toDecimalPlaces } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const overrideInputStyle = {
  inputSizeCell: {
    color: '#B5B5B5',
    '&,&::placeholder': {
      fontSize: '12px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
    '&::placeholder': {
      color: '#B5B5B5',
    },
  },
};
class SizeCell extends React.Component {
  state = {
    quantity: 0,
    kcal: 0,
    dishIngredientWeight: 0,
    dishIngredientWeightAfterProcessing: 0,
    dishIngredientDefaultKcal: 0,
    dishIngredientDefaultWeight: 0,
    dishIngredientDefaultWeightAfterProcessing: 0,
    dishIngredientPrice: 0,
    dishRecipePrice: 0,
  };

  componentDidMount() {
    const dishIngredient = this.getDishIngredient();
    const defaultKcal = this.getDefaultKcal({ dishIngredient });
    const defaultWeight = this.getDefaultWeight({ dishIngredient });
    const defaultWeightAfterProcessing = this.getDefaultWeightAfterProcessing({
      dishIngredient,
    });

    this.setState({
      quantity: parseFloat(
        parseFloat(dishIngredient.quantity).toFixed(
          this.props.dishIngredientsDecimalPlaces
        )
      ),
      kcal: parseFloat(
        (
          (dishIngredient.quantity * defaultKcal) /
          (100 / (dishIngredient?.ingredient?.weight || 100))
        ).toFixed(2)
      ),
      dishIngredientWeight: parseFloat(
        (dishIngredient.quantity * defaultWeight).toFixed(2)
      ),
      dishIngredientWeightAfterProcessing: parseFloat(
        (dishIngredient.quantity * defaultWeightAfterProcessing).toFixed(2)
      ),
      dishIngredientDefaultKcal: defaultKcal,
      dishIngredientDefaultWeight: defaultWeight,
      dishIngredientDefaultWeightAfterProcessing: defaultWeightAfterProcessing,
      dishIngredientPrice: dishIngredient?.ingredient?.price,
      dishRecipePrice: dishIngredient?.recipe?.price,
    });
  }

  componentWillReceiveProps(nextProps) {
    const currentDishIngredient = this.getDishIngredient(this.props);
    const nextDishIngredient = this.getDishIngredient(nextProps);

    if (nextDishIngredient.quantity !== currentDishIngredient.quantity) {
      const { quantity } = nextDishIngredient;
      const dishIngredient = this.getDishIngredient();
      const weightOfIngredient =
        dishIngredient?.ingredient?.macrosAfterProcessing?.weight || 100; // This is for ingredient only. Recipies works fine

      this.setState(prevState => ({
        quantity: parseFloat(
          parseFloat(quantity).toFixed(this.props.dishIngredientsDecimalPlaces)
        ),
        kcal: parseFloat(
          (
            (quantity * prevState.dishIngredientDefaultKcal) /
            (100 / weightOfIngredient)
          ).toFixed(2)
        ),
        dishIngredientWeight: parseFloat(
          (quantity * prevState.dishIngredientDefaultWeight).toFixed(2)
        ),
        dishIngredientWeightAfterProcessing: parseFloat(
          (
            quantity * prevState.dishIngredientDefaultWeightAfterProcessing
          ).toFixed(2)
        ),
      }));
    }
  }

  getDishIngredient = (props = this.props) => {
    const { dishSizes, size, rowIngredient } = props;
    let dishIngredient;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    if (dishSizeBySize) {
      dishIngredient = dishSizeBySize.ingredients.find(sizeIngredient => {
        return (
          (sizeIngredient.recipe !== null &&
            sizeIngredient.recipe['@id'] === rowIngredient['@id']) ||
          (sizeIngredient.ingredient !== null &&
            sizeIngredient.ingredient['@id'] === rowIngredient['@id'])
        );
      });
    }

    return dishIngredient;
  };

  getDefaultKcal = ({ dishIngredient }) => {
    const kcal = !dishIngredient.recipe
      ? dishIngredient.ingredient.macrosAfterProcessing.calories
      : dishIngredient.recipe.macrosAfterProcessing.calories;

    return parseFloat(kcal.toFixed(2));
  };

  getDefaultWeight = ({ dishIngredient }) => {
    const weight = !dishIngredient.recipe
      ? dishIngredient.ingredient.weight
      : dishIngredient.recipe.weight;

    return parseFloat(weight.toFixed(2));
  };

  getDefaultWeightAfterProcessing = ({ dishIngredient }) => {
    const weight = !dishIngredient.recipe
      ? dishIngredient.ingredient.macrosAfterProcessing.weight
      : dishIngredient.recipe.macrosAfterProcessing.weight;

    return weight ? parseFloat(weight.toFixed(2)) : 0;
  };

  handleChange = ({ quantity, override = {} }) => {
    const dishIngredient = this.getDishIngredient();
    const { dishSizes, onChange, rowIngredient, size } = this.props;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    const {
      dishIngredientDefaultKcal,
      dishIngredientDefaultWeight,
      dishIngredientDefaultWeightAfterProcessing,
    } = this.state;

    const weightOfIngredient = dishIngredient?.ingredient?.weight || 100; // This is for ingredient only. Recipies works fine

    const kcal = override.kcal
      ? override.kcal
      : parseFloat(
          (
            (quantity * dishIngredientDefaultKcal) /
            (100 / weightOfIngredient)
          ).toFixed(2)
        );

    const dishIngredientWeight = override.dishIngredientWeight
      ? override.dishIngredientWeight
      : parseFloat((quantity * dishIngredientDefaultWeight).toFixed(2));

    const dishIngredientWeightAfterProcessing = override.dishIngredientWeightAfterProcessing
      ? override.dishIngredientWeightAfterProcessing
      : parseFloat(
          (quantity * dishIngredientDefaultWeightAfterProcessing).toFixed(2)
        );

    this.setState({
      quantity,
      kcal,
      dishIngredientWeight,
      dishIngredientWeightAfterProcessing,
    });

    onChange(quantity, dishSizeBySize, rowIngredient['@id']);
  };

  resetInputFields = () => {
    const { dishSizes, onChange, rowIngredient, size } = this.props;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    this.setState({
      quantity: '',
      kcal: '',
      dishIngredientWeight: '',
      dishIngredientWeightAfterProcessing: '',
    });

    onChange(0, dishSizeBySize, rowIngredient['@id']);
  };

  render() {
    const {
      quantity,
      kcal,
      dishIngredientWeight,
      dishIngredientWeightAfterProcessing,
    } = this.state;
    const { classes, t, dishIngredientsDecimalPlaces } = this.props;
    const inputClasses = classNames({
      [classes.inputSizeCell]: true,
    });

    return (
      <TableCell>
        <div
          style={{
            minWidth: '240px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginRight: '10px' }}>
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {t('columns.qty', 'Ilość')}
              </FormLabel>
            </div>
            <CustomInput
              formControlProps={{ fullWidth: false }}
              inputProps={{
                placeholder: t('columns.qtyPlaceholder', 'Wpisz ilość'),
                value: quantity,
                type: 'number',
                onChange: ({ target: { value: quantity } }) => {
                  if (quantity.length === 0) {
                    this.resetInputFields();
                    return;
                  }

                  if (countDecimal(quantity) <= dishIngredientsDecimalPlaces) {
                    this.handleChange({
                      quantity: quantity >= 0 ? quantity : 0,
                    });
                  }
                },
              }}
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.ingredientsDetails.weight', 'Waga')}
              </FormLabel>
            </div>
            <CustomInput
              formControlProps={{ fullWidth: false }}
              inputProps={{
                placeholder: t(
                  'form.ingredientsDetails.weightPlaceholder',
                  'Wpisz wagę'
                ),
                value: dishIngredientWeight,
                type: 'number',
                onChange: ({ target: { value: weight } }) => {
                  if (weight.length === 0) {
                    this.resetInputFields();
                    return;
                  }

                  const { dishIngredientDefaultWeight } = this.state;
                  const dishIngredientWeight =
                    countDecimal(weight) <= 2
                      ? weight
                      : toDecimalPlaces(weight);

                  const quantity = parseFloat(
                    (
                      dishIngredientWeight / dishIngredientDefaultWeight
                    ).toFixed(dishIngredientsDecimalPlaces)
                  );

                  this.handleChange({
                    quantity: quantity >= 0 ? quantity : 0,
                    override: {
                      dishIngredientWeight,
                    },
                  });
                },
              }}
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.ingredientsDetails.WpO', 'WpO')}
              </FormLabel>
            </div>
            <CustomInput
              formControlProps={{ fullWidth: false }}
              inputProps={{
                placeholder: t(
                  'form.ingredientsDetails.WpOPlacholder',
                  'Wpisz WpO'
                ),
                value: dishIngredientWeightAfterProcessing,
                type: 'number',
                onChange: ({ target: { value: weightAfterProcessing } }) => {
                  if (weightAfterProcessing.length === 0) {
                    this.resetInputFields();
                    return;
                  }

                  const {
                    dishIngredientDefaultWeightAfterProcessing,
                  } = this.state;
                  const dishIngredientWeightAfterProcessing =
                    countDecimal(weightAfterProcessing) <= 2
                      ? weightAfterProcessing
                      : toDecimalPlaces(weightAfterProcessing);

                  const quantity = parseFloat(
                    (
                      dishIngredientWeightAfterProcessing /
                      dishIngredientDefaultWeightAfterProcessing
                    ).toFixed(dishIngredientsDecimalPlaces)
                  );

                  this.handleChange({
                    quantity: quantity >= 0 ? quantity : 0,
                    override: {
                      dishIngredientWeightAfterProcessing,
                    },
                  });
                },
              }}
            />
          </div>
          <div>
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.ingredientsDetails.kcal', 'Kcal')}
              </FormLabel>
            </div>
            <CustomInput
              formControlProps={{ fullWidth: false }}
              inputProps={{
                placeholder: t(
                  'form.ingredientsDetails.kcalPlaceholder',
                  'Wpisz kcal'
                ),
                value: kcal,
                type: 'number',
                onChange: ({ target: { value: kcal } }) => {
                  if (kcal.length === 0) {
                    this.resetInputFields();
                    return;
                  }

                  const { dishIngredientDefaultKcal } = this.state;
                  const dishIngredient = this.getDishIngredient();
                  const weightOfIngredient =
                    dishIngredient?.ingredient?.macrosAfterProcessing?.weight ||
                    100; // This is for ingredient only. Recipies works fine
                  const overrideKcal =
                    countDecimal(kcal) <= 2 ? kcal : toDecimalPlaces(kcal);

                  const quantity = parseFloat(
                    (
                      (overrideKcal / dishIngredientDefaultKcal) *
                      (100 / weightOfIngredient)
                    ).toFixed(dishIngredientsDecimalPlaces)
                  );

                  this.handleChange({
                    quantity: quantity >= 0 ? quantity : 0,
                    override: {
                      kcal: overrideKcal,
                    },
                  });
                },
              }}
            />
          </div>
          <div
            style={{
              marginRight: '10px',
              marginLeft: '10px',
              // border: '1px solid red'
            }}
          >
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.ingredientsDetails.price2', 'Cena')}
              </FormLabel>
            </div>
            <CustomInput
              overrideClasses={{ input: inputClasses }}
              inputProps={{
                disabled: true,
                type: 'number',
                value: this.state.dishIngredientPrice
                  ? ((this.state.dishIngredientPrice * quantity) / 100).toFixed(
                      2
                    )
                  : (this.state.dishRecipePrice * quantity).toFixed(2),
              }}
            />
          </div>
        </div>
      </TableCell>
    );
  }
}

const mapStateToProps = state => ({
  dishIngredientsDecimalPlaces:
    state?.Company?.dietarySetup?.dishIngredientsDecimalPlaces,
});

const combinedStyles = combineStyles(extendedFormsStyle, overrideInputStyle);

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(SizeCell);

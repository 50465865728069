import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { put } from 'helpers/apiHelpers';

import SelectInput from 'components/FormSelect/SelectInput';

import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import TOAST_DURATIONS from 'helpers/toastDurations';

const SourcePicker = ({
  classes,
  orderId,
  sources = [],
  openToast,
  initialValue,
}) => {
  const { t } = useTranslation();

  const [source, setSource] = useState({});

  useEffect(() => {
    const selectedOption = sources.find(
      ({ '@id': iri }) => iri === initialValue
    );

    setSource(selectedOption ?? {});
  }, []);

  const handleSourceChange = obj => {
    put(orderId, { source: obj['@id'] })
      .then(() => {
        if (obj?.['@id'] === null) {
          setSource({});
        }
        setSource(obj);
        openToast({
          messages: [t('success.sourceUpdated', 'Źródło zostało zmienione')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      })
      .catch(({ response: { data } }) => {
        if (data.hasOwnProperty('violations')) {
          openToast({
            messages: data.violations.map(el => el.message),
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      });
  };

  return (
    <div>
      <SelectInput
        classes={classes}
        trackBy={'@id'}
        mapBy={'value'}
        disabled={false}
        options={[
          {
            '@id': null,
            value: t('$*orders.source.noSource', '$$Brak źródła'),
          },
          ...sources,
        ]}
        value={source}
        handleChange={(ev, obj) => handleSourceChange(obj)}
      />
    </div>
  );
};

export default withToast(withStyles(extendedFormsStyle)(SourcePicker));

import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import Comment from '@material-ui/icons/Comment';
// core components
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import columnConfig from './columnConfig';
import DataGrid from '../../../components/DataGrid/DataGrid';

import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Dashboard extends React.Component {
  render() {
    const { t, classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" stats icon>
          <CardIcon color="warning">
            <Comment />
          </CardIcon>
          <p className={classes.cardCategory}>
            <Link to="/admin/rates">{t('dashboard.goToComments')}</Link>
          </p>
          <h3 className={classes.cardTitle}>
            {t('dashboard.lastClientComments')}
          </h3>
        </CardHeader>
        <CardFooter stats>
          <div style={{ width: '100%' }}>
            <DataGrid
              disableTotal
              disableExport
              remove={true}
              paginationTop={false}
              paginationBottom={false}
              url="/rates"
              reportName={'rate'}
              columns={columnConfig({ t })}
              role="RATE"
              minRows={5}
              fixedPageSize={10}
              manipulateQuery={(requestData, query) => {
                query._orX = [];
                if (query.createdAt) {
                  const filters = query.createdAt;
                  delete query.createdAt;
                  if (filters.after) {
                    query['createdAt[after]'] = filters.after;
                  }
                  if (filters.before) {
                    query['createdAt[before]'] = filters.before;
                  }
                }

                if (query.user) {
                  query._orX.push([
                    {
                      'bag.clientDiet.client.firstName': query.user.split(' '),
                      'dishItem.bag.clientDiet.client.firstName': query.user.split(
                        ' '
                      ),
                      'bag.clientDiet.client.lastName': query.user,
                      'dishItem.bag.clientDiet.client.lastName': query.user.split(
                        ' '
                      ),
                    },
                  ]);
                }
                if (query.diet) {
                  query._orX.push([
                    {
                      'bag.diet.name': query.diet,
                      'dishItem.bag.diet.name': query.diet,
                    },
                  ]);
                }
                if (query.date) {
                  query._orX.push([
                    {
                      'bag.date': query.date,
                      'dishItem.bag.date': query.date,
                    },
                  ]);
                }

                query['length_filter[gt][comment]'] = 1;

                return query;
              }}
            ></DataGrid>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(Dashboard));

import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import withRole from 'components/Security/withRole';
import AccessKeysModule from './modules/AccessKeysModule';
import AccessKeyEditModule from './modules/AccessKeyEditModule';
import {
  ROLE_SHOW_BRAND_ACCESS_KEY,
  ROLE_EDIT_BRAND_ACCESS_KEY,
} from 'helpers/roles';

const AccessKeys = props => {
  const { history, match } = props;
  const ProtectedAccessKeysList = withRole(
    ROLE_SHOW_BRAND_ACCESS_KEY,
    AccessKeysModule
  );
  const ProtectedEditForm = withRole(
    ROLE_EDIT_BRAND_ACCESS_KEY,
    AccessKeyEditModule
  );

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ProtectedAccessKeysList {...props} {...parentProps} />
          )}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => (
            <ProtectedEditForm {...props} {...parentProps} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(AccessKeys);

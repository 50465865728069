import { Route, Router, Switch, withRouter } from 'react-router';
import {
  ROLE_CREATE_DICTIONARY,
  ROLE_EDIT_DICTIONARY,
  ROLE_SHOW_DICTIONARY,
} from 'helpers/roles';

import withRole from 'components/Security/withRole';

import List from './List';
import {
  RegularForm,
  ContainerForm,
  BagContainerForm,
  WithPositionForm,
  ClientTagsForm,
} from './Forms';

import DICTIONARIES_TYPES from 'views/Dictionary/dictionariesTypes.const';

const Dictionary = props => {
  const {
    type = DICTIONARIES_TYPES.REGULAR,
    showRole,
    createRole,
    editRole,
    history,
    match,
  } = props;

  const getFormByType = () => {
    switch (type) {
      case DICTIONARIES_TYPES.CONTAINERS:
        return ContainerForm;

      case DICTIONARIES_TYPES.WITH_POSITION:
        return WithPositionForm;

      case DICTIONARIES_TYPES.BAG_CONTAINERS:
        return BagContainerForm;

      case DICTIONARIES_TYPES.CLIENT_TAGS:
        return ClientTagsForm;

      default:
        return RegularForm;
    }
  };

  const ListSecured = withRole(
    showRole ? showRole : ROLE_SHOW_DICTIONARY,
    List
  );

  const CreateForm = withRole(
    createRole ? createRole : ROLE_CREATE_DICTIONARY,
    getFormByType()
  );

  const EditForm = withRole(
    editRole ? editRole : ROLE_EDIT_DICTIONARY,
    getFormByType()
  );

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <CreateForm {...props} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => <EditForm {...props} {...parentProps} />}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(Dictionary);

import React from 'react';
import { TableCell } from '@material-ui/core';

class ElementSumCell extends React.Component {
  render() {
    const {
      dishSizes,
      size,
      element,
      elementConstant,
      subtractElement,
    } = this.props;

    let elementSum = 0;
    let caloriesSum = 0;
    let wpoSum = 0;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    dishSizeBySize.ingredients.forEach(ingredient => {
      if (ingredient.recipe === null) {
        elementSum +=
          (ingredient.ingredient.macrosAfterProcessing[element] *
            ingredient.ingredient.weight *
            ingredient.quantity) /
          100;
        caloriesSum +=
          (ingredient.ingredient.macrosAfterProcessing.calories *
            ingredient.ingredient.weight *
            ingredient.quantity) /
          100;
        wpoSum +=
          ingredient.ingredient.macrosAfterProcessing.weight *
          ingredient.quantity;

        if (subtractElement) {
          elementSum -=
            (ingredient.ingredient.macrosAfterProcessing[subtractElement] *
              ingredient.ingredient.weight *
              ingredient.quantity) /
            100;
        }
      } else if (ingredient.ingredient === null) {
        elementSum +=
          ingredient.recipe.macrosAfterProcessing[element] *
          ingredient.quantity;
        caloriesSum +=
          ingredient.recipe.macrosAfterProcessing.calories *
          ingredient.quantity;
        wpoSum +=
          ingredient.recipe.macrosAfterProcessing.weight * ingredient.quantity;
        if (subtractElement) {
          elementSum -=
            ingredient.recipe.macrosAfterProcessing[subtractElement] *
            ingredient.quantity;
        }
      }
    });

    return (
      <TableCell>
        {`${(elementSum || 0).toFixed(2)} g (${(
          (elementSum / wpoSum) * 100 || 0
        ).toFixed(2)}%)`}
        <br />
        {`${(elementSum * elementConstant || 0).toFixed(2)} kcal (${(
          ((elementSum * elementConstant) / caloriesSum) * 100 || 0
        ).toFixed(2)}%)`}
      </TableCell>
    );
  }
}

export default ElementSumCell;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button.jsx';
import SelectInput from 'components/FormSelect/SelectInput';
import './style.css';

const ContainerReplacementSection = ({
  classes,
  data = [],
  elementId,
  removeItem = () => {},
  closeModal = () => {},
  handleAfterRemove = () => {},
}) => {
  const { t } = useTranslation();
  const [containerToReplace, setContainerToReplace] = useState({
    value: 0,
    name: t('common.lack', '$$Brak'),
  });

  const replacementOptions = [
    { value: 0, name: t('common.lack', '$$Brak') },
    ...data
      .filter(({ '@id': iri }) => iri !== elementId)
      .map(({ '@id': iri, name }) => ({
        name: name,
        value: iri,
      })),
  ];

  const handleSelect = ({ target: { name, value } }) => {
    setContainerToReplace({ name, value });
  };

  const handleRemove = async () => {
    try {
      await removeItem(elementId, {
        data: {
          replace:
            containerToReplace?.value === 0
              ? undefined
              : containerToReplace?.value,
        },
      });
      handleAfterRemove();
      closeModal();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <div>
      <p>
        {t(
          '$*dictionaries.containers.customRemoveBodyText',
          '$$Wybierz pojemnik, który zostanie ustawiony na porcjach dań w miejsce usuwanego:'
        )}
      </p>
      <SelectInput
        noGrid
        classes={classes}
        name="containerReplacementSelect"
        mapBy="name"
        trackBy="value"
        value={containerToReplace}
        options={replacementOptions}
        handleChange={handleSelect}
      />
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}
      >
        <Button
          color={'danger'}
          style={{ margin: '5px' }}
          className={classes.marginRight}
          onClick={closeModal}
        >
          {t('common.no', '$$NIE')}
        </Button>
        <Button
          color={'success'}
          className={classes.marginLeft}
          onClick={handleRemove}
        >
          {t('common.yes', '$$TAK')}
        </Button>
      </div>
    </div>
  );
};

export default ContainerReplacementSection;

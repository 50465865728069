import React from 'react';
import Moment from 'moment';
import Datetime from 'react-datetime';
import { withTranslation } from 'react-i18next';

class DateRange extends React.Component {
  state = {
    after: this.props.value?.after || null,
    before: this.props.value?.before || null,
  };

  onChange = () => {
    this.props.onChange({ after: this.state.after, before: this.state.before });
  };

  render() {
    return (
      <div style={{ display: 'flex', margin: '0 -5px' }}>
        <div style={{ margin: '0 5px' }}>
          <Datetime
            timeFormat={false}
            dateFormat={Moment.localeData().longDateFormat('L')}
            closeOnSelect={true}
            value={new Moment(this.state.after)}
            onChange={async date => {
              if (
                this.state.after !== null &&
                new Date(date).getTime() ===
                  new Date(new Moment(this.state.after)).getTime()
              ) {
                await this.setState({ after: null });
                return this.onChange();
              } else {
                await this.setState({
                  after: date.format('YYYY-MM-DD') + ' 00:00:00',
                });
                this.onChange();
              }
            }}
            inputProps={{
              placeholder: this.props.t('clients.from', 'od'),
              readOnly: true,
            }}
          />
        </div>
        <div style={{ margin: '0 5px' }}>
          <Datetime
            timeFormat={false}
            dateFormat={Moment.localeData().longDateFormat('L')}
            closeOnSelect={true}
            value={new Moment(this.state.before)}
            onChange={async date => {
              this.state.before !== null &&
              new Date(date).getTime() ===
                new Date(new Moment(this.state.before)).getTime()
                ? await this.setState({ before: null })
                : await this.setState({
                    before: date.format('YYYY-MM-DD') + ' 23:59:59',
                  });
              this.onChange();
            }}
            inputProps={{
              placeholder: this.props.t('clients.to', 'do'),
              readOnly: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(DateRange);

import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import Datetime from 'react-datetime';
import { compose } from 'redux';
import { get, post } from 'helpers/apiHelpers';
import { withTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import { withToast } from 'material-ui-toast-redux';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import AppDialogLoader from 'components/DialogLoader/AppDialogLoader';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';

import { fetchSmsTagsForClients } from 'actions/Dictionary';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import TOAST_DURATIONS from 'helpers/toastDurations';

const initialFilters = {
  marketingTerms: {
    trueValue: true,
    falseValue: true,
  },
  hasActiveDiets: {
    trueValue: true,
    falseValue: true,
  },
};

const MAX_MESSAGE_LENGTH = 670;

const filtersTranslations = t => ({
  marketingTerms: {
    trueText: t(
      'massSma.hasConsentFormMarketing',
      'Wyraził zgodę na marketing'
    ),
    falseText: t(
      'massSma.noConsentForMarketing',
      'Nie wyraził zgody na marketing'
    ),
  },
  hasActiveDiets: {
    trueText: t('massSma.hasActiveDiet', 'Ma aktywną dietę'),
    falseText: t('massSma.noActiveDiet', 'Nie ma aktywnej diety'),
  },
});

class Form extends Component {
  state = {
    content: '',
    processAt: new Date(),
    usersAmount: 0,
    isLoading: false,
    filters: {
      ...initialFilters,
    },
    bagDate: false,
    bagZone: false,
    zones: [],
    smsClientTags: [],
    smsClientTagsFunctional: '',
  };

  componentDidMount() {
    this.getCurrentFiltersUsersAmount(this.state);
    Promise.all([this.props.fetchSmsTagsForClients()]);
  }

  handleChange = event => {
    if (event.target.name === 'smsClientTagsFunctional') {
      if (this.state.smsClientTags.length === 0) {
        this.props.openToast({
          messages: [
            this.props.t(
              'massSms.setTagsFunctional.error',
              'Aby edytować to pole konieczne jest wybranie tagów'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
        return null;
      }
      this.setState({ [event.target.name]: event.target.value });
      const newState = {
        ...this.state,
        [event.target.name]: event.target.value,
      };
      return this.getCurrentFiltersUsersAmount(newState);
    }

    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    let smsValidator = true;
    if (
      this.state.smsClientTags.length > 0 &&
      this.state.smsClientTagsFunctional === ''
    ) {
      smsValidator = false;
    }
    return this.state.content && this.state.processAt && smsValidator;
  };

  checkAllFilters = () => {
    const newState = {
      ...this.state,
      filters: {
        ...initialFilters,
      },
    };

    this.setState(newState);
    this.getCurrentFiltersUsersAmount(newState);
  };

  handleBagDateChange = newDate => {
    let newState = { ...this.state };
    if (newDate) {
      newState = { ...newState, bagDate: newDate };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    if (this.state.bagDate === false) {
      const today = new Moment(new Date());
      newState = { ...newState, bagDate: today.format('DD.MM.YYYY') };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    newState = { ...newState, bagDate: false };
    this.setState(newState);
    return this.getCurrentFiltersUsersAmount(newState);
  };

  handleBagZoneChange = newZone => {
    let newState = { ...this.state };

    if (newZone) {
      newState = { ...newState, bagZone: newZone };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    if (this.state.bagZone === false) {
      const firstZone = (this.state.zones ?? [])[0];
      newState = { ...newState, bagZone: firstZone };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    newState = { ...newState, bagZone: false };
    this.setState(newState);
    return this.getCurrentFiltersUsersAmount(newState);
  };

  handleFilters = (filter, valueModifier) => {
    const newState = {
      ...this.state,
      filters: {
        ...this.state.filters,
        [filter]: {
          ...this.state.filters[filter],
          [valueModifier]: !this.state.filters[filter][valueModifier],
        },
      },
    };
    this.setState(newState);
    this.getCurrentFiltersUsersAmount(newState);
  };

  getActiveFilters = filters => {
    const filterKeys = Object.keys(filters);
    const activeFilters = {};

    filterKeys.forEach(key => {
      if (filters[key].trueValue !== filters[key].falseValue) {
        activeFilters[key] = filters[key].trueValue;
      }
    });

    return activeFilters;
  };

  getCurrentFiltersUsersAmount = async state => {
    this.setState({ isLoading: true });
    let zones = [];
    try {
      const zonesPayload = await get('/zones', {
        pagination: false,
        properties: ['id', 'name'],
      });
      zones = (zonesPayload?.['hydra:member'] ?? []).map(
        ({ id: iri, name }) => ({ value: iri, name })
      );
    } catch (e) {}

    get('/client-count-query', {
      pagination: true,
      partial: false,
      itemsPerPage: 1,
      properties: ['_xxx'],
      bagDate: this.state.bagDate ? this.state.bagDate : null,
      bagZone: this.state.bagZone ? this.state.bagZone?.value : null,
      smsClientTags: this.state.smsClientTags,
      smsClientTagsFunctional: this.state.smsClientTagsFunctional,
      type: 'sms',
      'length_filter[gte][phone.number]': 9,
      'length_filter[lte][phone.number]': 12,
      ...this.getActiveFilters(state.filters),
    })
      .then(res => {
        this.setState({
          usersAmount: res,
          zones: zones,
        });
      })
      .catch(e => {
        console.log({ e });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleSubmit = () => {
    const { t, history, openToast } = this.props;
    if (!this.validateForm()) {
      return openToast({
        messages: [t('sms.fillAllFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (this.state.usersAmount === 0) {
      return openToast({
        messages: [t('sms.userListCountZero')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const data = {
      processAt: this.state.processAt,
      content: this.state.content,
      filters: {
        ...this.getActiveFilters(this.state.filters),
        length_filter: {
          gte: { 'phone.number': 9 },
          lte: { 'phone.number': 12 },
        },
        bagDate: this.state.bagDate,
        bagZone: this.state.bagZone,
        smsClientTags: this.state.smsClientTags,
        smsClientTagsFunctional: this.state.smsClientTagsFunctional,
      },
    };

    post('/mass-sms', data).then(
      res => {
        history.push('/admin/mass-sms');
      },
      error =>
        openToast({
          messages: [t('sms.smsNotCreated')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        })
    );
  };

  render() {
    const { classes, t, tags } = this.props;

    return (
      <Fragment>
        {this.state.isLoading && (
          <AppDialogLoader
            text={t('common.loader', 'Trwa ładowanie...')} // common.loader
          />
        )}
        <form>
          <h2>{t('sms.creatingSms')}</h2>
          <AdminTable>
            <GridContainer>
              <GridItem md={6}>
                <GridItem sm={12}>
                  <p>{t('sms.smsTo')}</p>
                  <Button onClick={this.checkAllFilters}>{t('sms.all')}</Button>
                </GridItem>
                {Object.keys(initialFilters).map(filter => {
                  return (
                    <GridItem sm={12} key={filter}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-cy="testttt"
                            checked={this.state.filters[filter].trueValue}
                            onChange={() =>
                              this.handleFilters(filter, 'trueValue')
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={filtersTranslations(t)[filter].trueText}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.filters[filter].falseValue}
                            onChange={() =>
                              this.handleFilters(filter, 'falseValue')
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={filtersTranslations(t)[filter].falseText}
                      />
                    </GridItem>
                  );
                })}
                <GridItem md={12}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.bagDate}
                          onChange={() => this.handleBagDateChange()}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={t('sms.bagDate', 'Którzy mają torbę na dzień:')}
                    />
                    {this.state.bagDate && (
                      <div
                        style={{
                          marginLeft: '35px',
                          width: '100%',
                          maxWidth: '155px',
                        }}
                      >
                        <Datetime
                          timeFormat={false}
                          dateFormat={'YYYY-MM-DD'}
                          closeOnSelect={true}
                          value={this.state.bagDate}
                          onChange={ev =>
                            this.handleBagDateChange(ev.format('DD.MM.YYYY'))
                          }
                          inputProps={{
                            placeholder: t('sms.setDate'),
                            readOnly: true,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </GridItem>
                <GridItem md={12}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.bagZone}
                          onChange={() => this.handleBagZoneChange()}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={t('sms.bagZone', 'Którzy mają torbę w strefie:')}
                    />
                    {this.state.bagZone && (
                      <div
                        style={{
                          marginLeft: '35px',
                          width: '100%',
                          maxWidth: '155px',
                        }}
                      >
                        <SelectInput
                          options={this.state.zones}
                          value={this.state.bagZone}
                          mapBy="name"
                          trackBy="value"
                          classes={classes}
                          handleChange={(e, option) =>
                            this.handleBagZoneChange(option)
                          }
                        />
                      </div>
                    )}
                  </div>
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('sms.tagi.description.title', 'Tagi sms')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    mapBy="value"
                    trackBy="@id"
                    name="smsClientTags"
                    multiple={true}
                    value={this.state.smsClientTags}
                    options={tags}
                    disabled={false}
                    handleChange={event => {
                      this.handleChange(event);
                    }}
                    customStyle={{ width: '50px' }}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'sms.tagi.description',
                      'Zdefiniuj sposób funkcjonowania tagów'
                    )}
                    {this.state.smsClientTags.length > 0 ? ' *' : null}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    mapBy="name"
                    trackBy="value"
                    name="smsClientTagsFunctional"
                    value={this.state.smsClientTagsFunctional}
                    options={[
                      {
                        name: this.props.t(
                          'sms.tagi.description.clear',
                          'Brak'
                        ),
                        value: '',
                      },
                      {
                        name: this.props.t(
                          'sms.tagi.description.send',
                          'Wyślij sms do klientów z wybranymi tagami'
                        ),
                        value: 'YES',
                      },
                      {
                        name: this.props.t(
                          'sms.tagi.description.notSend',
                          'Nie wysyłaj smsów do klientów z wybranymi tagami'
                        ),
                        value: 'NO',
                      },
                    ]}
                    disabled={false}
                    handleChange={event => {
                      this.handleChange(event);
                    }}
                    customStyle={{ width: '50px' }}
                  />
                </GridItem>
                <GridItem md={12}>
                  {t('sms.recCount')}: <b>{this.state.usersAmount}</b>
                </GridItem>
              </GridItem>
              <GridItem md={6}>
                <GridItem sm={12}>
                  <GridItem md={12}>
                    <p>{t('sms.msg')}</p>
                  </GridItem>
                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('sms.deliveryDate')} *
                    </FormLabel>
                    <Datetime
                      timeFormat={true}
                      closeOnSelect={true}
                      value={this.state.processAt}
                      onChange={ev =>
                        this.setState({
                          processAt: ev.format('DD.MM.YYYY HH:mm'),
                        })
                      }
                      isValidDate={day =>
                        day.isAfter(Datetime.moment().subtract(1, 'day'))
                      }
                      inputProps={{
                        placeholder: t('sms.setDate'),
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('sms.content')} *
                    </FormLabel>
                    <FormTextInputNoGrid
                      label={t('sms.content')}
                      classes={classes}
                      name="content"
                      value={this.state.content}
                      handleChange={this.handleChange}
                      maxLength={MAX_MESSAGE_LENGTH}
                      rows={2}
                      rowsMax={30}
                      multiline
                    />
                    <span>
                      {' '}
                      {t('sms.charsLeftToUse', 'Pozostała ilość znaków')}:{' '}
                      {MAX_MESSAGE_LENGTH - this.state.content.length}/
                      {MAX_MESSAGE_LENGTH}
                    </span>
                  </GridItem>
                </GridItem>
              </GridItem>
            </GridContainer>
          </AdminTable>
          <FormControlStickyButton
            isFixedToBottom={true}
            classes={classes}
            discardText={t('sms.cancel')}
            submitText={t('sms.addSms2')}
            cancelPath="/admin/mass-sms"
            handleSubmit={this.handleSubmit}
          />
        </form>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  tags: state.Dictionary.smsClientTags,
});

const mapDispatchToProps = {
  fetchSmsTagsForClients,
};

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Form);

import { Component } from 'react';

import ErrorView from './ErrorView/ErrorView';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? <ErrorView /> : this.props.children;
  }
}

export default ErrorBoundary;

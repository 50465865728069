import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React from 'react';
import Card from 'components/Card/Card';
import SlotsSectionSettings from './componentsSlotsSettings/SlotsSectionSettings';
import RatesMenuSectionSettings from './componentsSlotsSettings/RatesMenuSectionSettings';
import DisabledDaysSectionSettings from './componentsSlotsSettings/DisabledDaysSectionSettings';

const SlotsSettings = ({
  t,
  daysOfWeek,
  dayFields,
  classes,
  state,
  handleRatingTimeChange,
  descriptionDays,
  handleRatingDayChange,
  handleModuleUpdate,
  modules,
  translateDays,
  translateFields,
  myStyle,
  setState,
  returnTableCell,
  handleInputChange,
}) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} style={{ marginBottom: '50px' }}>
            <SlotsSectionSettings
              classes={classes}
              t={t}
              daysOfWeek={daysOfWeek}
              dayFields={dayFields}
              translateDays={translateDays}
              translateFields={translateFields}
              returnTableCell={returnTableCell}
            />
            <RatesMenuSectionSettings
              classes={classes}
              t={t}
              state={state}
              handleRatingTimeChange={handleRatingTimeChange}
              descriptionDays={descriptionDays}
              handleRatingDayChange={handleRatingDayChange}
              setState={setState}
              myStyle={myStyle}
            />
            <DisabledDaysSectionSettings
              state={state}
              setState={setState}
              classes={classes}
              t={t}
              modules={modules}
              handleModuleUpdate={handleModuleUpdate}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default SlotsSettings;

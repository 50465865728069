import { useTranslation } from 'react-i18next';

import { Card, CardContent } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { blackColor, goldColor } from 'assets/jss/material-dashboard-pro-react';

import HomeIcon from './HomeIcon/HomeIcon';
import RefreshIcon from './RefreshIcon/RefreshIcon';

const leftSpacing = '48px';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: blackColor,
  },
  textContent: {
    display: 'grid',
    gap: '20px',
    fontSize: '18px',
    fontWeight: '400',
  },
  header: {
    fontSize: '30px',
    fontWeight: '700',
    margin: `0 0 48px ${leftSpacing}`,
  },
  card: {
    width: '100%',
    maxWidth: '48rem',
    padding: '28px 60px',
    borderRadius: '15px',
  },
  anchor: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    width: leftSpacing,
    flexShrink: '0',
  },
  separator: {
    marginLeft: leftSpacing,
  },
});

const ErrorView = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <CardContent>
          <h1 className={classes.header}>
            {t('error.pageLoadingErrorOccurred')}
          </h1>
          <p className={classes.textContent}>
            <a
              className={classes.anchor}
              href="#"
              onClick={e => {
                e.preventDefault();

                window.location.reload();
              }}
            >
              <RefreshIcon className={classes.icon} color={goldColor} />
              {t('error.refreshPage')}
            </a>
            <span className={classes.separator}>{t('error.or')}</span>
            <a className={classes.anchor} href="/">
              <HomeIcon className={classes.icon} color={goldColor} />
              {t('error.goToMainPage')}
            </a>
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default ErrorView;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import AssignmentReturned from '@material-ui/icons/AssignmentReturned';
import makeStyles from '@material-ui/styles/makeStyles';
import { withToast } from 'material-ui-toast-redux';

import { get, post, put } from 'helpers/apiHelpers';
import AdminTable from 'layouts/AdminTable';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import useGetColumns from './useGetColumns';
import AssignDriverModal from './AssignDriverModal';
import AssignZonesSection from './AssignZonesSection';
import AssignZonesWithClearAllSection from './AssignZonesWithClearAllSection';

import TOAST_DURATIONS from 'helpers/toastDurations';

const useStyles = makeStyles(buttonsStyle);

const List = ({ openToast }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = useGetColumns();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [zone, setZone] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [gridProps, setGridProps] = useState(null);

  const fetchZone = zone => {
    setIsLoading(true);
    get(zone).then(
      res => {
        setZone(res);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        openToast({
          messages: [t('addresses.cannotDownloadZone')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAddress(null);
    setZone(null);
    setSelectedDriver(null);
  };

  const openModal = (row, props) => {
    if (row.zone) {
      fetchZone(row.zone['@id']);
    }

    setGridProps(props);
    setIsModalOpen(true);
    setSelectedAddress(row);
    setSelectedDriver(row.driver);
  };

  const handleDriverSelect = (e, driver) => {
    setSelectedDriver(driver);
  };

  const handleDriverChange = () => {
    setIsLoading(true);
    put(selectedAddress['@id'], { driver: selectedDriver['@id'] }).then(
      () => {
        gridProps.grid.current.fireFetchData();
        closeModal();
        setIsLoading(false);
        openToast({
          messages: [t('addresses.changedDriver')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      },
      () => {
        setIsLoading(false);
        openToast({
          messages: [t('addresses.cantChangeDriver')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };

  const handleAssignZones = () => {
    setIsLoading(true);
    post('/actions/assign-zone-to-addresses').then(
      () => {
        setIsLoading(false);
        openToast({
          messages: [t('addresses.mapZones'), t('addresses.mapZones2')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      },
      () => {
        setIsLoading(false);
        openToast({
          messages: [t('addresses.cantMap')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };

  const handleAssignZonesWithClearAll = () => {
    setIsLoading(true);
    post('/actions/assign-zone-to-addresses?clearAll=true').then(
      () => {
        setIsLoading(false);
        openToast({
          messages: [t('addresses.mapZones'), t('addresses.mapZones2')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      },
      () => {
        setIsLoading(false);
        openToast({
          messages: [t('addresses.cantMap')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    );
  };

  const manipulateQuery = (requestData, query) => {
    return query;
  };

  const customActions = row => {
    return [
      {
        action: props => {
          openModal(row, props);
        },
        icon: <AssignmentReturned />,
        disabled: false,
        color: 'primary',
        buttonText: t('addresses.changeDriver'),
        simple: true,
        style: { width: 'auto' },
      },
    ];
  };

  return (
    <AdminTable title={t('addresses.list')}>
      <AssignDriverModal
        classes={classes}
        isLoading={isLoading}
        modalOpen={isModalOpen}
        closeModal={closeModal}
        selectedAddress={selectedAddress}
        selectedDriver={selectedDriver}
        handleDriverSelect={handleDriverSelect}
        handleDriverChange={handleDriverChange}
        zone={zone}
      />
      <GridContainer>
        <GridItem>
          <AssignZonesSection
            disabled={isLoading}
            handleAssignZones={handleAssignZones}
          />
        </GridItem>
        <GridItem>
          <AssignZonesWithClearAllSection
            disabled={isLoading}
            handleAssignZones={handleAssignZonesWithClearAll}
          />
        </GridItem>
      </GridContainer>
      <Divider />
      <DataGrid
        refreshOnFetch
        actions={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        customActions={customActions}
        customActionsWidth={200}
        url="/addresses"
        reportName="address"
        role="ADDRESSES"
        columns={columns}
        manipulateQuery={manipulateQuery}
        customActionsColumnDirection="actions-left"
      />
    </AdminTable>
  );
};

export default withToast(List);

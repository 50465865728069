import React, { Fragment } from 'react';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';

import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import CardWrapper from 'components/Card/CardWrapper';

const FormIngredientsShopingInformation = ({
  t,
  salesOptions,
  classes,
  state,
  handleWeightChange,
  salesSelectsOptions,
  handleChange,
  currency,
  additionalSalesOptions,
}) => {
  return (
    <GridItem xs={12}>
      <CardWrapper title={t('form.field.shoppingInfo')}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={3}>
                  {salesOptions(currency)?.map((option, index) => (
                    <Fragment key={index}>
                      <FormLabel
                        style={{ marginTop: '8px' }}
                        className={classes.labelHorizontal}
                      >
                        {' '}
                        {option.label}{' '}
                      </FormLabel>
                      <CustomInput
                        disabled={state?.products?.length > 0}
                        id={option?.id}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: option?.type,
                          name: option?.name,
                          onChange: handleWeightChange,
                          value: option?.value,
                        }}
                      />
                    </Fragment>
                  ))}
                  {salesSelectsOptions?.map((option, index) => {
                    return (
                      <option.Component
                        key={index}
                        classes={classes}
                        label={option.label}
                        options={option.options}
                        value={option.value}
                        trackBy={option.trackBy}
                        mapBy={option.mapBy}
                        handleChange={handleChange}
                        name={option.name}
                        id={option.id}
                        labelSm={3}
                        selectSm={4}
                      />
                    );
                  })}
                </GridItem>
                <GridItem md={3}>
                  {additionalSalesOptions.map((option, index) => (
                    <Fragment key={index}>
                      <FormLabel
                        style={{ marginTop: '8px' }}
                        className={classes.labelHorizontal}
                      >
                        {option.label}
                      </FormLabel>
                      <CustomInput
                        id={option.id}
                        disabled={option?.disabled}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: option.type,
                          name: option.name,
                          onChange: handleChange,
                          value: option.value,
                        }}
                      />
                    </Fragment>
                  ))}

                  <FormSelectSingle
                    classes={classes}
                    label={t('form.field.contractor.label')}
                    options={state.contractors}
                    value={state.contractor}
                    trackBy="@id"
                    mapBy="name"
                    handleChange={handleChange}
                    name="contractor"
                    id="contractor"
                    labelSm={3}
                    selectSm={4}
                  />
                </GridItem>
                <GridItem md={3}>
                  {t('form.field.priceAverage', {
                    replace: { currency: currency },
                  })}
                  : <strong>{state.priceAverage}</strong>
                </GridItem>
                <GridItem md={3}>
                  {t('form.field.priceAverageWeighted', {
                    replace: { currency: currency },
                  })}
                  : <strong>{state.priceAverageWeighted}</strong>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </GridItem>
  );
};

export default FormIngredientsShopingInformation;

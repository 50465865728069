import React from 'react';
import { TableCell } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { withTranslation } from 'react-i18next';

class SizeCell extends React.Component {
  state = {
    quantity: 0,
    kcal: 0,
    dishIngredientWeight: 0,
    dishIngredientWeightAfterProcessing: 0,
    dishIngredientDefaultKcal: 0,
    dishIngredientDefaultWeight: 0,
    dishIngredientDefaultWeightAfterProcessing: 0,
  };

  componentDidMount() {
    const dishIngredient = this.getDishIngredient();
    const defaultKcal = this.getDefaultKcal({ dishIngredient });
    const defaultWeight = this.getDefaultWeight({ dishIngredient });
    const defaultWeightAfterProcessing = this.getDefaultWeightAfterProcessing({
      dishIngredient,
    });

    this.setState({
      quantity: parseFloat(parseFloat(dishIngredient.quantity).toFixed(2)),
      kcal: parseFloat((dishIngredient.quantity * defaultKcal).toFixed(2)),
      dishIngredientWeight: parseFloat(
        (dishIngredient.quantity * defaultWeight).toFixed(2)
      ),
      dishIngredientWeightAfterProcessing: parseFloat(
        (dishIngredient.quantity * defaultWeightAfterProcessing).toFixed(2)
      ),
      dishIngredientDefaultKcal: defaultKcal,
      dishIngredientDefaultWeight: defaultWeight,
      dishIngredientDefaultWeightAfterProcessing: defaultWeightAfterProcessing,
    });
  }

  componentWillReceiveProps(nextProps) {
    const currentDishIngredient = this.getDishIngredient(this.props);
    const nextDishIngredient = this.getDishIngredient(nextProps);

    if (nextDishIngredient.quantity !== currentDishIngredient.quantity) {
      const { quantity } = nextDishIngredient;

      this.setState(prevState => ({
        quantity: parseFloat(parseFloat(quantity).toFixed(2)),
        kcal: parseFloat(
          (quantity * prevState.dishIngredientDefaultKcal).toFixed(2)
        ),
        dishIngredientWeight: parseFloat(
          (quantity * prevState.dishIngredientDefaultWeight).toFixed(2)
        ),
        dishIngredientWeightAfterProcessing: parseFloat(
          (
            quantity * prevState.dishIngredientDefaultWeightAfterProcessing
          ).toFixed(2)
        ),
      }));
    }
  }

  getDishIngredient = (props = this.props) => {
    const { dishSizes, size, rowIngredient } = props;
    let dishIngredient;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    if (dishSizeBySize) {
      dishIngredient = dishSizeBySize.ingredients.find(sizeIngredient => {
        return (
          (sizeIngredient.recipe !== null &&
            sizeIngredient.recipe['@id'] === rowIngredient['@id']) ||
          (sizeIngredient.ingredient !== null &&
            sizeIngredient.ingredient['@id'] === rowIngredient['@id'])
        );
      });
    }

    return dishIngredient;
  };

  getDefaultKcal = ({ dishIngredient }) => {
    const kcal = !dishIngredient.recipe
      ? dishIngredient.ingredient.macrosAfterProcessing.calories
      : dishIngredient.recipe.macrosAfterProcessing.calories;

    return parseFloat(kcal.toFixed(2));
  };

  getDefaultWeight = ({ dishIngredient }) => {
    const weight = !dishIngredient.recipe
      ? dishIngredient.ingredient.weight
      : dishIngredient.recipe.weight;

    return parseFloat(weight.toFixed(2));
  };

  getDefaultWeightAfterProcessing = ({ dishIngredient }) => {
    const weight = !dishIngredient.recipe
      ? dishIngredient.ingredient.macrosAfterProcessing.weight
      : dishIngredient.recipe.macrosAfterProcessing.weight;

    return weight ? parseFloat(weight.toFixed(2)) : 0;
  };

  handleChange = ({ quantity, ovverride = {} }) => {
    const { dishSizes, onChange, rowIngredient, size } = this.props;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    const {
      dishIngredientDefaultKcal,
      dishIngredientDefaultWeight,
      dishIngredientDefaultWeightAfterProcessing,
    } = this.state;

    const kcal = ovverride.kcal
      ? ovverride.kcal
      : parseFloat((quantity * dishIngredientDefaultKcal).toFixed(2));

    const dishIngredientWeight = ovverride.dishIngredientWeight
      ? ovverride.dishIngredientWeight
      : parseFloat((quantity * dishIngredientDefaultWeight).toFixed(2));

    const dishIngredientWeightAfterProcessing = ovverride.dishIngredientWeightAfterProcessing
      ? ovverride.dishIngredientWeightAfterProcessing
      : parseFloat(
          (quantity * dishIngredientDefaultWeightAfterProcessing).toFixed(2)
        );

    this.setState({
      quantity,
      kcal,
      dishIngredientWeight,
      dishIngredientWeightAfterProcessing,
    });

    onChange(quantity, dishSizeBySize, rowIngredient['@id']);
  };

  render() {
    const { quantity } = this.state;
    const { classes } = this.props;

    return (
      <TableCell>
        <div
          style={{
            minWidth: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginRight: '10px' }}>
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('columns.qty')}
              </FormLabel>
            </div>
            <CustomInput
              formControlProps={{ fullWidth: false }}
              inputProps={{
                placeholder: 'Wpisz ilość',
                value: quantity,
                type: 'number',
                onChange: ({ target: { value: quantity } }) => {
                  this.handleChange({ quantity: quantity >= 0 ? quantity : 0 });
                },
              }}
            />
          </div>
        </div>
      </TableCell>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle);

export default withTranslation()(withStyles(combinedStyles)(SizeCell));

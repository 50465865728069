import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class Disposal extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.disposal');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/accountant/disposal"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_DISPOSAL}
        pdfAvailable={false}
        excelAvailable={true}
        csvAvailable={true}
        dateType="range"
        archiveReportNames={['DISPOSAL']}
      />
    );
  }
}

export default withTranslation()(Disposal);

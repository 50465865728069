import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';
// core components
import Button from 'components/CustomButtons/Button.jsx';
import { withTranslation } from 'react-i18next';

class MediaUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleMediaChange = this.handleMediaChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleMediaChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
      });
    };
    this.props.setFile && this.props.setFile(file);
    file && reader.readAsDataURL(file);
  }

  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
    });
    this.refs.fileInput.value = null;
  }
  render() {
    var {
      avatar,
      buttonText,
      changeButtonText,
      isIcon,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      t,
    } = this.props;
    return (
      <div
        style={{ maxWidth: 'inherit', padding: 'inherit' }}
        className="fileinput text-center"
      >
        <b>{t('common.inmportFile', 'Plik importu')}:</b>{' '}
        {this.state.file
          ? this.state.file.name
          : t('common.noFileChoosen', 'Nie wybrano pliku')}
        <input
          type="file"
          onChange={this.handleMediaChange}
          ref="fileInput"
          accept="text/plain, .csv, .xlsx"
        />
        <div
          style={{
            display: isIcon ? 'flex' : 'block',
            justifyContent: isIcon ? 'center' : 'inherit',
          }}
        >
          {this.state.file === null ? (
            <span>
              <Button {...addButtonProps} onClick={() => this.handleClick()}>
                {buttonText ? buttonText : 'Wybierz plik'}
              </Button>
              {this.state.file && (
                <Button
                  {...removeButtonProps}
                  onClick={() => this.handleRemove()}
                >
                  <i className="fas fa-times" /> Usuń
                </Button>
              )}
            </span>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                {changeButtonText ? changeButtonText : 'Zmień'}
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> Usuń
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

MediaUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

export default withTranslation()(MediaUpload);

import React from 'react';
import ratesColumnConfig from './ratesColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';
import { withTranslation } from 'react-i18next';
const Rates = ({ userId, t }) => {
  return (
    <DataGrid
      hideArchive={true}
      actions={true}
      paginationTop={true}
      paginationBottom={false}
      url="/client-rates"
      reportName={'bags'}
      role={'ORDER'}
      columns={ratesColumnConfig(t)}
      minRows={18}
      manipulateQuery={(requestData, query) => {
        query['id'] = userId;
        return query;
      }}
    />
  );
};

export default withTranslation()(Rates);

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { combineStyles, isGranted } from 'helpers/helpers';
import { useState } from 'react';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import Banner from '../HomePage/Banner';
import DataGrid from 'components/DataGrid/DataGrid';
import AdminTable from 'layouts/AdminTable';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import columnConfig from './columnConfig';
import { put, get } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { useEffect } from 'react';
import {
  ROLE_REMOVE_BLOG_POST,
  ROLE_EDIT_BLOG_POST,
  ROLE_CREATE_BLOG_POST,
} from 'helpers/roles';
import PresentationUrl from 'components/CMS/PresentationUrl';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';

const initialState = {
  showBlogPage: false,
  enabled: false,
  content: '',
  heading: '',
  bannerDesktop: null,
  bannerDesktopUrl: null,
  bannerMobile: null,
  bannerMobileUrl: null,
  image: null,
  imageUrl: null,
  indexing: false,
  marketingTitle: '',
  metaDescription: '',
  openGraphDescription: '',
  openGraphTitle: '',
};

const Blog = ({ classes, selectedBrand, openToast, history }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(initialState);

  const newPost = () => history.push(`${window.location.pathname}/add`);
  const [enabledStateWithApi, setEnabledStateWithApi] = useState(false);

  useEffect(() => {
    get(`brands/${selectedBrand}/modules`).then(response => {
      const { Blog } = response?.configuration;
      setState(prev => ({
        ...prev,
        showBlogPage: Blog?.showBlogPage,
        enabled: Blog?.enabled,
        content: Blog?.content,
        heading: Blog?.heading,
        bannerDesktop: Blog?.bannerDesktop,
        bannerDesktopUrl: Blog?.bannerDesktopUrl,
        bannerMobile: Blog?.bannerMobile,
        bannerMobileUrl: Blog?.bannerMobileUrl,
        image: Blog?.image,
        imageUrl: Blog?.imageUrl,
        indexing: Blog?.indexing,
        marketingTitle: Blog?.marketingTitle,
        metaDescription: Blog?.metaDescription,
        openGraphDescription: Blog?.openGraphDescription,
        openGraphTitle: Blog?.openGraphTitle,
      }));
      setEnabledStateWithApi(Blog?.enabled);
    });
  }, []);

  const handleSubmit = () => {
    put(`brands/${selectedBrand}/modules`, {
      configuration: {
        Blog: {
          showBlogPage: state?.showBlogPage,
          enabled: state?.enabled,
          content: state?.content,
          heading: state?.heading,
          bannerDesktop: state?.bannerDesktop,
          bannerDesktopUrl: state?.bannerDesktopUrl,
          bannerMobile: state?.bannerMobile,
          bannerMobileUrl: state?.bannerMobileUrl,
          image: state?.image,
          imageUrl: state?.imageUrl,
          indexing: state?.indexing,
          marketingTitle: state?.marketingTitle,
          metaDescription: state?.metaDescription,
          openGraphDescription: state?.openGraphDescription,
          openGraphTitle: state?.openGraphTitle,
        },
      },
    }).then(response => {
      openToast({
        messages: [
          t('status.success.save', 'Zmiany zostały pomyślnie zapisane.'),
        ],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    });
  };

  return (
    <>
      <CardWrapper title={t('form.basicInfo')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <LabeledCheckbox
                name="active"
                onClick={() =>
                  setState(prev => ({
                    ...prev,
                    showBlogPage: !state.showBlogPage,
                  }))
                }
                checked={state.showBlogPage}
                label={<div>{t('cms.homePage.showHomePage')} </div>}
              />
            </GridItem>
            {state?.enabled && (
              <GridItem xs={12}>
                <PresentationUrl
                  subpage="/blog"
                  cmsShowActionButton={enabledStateWithApi}
                />
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <Banner number={1} state={state} setState={setState} page="blog" />

      <AdminTable title={t('blog.list')}>
        {isGranted(ROLE_CREATE_BLOG_POST) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={() => {
              newPost();
            }}
            submitText={t('blog.addPost') + '+'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={
            isGranted(ROLE_EDIT_BLOG_POST) && `${window.location.pathname}/edit`
          }
          remove={isGranted(ROLE_REMOVE_BLOG_POST)}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/blog-posts"
          reportName={'blogpost'}
          columns={columnConfig}
          role="BLOG"
          minRows={20}
          defaultSorted={[{ id: 'position', desc: false }]}
        />
      </AdminTable>
      <SEOSettingsCms
        state={state}
        setState={setState}
        moduleConfiguration={false}
        classes={classes}
        section="Blog"
      />
      {isGranted(ROLE_EDIT_BLOG_POST) && (
        <FormControlButtons
          classes={classes}
          submitText={t('form.save')}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};
const enchance = compose(
  withToast,
  connect(mapStateToProps, {}),
  withStyles(combineStyles(extendedFormsStyle))
);
export default enchance(Blog);

import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import ShowChart from '@material-ui/icons/ShowChart';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import { get } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartCard = ({ classes, selectedBrand }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    data: {
      labels: [
        t('months.short.Jan'),
        t('months.short.Feb'),
        t('months.short.Mar'),
        t('months.short.Apr'),
        t('months.short.May'),
        t('months.short.Jun'),
        t('months.short.Jul'),
        t('months.short.Aug'),
        t('months.short.Sep'),
        t('months.short.Oct'),
        t('months.short.Nov'),
        t('months.short.Dec'),
      ],
      datasets: [
        {
          label: '2021',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: classes.info,
        },
        {
          label: '2022',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: classes.danger,
        },
        {
          label: '2023',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: classes.warning,
        },
      ],
    },
  });

  const options = {
    responsive: true,
    gridLineOptions: {
      display: false,
    },
    scales: {
      x: {
        border: { display: false },
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      datalabels: { display: false },
    },
  };

  useEffect(() => {
    getIncomeHistory();
  }, []);

  const getIncomeHistory = () => {
    get(`/statistics/ecommerce/income/year`, {
      brandId: [selectedBrand],
      date: Moment(new Date()).format('YYYY-MM-DD'),
    }).then(res => getData(res));
    return;
  };

  const getData = incomeData => {
    const { currentYear, lastYear, twoYearsAgo } = incomeData;
    if (currentYear && lastYear) {
      const newChartData = {
        data: {
          labels: [
            t('months.short.Jan'),
            t('months.short.Feb'),
            t('months.short.Mar'),
            t('months.short.Apr'),
            t('months.short.May'),
            t('months.short.Jun'),
            t('months.short.Jul'),
            t('months.short.Aug'),
            t('months.short.Sep'),
            t('months.short.Oct'),
            t('months.short.Nov'),
            t('months.short.Dec'),
          ],
          datasets: [
            {
              label: new Date().getFullYear() - 2,
              data: twoYearsAgo['hydra:member'] ?? twoYearsAgo ?? [],
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
              label: new Date().getFullYear() - 1,
              data: lastYear['hydra:member'] ?? lastYear ?? [],
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: new Date().getFullYear(),
              data: currentYear['hydra:member'] ?? currentYear ?? [],
              backgroundColor: 'rgba(255, 152, 0, 0.5)',
            },
          ],
        },
      };

      setChartData(newChartData);
    }
  };

  return (
    <Card>
      <CardHeader color="warning" quickStats icon>
        <CardIcon color="warning">
          <ShowChart />
        </CardIcon>
        <h3 className={classes.quickStatsTitle}>
          {t('dashboard.incomeStats')}
        </h3>
      </CardHeader>
      <CardBody>
        <div class="barChart" style={{ marginTop: '15px' }}>
          <Bar
            options={options}
            data={chartData?.data}
            style={{ maxHeight: '350px' }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(dashboardStyle);

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(BarChartCard));

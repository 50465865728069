import types from 'helpers/constants';

const initialState = {
  roles: [],
  role: {},
};

function Roles(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };
    case types.FETCH_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case types.REMOVE_ROLE:
      return {
        ...state,
        roles: state.roles.filter(role => role.id !== action.payload.roleId),
      };
    default:
      return state;
  }
}

export default Roles;

import { DialogLoader } from 'components/DialogLoader';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import Config from '../../sections/Config';
import Excludes from '../../sections/Excludes';
import BasicInfo from '../../sections/BasicInfo';
import BasicInfoCard from 'components/CMS/BasicInfoCard';
import InfoBannerCard from 'components/CMS/InfoBannerCard';
import DescriptionCard from 'components/CMS/DescriptionCard';
import DietAssumptions from '../../sections/DietAssumptions';
import ContainerSelect from '../../sections/ContainerSelect';
import DeliveriesAndOrders from '../../components/DeliveriesAndOrders';
import ClientsPanelInterface from '../../sections/ClientsPanelInterface';

import DietFlowStatistics from '../../DietFlowStatistics';
import NavTabs from 'components/NavTabs/NavTabs';

const FormDietsView = props => {
  const {
    t,
    classes,
    state,
    setFormDietsState,
    getImage,
    removeImage,
    handleSubmit,
    handleChange,
    setDietSubpageData,
    handleBannerCheckboxChange,
    isEdit,
    dietId,
    allergens,
    ingredientCategories,
    isFoodsiEnabled,
  } = props;

  return (
    <>
      <DialogLoader
        loaderState={state.isSubmitting}
        text={t('form.savingChanges')}
      />
      <DialogLoader
        loaderState={state.isLoading}
        text={t('form.loadingDiet')}
      />
      <NavTabs
        title={isEdit ? t('form.editDiet') : t('form.addDiet')}
        tabs={[
          {
            tabButton: t('views.diets.clientPanel'),
            tabContent: (
              <>
                <BasicInfo {...props} />
                <ClientsPanelInterface {...props} />
                <Excludes
                  allergens={allergens}
                  ingredientCategories={ingredientCategories}
                  {...props}
                />
                <DietAssumptions {...props} />
                <Config {...props} />
                <ContainerSelect
                  {...props}
                  bagContainer={state.bagContainer}
                  numberOfBags={state.numberOfBags}
                  isFoodsiEnabled={isFoodsiEnabled}
                  setFormDietsState={setFormDietsState}
                />
                <FormControlButtons
                  classes={classes}
                  discardText={t('form.cancel')}
                  submitText={t('form.save')}
                  cancelPath="/admin/diets"
                  handleSubmit={handleSubmit}
                />
              </>
            ),
          },
          {
            tabButton: t('views.diets.dietSubpage'),
            tabContent: (
              <>
                <BasicInfoCard
                  urlSlug={state.urlSlug}
                  metaTitle={state.metaTitle}
                  handleChange={handleChange}
                  metaDescription={state.metaDescription}
                  subpage="/diets/"
                />
                <InfoBannerCard
                  infoBannerCheckbox={state.bannerEnabled}
                  bannerHeader={state.bannerHeader}
                  bannerText={state.bannerText}
                  handleInputChange={handleChange}
                  handleCheckboxChange={handleBannerCheckboxChange}
                  getImage={getImage}
                  removeImage={removeImage}
                  infoBannerMobileUrl={state.infoBannerMobileUrl}
                  infoBannerDesktopUrl={state.infoBannerDesktopUrl}
                />
                <DescriptionCard
                  value={state.dietDescription}
                  setValue={e => setDietSubpageData(e, 'dietDescription')}
                  title={t('views.diets.dietDescription')}
                />
                <FormControlButtons
                  classes={classes}
                  discardText={t('form.cancel')}
                  submitText={t('form.save')}
                  cancelPath="/admin/diets"
                  handleSubmit={handleSubmit}
                />
              </>
            ),
          },
          ...(isEdit
            ? [
                {
                  tabButton: t(
                    'views.diets.dietFlowStatistics',
                    'Statystyki flow'
                  ),
                  tabContent: <DietFlowStatistics dietId={dietId} />,
                },
              ]
            : []),
          {
            tabButton: t('views.diets.timeSettings', 'Sloty czasowe'),
            tabContent: (
              <>
                <DeliveriesAndOrders
                  state={state}
                  setState={newState =>
                    setFormDietsState(prev => ({ ...prev, ...newState }))
                  }
                />
                <FormControlButtons
                  classes={classes}
                  discardText={t('form.cancel')}
                  submitText={t('form.save')}
                  cancelPath="/admin/diets"
                  handleSubmit={handleSubmit}
                />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FormDietsView;

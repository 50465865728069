import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';

const PayNowModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const paynow = useCheckIsPaymentDisabled('PAYNOW');

  useEffect(() => {
    if (paynow.isSelected) {
      handleModuleUpdate({
        ...module,
        enabled: true,
      });
    }
  }, [paynow.isSelected]);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayNowModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={11}>
          <h3 style={{ marginLeft: '15px' }}>
            {t('paymentType.title.PayNow', 'Ustawienia PayNow')}
          </h3>
        </GridItem>
        <GridItem xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
          <div
            style={{
              height: '100%',
              width: '55%',
              background: 'RGB(255,152,0)',
            }}
          ></div>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <FormTextInput
          label="Api Key"
          classes={classes}
          customInput={FormTextInput}
          value={module.apiKey}
          onChange={e =>
            handleModuleUpdate({ ...module, apiKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Signature Key"
          classes={classes}
          customInput={FormTextInput}
          value={module.signatureKey}
          onChange={e =>
            handleModuleUpdate({ ...module, signatureKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayNowModule;

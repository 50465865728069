import { chain } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const translateOrderStatuses = t => [
  {
    systemValue: null,
    systemLabel: '',
    label: t('common.all', 'Wszystkie'),
    order: 0,
  },
  {
    systemValue: 'WAITING_FOR_PAYMENT',
    systemLabel: 'Oczekujące',
    label: t('common.paymentStatus.waiting', 'Oczekujące'),
    order: 1,
  },
  {
    systemValue: 'PAID',
    systemLabel: 'Opłacone',
    label: t('common.paymentStatus.payed', 'Opłacone'),
    order: 2,
  },
  {
    systemValue: 'CANCELED',
    systemLabel: 'Anulowane',
    label: t('common.paymentStatus.cancelled', 'Anulowane'),
    order: 3,
  },
];

export const useTranslatedOrderStatuses = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const st = chain(translateOrderStatuses(t))
      .filter(x => x.systemValue)
      .map(({ systemValue, label }) => [systemValue, label])
      .fromPairs()
      .value();
    return st;
  }, [t]);
};

const { useTranslation, Trans } = require('react-i18next');

const CreatedBy = ({
  isCreatedByEmployee,
  dietCreator,
  createdDate,
  createdTime,
}) => {
  const { t } = useTranslation();

  if (!dietCreator) {
    return null;
  }

  const createdBy = isCreatedByEmployee
    ? t('common.employeeGen', 'pracownika')
    : t('common.clientGen', 'klienta');

  const { actor, impersonator, associatedClient } = dietCreator;

  const getPerson = person => {
    return person
      ? `${person.firstName} ${person.lastName} ${person.email}`
      : '';
  };

  return (
    <div
      style={{
        marginBottom: '10px',
      }}
    >
      <Trans
        i18nKey="dietDetails.creationDate"
        createdDate={createdDate}
        createdTime={createdTime}
        createdBy={createdBy}
        actor={actor}
      >
        Dieta utworzona <b>{{ createdDate }}</b> o godzinie{' '}
        <b>{{ createdTime }}</b> przez{' '}
        <b>
          {{ createdBy }} {getPerson(impersonator || actor || associatedClient)}
        </b>
      </Trans>
    </div>
  );
};

export default CreatedBy;

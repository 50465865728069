import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './Users';
import UserForm from './UserForm';
import AddUserForm from './AddUserForm';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_USER,
  ROLE_EDIT_USER,
  ROLE_SHOW_USER,
} from 'helpers/roles';

class Users extends Component {
  render() {
    const props = this.props;

    const EditForm = withRole(ROLE_EDIT_USER, UserForm);
    const CreateForm = withRole(ROLE_CREATE_USER, AddUserForm);
    const ListSecured = withRole(ROLE_SHOW_USER, List);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Users);

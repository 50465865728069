import React from 'react';
import MailNotificationCardSectionContainer from './MailNotificationCardSection.container';

const NotificationsCard = ({
  mailForAdmin,
  title,
  mailing,
  defaultConfigFromBrand,
  mailingOptions,
  setAllowToChangedElements,
}) => {
  return (
    <MailNotificationCardSectionContainer
      mailForAdmin={mailForAdmin}
      title={title}
      mailing={mailing}
      defaultConfigFromBrand={defaultConfigFromBrand}
      mailingOptions={mailingOptions}
      setAllowToChangedElements={setAllowToChangedElements}
    />
  );
};

export default NotificationsCard;

import isEmpty from 'lodash/isEmpty';

import React, { useEffect, useState } from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { isFired, enable, disable } from 'helpers/systemFlagManager';
import CircularProgress from '@material-ui/core/CircularProgress';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

const SystemFlagBottomAction = ({
  disabled,
  label,
  factoryName,
  confirmation = {},
  factoryParams,
}) => {
  const [loading, setIsLoading] = useState(true);
  const [isFlagFired, setIsFlagFired] = useState(false);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] =
    useState(false);

  useEffect(() => {
    setIsLoading(true);
    isFired(factoryName, factoryParams).then(val => {
      setIsFlagFired(val);
      setIsLoading(false);
    });
  }, [factoryName, factoryParams]);

  const toggle = () => {
    setIsLoading(true);
    const action = isFlagFired ? disable : enable;
    action(factoryName, factoryParams)
      .then(() => {
        setIsFlagFired(!isFlagFired);
        setIsLoading(false);
        setIsConfirmationDialogOpened(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsConfirmationDialogOpened(false);
      });
  };

  return (
    <>
      <BottomNavigationAction
        disabled={loading || disabled}
        label={label}
        showLabel={true}
        icon={
          loading ? (
            <CircularProgress size={24} />
          ) : isFlagFired ? (
            <CheckBox style={{ color: '#123393' }} />
          ) : (
            <CheckBoxOutlineBlank style={{ color: '#123393' }} />
          )
        }
        onClick={
          isEmpty(confirmation)
            ? toggle
            : () => setIsConfirmationDialogOpened(true)
        }
      />
      <ConfirmationDialog
        isOpened={isConfirmationDialogOpened}
        message={
          isFlagFired
            ? confirmation?.enabledMessage
            : confirmation?.disabledMessage
        }
        onClose={() => setIsConfirmationDialogOpened(false)}
        onSubmit={toggle}
      />
    </>
  );
};

export default SystemFlagBottomAction;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Async } from 'react-select';

import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';

import ModalButton from 'components/CustomButtons/Button';

import AddNewCustomerForm from 'views/Clients/FormTabs/GeneralInfo/GeneralInfo';
import { orderStyles, asyncSelectStyles } from 'views/Orders/styles';

import { navyColor } from 'assets/jss/material-dashboard-pro-react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  selectUserWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    '@media (min-width: 769px)': {
      flexFlow: 'row',
    },
  },
  asyncInputWrapper: {
    width: '100%',
    marginBottom: '15px',
    '@media (min-width: 769px)': {
      width: '500px',
      marginBottom: '0',
    },
  },
  newClientButton: {
    marginBottom: '5px',
    color: navyColor,
    borderColor: navyColor,
  },
});

const SelectUserSection = ({
  classes: propClasses,
  promiseOptions,
  selectedCustomer,
  selectCurrentUser,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [isAddNewCustomerFormOpened, setIsAddNewCustomerFormOpened] =
    useState(false);

  const setNewUser = newUser => {
    selectCurrentUser({
      id: newUser?.id,
      label: `${newUser.firstName} ${newUser.lastName} (${newUser.email})`,
      value: newUser?.['@id'],
      invoiceInfo: newUser?.invoiceCompany
        ? {
            invoiceCompany: newUser?.invoiceCompany,
            invoiceVatNumber: newUser?.invoiceVatNumber,
            invoiceAddressCity: newUser?.invoiceAddressCity,
            invoiceAddressStreet: newUser?.invoiceAddressStreet,
            invoiceAddressPostCode: newUser?.invoiceAddressPostCode,
            invoiceAddressBuildNumber: newUser?.invoiceAddressBuildNumber,
            invoiceAddressPlaceNumber: newUser?.invoiceAddressPlaceNumber,
          }
        : {},
    });
    setIsAddNewCustomerFormOpened(false);
  };

  return (
    <>
      <div className={classes.selectUserWrapper}>
        <div
          className={classes.asyncInputWrapper}
          data-cy="select_client_new-order"
        >
          <Async
            value={selectedCustomer}
            styles={asyncSelectStyles}
            onChange={selectCurrentUser}
            clearValue={() => selectCurrentUser(null)}
            isClearable={true}
            loadOptions={promiseOptions}
            placeholder={t('orders.selectClient')}
            noOptionsMessage={() => t('orders.typeNameAndEmail')}
          />
        </div>
        <div>
          <MaterialButton
            className={classes.newClientButton}
            data-cy="button_client_new-order"
            variant="outlined"
            fullWidth
            onClick={() => setIsAddNewCustomerFormOpened(true)}
          >
            {t('orders.newClient')} +
          </MaterialButton>
        </div>
      </div>
      {isAddNewCustomerFormOpened && (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={isAddNewCustomerFormOpened}
          onClose={() => {
            setIsAddNewCustomerFormOpened(false);
          }}
        >
          <DialogContent>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => {
                setIsAddNewCustomerFormOpened(false);
              }}
            >
              <Close />
            </ModalButton>
            <h2>{t('clients.clientCreation', '$$Tworzenie klienta')}</h2>
            <AddNewCustomerForm
              classes={propClasses}
              onAfterSubmit={setNewUser}
              addNewUserOnNewOrderForm={true}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SelectUserSection;

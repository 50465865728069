import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ContainerCell from './ContainerCell';

class ContainerRow extends React.Component {
  render() {
    const {
      classes,
      mealTypeSizes,
      dishSizes,
      containers,
      handleContainerChange,
      t,
    } = this.props;

    return (
      <TableRow>
        <TableCell>{t('common.container', 'Pojemnik')}</TableCell>
        <TableCell />
        <TableCell />
        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => {
          return (
            <ContainerCell
              key={mealTypeSizeKey}
              classes={classes}
              size={mealTypeSize.size}
              dishSizes={dishSizes}
              containers={containers}
              handleContainerChange={handleContainerChange}
            />
          );
        })}
      </TableRow>
    );
  }
}

export default ContainerRow;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AddonsContent } from './AddonsContent';

const Component = ({ orderId, item, fillModal }) => {
  const { diet } = item;
  const { t } = useTranslation();

  const subscriptionSuffix = diet.isSubscription
    ? `(${t('diets.isSubscription')})`
    : '';

  return diet ? (
    <React.Fragment>
      <NavLink to={`/admin/ecommerce-diets/preview/${diet.id}`}>
        {diet?.diet?.name || diet?.offerDiet?.name} (#{diet.id})
        {subscriptionSuffix}
      </NavLink>
      {diet.addons.length > 0 && (
        <span
          style={{ color: '#89229b', cursor: 'pointer' }}
          onClick={() =>
            fillModal(
              <AddonsContent orderId={orderId} diet={diet}></AddonsContent>
            )
          }
        >
          {' '}
          {t('common.mainMenu.addons', 'Dodatki')}
        </span>
      )}
    </React.Fragment>
  ) : (
    '-'
  );
};
export default Component;

import React from 'react';
import { removeMenuTemplate } from 'actions/MenuTemplates';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { DeleteForever } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

class RemoveMenuTemplate extends React.Component {
  state = {
    alert: null,
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  removeCurrentMenuTemplate = async () => {
    this.setLoading(true);
    await this.props.removeMenuTemplate(this.props.id);
    this.setLoading(false);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRemove = () => {
    this.props.grid.current.fireFetchData();
  };

  confirmModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('menuPlanner.action.template.remove.alertTitle')}
          onConfirm={() => {
            this.removeCurrentMenuTemplate();
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };

  render() {
    const content = this.props.content
      ? this.props.content
      : this.props.t('menuPlanner.action.template.remove.actionName');

    return (
      <>
        <BottomNavigationAction
          label={content}
          showLabel={true}
          icon={<DeleteForever style={{ color: '#f65b52' }} />}
          onClick={this.confirmModal}
        />
        {this.state.alert}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  removeMenuTemplate: id => dispatch(removeMenuTemplate(id)),
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(sweetAlertStyle)(withToast(RemoveMenuTemplate)))
);

import Card from './Card';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  parentTitle: {
    display: 'flex',
  },
  title: { marginLeft: '15px', marginBottom: 0 },
  tooltip: { margin: '16px 0 0 5px' },
});

const CardWrapper = ({ children, title, tooltip = null }) => {
  const classes = useStyles();
  return (
    <Card>
      <div className={classes.parentTitle}>
        {title && <h3 className={classes.title}>{title}</h3>}
        {tooltip && <span className={classes.tooltip}>{tooltip}</span>}
      </div>
      {children}
    </Card>
  );
};

export default CardWrapper;

import React from 'react';
import FormDietsContainer from './components/formComponents/FormDiets.container';

const Form = ({
  match,
  location,
  fetchDiets,
  fetchAllergens,
  fetchIngredientCategories,
  openToast,
  fetchIngredients,
  t,
  classes,
  brandConfig,
  diets,
  history,
}) => {
  return (
    <FormDietsContainer
      match={match}
      location={location}
      fetchDiets={fetchDiets}
      fetchAllergens={fetchAllergens}
      fetchIngredientCategories={fetchIngredientCategories}
      openToast={openToast}
      fetchIngredients={fetchIngredients}
      t={t}
      classes={classes}
      brandConfig={brandConfig}
      diets={diets}
      history={history}
    />
  );
};

export default Form;

import types from 'helpers/constants';

const initialState = {
  ingredients: [],
  ingredientCategories: [],
  loading: true,
  ingredient: {},
};

function Ingredients(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_INGREDIENTS:
      return {
        ...state,
        ingredients: action.payload.ingredients,
        pages: action.payload.pages,
        loading: false,
      };
    case types.FETCH_INGREDIENT_CATEGORIES:
      return {
        ...state,
        ingredientCategories: action.payload.ingredientCategories,
      };
    case types.FETCH_INGREDIENT:
      return {
        ...state,
        ingredient: action.payload.ingredient,
      };
    case types.REMOVE_INGREDIENT:
      return {
        ...state,
        ingredients: state.ingredients.filter(
          ingredient => ingredient.id !== action.payload.removedItemId
        ),
      };
    case types.LOADING_INGREDIENTS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default Ingredients;

import React from 'react';
import { TableCell } from '@material-ui/core';
import { connect } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';

class PriceSumCell extends React.Component {
  render() {
    const { dishSizes, size, currency } = this.props;

    let sum = 0;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    dishSizeBySize.ingredients.forEach(ingredient => {
      if (ingredient.recipe === null) {
        sum +=
          (ingredient.ingredient.price *
            ingredient.ingredient.weight *
            ingredient.quantity) /
          100;
      } else if (ingredient.ingredient === null) {
        sum += ingredient.recipe.price * ingredient.quantity;
      }
    });

    return (
      <TableCell>{`${sum.toFixed(2)} ${currencyToSymbol(currency)}`}</TableCell>
    );
  }
}

export default connect(state => ({
  currency: state.Brands.brand.multinational.currency,
}))(PriceSumCell);

const getPieceWeight = item => {
  if (item.ingredient) {
    if (item?.weightIsAfterMachining) {
      return item.ingredient?.macrosAfterProcessing?.weight;
    } else {
      return item.ingredient.weight;
    }
  } else {
    if (item?.weightIsAfterMachining) {
      return item.recipe?.macrosAfterProcessing?.weight;
    } else {
      return item.recipe.weight;
    }
  }
};

export default getPieceWeight;

import React from 'react';
// core components
import Button from 'components/CustomButtons/Button.jsx';

import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withToast } from 'material-ui-toast-redux';
import { BorderColor, Delete } from '@material-ui/icons';
import TOAST_DURATIONS from 'helpers/toastDurations';

const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  background: rgb(242, 242, 242);
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

class IconUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.previewUrl || null,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidUpdate = prevProps => {
    if (this.props.previewUrl !== prevProps.previewUrl) {
      this.setState(prevState => ({
        ...prevState,
        file: null,
        imagePreviewUrl: this.props.previewUrl || null,
      }));
    }
  };

  handleImageChange(e) {
    const maxFileSize = this.props.maxUploadedFileSize || Infinity;
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file.size < maxFileSize) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      this.props.getImage(this.props.stateName, file);
      file && reader.readAsDataURL(file);
    } else {
      return this.props.openToast({
        messages: [
          this.props.t(
            'module.configClientPanel.icons.calendarIconWeightInfo',
            { x: '500 KB' }
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  handleRemove() {
    this.setState(prevState => ({
      ...prevState,
      file: null,
      imagePreviewUrl: null,
    }));

    this.props.removeImage(this.props.stateName);

    this.props.removeIcon(this.props.section, this.props.index);
    this.refs.fileInput.value = null;
  }

  render() {
    const {
      avatar,
      buttonText,
      isIcon,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      imgId,
      padding,
      btnOnlyIcon,
    } = this.props;

    return (
      <div
        style={{
          width: isIcon ? '48px' : 'inherit',
          padding: padding ? (isIcon ? '0 80px' : 'inherit') : null,
          height: '48px',
          display: 'flex',
          position: 'relative',
          marginBottom: 0,
          flexShrink: 0,
        }}
        className="fileinput text-center"
      >
        <input
          type="file"
          onChange={this.handleImageChange}
          ref="fileInput"
          accept="image/*"
        />

        <ImageContainer>
          {this.state.imagePreviewUrl !== null ? (
            <img
              style={{ width: '100%' }}
              id={imgId}
              src={this.state.imagePreviewUrl}
              alt="..."
            />
          ) : (
            <span>{this.props.t('common.lack')}</span>
          )}
        </ImageContainer>
        <div
          style={{
            display: isIcon ? 'flex' : 'block',
            justifyContent: isIcon ? 'center' : 'inherit',
            margin: 'auto',
          }}
        >
          {this.props.previewUrl === null ? (
            <span>
              <Button
                style={
                  btnOnlyIcon ? { padding: '5px', marginLeft: '10px' } : null
                }
                {...addButtonProps}
                onClick={() => this.handleClick()}
              >
                {btnOnlyIcon ? (
                  <BorderColor />
                ) : buttonText ? (
                  buttonText
                ) : (
                  this.props.t('form.selectPhoto')
                )}
              </Button>
              {this.props.previewUrl && (
                <Button
                  {...removeButtonProps}
                  onClick={() => this.handleRemove()}
                >
                  <i className="fas fa-times" /> {this.props.t('form.delete')}
                </Button>
              )}
            </span>
          ) : (
            <span>
              <Button
                style={
                  btnOnlyIcon ? { padding: '5px', marginLeft: '10px' } : null
                }
                {...changeButtonProps}
                onClick={() => this.handleClick()}
              >
                {btnOnlyIcon ? <BorderColor /> : this.props.t('form.change')}
              </Button>
              {avatar ? <br /> : null}

              <Button
                style={
                  btnOnlyIcon ? { padding: '5px', marginLeft: '10px' } : null
                }
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                {btnOnlyIcon ? <Delete /> : <i className="fas fa-times" />}
                {btnOnlyIcon ? null : this.props.t('form.delete')}
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withToast(IconUpload));

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import LinkManagment from '../../LinkManagment';
import CardWrapper from 'components/Card/CardWrapper';

const FooterSettings = ({
  t,
  classes,
  state,
  setState,
  handleDeleteLink,
  setDisabledButton,
  disabledButton,
  handleInputChange,
  updateModule,
  removeIcon,
  onClickUp,
  onClickDown,
}) => {
  return (
    <CardWrapper
      title={t('brandCfg.clientPanelDesign.footerTitle', 'Konfiguracja stopki')}
    >
      <CardBody>
        <GridContainer>
          {/* Up section */}
          <GridItem md={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'brandCfg.clientPanelDesign.footer.upSection.title',
                'Linki w górnej części stopki'
              )}
            </FormLabel>
          </GridItem>
          <GridItem md={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'brandCfg.clientPanelDesign.footer.upSection.showName',
                'Nazwa wyświetlana'
              )}
            </FormLabel>
          </GridItem>
          <GridItem md={2}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.clientPanelDesign.footer.upSection.icon', 'Ikonki')}
            </FormLabel>
          </GridItem>
          <GridItem md={2}>
            {/* empty section to preserve the structure */}
          </GridItem>
          <GridItem md={2}>
            {/* empty section to preserve the structure */}
          </GridItem>
        </GridContainer>
        {state.footerLink?.up?.map((line, index) => {
          return (
            <LinkManagment
              item={line}
              key={index}
              section={'up'}
              disabledButton={disabledButton}
              index={index}
              handleDeleteLink={() => handleDeleteLink(index, 'up')}
              classes={classes}
              t={t}
              handleInputChange={handleInputChange}
              updateModule={updateModule}
              removeImage={removeIcon}
              removeIcon={removeIcon}
              onClickUp={onClickUp}
              onClickDown={onClickDown}
            />
          );
        })}
      </CardBody>
      <GridContainer>
        <GridItem md={12}>
          <Button
            style={{ marginLeft: '12px' }}
            color="success"
            onClick={() => {
              setDisabledButton(true);
              setState(prevState => ({
                ...prevState,
                footerLink: {
                  ...prevState?.footerLink,
                  up: prevState?.footerLink?.up
                    ? [...prevState?.footerLink?.up, { link: '', name: '' }]
                    : [{ link: '', name: '' }],
                },
              }));
            }}
          >
            {t(
              'brandCfg.clientPanelDesign.footer.upSection.addLink',
              'Dodaj Link +'
            )}
          </Button>
        </GridItem>
      </GridContainer>
      <CardBody>
        <GridContainer>
          {/* Down section */}
          <GridItem md={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'brandCfg.clientPanelDesign.footer.downSection.title',
                'Linki w dolnej części stopki'
              )}
            </FormLabel>
          </GridItem>
          <GridItem md={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'brandCfg.clientPanelDesign.footer.upSection.showName',
                'Nazwa wyświetlana'
              )}
            </FormLabel>
          </GridItem>
          <GridItem md={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.clientPanelDesign.footer.upSection.icon', 'Ikonki')}
            </FormLabel>
          </GridItem>
          <GridItem md={3}>
            {/* empty section to preserve the structure */}
          </GridItem>
        </GridContainer>
        {state.footerLink?.down?.map((line, index) => {
          return (
            <LinkManagment
              item={line}
              key={index}
              section={'down'}
              disabledButton={disabledButton}
              index={index}
              handleDeleteLink={() => handleDeleteLink(index, 'down')}
              classes={classes}
              t={t}
              handleInputChange={handleInputChange}
              updateModule={updateModule}
              removeImage={removeIcon}
              removeIcon={removeIcon}
              onClickUp={onClickUp}
              onClickDown={onClickDown}
            />
          );
        })}
      </CardBody>
      <GridContainer>
        <GridItem md={12}>
          <Button
            style={{ marginLeft: '12px' }}
            color="success"
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                footerLink: {
                  ...prevState?.footerLink,
                  down: prevState?.footerLink?.down
                    ? [...prevState?.footerLink?.down, { link: '', name: '' }]
                    : [{ link: '', name: '' }],
                },
              }));
            }}
          >
            {t(
              'brandCfg.clientPanelDesign.footer.upSection.addLink',
              'Dodaj Link +'
            )}
          </Button>
        </GridItem>
      </GridContainer>
    </CardWrapper>
  );
};

export default FooterSettings;

import SelectInput from 'components/FormSelect/SelectInput';
import { useEffect, useState } from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_WASTE } from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getPieceWeight from '../utils/getPieceWeight';

const LossWeightInput = ({ row, classes, changeRow }) => {
  const unitOptions = [
    { name: 'g', value: 'g' },
    { name: 'kg', value: 'kg' },
  ];

  const { t } = useTranslation();

  const [weight, setWeight] = useState(row.lossWeight ?? 0);

  const handleChangeWeight = ev => {
    if (row.lossUnit === unitOptions[0].value) {
      changeRow(row, ev.target.value, 'lossWeight');
    } else {
      changeRow(row, ev.target.value * 1000, 'lossWeight');
    }

    changeRow(
      row,
      +(row.lossWeight / getPieceWeight(row)).toFixed(2),
      'amount'
    );
    setWeight(ev.target.value);
  };

  const handleChangeUnit = ev => {
    if (ev.target.value === row.lossUnit) {
      return;
    }

    if (row.lossUnit === unitOptions[0].value) {
      setWeight(prev => +(prev / 1000).toFixed(2));
    } else {
      setWeight(row.lossWeight);
    }

    changeRow(row, ev.target.value, 'lossUnit');
  };

  useEffect(() => {
    if (row.lossUnit === unitOptions[0].value) {
      setWeight(row.lossWeight);
    } else {
      setWeight(+(row.lossWeight / 1000).toFixed(2));
    }
  }, [row.amount]);

  useEffect(() => {
    if (!row.hasChanged && Math.floor(row.lossWeight / 1000) > 0) {
      setWeight(+(row.lossWeight / 1000).toFixed(2));
      changeRow(row, unitOptions[1].value, 'lossUnit');
    }
  }, [row.hasChanged]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ paddingRight: '10px', paddingTop: '9px' }}>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          inputProps={{
            disabled: row['@id'] && !isGranted(ROLE_EDIT_WASTE),
            type: 'number',
            placeholder: t('common.shared.typeValue'),
            name: 'lossWeight',
            value: weight,
            onChange: handleChangeWeight,
          }}
        />
      </div>
      <div style={{ width: '50px' }}>
        <SelectInput
          noGrid
          classes={classes}
          mapBy="name"
          trackBy="value"
          name="source"
          value={row.lossUnit}
          options={unitOptions}
          handleChange={handleChangeUnit}
          id="lossUnit"
        />
      </div>
    </div>
  );
};

export default LossWeightInput;

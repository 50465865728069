import React from 'react';
import dietsColumnConfig from './dietsColumnConfig';
import DataGrid from 'components/DataGrid/DataGrid';
import { trimEmptyStringsFromQuery } from 'helpers/helpers';
import { withTranslation } from 'react-i18next';

class Diets extends React.Component {
  render() {
    return (
      <DataGrid
        actions={true}
        previewPath="/admin/ecommerce-diets/preview"
        paginationTop={true}
        paginationBottom={false}
        url="/ecommerce-diets"
        reportName={'diet'}
        role={'ORDER'}
        columns={dietsColumnConfig(this.props.t)}
        minRows={18}
        manipulateQuery={(requestData, query) => {
          query['client.id[]'] = this.props.userId;

          if (query['order[address]']) {
            const desc = query['order[address]'];
            delete query['order[address]'];
            query['order[address.city]'] = desc;
            query['order[address.street]'] = desc;
          }

          if (query['address']) {
            const search = query['address'];
            delete query['address'];
            let splicedSearch = search.split(' ');
            splicedSearch = trimEmptyStringsFromQuery(splicedSearch);
            let orX = [
              [
                {
                  'address.city': splicedSearch,
                  'address.street': splicedSearch,
                  'address.postCode': splicedSearch,
                  'address.buildNumber': splicedSearch,
                  'address.placeNumberNumber': splicedSearch,
                  'pickUpPoint.value': splicedSearch,
                },
              ],
            ];

            query['_orX'] = (query['_orX'] || []).concat(orX);
          }

          return query;
        }}
      />
    );
  }
}

export default withTranslation()(Diets);

import types from 'helpers/constants';

export const closeToast = key => {
  return dispatch =>
    dispatch({
      type: types.APP_CLOSE_TOAST,
      payload: { key },
    });
};

export const openSweetToast = sweetAlertProps => {
  return dispatch => {
    const uniq = Math.random();

    const { onCancel, onConfirm, ...restProps } = sweetAlertProps;

    dispatch({
      type: types.APP_OPEN_SWEET_TOAST,
      payload: {
        ...restProps,
        key: uniq,
        onCancel: () => {
          if (onCancel) {
            onCancel();
          }
          dispatch(closeToast(uniq));
        },
        onConfirm: () => {
          if (onConfirm) {
            onConfirm();
          }
          dispatch(closeToast(uniq));
        },
      },
    });
  };
};

export const closeAllToasts = () => {
  return dispatch =>
    dispatch({
      type: types.APP_CLOSE_ALL_TOASTS,
    });
};

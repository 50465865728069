import { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import withStyles from '@material-ui/core/styles/withStyles';
import { Avatar, Popover, Tooltip } from '@mui/material';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import TOAST_DURATIONS from 'helpers/toastDurations';

// styled components
const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

// search icon for reat-select
const DropdownIndicator = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
  >
    <path
      d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const ChevronDown = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...props}
  >
    <path d="m7 10 5 5 5-5z" fill="currentColor" fillRule="evenodd" />
  </svg>
);

const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

const EmployeePicker = ({
  row = {},
  openToast,
  employees = [],
  initialEmployee = {},
  isPickingEnabled = true,
  onEmployeeChangeAction = () => {},
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [employee, setEmployee] = useState(initialEmployee);

  useEffect(() => {
    setEmployee(initialEmployee);
  }, [initialEmployee]);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const changeEmployee = async obj => {
    try {
      await onEmployeeChangeAction({ row, employeeIri: obj?.value });
      setEmployee(obj);
      openToast({
        messages: [
          t('$*success.userHasBeenAssinged', '$$Użytkownik został przypisany'),
        ],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'employee-popover' : undefined;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={employee?.label ?? 'Nieprzypisane'} placement="top" arrow>
        <Avatar
          src={
            employee?.contentUrl
              ? `${uploadedContentBaseURL}${employee?.contentUrl}`
              : '/static/images/avatar/1.jpg'
          }
        >
          {employee?.label
            ? `${employee.label.split(' ')[0][0]}${
                employee.label.split(' ')[1][0]
              }`
            : ''}
        </Avatar>
      </Tooltip>
      {isPickingEnabled && (
        <>
          <div style={{ marginLeft: 'auto' }}>
            <ChevronDown onClick={handleClick} style={{ cursor: 'pointer' }} />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen={true}
              onChange={changeEmployee}
              options={employees}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={employee}
            />
          </Popover>
        </>
      )}
    </div>
  );
};

const enhance = compose(withStyles(extendedFormsStyle), withToast);

export default enhance(EmployeePicker);

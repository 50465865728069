import styled from 'styled-components';

const Container = styled.div`
  //border: 1px solid lightgrey;
  border-radius: 2px;
  //width: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
`;

const Title = styled.h3`
  background: linear-gradient(60deg, #ab47bc, #8e24aa);
  color: #fff;
  margin: 0;
  padding: 8px;
`;

const RouteList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? '#EBEBEB' : 'white')};
  flex-grow: 1;
  min-height: 100px;
`;

export { Container, Title, RouteList };

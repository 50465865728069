import { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DialogLoader } from 'components/DialogLoader';
import { fetchBrandConfigModulesPack } from '../../actions/Brands';

const PowerBIStatistics = ({ selectedBrand, fetchBrandConfigModulesPack }) => {
  const { t } = useTranslation();

  const [modules, setModules] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async () => {
    const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
      'PowerBI',
    ]);

    setModules(modulesConfig?.configuration ?? {});
    setIsLoading(false);
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      {modules?.PowerBI?.iframeCode ? (
        <div
          dangerouslySetInnerHTML={{ __html: modules?.PowerBI?.iframeCode }}
        />
      ) : null}
    </>
  );
};

const enhance = compose(
  connect(({ Auth: { selectedBrand } }) => ({ selectedBrand }), {
    fetchBrandConfigModulesPack,
  })
);

export default enhance(PowerBIStatistics);

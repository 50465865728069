import types from 'helpers/constants';

const initialState = {
  drivers: [],
  driver: {},
  loading: true,
};

function Drivers(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DRIVERS:
      return {
        ...state,
        drivers: action.payload.drivers,
        loading: false,
      };
    case types.CLEAR_DRIVERS:
      return {
        ...state,
        drivers: [],
      };
    case types.FETCH_DRIVER:
      return {
        ...state,
        driver: action.payload,
        loading: false,
      };
    case types.REMOVE_DRIVER:
      return {
        ...state,
        drivers: state.drivers.filter(
          driver => driver.id !== action.payload.driverId
        ),
      };
    default:
      return state;
  }
}

export default Drivers;

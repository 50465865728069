import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from 'components/CustomButtons/Button.jsx';
class ScaleSizeRow extends React.Component {
  render() {
    const {
      mealTypeSizes,
      dishSizes,
      scaleDishSize,
      hasCheckedDishToResize,
    } = this.props;

    return (
      <TableRow>
        <TableCell />
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => scaleDishSize(dishSizes, mealTypeSizes)}
              color="primary"
              fullWidth
              disabled={!hasCheckedDishToResize}
            >
              Skaluj
            </Button>
          </div>
        </TableCell>
        <TableCell />
        {mealTypeSizes.map((mealTypeSize, index) => {
          return <TableCell key={index} />;
        })}
      </TableRow>
    );
  }
}

export default ScaleSizeRow;

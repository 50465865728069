import adobeFonts from './adobeFonts';
import customFonts from './customFonts';
import googleFonts from './googleFonts';

const fontsList = {
  custom: customFonts,
  google: googleFonts,
  typekit: adobeFonts,
};

export default fontsList;

import { useState, useEffect, createContext, useContext } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  updateBrandConfigModulesPack,
} from '../../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import produce from 'immer';
import { get, put } from '../../../helpers/apiHelpers';

import TOAST_DURATIONS from 'helpers/toastDurations';

const DYNAMIC_BACKGROUND = {
  defaultCatalog: '/media-catalogs/3ba7ea4c-736c-46b2-bf02-f2401e8a41c7',
};

const DISH_IMAGE_PLACEHOLDER = {
  PLImageCatalog: '/media-catalogs/860c7911-9873-4705-afa6-939d03c1dd4c',
  PLTransparentImageCatalog:
    '/media-catalogs/bd7b8341-80bf-42ec-8890-197e827d57b1',
  CZImageCatalog: '/media-catalogs/d77dcced-eade-4c78-a01d-80820511078a',
  CZTransparentImageCatalog:
    '/media-catalogs/2a24e245-6f90-4831-bba7-d37b7aca05c6',
};

const PanelConfigurationContext = createContext([{}, () => {}]);

const PanelConfigurationContextProvider = ({
  children,
  selectedBrand,
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  paymentTypes,
  updateBrandConfigModulesPack,
  openToast,
  selectedLanguage,
  t,
}) => {
  const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}/uploaded-media/`;
  const [isOpen, setIsOpen] = useState('');
  const [backgroundDialogMode, setBackgroundDialogMode] = useState(false);

  const [choicesBackground, setChoicesBackground] = useState({
    image: {},
    index: null,
  });
  const [backgroundToChoicedArray, setBackgroundToChoicedArray] = useState([]);

  const [choicesAlternatePhotoDish, setChoicesAlternatePhotoDish] = useState({
    image: {},
    index: null,
  });
  const [alternatePhotoToDishArray, setAlternatePhotoToDishArray] = useState(
    []
  );

  const catalogTransparentRelativeToLanguage = () => {
    if (
      selectedLanguage.toLowerCase() === 'cz' ||
      selectedLanguage.toLowerCase() === 'cs'
    )
      return DISH_IMAGE_PLACEHOLDER.CZTransparentImageCatalog;
    return DISH_IMAGE_PLACEHOLDER.PLTransparentImageCatalog;
  };

  useEffect(() => {
    get(DYNAMIC_BACKGROUND.defaultCatalog).then(response => {
      setBackgroundToChoicedArray(response.contents);
    });

    get(catalogTransparentRelativeToLanguage()).then(response => {
      setAlternatePhotoToDishArray(response.contents);
    });
  }, []);

  const [brandConfig, setBrandConfig] = useState({
    allChangesWithoutCosts: false,
    allowChangeAddress: false,
    allowChangeCalorific: false,
    allowChangeVariant: false,
    showPricePreview: false,
    allowChangeDeliveryDate: false,
    allowChangeMultipleDays: false,
    allowChangeDiet: false,
    showInformation: {
      dishImagePlaceholder: null,
      macroIngredients: false,
      secondMacroIngredients: false,
      dishCalorific: false,
      dishImage: false,
      allergens: false,
      dishComposition: false,
      dishCompositionValue: { mode: '', name: '' },
      tags: false,
      servingProposal: false,
      avgDishRating: false,
      summary: { show: false, tooltip: '' },
      glycemicIndex: { show: false, tooltip: '' },
    },
    showRefererCode: false,
    showFacebookCodeReference: true,
    showLinkedinCodeReference: true,
    showTwitterCodeReference: true,
    showWhatsappCodeReference: true,
    showMessengerCodeReference: true,
    facebookCodeReferenceLink: '',
    linkedinCodeReferenceLink: '',
    whatsappCodeReferenceLink: '',
    twitterCodeReferenceLink: '',
    messengerCodeReferenceLink: '',
    sharingRecipes: {
      orderForm: {
        printRecipe: false,
        downloadRecipe: false,
        showRecipe: false,
        showMealSubpage: false,
      },
      clientPanel: {
        printRecipe: false,
        downloadRecipe: false,
        showRecipe: false,
        showMealSubpage: false,
      },
      dishCompositionValue: 'Skład dania',
    },
    customScripts: '',
    headTags: '',
    bodyTags: '',
    showNutritionalValuesInMenuPreview: false,
  });

  const [configClientPanelModule, setConfigClientPanelModule] = useState({
    isSubscriptionPaymentMode: false,
    isOneTimePayPaymentMode: false,
    defaultPaymentMode: 2,
    oneTimePaymentDefaultGateway: '',
    subscriptionPaymentDefaultGateway: '',
    orderPaymentDefaultGateway: '',
    referralProgramDescription: '',
    initialBasketId: '',
    enableVariantStep: false,
    allowRenewDiet: false,
    clientCanCancelSubscription: false,
    clientCanRemoveAccount: false,
    validateBagChangesWithSlots: false,
    hideDisabledDaysOnClientCalendar: false,
    chooseMenuDescription: '',
    needToChangeDietDescription: null,
    showMenuPreviewOnOrderForm: false,
    showCalendarIcons: false,
    showInfoWithPhotoAboutDeliveredBag: false,
    allowCancelBags: false,
    allowClientOwnDietName: false,
    allowClientPayWithMoneboxEverywhere: false,
    useAddressesWithLessFields: false,
    allowClientSeeOwnId: false,
    allowPrintRecipe: false,
    showDiscountCodeInputInBasket: false,
    allowDownloadRecipe: false,
    allowShowRecipe: false,
    allowPageRecipe: false,
    allowClientSeeMoneyBox: false,
    filterShownMenuByDiet: false,
    calendarIconDelivered: null,
    calendarIconRated: null,
    calendarIconRatedPartial: null,
    calendarIconCanBeRated: null,
    calendarIconDetails: null,
    calendarIconChangeMenu: null,
    calendarIconMenuSoon: null,
    calendarIconSubscriptionOrdered: null,
    calendarIconSubscriptionAddDelivery: null,
    dishImagePlaceholder: '',
    dishImageBackground: '',
    dishImageBackgroundEnabled: false,
    omnibusEnabledForDefaultPrices: false,
    orderFormDietSectionBoxTypeMobile: '',
    calculatorMode: '',
    calculatorLink: '',
    demandPhoneNumber: false,
    showKnowAboutUs: false,
    allowGrayingOutDiscountCode: false,
    showNutritionalValuesInMenuPreview: false,
    allowStickyNextButton: false,
    params: [],
    allowFilterTags: false,
    hideSmallCalendar: false,
  });

  const [module, setModule] = useState({});
  const [paymentModesCopyModule, setPaymentModesCopyModule] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);

      const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
        'Shop',
        'ConfigClientPanel',
        'PaymentModesCopy',
        'SeoCmsMainSite',
        'SeoCmsAddonsSide',
        'SeoCmsDietSide',
        'SeoCmsDishSide',
        'SeoCmsZonesSubpage',
        'SeoCmsMenuSubpage',
        'SeoCmsClientPanelLogin',
        'SeoCmsClientPanelRegister',
        'SeoCmsClientPanelMyAccount',
      ]);

      const {
        '@id': idCcpIri,
        '@type': typeCcpIri,
        ...restConfigClientPanelVariables
      } = modulesConfig.configuration.ConfigClientPanel;
      setConfigClientPanelModule(restConfigClientPanelVariables);
      setPaymentModesCopyModule(modulesConfig.configuration.PaymentModesCopy);
      setModule(modulesConfig);
      await fetchPaymentTypes(selectedBrand);

      setBrandConfig(prevState => ({
        ...prevState,
        premiumType: brandConfig.premiumType,
        premiumAdditionalStep: brandConfig.premiumAdditionalStep,
        allChangesWithoutCosts: brandConfig.allChangesWithoutCosts,
        allowChangeAddress: brandConfig.allowChangeAddress,
        allowChangeVariant: brandConfig.allowChangeVariant,
        showPricePreview: brandConfig.showPricePreview,
        allowChangeCalorific: brandConfig.allowChangeCalorific,
        allowChangeDeliveryDate: brandConfig.allowChangeDeliveryDate,
        allowChangeDiet: brandConfig.allowChangeDiet,
        allowChangeMultipleDays: brandConfig.allowChangeMultipleDays,
        showInformation: brandConfig.showInformation,
        showFacebookCodeReference: brandConfig.showFacebookCodeReference,
        showLinkedinCodeReference: brandConfig.showLinkedinCodeReference,
        showMessengerCodeReference: brandConfig.showMessengerCodeReference,
        showRefererCode: brandConfig.showRefererCode,
        showTwitterCodeReference: brandConfig.showTwitterCodeReference,
        showWhatsappCodeReference: brandConfig.showWhatsappCodeReference,
        facebookCodeReferenceLink: brandConfig.facebookCodeReferenceLink
          ? brandConfig.facebookCodeReferenceLink
          : brandConfig.homePageLink,
        linkedinCodeReferenceLink: brandConfig.linkedinCodeReferenceLink
          ? brandConfig.linkedinCodeReferenceLink
          : brandConfig.homePageLink,
        whatsappCodeReferenceLink: brandConfig.whatsappCodeReferenceLink
          ? brandConfig.whatsappCodeReferenceLink
          : brandConfig.homePageLink,
        twitterCodeReferenceLink: brandConfig.twitterCodeReferenceLink
          ? brandConfig.twitterCodeReferenceLink
          : brandConfig.homePageLink,
        messengerCodeReferenceLink: brandConfig.messengerCodeReferenceLink
          ? brandConfig.messengerCodeReferenceLink
          : brandConfig.homePageLink,
        showNutritionalValuesInMenuPreview:
          modulesConfig?.configuration?.ConfigClientPanel
            ?.showNutritionalValuesInMenuPreview ?? false,
      }));
      setIsLoading(false);
    })();
  }, []);

  const handleChangeDietViewOption = ({ target: { name } }) => {
    setBrandConfig(
      produce(draft => {
        draft.showInformation[name] = !draft.showInformation[name];
      })
    );
  };

  const handleGlycemicIndexChange = ({ target: { name, value } }) => {
    setBrandConfig(
      produce(draft => {
        const isBoolean = ['show'].includes(name);
        const glycemicIndex = draft.showInformation.glycemicIndex;

        glycemicIndex[name] = isBoolean ? !glycemicIndex[name] : value;
      })
    );
  };

  const handleSummaryShowChange = ({ target: { name, value } }) => {
    setBrandConfig(
      produce(draft => {
        const isBoolean = ['show'].includes(name);
        const summary = draft.showInformation.summary;

        summary[name] = isBoolean ? !summary[name] : value;
      })
    );
  };

  const handleInputChange = e => {
    setBrandConfig(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleModuleUpdateConfigClientUpdate = data => {
    setConfigClientPanelModule({ ...configClientPanelModule, ...data });
  };

  const setConfigClientPanelModuleImageByStateName = (fieldName, data) => {
    setConfigClientPanelModule({
      ...configClientPanelModule,
      '@resources': {
        ...(configClientPanelModule?.['@resources'] ?? {}),
        [data?.['@id']]: { ...data },
      },
      [fieldName]: data?.['@id'] ?? null,
    });

    if (fieldName === 'dishImageBackground') {
      setChoicesBackground({
        image: null,
        index: null,
      });
    }

    if (fieldName === 'dishImagePlaceholder') {
      setChoicesAlternatePhotoDish({
        image: null,
        index: null,
      });
    }
  };

  const removeConfigClientPanelModuleImageByStateName = fieldName => {
    setConfigClientPanelModule({
      ...configClientPanelModule,
      [fieldName]: null,
    });

    if (fieldName === 'dishImageBackground') {
      setChoicesBackground({
        image: null,
        index: null,
      });
    }

    if (fieldName === 'dishImagePlaceholder') {
      setChoicesAlternatePhotoDish({
        image: null,
        index: null,
      });
    }
  };

  const handleClientCanCanceledDietDay = () => {
    if (configClientPanelModule.allowCancelBags) {
      openToast({
        messages: [
          t(
            'brands.newBrandForm.cancelDayDiet',
            'Brak dostępu do kalendarza automatycznie wyklucza możliwość samodzielnej rezygnacji z dnia diety przez klienta'
          ),
        ],
        type: 'warning',
        autoHideDuration: TOAST_DURATIONS.LG,
      });
    }
    if (brandConfig.allowChangeDeliveryDate) {
      setConfigClientPanelModule(prevState => ({
        ...prevState,
        allowCancelBags: false,
      }));
    }
  };
  const handleSubmit = async () => {
    if (
      brandConfig.showInformation?.dishCompositionValue?.mode === 'OFF' &&
      brandConfig.showInformation?.dishComposition
    ) {
      openToast({
        messages: [t('notify.cannotSavePleaseCheckOneOptionDishComposition')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return;
    }

    try {
      await put(`/brands/${selectedBrand}`, brandConfig);
      await updateBrandConfigModulesPack(selectedBrand, {
        ConfigClientPanel: configClientPanelModule,
        PaymentModesCopy: paymentModesCopyModule,
        ItemPaymentTypes: paymentTypes,
        SeoCmsMainSite: module.configuration.SeoCmsMainSite,
        SeoCmsAddonsSide: module.configuration.SeoCmsAddonsSide,
        SeoCmsDietSide: module.configuration.SeoCmsDietSide,
        SeoCmsDishSide: module.configuration.SeoCmsDishSide,
        SeoCmsZonesSubpage: module.configuration.SeoCmsZonesSubpage,
        SeoCmsMenuSubpage: module.configuration.SeoCmsMenuSubpage,
        SeoCmsClientPanelLogin: module.configuration.SeoCmsClientPanelLogin,
        SeoCmsClientPanelRegister:
          module.configuration.SeoCmsClientPanelRegister,
        SeoCmsClientPanelMyAccount:
          module.configuration.SeoCmsClientPanelMyAccount,
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } catch (error) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  const handleCheckboxChange = (key, setState = setBrandConfig, ...params) => {
    setState(prevState => ({
      ...prevState,
      [key]: !prevState[key],
      params,
    }));
  };

  return (
    <PanelConfigurationContext.Provider
      value={{
        module,
        isLoading,
        handleChangeDietViewOption,
        handleGlycemicIndexChange,
        handleSummaryShowChange,
        handleInputChange,
        handleModuleUpdateConfigClientUpdate,
        setConfigClientPanelModuleImageByStateName,
        removeConfigClientPanelModuleImageByStateName,
        handleClientCanCanceledDietDay,
        handleSubmit,
        handleCheckboxChange,
        brandConfig,
        configClientPanelModule,
        setConfigClientPanelModule,
        setBrandConfig,
        setModule,
        choicesBackground,
        setChoicesBackground,
        isOpen,
        setIsOpen,
        uploadedContentBaseURL,
        choicesAlternatePhotoDish,
        setChoicesAlternatePhotoDish,
        backgroundDialogMode,
        setBackgroundDialogMode,
        backgroundToChoicedArray,
        alternatePhotoToDishArray,
      }}
    >
      {children}
    </PanelConfigurationContext.Provider>
  );
};

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands }) => ({
      selectedBrand,
      paymentTypes: Brands.paymentTypes,
      selectedLanguage: Brands.brand.multinational.defaultLanguage,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchPaymentTypes,
      updateBrandConfigModulesPack,
    }
  ),
  withToast
);

export const usePanelConfigurationContext = () =>
  useContext(PanelConfigurationContext);

export default enhance(PanelConfigurationContextProvider);

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    width: 50,
  },
  {
    title: 'form.field.name',
    name: 'name',
    accessor: 'name',
    filterable: true,
  },
  {
    title: 'form.field.code',
    name: 'code',
    accessor: 'code',
    filterable: false,
  },
  {
    title: 'common.mainMenu.mealCategory',
    name: 'mealCategory.value',
    accessor: 'mealCategory.value',
    filterable: true,
  },
  {
    title: 'form.field.order',
    name: 'position',
    accessor: 'position',
    filterable: false,
  },
];

export default columnConfig;

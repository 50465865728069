import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/CustomSelect/Select';

import AdditionalMealTypeBox from './AdditionalMealTypeBox';

const AdditionalMealTypeSection = ({
  diet = {},
  allowedMealTypes = [],
  updateDiet = () => {},
  calendarSettings,
  selectedOptions,
  setSelectedOptions,
  selectedDietAdditionalCalendar,
  setSelectedDietAdditionalCalendar,
  editAdditionalMealTypes,
  setEditAdditionalMealTypes,
}) => {
  const { t } = useTranslation();

  const { selectedAdditionalMealTypes } = diet ?? {};

  const [isOpened, setIsOpened] = useState(false);

  const selectMealTypes = newMealTypes => {
    const addedElement = newMealTypes.find(secondItem => {
      return !diet.selectedAdditionalMealTypes.some(
        firstItem => firstItem.value === secondItem.value
      );
    });

    if (addedElement) {
      addedElement.date = [];
      addedElement.dateWithAmount = [];

      newMealTypes.map(el => {
        if (el.value === addedElement.value) {
          return addedElement;
        }
        return el;
      });
    }

    setSelectedOptions(newMealTypes);
    return updateDiet({
      ...diet,
      selectedAdditionalMealTypes: newMealTypes,
    });
  };

  const onRemoveClick = id => {
    setSelectedOptions(selectedOptions.filter(option => option?.value !== id));
    return updateDiet({
      ...diet,
      selectedAdditionalMealTypes: selectedAdditionalMealTypes.filter(
        selectedMealType => selectedMealType?.value !== id
      ),
    });
  };

  const onRemoveDay = (day, mealType) => {
    const selectedOptionsAfterRemove = selectedOptions.map(option => {
      if (option.value === mealType) {
        const filteredDate = option.dateWithAmount.filter(dateObj => {
          return dateObj.date !== day;
        });
        option.dateWithAmount = filteredDate;
        option.date = option.date.filter(dateValue => dateValue !== day);
      }
      return option;
    });

    setSelectedOptions(selectedOptionsAfterRemove);

    return updateDiet({
      ...diet,
      selectedAdditionalMealTypes: selectedOptionsAfterRemove,
    });
  };

  return (
    <div
      style={{ marginTop: '15px' }}
      data-cy="new-order_additional-meal-types-select"
    >
      <Select
        label={t('orders.select', '$$Wybierz')}
        value={selectedOptions}
        multiple
        options={allowedMealTypes}
        handleChange={selectMealTypes}
      />
      {selectedAdditionalMealTypes?.map(selecteMealTypes => {
        return (
          <div style={{ margin: '15px 0' }}>
            <AdditionalMealTypeBox
              diet={diet}
              {...selecteMealTypes}
              calendarSettings={calendarSettings}
              onRemoveClick={onRemoveClick}
              onRemoveDay={onRemoveDay}
              updateDiet={updateDiet}
              selectedDietAdditionalCalendar={selectedDietAdditionalCalendar}
              setSelectedDietAdditionalCalendar={
                setSelectedDietAdditionalCalendar
              }
              isOpened={isOpened}
              setIsOpened={setIsOpened}
              editAdditionalMealTypes={editAdditionalMealTypes}
              setEditAdditionalMealTypes={setEditAdditionalMealTypes}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AdditionalMealTypeSection;

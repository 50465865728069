import {
  whiteColor,
  grayColor,
  infoColor,
} from 'assets/jss/material-dashboard-pro-react';

const navTabsStyle = theme => ({
  root: {
    marginTop: '-8px',
    paddingLeft: '5px',
    marginRight: '16px',
    marginBottom: '0',
    minHeight: 'unset',
    flexGrow: '1',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  displayNone: {
    display: 'none !important',
  },
  fixed: {
    overflowX: 'visible',
  },
  tabs: {
    position: 'relative',
    display: 'block',
    borderRadius: '6px',
    minWidth: '100px',
    textAlign: 'center',
    transition: 'all .3s',
    padding: '4px 30px 4px 20px',
    color: grayColor[6],
    height: 'auto',
    minHeight: 'unset',
    maxWidth: '100%',
    margin: '8px -5px 0px -5px',
    backgroundColor: whiteColor,
    overflow: 'visible !important',
    opacity: '1',
    zIndex: '2',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
    '&:not(:first-child):before': {
      content: '" "',
      backgroundColor: grayColor[15],
      height: '70%',
      width: '2px',
      position: 'absolute',
      left: '-2px',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: '1',
    },
    '&:last-child': {
      padding: '4px 20px',
    },
  },
  labelContainer: {
    padding: '0!important',
    color: 'inherit',
  },
  label: {
    lineHeight: '24px',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '500',
    position: 'relative',
    display: 'block',
    color: 'inherit',
  },
  contentWrapper: {
    marginTop: '12px',
  },
  selected: {
    '&,&:hover': {
      color: whiteColor,
    },
    '&:before': {
      visibility: 'hidden',
    },
    '&:after': {
      position: 'absolute',
      content: '" "',
      backgroundColor: infoColor[0],
      top: '0',
      left: '0',
      right: '10px',
      bottom: '0',
      borderRadius: '6px',
      zIndex: '-1',
    },
    '&:last-child:after': {
      right: '0',
    },
    '&+*:before': {
      display: 'none',
    },
  },
  tabsWrapper: { display: 'flex', justifyContent: 'space-between' },
  tabsTitle: { margin: '22px 0px 12px 0px', lineHeight: '0.75em' },
});

export default navTabsStyle;

import React, { useState, useEffect } from 'react';
import { get, put } from '../../helpers/apiHelpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SmsNotificationsCard from './SmsNotificationsCard';
import { connect } from 'react-redux';
import SmsNotificationForClientPanel from './SmsNotificationForClientPanel';
import { withToast } from 'material-ui-toast-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

const SmsNotifications = ({ user, t, openToast }) => {
  const [state, setState] = useState({
    clientSms: [],
    clientToggleableSms: [],
    defaultConfigFromBrand: null,
  });

  useEffect(() => {
    fetchSmsConfigurations();

    get(user.company, {
      properties: ['defaultConfigFromBrand'],
    }).then(res => {
      setState(prevState => ({
        ...prevState,
        defaultConfigFromBrand: res.defaultConfigFromBrand,
      }));
    });
  }, []);

  const fetchSmsConfigurations = () => {
    const keySmsToSettingsClientPanel = ['bag_delivered'];
    get('sms-configurations').then(res => {
      const configurations = res['hydra:member'].map(configuration => ({
        ...configuration,
        hasChanged: false,
        isHidden: true,
        contentType:
          configuration.managedBySystem ||
          (configuration.enabledForOffice && !configuration.enabledForClient)
            ? 'office'
            : 'client',
      }));

      const clientToggleableSms = res['hydra:member'].filter(sms =>
        keySmsToSettingsClientPanel.includes(sms.smsKey)
      );
      setState(pervState => ({
        ...pervState,
        clientSms: configurations,
        clientToggleableSms,
      }));
    });
  };

  const handleSubmitSmsAllow = (functionState, sms) => {
    if (!sms.enabledForClient && sms.allowClientToToggle) {
      openToast({
        messages: [t('smsNotifications.smsDisabled', 'Sms jest wyłączony')],
        type: 'warning',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return;
    }

    setState(prev => {
      return {
        ...prev,
        clientToggleableSms: prev.clientToggleableSms.map(item => {
          if (item.id === sms.id) {
            return {
              ...item,
              hasChanged: false,
            };
          }
          return item;
        }),
      };
    });

    put(sms['@id'], {
      allowClientToToggle: sms?.allowClientToToggle,
    })
      .then(res => {
        openToast({
          messages: [t('notify.changesSaved', 'Zmiany zostały zapisane')],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.LG,
        });
        window.location.reload();
      })
      .catch(err => {
        openToast({
          messages: [t('notify.noChangesSaved', 'Zmiany nie zostały zapisane')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.LG,
        });
      });
  };

  return (
    <>
      <SmsNotificationsCard
        title={t(
          'smsNotifications.cardTitle.client',
          'Powiadomienia SMS (dla klienta)'
        )}
        setState={setState}
        sms={state.clientSms}
        clientToggleableSms={state.clientToggleableSms}
        defaultConfigFromBrand={state.defaultConfigFromBrand}
      />
      <SmsNotificationForClientPanel
        state={state.clientToggleableSms}
        setState={setState}
        handleSubmit={handleSubmitSmsAllow}
      />
    </>
  );
};
// }

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
    user: state.Auth.user,
  };
};

const enhance = compose(
  withTranslation(),
  withToast,
  connect(mapStateToProps, null)
);

export default enhance(SmsNotifications);

import isEmpty from 'lodash/isEmpty';

import StatusPicker from 'views/CateringsFlow/common/StatusPicker/StatusPicker';
import SelectFilter from 'components/Grid/Filter/Select';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';

import EmployeePicker from 'views/CateringsFlow/common/EmployeePicker/EmployeePicker';
import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import CATERINGS_FLOW_STATUSES_LABELS from 'views/CateringsFlow/consts/cateringsFlowStatusesLabels';
import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_BAGS_PACKHOUSE } from 'helpers/roles';
import { amountOfDoneSelectedColor } from 'views/CateringsFlow/utils/AmountOfDoneSelectedColor';

const columnConfig = ({
  t,
  employees,
  onStatusChangeAction,
  onEmployeeChangeAction,
}) => [
  {
    title: 'ID',
    name: 'id',
    accessor: 'id',
    filterable: true,
    sortable: true,
  },
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.name',
      '$$ID Nazwa'
    ),
    name: 'name',
    accessor: row => <b>{row?.name ?? row?.diet?.name ?? '_'}</b>,
    filterable: true,
    sortable: true,
  },
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.countToDo',
      '$$Ilość do zrobienia'
    ),
    name: 'amountToMake',
    accessor: row => (
      <b>
        {t('$*cateringsFlow.bagsPackhouse.dietsList.bagsCount', {
          count: row.amountToMake ?? 0,
        })}
      </b>
    ),
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countToDoAfterUpdate',
      '$$Ilość do zrobienia 2'
    ),
    name: 'amountToMakeAfterRefreshedAmounts',
    accessor: row => (
      <b>
        {t('$*cateringsFlow.bagsPackhouse.dietsList.bagsCount', {
          count: row.amountToMakeAfterRefreshedAmounts ?? 0,
        })}
      </b>
    ),
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.countDone',
      '$$Ilość zrobiona'
    ),
    name: 'amountOfDone',
    accessor: row => (
      <div
        style={{ color: amountOfDoneSelectedColor(row), fontWeight: 'bold' }}
      >
        {t('$*cateringsFlow.bagsPackhouse.dietsList.bagsCount', {
          count: row?.amountOfDone ?? 0,
        })}
      </div>
    ),
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.difference',
      '$$Róźnica'
    ),
    name: 'diff',
    accessor: row =>
      `${
        (row?.amountOfDone ?? 0) -
        (row?.amountToMakeAfterRefreshedAmounts
          ? row?.amountToMakeAfterRefreshedAmounts
          : row?.amountToMake ?? 0)
      }`,
    filterable: false,
    sortable: true,
  },
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.note',
      '$$Treść notatki'
    ),
    name: 'comment',
    accessor: 'comment',
    filterable: true,
    sortable: false,
  },
  {
    title: t(
      '$*cateringsFlow.bagsPackhouse.dietsList.column.status',
      '$$Status'
    ),
    name: 'status',
    accessor: row => {
      return (
        <StatusPicker
          row={row}
          initialStatus={row?.status}
          onStatusChangeAction={onStatusChangeAction}
          isPickingEnabled={
            row?.status !== 'CREATED' && isGranted(ROLE_EDIT_BAGS_PACKHOUSE)
          }
        />
      );
    },
    width: 150,
    filterable: true,
    sortable: true,
    Filter: ({ onChange, filter }) => {
      const options = (Object.values(CATERINGS_FLOW_STATUSES) ?? []).map(
        status => {
          const translation = CATERINGS_FLOW_STATUSES_LABELS[status];

          return {
            '@id': status,
            name: t(translation?.translationKey, translation?.defaultValue),
          };
        }
      );

      return (
        <SelectFilter
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
  },
  {
    title: t(
      '$*cateringsFlow.kitchen.recipiesList.column.employee',
      '$$Pracownik'
    ),
    name: 'assignedEmployee',
    accessor: row => {
      return (
        <EmployeePicker
          row={row}
          employees={employees.map(employee => ({
            contentUrl: employee?.contentUrl,
            label: employee?.value,
            value: employee?.['@id'],
          }))}
          isPickingEnabled={isGranted(ROLE_EDIT_BAGS_PACKHOUSE)}
          initialEmployee={
            isEmpty(row?.assignedEmployee)
              ? {}
              : {
                  contentUrl: row?.assignedEmployee?.image?.contentUrl,
                  label: `${row?.assignedEmployee?.firstName} ${row?.assignedEmployee?.lastName}`,
                  value: row?.assignedEmployee?.['@id'],
                }
          }
          onEmployeeChangeAction={onEmployeeChangeAction}
        />
      );
    },
    filterable: true,
    sortable: true,
    width: 85,
  },
];

export default columnConfig;

import types from 'helpers/constants';

const initialState = {
  dishes: [],
  dish: {},
  loading: true,
};

function Dishes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DISHES:
      return {
        ...state,
        dishes: action.payload.dishes,
        pages: action.payload.pages,
        loading: false,
      };
    case types.FETCH_DISH:
      return {
        ...state,
        dish: action.payload.dish,
        loading: false,
      };
    case types.REMOVE_DISH:
      return {
        ...state,
        dishes: state.dishes.filter(
          dish => dish.id !== action.payload.removedItemId
        ),
      };
    default:
      return state;
  }
}

export default Dishes;

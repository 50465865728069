import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import FormTextInput from 'components/FormTextInput/FormTextInput';

const HotJarModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'HotJarModule',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={e =>
              handleModuleUpdate({ ...module, enabled: !module.enabled })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('brands.newBrandForm.useHotjar', 'Używaj Hotjar')}
      />
      <FormTextInput
        label="Hotjar ID"
        classes={classes}
        name="hotJarId"
        value={module.id}
        maxLength={64}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            id: e.target.value,
          })
        }
        inputSize={12}
      />
      <FormTextInput
        label="Hotjar version"
        classes={classes}
        name="hotJarVersion"
        value={module.version}
        maxLength={64}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            version: e.target.value,
          })
        }
        inputSize={12}
      />
    </Fragment>
  );
};

export default HotJarModule;

import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import translateColumnConfig from './columnConfig';
import { trimEmptyStringsFromQuery } from 'helpers/helpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import NotesModal from './Details/notesModal';
import NotesEditModal from './Details/notesEditModal';

class List extends Component {
  state = {
    notesModalStatus: false,
    editModalStatus: false,
    editModalData: {},
    notes: [],
    client: {},
  };

  handleClickOpenModal = client => {
    this.setState({ notesModalStatus: true, client });
  };

  setNotes = notes => {
    this.setState({
      notes,
    });
  };

  setNotesModalStatus = notesModalStatus => {
    this.setState({
      notesModalStatus,
    });
  };

  setEditModalStatus = editModalStatus => {
    this.setState({ editModalStatus });
  };

  setEditModalData = editModalData => {
    this.setState({ editModalData });
  };
  render() {
    const { classes, t } = this.props;
    return (
      <AdminTable title={t('diets.dietList')}>
        <NotesModal
          classes={classes}
          client={this.state.client}
          openToast={this.props.openToast}
          setNotes={this.setNotes}
          notesModalStatus={this.state.notesModalStatus}
          setNotesModalStatus={this.setNotesModalStatus}
          editModalStatus={this.state.editModalStatus}
          setEditModalStatus={this.setEditModalStatus}
          setEditModalData={this.setEditModalData}
          t={t}
        />
        {this.state.editModalStatus ? (
          <NotesEditModal
            classes={classes}
            client={this.state.client}
            openToast={this.props.openToast}
            setNotes={this.setNotes}
            editModalStatus={this.state.editModalStatus}
            setEditModalStatus={this.setEditModalStatus}
            setNotesModalStatus={this.setNotesModalStatus}
            editModalData={this.state.editModalData}
            t={t}
          />
        ) : null}
        <DataGrid
          actions={true}
          export={true}
          previewPath="/admin/ecommerce-diets/preview"
          paginationTop={true}
          paginationBottom={false}
          url="/ecommerce-diets"
          reportName={'diet'}
          role="ORDER"
          columns={translateColumnConfig({
            t,
            handleOpenNotes: this.handleClickOpenModal,
          })}
          defaultHiddenColumns={['name', 'priceAfterDiscount']}
          minRows={20}
          manipulateQuery={(requestData, query) => {
            if (query['client.activeDietsCount']) {
              const filters = query['client.activeDietsCount'];
              delete query['client.activeDietsCount'];
              if (filters.valueFrom) {
                query['client.activeDietsCount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['client.activeDietsCount[lte]'] = filters.valueTo;
              }
            }

            if (query['priceAfterDiscount']) {
              const filters = query['priceAfterDiscount'];
              delete query['priceAfterDiscount'];
              if (filters.valueFrom) {
                query['priceAfterDiscount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['priceAfterDiscount[lte]'] = filters.valueTo;
              }
            }

            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;

              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }

            if (query['client.firstName']) {
              const splicedName = query['client.firstName'].split(' ');
              let _andX = [
                [
                  {
                    'client.firstName': splicedName[0],
                    'client.lastName': splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0]['client.lastName'] = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query['client.firstName'];
              query[operator] = _andX;
            }

            if (query['order[address]']) {
              const desc = query['order[address]'];
              delete query['order[address]'];
              query['order[address.city]'] = desc;
              query['order[address.street]'] = desc;
            }

            if (query['address']) {
              const search = query['address'];
              const searchString = String(search).replace(',', ' ');
              delete query['address'];
              let splicedSearch = searchString.split(' ');
              splicedSearch = trimEmptyStringsFromQuery(splicedSearch);

              let orX = [
                [
                  {
                    'address.city': splicedSearch,
                    'address.street': splicedSearch,
                    'address.postCode': splicedSearch,
                    'address.buildNumber': splicedSearch,
                    'address.placeNumberNumber': splicedSearch,
                    'pickUpPoint.value': splicedSearch,
                  },
                ],
              ];

              query['_orX'] = (query['_orX'] || []).concat(orX);
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(buttonsStyle), withToast);

export default enhance(List);

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import SelectInput from 'components/FormSelect/SelectInput';
import CardWrapper from 'components/Card/CardWrapper';

const ApperanceSettings = ({ t, classes, modules, setModules }) => {
  return (
    <CardWrapper
      title={t(
        'brandCfg.clientPanelDesign.componentsView',
        '$$Wygląd komponentów'
      )}
    >
      <CardBody>
        <GridContainer>
          <GridItem md={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'module.configClientPanel.dietTileMobileView',
                '$$Wygląd kafelka diety na widoku mobilnym'
              )}
            </FormLabel>

            <SelectInput
              noGrid
              classes={classes}
              mapBy="label"
              trackBy="value"
              name="orderFormDietSectionBoxTypeMobile"
              value={
                modules?.ConfigClientPanel?.orderFormDietSectionBoxTypeMobile
              }
              options={[
                {
                  label: t(
                    'module.configClientPanel.orderFormDietSectionBoxTypeMobile.bigBox',
                    '$$Duże zdjęcie'
                  ),
                  value: 'BIG_BOX',
                },
                {
                  label: t(
                    'module.configClientPanel.orderFormDietSectionBoxTypeMobile.smallBox',
                    '$$Małe zdjęcie'
                  ),
                  value: 'SMALL_BOX',
                },
              ]}
              handleChange={e => {
                setModules(prevState => ({
                  ...prevState,
                  ConfigClientPanel: {
                    ...prevState.ConfigClientPanel,
                    orderFormDietSectionBoxTypeMobile: e.target.value,
                  },
                }));
              }}
              size={12}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default ApperanceSettings;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  AnimatedColon,
  ClocksContainer,
  TimeWarning,
} from './RouteManager.styles';

const clocksTypes = ['newOrder', 'changeDate', 'changeAddress'];

const Clocks = ({ t, selectedDate, remainingTimes }) => {
  const [clocks, setClocks] = useState([]);

  useEffect(() => {
    if (!selectedDate || !Object.keys(remainingTimes).length) {
      return null;
    }

    const targetWeekDay = moment(selectedDate)
      .locale('en')
      .format('dddd')
      .toLowerCase();

    const updateClocks = () => {
      setClocks(
        clocksTypes.map(clockType => {
          const targetDate = moment(selectedDate).subtract(
            remainingTimes[targetWeekDay][clockType],
            'minutes'
          );

          const duration = moment.duration(targetDate.diff(moment()));

          return [duration.days(), duration.hours(), duration.minutes()];
        })
      );
    };

    updateClocks();

    const clocksInterval = setInterval(updateClocks, 60 * 1000);

    return () => {
      clearInterval(clocksInterval);
    };
  }, [selectedDate, remainingTimes]);

  return (
    <ClocksContainer>
      {clocks?.map((clock, index) => (
        <tr key={index}>
          <td>{t(`routeManager.firstSection.table.label${index}`)}:</td>
          <td>
            {clock[0]}d<AnimatedColon> : </AnimatedColon>
            {clock[1]}h<AnimatedColon> : </AnimatedColon>
            {clock[2]}m{' '}
            {clock[2] < 0 && (
              <TimeWarning>
                {t('routeManager.firstSection.table.warning')}
              </TimeWarning>
            )}
          </td>
        </tr>
      ))}
    </ClocksContainer>
  );
};

const enhance = compose(withTranslation());

export default enhance(Clocks);

import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  statisticSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-5px',
  },
  statisticSummaryTitle: { flex: '0 0 45%', marginBottom: '10px' },
  statisticSummaryValue: { flex: '0 0 55%', marginBottom: '10px' },
});
const PlannerSummaryItem = ({ title, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.statisticSummary}>
      <span className={classes.statisticSummaryTitle}>{title}:</span>
      <span className={classes.statisticSummaryValue}>{value}</span>
    </div>
  );
};

export default PlannerSummaryItem;

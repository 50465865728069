import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { put } from 'helpers/apiHelpers';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import S from './AuthStyled';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class ResetPassword extends React.Component {
  state = {
    cardAnimation: 'cardHidden',
    newPassword: '',
    repeatNewPassword: '',
    changeSuccess: false,
    errorMessage: '',
    isLoading: false,
    passwordValidation: {
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      isValidLength: false,
      identicalPasswords: false,
      isUseSpecialChar: false,
    },
    buttonEnabled: false,
    showPasswordsNotMatchingMessage: false,
  };

  token = this.props.match.params.token;

  specialCharPattern = /[@#$%]+/;
  blockedCharPattern = /[\^&\*\(\)_\+\-=\[\]\{};':"\\|,.<>\/\?`~!]/;

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });

    this.validatePassword(ev.target.name, ev.target.value);
  };

  validatePassword = (name, value) => {
    const newValues = {};
    if (name === 'newPassword') {
      newValues.hasLowerCase = this.hasLowerCase(value);
      newValues.hasUpperCase = this.hasUpperCase(value);
      newValues.hasNumber = this.hasNumber(value);
      newValues.isValidLength = this.isValidLength(value);
      newValues.identicalPasswords = value === this.state.repeatNewPassword;
      newValues.isUseSpecialChar =
        this.specialCharPattern.test(value) &&
        !this.blockedCharPattern.test(value);
    } else {
      newValues.identicalPasswords = this.state.newPassword === value;
    }

    this.setState(prevState => ({
      ...prevState,
      passwordValidation: {
        ...prevState.passwordValidation,
        ...newValues,
      },
    }));
  };

  validateButton = () => {
    const buttonEnabled =
      this.state.passwordValidation.hasLowerCase &&
      this.state.passwordValidation.hasUpperCase &&
      this.state.passwordValidation.hasNumber &&
      this.state.passwordValidation.isValidLength &&
      this.state.passwordValidation.identicalPasswords &&
      this.state.passwordValidation.isUseSpecialChar;

    return !buttonEnabled;
  };

  handleSubmit = ev => {
    ev.preventDefault();

    this.setState({ isLoading: true });

    put('/change-password', {
      plainPassword: this.state.newPassword,
      token: this.token,
    })
      .then(
        response => {
          this.setState({
            isLoading: false,
            newPassword: '',
            changeSuccess: true,
          });
        },
        error => {
          if (error.response && error.response.status === 400)
            this.setState({
              isLoading: false,
              errorMessage: error.response.data.violations[0].message,
            });
        }
      )
      .then(() => this.props.history.push('/auth/login'));
  };

  hasLowerCase = str => {
    return str.toUpperCase() !== str;
  };

  hasUpperCase = str => {
    return str.toLowerCase() !== str;
  };

  hasNumber = str => {
    return /\d/.test(str);
  };

  isValidLength = str => {
    return str.length >= 8;
  };

  checkValidationRules = stateName => {
    const { passwordValidation, newPassword } = this.state;

    if (passwordValidation[stateName]) {
      return 'isValid';
    }

    if (newPassword) {
      return 'isInvalid';
    }

    return '';
  };

  comparePasswords = () => {
    if (
      this.state.newPassword &&
      this.state.repeatNewPassword &&
      !this.state.passwordValidation.identicalPasswords
    ) {
      this.setState({ showPasswordsNotMatchingMessage: true });
    } else {
      this.setState({ showPasswordsNotMatchingMessage: false });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <S.AuthHeader>{t('common.passwordResetForm.header')}</S.AuthHeader>

        <S.AuthParagraph icon>
          {t('common.passwordResetForm.infoMessage')}
        </S.AuthParagraph>

        <S.AuthForm changePassword onSubmit={this.handleSubmit}>
          <S.AuthInput>
            <input
              type="password"
              className={`form__input ${
                this.state.newPassword ? 'label-top' : ''
              }`}
              autoComplete="off"
              name="newPassword"
              id="password"
              placeholder=" "
              value={this.state.newPassword}
              onChange={this.handleChange}
            />
            <label className="form__label" htmlFor="password">
              {t('common.passwordResetForm.passwordInputFieldLabel')}
            </label>
          </S.AuthInput>

          <S.AuthCheckboxWrapper>
            <p className={this.checkValidationRules('hasUpperCase')}>
              {t('common.passwordResetForm.passwordValidation.uppercaseLetter')}
            </p>
            <p className={this.checkValidationRules('hasLowerCase')}>
              {t('common.passwordResetForm.passwordValidation.lowercaseLetter')}
            </p>
            <p className={this.checkValidationRules('hasNumber')}>
              {t('common.passwordResetForm.passwordValidation.number')}
            </p>
            <p className={this.checkValidationRules('isValidLength')}>
              {t('common.passwordResetForm.passwordValidation.characterNumber')}
            </p>
            <p className={this.checkValidationRules('isUseSpecialChar')}>
              {t('common.passwordResetForm.passwordValidation.specialChar')}
            </p>
          </S.AuthCheckboxWrapper>

          <S.AuthInput>
            <input
              type="password"
              className={`form__input ${
                this.state.repeatNewPassword ? 'label-top' : ''
              }`}
              autoComplete="off"
              name="repeatNewPassword"
              id="repeat-password"
              placeholder=" "
              value={this.state.repeatNewPassword}
              onChange={this.handleChange}
              onBlur={this.comparePasswords}
            />
            <label className="form__label" htmlFor="repeat-password">
              {t('common.passwordResetForm.repeatPasswordInputFieldLabel')}
            </label>
          </S.AuthInput>

          {this.state.showPasswordsNotMatchingMessage && (
            <S.ErrorMessage>
              {t('common.passwordResetForm.passwordsNotMatchingErrorMsg')}
            </S.ErrorMessage>
          )}

          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <S.AuthButton type="submit" disabled={this.validateButton()}>
              {t('common.passwordResetForm.changePassword')}
            </S.AuthButton>
          )}

          <S.AuthLink onClick={() => this.props.history.push('/auth/login')}>
            {t('common.passwordResetForm.cancel')}
          </S.AuthLink>
        </S.AuthForm>
      </>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withStyles(loginPageStyle),
  withTranslation()
);
export default enhance(ResetPassword);

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import React from 'react';
import CardWrapper from 'components/Card/CardWrapper';

const BannerSettings = ({
  t,
  classes,
  state,
  handleInputChange,
  handleToggle,
  getImage,
  removeImage,
}) => {
  return (
    <CardWrapper title={t('brandCfg.view.orderForm')}>
      <CardBody>
        <GridContainer>
          <GridItem md={6}>
            <FormTextInput
              multiline
              rows={3}
              rowsMax={10}
              label={
                <div style={{ display: 'flex' }}>
                  <span>{t('brandCfg.captionInBanner')}</span>
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.captionInBannerTooltip')}</h4>
                          <h4>
                            {t('brandCfg.etc')}{' '}
                            {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt nagłówka</div>`}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
              classes={classes}
              name="newOrderInfoTitle"
              value={state.newOrderInfoTitle}
              handleChange={handleInputChange}
              inputSize={12}
              maxLength={160}
            />
            <FormTextInput
              label={
                <div style={{ display: 'flex' }}>
                  <span>{t('brandCfg.textUnderCaption')}</span>
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                          <h4>
                            {t('brandCfg.etc')}{' '}
                            {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
              classes={classes}
              name="newOrderInfoDescription"
              value={state.newOrderInfoDescription}
              handleChange={handleInputChange}
              inputSize={12}
              maxLength={1000}
              rows={3}
              rowsMax={20}
              multiline={true}
            />
          </GridItem>

          <GridItem md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('newOrderInfoEnabled')}
                  checked={state.newOrderInfoEnabled}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('brandCfg.infoBannerShow.short')}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                textAlign: 'center',
              }}
            >
              <div>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginTop: '15px', marginBottom: '24px' }}
                >
                  {t('brandCfg.bannerPanorama')}
                </FormLabel>
                <FormImageUpload
                  classes={classes}
                  stateName="newOrderInfoBannerDesktop"
                  buttonText={t('common.shared.select', 'Wybierz')}
                  getImage={getImage}
                  imgId="desktop-banner"
                  tooltipImageSize={t('brandCfg.widthMinPanorama')}
                  removeImage={removeImage}
                  previewUrl={state.newOrderInfoBannerDesktopUrl}
                />
              </div>
              <div>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginTop: '15px', marginBottom: '24px' }}
                >
                  {t('brandCfg.bannerPortrait')}
                </FormLabel>
                <FormImageUpload
                  classes={classes}
                  stateName="newOrderInfoBannerMobile"
                  buttonText={t('common.shared.select', 'Wybierz')}
                  getImage={getImage}
                  imgId="mobile-banner"
                  tooltipImageSize={t('brandCfg.widthPortrait')}
                  removeImage={removeImage}
                  previewUrl={state.newOrderInfoBannerMobileUrl}
                />
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default BannerSettings;

import types from 'helpers/constants';

const initialState = {
  variants: [],
  variant: {},
  loading: true,
};

function Variants(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_VARIANTS:
      return {
        ...state,
        variants: action.payload.variants,
        loading: false,
      };
    case types.CLEAR_VARIANTS:
      return {
        ...state,
        variants: [],
      };
    case types.FETCH_VARIANT:
      return {
        ...state,
        loading: false,
        variant: action.payload.variant,
      };
    case types.REMOVE_VARIANT:
      return {
        ...state,
        variants: state.variants.filter(
          variant => variant.id !== action.payload.variantId
        ),
      };
    default:
      return state;
  }
}

export default Variants;

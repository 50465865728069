import types from 'helpers/constants';

const initialState = {
  purposes: [],
  loading: true,
};

function Purposes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PURPOSES:
      return {
        ...state,
        purposes: action.payload.purposes,
        loading: false,
      };
    case types.REMOVE_PURPOSE:
      return {
        ...state,
        purposes: state.purposes.filter(
          purpose => purpose.id !== action.payload.removedItemId
        ),
      };
    case types.LOADING_PURPOSES:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default Purposes;

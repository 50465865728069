import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import { get } from 'helpers/apiHelpers';
import { ROLE_SHOW_BAGS_PACKHOUSE } from 'helpers/roles';

import withRole from 'components/Security/withRole';

import TasksListDetails from 'views/CateringsFlow/common/TasksListDetails/TasksListDetails';
import BagsPackhouseTasksList from 'views/CateringsFlow/BagsPackhouse/components/BagsPackhouseTasksList/BagsPackhouseTasksList';

const BagsPackhouseTaskPreview = () => {
  const match = useRouteMatch();

  const [tasksListDetails, setTasksListDetails] = useState({});

  const ListSecured = withRole(
    ROLE_SHOW_BAGS_PACKHOUSE,
    BagsPackhouseTasksList
  );

  useEffect(() => {
    fetchTask();
  }, []);

  const fetchTask = async () => {
    try {
      const response = await get(`/task-lists/${match?.params?.id}`);

      setTasksListDetails(response);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <TasksListDetails
        {...tasksListDetails}
        status={tasksListDetails?.taskListStatuses?.packDiet}
      />
      <ListSecured tasksListId={match?.params?.id} />
    </>
  );
};

export default BagsPackhouseTaskPreview;

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { combineStyles } from '../../helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const styles = () => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

class AssignZonesSection extends Component {
  handleAssignZones = () => {
    return this.props.handleAssignZones();
  };

  render() {
    const { t } = this.props;

    return (
      <GridContainer>
        <GridItem md={12} data-cy="__assign_zones">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color={'primary'}
              onClick={this.handleAssignZones}
              disabled={this.props.disabled}
            >
              {t('addresses.assignZones')}
            </Button>
            <div style={{ marginLeft: '10px' }}>
              <Tooltip
                title={
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: t('addresses.click'),
                    }}
                  />
                }
                placement="right"
              >
                <Info
                  fontSize="small"
                  style={{
                    color: 'grey',
                    display: 'block',
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

const combinedStyles = combineStyles(styles, buttonsStyle, extendedFormsStyle);
const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(AssignZonesSection);

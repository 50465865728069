import React, { useEffect, useState } from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import { get, put } from '../../helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../components/DialogLoader';
import InfoBanner from 'components/CMS/InfoBanner';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import { withToast } from 'material-ui-toast-redux';
import PresentationUrl from 'components/CMS/PresentationUrl';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';

const initialAddonState = {
  enabled: false,
  showBanner: false,
  bannerTitle: '',
  bannerSubTitle: '',
  bannerDesktop: null,
  bannerMobile: null,
};

const AddonsCmsPage = ({ classes, t, selectedBrand, openToast }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addon, setAddon] = useState(initialAddonState);
  const [enabledStateWithApi, setEnabledStateWithApi] = useState(false);
  const [listPageAddon, setListPageAddon] = useState(initialAddonState);
  const [module, setModule] = useState({});

  useEffect(() => {
    (async () => {
      const {
        configuration: { SeoAddon },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoAddon`);

      const getBanner = type =>
        SeoAddon[type] ? SeoAddon['@resources'][SeoAddon[type]] : null;

      const addonData = {
        enabled: SeoAddon.enabled,
        showBanner: SeoAddon.showBanner,
        bannerTitle: SeoAddon.bannerTitle,
        bannerSubTitle: SeoAddon.bannerSubTitle,
        bannerDesktop: getBanner('bannerDesktop'),
        bannerMobile: getBanner('bannerMobile'),
      };

      const listPageAddonData = {
        enabled: SeoAddon.listPageEnabled,
        showBanner: SeoAddon.listPageShowBanner,
        bannerTitle: SeoAddon.listPageBannerTitle,
        bannerSubTitle: SeoAddon.listPageBannerSubTitle,
        bannerDesktop: getBanner('listPageBannerDesktop'),
        bannerMobile: getBanner('listPageBannerMobile'),
      };

      const modulesConfig = await get(`brands/${selectedBrand}/modules`);
      setModule(modulesConfig);

      setAddon(addonData);
      setListPageAddon(listPageAddonData);
      setEnabledStateWithApi(SeoAddon.listPageEnabled);
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const {
        configuration: { SeoAddon },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoAddon`);
      setEnabledStateWithApi(SeoAddon.listPageEnabled);
    };
    fetchData();
  }, [isSubmitting]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    put(`brands/${selectedBrand}/modules`, {
      configuration: {
        SeoCmsAddonsSide: module.configuration.SeoCmsAddonsSide,
      },
    }).then(response => {
      openToast({
        messages: [
          t('status.success.save', 'Zmiany zostały pomyślnie zapisane.'),
        ],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      setIsSubmitting(false);
    });

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoAddon',
        configuration: {
          SeoAddon: {
            enabled: addon.enabled,
            showBanner: addon.showBanner,
            bannerTitle: addon.bannerTitle,
            bannerSubTitle: addon.bannerSubTitle,
            bannerDesktop: addon.bannerDesktop?.['@id'],
            bannerMobile: addon.bannerMobile?.['@id'],

            listPageEnabled: listPageAddon.enabled,
            listPageShowBanner: listPageAddon.showBanner,
            listPageBannerTitle: listPageAddon.bannerTitle,
            listPageBannerSubTitle: listPageAddon.bannerSubTitle,
            listPageBannerDesktop: listPageAddon.bannerDesktop?.['@id'],
            listPageBannerMobile: listPageAddon.bannerMobile?.['@id'],
          },
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getImage = (name, data, setState) => {
    if (name === 'infoBannerDesktop') {
      setState(prev => ({ ...prev, bannerDesktop: data }));
    }

    if (name === 'infoBannerMobile') {
      setState(prev => ({ ...prev, bannerMobile: data }));
    }
  };

  const removeImage = (name, data, setState) => {
    if (name === 'infoBannerDesktop') {
      setState(prev => ({ ...prev, bannerDesktop: null }));
    }

    if (name === 'infoBannerMobile') {
      setState(prev => ({ ...prev, bannerMobile: null }));
    }
  };

  const handleBannerTextChange = (target, setState) => {
    if (target?.name === 'bannerHeader') {
      setState(prev => ({ ...prev, bannerTitle: target?.value }));
    }

    if (target?.name === 'bannerText') {
      setState(prev => ({ ...prev, bannerSubTitle: target?.value }));
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <CardWrapper title={t('cms.addons.addonPage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setListPageAddon(prev => ({
                        ...prev,
                        enabled: !prev.enabled,
                      }));
                    }}
                    checked={listPageAddon.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('cms.addons.addonPage.show')}
              />
            </GridItem>
            {listPageAddon.enabled && (
              <GridItem sm={12}>
                <PresentationUrl
                  subpage="/addons"
                  cmsShowActionButton={enabledStateWithApi}
                />
              </GridItem>
            )}
            <InfoBanner
              getImage={(name, data) => {
                getImage(name, data, setListPageAddon);
              }}
              bannerText={listPageAddon.bannerSubTitle}
              removeImage={(name, data) => {
                removeImage(name, data, setListPageAddon);
              }}
              bannerHeader={listPageAddon.bannerTitle}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, setListPageAddon);
              }}
              infoBannerCheckbox={listPageAddon.showBanner}
              infoBannerMobileUrl={listPageAddon.bannerMobile?.contentUrl}
              infoBannerDesktopUrl={listPageAddon.bannerDesktop?.contentUrl}
              handleCheckboxChange={() => {
                setListPageAddon(prev => ({
                  ...prev,
                  showBanner: !prev.showBanner,
                }));
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.addons.addonSubpage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setAddon(prev => ({ ...prev, enabled: !prev.enabled }));
                    }}
                    checked={addon.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.addons.addonSubpage.show')}{' '}
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('cms.addons.showAddonSubpage.tooltip')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </GridItem>
            <InfoBanner
              getImage={(name, data) => {
                getImage(name, data, setAddon);
              }}
              bannerText={addon.bannerSubTitle}
              removeImage={(name, data) => {
                removeImage(name, data, setAddon);
              }}
              bannerHeader={addon.bannerTitle}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, setAddon);
              }}
              infoBannerTooltip={t('cms.showInfoBanner.tooltip.addons')}
              infoBannerCheckbox={addon.showBanner}
              infoBannerMobileUrl={addon.bannerMobile?.contentUrl}
              infoBannerDesktopUrl={addon.bannerDesktop?.contentUrl}
              handleCheckboxChange={() => {
                setAddon(prev => ({ ...prev, showBanner: !prev.showBanner }));
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      {/* SEO */}
      {module?.configuration?.SeoCmsAddonsSide && (
        <SEOSettingsCms
          state={module}
          setState={setModule}
          section="SeoCmsAddonsSide"
        />
      )}

      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(AddonsCmsPage);

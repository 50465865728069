import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import roles from 'helpers/roles';
import { isGranted } from 'helpers/helpers';
const TableHeadSection = ({ t }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{t('notify.whatMail')}</TableCell>
        <TableCell>{t('notify.client')}</TableCell>
        <TableCell>{t('notify.admin')}</TableCell>
        <TableCell>{t('notify.content')}</TableCell>
        {isGranted(roles.ROLE_EDIT_MAIL_CONFIGURATION) && (
          <TableCell>{t('notify.actions')}</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadSection;

import React from 'react';
import Button from 'components/CustomButtons/Button';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { createMenuTemplate, fetchMenuTemplate } from 'actions/MenuTemplates';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { Save } from '@material-ui/icons';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withTranslation } from 'react-i18next';
import TOAST_DURATIONS from 'helpers/toastDurations';

const BottomNavigationActionStyled = withStyles({
  root: {
    marginTop: 0,
  },
})(BottomNavigationAction);

class CreateMenuTemplate extends React.Component {
  state = {
    menuTemplateName: '',
    saveMenuTemplateModal: false,
    selectMenuTemplateModal: false,
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveMenuTemplate = async () => {
    let message;
    let type;

    this.setLoading(true);
    try {
      await this.props.createMenuTemplate({
        date: this.props.date,
        name: this.state.menuTemplateName,
      });
      message = this.props.t('menuPlanner.action.template.savedNew');
      type = 'success';
      this.closeCreateTemplateModal();
    } catch (e) {
      message = e.response.data.violations[0].message;
      type = 'error';
      this.closeCreateTemplateModal();
    }
    this.setLoading(false);

    this.props.openToast({
      messages: [message],
      type: type,
      autoHideDuration: TOAST_DURATIONS.SM,
    });
  };

  closeCreateTemplateModal = () =>
    this.setState(prevState => ({
      ...prevState,
      saveMenuTemplateModal: false,
    }));

  openCreateTemplateModal = () =>
    this.setState(prevState => ({ ...prevState, saveMenuTemplateModal: true }));

  render() {
    let { classes } = this.props;

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.saveMenuTemplateModal}
          onClose={this.closeCreateTemplateModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeCreateTemplateModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <h1>
              {this.props.t('menuPlanner.action.template.createNew.note')}
            </h1>
            <FormTextInput
              label={this.props.t('menuPlanner.action.template.createNew.name')}
              classes={classes}
              name="menuTemplateName"
              value={this.state.menuTemplateName}
              handleChange={this.handleInputChange}
              inputSize={12}
              maxLength={64}
            />
            <Button
              color={'success'}
              className={classes.marginLeft}
              onClick={this.saveMenuTemplate}
              disabled={this.state.menuTemplateName < 1 || this.state.loading}
            >
              {this.props.t('common.shared.save')}
            </Button>
          </DialogContent>
        </Dialog>
        <BottomNavigationActionStyled
          label={this.props.t(
            'menuPlanner.action.template.createNew.actionName'
          )}
          icon={<Save style={{ color: '#01a8bc' }} />}
          onClick={this.openCreateTemplateModal}
          showLabel={true}
          disabled={this.props.loading}
        />
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => {
  return {
    menuTemplate: state.MenuTemplates.menuTemplate,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuTemplate: date => dispatch(fetchMenuTemplate(date)),
  createMenuTemplate: data => dispatch(createMenuTemplate(data)),
});

export default withTranslation()(
  withStyles(combinedStyles)(
    connect(mapStateToProps, mapDispatchToProps)(withToast(CreateMenuTemplate))
  )
);

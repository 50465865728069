import React from 'react';
import { modulesNames } from '../consts';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { DialogLoader } from 'components/DialogLoader';
import LogoSettings from './components/LogoSettings';
import FontSettings from './components/FontSettings';
import BackgroundSettings from './components/BackgroundSettings';
import CssStyleComponents from './components/CssStyleComponents';
import HeaderSettings from './components/HeaderSettings';
import FooterSettings from './components/FooterSettings';
import ScriptsSettings from './components/ScriptsSettings';

const ClienPanelDesignView = ({ props }) => {
  const {
    state,
    setState,
    modules,
    setModules,
    isLoading,
    isColor,
    setIsColor,
    handleDeleteLink,
    disabledButton,
    setDisabledButton,
    Wrapper,
    getImage,
    removeIcon,
    removeImage,
    handleInputChange,
    onClickUp,
    updateModule,
    onClickDown,
    handleColorPicker,
    handleModuleUpdate,
    handleSubmit,
    classes,
    t,
    openToast,
  } = props;

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <LogoSettings
            classes={classes}
            t={t}
            getImage={getImage}
            removeImage={removeImage}
            state={state}
            handleInputChange={handleInputChange}
          />

          <FontSettings
            classes={classes}
            handleModuleUpdate={handleModuleUpdate}
            modules={modules}
            modulesNames={modulesNames}
          />

          <BackgroundSettings
            t={t}
            state={state}
            setState={setState}
            isColor={isColor}
            setIsColor={setIsColor}
            Wrapper={Wrapper}
            classes={classes}
            handleColorPicker={handleColorPicker}
            getImage={getImage}
            removeImage={removeImage}
            openToast={openToast}
          />

          <CssStyleComponents
            classes={classes}
            state={state}
            handleInputChange={handleInputChange}
          />

          <HeaderSettings
            t={t}
            classes={classes}
            modules={modules}
            setModules={setModules}
            modulesNames={modulesNames}
          />

          <FooterSettings
            t={t}
            classes={classes}
            state={state}
            setState={setState}
            handleDeleteLink={handleDeleteLink}
            setDisabledButton={setDisabledButton}
            disabledButton={disabledButton}
            handleInputChange={handleInputChange}
            updateModule={updateModule}
            removeIcon={removeIcon}
            onClickUp={onClickUp}
            onClickDown={onClickDown}
          />

          {/* SCRIPTS */}
          <ScriptsSettings
            t={t}
            classes={classes}
            state={state}
            handleInputChange={handleInputChange}
          />

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default ClienPanelDesignView;

import { get } from 'helpers/apiHelpers';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button.jsx';
import { useEffect } from 'react';

const CustomRemoveBody = ({
  classes,
  elementId,
  removeItem = () => {},
  closeModal = () => {},
  handleAfterRemove = () => {},
}) => {
  const { t } = useTranslation();
  const [ingredientOccurrence, setIngredientOccurrence] = useState({
    dishes: [],
    recipes: [],
  });

  useEffect(async () => {
    const ingredientId = elementId.split('/').pop();
    const newIngredientOccurrence = await get(
      `ingredient-usage/${ingredientId}`
    );

    setIngredientOccurrence({
      dishes: newIngredientOccurrence?.dishes ?? [],
      recipes: newIngredientOccurrence?.recipes ?? [],
    });
  }, []);

  const handleRemove = async () => {
    try {
      await removeItem(elementId);
      handleAfterRemove();
      closeModal();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <div>
      {!isEmpty(ingredientOccurrence?.dishes) && (
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t(
              '$*list.ingredientsList.customRemoveBody.occurresInDishes',
              '$$Występuje w daniach:'
            )}
          </span>{' '}
          {ingredientOccurrence?.dishes.map(({ name }) => name).join(', ')}
        </p>
      )}
      {!isEmpty(ingredientOccurrence?.recipes) && (
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t(
              '$*list.ingredientsList.customRemoveBody.occurresInRecipes',
              '$$Występuje w przepisach:'
            )}
          </span>{' '}
          {ingredientOccurrence?.recipes.map(({ name }) => name).join(', ')}
        </p>
      )}
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}
      >
        <Button
          color={'danger'}
          style={{ margin: '5px' }}
          className={classes.marginRight}
          onClick={closeModal}
        >
          {t('common.no', '$$NIE')}
        </Button>
        <Button
          color={'success'}
          className={classes.marginLeft}
          onClick={handleRemove}
        >
          {t('common.yes', '$$TAK')}
        </Button>
      </div>
    </div>
  );
};

export default CustomRemoveBody;

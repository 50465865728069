import React, { Component } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';

import { combineStyles } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import TOAST_DURATIONS from 'helpers/toastDurations';

const DietDelivery = WrappedComponent =>
  class extends Component {
    state = {
      deliveryPrices: [],
      isLoading: true,
    };

    componentDidMount() {
      Promise.all([
        get('/zone-costs', { pagination: false }),
        get('/zones?properties[]=name&properties[]=id', { pagination: false }),
      ]).then(([zoneCostsResponse, zoneDetailsResponse]) => {
        const zoneCosts = zoneCostsResponse['hydra:member'];
        const zoneDetails = zoneDetailsResponse['hydra:member'];

        const zones = zoneDetails.map(element => {
          return {
            hasChanged: false,
            zone: element['@id'],
            dietCostsId: _.get(
              zoneCosts.find(el => el.zone === element['@id']),
              '@id'
            ),
            name: element.name,
            vat: _.get(
              zoneCosts.find(el => el.zone === element['@id']),
              'vat',
              ''
            ),
            price: _.get(
              zoneCosts.find(el => el.zone === element['@id']),
              'price',
              ''
            ),
            _vat: _.get(
              zoneCosts.find(el => el.zone === element['@id']),
              'vat',
              ''
            ),
            _price: _.get(
              zoneCosts.find(el => el.zone === element['@id']),
              'price',
              ''
            ),
            cost: _.get(
              zoneCosts.find(el => el.zone === element['@id']),
              'cost',
              ''
            ),
          };
        });

        this.setState({
          deliveryPrices: zones,
          isLoading: false,
        });
      });
    }

    handleChange = (name, value, zone) => {
      const { deliveryPrices } = this.state;
      const zoneIndex = deliveryPrices.findIndex(
        price => price.zone === zone.zone
      );
      deliveryPrices[zoneIndex][name] = value;

      deliveryPrices[zoneIndex].hasChanged =
        deliveryPrices[zoneIndex][`_${name}`] !== parseFloat(value);

      this.setState({ deliveryPrices });
    };

    validateForm = () => {
      const { t } = this.props;
      const { deliveryPrices } = this.state;
      let isValid = true;

      if (
        deliveryPrices.some(
          deliveryPrice =>
            deliveryPrice.price === '' ||
            deliveryPrice.vat === '' ||
            deliveryPrice.cost === ''
        )
      ) {
        isValid = false;
        this.props.openToast({
          messages: [
            t('prices.toast.notEmpty', 'Pola Koszt i VAT nie mogą być puste'),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
      if (
        deliveryPrices.some(
          deliveryPrice =>
            isNaN(deliveryPrice.price) ||
            isNaN(deliveryPrice.vat) ||
            isNaN(deliveryPrice.cost)
        )
      ) {
        isValid = false;
        this.props.openToast({
          messages: [
            t('prices.toast.mustBeNumb', 'Pola Koszt i VAT muszą być liczbami'),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
      return isValid;
    };

    saveAll = () => {
      if (!this.validateForm()) {
        return;
      }

      const { deliveryPrices } = this.state;
      deliveryPrices.forEach(deliveryPrice => {
        if (deliveryPrice.price !== '') {
          deliveryPrice.price = parseFloat(deliveryPrice.price);
        }
        if (deliveryPrice.vat !== '') {
          deliveryPrice.vat = parseFloat(deliveryPrice.vat);
        }
        if (deliveryPrice.cost !== '') {
          deliveryPrice.cost = parseFloat(deliveryPrice.cost);
        }
      });

      return Promise.all(
        deliveryPrices.map(deliveryPrice => {
          if (deliveryPrice.hasChanged) {
            return deliveryPrice.dietCostsId !== undefined
              ? put(deliveryPrice.dietCostsId, deliveryPrice)
              : post('/zone-costs', deliveryPrice);
          }
          return null;
        })
      ).then(() => {
        deliveryPrices.forEach(price => {
          price.hasChanged = false;
        });
        this.props.openToast({
          messages: [
            this.props.t('success.changesSaved', 'Zmiany zostały zapisane'),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
        this.setState({
          deliveryPrices: deliveryPrices.map(price => ({
            ...price,
            _price: price.price,
            _vat: price.vat,
          })),
        });
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.state}
          classes={this.props.classes}
          handleChange={this.handleChange}
          saveAll={this.saveAll}
          openToast={this.props.openToast}
        />
      );
    }
  };

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

export default compose(
  withStyles(combinedStyles),
  withToast,
  withTranslation(),
  DietDelivery
);

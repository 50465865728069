import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ROLE_EDIT_COST } from 'helpers/roles';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { isGranted } from 'helpers/helpers';
import CustomInput from 'components/CustomInput/CustomInput';
import { useTranslation } from 'react-i18next';

const translateDiscountElementTypes = t => ({
  1: t('common.shared.percentage', 'Procentowy'),
  0: t('common.shared.amount', 'Kwotowy'),
});

const translateOptions = t => ({
  CHANGE_MENU: t(
    'additionalPrices.changeMenu',
    'Opcja zmiany menu - "Opcja Premium'
  ),
  ECO_CONTAINER: t('additionalPrices.ecoContainer', 'Opakowania ekologiczne'),
});

const AdditionalPricesRow = ({ point, classes, handleChange, saveRow }) => {
  const { t } = useTranslation();
  const options = translateOptions(t);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {options[point.systemValue] || point.value}
      </TableCell>
      <TableCell>
        {translateDiscountElementTypes(t)[point.isPercentage ? 1 : 0]}
      </TableCell>
      <TableCell>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: 'text',
            placeholder: t('common.shared.typeValue'),
            name: 'discount',
            value: point.discount,
            onChange: event => handleChange(event, point),
          }}
        />
      </TableCell>
      <TableCell>
        {point.systemValue.includes('CONTAINER') && (<CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: 'number',
              placeholder: t('common.shared.typeValue'),
              name: 'vatRate',
              value: point.vatRate,
              onChange: event => handleChange(event, point),
            }}
        />)}
      </TableCell>
      {isGranted(ROLE_EDIT_COST) && (
        <TableCell>
          <FormControlButtons
            classes={classes}
            handleSubmit={() => saveRow(point)}
            submitText={t('common.shared.save')}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default AdditionalPricesRow;

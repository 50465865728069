import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './List';
import Form from './Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_MEAL_TYPE,
  ROLE_EDIT_MEAL_TYPE,
  ROLE_SHOW_MEAL_TYPE,
} from 'helpers/roles';

const MealTypes = props => {
  const { history, match } = props;
  const ListSecured = withRole(ROLE_SHOW_MEAL_TYPE, List);
  const CreateForm = withRole(ROLE_CREATE_MEAL_TYPE, Form);
  const EditForm = withRole(ROLE_EDIT_MEAL_TYPE, Form);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <CreateForm {...props} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => <EditForm {...props} {...parentProps} />}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(MealTypes);

import { useTranslation } from 'react-i18next';

import EXTERNAL_URLS from 'constants/externalUrls';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';

const PayUInfoWithTooltip = () => {
  const { t } = useTranslation();

  return (
    <InfoWithTooltip
      link={EXTERNAL_URLS.payUPaymentConfig}
      title={t('paymentType.payUDescription')}
    />
  );
};

export default PayUInfoWithTooltip;

import React from 'react';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import AdditionalOfferTile from './AdditionalOfferTile';
import { GROUPED_ADDITIONAL_OFFER_ELEMENTS } from './consts/AdditionalOfferElements';

const AdditionalOfferPage = () => {
  return (
    <>
      {GROUPED_ADDITIONAL_OFFER_ELEMENTS.map(({ title, elements }) => (
        <>
          <h1>{title}</h1>
          <GridContainer>
            {elements.map(additionalOffer => (
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <AdditionalOfferTile {...additionalOffer} />
              </GridItem>
            ))}
          </GridContainer>
        </>
      ))}
    </>
  );
};

export default AdditionalOfferPage;

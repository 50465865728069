import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import ModuleNotLoaded from './ModuleNotLoaded';

const ScanAndSortModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  if (!module) {
    return <ModuleNotLoaded moduleName="ScanAndSortModule" />;
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={() =>
              handleModuleUpdate({
                ...module,
                enabled: !module.enabled,
              })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t(
          '$*analyticsTools.modules.scanAndSort.checkboxLabel',
          'Używaj ScanAdnSort'
        )}
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.url')}
        classes={classes}
        name="url"
        value={module.url}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            url: e.target.value,
          })
        }
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.token')}
        maxLength="none"
        classes={classes}
        name="token"
        value={module.token}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            token: e.target.value,
          })
        }
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.refreshToken')}
        maxLength="none"
        classes={classes}
        name="refreshToken"
        value={module.refreshToken}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            refreshToken: e.target.value,
          })
        }
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.id')}
        classes={classes}
        name="goodspeedBrandId"
        value={module.goodspeedBrandId}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            goodspeedBrandId: e.target.value,
          })
        }
      />
    </>
  );
};

export default ScanAndSortModule;

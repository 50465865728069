import React from 'react';
import AdminTable from 'layouts/AdminTable';

import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LogView from 'components/History/LogView';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';

const FormFiltersView = ({ props }) => {
  const {
    t,
    diets,
    classes,
    state,
    isLoading,
    isEdit,
    packageId,
    handleInputChange,
    handleSelect,
    getImage,
    removeImage,
    handleToggle,
    handleSubmit,
  } = props;
  return (
    <>
      {isLoading && (
        <AdminTable
          title={isEdit ? t('form.editFilter') : t('form.addNewFilter')}
        >
          <FormTextInput
            label={t('form.field.filterName') + '*'}
            classes={classes}
            name="name"
            value={state.name}
            handleChange={handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={t('form.field.order') + '*'}
            classes={classes}
            name="position"
            value={state.position}
            type="number"
            handleChange={handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={t('form.field.description')}
            classes={classes}
            name="description"
            value={state.description}
            handleChange={handleInputChange}
            inputSize={6}
            multiline={true}
            rows={3}
            rowsMax={20}
            maxLength={2000}
          />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.field.active.label')}
                <div style={{ marginLeft: '10px' }}>
                  <Tooltip
                    title={
                      <div>
                        <h4>{t('form.activeInfo')}</h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    name="active"
                    onClick={handleToggle}
                    checked={state.active}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={''}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <SelectInput
                name="diets"
                multiple
                classes={classes}
                label={
                  <div style={{ display: 'flex' }}>
                    <span>{t('form.field.dietsInFilter')} *</span>
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('form.dietsInPackageInfo')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
                mapBy="name"
                trackBy="@id"
                value={state.diets}
                disabledMenuItems={diets?.filter(diet => {
                  if (state.disabledDiets?.includes(diet['@id'])) {
                    return diet['@id'];
                  }
                  return null;
                })}
                options={diets.map(diet => {
                  return diet.active
                    ? diet
                    : {
                        ...diet,
                        name: `${diet.name} (${t('form.inactive')})`,
                      };
                })}
                handleChange={handleSelect}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <SelectInput
                name="disabledDiets"
                multiple
                classes={classes}
                label={
                  <FormLabel className={classes.labelHorizontal}>
                    {`${t('form.field.dietsGrayedOut')}`}
                  </FormLabel>
                }
                mapBy="name"
                trackBy="@id"
                value={state.disabledDiets}
                disabledMenuItems={diets.filter(diet => {
                  if (state.diets.includes(diet['@id'])) {
                    return diet['@id'];
                  }
                  return null;
                })}
                options={diets.map(diet => {
                  return diet.active
                    ? diet
                    : { ...diet, name: `${diet.name} (Nieaktywna)` };
                })}
                handleChange={handleSelect}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: '20px' }}>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.field.filterPhoto')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={5} style={{ marginLeft: '-15px' }}>
              <FormImageUpload
                classes={classes}
                stateName="image"
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={state.imageUrl}
              />
            </GridItem>
            <GridItem xs={12} sm={5} style={{ marginLeft: '-15px' }}>
              <FormImageUpload
                buttonText={t('form.field.selectIcon')}
                isIcon
                classes={classes}
                stateName="mainImage"
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={state.mainImageUrl}
              />
            </GridItem>
          </GridContainer>
        </AdminTable>
      )}

      {!isEdit && (
        <FormControlStickyButton
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/packages"
          handleSubmit={handleSubmit}
        />
      )}

      {isEdit && (
        <LogView
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/packages"
          handleSubmit={handleSubmit}
          iri={`/packages/${packageId}`}
        />
      )}
    </>
  );
};

export default FormFiltersView;

import MealRoute from './MealRoute';
import GroupOfFinishedProductRoute from './GroupOfFinishedProductRoute';

const Meals = props => {
  const isKwg = window.location.pathname.includes('kwg_group');
  return (
    <>
      {isKwg ? (
        <GroupOfFinishedProductRoute props={props} />
      ) : (
        <MealRoute props={props} />
      )}
    </>
  );
};

export default Meals;

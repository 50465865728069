import FormLabel from '@material-ui/core/FormLabel';
import addonPhotoTemplate from 'assets/img/addonPhotoTemplate.jpg';

import CustomInput from 'components/CustomInput/CustomInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';

import CardBody from 'components/Card/CardBody';
import { Info } from '@material-ui/icons';
import CardWrapper from 'components/Card/CardWrapper';
import { useTranslation } from 'react-i18next';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import classNames from 'classnames';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';

const defaultUsageOptions = [
  { name: 'form.field.defaultUsage.option.forBag', value: 'FOR_BAG' },
  { name: 'form.field.defaultUsage.option.forDiet', value: 'FOR_DIET' },
];

const excludedPlacesOptions = [
  { name: 'form.field.excludedPlaces.option.newOrder', value: 'NEW_ORDER' },
  { name: 'form.field.excludedPlaces.option.extendBag', value: 'EXTEND_BAG' },
];

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  tooltip: { marginLeft: '5px', fontSize: '1rem' },
  priorityLabel: { marginBottom: '9px' },
  codeLabel: { marginBottom: '7px' },
});

const ClientPanel = ({
  formData,
  handleChange,
  handleCheckboxClick,
  categories,
  getImage,
  removeImage,
  contractors,
  diets,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <CardWrapper
        title={t('views.addons.addons.section.clientPanel.title') + ':'}
      >
        <CardBody>
          <GridContainer>
            <GridItem sm={8}>
              <GridContainer>
                <GridItem sm={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.name.label')} *
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t('views.addons.addons.form.field.name.tooltip')}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    maxLength={70}
                    inputProps={{
                      name: 'name',
                      value: formData.name,
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.shortDescription.label')}
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t(
                            'views.addons.addons.form.field.shortDescription.tooltip'
                          )}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    maxLength={600}
                    inputProps={{
                      name: 'shortDescription',
                      value: formData.shortDescription,
                      onChange: handleChange,
                    }}
                  />
                </GridItem>

                <GridItem sm={3}>
                  <LabeledCheckbox
                    name="active"
                    onClick={handleCheckboxClick}
                    checked={formData.active}
                    label={
                      <>
                        {t('form.field.active.label')}
                        <Tooltip
                          className={classes.tooltip}
                          title={
                            <h4>
                              {t(
                                'views.addons.addons.form.field.active.tooltip'
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info fontSize="small" />
                        </Tooltip>
                      </>
                    }
                  />
                </GridItem>
                <GridItem sm={5}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.excludedPlaces.label')}
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t(
                            'views.addons.addons.form.field.excludedPlaces.tooltip'
                          )}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple
                    classes={classes}
                    name="excludedPlaces"
                    mapBy="name"
                    trackBy="value"
                    options={excludedPlacesOptions.map(({ name, value }) => ({
                      name: t(name),
                      value,
                    }))}
                    value={formData.excludedPlaces}
                    handleChange={handleChange}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.defaultUsage.label')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    name="defaultUsage"
                    mapBy="name"
                    trackBy="value"
                    options={defaultUsageOptions.map(({ name, value }) => ({
                      name: t(name),
                      value,
                    }))}
                    value={formData.defaultUsage}
                    handleChange={handleChange}
                  />
                </GridItem>

                <GridItem sm={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.category.label')}
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t('views.addons.addons.form.field.category.tooltip')}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    name="category"
                    mapBy="name"
                    options={categories}
                    value={formData.category}
                    handleChange={handleChange}
                  />
                </GridItem>
                <GridItem sm={2}>
                  <FormLabel
                    className={classNames(
                      classes.labelHorizontal,
                      classes.priorityLabel
                    )}
                  >
                    {t('form.field.priority.label')}
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t('views.addons.addons.form.field.priority.tooltip')}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'position',
                      value: formData.position,
                      onChange: handleChange,
                      type: 'number',
                    }}
                  />
                </GridItem>
                <GridItem sm={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.clientCost.label')} *
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'clientCost',
                      value: formData.clientCost,
                      onChange: handleChange,
                      type: 'number',
                      style: { marginTop: '9px' },
                    }}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.clientVat.label')} *
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t(
                            'views.addons.addons.form.field.clientVat.tooltip'
                          )}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'clientVat',
                      value: formData.clientVat,
                      onChange: handleChange,
                      type: 'number',
                      style: { marginTop: '9px' },
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem sm={4}>
              <GridContainer>
                <GridItem sm={12}>
                  <FormImageUpload
                    classes={classes}
                    stateName="image"
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={formData?.imageUrl}
                    defaultImage={addonPhotoTemplate}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.restrictedToDiets.label')}
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <h4>
                          {t(
                            'views.addons.addons.form.field.restrictedToDiets.tooltip'
                          )}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple
                    classes={classes}
                    name="restrictedToDiets"
                    mapBy="name"
                    trackBy="value"
                    options={diets.map(diet => ({
                      name: diet.name,
                      value: diet['@id'],
                    }))}
                    value={formData.restrictedToDiets}
                    handleChange={handleChange}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      <CardWrapper title={t('views.addons.addons.section.otherSettings.title')}>
        <CardBody>
          <GridContainer>
            <GridItem sm={8}>
              <GridContainer>
                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.cost.label')} *
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'cost',
                      value: formData.cost,
                      onChange: handleChange,
                      type: 'number',
                      style: { marginTop: '9px' },
                    }}
                  />
                </GridItem>

                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.contractor.label')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    name="contractor"
                    mapBy="name"
                    options={contractors}
                    value={formData.contractor}
                    handleChange={handleChange}
                  />
                </GridItem>

                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.stock.label')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'stock',
                      value: formData.stock,
                      onChange: handleChange,
                      type: 'number',
                      style: { marginTop: '9px' },
                    }}
                  />
                </GridItem>

                <GridItem sm={4}>
                  <FormLabel
                    className={classNames(
                      classes.labelHorizontal,
                      classes.codeLabel
                    )}
                  >
                    {t('form.field.code.label')} *
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'code',
                      value: formData.code,
                      onChange: handleChange,
                    }}
                  />
                </GridItem>

                <GridItem sm={8}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.notes.label')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'notes',
                      value: formData.notes,
                      onChange: handleChange,
                      style: { marginTop: '7px' },
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper
        title={t('views.addons.addons.section.logisticSettings.title')}
      >
        <CardBody>
          <GridContainer>
            <GridItem sm={8}>
              <GridContainer>
                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.volume.label')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'volume',
                      value: formData.volume,
                      onChange: handleChange,
                      type: 'number',
                      style: { marginTop: '9px' },
                    }}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('form.field.weight.label')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'weight',
                      value: formData.weight,
                      onChange: handleChange,
                      type: 'number',
                      style: { marginTop: '9px' },
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </>
  );
};

export default ClientPanel;

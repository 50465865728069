import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class SentSms extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportSentSms');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/sent-sms"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_FOOD_COST}
        pdfAvailable={false}
        excelAvailable={true}
        dateType="range"
        archiveReportNames={['SENT_SMS']}
      />
    );
  }
}

export default withTranslation()(SentSms);

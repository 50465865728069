import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import { Fastfood } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class MacrosExceeded extends React.Component {
  state = {
    codesErrorModal: false,
  };

  updateState = (field, value) =>
    this.setState(prevState => ({ ...prevState, [field]: value }));

  closeCodesErrorModal = () => this.updateState('codesErrorModal', false);

  openCodesErrorModal = () => this.updateState('codesErrorModal', true);

  render() {
    if (this.props.wrongRows.length === 0) {
      return null;
    }

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.codesErrorModal}
          onClose={this.closeCodesErrorModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeCodesErrorModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'left'} border>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.diet'
                      )}
                    </TableCell>
                    <TableCell align={'left'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.variant'
                      )}
                    </TableCell>
                    <TableCell align={'left'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.calorific'
                      )}
                    </TableCell>
                    <TableCell colSpan={6} align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.macro'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={2} align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.protein'
                      )}
                    </TableCell>
                    <TableCell colSpan={2} align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.carbohydrates'
                      )}
                    </TableCell>
                    <TableCell colSpan={2} align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.fat'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.current'
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.expected'
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.current'
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.expected'
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.current'
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {this.props.t(
                        'menuPlanner.action.warning.macrosExceeded.fields.expected'
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.wrongRows.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell align={'left'}>{row.diet}</TableCell>
                      <TableCell align={'left'}>{row.variant}</TableCell>
                      <TableCell align={'left'}>{row.calory}</TableCell>
                      <TableCell
                        align={'center'}
                        style={
                          row.macros.protein.isValid
                            ? {}
                            : {
                                background: '#f65b52',
                                color: '#fff',
                              }
                        }
                      >
                        {row.macros.protein.current}%
                      </TableCell>
                      <TableCell
                        align={'center'}
                        style={
                          row.macros.protein.isValid
                            ? {}
                            : {
                                background: '#f65b52',
                                color: '#fff',
                              }
                        }
                      >
                        {row.macros.protein.expected}% (+/-
                        {row.macros.protein.margin}%)
                      </TableCell>
                      <TableCell
                        align={'center'}
                        style={
                          row.macros.carbohydrates.isValid
                            ? {}
                            : {
                                background: '#f65b52',
                                color: '#fff',
                              }
                        }
                      >
                        {row.macros.carbohydrates.current}%
                      </TableCell>
                      <TableCell
                        align={'center'}
                        style={
                          row.macros.carbohydrates.isValid
                            ? {}
                            : {
                                background: '#f65b52',
                                color: '#fff',
                              }
                        }
                      >
                        {row.macros.carbohydrates.expected}% (+/-
                        {row.macros.carbohydrates.margin}%)
                      </TableCell>
                      <TableCell
                        align={'center'}
                        style={
                          row.macros.fat.isValid
                            ? {}
                            : {
                                background: '#f65b52',
                                color: '#fff',
                              }
                        }
                      >
                        {row.macros.fat.current}%
                      </TableCell>
                      <TableCell
                        align={'center'}
                        style={
                          row.macros.fat.isValid
                            ? {}
                            : {
                                background: '#f65b52',
                                color: '#fff',
                              }
                        }
                      >
                        {row.macros.fat.expected}% (+/-{row.macros.fat.margin}%)
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </DialogContent>
        </Dialog>
        <Tooltip
          style={{ marginLeft: '10px', cursor: 'help' }}
          title={
            <div>
              <h4>
                {this.props.t(
                  'menuPlanner.action.warning.macrosExceeded.tooltip.desc'
                )}
              </h4>
            </div>
          }
          placement="bottom"
        >
          <BottomNavigationAction
            label={this.props.t(
              'menuPlanner.action.warning.macrosExceeded.tooltip.actionName'
            )}
            showLabel={true}
            icon={<Fastfood style={{ color: '#f65b52' }} />}
            onClick={this.openCodesErrorModal}
          />
        </Tooltip>
      </>
    );
  }
}

export default withTranslation()(
  withStyles(sweetAlertStyle)(withToast(MacrosExceeded))
);

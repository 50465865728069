import types from 'helpers/constants';

const initialState = {
  zoneCategories: [],
  zoneCategory: {},
};

function ZoneCategories(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ZONE_CATEGORIES:
      return {
        ...state,
        zoneCategories: action.payload.zoneCategories,
      };
    case types.FETCH_ZONE_CATEGORY:
      return {
        ...state,
        zoneCategory: action.payload,
      };
    case types.REMOVE_ZONE_CATEGORY:
      return {
        ...state,
        zoneCategories: state.zoneCategories.filter(
          zoneCategory => zoneCategory.id !== action.payload.zoneCategoryId
        ),
      };
    default:
      return state;
  }
}

export default ZoneCategories;

import React from 'react';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TextFieldTooltip = ({ label }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      <span>{label}</span>
      <div style={{ marginLeft: '10px', display: 'inline-block' }}>
        <Tooltip
          title={
            <div>
              <h4>{t('form.menuPlanning.tooltipTitle')}</h4>
              <h4>
                {t('common.shared.etc')}{' '}
                {`<div style="margin: 30px; max-width: 50%">${t(
                  'form.menuPlanning.exampleTitleText'
                )}</div>
`}
              </h4>
            </div>
          }
          placement="right"
        >
          <Info fontSize={'small'} />
        </Tooltip>
      </div>
    </div>
  );
};

export default TextFieldTooltip;

export const GROUPED_ADDITIONAL_OFFER_ELEMENTS = [
  {
    title: 'Rozszerzenie systemowe',
    elements: [
      {
        link: '',
        title: 'Kasia',
        description:
          'Przejdź na wyższy poziom w wyborze menu! Skorzystaj z innowacyjnego modułu, który pozwala klientom podać swoje antypreferencje żywieniowe i na tej podstawie “Kasia” dobierze automatycznie dla nich menu spośród dostępnych opcji.',
        lifetimePrice: '29 990 zł',
        annualPrice: '14 900 zł',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Subskrypcja',
        description:
          'Zlokalizuj klientów jeszcze bardziej dzięki funkcji zamówień subskrypcyjnych. Z subskrypcją Twoi klienci automatycznie będą odnawiać zamówienia. Mają możliwość wstrzymania zamówienia w dowolnej chwili, a przywrócą je jednym kliknięciem. Wydłuży się Twoja średnia długość zamawianych diet, dodatkowo zbudujesz silniejsze relacje z klientami.',
        lifetimePrice: '29 990 zł',
        annualPrice: '14 990 zł',
        monthlyPrice: '890 zł',
      },
      {
        link: '',
        title: 'CMS',
        description:
          'Aktualizowanie cenników na wordpress, wpisywanie menu czy aktualizowanie stref dostaw to przeszłość. Dzięki modułowi CMS od Caterings automatycznie zostaną wygenerowane strony dla każdej diety z jej opiniami i menu, dla każdego dodatku, dla każdej strefy czy nawet dla każdego dania! Ponadto możesz generować dowolną ilość pozostałych podstron jak np. z opisem programu lojalnościowego, regulaminami, itp.',
        lifetimePrice: '9 990 zł',
        annualPrice: '4990 zł',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Dodatki',
        description:
          'Rozszerz swoją ofertę o dodatki, które można zakupić w formie jednorazowej lub na każdy dzień trwania diety. Możesz rozszerzyć Swoją ofertę o asortyment taki jak np. ekologiczne sztućce, suplementy diety, przetwory, przekąski, torby termiczne i inne, jakie tylko wymyślisz',
        lifetimePrice: '9 990 zł',
        annualPrice: '4990 zł',
        monthlyPrice: '290 zł',
      },
      {
        link: '',
        title: 'Sklep',
        description:
          'Sprzedawaj pojedyncze dania zgodnie z zaplanowanym menu na konkretny dzień lub spośród wyselekcjonowanych wcześniej pozycji ze “stałej oferty sklepu”. Sklep pomoże podnieść wartość końcową średniego koszyka klienta; rezultatem jest realizowanie większych zysków. Umożliwi również personalizację diety, wybierając dania z różnych diet i różnych kalorycznościach.',
        lifetimePrice: '19 990 zł',
        annualPrice: '9 990 zł',
        monthlyPrice: '590 zł',
      },
      {
        link: '',
        title: 'Flow',
        description:
          'Pełna cyfryzacja Twojej produkcji. Zautomatyzuj, zoptymalizuj oraz zuberyzuj pracę swoich zespołów - kuchnia, pakowna dań i toreb. Flow podniesie efektywność pracy Twoich pracowników oraz zminimalizuje ryzyko pojawienia się błędu praktycznie do zera. Przypisuj pracowników do zadań, monitoruj na bieżąco jakie dania lub torby są już gotowe. Przejdź na wyższy poziom produkcji!',
        lifetimePrice: '49 990 zł',
        annualPrice: '24 990 zł',
        monthlyPrice: '1 490 zł',
      },
      {
        link: '',
        title: 'Magazyn',
        description:
          'Lepiej pilnuj kosztów produkcji, dzięki pobieraniu cen składników bezpośrednio z faktur. Co więcej ułatw pracę zakupowcom, dzięki bieżącym wyliczeniom ile towaru jest na magazynie a ile potrzebne jest do produkcji',
        lifetimePrice: '19 990 zł',
        annualPrice: '9 990 zł',
        monthlyPrice: '590 zł',
      },
      {
        link: '',
        title: 'Zamówienia hurtowe',
        description:
          'Efektywnie obsługuj zamówienia hurtowe pod imprezy firmowe, konferencje, różne eventy czy nawet wesela, komunie, chrzciny itp. Moduł pozwala wybrać jakie dania z Twojej bazy mają zostać wyprodukowane i dla kogo (określając wtedy “paramarkę”) poprzez wybór dań z planowania menu na dany dzień lub całej bazy. Wszystko co wybierzesz idealnie rozpropaguje się na odpowiednie raporty produkcyjne. Łatwiej sprzedawaj swoje dania, bez chaosu dla kuchni i produkcji.',
        lifetimePrice: '9 990 zł',
        annualPrice: '4 990 zł',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Straty',
        description:
          'Monitoruj jakie straty jedzenia generowane są na kuchni i optymalizuj działania aby doprowadzić do jak najmniejszych strat. Pilnuj poziomu jakości produkcji; generuj raporty tygodniowe, miesięczne lub z wyznaczonego przez Ciebie okresu aby na bieżąco sprawdzać postępy we wprowadzanych optymalizacjach.',
        lifetimePrice: '2 990 zł',
        annualPrice: '1490 zł',
        monthlyPrice: '89 zł',
      },
      {
        link: '',
        title: 'Aplikacja PWA',
        description:
          'Aplikacja mobilna, możliwa do pobrania ze strony cateringu na telefon lub nawet na komputer! Zapewnij bliższy kontakt i większą lokalizację klientów dzięki umożliwieniu im posiadania ikonki Twojego cateringu na swoim telefonie. Klienci mogą wybierać menu, edytować dietę, dokupywać dodatki czy składać kolejne zamówienia.',
        lifetimePrice: '4 900 zł',
        annualPrice: '2 490 zł',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Aplikacja Google Play',
        description:
          'Aplikacja mobilna dla urządzeń z system Android jest dostępna do pobrania w sklepie Google Play. Zapewnij bliższy kontakt i większą lokalizację klientów dzięki umożliwieniu im posiadania ikonki Twojego cateringu na swoim telefonie. Klienci mogą wybierać menu, edytować dietę, dokupywać dodatki czy składać kolejne zamówienia. Wdrożenie trwa około 3-4 tygodnie.',
        lifetimePrice: '9 900 zł',
        annualPrice: '4 990 zł',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Aplikacja App Store',
        description:
          'Aplikacja mobilna na urządzenia z systemem IOS jest dostępna do pobrania w Apple Store. Zapewnij bliższy kontakt i większą lokalizację klientów dzięki umożliwieniu im posiadania ikonki Twojego cateringu na swoim telefonie. Klienci mogą wybierać menu, edytować dietę, dokupywać dodatki czy składać kolejne zamówienia. Wdrożenie trwa około 3-4 tygodnie.',
        lifetimePrice: '24 900 zł',
        annualPrice: '12 490 zł',
        monthlyPrice: '-',
      },
    ],
  },
  {
    title: 'Pakiety rozszerzeń',
    elements: [
      {
        link: '',
        title: 'Pakiet produkcyjny',
        description:
          'Pakiet obejmuje rozszerzenia: Flow, Straty i Magazyn. Jest kompleksową propozycją, która wzniesie na wyższy poziom produkcję w Twoim cateringu i obniży koszty prowadzenia biznesu.',
        lifetimePrice: '57 000 zł',
        annualPrice: '36 490 zł',
        monthlyPrice: '2 199 zł',
      },
      {
        link: '',
        title: 'Pakiet mrkt-sprzedażowy',
        description:
          'Pakiet obejmuje rozszerzenia: Sklep, Dodatki, Subskrypcja, CMS, Kasia. Jest kompleksową propozycją, która podniesie poziom rentowność biznesu poprzez zwiększanie przychodów w Twoim cateringu.',
        lifetimePrice: '85 000 zł',
        annualPrice: '49 990 zł',
        monthlyPrice: '3 298 zł',
      },
      {
        link: '',
        title: 'Pakiet aplikacji mobilnych',
        description:
          'Pakiet obejmuje opublikowanie aplikacji w sklepach Google Play oraz AppStore. Jest kompleksową propozycją, która zwiększy rozpoznawalność Twojego cateringu i jeszcze bardziej zlojalizuje klientów. ',
        lifetimePrice: '37 900 zł',
        annualPrice: '19 990 zł',
        monthlyPrice: '-',
      },
    ],
  },
  {
    title: 'Usługi dla cateringów',
    elements: [
      {
        link: '',
        title: 'Układanie menu',
        description: `Oferta usług dietetycznych obejmuje opracowanie przez naszych doświadczonych dietetyków pysznego, zoptymalizowanego i zróżnicowanego menu, które wzbogaci i odświeży Twoją ofertę. Rozpiszemy nowe menu dla istniejących lub nowych diet. W ramach oferty wszystko zostanie wprowadzone do Caterings.`,
        lifetimePrice: '',
        annualPrice: '',
        monthlyPrice: '',
      },
      {
        link: '',
        title: 'Konfiguracja naklejek',
        description:
          'Spersonalizowanie i dostosowanie do Twoich potrzeb projektu naklejek na dania, torby lub do organizacji pracy np. kuchni, czy zaopatrzenia. Są one w pełni zintegrowane z używaną przez Państwa drukarką. *Wymagane jest posiadanie programu “NiceLabel”',
        lifetimePrice: '290 zł',
        annualPrice: '-',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Strona internetowa',
        description:
          'Nowoczesna i atrakcyjna strona internetowa dla kolejnej marki lub odświeżenie aktualnego projektu. Przy projektowaniu strony internetowej skupimy się na realizowaniu Twojej wizji, uwzględniając aktualne standardy i trendy. Strona, którą przygotujemy będzie działała płynnie, będzie responsywna i zwiększy poziom konwersji wśród odwiedzających ją klientów.',
        lifetimePrice: '4900 zł',
        annualPrice: '-',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Konfiguracja GTM + GA4',
        description:
          'Google Tag Manager + Google Analytics 4. Menedżer tagów Google to system zarządzania tagami, umożliwiający Ci szybkie i łatwe aktualizowanie kodów śledzenia i powiązanych fragmentów kodu, czyli tagów, w witrynie lub aplikacji mobilnej, który zostanie zintegrowany z GA4. Jest to nowa usługa zaprojektowana z myślą o przyszłości pomiarów: zbiera dane o witrynie i aplikacji, będą umożliwiać Ci lepsze poznawanie ścieżki klienta; są używane dane oparte na zdarzeniach, a nie na sesji; zawiera ustawienia prywatności, np. pomiar bez użycia plików cookie, oraz modelowanie zachowań i konwersji. Obejmuje funkcje prognostyczne, które podpowiadają zalecane działania bez użycia skomplikowanych modeli; integruje się bezpośrednio z platformami medialnymi, żeby zachęcić użytkowników do działania w witrynie lub aplikacji.',
        lifetimePrice: '900 zł',
        annualPrice: '',
        monthlyPrice: '',
      },
      {
        link: '',
        title: 'Konfiguracja GTM + UA',
        description: `Google Tag Manager + Universal Analytics.
          Menedżer tagów Google to system zarządzania tagami, umożliwiający Ci szybkie i łatwe aktualizowanie kodów śledzenia i powiązanych fragmentów kodu, czyli tagów, w witrynie lub aplikacji mobilnej, który zostanie zintegrowany z Universal Analytics. Usługi UA zawierają inne raporty niż usługi Google Analytics 4. Jeśli Twoja witryna została skonfigurowana w Google Analytics przed 14 października 2020 r., prawdopodobnie używasz w niej usługi Universal Analytics, ponieważ w przypadku witryn konfigurowanych przed tą datą był to domyślny rodzaj usługi.`,
        lifetimePrice: '900 zł',
        annualPrice: '-',
        monthlyPrice: '-',
      },
      {
        link: '',
        title: 'Konfiguracja GTM + FB',
        description:
          'Google Tag Manager + Facebook Pixel. Menedżer tagów Google to system zarządzania tagami, umożliwiający Ci szybkie i łatwe aktualizowanie kodów śledzenia i powiązanych fragmentów kodu, czyli tagów, w witrynie lub aplikacji mobilnej, który zostanie zintegrowany z Facebook Pixel. Jest to kod, który umieszczony na stronie internetowej działa jak bardzo dobre narzędzie analityczne. Od momentu wgrania, zapisuje ważne dane, które później z sukcesem wykorzystasz w kampaniach i targetowaniu reklamy na precyzyjne grupy odbiorców.',
        lifetimePrice: '900 zł',
        annualPrice: '',
        monthlyPrice: '',
      },
      {
        link: '',
        title: 'Raport analityczny',
        description:
          'Zaawansowana analiza wieloobszarowa, to raport, który umożliwi Ci dostęp do statystyk i analiz porównawczych Twojego cateringu w stosunku do konkurencji oraz do trendów panujących na rynku. Dane zawierają między innymi informacje takie jak: średnia wartość zamówienia, średnia długość diety, udział rabatów w przychodach w stosunku do konkurencji, jak klienci korzystają z wyboru menu, wielkość udziału subskrypcji w przychodach, badanie poziomu konwersji na stronie a także przeanalizujemy formularz zamówień i wiele więcej. To wszystko pozwoli wesprzeć Twój proces decyzyjny w wybraniu odpowiedniej strategii sprzedażowej. Raport jest przygotowywany na początku miesiąca i dotyczy miesiąca minionego.',
        lifetimePrice: '-',
        annualPrice: '9900 zł',
        monthlyPrice: '890 zł',
      },
    ],
  },
];

import React from 'react';
import Moment from 'moment';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import OrderStatusCell from 'components/Grid/Cells/OrderStatusCell';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import { translateOrderStatuses } from '../../../Orders/Orders/statusTranslations';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
import { Tooltip } from '@material-ui/core';
import Addons from 'views/EcommerceBags/ColumnComponents/Addons';
import YesNoFilter from 'components/Grid/Filter/YesNo';

const columnConfig = t => [
  {
    title: 'ecommerceBags.fields.id',
    name: 'id',
    accessor: row => row.id,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.id',
    name: 'dietId',
    width: 70,
    filterable: true,
    sortable: true,
    accessor: prop => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`/admin/ecommerce-diets/preview/${prop.dietId}`}
      >
        {prop.dietId}
      </a>
    ),
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.isSubscription',
    name: 'isSubscription',
    accessor: row => <BooleanView value={row.isSubscription} />,
    sortable: false,
    filterable: false,
  },

  {
    title: 'ecommerceBags.fields.bag.clientDiet.active',
    name: 'activeBag',
    accessor: row => <BooleanView value={row.active} />,
    sortable: false,
    filterable: false,
  },

  {
    title: 'ecommerceBags.fields.bag.clientDiet.order.status',
    accessor: row => (
      <OrderStatusCell
        status={{ value: row.status, systemValue: row.systemStatus }}
      />
    ),
    filterable: true,
    sortable: true,
    width: 170,
    name: 'systemStatus',
    Filter: ({ onChange, filter }) => {
      const selectOptions = translateOrderStatuses(t);
      return (
        <select
          style={{ height: '30px', width: '100%', padding: '0' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value={''}>{selectOptions[0].label}</option>
          <option value={selectOptions[1].systemValue}>
            {selectOptions[1].label}
          </option>
          <option value={selectOptions[2].systemValue}>
            {selectOptions[2].label}
          </option>
          <option value={selectOptions[3].systemValue}>
            {selectOptions[3].label}
          </option>
        </select>
      );
    },
  },
  {
    title: 'ecommerceBags.fields.bag.date',
    accessor: row => Moment(row.date).format('YYYY-MM-DD'),
    name: 'date',
    Filter: ({ onChange, filter }) => {
      return <DateRangeFilter onChange={onChange} value={filter?.value} />;
    },
    width: 90,
    filterable: false,
    sortable: true,
  },

  {
    title: t('ecommerceBags.fields.bag.deliveredAt'),
    accessor: row => row.deliveredAt,
    name: 'deliveredAt',
    width: 90,
    filterable: false,
    sortable: false,
  },

  {
    title: t('ecommerceBags.fields.bag.deliveryPhoto', 'Zdjęcie torby'),
    accessor: row =>
      row.deliveryPhoto ? (
        <img
          src={row.deliveryPhoto}
          style={{ width: '100%', maxWidth: '150px' }}
        />
      ) : null,
    name: 'deliveryPhoto',
    filterable: false,
    sortable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.client',
    accessor: row => {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`/admin/clients/edit/${row.clientId}`}
        >
          {row.clientFirstName} {row.clientLastName}
        </a>
      );
    },
    name: 'user',
    width: 120,
    filterable: false,
    sortable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.client.email',
    accessor: prop => (
      <a href={`mailto:${prop.clientEmail}`}>{prop.clientEmail}</a>
    ),
    width: 250,
    name: 'clientEmail',
    filterable: false,
    sortable: false,
  },

  {
    title: 'ecommerceBags.fields.bag.addons',
    accessor: prop => <Addons addons={prop.addons} />,
    width: 200,
    name: 'hasAddons',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    filterable: true,
    sortable: false,
  },

  {
    title: 'ecommerceBags.fields.bag.phone',
    accessor: row => row.clientPhoneNumber,
    name: 'clientPhoneNumber',
    sortable: false,
    filterable: false,
  },

  {
    title: 'ecommerceBags.fields.bag.address',
    accessor: row => {
      return row.city
        ? `${row.city} ${row.postCode}, ${row.street} ${row.buildNumber}`
        : row.pickUpPoint || '-';
    },
    width: 200,
    name: 'address',
    sortable: true,
    filterable: false,
  },

  {
    title: 'ecommerceBags.fields.bag.zones',
    accessor: row => row.zoneName,
    width: 200,
    name: 'zoneName',
    sortable: false,
    filterable: false,
  },

  {
    title: 'ecommerceBags.fields.realFoodCost',
    accessor: row => row.realFoodCost,
    name: 'realFoodCost',
    width: 60,
    filterable: false,
    sortable: false,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },

  {
    title: 'ecommerceBags.fields.priceFromMoneyBox',
    accessor: row => row.priceFromMoneyBox,
    name: 'priceFromMoneyBox',
    width: 60,
    filterable: false,
    sortable: false,
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },

  {
    title: 'ecommerceBags.fields.bag.priceAfterDiscount',
    name: 'priceAfterDiscount',
    width: 60,
    accessor: row => {
      const {
        ecoContainerPrice,
        priceAddonsAfterDiscount,
        priceAddonsBeforeDiscount,
        priceAddonsDiscount,
        priceAfterDiscount,
        priceBeforeDiscount,
        priceDeliveryAfterDiscount,
        priceDeliveryBeforeDiscount,
        priceDeliveryDiscount,
        priceDiscount,
        priceDishesAfterDiscount,
        priceDishesBeforeDiscount,
        priceDishesDiscount,
      } = row ?? {};

      return (
        <div style={{ textAlign: 'right', paddingRight: 8 }}>
          <Tooltip
            interactive
            title={
              <div>
                <h5>{t('ecommerceBags.fields.bag.price.diet')}</h5>
                <span style={{ fontSize: '12px' }}>
                  {t('ecommerceBags.fields.bag.price.before')}:{' '}
                  {priceBeforeDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                  {priceDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.after')}:{' '}
                  {priceAfterDiscount?.toFixed(2) ?? 0}
                </span>
                <hr />

                <h5>{t('ecommerceBags.fields.bag.price.delivery')}</h5>
                <span style={{ fontSize: '12px' }}>
                  {t('ecommerceBags.fields.bag.price.before')}:{' '}
                  {priceDeliveryBeforeDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                  {priceDeliveryDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.after')}:{' '}
                  {priceDeliveryAfterDiscount?.toFixed(2) ?? 0}
                </span>
                <hr />

                <h5>{t('ecommerceBags.fields.bag.price.dish')}</h5>
                <span style={{ fontSize: '12px' }}>
                  {t('ecommerceBags.fields.bag.price.before')}:{' '}
                  {priceDishesBeforeDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                  {priceDishesDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.after')}:{' '}
                  {priceDishesAfterDiscount?.toFixed(2) ?? 0}
                </span>
                <hr />

                <h5>{t('ecommerceBags.fields.bag.price.addon')}</h5>
                <span style={{ fontSize: '12px' }}>
                  {t('ecommerceBags.fields.bag.price.before')}:{' '}
                  {priceAddonsBeforeDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                  {priceAddonsDiscount?.toFixed(2) ?? 0}
                  <br />
                  {t('ecommerceBags.fields.bag.price.after')}:{' '}
                  {priceAddonsAfterDiscount?.toFixed(2) ?? 0}
                </span>
                <hr />

                <h5>{t('ecommerceBags.fields.bag.price.ecoContainer')}</h5>
                <span style={{ fontSize: '12px' }}>
                  {t('ecommerceBags.fields.bag.price.before')}:{' '}
                  {ecoContainerPrice?.toFixed(2) ?? 0}
                  <br />
                </span>
              </div>
            }
            placement={'right'}
          >
            <span style={{ cursor: 'help' }}>
              {(
                (priceAfterDiscount ?? 0) +
                (priceAddonsAfterDiscount ?? 0) +
                (priceDishesAfterDiscount ?? 0) +
                (priceDeliveryAfterDiscount ?? 0)
              )?.toFixed(2) ?? 0}
            </span>
          </Tooltip>
        </div>
      );
    },
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.diet.income',
    name: 'income',
    accessor: row => row.income,
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.diet.name',
    name: 'dietName',
    accessor: row => row.dietName,
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.variant.name',
    name: 'variantName',
    accessor: row => row.variantName,
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.calorific.name',
    name: 'calorificName',
    accessor: row => row.calorificName,
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.optionChangeMenu',
    name: 'optionChangeMenu',
    accessor: row => <BooleanView value={row.optionChangeMenu} />,
    width: 70,
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.useEcoContainers',
    name: 'useEcoContainers',
    accessor: row => <BooleanView value={row.useEcoContainers} />,
    width: 70,
    sortable: false,
    filterable: false,
  },
  {
    title: 'ecommerceBags.fields.clientChanged',
    name: 'clientChanged',
    accessor: row => <BooleanView value={row.clientChanged} />,
    width: 70,
    sortable: false,
    filterable: false,
  },
];

export default columnConfig;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import ShowChart from '@material-ui/icons/BarChart';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import { get } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartBagsIncome = ({ classes, selectedBrand }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    data: {
      labels: [],
      datasets: [
        {
          label: t('dashboard.bagsIncomeStats', 'Dochód z toreb'),
          data: [],
          backgroundColor: classes.warning,
        },
      ],
    },
  });

  const options = {
    responsive: true,
    gridLineOptions: {
      display: false,
    },
    scales: {
      x: {
        border: { display: false },
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        position: 'none',
      },
      datalabels: { display: false },
    },
  };

  useEffect(() => {
    getIncomeHistory();
  }, []);

  const getIncomeHistory = () => {
    get(`/statistics/ecommerce/bags-income`, {
      brandId: [selectedBrand],
    }).then(res => getData(res['hydra:member']));
  };

  const getData = incomeData => {
    const data = incomeData?.length > 0 ? incomeData[0] : {};

    setChartData({
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            label: t('dashboard.bagsIncomeStats', 'Dochód z toreb'),
            data: Object.values(data),
            backgroundColor: 'rgba(255, 152, 0, 0.5)',
          },
        ],
      },
    });
  };

  return (
    <Card>
      <CardHeader color="warning" quickStats icon>
        <CardIcon color="warning">
          <ShowChart />
        </CardIcon>
        <h3 className={classes.quickStatsTitle}>
          {t('dashboard.bagIncomeStats', 'Dochód torba')}
        </h3>
      </CardHeader>
      <CardBody>
        <div class="barChart" style={{ marginTop: '15px' }}>
          <Bar
            options={options}
            data={chartData?.data}
            style={{ maxHeight: '350px' }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(dashboardStyle);

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(BarChartBagsIncome));

import { useEffect, useState } from 'react';
import { TableCell } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';
import {
  isTranslatableLangIdenticalInTwoObjects,
  useTranslatableSelectedLanguage,
  isTranslatableLangEmpty,
  TranslatableObjectKey,
} from 'hooks/redux/Translatable/useTranslatableLanguages';

const SizeNameCell = ({
  dish,
  size,
  dishSizes,
  handleSizeNameChange,
  classes,
  copyMode,
}) => {
  const [isDefault, setIsDefault] = useState();
  const { t } = useTranslation();
  const lang = useTranslatableSelectedLanguage();

  const dishSizeBySize = dishSizes.find(
    dishSize => dishSize.size === size?.['@id']
  );

  useEffect(() => {
    setIsDefault(
      isTranslatableLangEmpty(dishSizeBySize.name, lang) ||
        isTranslatableLangIdenticalInTwoObjects(
          dishSizeBySize.name,
          dish.nameForClientInitial,
          lang
        )
    );
  }, [lang]);

  const onToggleIsDefault = () => {
    const isNewStateIsNameOverridden = !isDefault;
    if (!copyMode) {
      const defaultValue = !isNewStateIsNameOverridden
        ? dish.nameForClient[TranslatableObjectKey][lang]
        : dish.nameForClientInitial[TranslatableObjectKey][lang];

      handleSizeNameChange(
        {
          [TranslatableObjectKey]: {
            ...(dishSizeBySize.name[TranslatableObjectKey] || {}),
            [lang]: defaultValue,
          },
        },
        dishSizeBySize
      );
    }
    setIsDefault(isNewStateIsNameOverridden);
  };

  return (
    <TableCell>
      <FormControlLabel
        control={
          <Checkbox
            key={isDefault ? 'y' : 'n'}
            checked={isDefault}
            onChange={onToggleIsDefault}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('mealTypes.default.fem', 'Domyślna')}
      />
      {!isDefault && (
        <FormTextInputTranslatable
          classes={classes}
          formControlProps={{ fullWidth: true }}
          maxLength={160}
          value={dishSizeBySize.name}
          handleChange={e => {
            if (e.target.value !== dishSizeBySize.name) {
              handleSizeNameChange(e.target.value, dishSizeBySize);
            }
          }}
        />
      )}
    </TableCell>
  );
};

export default SizeNameCell;

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from '../../../components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { withTranslation } from 'react-i18next';
import IdDetails from './IdDetails';
import { ROLE_SHOW_ORDER, ROLE_SHOW_BAG } from 'helpers/roles';

const MappingDetails = ({ t, row }) => {
  const [state, setState] = useState({
    modal: false,
  });

  const hideModal = () => {
    setState(prev => ({ ...prev, modal: false }));
  };

  const openModal = () => {
    setState(prev => ({ ...prev, modal: true }));
  };
  return (
    <>
      <Dialog maxWidth={'xl'} open={state.modal} onClose={hideModal}>
        <DialogContent>
          <ModalButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              cursor: 'pointer',
            }}
            color={'transparent'}
            justIcon
            round
            onClick={hideModal}
          >
            &times;
          </ModalButton>
        </DialogContent>
        <DialogContent>
          <Paper>
            <Table style={{ textAlign: 'center' }}>
              <TableHead>
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell
                    colSpan={2}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.diet')}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.variant')}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.calorific')}
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.statistics')}
                  </TableCell>
                </TableRow>
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.to')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.to')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.to')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.countBags')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.countDiets')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.countSubscriptions')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.countIntents')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.mapping.map(
                  ({
                    from,
                    to,
                    alreadyChangedBags,
                    alreadyChangedDiets,
                    alreadyChangedSubscription,
                    alreadyChangedSubscriptionIntents,
                    shouldChangeBags,
                    shouldChangeDiets,
                    shouldChangeSubscription,
                    shouldChangeSubscriptionIntents,
                  }) => (
                    <TableRow>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {from?.diet?.name ? from.diet.name : null}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {to.diet.name}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {from?.variant?.name ? from.variant.name : null}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {to?.variant?.name}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {from?.calorific?.name ? from.calorific.name : null}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {to?.calorific?.name}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {alreadyChangedBags.length}/{shouldChangeBags.length}
                        {shouldChangeBags.length > 0 && (
                          <IdDetails
                            linkRole={ROLE_SHOW_BAG}
                            previewLink={'/admin/ecommerce-bags'}
                            source={shouldChangeBags}
                            finished={alreadyChangedBags}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {alreadyChangedDiets.length}/{shouldChangeDiets.length}
                        {shouldChangeDiets.length > 0 && (
                          <IdDetails
                            linkRole={ROLE_SHOW_ORDER}
                            previewLink={'/admin/ecommerce-diets'}
                            source={shouldChangeDiets}
                            finished={alreadyChangedDiets}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {alreadyChangedSubscription.length}/
                        {shouldChangeSubscription.length}
                        {shouldChangeSubscription.length > 0 && (
                          <IdDetails
                            linkRole={ROLE_SHOW_ORDER}
                            previewLink={'/admin/subscriptions'}
                            previewKey={'clientDiet.id'}
                            source={shouldChangeSubscription}
                            finished={alreadyChangedSubscription}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          textAlign: 'center',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {alreadyChangedSubscriptionIntents.length}/
                        {shouldChangeSubscriptionIntents.length}
                        {shouldChangeSubscriptionIntents.length > 0 && (
                          <IdDetails
                            source={shouldChangeSubscriptionIntents}
                            finished={alreadyChangedSubscriptionIntents}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
      </Dialog>
      <Button onClick={openModal}>
        {t('massAction.dietElements.mapping')}
      </Button>
    </>
  );
};

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(MappingDetails);

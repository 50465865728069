import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import BrandInformation from 'views/BrandConfiguration/BrandInformation';

const BrandSettings = ({ isEdit, roles, ...restProps }) => {
  return (
    <div>
      <BrandInformation isEdit={isEdit} {...restProps} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    roles: state.Auth.user.roles,
  };
};

const enhance = compose(connect(mapStateToProps, null));

export default enhance(BrandSettings);

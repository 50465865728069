import React from 'react';
import Moment from 'moment';
import DateFilter from '../../components/Grid/Filter/Date';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import YesNoFilter from 'components/Grid/Filter/YesNo';
const columnConfig = [
  {
    title: 'columns.dishPublic',
    name: 'draft',
    accessor: row => <BooleanView value={!row?.draft} />,
    Filter: ({ onChange, filter }) => {
      return (
        <YesNoFilter
          onChange={onChange}
          value={`${filter?.value}`}
          reverse={true}
        />
      );
    },
    filterable: true,
  },
  {
    title: 'columns.date',
    accessor: row => Moment(row.date).format('YYYY-MM-DD'),
    name: 'date',
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    filterable: true,
    sortable: false,
  },
  {
    title: 'diets.diet',
    accessor: row => row.diet.name,
    name: 'diet.name',
    filterable: true,
    sortable: false,
  },
  {
    title: 'diets.variant',
    accessor: row => row.variant.name,
    name: 'variant.name',
    filterable: true,
    sortable: false,
  },
  {
    title: 'employees.brand',
    accessor: row => row.brand.name,
    name: 'brand.name',
    filterable: true,
    sortable: false,
  },
];

export default columnConfig;

import styled from 'styled-components';

export const Container = styled.div`
  max-height: 65vh;
  overflow-y: auto;
  > div:not(:last-child) {
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid black;
  }
`;

export const ItemWrapper = styled.div`
  h3 {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }
`;

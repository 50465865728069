import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';

import { currencyToSymbol } from 'utils/currencies';

import { dangerColor } from 'assets/jss/material-dashboard-pro-react';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  priceBeforeDiscount: {
    color: dangerColor[0],
    marginRight: '5px',
    textDecoration: 'line-through',
  },
});

const PriceWrapper = ({
  beforeDiscount,
  afterDiscount,
  mayNegative = false,
  style = {},
}) => {
  const classes = useStyles();

  const { currency } = useSelector(state => {
    return {
      currency: state?.Brands.brand.multinational.currency,
    };
  });

  const renderPrice = useCallback(
    price => {
      if (mayNegative) {
        return `${price} ${currencyToSymbol(currency)}`;
      }

      return `${price < 0 ? 0 : (price ?? 0).toFixed(2)} ${currencyToSymbol(
        currency
      )}`;
    },
    [mayNegative]
  );

  return (
    <div className={classNames(classes.priceWrapper, style?.wrapper)}>
      {isNumber(beforeDiscount) && beforeDiscount !== afterDiscount && (
        <div className={classes.priceBeforeDiscount}>
          {renderPrice(beforeDiscount)}
        </div>
      )}
      {isNumber(afterDiscount) && (
        <div style={style?.afterDiscount}>{renderPrice(afterDiscount)}</div>
      )}
    </div>
  );
};

export default PriceWrapper;

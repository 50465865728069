import SelectInput from 'components/FormSelect/SelectInput';
import React from 'react';
import {
  useTranslatableLanguageHandler,
  useTranslatableLanguages,
  useTranslatableSelectedLanguage,
} from 'hooks/redux/Translatable/useTranslatableLanguages';
import { makeStyles } from '@material-ui/styles';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

const useStyles = makeStyles(combineStyles(extendedFormsStyle, buttonsStyle));

const LanguagePicker = () => {
  const classes = useStyles();
  const translatableLanguages = useTranslatableLanguages();
  const value = useTranslatableSelectedLanguage();
  const handler = useTranslatableLanguageHandler();

  return (
    <SelectInput
      noGrid={true}
      options={translatableLanguages.map(el => ({ lang: el }))}
      value={value}
      trackBy="lang"
      classes={classes}
      mapBy="lang"
      customFormControlClasses={{
        customSelectFormControl: 'translatable-language-picker',
      }}
      handleChange={handler}
    />
  );
};

export default LanguagePicker;

import React from 'react';
import { TableCell, Tooltip } from '@material-ui/core';

import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class ContainerCell extends React.Component {
  render() {
    const { dishSizes, size, t } = this.props;
    let sum = 0;
    let processedSum = 0;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    dishSizeBySize.ingredients.forEach(ingredient => {
      if (!!ingredient.ingredient) {
        sum += ingredient.ingredient.weight * ingredient.quantity;
        processedSum +=
          ((((ingredient.ingredient.weight *
            ingredient.ingredient.thermalProcessing) /
            100) *
            ingredient.ingredient.workingOnMachining) /
            100) *
          ingredient.quantity;
      }

      if (ingredient.recipe) {
        sum += ingredient.recipe.weight * ingredient.quantity;
        processedSum +=
          ingredient.recipe.weightAfterProcessing * ingredient.quantity;
      }
    });
    return (
      <TableCell>
        <Tooltip
          title={
            <span style={{ fontSize: '12px' }}>
              {t(
                'mealTypes.sizeRow.dryWeightBeforeProcessing',
                'Gramatura "sucha", przed obróbką'
              )}
            </span>
          }
          placement={'top'}
        >
          <span style={{ cursor: 'help' }}>{sum.toFixed(0)}g</span>
        </Tooltip>
        &nbsp;
        <Tooltip
          title={
            <span style={{ fontSize: '12px' }}>
              {t(
                'mealTypes.sizeRow.weightAfterProcessing',
                'Gramatura po obróbce, uwzględniając WOT i WOM'
              )}
            </span>
          }
          placement={'top'}
        >
          <span style={{ cursor: 'help' }}>({processedSum.toFixed(0)}g)</span>
        </Tooltip>
      </TableCell>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(ContainerCell);

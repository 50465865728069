import React from 'react';
import BooleanView from '../../../components/DataGrid/ValueViews/BooleanView';
import YesNoFilter from '../../../components/Grid/Filter/YesNo';
import MappingDetails from './MappingDetails';
import Moment from 'moment';

const createBooleanColumn = (field, accessor, width = undefined) => ({
  title: 'massAction.dietElements.' + field,
  accessor: row => <BooleanView value={accessor(row)} />,
  name: field,
  width,
  Filter: ({ onChange, filter }) => (
    <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
  ),
  filterable: true,
  sortable: false,
});

const columnConfig = t => [
  {
    title: 'massAction.dietElements.createdDate',
    accessor: row => Moment(row.createdDate).format('llll'),
    name: 'createdDate',
    filterable: true,
    sortable: true,
    width: 150,
  },
  {
    title: 'massAction.dietElements.createdBy',
    accessor: ({ createdBy: { firstName, lastName, email } }) =>
      `${firstName} ${lastName} (${email})`,
    name: 'createdBy',
    filterable: true,
    sortable: true,
    width: 300,
  },
  {
    title: 'massAction.dietElements.status',
    accessor: ({ status }) => t(`massAction.dietElements.status.${status}`),
    name: 'status',
    filterable: false,
    sortable: false,
    width: 100,
  },
  createBooleanColumn(
    'applyToAllActiveDiets',
    row => row.applyToAllActiveDiets
  ),
  {
    title: 'massAction.dietElements.dateFrom',
    accessor: row => (row.dateFrom ? Moment(row.dateFrom).format('LL') : null),
    name: 'dateFrom',
    filterable: true,
    sortable: true,
  },
  {
    title: 'massAction.dietElements.dateTo',
    accessor: row => (row.dateFrom ? Moment(row.dateTo).format('LL') : null),
    name: 'dateTo',
    filterable: true,
    sortable: true,
  },
  createBooleanColumn('list.updateBags', row => row.updateBags),
  createBooleanColumn('list.updateDietDefaults', row => row.updateDietDefaults),
  createBooleanColumn(
    'list.updateSubscriptionIntents',
    row => row.updateSubscriptionIntents
  ),
  createBooleanColumn(
    'list.updateSubscriptions',
    row => row.updateSubscriptions
  ),
  createBooleanColumn(
    'list.resetSubscriptionIntents',
    row => row.updateSubscriptions
  ),
  {
    title: 'massAction.dietElements.mapping',
    accessor: row => <MappingDetails row={row} t={t} />,
    name: 'mapping',
    filterable: false,
    sortable: false,
    width: 300,
  },
];

export default columnConfig;

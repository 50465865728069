import React, { useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AdminTable from '../../layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import PropTypes from 'prop-types';
import { get } from 'helpers/apiHelpers';
import Moment from 'moment';
import Paper from '@material-ui/core/es/Paper/Paper';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Storage } from '@material-ui/icons';
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';

const DisplayValue = params => {
  const { t } = useTranslation();
  const {
    field,
    obj: { '@LogType': type, ...args },
  } = params;
  if (type === 'PURE') {
    if ((field + '').toLowerCase().includes('template')) {
      return (
        <Button
          onClick={() => {
            const win = window.open(
              '',
              t('mailNotifications.clientPreview', 'Podgląd Klient'),
              'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=800'
            );
            win.document.write(args.value);
          }}
        >
          {t('menuPlanner.action.template.use.preview', 'Podgląd')}
        </Button>
      );
    } else {
      return <span>{args.value}</span>;
    }
  }
  if (type === 'ENTITY') {
    if (typeof args.labels !== 'undefined') {
      return <span>{args.labels.objectName || args.value}</span>;
    } else {
      return <span>{args.value}</span>;
    }
  }
  if (type === 'COLLECTION') {
    if (args.value.hasOwnProperty('length')) {
      return args.value.map((log, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && ','} <DisplayValue field={index} obj={log} />
          </React.Fragment>
        );
      });
    } else {
      const keys = Object.keys(args.value);

      return keys.map((key, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && ','} {key}:{' '}
            <DisplayValue field={index} obj={args.value[key]} />
          </React.Fragment>
        );
      });
    }
  }

  let viewValue = params.obj;

  try {
    viewValue = JSON.stringify(params.obj, undefined, 2);
  } catch (e) {}

  return (
    <span>
      <pre>{viewValue}</pre>
    </span>
  );
};

const ActorInfo = ({ loggedAt, actorRawInfo, impersonator, requestId }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {Moment(loggedAt).format('llll ')}

      {impersonator && (
        <>
          {impersonator.firstName} {impersonator.lastName} ({impersonator.email}
          ){' '}
          {t('logView.actorInfo.loggedIn', 'Zalogowany na koncie użytkownika')}{' '}
          {actorRawInfo}
        </>
      )}
      {!impersonator && actorRawInfo}
      {requestId && (
        <span
          style={{
            marginLeft: 16,
            background: '#efefef',
            padding: 4,
            borderRadius: 16,
          }}
        >
          {requestId}
        </span>
      )}
    </React.Fragment>
  );
};

const InsertBlock = ({
  actorRawInfo,
  changes,
  loggedAt,
  objectName,
  impersonatedBy,
  labels,
  objectId,
  snapshot,
}) => {
  const changedFields = Object.keys(changes).filter(
    field => !['currentObjectInfo'].includes(field)
  );
  const { t } = useTranslation();

  const title = t('logView.insertBlock.title', {
    defaultValue: `Utworzenie rekordu o ID: {{id}} "{{changes}}"`,
    replace: {
      changes:
        labels._objectName ||
        (
          changes.currentObjectInfo || {
            currentObjectInfo: { value: objectName },
          }
        ).value ||
        '',
      id: objectId,
    },
  });
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <AddCircleIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              <ActorInfo
                actorRawInfo={actorRawInfo}
                loggedAt={loggedAt}
                impersonator={impersonatedBy}
                requestId={snapshot?.requestId ?? null}
              />
            </Typography>
            <Table style={{ width: 'initial' }}>
              <TableBody>
                <TableRow>
                  <TableCell className={'head'}>
                    {t('form.fieldName', 'Pole')}
                  </TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>{labels[field] ?? field}</TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>{t('form.value', 'Wartość')}</TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>
                        <DisplayValue field={field} obj={changes[field].to} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
        }
      >
        <span>
          {title}
          {!Array.isArray(snapshot) && (
            <Tooltip
              placement="top"
              title={<pre>{JSON.stringify(snapshot, null, 2)}</pre>}
            >
              <Storage style={{ verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </span>
      </ListItemText>
    </ListItem>
  );
};

const UpdateBlock = ({
  actorRawInfo,
  changes,
  loggedAt,
  objectName,
  impersonatedBy,
  labels,
  objectId,
  snapshot,
}) => {
  const { t } = useTranslation();
  const changedFields = Object.keys(changes).filter(
    field => !['currentObjectInfo'].includes(field)
  );
  const title = t('logView.updateBlock.title', {
    defaultValue: `Modyfikacja rekordu o ID: {{id}}  {{changes}}`,
    replace: {
      changes:
        labels._objectName ||
        (
          changes.currentObjectInfo || {
            currentObjectInfo: { value: objectName },
          }
        ).value ||
        '',
      id: objectId,
    },
  });

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <CreateIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              <ActorInfo
                actorRawInfo={actorRawInfo}
                loggedAt={loggedAt}
                impersonator={impersonatedBy}
                requestId={snapshot?.requestId ?? null}
              />
            </Typography>
            <Table style={{ width: 'initial' }}>
              <TableBody>
                <TableRow>
                  <TableCell className={'head'}>
                    {t('form.fieldName', 'Pole')}
                  </TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>{labels[field] ?? field}</TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('form.valueBefore', 'Wartość przed')}
                  </TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>
                        <DisplayValue field={field} obj={changes[field].from} />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>{t('form.valueAfter', 'Wartość po')}</TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>
                        <DisplayValue field={field} obj={changes[field].to} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
        }
      >
        <span>
          {title}
          {!Array.isArray(snapshot) && (
            <Tooltip
              placement="top"
              title={<pre>{JSON.stringify(snapshot, null, 2)}</pre>}
            >
              <Storage style={{ verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </span>
      </ListItemText>
    </ListItem>
  );
};

const RemoveBlock = ({
  actorRawInfo,
  changes,
  loggedAt,
  objectName,
  impersonatedBy,
  labels,
  objectId,
  snapshot,
}) => {
  const { t } = useTranslation();
  const title = t('logView.removeBlock.title', {
    defaultValue: `Skasowanie rekordu o ID: {{id}} {{changes}}`,
    replace: {
      changes:
        labels._objectName ||
        (
          changes.currentObjectInfo || {
            currentObjectInfo: { value: objectName },
          }
        ).value ||
        '',
      id: objectId,
    },
  });

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <RemoveIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <>
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary">
                <ActorInfo
                  actorRawInfo={actorRawInfo}
                  loggedAt={loggedAt}
                  impersonator={impersonatedBy}
                  requestId={snapshot?.requestId ?? null}
                />
              </Typography>
            </React.Fragment>
          </>
        }
      >
        <span>
          {title}
          {!Array.isArray(snapshot) && (
            <Tooltip
              placement="top"
              title={<pre>{JSON.stringify(snapshot, null, 2)}</pre>}
            >
              <Storage style={{ verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </span>
      </ListItemText>
    </ListItem>
  );
};

export const LogBlock = ({ historyRow }) => {
  const map = { INSERT: InsertBlock, UPDATE: UpdateBlock, REMOVE: RemoveBlock };

  if (map.hasOwnProperty(historyRow.actionType)) {
    const ViewComponent = map[historyRow.actionType];

    return (
      <Paper style={{ overflowX: 'auto', marginBottom: '14px' }}>
        {' '}
        <ViewComponent {...historyRow} />
      </Paper>
    );
  }

  return <div>{historyRow.actionType}</div>;
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

export const ModalLogBlock = withStyles(notificationsStyle)(
  ({ classes, row }) => {
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation();

    return (
      <div>
        <Dialog
          classes={{
            root: classes.center + ' ' + classes.modalRoot,
            paper: classes.modal,
          }}
          open={opened}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpened(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <LogBlock historyRow={row} />
          </DialogContent>

          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => setOpened(false)} color="primary" simple>
              {t('form.close', 'Zamknij')}
            </Button>
          </DialogActions>
        </Dialog>
        <Button onClick={() => setOpened(true)} color="primary" simple>
          {t('$*common.show')} {(t('common.details') || '').toLowerCase()}
        </Button>
      </div>
    );
  }
);

class LogView extends React.Component {
  initialState = {
    history: [],
    showHistory: this.props.historyOpenDefault,
    loading: true,
    updating: false,
    page: 1,
    itemsPerPage: 5,
    allLoaded: false,
  };
  state = this.initialState;

  componentDidMount() {
    this.props.historyOpenDefault
      ? get(this.props.iri, {
          showHistory: true,
          partial: true,
          itemsPerPage: this.state.itemsPerPage,
          page: this.state.page,
        }).then(res => {
          return this.setState({
            history: res['hydra:member'],
            loading: false,
            allLoaded: res['hydra:member'].length === 0,
          });
        })
      : this.setState({ loading: false });
  }

  toggleHistory = async () => {
    const { 'hydra:member': history } =
      this.state.history.length === 0 &&
      (await get(this.props.iri, {
        showHistory: true,
        partial: true,
        itemsPerPage: this.state.itemsPerPage,
        page: this.state.page,
      }));
    const showHistory = !this.state.showHistory;
    this.setState(prev => ({
      ...prev,
      history: history ? history : prev.history,
      showHistory,
      allLoaded: history ? history.length === 0 : false,
    }));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.iri !== this.props.iri) {
      this.setState(
        { ...this.initialState, loading: false },
        () => this.state.showHistory && this.toggleHistory()
      );
    }
  }

  updateHistory = loadAll => {
    this.setState({ updating: true });

    const action = loadAll
      ? get(this.props.iri, {
          showHistory: true,
          partial: false,
          pagination: false,
        })
      : get(this.props.iri, {
          showHistory: true,
          partial: true,
          itemsPerPage: this.state.itemsPerPage,
          page: this.state.page + 1,
        });

    action.then(res => {
      this.setState({
        page: this.state.page + 1,
        history: loadAll
          ? res['hydra:member']
          : [...this.state.history, ...res['hydra:member']],
        allLoaded: loadAll ? true : res['hydra:member'].length === 0,
        updating: false,
      });
    });
  };

  render() {
    const {
      classes,
      discardText,
      submitText,
      cancelPath,
      handleSubmit,
      secondarySubmitText,
      handleSecondarySubmit,
      customButtonsRight,
      submitDisabled,
      customOffsetSmall,
      customOffsetLarge,
      isFixedToBottom,
    } = this.props;

    return this.state.loading ? (
      <CircularProgress />
    ) : (
      <>
        <FormControlStickyButton
          isFixedToBottom={isFixedToBottom}
          submitDisabled={submitDisabled}
          t={this.props.t}
          classes={classes}
          discardText={discardText}
          submitText={submitText}
          cancelPath={cancelPath}
          handleSubmit={handleSubmit}
          history={true}
          showHistory={this.state.showHistory}
          toggleHistory={this.toggleHistory}
          secondarySubmitText={secondarySubmitText}
          handleSecondarySubmit={handleSecondarySubmit}
          customButtonsRight={customButtonsRight}
          customOffsetSmall={customOffsetSmall}
          customOffsetLarge={customOffsetLarge}
        />

        {this.state.showHistory && (
          <AdminTable>
            <h3>{this.props.t('form.history')}</h3>
            <List className={classes.root}>
              {this.state.history
                .slice(0, this.state.historyOffset)
                .map((historyRow, index) => {
                  return <LogBlock historyRow={historyRow} key={index} />;
                })}
            </List>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                disabled={this.state.allLoaded}
                onClick={() => this.updateHistory(false)}
              >
                {this.state.updating ? (
                  <CircularProgress />
                ) : this.state.allLoaded ? (
                  `${this.props.t('form.loadedRecords')} (${
                    this.state.history.length
                  })`
                ) : (
                  `${this.props.t('form.loadNextRecords')} (${
                    this.state.itemsPerPage
                  })`
                )}
              </Button>
              {!this.state.allLoaded && !this.state.updating && (
                <Button onClick={() => this.updateHistory(true)}>
                  {this.props.t('form.loadAllData', 'Załaduj wszystkie')}
                </Button>
              )}
            </div>
          </AdminTable>
        )}
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

LogView.propTypes = {
  iri: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(combinedStyles)(LogView));

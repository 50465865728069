import React from 'react';
import Accordion from 'components/Accordion/Accordion';
import { calendarStyles, orderStyles } from '../../styles';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { currencyToSymbol } from 'utils/currencies';
import { shallowEqual, useSelector } from 'react-redux';

export const usedDiscountsTranslations = t => ({
  DIET_LENGTH: t('discountTypes.dietLength', 'Długość diety'),
  SAME_ADDRESS: t('discountTypes.sameAddress', 'Ten sam adres'),
  CODE: t('discountTypes.code', 'Kod rabatowy'),
  PICK_UP_POINT: t('discountTypes.pickUpPoint', 'Punkt odbioru'),
});

const renderDiscountItem = (discountType, discountValue, t, currency) => (
  <li style={orderStyles.usedDiscountListItem}>
    <span style={calendarStyles.spaceBetween}>
      <span
        style={
          discountType === 'CODE'
            ? { fontWeight: 'bold', cursor: 'pointer' }
            : {}
        }
      >
        {usedDiscountsTranslations(t)[discountType]}
      </span>
      <span>
        {discountValue} {currencyToSymbol(currency)}
      </span>
    </span>
  </li>
);

const DietDetailsPrices = ({ dietInfo }) => {
  const { t } = useTranslation();
  const { currency } = useSelector(
    state => state.Brands.brand.multinational,
    shallowEqual
  );
  return (
    <div style={{ marginLeft: '5px', ...orderStyles.box }}>
      <Accordion
        active={0}
        collapses={[
          {
            title: t('orders.currentDietPrice', 'Aktualna wartość diety'),
            content: (
              <div style={{ width: '100%' }}>
                {dietInfo.priceDiscount > 0 ? (
                  <div>
                    <h5 style={calendarStyles.spaceBetween}>
                      {t('orders.beforeDiscounts', 'Przed rabatem')}:{' '}
                      <span>
                        {dietInfo.priceBeforeDiscount !== ''
                          ? `${dietInfo.priceBeforeDiscount.toFixed(
                              2
                            )} ${currencyToSymbol(currency)}`
                          : '-'}
                      </span>
                    </h5>
                    <h5>{t('orders.discounts', 'Rabaty')}:</h5>
                    <ol style={{ padding: '0 0 0 12px', margin: '5px 0' }}>
                      {Object.entries(dietInfo.usedDiscounts)
                        .filter(([_, { discount }]) => discount !== 0)
                        .map(([key, { discount, payload }]) =>
                          key === 'CODE' ? (
                            <Tooltip
                              title={
                                <h4>
                                  {t(
                                    'orders.usedDiscountCode',
                                    'Użyty kod rabatowy'
                                  )}{' '}
                                  - {payload?.code}
                                </h4>
                              }
                              placement="right"
                            >
                              {renderDiscountItem(
                                key,
                                discount.toFixed(2),
                                t,
                                currency
                              )}
                            </Tooltip>
                          ) : (
                            renderDiscountItem(
                              key,
                              discount.toFixed(2),
                              t,
                              currency
                            )
                          )
                        )}
                    </ol>

                    <h5 style={calendarStyles.spaceBetween}>
                      {t('orders.discountsTotal', 'Rabaty razem')}:{' '}
                      <span>
                        {dietInfo.priceDiscount !== ''
                          ? `${dietInfo.priceDiscount.toFixed(
                              2
                            )} ${currencyToSymbol(currency)}`
                          : '-'}
                      </span>
                    </h5>
                    <h5 style={calendarStyles.spaceBetween}>
                      {t('orders.afterDiscounts', 'Po rabacie')}:{' '}
                      <span>
                        {dietInfo.priceAfterDiscount !== ''
                          ? `${dietInfo.priceAfterDiscount.toFixed(
                              2
                            )} ${currencyToSymbol(currency)}`
                          : '-'}
                      </span>
                    </h5>
                  </div>
                ) : (
                  <h5 style={calendarStyles.spaceBetween}>
                    {t('columns.cost', 'Koszt')}:{' '}
                    <span>
                      {dietInfo.priceAfterDiscount !== ''
                        ? `${dietInfo.priceAfterDiscount.toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h5>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DietDetailsPrices;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import { compose } from 'redux';
import { combineStyles, isGranted } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { FormLabel, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import SelectInput from 'components/FormSelect/SelectInput';
import { get, put } from 'helpers/apiHelpers';
import { connect, useSelector } from 'react-redux';
import { DialogLoader } from 'components/DialogLoader';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { fetchDiets } from 'actions/Diets';
import { ROLE_EDIT_MODULE_MENU_SELECTION_ASSISTANT } from '../../helpers/roles';
import CardWrapper from 'components/Card/CardWrapper';

const MenuSelectionAssistant = ({ classes, diets }) => {
  const { t } = useTranslation();
  const selectedBrand = useSelector(state => state.Auth.selectedBrand);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [enabledForClient, setEnabledForClient] = useState(false);
  const [orderBy, setOrderBy] = useState(null);

  const [name, setName] = useState(null);
  const [sex, setSex] = useState(null);

  const [allergensAntiPreferencesEnabled, setAllergensAntiPreferencesEnabled] =
    useState(false);
  const [
    mealTypeAllergensAntiPreferencesEnabled,
    setMealTypeAllergensAntiPreferencesEnabled,
  ] = useState(false);
  const [
    ingredientCategoriesAntiPreferencesEnabled,
    setIngredientCategoriesAntiPreferencesEnabled,
  ] = useState(false);
  const [
    mealTypeIngredientCategoriesAntiPreferencesEnabled,
    setMealTypeIngredientCategoriesAntiPreferencesEnabled,
  ] = useState(false);
  const [
    clientTagsAntiPreferencesEnabled,
    setClientTagsAntiPreferencesEnabled,
  ] = useState(false);
  const [
    mealTypeClientTagsAntiPreferencesEnabled,
    setMealTypeClientTagsAntiPreferencesEnabled,
  ] = useState(false);

  const [allergens, setAllergens] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [clientTags, setClientTags] = useState([]);
  const [selectedDiets, setSelectedDiets] = useState([]);

  const [sexes, setSexes] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const [ingredientCategoryOptions, setIngredientCategoryOptions] = useState(
    []
  );
  const [clientTagOptions, setClientTagOptions] = useState([]);
  const orderBys = [
    {
      label: t('brandCfg.menuSelectionAssistant.orderBy.rating'),
      value: 'RATING',
    },
    {
      label: t('brandCfg.menuSelectionAssistant.orderBy.foodCost'),
      value: 'FOOD_COST',
    },
  ];

  useEffect(() => {
    (async () => {
      const {
        configuration: { MenuSelectionAssistant },
      } = await get(
        `brands/${selectedBrand}/modules?module[]=MenuSelectionAssistant`
      );
      fetchDiets();
      setSelectedDiets(MenuSelectionAssistant.diets);
      setEnabledForClient(MenuSelectionAssistant.enabledForClient);
      setOrderBy(MenuSelectionAssistant.orderBy);
      setName(MenuSelectionAssistant.name);
      setSex(MenuSelectionAssistant.sex);
      setAllergensAntiPreferencesEnabled(
        MenuSelectionAssistant.allergensAntiPreferencesEnabled
      );
      setMealTypeAllergensAntiPreferencesEnabled(
        MenuSelectionAssistant.mealTypeAllergensAntiPreferencesEnabled
      );
      setIngredientCategoriesAntiPreferencesEnabled(
        MenuSelectionAssistant.ingredientCategoriesAntiPreferencesEnabled
      );
      setMealTypeIngredientCategoriesAntiPreferencesEnabled(
        MenuSelectionAssistant.mealTypeIngredientCategoriesAntiPreferencesEnabled
      );
      setClientTagsAntiPreferencesEnabled(
        MenuSelectionAssistant.clientTagsAntiPreferencesEnabled
      );
      setMealTypeClientTagsAntiPreferencesEnabled(
        MenuSelectionAssistant.mealTypeClientTagsAntiPreferencesEnabled
      );
      setAllergens(MenuSelectionAssistant.allergens);
      setIngredientCategories(MenuSelectionAssistant.ingredientCategories);
      setClientTags(MenuSelectionAssistant.clientTags);

      get('sexes').then(res => setSexes(res['hydra:member']));
      get('allergens', { pagination: false }).then(res =>
        setAllergenOptions(res['hydra:member'])
      );
      get('/ingredient-categories', { pagination: false }).then(res =>
        setIngredientCategoryOptions(res['hydra:member'])
      );
      get('client-tags', { pagination: false }).then(res =>
        setClientTagOptions(res['hydra:member'])
      );
    })();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'MenuSelectionAssistant',
        configuration: {
          MenuSelectionAssistant: {
            enabledForClient,
            orderBy,
            name,
            sex,
            allergensAntiPreferencesEnabled,
            mealTypeAllergensAntiPreferencesEnabled,
            ingredientCategoriesAntiPreferencesEnabled,
            mealTypeIngredientCategoriesAntiPreferencesEnabled,
            clientTagsAntiPreferencesEnabled,
            mealTypeClientTagsAntiPreferencesEnabled,
            allergens,
            ingredientCategories,
            clientTags,
            diets: selectedDiets,
          },
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <GridContainer>
        <GridItem md={6}>
          <CardWrapper
            title={t('brandCfg.menuSelectionAssistant.generalSettings')}
          >
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enabledForClient}
                        onChange={() => setEnabledForClient(!enabledForClient)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.enabledForClient'
                    )}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.menuSelectionAssistant.orderBy')}
                    <Tooltip
                      style={{ color: 'grey', marginBottom: '-4px' }}
                      title={
                        <h4>
                          {t('brandCfg.menuSelectionAssistant.orderBy.tooltip')}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    name="orderBy"
                    mapBy="label"
                    trackBy="value"
                    options={orderBys}
                    value={orderBy}
                    handleChange={e => setOrderBy(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
        <GridItem md={6}>
          <CardWrapper
            title={t('brandCfg.menuSelectionAssistant.personification')}
          >
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  {t(
                    'brandCfg.menuSelectionAssistant.personification.description'
                  )}
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.menuSelectionAssistant.name')}
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="name"
                    value={name}
                    maxLength={255}
                    handleChange={e => setName(e.target.value)}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.menuSelectionAssistant.sex')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    name="sex"
                    mapBy="value"
                    trackBy="id"
                    options={sexes}
                    value={sex}
                    handleChange={e => setSex(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
        <GridItem md={12}>
          <CardWrapper title={t('brandCfg.menuSelectionAssistant.categories')}>
            <CardBody>
              <GridContainer>
                <GridItem
                  md={12}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'brandCfg.menuSelectionAssistant.categories.allergens'
                    ),
                  }}
                />
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allergensAntiPreferencesEnabled}
                        onChange={() =>
                          setAllergensAntiPreferencesEnabled(
                            !allergensAntiPreferencesEnabled
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.categories.enabledAll'
                    )}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mealTypeAllergensAntiPreferencesEnabled}
                        onChange={() =>
                          setMealTypeAllergensAntiPreferencesEnabled(
                            !mealTypeAllergensAntiPreferencesEnabled
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.categories.enabledMealType'
                    )}
                  />
                </GridItem>
                <GridItem
                  md={12}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'brandCfg.menuSelectionAssistant.categories.ingredientCategories'
                    ),
                  }}
                />
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ingredientCategoriesAntiPreferencesEnabled}
                        onChange={() =>
                          setIngredientCategoriesAntiPreferencesEnabled(
                            !ingredientCategoriesAntiPreferencesEnabled
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.categories.enabledAll'
                    )}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          mealTypeIngredientCategoriesAntiPreferencesEnabled
                        }
                        onChange={() =>
                          setMealTypeIngredientCategoriesAntiPreferencesEnabled(
                            !mealTypeIngredientCategoriesAntiPreferencesEnabled
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.categories.enabledMealType'
                    )}
                  />
                </GridItem>
                <GridItem
                  md={12}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'brandCfg.menuSelectionAssistant.categories.clientTags'
                    ),
                  }}
                />
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientTagsAntiPreferencesEnabled}
                        onChange={() =>
                          setClientTagsAntiPreferencesEnabled(
                            !clientTagsAntiPreferencesEnabled
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.categories.enabledAll'
                    )}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mealTypeClientTagsAntiPreferencesEnabled}
                        onChange={() =>
                          setMealTypeClientTagsAntiPreferencesEnabled(
                            !mealTypeClientTagsAntiPreferencesEnabled
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.categories.enabledMealType'
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
        <GridItem md={12}>
          <CardWrapper
            title={t('brandCfg.menuSelectionAssistant.antiPreferences')}
          >
            <CardBody>
              <GridContainer md={6}>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brandCfg.menuSelectionAssistant.antiPreferences.allergens'
                    )}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple
                    classes={classes}
                    name="allergens"
                    mapBy="value"
                    trackBy="id"
                    options={allergenOptions}
                    value={allergens}
                    handleChange={e => setAllergens(e.target.value)}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brandCfg.menuSelectionAssistant.antiPreferences.ingredientCategories'
                    )}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple
                    classes={classes}
                    name="ingredientCategories"
                    mapBy="value"
                    trackBy="id"
                    options={ingredientCategoryOptions}
                    value={ingredientCategories}
                    handleChange={e => setIngredientCategories(e.target.value)}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brandCfg.menuSelectionAssistant.antiPreferences.clientTags'
                    )}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple
                    classes={classes}
                    name="clientTags"
                    mapBy="value"
                    trackBy="id"
                    options={clientTagOptions}
                    value={clientTags}
                    handleChange={e => setClientTags(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>
      <CardWrapper
        title={t(
          'brandCfg.menuSelectionAssistant.antiPreferencesClientSettings'
        )}
      >
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormLabel className={classes.labelHorizontal}>
                {t('brandCfg.menuSelectionAssistant.antiPreferences.setDiet')}
              </FormLabel>
            </GridItem>

            <GridItem md={12}>
              <SelectInput
                noGrid
                multiple
                classes={classes}
                name="selectedDiets"
                mapBy="name"
                trackBy="id"
                options={diets}
                value={selectedDiets}
                handleChange={e => setSelectedDiets(e.target.value)}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      {isGranted(ROLE_EDIT_MODULE_MENU_SELECTION_ASSISTANT) && (
        <FormControlButtons
          classes={classes}
          submitText={t('form.save')}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    diets: state?.Diets?.diets,
  };
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  connect(mapStateToProps, fetchDiets),
  withStyles(combinedStyles)
);

export default enhance(MenuSelectionAssistant);

import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import { Container, ItemWrapper } from './v2Types.styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableFooter } from '@mui/material';

const OrderItemDayDish = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <h3>{t('orders.orderList.modal.dish', 'Danie')}</h3>
      <p>{item?.dishSize?.dish?.nameForClient}</p>
      <p>{`${t('orders.orderList.modal.quantity', 'Ilość:')} ${
        item?.quantity
      }`}</p>
      <p>{`${t(
        'orders.orderList.modal.priceAfterDiscount',
        'Cena po obniżce:'
      )} ${item?.priceAfterDiscount}`}</p>
      <p>{`${t(
        'orders.orderList.modal.priceBeforeDiscount',
        'Cena przed obniżką:'
      )} ${item?.priceBeforeDiscount}`}</p>
      <p>{Moment(item?.day).format(Moment.localeData().longDateFormat('L'))}</p>
    </ItemWrapper>
  );
};
const OrderItemDayDiet = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              {t(
                'orders.orderList.modal.purchaseDayToDish',
                'Dokupienie dnia do diety'
              )}
              <a href={'/admin/ecommerce-diets/preview/' + item.clientDiet?.id}>
                {' '}
                #{item.clientDiet?.id}
              </a>{' '}
              {t('orders.orderList.modal.onDay', 'Na dzień')}{' '}
              {Moment(item?.date).format(
                Moment.localeData().longDateFormat('L')
              )}{' '}
              (
              {t(
                'orders.orderList.modal.renewalSubscription',
                'Odnowienie subskrypcji'
              )}
              )
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              Parametry diety
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('diets.diet', 'Dieta')}</TableCell>
            <TableCell>{item.offerDiet.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('clients.variant', 'Wariant')}</TableCell>
            <TableCell>
              {item.variant.name}
              <br />
              {item.mealTypes.map(mealType => mealType.name).join(', ')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('clients.calories', 'Kaloryczność')}</TableCell>
            <TableCell>{item.calorific.name}</TableCell>
          </TableRow>
          {item.addons.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                  {t('orders.orderList.modal.additions', 'Dodatki:')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t('orders.orderList.modal.name', 'Nazwa')}
                </TableCell>
                <TableCell>
                  {t('orders.orderList.modal.quantity', 'Ilość:')}
                </TableCell>
              </TableRow>
              {item.addons.map((addon, index) => (
                <TableRow key={index}>
                  <TableCell>{addon.addon.name}</TableCell>
                  <TableCell>{addon.quantity}</TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              {t('orders.orderList.modal.sumamry', 'Podsumowanie')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {t(
                        'orders.orderList.modal.priceAfterDiscount',
                        'Cena po obniżce:'
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        'orders.orderList.modal.priceBeforeDiscount',
                        'Cena przed obniżką:'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{item?.priceAfterDiscount}</TableCell>
                    <TableCell>{item?.priceBeforeDiscount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </ItemWrapper>
  );
};

const OrderItemDayAddon = ({ item }) => {
  const { t } = useTranslation();
  return (
    <ItemWrapper>
      <h3>{t('orders.orderList.modal.addition', 'Dodatek')}</h3>
      <p>{item?.purchasedAddon?.addon?.name}</p>
      <p>{`${t('orders.orderList.modal.quantity', 'Ilość:')} ${
        item?.purchasedAddon?.quantity
      }`}</p>
      <p>{`${t(
        'orders.orderList.modal.priceAfterDiscount',
        'Cena po obniżce:'
      )} ${item?.priceAfterDiscount}`}</p>
      <p>{`${t(
        'orders.orderList.modal.priceBeforeDiscount',
        'Cena przed obniżką:'
      )} ${item?.priceBeforeDiscount}`}</p>
      <p>{Moment(item?.day).format(Moment.localeData().longDateFormat('L'))}</p>
    </ItemWrapper>
  );
};

const OrderItemDayMealType = ({ item }) => {
  const { t } = useTranslation();
  return (
    <ItemWrapper>
      <p>
        <b>
          {`${t(
            'orders.orderList.modal.additionalMealTypes',
            'Dodatkowe posiłki:'
          )}
          ${item?.mealType}`}
        </b>
      </p>
      <p>{`${t(
        'orders.orderList.modal.priceBeforeDiscount',
        'Cena przed obniżką:'
      )} ${item?.priceBeforeDiscount}`}</p>
      <p>{`${t(
        'orders.orderList.modal.priceAfterDiscount',
        'Cena po obniżce:'
      )} ${item?.priceAfterDiscount}`}</p>
      <p>{`${t('orders.orderList.modal.quantity', 'Ilość:')} ${
        item?.quantity
      }`}</p>
      <p>{item?.day}</p>
    </ItemWrapper>
  );
};

const OrderItemDiet = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <h3>{t('orders.orderList.modal.diet', 'Dieta')}</h3>
      <p>
        {`${t('orders.orderList.modal.variant', 'Wariant:')} ${
          item.variant.name
        }`}
      </p>
      <p>
        {`${('orders.orderList.modal.calorific', 'Kaloryczność:')} ${
          item.calorific.name
        }`}
      </p>
      <p>
        {item?.address?.postCode} {item?.address?.city}, {item?.address?.street}{' '}
        {item?.address?.buildNumber}
        {item?.address?.placeNumber && `\\${item?.address?.placeNumber}`}
      </p>
      <p>{`${t(
        'orders.orderList.modal.priceAfterDiscount',
        'Cena po obniżce:'
      )}  ${item?.priceV2?.afterDiscount}`}</p>
      <p>{`${t(
        'orders.orderList.modal.priceBeforeDiscount',
        'Cena przed obniżką:'
      )} ${item?.priceV2?.beforeDiscount}`}</p>
    </ItemWrapper>
  );
};
const OrderItemDayDelivery = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <h3>{t('orders.orderList.modal.delivery', 'Dostawa')}</h3>
      <p>{Moment(item?.day).format(Moment.localeData().longDateFormat('L'))}</p>
      {item.pickupPoint && <>{item.pickupPoint.value}</>}
      {item.address && (
        <p>
          {item.address.postCode} {item.address.city}, {item.address.street}{' '}
          {item.address.buildNumber}/{item.address.placeNumber}
        </p>
      )}
    </ItemWrapper>
  );
};
const OrderItemDayModification = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
        {'Modyfikacja zamówienia'}
      </h3>
      {!!item?.changedDate && (
        <p>
          {t('orderItemDayModification.changedDate', {
            defaultValue: 'Zmiana daty dostawy: {{date}}',
            replace: {
              date: Moment(item?.changeDate?.date).format(
                Moment.localeData().longDateFormat('L')
              ),
            },
          })}
        </p>
      )}
      {!!item?.changedDiet && (
        <p>
          {t('orderItemDayModification.changedDiet', {
            defaultValue: 'Zmiana diety: {{dietName}}',
            replace: {
              dietName: item?.changedDiet?.name,
            },
          })}
        </p>
      )}
      {!!item?.changedVariant && (
        <p>
          {t('orderItemDayModification.changedVariant', {
            defaultValue: 'Zmiana wariantu: {{variantName}}',
            replace: {
              variantName: item?.changedVariant?.name,
            },
          })}
        </p>
      )}
      {!!item?.changedCalorific && (
        <p>
          {t('orderItemDayModification.changedCalorific', {
            defaultValue: 'Zmiana kaloryczności: {{calorificName}}',
            replace: {
              calorificName: item?.changedCalorific?.name,
            },
          })}
        </p>
      )}
      {!!item?.changedDietOptions?.optionChangeMenu && (
        <p>
          {t(
            'orderItemDayModification.changedMenuOption',
            '$$Dokupiono wybór menu'
          )}
        </p>
      )}
      {!!item?.changedDietOptions?.useEcoContainers && (
        <p>
          {t(
            'orderItemDayModification.changedEcoContainersOption',
            '$$Dokupiono eko opakowania'
          )}
        </p>
      )}
      {!!item?.changedAddress && (
        <>
          <p>
            {t('orderItemDayModification.changedAddress', '$$Zmiana adresu: ')}
          </p>
          <p>
            {item.changedAddress.postCode} {item.changedAddress.city},{' '}
            {item.changedAddress.street} {item.changedAddress.buildNumber}/
            {item.changedAddress.placeNumber}
          </p>
        </>
      )}
      {!!item?.changedPickUpPoint && (
        <>
          <p>
            {t(
              'orderItemDayModification.changedPickupPoint',
              '$$Zmiana punktu odbioru: '
            )}
          </p>
          <p>{item?.changedPickUpPoint?.value}</p>
        </>
      )}
      <p style={{ marginTop: '15px' }}>
        {t('orderItem.priceAfterDiscount', {
          defaultValue: 'Cena po obniżce: {{price}}',
          replace: { price: item?.priceV2?.afterDiscount },
        })}
      </p>
      <p>
        {t('orderItem.priceBeforeDiscount', {
          defaultValue: 'Cena przed obniżką: {{price}}',
          replace: { price: item?.priceV2?.beforeDiscount },
        })}
      </p>
      <p>
        {Moment(item?.bag?.date).format(
          Moment.localeData().longDateFormat('L')
        )}
      </p>
    </ItemWrapper>
  );
};

const V2Components = {
  OrderItemDayDish,
  OrderItemDayAddon,
  OrderItemDiet,
  OrderItemDayDelivery,
  OrderItemDayModification,
  OrderItemDayDiet,
  OrderItemDayMealType,
};

const V2Types = ({ items, fillModal }) => {
  const { t } = useTranslation();

  const createModalContent = () => {
    return items.map(item => {
      if (!V2Components[item.type]) {
        return '';
      }
      const V2Component = V2Components[item.type];

      return <V2Component item={item} />;
    });
  };

  const getModalContent = () => {
    fillModal(<Container>{createModalContent()}</Container>);
  };

  const isAnyItemDayModification = items.some(
    ({ type }) => type === 'OrderItemDayModification'
  );
  const newBagItems = items.filter(({ type }) => type === 'OrderItemDayDiet');

  return (
    <span
      style={{ color: '#89229b', cursor: 'pointer' }}
      onClick={getModalContent}
    >
      {isAnyItemDayModification
        ? t('orders.modification', '$$Modyfikacje')
        : newBagItems.length > 0
        ? `${t('orders.buyBag.subscriptionRenewal', 'Odnowienie subskrypcji')}${newBagItems.length > 1 ? ` x${newBagItems.length}` : ''}`
        : t('orders.items', '$$Przedmioty')}
    </span>
  );
};

export default V2Types;

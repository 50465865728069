import types from 'helpers/constants';

const initialState = {
  allBrandsMenuPlanners: {},
};

function MenuPlanners(state = initialState, action) {
  switch (action.type) {
    case types.INITIALIZE_MENU_PLANNERS:
      return {
        ...state,
        allBrandsMenuPlanners: {
          ...(action.payload?.allBrandsMenuPlanners ??
            state.allBrandsMenuPlanners),
        },
      };
    case types.UPDATE_VISIBLE_VARIANTS:
      return {
        ...state,
        allBrandsMenuPlanners: {
          ...state.allBrandsMenuPlanners,
          [action.payload.brandIri]: {
            ...(state.allBrandsMenuPlanners?.[[action.payload.brandIri]] ?? {}),
            visibleVariants: action.payload?.visibleVariants,
          },
        },
      };
    case types.UPDATE_VISIBLE_DIETS:
      return {
        ...state,
        allBrandsMenuPlanners: {
          ...state.allBrandsMenuPlanners,
          [action.payload.brandIri]: {
            ...(state.allBrandsMenuPlanners?.[[action.payload.brandIri]] ?? {}),
            visibleDiets: action.payload?.visibleDiets,
          },
        },
      };
    default:
      return state;
  }
}

export default MenuPlanners;

const defaultState = {
  firstName: '',
  lastName: '',
  phone: { number: '', countryCode: '' },
  email: '',
  hourlySalary: 0.0,
  productionWorker: false,
  plainPassword: '',
  plainPasswordMatch: '',
  selectedRoles: [],
  brands: [],
  selectedBrands: [],
  isEmailNotifyEnabled: true,
  limitationSettingsManagement: false,
};

export default defaultState;

import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import GeneralInfo from './GeneralInfo/GeneralInfo';
import LogView from 'components/History/LogView';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

class UserForm extends Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <GeneralInfo classes={classes} />
        <LogView iri={`/employees/${this.props.match.params.id}`} />
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withRouter);

export default enhance(UserForm);

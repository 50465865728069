import { Fragment } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { DialogLoader } from '../../../components/DialogLoader';
import { usePanelConfigurationContext } from './PanelConfiguration.context';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import StatusIcons from '../components/StatusIcons';
import CustomMenuOptions from './CustomMenuOptions';
import ClientPanelGeneralSettings from './ClientPanelGeneralSettings';
import DishDetails from './DishDetails';
import RefLinksSettings from './RefLinksSettings';
import DishImageConfig from './DishImageConfig';

const PanelConfigurationView = ({ t, classes }) => {
  const {
    module,
    isLoading,
    handleModuleUpdateConfigClientUpdate,
    handleSubmit,
    configClientPanelModule,
    setModule,
  } = usePanelConfigurationContext();

  return (
    <Fragment>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      {!isLoading && (
        <Fragment>
          <ClientPanelGeneralSettings />
          <CustomMenuOptions />
          <DishDetails />

          {configClientPanelModule.hideSmallCalendar ? null : (
            <GridContainer>
              <GridItem md={12}>
                <StatusIcons
                  removeIcon={() => {}}
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdateConfigClientUpdate}
                  module={configClientPanelModule}
                  hasSubscription={false}
                />
              </GridItem>
            </GridContainer>
          )}

          <DishImageConfig />
          <RefLinksSettings />

          {module.configuration.SeoCmsClientPanelLogin && (
            <SEOSettingsCms
              state={module}
              setState={setModule}
              classes={classes}
              section="SeoCmsClientPanelLogin"
            />
          )}
          {module.configuration.SeoCmsClientPanelRegister && (
            <SEOSettingsCms
              state={module}
              setState={setModule}
              classes={classes}
              section="SeoCmsClientPanelRegister"
            />
          )}
          {module.configuration.SeoCmsClientPanelMyAccount && (
            <SEOSettingsCms
              state={module}
              setState={setModule}
              classes={classes}
              section="SeoCmsClientPanelMyAccount"
            />
          )}
          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(PanelConfigurationView);

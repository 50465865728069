import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import LocalMallOutlined from '@material-ui/icons/LocalMallOutlined';

import { get } from 'helpers/apiHelpers';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import { useTranslation } from 'react-i18next';

const Dashboard = ({ classes, selectedBrand }) => {
  const [bags, setBags] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getBags();
  }, []);

  const getBags = () => {
    try {
      get(`statistics/ecommerce/bags/day`, {
        brandId: [selectedBrand],
        date: Moment(new Date()).format('YYYY-MM-DD'),
      }).then(({ value }) => setBags(value));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Card>
      <CardHeader color="success" quickStats icon>
        <CardIcon color="success">
          <LocalMallOutlined />
        </CardIcon>
        <div>
          <h3 className={classes.quickStatsTitle}>{t('dashboard.bags')}</h3>
          <p className={classes.quickStatsValue}>{bags}</p>
        </div>
      </CardHeader>
      <CardFooter stats>
        <p className={classes.quickStatsDescription}>
          {t('dashboard.bagsDescription')}
        </p>
      </CardFooter>
    </Card>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(dashboardStyle)(Dashboard));

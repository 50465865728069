import { get, remove } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchPackages = () => {
  return dispatch => {
    return get('/packages', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_DIETS,
        payload: {
          diets: response['hydra:member'],
        },
      });
    });
  };
};

export const fetchAllPackages = () => {
  return dispatch => {
    return get('/packages', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_PACKAGES,
        payload: {
          packages: response['hydra:member'],
        },
      });
    });
  };
};

export const fetchPackage = id => {
  return dispatch => {
    return get(`/packages/${id}`).then(response => {
      dispatch({
        type: types.FETCH_DIET,
        payload: {
          id: response.id,
          name: response.name,
          diets: response.diets,
          image: response.image,
          active: response.active,
          position: response.position,
          description: response.description,
          showOtherDiets: response.showOtherDiets,
        },
      });
      return response;
    });
  };
};

export const removeDiet = id => {
  return dispatch =>
    remove(`/diets/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_DIET,
        payload: {
          dietId: id,
        },
      });
    });
};

import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class IncomeSummary extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportsIncomeSummary');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/income-summary"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_INCOME_SUMMARY}
        pdfAvailable={false}
        excelAvailable={true}
        dateType="range"
        archiveReportNames={['INCOME_SUMMARY']}
      />
    );
  }
}

export default withTranslation()(IncomeSummary);

import React from 'react';
import TagsInput from 'react-tagsinput';
import { connect } from 'react-redux';

// Helpers
import axios from 'helpers/gastro';
import roles from 'helpers/roles';
import { isGranted, sortByPosition } from 'helpers/helpers';

// Core Components
import Card from 'components/Card/Card.jsx';
import LogView from 'components/History/LogView';
import GridItem from 'components/Grid/GridItem.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import DataGrid from 'components/DataGrid/DataGrid';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer.jsx';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import { DialogLoader } from 'components/DialogLoader';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import { mapArrayOfTranslationsToPureArray } from 'components/Translatable/TranslatableReader';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
import { Divider } from '@material-ui/core';

// Custom elements
import columnConfig from './commentsColumnConfig';
import TypeBlockAdvanced from './TypeBlockComponents/TypeBlockAdvanced';
import PreparationInstructions from './PreparationInstructions';
import menuPlannerColumnConfig from './menuPlannerColumnConfig';

//Assets
import defaultImageForClient from 'assets/img/dishPhotoForClientTemplate.jpg';
import FormTextInputTranslatable from '../../components/FormTextInput/FormTextInputTranslatable';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import TOAST_DURATIONS from 'helpers/toastDurations';

const translateMacronutrients = t => ({
  calories: t('form.ingredientsDetails.calories'),
  carbohydrates: t('common.carbohydrates'),
  fat: t('form.ingredientsDetails.fat'),
  protein: t('form.ingredientsDetails.protein'),
});

class MealForm extends React.Component {
  state = {
    isGenerating: false,
    selectedBrandForPdfPreview: null,
  };
  handleGenerate = (dishName, url) => {
    this.setState({ isGenerating: true });
    axios
      .get(url, {
        responseType: 'blob',
        headers: {
          accept: 'application/pdf',
          'X-G-BRAND': this.state.selectedBrandForPdfPreview,
        },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${dishName}.pdf`);
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(
              'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
            );
          }
        },
        error => {
          this.props.openToast({
            messages: [
              'Nie udało się wygenerować raportu',
              error.response ? error.response.data['hydra:description'] : error,
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  handleBrandChose = e => {
    const selectedBrandForPdfPreview = e.target.value;
    this.setState({ selectedBrandForPdfPreview });
  };

  componentDidMount() {
    const brandsOfSelectedMealTypes = this.props.isEditing
      ? this.props.dish.brands.filter(brand =>
          this.props.savedDishMealTypeBrands.some(
            dishType => dishType['@id'] === brand['@id']
          )
        )
      : [];

    this.setState({
      selectedBrandForPdfPreview: brandsOfSelectedMealTypes[0]?.id,
    });
  }

  render() {
    const { classes } = this.props;

    const groupedTypes = this.props.dish.types.reduce(
      (acc, { brand, ...itemWithoutBrand }) => {
        const { '@id': brandId } = brand;

        return {
          ...acc,
          [brandId]: {
            ...brand,
            types:
              acc[brandId] === undefined
                ? [itemWithoutBrand]
                : [...acc[brandId].types, itemWithoutBrand],
          },
        };
      },
      {}
    );

    const selectedBrandMealTypes = Object.values(groupedTypes).reduce(
      (acc, { types, name }) => {
        const typesWithBrand = types.map(type => ({
          ...type,
          brandName: name,
        }));

        return [...acc, ...typesWithBrand];
      },
      []
    );

    const dishToCalculateNutrients =
      this.props.dish.sizes.length > 0
        ? this.props.dish.sizes.find(dish => dish.size === '/sizes/83') ||
          this.props.dish.sizes[0]
        : { ingredients: [] };

    const dishNutrients = dishToCalculateNutrients.ingredients.reduce(
      (prev, curr) => {
        const nutrients = {
          calories:
            +curr.quantity * curr.ingredient?.calories ||
            curr.ingredient?.macrosAfterProcessing.calories ||
            curr.recipe?.macrosAfterProcessing.calories,
          carbohydrates:
            +curr.quantity * curr.ingredient?.carbohydrates ||
            curr.ingredient?.macrosAfterProcessing.carbohydrates ||
            curr.recipe?.macrosAfterProcessing.carbohydrates,
          fat:
            +curr.quantity * curr.ingredient?.fat ||
            curr.ingredient?.macrosAfterProcessing.fat ||
            curr.recipe?.macrosAfterProcessing.fat,
          protein:
            +curr.quantity * curr.ingredient?.protein ||
            curr.ingredient?.macrosAfterProcessing.protein ||
            curr.recipe?.macrosAfterProcessing.protein,
        };
        return {
          calories: prev.calories + Math.round(nutrients.calories * 5) / 10,
          carbohydrates:
            prev.carbohydrates + Math.round(nutrients.carbohydrates * 5) / 10,
          fat: prev.fat + Math.round(nutrients.fat * 5) / 10,
          protein: prev.protein + Math.round(nutrients.protein * 5) / 10,
        };
      },
      {
        calories: 0,
        carbohydrates: 0,
        fat: 0,
        protein: 0,
      }
    );

    const { t } = this.props;
    const macronutrients = translateMacronutrients(t);

    const brandsOfSelectedMealTypes = this.props.isEditing
      ? this.props.dish.brands.filter(brand =>
          this.props.savedDishMealTypeBrands.some(
            type => type['@id'] === brand['@id']
          )
        )
      : [];

    const selectedBrandForPdfPreview = brandsOfSelectedMealTypes.find(
      brand => brand.id === this.state.selectedBrandForPdfPreview
    );

    return (
      <div>
        <DialogLoader
          loaderState={this.props.isSubmitting}
          text={t('form.savingChanges', 'Zapisywanie zmian...')}
        />
        <DialogLoader
          loaderState={this.props.isLoading}
          text={t('form.loadingMeal', 'Wczytywanie dania...')}
        />
        <DialogLoader
          loaderState={this.state.isGenerating}
          text={t('reports.generatingPdf', 'Trwa generowanie PDF...')}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <form>
                  <GridContainer justify="center">
                    <GridItem md={12}>
                      <GridContainer>
                        <GridItem xs={12} md={6}>
                          <h3>{t('form.basicInfo')}</h3>
                          <Divider />
                          <GridContainer>
                            <GridItem xs={12}>
                              <FormLabel style={{ fontSize: '14px' }}>
                                {t('form.field.defaultName') + '*'}
                              </FormLabel>
                              <FormTextInputTranslatable
                                noGrid
                                classes={classes}
                                name="nameForClient"
                                value={this.props.dish.nameForClient}
                                handleChange={this.props.handleChange}
                                maxLength={160}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12}>
                              <FormLabel style={{ fontSize: '14px' }}>
                                {t('form.field.description')}
                              </FormLabel>
                              <FormTextInput
                                noGrid
                                classes={classes}
                                name="note"
                                value={this.props.dish.note}
                                handleChange={this.props.handleChange}
                                maxLength={160}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={4}>
                              <FormTextInput
                                label={t('form.field.preparationTime')}
                                type="number"
                                classes={classes}
                                name="preparingEstimatedMinutes"
                                value={
                                  this.props.dish.preparingEstimatedMinutes
                                }
                                handleChange={this.props.handleChange}
                                inputSize={12}
                              />
                            </GridItem>
                            <GridItem xs={4}>
                              <FormLabel className={classes.labelHorizontal}>
                                {t('form.field.tagsForClient')}
                              </FormLabel>
                              <SelectInput
                                multiple
                                noGrid
                                classes={classes}
                                label={t('form.field.tagsForClient')}
                                options={this.props.clientTags}
                                value={this.props.dish.clientTags}
                                name="clientTags"
                                mapBy="value"
                                trackBy="@id"
                                handleChange={this.props.handleChange}
                              />
                            </GridItem>
                            <GridItem xs={4}>
                              <FormLabel className={classes.labelHorizontal}>
                                {t('form.field.homeRequirements')}
                              </FormLabel>
                              <SelectInput
                                multiple
                                noGrid
                                classes={classes}
                                label="Home requirements"
                                options={this.props.homeRequirements}
                                value={this.props.dish.homeRequirements}
                                name="homeRequirements"
                                mapBy="value"
                                trackBy="@id"
                                handleChange={this.props.handleChange}
                              />
                            </GridItem>
                          </GridContainer>
                          <h3>{t('form.field.dishIngredients')}</h3>
                          <Divider />
                          <GridContainer>
                            <GridItem xs={12}>
                              <FormSelectDropdown
                                filterName="name"
                                includeWorkName
                                placeholder={t('form.field.addIngredient')}
                                handleClick={this.props.addMeal}
                                clearAfterSelect={true}
                                filter={this.props.getFilteredIngredients}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            {Object.values(groupedTypes).map(
                              ({ name, types }) =>
                                !types.length ? (
                                  <GridItem md={12}>
                                    <div
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '20px',
                                      }}
                                    >
                                      <h1>
                                        {t(
                                          'mealForm.chooseMealTypesToConfigureDishes',
                                          'Wybierz typy posiłków, aby konfigurować dania'
                                        )}
                                      </h1>
                                    </div>
                                  </GridItem>
                                ) : (
                                  <GridItem md={12}>
                                    {sortByPosition(types).map(
                                      (type, typeKey) => (
                                        <TypeBlockAdvanced
                                          classes={classes}
                                          key={typeKey}
                                          type={type}
                                          handleRemoveIngredient={
                                            this.props.handleRemove
                                          }
                                          mealTypeIngredientsSum={
                                            this.props.mealTypeIngredientsSum
                                          }
                                          dishSizes={this.props.dish.sizes}
                                          onChange={
                                            this.props.handleQuantityChange
                                          }
                                          selectedBrandMealTypes={
                                            selectedBrandMealTypes
                                          }
                                          {...this.props}
                                        />
                                      )
                                    )}
                                  </GridItem>
                                )
                            )}
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={12}>
                              <div style={{ marginBottom: '5px' }}>
                                <FormLabel style={{ fontSize: '14px' }}>
                                  {t('form.field.allergens')}
                                </FormLabel>
                              </div>
                              <TagsInput
                                tagProps={{
                                  className:
                                    'react-tagsinput-tag react-tagsinput-tag-small react-tagsinput-tag-disabled danger',
                                }}
                                inputProps={{ placeholder: '' }}
                                disabled
                                value={[
                                  ...new Set(
                                    mapArrayOfTranslationsToPureArray(
                                      this.props.allergens
                                    )
                                  ),
                                ]}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={12}>
                              <div>
                                {t('form.fielf.macronutrientsPerPerson')}:{''}
                              </div>
                              {Object.entries(dishNutrients).map(
                                ([key, val]) => (
                                  <span
                                    style={{
                                      marginRight: '20px',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {macronutrients[key]}:&nbsp;{val.toFixed(2)}
                                  </span>
                                )
                              )}
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <h3>{t('form.field.photos')}</h3>
                          <Divider />
                          <GridContainer>
                            <GridItem xs={6} l={4}>
                              <FormImageUpload
                                label={t(
                                  'form.field.photoForProduction',
                                  'Zdjęcie dla produkcji'
                                )}
                                classes={classes}
                                stateName={'productionImage'}
                                getImage={this.props.getImage}
                                removeImage={this.props.removeImage}
                                previewUrl={this.props.productionImageUrl}
                              />
                            </GridItem>
                            <GridItem xs={6} l={4}>
                              <FormImageUpload
                                label={t(
                                  'mealForm.pictureForClient',
                                  'Zdjęcie dla klienta'
                                )}
                                classes={classes}
                                stateName={'clientImage'}
                                getImage={this.props.getImage}
                                defaultImage={defaultImageForClient}
                                removeImage={this.props.removeImage}
                                previewUrl={this.props.clientImageUrl}
                              />
                            </GridItem>
                            <GridItem xs={6} l={4}>
                              <FormImageUpload
                                label={t(
                                  'form.field.mealPdf',
                                  'Zdjęcie posiłku'
                                )}
                                classes={classes}
                                stateName={'pdfImage'}
                                getImage={this.props.getImage}
                                removeImage={this.props.removeImage}
                                previewUrl={this.props.pdfImageUrl}
                              />
                            </GridItem>
                          </GridContainer>
                          <h3>{t('form.field.preparationInstruction')}</h3>
                          <Divider />
                          <PreparationInstructions {...this.props} />
                          <GridItem xs={12}>
                            <FormLabel style={{ fontSize: '14px' }}>
                              {t('form.field.tips')}
                            </FormLabel>
                            <FormTextInput
                              noGrid
                              classes={classes}
                              name="tips"
                              value={this.props.dish.tips}
                              handleChange={this.props.handleChange}
                              maxLength={160}
                            />
                          </GridItem>
                        </GridItem>
                        {isGranted(roles.ROLE_SHOW_MENU_PLANNING) &&
                          this.props.isEditing && (
                            <GridItem md={6}>
                              <h3>
                                {t('form.field.dishAppearedOnMenuPlanning')}
                              </h3>
                              <Divider />
                              <GridContainer>
                                <GridItem md={12}>
                                  <DataGrid
                                    disableExport
                                    disableTotal
                                    defaultSorted={[{ id: 'date', desc: true }]}
                                    filterable={false}
                                    sortable={false}
                                    paginationTop={true}
                                    paginationBottom={false}
                                    url="/menu-planners"
                                    reportName={'menu-planners'}
                                    columns={menuPlannerColumnConfig}
                                    role="MENU_PLANNER"
                                    minRows={5}
                                    customDefaultPageSize={10}
                                    showPageSizeOptions={false}
                                    manipulateQuery={(requestData, query) => {
                                      query['draft'] = false;
                                      query['dish'] =
                                        this.props.match.params.id;
                                      query['selectInBrands'] =
                                        this.props.dish.brands.map(
                                          brand => brand['@id']
                                        );

                                      query['properties'] = [
                                        'date',
                                        'dish',
                                        'brand',
                                        'variant',
                                      ];
                                      query['properties[diet]'] = ['name'];
                                      query['properties[brand]'] = ['name'];
                                      query['properties[variant]'] = ['name'];

                                      return query;
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )}
                        {isGranted(roles.ROLE_SHOW_RATE) &&
                          this.props.isEditing && (
                            <GridItem md={6}>
                              <h3>
                                {t('common.heading.comments', 'Komentarze')}
                              </h3>
                              <Divider />
                              <GridContainer>
                                <GridItem md={12}>
                                  {this.props.dish.brands.length && (
                                    <DataGrid
                                      disableExport
                                      disableTotal
                                      filterable={false}
                                      sortable={false}
                                      paginationTop={true}
                                      paginationBottom={false}
                                      url={`/client-dish-rates-view?dish.id=${this.props.match.params.id}`}
                                      reportName={'r'}
                                      columns={columnConfig}
                                      role="RATE"
                                      minRows={5}
                                      customDefaultPageSize={5}
                                      showPageSizeOptions={false}
                                      manipulateQuery={(requestData, query) => {
                                        query['length_filter[gt][comment]'] = 0;

                                        query['selectInBrands'] =
                                          this.props.dish.brands.map(
                                            brand => brand['@id']
                                          );

                                        return query;
                                      }}
                                    />
                                  )}
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* {!this.props.isLoading && (
          <FormControlButtons
            classes={classes}
            discardText={t('form.cancel')}
            submitText={t('form.saveAndClose')}
            cancelPath="/admin/meals"
            secondarySubmitText={t('form.save')}
            handleSubmit={e => this.props.handleSubmit(e, true)}
            handleSecondarySubmit={e => this.props.handleSubmit(e, false)}
            customButtonsRight={
              this.props.dish.id
                ? [
                    {
                      label: t('form.pdfView'),
                      style: { margin: '5px' },
                      className: classes.marginLeft,
                      onClick: () => {
                        const url = `${process.env.REACT_APP_API_ENDPOINT}dishes/${this.props.dish.id}/pdf?xbrand=${this.state.selectedBrandForPdfPreview}&disposition=attachment`;
                        this.handleGenerate(this.props.dish.nameForClient, url);
                      },
                    },
                  ]
                : []
            }
          />
        )} */}
        {this.props.dish.id ? (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'inline-block', margin: '0 15px 0 auto' }}>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.field.brandForPdfPreviewLabel')}
              </FormLabel>
              <SelectInput
                noGrid
                classes={classes}
                options={brandsOfSelectedMealTypes}
                value={selectedBrandForPdfPreview}
                name="brandForPdfPreview"
                mapBy="name"
                trackBy="id"
                handleChange={this.handleBrandChose}
              />
            </div>
          </div>
        ) : null}

        {!this.props.isLoading && !this.props.isEditing && (
          <FormControlStickyButton
            t={t}
            classes={classes}
            discardText={t('form.cancel')}
            submitText={t('form.saveAndClose')}
            cancelPath={'/admin/meals'}
            handleSubmit={e => this.props.handleSubmit(e, true)}
            history={false}
            secondarySubmitText={t('form.save')}
            handleSecondarySubmit={e => this.props.handleSubmit(e, false)}
            customButtonsRight={
              this.props.dish.id
                ? [
                    {
                      label: t('form.pdfView'),
                      style: { margin: '5px' },
                      className: classes.marginLeft,
                      onClick: () => {
                        const url = `${process.env.REACT_APP_API_ENDPOINT}dishes/${this.props.dish.id}/pdf?xbrand=${this.state.selectedBrandForPdfPreview}&disposition=attachment`;
                        this.handleGenerate(this.props.dish.nameForClient, url);
                      },
                    },
                  ]
                : []
            }
          />
        )}

        {this.props.isEditing && (
          <LogView
            t={t}
            classes={classes}
            discardText={t('form.cancel')}
            submitText={t('form.saveAndClose')}
            cancelPath={'/admin/meals'}
            handleSubmit={e => this.props.handleSubmit(e, true)}
            secondarySubmitText={t('form.save')}
            handleSecondarySubmit={e => this.props.handleSubmit(e, false)}
            customButtonsRight={
              this.props.dish.id
                ? [
                    {
                      label: t('form.pdfView'),
                      style: { margin: '5px' },
                      className: classes.marginLeft,
                      onClick: () => {
                        const url = `${process.env.REACT_APP_API_ENDPOINT}dishes/${this.props.dish.id}/pdf?xbrand=${this.state.selectedBrandForPdfPreview}&disposition=attachment`;
                        this.handleGenerate(this.props.dish.nameForClient, url);
                      },
                    },
                  ]
                : []
            }
            iri={`/dishes/${this.props.match.params.id}`}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedBrand: state.Auth.selectedBrand,
});

export default connect(mapStateToProps, null)(MealForm);

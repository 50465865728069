import React from 'react';
import { TableCell } from '@material-ui/core';

class CaloriesSumCell extends React.Component {
  render() {
    const { dishSizes, size, mealTypeSize, rowType } = this.props;

    let sum = 0;

    if (rowType === 'sum') {
      const dishSizeBySize = dishSizes.find(
        dishSize => dishSize.size === size['@id']
      );

      dishSizeBySize.ingredients.forEach(ingredient => {
        if (ingredient.recipe === null) {
          sum +=
            (ingredient.ingredient.macrosAfterProcessing.calories *
              ingredient.ingredient.weight *
              ingredient.quantity) /
            100;
        } else if (ingredient.ingredient === null) {
          sum +=
            ingredient.recipe.macrosAfterProcessing.calories *
            ingredient.quantity;
        }
      });

      return <TableCell>{`${sum.toFixed(0)} kcal`}</TableCell>;
    } else if (rowType === 'required') {
      sum = mealTypeSize.calories;

      return <TableCell>{`${sum} kcal`}</TableCell>;
    }
  }
}

export default CaloriesSumCell;

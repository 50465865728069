import DataGrid from 'components/DataGrid/DataGrid';
import AdminTable from 'layouts/AdminTable';
import React from 'react';

const FormIngredientsUseInRecipe = ({ match, t }) => {
  return (
    <AdminTable
      title={t('$*ingredient.form.usedInRecipes', '$$Użyty w przepisach')}
    >
      <DataGrid
        actions={false}
        remove={false}
        hideArchive={true}
        paginationTop={true}
        paginationBottom={false}
        url={'/recipes'}
        columns={[
          {
            title: 'ID',
            accessor: 'id',
            name: 'id',
            filterable: true,
            sortable: true,
          },
          {
            title: 'form.field.recipe',
            accessor: 'workName',
            name: 'workName',
            filterable: true,
          },
          {
            title: 'form.field.kitchen',
            accessor: 'kitchen.value',
            name: 'kitchen.value',
            filterable: false,
          },
        ]}
        manipulateQuery={(table, query) => {
          query['ingredients.ingredient.id'] = match.params.id;

          return query;
        }}
      />
    </AdminTable>
  );
};

export default FormIngredientsUseInRecipe;

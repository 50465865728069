import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class List extends Component {
  render() {
    return (
      <AdminTable
        title={this.props.t('comments.commentsList', 'Lista komentarzy')}
      >
        <DataGrid
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/rates"
          reportName={'rate'}
          columns={columnConfig(this.props.t)}
          role="RATE"
          minRows={20}
          manipulateQuery={(_, query) => {
            query._orX = [];

            if (query.createdAt) {
              const filters = query.createdAt;

              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }

              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }

              delete query.createdAt;
            }

            if (query.user) {
              const splicedName = query.user.split(' ');

              if (splicedName.length === 1) {
                query._orX.push([
                  {
                    'bag.clientDiet.client.firstName': query.user,
                    'dishItem.bag.clientDiet.client.firstName': query.user,
                    'dishSizeItem.bag.clientDiet.client.firstName': query.user,
                    'bag.clientDiet.client.lastName': query.user,
                    'dishItem.bag.clientDiet.client.lastName': query.user,
                    'dishSizeItem.bag.clientDiet.client.lastName': query.user,
                  },
                ]);
              } else if (splicedName.length === 2) {
                query._orX.push(
                  [
                    {
                      'bag.clientDiet.client.firstName': splicedName[0],
                      'dishItem.bag.clientDiet.client.firstName':
                        splicedName[0],
                      'dishSizeItem.bag.clientDiet.client.firstName':
                        splicedName[0],
                    },
                  ],
                  [
                    {
                      'bag.clientDiet.client.lastName': splicedName[1],
                      'dishItem.bag.clientDiet.client.lastName': splicedName[1],
                      'dishSizeItem.bag.clientDiet.client.lastName':
                        splicedName[1],
                    },
                  ]
                );
              } else {
                query._orX.push([
                  {
                    'bag.clientDiet.client.firstName': splicedName,
                    'dishItem.bag.clientDiet.client.firstName': splicedName,
                    'dishSizeItem.bag.clientDiet.client.firstName': splicedName,
                    'bag.clientDiet.client.lastName': splicedName,
                    'dishItem.bag.clientDiet.client.lastName': splicedName,
                    'dishSizeItem.bag.clientDiet.client.lastName': splicedName,
                  },
                ]);
              }

              delete query.user;
            }

            if (query.diet) {
              query._orX.push([
                {
                  'bag.diet.name': query.diet,
                  'dishItem.bag.diet.name': query.diet,
                },
              ]);

              delete query.diet;
            }

            if (query.date) {
              query._orX.push([
                {
                  'bag.date': query.date,
                  'dishItem.bag.date': query.date,
                  'dishSizeItem.bag.date': query.date,
                },
              ]);

              delete query.date;
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}
const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

import React from 'react';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';

const FormIngredientFattyAcids = ({
  t,
  columnConfigFattyAcids,
  handleNutrients,
  state,
  classes,
}) => {
  return (
    <GridItem md={12}>
      <CardWrapper title={t('form.field.ingredient.fattyAcids.title')}>
        <CardBody style={{ paddingLeft: '100px', paddingRight: '100px' }}>
          <GridContainer md={12} justify="center">
            {columnConfigFattyAcids.map((fattyAcids, index) => {
              return (
                <GridItem md={3} key={index}>
                  <FormLabel className={classes.labelHorizontal}>
                    {`${t(fattyAcids.title)} (${fattyAcids?.unit})`}
                  </FormLabel>
                  <CustomInput
                    id={fattyAcids.title}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: fattyAcids.type,
                      name: fattyAcids.name,
                      onChange: event => handleNutrients(event, 'fattyAcids'),
                      value: state?.fattyAcids?.[fattyAcids.name],
                    }}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </GridItem>
  );
};

export default FormIngredientFattyAcids;

import React from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.jsx';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
import { get } from 'helpers/apiHelpers';
import moment from 'moment';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { withToast } from 'material-ui-toast-redux';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TOAST_DURATIONS from 'helpers/toastDurations';

class MultiplierCalculator extends React.Component {
  state = {
    dateFirst: '',
    dateSecond: '',
    bagsFirst: 0,
    bagsSecond: 0,
  };

  validate = row => {
    return row.dateFirst && row.dateSecond;
  };

  handleChangeDate = (event, field) => {
    this.setState(prevState => ({
      ...prevState,
      [field]: event.format('YYYY-MM-DD'),
    }));
  };

  handleCalculate = async () => {
    if (!this.validate(this.state)) {
      this.props.openToast({
        messages: [this.props.t('reports.multiplierDate')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });

      return;
    }

    let brandIds =
      this.props.brandIds.length > 0
        ? this.props.brandIds
            .map(item => parseInt(item.value.replace('/brands/', '')))
            .filter(item => !isNaN(item))
        : [0];

    let [bagsFirst, bagsSecond] = await Promise.all([
      get(this.props.url, {
        brandIds: brandIds,
        date: this.state.dateFirst,
      }),
      get(this.props.url, {
        brandIds: brandIds,
        date: this.state.dateSecond,
      }),
    ]);

    this.setState({
      bagsFirst: bagsFirst?.value ?? 0,
      bagsSecond: bagsSecond?.value ?? 0,
    });

    if (this.state.bagsFirst > 0 && this.state.bagsSecond > 0) {
      this.props.handleChange(
        'multiplier',
        (this.state.bagsFirst / this.state.bagsSecond).toFixed(2)
      );

      return;
    }

    this.props.openToast({
      messages: [this.props.t('reports.multiplierDateError')],
      type: 'error',
      autoHideDuration: TOAST_DURATIONS.SM,
    });
  };

  handleClickAway = event => {
    if (this.props.anchorElement.contains(event.target)) {
      return;
    }

    this.props.handleChange('multiplierCalculatorOpen', false);
  };

  render() {
    const { classes } = this.props;

    return (
      <Popper
        open={this.props.open}
        anchorEl={this.props.anchorElement}
        disablePortal
        placement={'bottom'}
        className={classes.customPicker}
      >
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <GridContainer justify="flex-start" style={{ position: 'relative' }}>
            <GridItem sm={4}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {this.props.t('reports.dateFirst')}
                <br />
                {this.props.t('reports.dateBags', {
                  bags: this.state.bagsFirst,
                })}
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={new moment(this.state.dateFirst)}
                  onChange={ev => this.handleChangeDate(ev, 'dateFirst')}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem sm={4}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {this.props.t('reports.dateSecond')}
                <br />
                {this.props.t('reports.dateBags', {
                  bags: this.state.bagsSecond,
                })}
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={new moment(this.state.dateSecond)}
                  onChange={ev => this.handleChangeDate(ev, 'dateSecond')}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem sm={4}>
              <Button
                color={'primary'}
                style={{ padding: '5px', marginTop: '50%' }}
                onClick={() => this.handleCalculate()}
              >
                {this.props.t('reports.count')}
              </Button>
            </GridItem>
          </GridContainer>
        </ClickAwayListener>
      </Popper>
    );
  }
}

MultiplierCalculator.defaultProps = {};

MultiplierCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  anchorElement: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  brandIds: PropTypes.array,
};

export default withToast(withTranslation()(MultiplierCalculator));

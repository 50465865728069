import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from 'actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Datetime from 'react-datetime';
import SelectInput from 'components/FormSelect/SelectInput';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import produce from 'immer';
import { cloneDeep, zipWith } from 'lodash';
import {
  dayObject,
  timeFields,
  days as daysOfWeek,
  modulesNames,
} from '../consts';
import { put } from 'helpers/apiHelpers';
import makeStyles from '@material-ui/styles/makeStyles';
import DeliveryAndOrdersView from './DeliveryAndOrders.view';
import TOAST_DURATIONS from 'helpers/toastDurations';

const useStyles = makeStyles(() => ({
  inputDateTime: {
    zIndex: 1,
  },
}));

const translateDays = t => ({
  monday: t('common.monday', 'Poniedziałek'),
  tuesday: t('common.tuesday', 'Wtorek'),
  wednesday: t('common.wednesday', 'Środa'),
  thursday: t('common.thursday', 'Czwartek'),
  friday: t('common.friday', 'Piątek'),
  saturday: t('common.saturday', 'Sobota'),
  sunday: t('common.sunday', 'Niedziela'),
});

const translateFields = t => ({
  newOrder: t('brands.newBrandForm.translateFields.newOrder'),
  newOrderSubscription: t(
    'brands.newBrandForm.translateFields.newOrderSubscription'
  ),
  changeDate: t('brands.newBrandForm.translateFields.changeDate'),
  changeAddress: t('brands.newBrandForm.translateFields.changeAddress'),
  changeDiet: t('brands.newBrandForm.translateFields.changeDiet'),
  buyAddons: t('brands.newBrandForm.translateFields.buyAddons'),
  shouldBeDeliveredIn: t(
    'brands.newBrandForm.translateFields.shouldBeDeliveredIn'
  ),
  shouldBeDeliveredInBlock: t(
    'brands.newBrandForm.translateFields.shouldBeDeliveredInBlock'
  ),
  paymentDeadline: t('brands.newBrandForm.translateFields.paymentDeadline'),
  rate: t('brands.newBrandForm.translateFields.rate', 'Ocena menu'),
});

const DeliveryAdnOrdersContainer = ({
  classes,
  t,
  openToast,
  selectedBrand,
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const myStyle = useStyles();
  const [state, setState] = useState({
    days: [],
    adminDays: [],
    daysDefault: null,
    daysMax: null,
    daysMin: null,
    daysSelectorType: null,
    deliveryDaysOptions: [
      { label: 'Niedostępny', name: 'inaccessible', value: 0 },
      { label: 'Poniedziałek', name: 'monday', value: 1 },
      { label: 'Wtorek', name: 'tuesday', value: 2 },
      { label: 'Środa', name: 'wednesday', value: 3 },
      { label: 'Czwartek', name: 'thursday', value: 4 },
      { label: 'Piątek', name: 'friday', value: 5 },
      { label: 'Sobota', name: 'saturday', value: 6 },
      { label: 'Niedziela', name: 'sunday', value: 7 },
    ],
    disabledDays: [],
    calendarText: '',
    tableHeaderDates: {
      monday: '2021-09-20',
      tuesday: '2021-09-21',
      wednesday: '2021-09-22',
      thursday: '2021-09-23',
      friday: '2021-09-24',
      saturday: '2021-09-25',
      sunday: '2021-09-26',
    },
    chosenDateAndTime: {
      monday: {
        newOrder: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
      },
      tuesday: {
        newOrder: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
      },
      wednesday: {
        newOrder: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
      },
      thursday: {
        newOrder: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
      },
      friday: {
        newOrder: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
      },
      saturday: {
        newOrder: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
      },
      sunday: {
        newOrder: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
      },
    },
    ratingDateAndTimeNewView: {
      day: 0,
      time: '00:00',
    },
    testDays: [],
    testDaysDefault: null,
    testDaysEnabled: false,
    predefinedDietLengthEnabled: false,
    customDietLengthEnabled: false,
    testDaysMax: null,
    testDaysMin: null,
    testDaysSelectorType: null,
  });
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );
      const newState = {
        adminDays: brandConfig?.adminDays.sort(sortDays) || [],
        calendarText: brandConfig.calendarText || '',
        days: brandConfig.days.sort(sortDays),
        daysDefault: brandConfig.daysDefault,
        daysMax: brandConfig.daysMax,
        daysMin: brandConfig.daysMin,
        daysSelectorType: brandConfig.daysSelectorType,
        deliveryDaysOptions: translateDeliveryDaysOptions(),
        disabledDays: brandConfig.disabledDays.map(d => d.substr(0, 10)),
        testDays: brandConfig.testDays,
        testDaysDefault: brandConfig.testDaysDefault,
        testDaysEnabled: brandConfig.testDaysEnabled,
        testDaysMax: brandConfig.testDaysMax,
        testDaysMin: brandConfig.testDaysMin,
        testDaysSelectorType: brandConfig.testDaysSelectorType,
        predefinedDietLengthEnabled: brandConfig.predefinedDietLengthEnabled,
        customDietLengthEnabled: brandConfig.customDietLengthEnabled,
        maximumDaysAllowedToOrderInFuture:
          brandConfig.maximumDaysAllowedToOrderInFuture,
      };

      setState(prevState => ({ ...prevState, ...newState }));
      convertMinutesToDate(brandConfig);
      setModules(modulesConfig.configuration);

      setIsLoading(false);
    })();
  }, []);

  const convertMinutesToDate = brandConfig => {
    let obj = { ...state.chosenDateAndTime };
    let addedRatingData = false;
    let ratingDay = null;
    let ratingTime = null;

    daysOfWeek.forEach(dayOfWeek => {
      setState(prevState => ({
        ...prevState,
        [dayOfWeek]: dayObject(brandConfig[dayOfWeek]),
      }));

      timeFields.forEach(field => {
        const minutes = brandConfig[dayOfWeek][field];
        if (field === 'rate' && !addedRatingData) {
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          let remainingHours = hours % 24;
          let remainingMinutes = minutes % 60;
          remainingHours =
            remainingHours < 10 ? `0${remainingHours}` : `${remainingHours}`;
          remainingMinutes =
            remainingMinutes < 10
              ? `0${remainingMinutes}`
              : `${remainingMinutes}`;
          ratingDay = days;
          ratingTime = `${remainingHours}:${remainingMinutes}`;
        } else if (field !== 'rate') {
          const data = moment(state.tableHeaderDates[dayOfWeek]).subtract(
            minutes,
            'minutes'
          );
          const date = data.format('YYYY-MM-DD');
          const time = data.format('HH:mm');
          const day = data.locale('en').format('dddd');
          obj[dayOfWeek][field].day = { label: day, value: date };
          obj[dayOfWeek][field].time = time;
        }
      });
    });

    setState(prevState => ({
      ...prevState,
      chosenDateAndTime: obj,
      ratingDateAndTimeNewView: { day: ratingDay, time: ratingTime },
    }));
  };

  const convertDateToMinutes = () => {
    return new Promise(resolve => {
      const daysWithConvertedMinutes = {};

      for (const [dayName, value] of Object.entries(state.tableHeaderDates)) {
        const obj = {};
        daysWithConvertedMinutes[dayName] = { ...state[dayName], ...obj };
        for (const [dayField, dayFieldData] of Object.entries(
          state.chosenDateAndTime[dayName]
        )) {
          const dayFieldMinutes = moment(value).diff(
            moment(`${dayFieldData.day.value} ${dayFieldData.time}`),
            'minutes'
          );
          obj[dayField] = dayFieldMinutes;
        }

        const [hours, minutes] = state.ratingDateAndTimeNewView.time.split(':');
        const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
        const totalDays = state.ratingDateAndTimeNewView.day * 1440;
        obj.rate = totalMinutes + totalDays;
        setState(prevState => ({
          ...prevState,
          [dayName]: {
            ...prevState[dayName],
            ...obj,
          },
        }));
        daysWithConvertedMinutes[dayName] = { ...state[dayName], ...obj };
      }

      resolve(daysWithConvertedMinutes);
    });
  };

  const sortDays = (a, b) => a - b;

  const translateDeliveryDaysOptions = () => {
    const weekdays = cloneDeep(moment.localeData().weekdays());
    const sunday = weekdays.shift();
    weekdays.push(sunday);
    weekdays.unshift(t('common.unavailable', 'Niedostępny'));

    const deliveryDaysOptions = [
      {
        label: t('common.unavailable', 'Niedostępny'),
        name: 'inaccessible',
        value: 0,
      },
      { label: 'Poniedziałek', name: 'monday', value: 1 },
      { label: 'Wtorek', name: 'tuesday', value: 2 },
      { label: 'Środa', name: 'wednesday', value: 3 },
      { label: 'Czwartek', name: 'thursday', value: 4 },
      { label: 'Piątek', name: 'friday', value: 5 },
      { label: 'Sobota', name: 'saturday', value: 6 },
      { label: 'Niedziela', name: 'sunday', value: 7 },
    ];

    const zipped = zipWith(deliveryDaysOptions, weekdays, (d, w) => {
      d.label = w;
      return d;
    });

    return zipped;
  };

  const handleRatingTimeChange = time => {
    setState(prevState => ({
      ...prevState,
      ratingDateAndTimeNewView: {
        ...prevState.ratingDateAndTimeNewView,
        time: time.format('HH:mm'),
      },
    }));
  };

  const handleDeliveryDaySelectChange = (
    event,
    selectedValue,
    dayOfWeek,
    field
  ) => {
    let filteredDisabledDays = [];
    if (selectedValue.name === 'inaccessible') {
      filteredDisabledDays = state.disabledDays.filter(
        date =>
          new moment(date).locale('en').format('dddd').toLowerCase() !==
          event.target.name
      );

      setState(prevState => ({
        ...prevState,
        disabledDays: filteredDisabledDays,
      }));
    }

    setState(prevState => ({
      ...prevState,
      [dayOfWeek]: {
        ...prevState[dayOfWeek],
        [field]: selectedValue.value,
      },
    }));
  };

  const getWeekdayOptions = date => {
    const options = [];

    const dayMap = {
      0: t('common.sunday'),
      1: t('common.monday'),
      2: t('common.tuesday'),
      3: t('common.wednesday'),
      4: t('common.thursday'),
      5: t('common.friday'),
      6: t('common.saturday'),
    };

    for (let i = 7; i > 0; i--) {
      let dateObject = moment(date).subtract(i, 'days');
      let dayName = dayMap[dateObject.day()];
      let obj = { label: dayName, value: dateObject.format('YYYY-MM-DD') };
      options.push(obj);
    }

    return options;
  };

  const handleDaySelect = (selectedDay, dayOfWeek, field) => {
    const newState = produce(state, draftState => {
      let dayWeek = draftState.chosenDateAndTime[dayOfWeek];
      dayWeek[field].day = selectedDay;
    });

    setState(newState);
  };

  const handleTimeSelect = (selectedTime, dayOfWeek, field) => {
    const newState = produce(state, draftState => {
      let dayWeek = draftState.chosenDateAndTime[dayOfWeek];
      dayWeek[field].time = selectedTime.format('HH:mm');
    });

    setState(newState);
  };

  const returnTableCell = (classes, dayOfWeek, field) => {
    if (
      field === 'shouldBeDeliveredIn' ||
      field === 'shouldBeDeliveredInBlock'
    ) {
      return (
        <>
          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name={dayOfWeek}
            value={state[dayOfWeek][field]}
            options={state.deliveryDaysOptions}
            handleChange={(ev, obj) =>
              handleDeliveryDaySelectChange(ev, obj, dayOfWeek, field)
            }
            size={12}
          />
        </>
      );
    }

    return (
      <>
        <SelectInput
          noGrid
          classes={classes}
          mapBy="label"
          trackBy="value"
          name={dayOfWeek}
          value={state.chosenDateAndTime[dayOfWeek][field].day}
          options={getWeekdayOptions(state.tableHeaderDates[dayOfWeek])}
          handleChange={(ev, obj) => handleDaySelect(obj, dayOfWeek, field)}
          size={12}
        />

        <Datetime
          dateFormat={false}
          value={state.chosenDateAndTime[dayOfWeek][field].time}
          onChange={e => handleTimeSelect(e, dayOfWeek, field)}
          inputProps={{
            placeholder: t('zones.select'),
            readOnly: true,
          }}
          locale="pl"
        />
      </>
    );
  };

  const handleRatingDayChange = (e, day) => {
    setState(prevState => ({
      ...prevState,
      ratingDateAndTimeNewView: {
        ...prevState.ratingDateAndTimeNewView,
        day: day.value,
      },
    }));
  };

  const descriptionDays = () => {
    let nameOfDay = '';
    if (state.ratingDateAndTimeNewView.day === 0)
      nameOfDay = t(
        'brands.newBrandForm.ratingSelect.sameDay',
        'tego samego dnia'
      );
    if (state.ratingDateAndTimeNewView.day === 1)
      nameOfDay =
        `${state.ratingDateAndTimeNewView.day} ` +
        t('brands.newBrandForm.ratingSelect.dayLater', 'dzień później');
    if (state.ratingDateAndTimeNewView.day > 1)
      nameOfDay =
        `${state.ratingDateAndTimeNewView.day} ` +
        t('brands.newBrandForm.ratingSelect.daysLater', 'dni później');
    return nameOfDay;
  };

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleDays = (days, changed, daysType) => {
    const hasDuplicates = () => {
      const lengthCount = days.filter(length => {
        return changed.includes(length);
      }).length;
      return lengthCount > 1;
    };

    if (hasDuplicates()) {
      return openToast({
        messages: [
          t('errors.valueAlreadyAdded', 'Ta wartość została już dodana'),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
    if (days.some(val => isNaN(val))) {
      return openToast({
        messages: [t('errors.valueMustBeNumber', 'Wartość musi być liczbą')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
    setState(prevState => ({ ...prevState, [daysType]: days }));
  };

  const handleInputChange = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const validateForm = () => {
    if (!state.daysDefault || !state.daysMax || !state.daysMin) {
      openToast({
        messages: [
          t(
            'errors.minMaxDefaultValuesNeeded',
            '$$Ustaw minimalną, maksymalną oraz domyślną wartość możliwych dni zamówień dla klienta'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return false;
    }

    const maximumDaysAllowedToOrderInFuture =
      modules?.[modulesNames.ConfigClientPanel]
        ?.maximumDaysAllowedToOrderInFuture;

    if (
      maximumDaysAllowedToOrderInFuture > 0 &&
      (maximumDaysAllowedToOrderInFuture < state.daysMax ||
        (state?.days ?? []).find(
          daysOption => daysOption > maximumDaysAllowedToOrderInFuture
        ))
    ) {
      openToast({
        messages: [
          t(
            'errors.maxiumNumbersOfDaysAhead.tooLow',
            '$$Maksymalna liczba dni do zamówienia w przód nie może być mniejsza niż maksymalna długość zamówienia ani zdefinowane długości'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) return;

    try {
      const days = await convertDateToMinutes();
      const data = {
        calendarText: state.calendarText,
        disabledDays: state.disabledDays.map(el => el + ' 00:00:00'),
        days: state.days.map(length => parseInt(length)),
        daysDefault: parseInt(state.daysDefault),
        daysMax: parseInt(state.daysMax),
        daysMin: parseInt(state.daysMin),
        daysSelectorType: state.daysSelectorType,
        adminDays: state.adminDays,
        testDays: state.testDays,
        testDaysDefault: parseInt(state.testDaysDefault),
        testDaysEnabled: state.testDaysEnabled,
        testDaysMax: parseInt(state.testDaysMax),
        testDaysMin: parseInt(state.testDaysMin),
        testDaysSelectorType: state.testDaysSelectorType,
        predefinedDietLengthEnabled: state.predefinedDietLengthEnabled,
        customDietLengthEnabled: state.customDietLengthEnabled,
        ...days,
      };
      await put(`/brands/${selectedBrand}`, data);
      await updateBrandConfigModulesPack(selectedBrand, modules);

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } catch (error) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  const props = {
    classes: classes,
    t: t,
    translateDays: translateDays,
    translateFields: translateFields,
    myStyle: myStyle,
    state: state,
    setState: setState,
    modules: modules,
    setModules: setModules,
    isLoading: isLoading,
    handleRatingTimeChange: handleRatingTimeChange,
    returnTableCell: returnTableCell,
    handleRatingDayChange: handleRatingDayChange,
    descriptionDays: descriptionDays,
    handleModuleUpdate: handleModuleUpdate,
    handleDays: handleDays,
    handleInputChange: handleInputChange,
    handleSubmit: handleSubmit,
  };

  return <DeliveryAndOrdersView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand, fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(DeliveryAdnOrdersContainer);

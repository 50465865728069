import React from 'react';
import { connect } from 'react-redux';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SummaryDay from './SummaryDay';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class SummaryContent extends React.Component {
  render() {
    let { mealTypes, filteredDiets, classes, t } = this.props;

    if (this.props.variant === '') {
      return null;
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  padding="dense"
                  style={{ borderBottom: 0 }}
                  colSpan={1}
                />
                {filteredDiets.map((diet, index) => (
                  <TableCell
                    colSpan={1}
                    key={index}
                    align="center"
                    padding="dense"
                  >
                    {diet.name}
                    {!diet.active && (
                      <>
                        <br />{' '}
                        <span style={{ color: '#bebebe' }}>
                          {t('menuPlanner.dietInactive', '(Dieta nieaktywna)')}
                        </span>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              {this.props.dates.map(day => (
                <SummaryDay
                  key={day}
                  day={day}
                  diets={filteredDiets}
                  variant={this.props.variant}
                  mealTypes={mealTypes}
                />
              ))}
            </TableHead>
          </Table>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    mealTypes: state.MealTypes.mealTypes,
  };
};

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(buttonsStyle),
  withTranslation()
);

export default enhance(SummaryContent);

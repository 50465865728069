import React from 'react';

import SelectInput from 'components/FormSelect/SelectInput';

/** @deprecated use SelectInput **/
const FormSelectSingle = props => {
  return <SelectInput {...props} />;
};

export default FormSelectSingle;

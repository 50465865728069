import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_DRIVER } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  newDriver = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('drivers.list')}>
        {isGranted(ROLE_CREATE_DRIVER) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newDriver}
            submitText={this.props.t('drivers.addDriver') + ' +'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/drivers"
          reportName={'driver'}
          columns={columnConfig}
          role="DRIVER"
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

export const adminMailingOrder = [
  'assign_zone_to_addresses_started',
  'assign_zone_to_addresses_finished',
  'import_diet_prices_started',
  'import_diet_prices_finished',
  'diet_reminder',
  'system_created_new_dish_sizes',
  'employee_welcome',
  'lost_password_admin',
];

export const clientMailingOrder = [
  'new_account',
  'remove_account',
  'lost_password',
  'client_marketing_terms',
  'new_order',
  'new_order_v2',
  'order_verified',
  'waiting_order_status_change_paid',
  'waiting_order_status_change_canceled',
  'paid_order_status_change_canceled',
  'subscription_renewed_order_successful',
  'subscription_renewed_order_failed',
  'client_notification',
  'change_with_refund',
  'change_with_surcharge',
  'change_bag_dishes',
  'bag_delivered',
  'cancel_bags',
  'menu_published',
  'menu_assistant_failed_suggestion',
  'mass_mail',
];

export const clientToggleable = ['change_bag_dishes', 'bag_delivered'];

export const mailingOptions = t => ({
  diet_reminder: t(
    'mailNotification.mailingOptions.dietReminder',
    'Informacja o diecie'
  ),
  new_account: t(
    'mailNotification.mailingOptions.newAccount',
    'Założenie konta'
  ),
  new_order: t(
    'mailNotification.mailingOptions.newOrder',
    'Złożenie zamówienia na nową dietę przez klienta'
  ),
  new_order_v2:
    t(
      'mailNotification.mailingOptions.newOrder',
      'Złożenie zamówienia na nową dietę przez klienta'
    ) + ' - v2',
  change_with_surcharge: t(
    'mailNotification.mailingOptions.changeWithSurcharge',
    'Złożenie zamówienia na "dodatek" do diety z dopłatą'
  ),
  change_with_refund: t(
    'mailNotification.mailingOptions.changeWithRefund',
    'Złożenie zamówienia na "dodatek" do diety ze zwrotem punktów dla klienta'
  ),
  order_status_change: t(
    'mailNotification.mailingOptions.orderStatusChange',
    'Zmiana statusu zamówienia'
  ),
  waiting_order_status_change_paid: t(
    'mailNotification.mailingOptions.waitingOrderStatusChangePaid',
    'Zmiana statusu zamówienia z oczekującego na opłacony'
  ),
  waiting_order_status_change_canceled: t(
    'mailNotification.mailingOptions.waitingOrderStatusChangeCanceled',
    'Zmiana statusu zamówienia z oczekującego na anulowany'
  ),
  paid_order_status_change_canceled: t(
    'mailNotification.mailingOptions.paidOrderStatusChangeCanceled',
    'Zmiana statusu zamówienia z opłaconego na anulowany'
  ),
  remove_account: t(
    'mailNotification.mailingOptions.removeAccount',
    'Usunięcie konta'
  ),
  lost_password_admin: t(
    'mailNotification.mailingOptions.lostPasswordAdmin',
    'Zapomniane hasło (administracja)'
  ),
  lost_password: t(
    'mailNotification.mailingOptions.lostPassword',
    'Zapomniane hasło (klient)'
  ),
  assign_zone_to_addresses_started: t(
    'mailNotification.mailingOptions.assignZoneToAddressStarted',
    'Rozpoczęcie przypisywania stref'
  ),
  assign_zone_to_addresses_finished: t(
    'mailNotification.mailingOptions.assignZoneToAddressFinished',
    'Zakończenie przypisywania stref'
  ),
  subscription_renewed_order_successful: t(
    'mailNotification.mailingOptions.subscriptionRenewedOrderSuccessfull',
    'Odnowienie subskrypcji'
  ),
  subscription_renewed_order_failed: t(
    'mailNotification.mailingOptions.subscriptionRenewedOrderFailed',
    'Nie udało się odnowić subskrypcji'
  ),
  change_delivery: t(
    'mailNotification.mailingOptions.changeDelivery',
    'Edycja diety'
  ),
  change_bag_dishes: t(
    'mailNotification.mailingOptions.changeDish',
    'Edycja posiłku'
  ),
  order_verified: t(
    'mailNotification.mailingOptions.orderVerified',
    'Weryfikacja zamówienia'
  ),
  client_notification: t(
    'mailNotification.mailingOptions.clientNotification',
    'Powiadomienia klienta (skasowanie karty)'
  ),
  cancel_bags: t(
    'mailNotification.mailingOptions.cancelBags',
    'Anulowanie dnia diety'
  ),
  menu_published: t(
    'mailNotification.mailingOptions.menuPublished',
    'Opublikowanie menu'
  ),
  client_marketing_terms: t(
    'mailNotification.mailingOptions.clientMarketingTerms',
    'Zmiana zgody marketingowej'
  ),
  employee_welcome: t(
    'mailNotification.mailingOptions.employeeWelcome',
    'Dodanie pracownika'
  ),
  bag_delivered: t(
    'mailNotification.mailingOptions.bagDelivered',
    'Dostarczenie torby'
  ),
  import_diet_prices_finished: t(
    'mailNotification.mailingOptions.import_diet_prices_finished',
    'Zakończenie importowania cen diet'
  ),
  import_diet_prices_started: t(
    'mailNotification.mailingOptions.import_diet_prices_started',
    'Rozpoczęcie importowania cen diet'
  ),
  menu_assistant_failed_suggestion: t(
    'mailNotification.mailingOptions.menu_assistant_failed_suggestion',
    'Niepowodzenie w wyborze menu'
  ),
  mass_mail: t(
    'mailNotification.mailingOptions.mass_mail',
    'Wiadomość'
  ),
});

import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import { useTranslation } from 'react-i18next';

const AddonsBreadcrumbAdditionalContent = () => {
  const { t } = useTranslation();

  return (
    <InfoWithTooltip
      title={t('common.mainMenu.addonsInfo')}
      link={EXTERNAL_URLS.addons}
    />
  );
};

export default AddonsBreadcrumbAdditionalContent;

import React from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_PACKAGE_LINE_FILTER,
  ROLE_EDIT_PACKAGE_LINE_FILTER,
  ROLE_REMOVE_PACKAGE_LINE_FILTER,
  ROLE_SHOW_PACKAGE_LINE_FILTER,
} from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  adminTableTitleParent: { display: 'flex', alignItems: 'center' },
  tooltipIcon: {
    color: 'RGB(130,130,130)',
  },
  spanTooltip: { fontSize: '14px', lineHeight: '22px' },
}));

const List = ({ classes: propClasses, t, history }) => {
  const newSize = () => history.push(`${window.location.pathname}/add`);

  const classes = useStyles();

  return (
    <AdminTable
      title={
        <span className={classes.adminTableTitleParent}>
          {t('list.dietsLineFiltersList')}
          <span>
            <Tooltip
              title={
                <span className={classes.spanTooltip}>
                  {t('list.dietsLineFiltersList.tooltip')}
                </span>
              }
            >
              <Info className={classes.tooltipIcon} size={20} />
            </Tooltip>
          </span>
        </span>
      }
    >
      {isGranted(ROLE_CREATE_PACKAGE_LINE_FILTER) && (
        <FormControlButtons
          classes={propClasses}
          handleSubmit={newSize}
          submitText={t('form.addFilter') + ' +'}
        />
      )}
      <DataGrid
        actions={true}
        export={true}
        editPath={`${window.location.pathname}/edit`}
        remove={isGranted(ROLE_REMOVE_PACKAGE_LINE_FILTER)}
        paginationTop={true}
        paginationBottom={false}
        url="package-line-filters"
        reportName={'packagelinefilter'}
        columns={columnConfig(t)}
        role={ROLE_SHOW_PACKAGE_LINE_FILTER}
      />
    </AdminTable>
  );
};

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

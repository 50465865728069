import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BoxSection from '../BoxSection';

import { Divider } from '@material-ui/core';

import PriceWrapper from 'components/PriceWrapper/PriceWrapper';

import { calendarStyles } from 'views/Orders/styles';

import { currencyToSymbol } from 'utils/currencies';
import { goldColor } from 'assets/jss/material-dashboard-pro-react';

const OrderSummarySection = ({ diets, orderCost } = {}) => {
  const { t } = useTranslation();

  const validationArray = diets.map(
    diet =>
      (diet.address ?? !!diet.pickUpPoint) &&
      diet.dietLength === diet.selectedDays.length &&
      !!diet.selectedDays &&
      !!diet.selectedDiet &&
      !!diet.selectedAddons &&
      !!diet.selectedVariant &&
      !!diet.selectedCalorific
  );
  const areAllDietsValid = validationArray.every(el => el === true);

  const priceLeftToPay = orderCost?.basket?.price?.afterDiscount;
  const priceBeforeDiscount = orderCost?.basket?.price?.beforeDiscount;
  const useMoneyBox = orderCost?.basket?.useMoneybox;
  const useMoneyBoxValue = orderCost?.basket?.price?.discountParts.MONEYBOX;

  const currency = useSelector(
    state => state?.Brands?.brand?.multinational?.currency
  );

  return (
    <BoxSection
      title={t('orders.toPay', '$$Do zapłaty')}
      style={{ margin: '5px' }}
      headerRightCornerComponent={
        <>
          {areAllDietsValid ? (
            <PriceWrapper
              beforeDiscount={priceBeforeDiscount}
              afterDiscount={priceLeftToPay}
              style={{
                wrapper: { fontWeight: '500' },
                afterDiscount: { color: goldColor },
              }}
            />
          ) : (
            <></>
          )}
        </>
      }
    >
      <div>
        {areAllDietsValid ? (
          <>
            {diets.map((diet, index) => {
              return (
                <div>
                  <p
                    style={{
                      ...calendarStyles.spaceBetween,
                      marginTop: '15px',
                    }}
                  >
                    <span>
                      {diet?.customDietName ??
                        `${diet?.selectedDiet?.label}, ${diet?.selectedVariant?.label}, ${diet?.selectedCalorific?.label}`}
                    </span>
                    <span>
                      <PriceWrapper
                        beforeDiscount={
                          orderCost?.basket?.rows?.[index]?.price
                            ?.beforeDiscount
                        }
                        afterDiscount={
                          orderCost?.basket?.rows?.[index]?.price?.afterDiscount
                        }
                      />
                    </span>
                  </p>
                </div>
              );
            })}
            {useMoneyBox && useMoneyBoxValue > 0 && (
              <>
                <Divider />
                <div>
                  <p
                    style={{
                      ...calendarStyles.spaceBetween,
                      marginTop: '15px',
                    }}
                  >
                    <span>
                      {t(
                        'orders.moneyBoxValue',
                        '$$Użyte punkty ze skarbonki podane w '
                      )}
                      {currencyToSymbol(currency)}
                    </span>
                    <span>{`${useMoneyBoxValue} ${currencyToSymbol(
                      currency
                    )}`}</span>
                  </p>
                </div>
              </>
            )}
          </>
        ) : (
          <div>
            <p style={{ marginTop: '15px' }}>
              {t(
                'orders.order.summaryShow',
                '$$Aby zobaczyć podsumowanie, wypełnij wszystkie wymagane pola w dietach które chcesz zamówić, jeśli nie chcesz zamawiać którejś z diet, usuń ją z listy'
              )}
            </p>
          </div>
        )}
      </div>
    </BoxSection>
  );
};

export default OrderSummarySection;

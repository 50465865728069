import React from 'react';

import ListComponents from 'views/Orders/Orders/ListComponents';
import StatusPicker from 'views/Orders/Orders/StatusPicker';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import { withTranslation, useTranslation } from 'react-i18next';
import {
  paymentTypeOptions,
  paymentTypesTranslations,
} from '../../../Orders/Orders/columnConfig';

const translateSelectOptions = t => [
  t('paymentType.all', 'Wszystkie'),
  t('common.paymentStatus.waiting', 'Oczekujące'),
  t('common.paymentStatus.payed', 'Opłacone'),
  t('common.paymentStatus.cancelled', 'Anulowane'),
];

class RawValueFilter extends React.Component {
  state = {
    valueFrom: this.props.value?.valueFrom || null,
    valueTo: this.props.value?.valueTo || null,
  };

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <input
          type="number"
          placeholder={this.props.t('clients.from', 'od')}
          value={this.state.valueFilter}
          onChange={async ev => {
            await this.setState({
              valueFrom: ev.target.value,
            });
            this.props.onFilterChange(this.state);
          }}
        />
        <input
          type="number"
          placeholder={this.props.t('clients.to', 'do')}
          value={this.state.valueFilter}
          onChange={async ev => {
            await this.setState({
              valueTo: ev.target.value,
            });
            this.props.onFilterChange(this.state);
          }}
        />
      </div>
    );
  }
}

const ValueFilter = withTranslation()(RawValueFilter);

const columnConfig = (grid, fillModal) => {
  return [
    {
      title: 'ID',
      accessor: 'id',
      width: 100,
    },
    {
      title: 'clients.orderDate',
      Filter: ({ onChange, filter }) => {
        return <DateRangeFilter onChange={onChange} value={filter?.value} />;
      },
      accessor: row => dateTimeCell(row.createdAt),
      name: 'createdAt',
      width: 300,
    },
    {
      title: 'orders.items',
      accessor: row => {
        if (row.items === undefined) return;

        const v2types = [
          'OrderItemDayDish',
          'OrderItemDayAddon',
          'OrderItemDayDelivery',
          'OrderItemDayModification',
          'OrderItemDayDiet',
          'OrderItemDayMealType',
        ];

        const orderItemDiets = row.items.filter(
          el => el.type === 'OrderItemDiet'
        );
        const v2Items = row.items.filter(el => v2types.includes(el.type));

        if (orderItemDiets.length > 0 && v2Items?.length > 0) {
          const Diet = ListComponents['OrderItemDiet'];
          const V2Items = ListComponents['v2Types'];

          return (
            <>
              {orderItemDiets
                .map(el => (
                  <Diet orderId={row.id} item={el} fillModal={fillModal} />
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
              {', '}
              <V2Items items={v2Items} fillModal={fillModal} />
            </>
          );
        }

        if (v2Items?.length > 0) {
          const Component = ListComponents['v2Types'];

          return <Component items={v2Items} fillModal={fillModal} />;
        }

        if (orderItemDiets.length > 0) {
          const Component = ListComponents['OrderItemDiet'];
          return orderItemDiets
            .map(el => (
              <Component orderId={row.id} item={el} fillModal={fillModal} />
            ))
            .reduce((prev, curr) => [prev, ', ', curr]);
        }

        const diets = row.items.filter(el => el.type === 'DietItem');
        if (diets.length > 0) {
          const Component = ListComponents['DietItem'];

          return diets
            .map(el => (
              <Component orderId={row.id} item={el} fillModal={fillModal} />
            ))
            .reduce((prev, curr) => [prev, ', ', curr]);
        }

        if (row.items.length === 0) {
          return null;
        }

        const Component = ListComponents[row.items[0].type] || null;

        if (null === Component) {
          return `Unknown type "${row.items[0].type}"`;
        }

        return (
          <Component
            orderId={row.id}
            fillModal={fillModal}
            items={row.items}
            item={row.items[0]}
            userId={row.client.id}
          />
        );
      },
      name: 'dietName',
      width: 160,
      sortable: false,
    },
    {
      title: 'clients.orderValue',
      accessor: row => row.priceAfterDiscountWithoutMoneyBox.toFixed(2),
      name: 'priceAfterDiscountWithoutMoneyBox',
      sortable: false,
      width: 120,
      Filter: ({ onChange, filter }) => {
        return <ValueFilter onFilterChange={onChange} value={filter?.value} />;
      },
    },
    {
      title: 'clients.status',
      accessor: row => {
        return (
          <StatusPicker
            value={row.status}
            disabled={row.status.systemValue === 'CANCELED'}
            orderId={row['@id']}
            grid={grid}
          />
        );
      },
      sortable: false,
      name: 'status.value',
      wrapFilter: true,
      Filter: ({ onChange, filter }, accessorPlug) => {
        const selectOptions = translateSelectOptions(accessorPlug.t);
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{selectOptions[0]}</option>
            <option value={selectOptions[1]}>{selectOptions[1]}</option>
            <option value={selectOptions[2]}>{selectOptions[2]}</option>
            <option value={selectOptions[3]}>{selectOptions[3]}</option>
          </select>
        );
      },
    },
    {
      title: 'clients.paymentType',
      accessor: (row, { t }) => {
        return t(paymentTypesTranslations[row.paymentType]);
      },
      sortable: false,
      filterable: true,
      name: 'paymentType',
      wrapFilter: true,
      Filter: ({ onChange, filter }) => {
        const { t } = useTranslation();
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{t(paymentTypeOptions[0])}</option>
            {Object.entries(paymentTypesTranslations).map(([key, value]) => (
              <option value={key}>{t(value)}</option>
            ))}
          </select>
        );
      },
    },
  ];
};

export default columnConfig;

import { ROLE_SHOW_REPORT_PURCHASED_ADDONS } from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';

const AddonsReport = () => {
  const { t } = useTranslation();

  return (
    <BaseBrandReport
      archiveReportNames={['PURCHASED_ADDONS']}
      title={t('reports.reportPurchasedAddons')}
      excelUrl="/reports/purchased-addons"
      excelAvailable={true}
      role={ROLE_SHOW_REPORT_PURCHASED_ADDONS}
      fileName={getReportFileName(t('views.addons.addons.list'))}
      dateType="range"
      useZoneCategories={true}
    />
  );
};

export default AddonsReport;

import styled from 'styled-components';

const TreeNode = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  > div {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0 5px 0 0;
    line-height: 20px;
    cursor: pointer;
  }

  span {
    cursor: pointer;
  }
`;

const TreeNodeWrapper = styled.div`
  margin-left: ${props => (props.indent ? '15px' : 0)};
`;

const S = { TreeNode, TreeNodeWrapper };

export default S;

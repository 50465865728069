import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Check, Clear } from '@material-ui/icons';

import { get } from 'helpers/apiHelpers';
import DataGrid from 'components/DataGrid/DataGrid';
import translateColumnConfig from './historyColumnConfig';
import { isEmpty } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';

const StyledTableCellHeader = withStyles(theme => {
  return {
    head: {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell);

const StyledTableCell = withStyles(theme => {
  return {
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell);

const StyledTableRow = withStyles(theme => {
  return {
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  };
})(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}));

const getCreationDetails = (userDetails, stringDate, t) => {
  const fullName = !isEmpty(userDetails)
    ? `${userDetails.firstName} ${userDetails.lastName}`
    : t('promoCodes.infoTable.unknown', 'Nieznany');
  const formattedDate =
    stringDate !== null
      ? new Moment(stringDate).format('DD-MM-YYYY HH:mm')
      : t('promoCodes.infoTable.noDate', 'Brak daty');

  return `${fullName}
  ${
    isEmpty(userDetails) ? '' : t('promoCodes.infoTable.atDay', 'w dniu \n')
  } ${formattedDate}`;
};

function CustomizedTables({ code, previewId, diets }) {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({});
  const classes = useStyles();

  const filteredDiets = diets.filter(diet => {
    return code.diets && code.diets.includes(diet['@id']);
  });

  useEffect(() => {
    if (code.createdBy) {
      get(code.createdBy).then(setUserDetails);
    }
  }, [code]);

  return (
    <div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>
                {t('promoCodes.infoTable.codeDesc', 'Opis kodu')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.leftToUse', 'Pozostało użyć')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t(
                  'promoCodes.infoTable.customerLimit',
                  'Limit użyć przez klienta'
                )}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t(
                  'discountCodes.usageLimitPerSubscription',
                  'Limit użyć przez klienta'
                )}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t(
                  'discountCodes.minimalOrderValue',
                  'Minimalna wartość zamówienia'
                )}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t(
                  'discountCodes.minimalDietLength',
                  'Minimalna długość diety'
                )}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.createdBy', 'Stworzony przez')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.appliesTo', 'Dotyczy diet')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('common.mainMenu.addons', 'Dodatki')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.sinceWhen', 'Od kiedy')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.untilWhen', 'Do kiedy')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.value', 'Wartość')}
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                {t('promoCodes.infoTable.status', 'Status')}
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                {code.description}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.limit === null
                  ? t('promoCodes.infoTable.noLimit', 'brak limitu')
                  : code.limit}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.limitPerUser === null
                  ? t('promoCodes.infoTable.noLimit', 'brak limitu')
                  : code.limitPerUser}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.limitPerSubscription === null
                  ? t('promoCodes.infoTable.noLimit', 'brak limitu')
                  : code.limitPerSubscription}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.minimalOrderValue === null ? '-' : code.minimalOrderValue}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.minimalDietLength === null ? '-' : code.minimalDietLength}
              </StyledTableCell>
              <StyledTableCell style={{ whiteSpace: 'pre-line' }} align="right">
                {getCreationDetails(userDetails, code.createdAt, t)}
              </StyledTableCell>
              <StyledTableCell align="right">
                {!filteredDiets
                  ? '-'
                  : !filteredDiets.length
                  ? t('promoCodes.infoTable.allDiets', 'Wszystkie diety')
                  : filteredDiets.map(diet => diet.name).join(', ')}{' '}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.applyToAddons ? (
                  <Check style={{ color: '#46ad58' }} />
                ) : (
                  <Clear style={{ color: '#f4928d' }} />
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.validFrom === null
                  ? '-'
                  : new Moment(code.validFrom).format('DD.MM.YYYY')}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.validTo === null
                  ? '-'
                  : new Moment(code.validTo).format('DD.MM.YYYY')}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.discount}
                {code.isPercentage ? '%' : 'zł'} {/* form.field.currency */}
              </StyledTableCell>
              <StyledTableCell align="right">
                {code.active === true
                  ? t('form.field.active', 'Aktywny')
                  : t('form.field.inactive', 'Nieaktywny')}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </Paper>
      <h3 style={{ marginTop: '30px' }}>
        <b>
          {t(
            'promoCodes.infoTable.usageHistory',
            'Historia wykorzystania kodu'
          )}
        </b>
      </h3>
      <Paper className={classes.root}>
        <DataGrid
          paginationTop={true}
          paginationBottom={false}
          export={true}
          minRows={3}
          url={`discount-codes/${previewId}/orders`}
          columns={translateColumnConfig(t)}
          role="DISCOUNT_CODE"
        />
      </Paper>
    </div>
  );
}

export default CustomizedTables;

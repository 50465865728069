import moment from 'moment';

import types from 'helpers/constants';

const initialState = {
  user: { brands: [], roles: [] },
  selectedBrand: null,
  links: [],
  token: '',
  refreshToken: '',
  temporaryAccountLock: false,
  temporaryLockExpiredAt: moment(),
  languages: null,
  selectedLanguage: null,
};

function Auth(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.REFRESH_LOGIN:
      localStorage.setItem('t', payload.token);
      localStorage.setItem('rt', payload.refreshToken);
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        refreshToken: payload.refreshToken,
      };

    case types.UPDATE_MY_ROLES:
      return {
        ...state,
        user: { ...state.user, roles: payload.roles },
      };

    case types.UPDATE_MY_LINKS:
      return {
        ...state,
        links: payload.links,
      };

    case types.LOGIN:
      localStorage.setItem('t', payload.token);
      localStorage.setItem('rt', payload.refreshToken);
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        refreshToken: payload.refreshToken,
      };

    case types.ADD_USER_BRAND:
      return {
        ...state,
        user: {
          ...state.user,
          brands: [...payload.brands],
        },
      };

    case types.LOGOUT:
      localStorage.setItem('t', '');
      localStorage.setItem('rt', '');
      return {
        ...state,
        ...initialState,
        temporaryAccountLock: state.temporaryAccountLock,
        temporaryLockExpiredAt: state.temporaryLockExpiredAt,
      };

    case types.CHANGE_BRAND:
      return {
        ...state,
        selectedBrand: payload.brand,
      };

    case types.SET_TEMPORARY_LOCK:
      return {
        ...state,
        temporaryAccountLock: payload.isLock,
        temporaryLockExpiredAt: payload.expiredAt,
      };

    case 'SET_LANGUAGES':
      return {
        ...state,
        languages: payload.languages,
        selectedLanguage: payload.selectedLanguage,
      };
    case 'SET_SELECTED_LANGUAGE':
      return {
        ...state,
        selectedLanguage: payload,
      };
    default:
      return state;
  }
}

export default Auth;

import React, { Component } from 'react';
import { orderStyles } from '../../styles';

import ModalButton from 'components/CustomButtons/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import Button from '../../../../components/CustomButtons/Button';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';

class BagChangeModal extends Component {
  copyStringToClipboard = str => {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  };

  renderOrderItems = orderItems => {
    const orderItemsTranslations = {
      date: 'Zmiana daty',
      diet: 'Zmiana diety',
      calorific: 'Zmiana kaloryczności',
      variant: 'Zmiana wariantu',
      optionChangeMenu: 'Możliwość zmiany menu',
      address: 'Zmiana adresu',
      pickUpPoint: 'Zmiana punktu odbioru',
    };

    return Object.keys(orderItems[0].payload).map(key => (
      <h1 style={{ textAlign: 'center' }}>
        {orderItemsTranslations[key] || 'Modyfikacja dnia diety'}
      </h1>
    ));
  };

  render() {
    const {
      bagChangeModalStatus,
      setBagChangeModalStatus,
      bagChanges,
      t,
      multinational: { currency },
    } = this.props;

    if (bagChanges) {
      return (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={bagChangeModalStatus}
          onClose={() => setBagChangeModalStatus(false)}
        >
          <DialogContent>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => setBagChangeModalStatus(false)}
            >
              <Close />
            </ModalButton>
            <h1 style={{ textAlign: 'center' }}>
              {t(
                'bagChangeModal.orderCreated',
                'Zostało stworzone nowe zamówienie'
              )}
            </h1>
            {this.renderOrderItems(bagChanges.order.items)}
            <h3 style={{ textAlign: 'center' }}>
              <b>
                {t('bagChangeModal.amountOfSurcharge', {
                  defaultValue: 'Kwota dopłaty: {{amount}} {{currencySymbol}}',
                  replace: {
                    amount: bagChanges.order.priceLeftToPay.toFixed(2),
                    currencySymbol: currencyToSymbol(currency),
                  },
                })}
              </b>
            </h3>
            {['TPAY', 'PAYU', 'STRIPE_LINK', 'BLUE_MEDIA'].includes(
              bagChanges.order.paymentType
            ) && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a
                    href={bagChanges.order.paymentLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button color={'primary'}>
                      {t('bagChangeModal.goToPayment', 'Przejdź do płatności')}
                    </Button>
                  </a>
                  <Button
                    color={'transparent'}
                    onClick={this.copyStringToClipboard(
                      bagChanges.order.paymentLink
                    )}
                  >
                    {t(
                      'bagChangeModal.copyLinkToClipboard',
                      'Skopiuj link do schowka'
                    )}
                  </Button>
                </div>
                <h3 style={{ textAlign: 'center' }}>
                  <Trans i18nKey="bagChangeModal.linkAwailableAlso">
                    Link do zamówienia jest też dostępny w{' '}
                    <NavLink to={'/admin/orders'} target={'_blank'}>
                      liście zamówień
                    </NavLink>{' '}
                    w panelu administratora oraz w historii zamówień w panelu
                    klienta
                  </Trans>
                </h3>
              </div>
            )}
          </DialogContent>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

const enhance = compose(
  connect(({ Brands: { brand: { multinational } } }) => ({ multinational }))
);

export default enhance(BagChangeModal);

import React from 'react';
import { withTranslation } from 'react-i18next';

class YesNoFilter extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <select
        style={{ padding: '0', width: '100%' }}
        onChange={e => {
          this.props.onChange(
            e.target.value === '' ? '' : e.target.value === 'true'
          );
        }}
        value={this.props.value || ''}
      >
        <option value="">
          {this.props.allLabel || t('common.all', 'Wszystkie')}
        </option>
        {/* common.yes */}
        <option value={true}>
          {this.props.reverse
            ? t('common.no', 'Nie')
            : this.props.trueLabel || t('common.yes', 'Tak')}
        </option>
        {/* common.yes */}
        <option value={false}>
          {this.props.reverse
            ? t('common.yes', 'Tak')
            : this.props.falseLabel || t('common.no', 'Nie')}
        </option>
        {/* common.no */}
      </select>
    );
  }
}

export default withTranslation()(YesNoFilter);

import _ from 'lodash';
import moment from 'moment';

export const getMonth = month => moment().month(month);
export const getStartOfMonth = month =>
  getMonth(month).startOf('month').isoWeekday();
export const getEndOfMonth = month =>
  getMonth(month).endOf('month').isoWeekday();

export const getCurrentMonthAndYear = item => item.format('MMMM YYYY');
export const getCurrentMonthDates = month =>
  _.range(0, getMonth(month).daysInMonth()).map((el, index) =>
    getMonth(month).date(index + 1)
  );
export const isTheSameDays = (day1, day2) =>
  day1.format('YYMMDD') === day2.format('YYMMDD');
export const isDateInArray = (day, arr = []) =>
  !!arr.find(d => isTheSameDays(d, day));
export const isSaturday = item => item.isoWeekday() === 6;
export const isSunday = item => item.isoWeekday() === 7;
export const datepickerCapDay = () => moment().add(2, 'month');
export const isDatepickerValidDay = day => day.isBefore(datepickerCapDay());

export const isWeekend = item => isSaturday(item) || isSunday(item);

/*
common.sundayShort
common.mondayShort
common.tuesdayShort
common.wednesdayShort
common.thursdayShort
common.fridayShort
common.satursdayShort
*/

import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './List';
import withRole from 'components/Security/withRole';
import { ROLE_SHOW_REPORT_ARCHIVE } from 'helpers/roles';

class MealTypes extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_REPORT_ARCHIVE, List);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(MealTypes);

import React from 'react';

import Card from 'components/Card/Card';
import Notes from 'components/Notes/Notes';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import ROLES from 'helpers/roles';

const MealNotes = ({ dishId }) => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <Notes
              urlParam={`/dishes/${dishId}`}
              notePermission={ROLES.ROLE_NOTE_DISH}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default MealNotes;

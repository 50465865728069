/*eslint-disable*/
import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/CustomButtons/Button.jsx';

import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';

// @material-ui/icons
// core components

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class InfoModal extends React.Component {
  render() {
    const { classes, innerHTML } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.props.modalState}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.props.handleClose(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth={this.props.fullWidth}
      >
        {this.props.modalTitle && (
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{this.props.modalTitle}</h4>
          </DialogTitle>
        )}
        {this.props.modalContent && (
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <div>{this.props.modalContent}</div>
          </DialogContent>
        )}
        {innerHTML && (
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
          </DialogContent>
        )}
        <DialogActions className={classes.modalFooter}>
          {!this.props.hideDefaultCloseButton && (
            <Button
              onClick={() => this.props.handleClose(false)}
              color="danger"
              simple
            >
              {this.props.closeButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(notificationsStyle)(InfoModal);

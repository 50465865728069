import MIME_TYPES from './mimeTypes';

const MIME_TYPES_DATA = {
  [MIME_TYPES.pdf]: {
    extension: 'pdf',
    translationKey: 'reports.gPDF',
  },
  [MIME_TYPES.xlsx]: {
    extension: 'xlsx',
    translationKey: 'reports.gExcel',
    translationValues: { format: 'XLSX' },
  },
  [MIME_TYPES.csv]: {
    extension: 'csv',
    translationKey: 'reports.gCSV',
  },
};

export default MIME_TYPES_DATA;

import React from 'react';
import { Info } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

const ExcludedIngredientsTooltip = () => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        <div>
          <h4>{t('form.dietExcludedInfo1')}</h4>
          <h4>{t('form.dietExcludedInfo2')}</h4>
          <h4>{t('form.dietExcludedInfo3')}</h4>
          <h4>{t('form.dietExcludedInfo4')}</h4>
        </div>
      }
      placement="right"
    >
      <Info fontSize={'small'} />
    </Tooltip>
  );
};

export default ExcludedIngredientsTooltip;

import BasketItemDiet from './BasketItemDiet';
import BasketItemAddon from './BasketItemAddon';
import BasketItemDish from './BasketItemDish';
import BasketItemDayModification from './BasketItemDayModification';
import BasketItemMealType from './BasketItemMealType';

const BasketRows = {
  BasketItemDiet,
  BasketItemAddon,
  BasketItemDish,
  BasketItemDayModification,
  BasketItemMealType,
};

export default BasketRows;

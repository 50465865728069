export const columnsMinerals = [
  {
    title: 'form.field.ingredient.sodium',
    type: 'number',
    name: 'sodium',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.potassium',
    type: 'number',
    name: 'potassium',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.calcium',
    type: 'number',
    name: 'calcium',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.phosphorus',
    type: 'number',
    name: 'phosphorus',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.magnesium',
    type: 'number',
    name: 'magnesium',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.iron',
    type: 'number',
    name: 'iron',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.zinc',
    type: 'number',
    name: 'zinc',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.copper',
    type: 'number',
    name: 'copper',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.iodine',
    type: 'number',
    name: 'iodine',
    unit: 'µg',
  },
  {
    title: 'form.field.ingredient.manganese',
    type: 'number',
    name: 'manganese',
    unit: 'mg',
  },
];

import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? '#A3D0F9' : 'white')};
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

export { Container };

import chroma from 'chroma-js';

export const nonOperatingEvent = (disabledDay, t) => {
  return {
    title: t('common.dayNotSupported', 'Dzień nieobsługiwany'),
    start: new Date(disabledDay),
    end: new Date(disabledDay),
    color: 'white',
    shadow: 'none',
    height: '70px',
    fontColor: '#f44336',
  };
};

export const noDietEvent = (date, t) => {
  return {
    title: t('dietStatus.noDiet', 'Brak diety'),
    start: new Date(date),
    end: new Date(date),
    color: 'white',
    shadow: 'none',
    height: '70px',
    fontColor: '#265985',
  };
};

export const dietEvent = (el, component) => {
  let fontColor;
  const minContrast = 4.5;
  if (chroma.contrast(el.diet?.color ?? '#087cec', 'white') > minContrast) {
    fontColor = 'white';
  } else {
    fontColor = 'black';
  }
  return {
    state: el.state,
    newState: el.newState,
    type: el['@type'],
    start: new Date(el.date),
    end: new Date(el.date),
    title: component,
    allDay: true,
    color: el.diet?.color ?? '#087cec',
    fontColor: fontColor,
    height: '120px',
    iri: el['@id'],
    id: el.id,
  };
};

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import ModuleNotLoaded from './ModuleNotLoaded';

const TrustedShopsModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return <ModuleNotLoaded moduleName="TrustedShopsModule" />;
  }

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={() =>
              handleModuleUpdate({
                ...module,
                enabled: !module.enabled,
              })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('brands.newBrandForm.useTrustedShops', 'Używaj Trusted Shops')}
      />
      <FormTextInput
        label="TS ID"
        classes={classes}
        name="TS"
        value={module.tsId}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            tsId: e.target.value,
          })
        }
      />
    </Fragment>
  );
};

export default TrustedShopsModule;

import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';

import DataGrid from 'components/DataGrid/DataGrid';
import CardBody from 'components/Card/CardBody.jsx';

import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import columnConfig from './columnConfig';
import CardWrapper from 'components/Card/CardWrapper';

const GeneratedTasks = ({
  role = 'TASKS_GENERATOR',
  classes,
  refresh,
  preview,
  canRemove = false,
  customActions,
  defaultHiddenColumns = [],
  separatingConfigsParam = '',
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <CardWrapper
        title={t('$*common.generatedTasks', '$$Wygenerowane zadania')}
      >
        <CardBody>
          <DataGrid
            refresh={refresh}
            url={`/task-lists?${separatingConfigsParam}`}
            role={role}
            // remove={canRemove}  TEMPORARY COMMENTED OUT, WAITING FOR ITS MOMENT
            previewPath={preview}
            actions={true}
            columns={columnConfig(t)}
            minRows={15}
            reportName={'tasks'}
            paginationTop={true}
            showColumnFilter={true}
            paginationBottom={false}
            customActions={customActions}
            defaultSorted={[{ id: 'generatedAt', desc: true }]}
            defaultHiddenColumns={defaultHiddenColumns}
            manipulateQuery={(requestData, query) => {
              if (query['generatedBy.firstName']) {
                const splicedName = query['generatedBy.firstName'].split(' ');
                let _andX = [
                  [
                    {
                      'generatedBy.firstName': splicedName[0],
                      'generatedBy.lastName': splicedName[0],
                    },
                  ],
                ];

                if (splicedName.length > 1) {
                  _andX[0][0]['generatedBy.lastName'] = splicedName[1];
                }

                const operator = splicedName.length > 1 ? '_andX' : '_orX';

                delete query['generatedBy.firstName'];
                query[operator] = _andX;
              }

              return query;
            }}
          />
        </CardBody>
      </CardWrapper>
    </div>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(GeneratedTasks);

import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from 'views/Ingredients/List/List';
import Form from 'views/Ingredients/Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_INGREDIENT,
  ROLE_EDIT_INGREDIENT,
  ROLE_SHOW_INGREDIENT,
} from 'helpers/roles';

const Ingredients = ({ history, location, match }) => {
  const props = { history, location, match };

  const ListSecured = withRole(ROLE_SHOW_INGREDIENT, List);
  const CreateForm = withRole(ROLE_CREATE_INGREDIENT, Form);
  const EditForm = withRole(ROLE_EDIT_INGREDIENT, Form);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <CreateForm {...props} key="add" />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => (
            <EditForm {...props} {...parentProps} key="edit" />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(Ingredients);

import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';
import { usePanelConfigurationContext } from './PanelConfiguration.context';
import { FormTextInput } from 'components';
import CardWrapper from 'components/Card/CardWrapper';

const RefLinksSettings = ({ t, classes }) => {
  const {
    brandConfig,
    handleInputChange,
    handleCheckboxChange,
    configClientPanelModule,
    setConfigClientPanelModule,
  } = usePanelConfigurationContext();

  return (
    <CardWrapper
      title={t(
        'brands.newBrandForm.sharingOptions',
        'Opcje udostępniania kodu polecającego'
      )}
    >
      <Divider style={{ marginBottom: '20px' }} />
      <CardBody>
        <GridContainer>
          <GridItem md={12} lg={6}>
            <GridContainer>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.showRefererCode}
                  onClick={() => {
                    handleCheckboxChange('showRefererCode');
                  }}
                  label={t(
                    'brands.newBrandForm.sharingOption',
                    'Klient ma możliwość udostępniania kodu polecającego'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.showFacebookCodeReference}
                  onClick={() => {
                    handleCheckboxChange('showFacebookCodeReference');
                  }}
                  label={t(
                    'brands.newBrandForm.facebookSharing',
                    'Udostępnianie przez Facebook'
                  )}
                />
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.facebookAddress',
                    'Adres udostępniany przez Facebook'
                  )}
                  classes={classes}
                  name="facebookCodeReferenceLink"
                  value={brandConfig.facebookCodeReferenceLink}
                  disabled={!brandConfig.showFacebookCodeReference}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.showLinkedinCodeReference}
                  onClick={() => {
                    handleCheckboxChange('showLinkedinCodeReference');
                  }}
                  label={t(
                    'brands.newBrandForm.linkedinSharing',
                    'Udostępnianie przez LinkedIn'
                  )}
                />
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.linkedinAddress',
                    'Adres udostępniany przez LinkedIn'
                  )}
                  classes={classes}
                  name="linkedinCodeReferenceLink"
                  value={brandConfig.linkedinCodeReferenceLink}
                  disabled={!brandConfig.showLinkedinCodeReference}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.showTwitterCodeReference}
                  onClick={() => {
                    handleCheckboxChange('showTwitterCodeReference');
                  }}
                  label={t(
                    'brands.newBrandForm.twitterSharing',
                    'Udostępnianie przez Twitter'
                  )}
                />
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.twitterAddress',
                    'Adres udostępniany przez Twitter'
                  )}
                  classes={classes}
                  name="twitterCodeReferenceLink"
                  value={brandConfig.twitterCodeReferenceLink}
                  disabled={!brandConfig.showTwitterCodeReference}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem md={12} lg={6}>
            <GridContainer>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.showWhatsappCodeReference}
                  onClick={() => {
                    handleCheckboxChange('showWhatsappCodeReference');
                  }}
                  label={t(
                    'brands.newBrandForm.whatsappSharing',
                    'Udostępnianie przez WhatsApp'
                  )}
                />
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.whatsapAddress',
                    'Adres udostępniany przez WhatsApp'
                  )}
                  classes={classes}
                  name="whatsappCodeReferenceLink"
                  value={brandConfig.whatsappCodeReferenceLink}
                  disabled={!brandConfig.showWhatsappCodeReference}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.showMessengerCodeReference}
                  onClick={() => {
                    handleCheckboxChange('showMessengerCodeReference');
                  }}
                  label={t(
                    'brands.newBrandForm.messengerSharing',
                    'Udostępnianie przez Messenger'
                  )}
                />
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.messengerAddress',
                    'Adres udostępniany przez Messenger'
                  )}
                  classes={classes}
                  name="messengerCodeReferenceLink"
                  value={brandConfig.messengerCodeReferenceLink}
                  disabled={!brandConfig.showMessengerCodeReference}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
              </GridItem>
              <GridItem md={12}>
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.sharingCopy',
                    'Tekst zaproszenia'
                  )}
                  classes={classes}
                  name="referralProgramDescription"
                  value={configClientPanelModule.referralProgramDescription}
                  disabled={false}
                  maxLength={600}
                  multiline
                  handleChange={event => {
                    setConfigClientPanelModule(prevState => ({
                      ...prevState,
                      referralProgramDescription: event.target.value,
                    }));
                  }}
                  inputSize={6}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(extendedFormsStyle)
);

export default enhance(RefLinksSettings);

import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import AddonsCategoriesList from './AddonsCategoriesList';
import AddonsCategoriesForm from './AddonsCategoriesForm';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_ADDON,
  ROLE_EDIT_ADDON,
  ROLE_SHOW_ADDON,
} from 'helpers/roles';

const AddonsCategories = props => {
  const { history, match } = props;
  const ProtectedAddonsCategoriesList = withRole(
    ROLE_SHOW_ADDON,
    AddonsCategoriesList
  );
  const ProtectedCreateForm = withRole(ROLE_CREATE_ADDON, AddonsCategoriesForm);
  const ProtectedEditForm = withRole(ROLE_EDIT_ADDON, AddonsCategoriesForm);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ProtectedAddonsCategoriesList {...props} {...parentProps} />
          )}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <ProtectedCreateForm {...props} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => (
            <ProtectedEditForm {...props} {...parentProps} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(AddonsCategories);

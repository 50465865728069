import React from 'react';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import Card from 'components/Card/Card';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import LettersCounter from 'views/PagesCreator/LettersCounter';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const Benefit = ({
  t,
  label,
  classes,
  getImage,
  removeImage,
  handleChange,
  benefit,
}) => {
  const previewUrl = benefit.imageUrl;

  return (
    <Card>
      <FormImageUpload
        label={label}
        classes={classes}
        stateName={`benefit${benefit.no}`}
        getImage={getImage}
        removeImage={removeImage}
        previewUrl={previewUrl}
      />
      <div style={{ margin: '18px' }}>
        <FormTextInput
          noGrid
          classes={classes}
          name="title"
          value={benefit.title}
          handleChange={handleChange}
          label={t('form.title', 'Title')}
          maxLength={160}
        />
        <FormTextInput
          noGrid
          classes={classes}
          name="content"
          value={benefit.content}
          handleChange={handleChange}
          rows={3}
          rowsMax={20}
          maxLength={500}
          label={
            <LettersCounter
              label={t('form.field.description', 'Description')}
              wordsCount={benefit.content?.length ?? 0}
              colorsConfig={[
                {
                  minValue: 2,
                  maxValue: 450,
                  color: 'green',
                },
                {
                  minValue: 450,
                  maxValue: 499,
                  color: 'orange',
                },
                { minValue: 499, color: 'red' },
              ]}
            />
          }
          multiline
        />
      </div>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(Benefit);

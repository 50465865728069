import { useTranslation } from 'react-i18next';
import { FormLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import CardBody from 'components/Card/CardBody';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import CardWrapper from 'components/Card/CardWrapper';

const FakturowniaWarehouseModule = ({
  classes,
  handleModuleUpdate,
  module,
}) => {
  const { t } = useTranslation();
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'FakturowniaWarehouseModule',
        })}
      </div>
    );
  }

  const toggleEnabled = e => {
    if (e.target.checked) {
      handleModuleUpdate({ ...module, enabled: e.target.checked });
    } else {
      setWarningModalOpen(true);
    }
  };

  return (
    <CardWrapper>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.enabled}
                  onChange={toggleEnabled}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={'Fakturownia.pl - Magazyn'}
            />
          </GridItem>
          <GridItem md={6}>
            <GridContainer>
              <GridItem md={12} style={{ height: '65px' }}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('brands.newBrandForm.apiToken', 'API Token')}
                </FormLabel>
                <FormTextInputNoGrid
                  classes={classes}
                  name="apiToken"
                  value={module.apiToken}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      apiToken: e.target.value,
                    })
                  }
                />
              </GridItem>
              <GridItem md={12} style={{ height: '65px' }}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('brands.newBrandForm.host', 'Host ')}
                </FormLabel>
                <FormTextInputNoGrid
                  classes={classes}
                  name="host"
                  value={module.host}
                  maxLength={64}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      host: e.target.value,
                    })
                  }
                />
              </GridItem>
              <GridItem md={12} style={{ height: '65px' }}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('brands.newBrandForm.token', 'Token ')}
                </FormLabel>
                <FormTextInputNoGrid
                  classes={classes}
                  name="token"
                  value={module.token}
                  maxLength={64}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      token: e.target.value,
                    })
                  }
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
      <Dialog
        open={isWarningModalOpen}
        onClose={() => setWarningModalOpen(false)}
      >
        <DialogContent style={{ maxHeight: '700px' }}>
          <div>{t('module.FakturowniaWarehouse.disableWarning')}</div>
          <ModalButton
            style={{ float: 'right' }}
            color={'danger'}
            onClick={() => {
              handleModuleUpdate({ ...module, enabled: false });
              setWarningModalOpen(false);
            }}
          >
            {t('form.change')}
          </ModalButton>
          <ModalButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              cursor: 'pointer',
            }}
            color={'transparent'}
            justIcon
            round
            onClick={() => setWarningModalOpen(false)}
          >
            <Close />
          </ModalButton>
        </DialogContent>
      </Dialog>
    </CardWrapper>
  );
};

export default FakturowniaWarehouseModule;

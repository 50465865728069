import Chip from '@material-ui/core/Chip';

const STATUS_STYLES_MAP = {
  PAID: { color: 'green', borderColor: 'green' },
  WAITING_FOR_PAYMENT: { color: 'orange', borderColor: 'orange' },
  CANCELED: {},
};

export const orderStatusCell = ({ status: { value, systemValue } }) => {
  return (
    <Chip
      label={value}
      variant="outlined"
      style={STATUS_STYLES_MAP[systemValue] ?? {}}
    />
  );
};

export default orderStatusCell;

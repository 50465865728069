export const initialState = {
  modal: null,
  modalOptions: [],
  image: null,
  imageUrl: '',
  variant: {
    name: '',
    shortName: '',
    position: '',
    internalPosition: '',
    mealTypes: [],
    diets: [],
    active: true,
    allowSelectMenuFromOtherVariants: true,
    superVariantAlpha: false,
    superVariantBeta: false,
    minPrice: null,
    minMealTypes: null,
    calories: [],
  },
};

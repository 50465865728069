import React from 'react';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const List = ({ t, currency }) => {
  return (
    <AdminTable title={t('list.products')}>
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        paginationTop={true}
        paginationBottom={false}
        url="products"
        reportName={'product'}
        columns={columnConfig(t, currency)}
        role="PRODUCT"
      />
    </AdminTable>
  );
};

const mapStateToProps = state => {
  return {
    currency: state.Brands.brand.multinational.currency,
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(List);

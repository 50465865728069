import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import { get } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import CardBody from 'components/Card/CardBody';
import SelectInput from 'components/FormSelect/SelectInput';
import CardWrapper from 'components/Card/CardWrapper';
import GridItem from 'components/Grid/GridItem';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  grid: { marginLeft: '-15px' },
});

const ContainerSelect = ({
  classes: propClasses,
  bagContainer,
  setBagContainer,
  numberOfBags,
  setFormDietsState,
  isFoodsiEnabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [containers, setContainers] = useState([]);

  const minNumberOfBags = 1;
  const maxNumberOfBags = 10;

  const numberOfBagsOption = () => {
    const options = [];
    for (let i = minNumberOfBags; i <= maxNumberOfBags; i++) {
      options.push({ name: `${i}`, value: i });
    }
    return options;
  };

  useEffect(async () => {
    const newContainers = await get('/bag-containers');
    setContainers(newContainers?.['hydra:member']);
  }, []);

  return (
    <CardWrapper title={t('diets.configContainerType', '$$Ustawienia torby')}>
      <CardBody style={{ marginBottom: '1rem' }}>
        <GridItem xs={6} className={classes.grid}>
          <SelectInput
            classes={propClasses}
            label={t(
              ('diets.confidContainerType.selectLabel',
              'Wybierz do jakiej torby ma być pakowana dieta')
            )}
            mapBy="name"
            trackBy="@id"
            value={bagContainer}
            options={containers}
            handleChange={(ev, obj) => setBagContainer(obj)}
          />
        </GridItem>
        {isFoodsiEnabled && (
          <GridItem xs={6} className={classes.grid}>
            <SelectInput
              classes={propClasses}
              label={`${t(
                ('diets.confidContainerType.numberOfBags', 'Liczba toreb')
              )} *`}
              mapBy="name"
              trackBy="value"
              value={numberOfBags}
              options={numberOfBagsOption()}
              handleChange={(ev, obj) => {
                return setFormDietsState(prev => {
                  return { ...prev, numberOfBags: obj.value };
                });
              }}
            />
          </GridItem>
        )}
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(ContainerSelect);

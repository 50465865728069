import GridItem from 'components/Grid/GridItem';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TagsInput from 'react-tagsinput';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const DefinedLengthsSectionSettiongs = ({
  t,
  classes,
  state,
  setState,
  handleDays,
}) => {
  return (
    <>
      <GridItem md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.predefinedDietLengthEnabled}
              onChange={() =>
                setState(prevState => ({
                  ...prevState,
                  predefinedDietLengthEnabled: !state.predefinedDietLengthEnabled,
                }))
              }
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('brandCfg.defLength')}
        />
        <label
          htmlFor="orderLength"
          style={{
            cursor: 'pointer',
            display: 'block',
            color: 'rgba(0, 0, 0, 0.46)',
          }}
        >
          {t('brandCfg.addLength')}
        </label>
        <TagsInput
          renderTag={props => {
            let {
              tag,
              key,
              disabled,
              onRemove,
              classNameRemove,
              getTagDisplayValue,
              ...other
            } = props;
            return (
              <span key={key} {...other}>
                {getTagDisplayValue(
                  parseInt(tag) === 1
                    ? tag + t('brandCfg.day')
                    : tag + t('brandCfg.days')
                )}
                {!disabled && (
                  <a
                    href="/"
                    className={classNameRemove}
                    onClick={e => {
                      e.preventDefault();
                      onRemove(key);
                    }}
                  >
                    {''}
                  </a>
                )}
              </span>
            );
          }}
          inputProps={{
            style: {
              width: '45px',
              marginTop: '25px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
              paddingBottom: '10px',
            },
            id: 'orderLength',
            placeholder: t('brands.newBrandForm.eg', 'np: "7"'),
          }}
          tagProps={{
            className: 'react-tagsinput-tag info',
          }}
          value={state.days}
          onChange={(tags, changed) => {
            const tagsToInts = tags.map(el => parseInt(el));
            const changedToInts = changed.map(el => parseInt(el));
            handleDays(tagsToInts, changedToInts, 'days');
          }}
        />
      </GridItem>
      <GridItem md={6}></GridItem>
    </>
  );
};

export default DefinedLengthsSectionSettiongs;

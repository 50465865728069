import React from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';

const generateGoToUserLink = props => {
  const { firstName, lastName, id } = props.client;
  return (
    <Link to={`/admin/clients/edit/${id}`}>
      {firstName} {lastName}
    </Link>
  );
};

const generateGoToOrderLink = ({ id }) => (
  <Link to={`/admin/orders/?orderId=${id}`}>{id}</Link>
);

const translateColumnConfig = t => [
  {
    title: t('promoCodes.historyColumnConfig.clientID', 'ID klienta'),
    accessor: props => props.client.id,
    name: 'id',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    title: t('promoCodes.historyColumnConfig.client', 'Użytkownik'),
    accessor: generateGoToUserLink,
    name: 'client',
    filterable: false,
    sortable: false,
  },
  {
    title: t('promoCodes.historyColumnConfig.dateOfUse', 'Data użycia'),
    accessor: row => new Moment(row.createdAt).format('DD.MM.YYYY'),
    name: 'createdAt',
    filterable: false,
    sortable: false,
  },
  {
    title: t('promoCodes.historyColumnConfig.order', 'Zamówienie'),
    name: '@id',
    filterable: false,
    sortable: false,
    accessor: generateGoToOrderLink,
  },
  {
    title: t('promoCodes.infoTable.status', 'Status'),
    name: 'status.value',
    filterable: false,
    sortable: false,
    accessor: row => row.status.value,
  },
  {
    title: t('promoCodes.historyColumnConfig.before', 'Koszt przed rabatem'),
    accessor: 'priceBeforeDiscount',
    name: 'priceBeforeDiscount',
    filterable: false,
    sortable: false,
  },
  {
    title: t('promoCodes.historyColumnConfig.after', 'Koszt po rabacie'),
    accessor: 'priceAfterDiscount',
    name: 'priceAfterDiscount',
    filterable: false,
    sortable: false,
  },
];

export default translateColumnConfig;

import CATERINGS_FLOW_STATUSES from './cateringsFlowStatuses';

const validTransition = [
  {
    from: [CATERINGS_FLOW_STATUSES.CREATED, CATERINGS_FLOW_STATUSES.WAITING],
    to: [],
  },
  {
    from: [CATERINGS_FLOW_STATUSES.WAITING],
    to: [
      CATERINGS_FLOW_STATUSES.DONE_WAITING,
      CATERINGS_FLOW_STATUSES.DONE,
      CATERINGS_FLOW_STATUSES.EARN_EXTRA,
    ],
  },
  {
    from: [CATERINGS_FLOW_STATUSES.IN_PROGRESS],
    to: [
      CATERINGS_FLOW_STATUSES.CREATED,
      CATERINGS_FLOW_STATUSES.WAITING,
      CATERINGS_FLOW_STATUSES.IN_PROGRESS,
      CATERINGS_FLOW_STATUSES.EARN_EXTRA,
    ],
  },
  {
    from: [CATERINGS_FLOW_STATUSES.DONE_WAITING],
    to: [
      CATERINGS_FLOW_STATUSES.CREATED,
      CATERINGS_FLOW_STATUSES.WAITING,
      CATERINGS_FLOW_STATUSES.IN_PROGRESS,
      CATERINGS_FLOW_STATUSES.DONE_WAITING,
      CATERINGS_FLOW_STATUSES.EARN_EXTRA,
    ],
  },
  {
    from: [CATERINGS_FLOW_STATUSES.DONE],
    to: [
      CATERINGS_FLOW_STATUSES.CREATED,
      CATERINGS_FLOW_STATUSES.WAITING,
      CATERINGS_FLOW_STATUSES.IN_PROGRESS,
      CATERINGS_FLOW_STATUSES.DONE_WAITING,
      CATERINGS_FLOW_STATUSES.DONE,
    ],
  },
  {
    from: [CATERINGS_FLOW_STATUSES.EARN_EXTRA],
    to: [
      CATERINGS_FLOW_STATUSES.CREATED,
      CATERINGS_FLOW_STATUSES.WAITING,
      CATERINGS_FLOW_STATUSES.DONE_WAITING,
      CATERINGS_FLOW_STATUSES.DONE,
      CATERINGS_FLOW_STATUSES.EARN_EXTRA,
    ],
  },
];

export default validTransition;

import React, { useState } from 'react';

import PeriodDiscounts from './PeriodDiscounts.container';
import DeliveryDiscountPlaces from './DeliveryDiscountPlaces.container';
import SameAddressDiscounts from './SameAddressDiscounts.container';

import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';
import LogView from 'components/History/LogView';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  tabsContainer: {
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  button: {
    boxShadow: 'none',
    marginTop: '0',
    marginBottom: '0',
    color: '#000',
    '&:hover': { boxShadow: 'none' },
  },
  buttonAfter: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: '0',
      height: '15px',
      width: '1px',
      background: 'RGB(209,213,219)',
    },
    '&:last-child': { '&::after': { display: 'none' } },
  },
  currentButton: {
    color: 'white',
    '&::after': { display: 'none' },
  },
  historyMarginCustom: {
    margin: '5px',
  },
});

const VIEW_PERIOD = 'period';
const VIEW_POINT = 'point';
const VIEW_SAME_ADDRESS = 'sameaddress';

const translateDiscountsTypes = t => [
  {
    name: t('dietDiscountsTypes.period', 'Okres zamówienia'),
    value: VIEW_PERIOD,
  },
  {
    name: t('dietDiscountsTypes.point', 'Odbiór z punktu'),
    value: VIEW_POINT,
  },
  {
    name: t('dietDiscountsTypes.sameaddress', 'Wspólny adres'),
    value: VIEW_SAME_ADDRESS,
  },
];

const historyEndpoints = {
  [VIEW_PERIOD]: '/discount-diet-lengths',
  [VIEW_POINT]: '/discount-pick-up-points',
  [VIEW_SAME_ADDRESS]: '/discount-same-addresses',
};

const discountViews = {
  [VIEW_PERIOD]: <PeriodDiscounts />,
  [VIEW_POINT]: <DeliveryDiscountPlaces />,
  [VIEW_SAME_ADDRESS]: <SameAddressDiscounts />,
};

const PeriodDiscountsContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({
    currentView: translateDiscountsTypes(t)[0].value,
  });

  const changeDiscountView = currentView => setState({ currentView });

  const renderLayout = () => discountViews[state.currentView];

  const renderLogView = () => (
    <LogView
      iri={historyEndpoints[state.currentView]}
      customOffsetSmall={classes.historyMarginCustom}
      customOffsetLarge={classes.historyMarginCustom}
      isFixedToBottom={true}
    />
  );

  return (
    <>
      <GridContainer>
        <GridItem>
          <div className={classes.tabsContainer}>
            {translateDiscountsTypes(t).map(({ value, name }, index) => {
              const isCurrent = state.currentView === value;
              const indexOfChoicesElement = translateDiscountsTypes(
                t
              ).findIndex(el => el.value === state.currentView);

              return (
                <Button
                  key={value}
                  className={classNames(
                    classes.button,
                    isCurrent && classes.currentButton,
                    (index < indexOfChoicesElement - 1 ||
                      index > indexOfChoicesElement) &&
                      classes.buttonAfter
                  )}
                  color={isCurrent ? 'info' : 'white'}
                  round={false}
                  onClick={() => {
                    changeDiscountView(value);
                  }}
                >
                  {name}
                </Button>
              );
            })}
          </div>
        </GridItem>
      </GridContainer>
      <form id="newIngredientForm">
        <Card>
          <CardBody>{renderLayout()}</CardBody>
        </Card>
        {renderLogView()}
      </form>
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(PeriodDiscountsContainer);

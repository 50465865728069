import React from 'react';
import FormIngredientsContainer from './components/FormIngredients.container';

const Form = ({ match, location, history }) => {
  return (
    <FormIngredientsContainer
      match={match}
      location={location}
      history={history}
    />
  );
};

export default Form;

import { Container } from './DriverRoute.styles';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment';

const DriverRoute = ({ route, index, isDragDisabled }) => {
  return (
    <Draggable
      draggableId={route.id.toString()}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <div>
            {' '}
            <p>
              {route.address.street} {route.address.buildNumber}{' '}
              {route.address.placeNumber
                ? ` / ${route.address.placeNumber}`
                : ''}
            </p>
            <p>
              {route.address.postCode} {route.address.city}
            </p>
          </div>
          <div>
            <p>
              {moment(route.address.deliveryHourFrom).format('HH:mm')} -{' '}
              {moment(route.address.deliveryHourTo).format('HH:mm')}
            </p>
          </div>
        </Container>
      )}
    </Draggable>
  );
};

export default DriverRoute;

import moment from 'moment';

import i18n from '../i18n';

const getReportFileName =
  title =>
  ({ date, dateFrom, dateTo }) => {
    let resultDate = '_';

    if (date) {
      resultDate = `_${date}_`;
    } else if (dateFrom && dateFrom === dateTo) {
      resultDate = `_${dateFrom}_`;
    } else if (dateFrom && dateFrom !== dateTo) {
      resultDate = `_${dateFrom}_-_${dateTo}_`;
    }

    let fileName = `${title
      .replace(i18n.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}${resultDate}(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

export default getReportFileName;

export const translateDietStatuses = t => ({
  NO_DIET: t('dietStatus.noDiet', 'Brak diety'),
  ADD_DIET: t('dietStatus.addDiet', 'Dodaj dzień do diety'),
  DETAILS_SHOW_PREV: t('dietStatus.detailsShowPrev', 'Minął czas na ocenę'),
  RATE_MENU: t('dietStatus.rateMenu', 'Oceń menu'),
  TODAY: t('dietStatus.totay', 'Dzisiaj'),
  DETAILS_SHOW_NEXT: t(
    'dietStatus.detailsShowNext',
    'Dzień Diety. Nie mona edytować'
  ),
  CONFIGURABLE: t('dietStatus.configurable', 'Możliwa edycja'),
});

import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import { useTranslation } from 'react-i18next';

const ReportsBoxLabelsBreadcrumbAdditionalContent = () => {
  const { t } = useTranslation();

  return (
    <InfoWithTooltip
      title={t('common.mainMenu.reportsBoxLabelsInfo')}
      link={EXTERNAL_URLS.waysOfPackingBags}
    />
  );
};

export default ReportsBoxLabelsBreadcrumbAdditionalContent;

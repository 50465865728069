import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import React from 'react';
import { Tooltip } from '@material-ui/core/index';
import { Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';

const TestDaysSectionSettings = ({
  t,
  state,
  setState,
  classes,
  handleInputChange,
  modules,
  setModules,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.testDaysEnabled}
            onChange={e =>
              setState(prevState => ({
                ...prevState,
                testDaysEnabled: !state.testDaysEnabled,
              }))
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('brandCfg.offerTestDays')}
      />
      <GridContainer>
        <GridItem md={6}>
          <label
            htmlFor="orderLength"
            style={{
              cursor: 'pointer',
              display: 'block',
              color: 'rgba(0, 0, 0, 0.46)',
            }}
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                testDaysSelectorType: 'RANGE',
              }));
            }}
          >
            {t(
              'brands.newBrandForm.minAndMax',
              'Ustaw minimalną oraz maksymalną wartość możliwych dni zamówień testowych '
            )}
          </label>
          <GridContainer>
            <GridItem md={4}>
              <FormLabel className={classes.labelHorizontal}>
                {t('brandCfg.minimum', 'Minimalnie ')}
              </FormLabel>
              <NumberFormat
                classes={classes}
                customInput={FormTextInputNoGrid}
                format={'###'}
                name={'testDaysMin'}
                value={state.testDaysMin}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem md={4}>
              <FormLabel className={classes.labelHorizontal}>
                {t('brandCfg.maximum', 'Maksymalnie ')}
              </FormLabel>
              <NumberFormat
                classes={classes}
                customInput={FormTextInputNoGrid}
                format={'###'}
                name={'testDaysMax'}
                value={state.testDaysMax}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem md={4}>
              <FormLabel className={classes.labelHorizontal}>
                {t('brandCfg.defValue', 'Wartość domyślna ')}
              </FormLabel>
              <NumberFormat
                classes={classes}
                customInput={FormTextInputNoGrid}
                format={'###'}
                name={'testDaysDefault'}
                value={state.testDaysDefault}
                onChange={handleInputChange}
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="allowGrayingOutDiscountCode"
                    onClick={() =>
                      setModules(prev => ({
                        ...prev,
                        ConfigClientPanel: {
                          ...prev.ConfigClientPanel,
                          allowGrayingOutDiscountCode: !modules
                            .ConfigClientPanel.allowGrayingOutDiscountCode,
                        },
                      }))
                    }
                    checked={
                      modules?.ConfigClientPanel?.allowGrayingOutDiscountCode
                    }
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t(
                  'brandCfg.allowGrayingOutDiscountCode',
                  'Wyszarz pole na kod rabatowy'
                )}
              />
              <Tooltip
                title={
                  <h4>
                    {t(
                      'brandCfg.allowGrayingOutDiscountCode.tooltip',
                      'Gdy klient wybierze zamówienie testowe pole na kod rabatowy zostanie wyszarzone i pokaże się komunikat: “Kody rabatowe i polecające nie działają przy zamówieniu próbnym”'
                    )}
                  </h4>
                }
                placement="right"
              >
                <Info
                  style={{
                    marginLeft: '-10px',
                    color: 'grey',
                    fontSize: '16px',
                  }}
                />
              </Tooltip>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default TestDaysSectionSettings;

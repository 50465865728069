import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './Orders.container';
import Form from './NewOrder';
import withRole from 'components/Security/withRole';
import { ROLE_CREATE_ORDER, ROLE_SHOW_ORDER } from 'helpers/roles';

class Orders extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_ORDER, List);
    const CreateForm = withRole(ROLE_CREATE_ORDER, Form);
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Orders);

import types from 'helpers/constants';

const initialState = {
  zones: [],
  zone: {},
};

function Zones(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ZONES:
      return {
        ...state,
        zones: action.payload.zones,
      };
    case types.FETCH_ZONE:
      return {
        ...state,
        zone: action.payload,
      };
    case types.REMOVE_ZONE:
      return {
        ...state,
        zones: state.zones.filter(zone => zone.id !== action.payload.zoneId),
      };
    default:
      return state;
  }
}

export default Zones;

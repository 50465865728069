import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './List';
import Form from './Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_SHOW_PACKAGE,
  ROLE_EDIT_PACKAGE,
  ROLE_CREATE_PACKAGE,
} from 'helpers/roles';

const FilterLineDiets = props => {
  const ListSecured = withRole(ROLE_SHOW_PACKAGE, List);
  const CreateForm = withRole(ROLE_CREATE_PACKAGE, Form);
  const EditForm = withRole(ROLE_EDIT_PACKAGE, Form);

  return (
    <Router history={props.history}>
      <Switch>
        <Route
          exact
          path={`${props.match.url}/`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${props.match.url}/add`}
          render={() => <CreateForm {...props} />}
        />
        <Route
          path={`${props.match.url}/edit/:id`}
          render={parentProps => <EditForm {...props} {...parentProps} />}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(FilterLineDiets);

import React, { Component } from 'react';
import { Route, Router, Switch, withRouter } from 'react-router';

import Blog from './Blog';
import Form from './Form';

class BlogCreator extends Component {
  render() {
    const props = this.props;

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <Blog {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <Form {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => (
              <Form isEdit={true} {...props} {...parentProps} />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(BlogCreator);

import { Component, createRef } from 'react';
import _ from 'lodash';
import qs from 'query-string';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/styles/withStyles';
import { Cached } from '@material-ui/icons';
import classNames from 'classnames';

import axios from 'helpers/gastro';
import roles from 'helpers/roles';
import { get } from 'helpers/apiHelpers';
import {
  isGranted,
  translateNamesForExport,
  trimEmptyStringsFromQuery,
} from 'helpers/helpers';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import { DialogLoader } from 'components/DialogLoader';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import Actions from './Actions';
import DataGridArchive from './DataGridArchive';
import ColumnManager, { FilterPersistDriver } from './ColumnManager';

import TOAST_DURATIONS from 'helpers/toastDurations';
import RegularCheckbox from 'components/Checkbox/RegularCheckbox';

const styles = {
  dataGridCheckRoot: {
    '&&': {
      padding: 0,
    },
  },
  dataGridExportWrapper: { display: 'flex', alignItems: 'center' },
  dataGridExport: {
    display: 'flex',
    transition: '0.5s all',
    marginLeft: '3px',
    marginRight: '10px',
  },
  dataGridLoading: {
    opacity: 0,
  },
  dataGridColumnManager: {
    transition: '0.5s opacity',
    marginRight: '10px',
  },
  dataGridColumnManagerLoading: {
    opacity: 0,
  },
  dataGridDisableTotal: {
    transition: '0.5s all',
    marginRight: '10px',
  },
  dataGridNoRefresh: {
    transition: '0.5s opacity',
    cursor: 'pointer',
    marginTop: '5px',
    marginRight: '10px',
  },
};

class DataGrid extends Component {
  grid = createRef();
  filterPersistDriver;
  constructor(props) {
    super(props);
    this.filterPersistDriver = new FilterPersistDriver(props.url);
    this.state.hiddenColumns = this.filterPersistDriver.getHiddenColumns(
      props.defaultHiddenColumns || []
    );
  }

  state = {
    data: [],
    pages: 1,
    query: {},
    loading: true,
    exporting: false,
    totalItems: 0,
    summaryRow: {},
    usedFilters: [],
    firstLoaded: true,
    typingTimeout: 0,
    hiddenColumns: [],
    computedDefaultFilter: [],
  };

  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,
      computedDefaultFilter: this.resolveDefaultFilter(),
    }));
  }

  getExpandedRows = data => {
    let expanded = {};
    const { defaultExpanded } = this.props;

    if (defaultExpanded) {
      data.map((item, index) => {
        expanded = { ...expanded, [index]: true };
        return null;
      });
    }

    return expanded;
  };

  getClassName = () => {
    const { striped = true } = this.props;

    return `${striped ? '-striped' : ''} -highlight'`;
  };

  extractValue = (value, extractFiltersByComma = true) => {
    if (
      typeof value === 'string' &&
      extractFiltersByComma &&
      value.includes(',') &&
      !this.props.unseparateAfterComma
    ) {
      let result = value.split(',').map(el => el.trim());
      result = trimEmptyStringsFromQuery(result);
      return result;
    }

    return value;
  };

  fetchDataWithTimeout = (requestData, table) => {
    if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);

    this.setState({
      typingTimeout: setTimeout(() => this.fetchData(requestData, table), 500),
    });
  };

  isFilterOnHiddenColumn() {
    const filteredKeys = this.state.usedFilters.map(el => el.id);

    return (
      filteredKeys.filter(el => this.state.hiddenColumns.includes(el)).length >
      0
    );
  }

  fetchData = async (requestData, table) => {
    if (!this.props.url && this.props.data) {
      return this.setState({
        data: this.props.data,
        loading: false,
      });
    }

    this.setState(prevState => {
      if (this.props.refreshOnFetch) {
        return {
          ...prevState,
          data: [],
          summaryRow: {},
          loading: true,
        };
      } else {
        return {
          ...prevState,
          loading: true,
        };
      }
    });
    let query = {
      partial: true,
      itemsPerPage: requestData.pageSize,
      page: requestData.page + 1,
    };

    if (this.props.query) {
      query.key = this.props.query;
    }

    let filter = undefined;

    if (requestData.filtered.length > 0) {
      filter = window.btoa(
        unescape(encodeURIComponent(JSON.stringify(requestData.filtered)))
      );
      this.setState(p => ({ ...p, usedFilters: requestData.filtered }));
    } else {
      this.setState(p => ({ ...p, usedFilters: [] }));
    }
    const queryParams = qs.parse(window.location.search);
    const newQueries = { ...queryParams, filter: filter };
    this.props.history.replace({ search: '?' + qs.stringify(newQueries) });
    requestData.filtered.map(el => {
      const extractFiltersByComma = table.props.columns.find(
        column => column.id === el.id
      )?.extractFiltersByComma;

      return (query[el.id] = this.extractValue(
        el.value,
        extractFiltersByComma
      ));
    });

    if (requestData.sorted.length > 0) {
      query['order[' + requestData.sorted[0].id + ']'] = requestData.sorted[0]
        .desc
        ? 'DESC'
        : 'ASC';
    } else {
      query['order[id]'] = 'DESC';
    }

    if (typeof this.props.manipulateQuery === 'function') {
      query = this.props.manipulateQuery(requestData, query);
    }

    this.setState(prevState => ({
      ...prevState,
      query,
    }));

    get(this.props.url, query).then(response => {
      if (query.workName > '' && response['hydra:member'].length === 0) {
        query.name = query.workName;
        query.workName = null;
        get(this.props.url, query).then(response => {
          this.setState(prevState => {
            return {
              ...prevState,
              summaryRow,
              expandedRows: expandedRows,
              data: (response['hydra:member'] ?? []).filter(
                item => !isEmpty(item)
              ),
            };
          });
        });
      }

      const columnsToSummarize = [...this.props.columns]
        .filter(({ summarize }) => summarize)
        .map(({ name }) => name);
      let summaryRow = {};

      if (!isEmpty(columnsToSummarize)) {
        summaryRow = this.getSummaryRow({
          columnsToSummarize,
          data: response['hydra:member'],
        });
      }

      const expandedRows = this.getExpandedRows(response['hydra:member']);

      this.setState(prevState => {
        return {
          ...prevState,
          summaryRow,
          expandedRows: expandedRows,
          data: (response['hydra:member'] ?? []).filter(item => !isEmpty(item)),
        };
      });

      if (typeof this.props.onFetchedData === 'function') {
        this.props.onFetchedData(response['hydra:member']);
      }
    });

    try {
      let { 'hydra:totalItems': totalItems } = await get(this.props.url, {
        ...query,
        pagination: true,
        itemsPerPage: 1,
        partial: false,
        'properties[]': '_',
      });

      let pages = Math.floor(totalItems / requestData.pageSize);

      if (pages !== totalItems / requestData.pageSize) {
        pages++;
      }

      this.setState(prevState => {
        return {
          ...prevState,
          loading: false,
          pages,
          totalItems,
        };
      });

      typeof this.props.afterFetchData === 'function' &&
        this.props.afterFetchData(table, requestData);
    } catch (error) {
      // loading on false when error fetch
      this.setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  // For now it only works with numerous values, to make it work you need to add flag summarize: true in columnConfig and make sure that name is same as key in data to reduce values properly. When adding to already existing DataGrid check if columns config accessors work with null/undefined values properly
  getSummaryRow = ({ columnsToSummarize = [], data = [] }) => {
    let summaryRow = {};
    const titleColumn = (this.props.columns ?? []).find(
      ({ name }) => !this.state.hiddenColumns.includes(name)
    );

    columnsToSummarize.forEach(column => {
      const summedValue = data.reduce((accum, row) => accum + row[column], 0);

      summaryRow[column] = summedValue;
    });

    if (!isEmpty(summaryRow)) {
      summaryRow.isSummary = true;
      summaryRow[titleColumn?.name ?? 'title'] = this.props.t(
        '$*common.sum',
        '$$Suma'
      );
    }

    return summaryRow;
  };

  fireFetchData = () => {
    this.grid.current.fireFetchData();
  };

  getExportButtonConfig = () => {
    const namesForExport = translateNamesForExport(this.props.t);
    return (
      this.props.exportButtonConfig || [
        {
          label: 'XLSX',
          format: 'xlsx',
          endpoint: this.props.url,
          fileName: (url, date) => {
            return namesForExport[url]
              ? namesForExport[url] + date
              : url + date;
          },
        },
        {
          label: 'CSV',
          format: 'csv',
          endpoint: this.props.url,
          fileName: (url, date) => {
            return namesForExport[url]
              ? namesForExport[url] + date
              : url + date;
          },
        },
      ]
    );
  };

  exportData = ({ format, fileName, endpoint, manipulateQuery }) => {
    this.setState({ exporting: true });
    const { t } = this.props;
    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName =
      typeof fileName === 'string' ? fileName : fileName(endpoint, date);

    const params =
      typeof manipulateQuery === 'function'
        ? manipulateQuery({ ...this.state.query })
        : this.state.query;

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: params,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
          this.setState({ exporting: false });
        },
        error => {
          this.setState({ exporting: false });
          return this.props.openToast({
            messages: [
              t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
        }
      );
  };

  componentWillReceiveProps(props) {
    const { refresh } = this.props;
    if (props.refresh !== refresh) {
      this.fireFetchData();
    }
  }

  wrapColumnPropertyWithPlug = (property, plug) => {
    if (typeof property === 'function') {
      return row => property(row, plug);
    } else {
      return property;
    }
  };

  render() {
    const { classes } = this.props;
    let { defaultSorted } = this.props;
    defaultSorted =
      typeof defaultSorted === 'undefined'
        ? [{ id: 'id', desc: true }]
        : defaultSorted;

    let accessorPlug = {
      t: this.props.t,
      multinational: this.props.multinational,
    };

    let columnConfig = this.props.columns;
    let canEdit = this.props.editPath || this.props.editAction;
    let canRemove = this.props.remove;
    let canShow = this.props.previewPath;
    let canExport =
      this.props.export && this.getExportButtonConfig().length > 0;

    if (typeof this.props.role !== 'undefined') {
      const editRole = 'ROLE_EDIT_' + this.props.role;
      const removeRole = 'ROLE_REMOVE_' + this.props.role;
      const showRole = 'ROLE_SHOW_' + this.props.role;
      const exportRole = 'ROLE_EXPORT_' + this.props.role;

      if (roles.hasOwnProperty(editRole)) {
        canEdit = canEdit && isGranted(editRole);
      }

      if (roles.hasOwnProperty(removeRole)) {
        canRemove = canRemove && isGranted(removeRole);
      }

      if (roles.hasOwnProperty(showRole)) {
        canShow = canShow && isGranted(showRole);
      }

      if (roles.hasOwnProperty(exportRole)) {
        canExport = canExport && isGranted(exportRole);
      }
    }

    const hasActions =
      canEdit ||
      canRemove ||
      canShow ||
      this.props.canEditChecker ||
      this.props.canShowChecker ||
      this.props.canRemoveChecker ||
      this.props.customActions ||
      this.props.hasRoleToModifyNotes;

    if (
      hasActions &&
      this.props.actions &&
      !columnConfig.find(c => c.name === 'actions')
    ) {
      columnConfig.push({
        name: 'actions',
        width: this.props.customActionsWidth
          ? this.props.customActionsWidth
          : this.props.editPath && this.props.previewPath
          ? 150
          : 100,
        sortable: false,
        filterable: false,
        Header: this.props.t('dataGrid.actions'),
        headerAdditionalContent:
          this.props.actionsHeaderAdditionalContent || null,
        accessor: row =>
          !row.isSummary ? (
            <Actions
              row={row}
              data={this.state.data}
              grid={this.grid}
              editPath={this.props.editPath}
              editAction={this.props.editAction}
              previewPath={this.props.previewPath}
              afterRemove={this.props.afterRemove}
              remove={this.props.remove}
              role={this.props.role}
              customActionsColumnDirection={
                this.props.customActionsColumnDirection
              }
              renderCustomRemoveBody={this.props.renderCustomRemoveBody}
              canEdit={
                this.props.hasRoleToModifyNotes &&
                row.moderator?.['@id'] === this.props.id
                  ? true
                  : this.props.canEditChecker
                  ? this.props.canEditChecker(canEdit, row)
                  : canEdit
              }
              canShow={
                this.props.hasRoleToModifyNotes
                  ? true
                  : this.props.canShowChecker
                  ? this.props.canShowChecker(canShow, row)
                  : canShow
              }
              canRemove={
                this.props.hasRoleToModifyNotes &&
                row.moderator?.['@id'] === this.props.id
                  ? true
                  : this.props.canRemoveChecker
                  ? this.props.canRemoveChecker(canRemove, row)
                  : canRemove
              }
              customActions={props =>
                typeof this.props.customActions === 'function'
                  ? this.props.customActions(props)
                  : this.props.customActions
              }
            />
          ) : null,
      });
    }

    columnConfig = columnConfig
      .filter(c => !this.state.hiddenColumns.includes(c.name))
      .map(column => {
        const config = {
          ...column,
          style: { textAlign: 'start' },
          id: column.name,
          Header: (() => {
            if (column.title && column.Header) {
              console.warn(
                'The column should only have a title or only a header defined. Otherwise, the title will overwrite the headline'
              );
            }

            return (
              <span style={column.headerStyles}>
                {column.title ? this.props.t(column.title) : column.Header}
                <span className="DataGrid-arrow" />
                {column.headerAdditionalContent}
              </span>
            );
          })(),
          accessor: this.wrapColumnPropertyWithPlug(
            column.accessor,
            accessorPlug
          ),
        };
        if (config.wrapFilter) {
          config.Filter = this.wrapColumnPropertyWithPlug(
            column.Filter,
            accessorPlug
          );
        }
        return config;
      });

    let pageSizeOptions = [1, 5, 10, 20, 50, 100];
    let defaultPageSize = this.props.customDefaultPageSize
      ? this.props.customDefaultPageSize
      : 20;

    if (this.state.totalItems > 0) {
      pageSizeOptions.push(
        this.state.totalItems < 500 ? this.state.totalItems : 500
      );
      pageSizeOptions = pageSizeOptions.filter(
        el => el <= this.state.totalItems
      );
      pageSizeOptions = _.uniq(pageSizeOptions);

      if (this.state.totalItems < defaultPageSize) {
        defaultPageSize = pageSizeOptions[pageSizeOptions.length - 1];
      }
    }

    if (this.props.massDelete) {
      columnConfig.unshift({
        id: 'mass-delete',
        width: 50,
        filterable: false,
        sortable: false,
        Header: col => {
          return (
            <RegularCheckbox
              customClasses={{ root: classes.dataGridCheckRoot }}
              checked={defaultPageSize === this.props.massDeleteItemsIds.length}
              onClick={() => {
                if (defaultPageSize === this.props.massDeleteItemsIds.length) {
                  this.props.massDeleteHandleMultipleCheckboxes([]);
                } else {
                  const itemsIds = col.data.map(item => item.id);
                  this.props.massDeleteHandleMultipleCheckboxes(itemsIds);
                }
              }}
            />
          );
        },
        accessor: row => {
          return (
            <RegularCheckbox
              customClasses={{ root: classes.dataGridCheckRoot }}
              checked={this.props.massDeleteItemsIds.includes(row.id)}
              onClick={() => {
                this.props.massDeleteHandleSingleCheckbox(row.id);
              }}
            />
          );
        },
      });
    }

    return (
      <div>
        <DialogLoader
          loaderState={this.state.exporting}
          text={this.props.t('dataGrid.exporting')}
        />
        <div className={classes.dataGridExportWrapper}>
          {this.props.disableExport
            ? null
            : canExport && (
                <>
                  <div
                    className={classNames(
                      classes.dataGridExport,
                      this.state.loading && classes.dataGridLoading
                    )}
                    data-cy="__export_dropdown"
                  >
                    <CustomDropdown
                      hoverColor="info"
                      buttonText={this.props.t('dataGrid.export')}
                      buttonProps={{
                        fullWidth: true,
                      }}
                      dropdownHeader={this.props.t('dataGrid.selectFormat')}
                      dropdownList={this.getExportButtonConfig().map(conf => ({
                        handleClick: () =>
                          this.exportData({
                            format: conf.format,
                            fileName: conf.fileName,
                            endpoint: conf.endpoint,
                            manipulateQuery: conf.manipulateQuery,
                          }),
                        optionText: conf.label,
                      }))}
                    />
                  </div>
                </>
              )}
          {!this.props.hideArchive && (
            <DataGridArchive reportName={this.props.reportName} />
          )}
          <div
            className={classNames(
              classes.dataGridColumnManager,
              this.state.loading && classes.dataGridLoading
            )}
          >
            <ColumnManager
              columns={this.props.columns}
              hidden={this.state.hiddenColumns}
              isFilterOnHiddenColumn={this.isFilterOnHiddenColumn()}
              setHidden={hidden =>
                this.setState({ hiddenColumns: hidden }, () =>
                  this.filterPersistDriver.setHiddenColumns(hidden)
                )
              }
            />
          </div>
          {!this.props.disableTotal && (
            <span
              className={classNames(
                classes.dataGridDisableTotal,
                this.state.loading && classes.dataGridLoading
              )}
            >
              {this.state.data.length} {this.props.t('dataGrid.pageOf')}{' '}
              {this.state.totalItems} {this.props.t('dataGrid.results')}
            </span>
          )}

          {!this.props.noRefresh && (
            <div
              className={classNames(
                classes.dataGridNoRefresh,
                this.state.loading && classes.dataGridLoading
              )}
              onClick={() => this.fireFetchData()}
            >
              <Cached />
            </div>
          )}
          {this.props.additionalHeaderOptions &&
            this.props.additionalHeaderOptions.map(option => option)}
        </div>
        <ReactTable
          ref={this.grid}
          manual
          data={
            this.props.incjetedData || isEmpty(this.state.summaryRow)
              ? this.state.data
              : [...this.state.data, this.state.summaryRow]
          }
          pageSizeOptions={pageSizeOptions}
          loading={this.state.loading}
          columns={columnConfig}
          filterable={true}
          expanded={this.state.expandedRows ?? {}}
          onExpandedChange={e => this.setState({ expandedRows: e })}
          SubComponent={this.props.SubComponent}
          defaultSorted={defaultSorted}
          defaultFiltered={this.state.computedDefaultFilter}
          resizable={this.props.resizable || false}
          minRows={this.props.minRows || 10}
          defaultPageSize={
            this.props.fixedPageSize
              ? this.props.fixedPageSize
              : defaultPageSize
          }
          onFetchData={this.fetchDataWithTimeout}
          pages={this.state.pages}
          showPaginationTop={this.props.paginationTop}
          showPaginationBottom={this.props.paginationBottom}
          showPageSizeOptions={this.state.totalItems > defaultPageSize}
          className={this.getClassName()}
          previousText={this.props.t('dataGrid.prevPage')}
          nextText={this.props.t('dataGrid.nextPage')}
          loadingText={this.props.t('dataGrid.loading')}
          noDataText={this.props.t('dataGrid.notFound')}
          pageText={this.props.t('dataGrid.page')}
          ofText={this.props.t('dataGrid.pageOf')}
          rowsText={this.props.t('dataGrid.results')}
          getTdProps={(state, rowInfo, column, instance) => {
            let props = {
              onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };

            if (rowInfo && column) {
              props['data-cy'] = `my-table-cell-${rowInfo.index}-${column.id}`;
            }

            if (typeof this.props.getTdProps === 'function') {
              let givenProps = this.props.getTdProps(
                state,
                rowInfo,
                column,
                instance,
                this
              );
              props = { ...props, ...givenProps };
            }

            return props;
          }}
          getTrProps={(state, rowInfo, column) => {
            let props = {
              onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };

            if (rowInfo) {
              props['data-cy'] = `my-table-row-${rowInfo.index}`;
            }

            if (rowInfo?.original?.isSummary) {
              props = { ...props, style: { fontWeight: 'bold' } };
            }

            if (typeof this.props.getTrProps === 'function') {
              let givenProps = this.props.getTrProps(
                state,
                rowInfo,
                column,
                this
              );
              props = { ...props, ...givenProps };
            }

            return props;
          }}
          getTrGroupProps={this.props.getTrGroupProps}
        />
      </div>
    );
  }

  resolveDefaultFilter() {
    try {
      const queryParams = qs.parse(window.location.search);

      if (queryParams.filter) {
        return (
          JSON.parse(
            decodeURIComponent(escape(window.atob(queryParams.filter)))
          ) ||
          this.props.defaultFiltered ||
          []
        );
      }
    } catch (e) {
      console.error(e);
    }
    return this.props.defaultFiltered || [];
  }
}

DataGrid.propTypes = {
  url: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      accessor: PropTypes.isRequired,
    })
  ),
  addPath: PropTypes.string,
  editPath: PropTypes.string,
  btnText: PropTypes.string,
  title: PropTypes.string,
  refresh: PropTypes.bool,
  defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string),
  defaultFiltered: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  massDelete: PropTypes.bool,
  massDeleteHandleSingleCheckbox: PropTypes.func,
  massDeleteHandleMultipleCheckboxes: PropTypes.func,
  massDeleteItemsIds: PropTypes.array,
  striped: PropTypes.bool,
};

const enhance = compose(
  withTranslation(),
  withToast,
  withRouter,
  connect(
    ({
      Brands: {
        brand: { multinational },
      },
      Auth: {
        user: { id },
      },
    }) => ({ multinational, id })
  ),
  withStyles(buttonsStyle),
  withStyles(styles)
);

export default enhance(DataGrid);

import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { get } from 'helpers/apiHelpers';
import ConfirmNestedRecipes from './ConfirmNestedRecipes';
import ConfirmNestedDishes from './ConfirmNestedDishes';
import { useTranslation } from 'react-i18next';

const ConfirmNestedUpdatesModal = ({
  ingredientId,
  ingredientState,
  classes,
  onCancel,
  onSave,
}) => {
  const [loading, setLoading] = useState(true);
  const [updateRecipes, setUpdateRecipes] = useState(false);
  const [updateDishes, setUpdateDishes] = useState(false);
  const [dishes, setDishes] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const { t } = useTranslation();

  const fetchNestedData = async () => {
    setLoading(true);

    const recipesData = await get(
      '/recipes',
      {
        pagination: false,
        'ingredients.ingredient.id': ingredientId,
        properties: ['id', 'workName', 'ingredients'],
        'properties[ingredients]': [
          'id',
          'ingredient',
          'quantity',
          'workingOnMachining',
          'ingredient',
        ],
        'properties[ingredients][ingredient]': ['id', 'name'],
      },
      { disableLoader: true }
    );
    const dishesData = await get(
      '/dishes',
      {
        pagination: false,
        'components.ingredient.id': ingredientId,
        properties: ['id', 'nameForClient', 'workingName'],
      },
      { disableLoader: true }
    );

    setRecipes(
      recipesData['hydra:member'].map(recipe => {
        const currentWOM =
          recipe.ingredients.find(
            ri => ri.ingredient.id === parseInt(ingredientId)
          )?.workingOnMachining || 0;
        return {
          picked: false,
          id: recipe.id,
          name: recipe.workName,
          workingOnMachining: currentWOM,
          newWorkingOnMachining:
            currentWOM === parseFloat(ingredientState.__originalRecord.wom)
              ? ingredientState.wom
              : currentWOM,
        };
      })
    );
    setDishes(
      dishesData['hydra:member'].map(dish => ({ ...dish, picked: false }))
    );
    setLoading(false);

    if (
      dishesData['hydra:member'].length + recipesData['hydra:member'].length ===
      0
    ) {
      onSave({});
    }
  };

  useEffect(() => {
    fetchNestedData();
  }, []);

  if (loading) {
    return (
      <Container>
        <GridContainer>
          <GridItem style={{ margin: '0 auto' }}>
            <CircularProgress />
          </GridItem>
        </GridContainer>
      </Container>
    );
  }

  return (
    <Container>
      {recipes.length > 0 && (
        <>
          <GridContainer>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updateRecipes}
                    onChange={() => setUpdateRecipes(!updateRecipes)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  t(
                    'form.ingredients.womModal.updateRecipes',
                    '$$Zaktualizuj przepisy'
                  ) +
                  ` (${
                    !updateRecipes ? 0 : recipes.filter(el => el.picked).length
                  }/${recipes.length})`
                }
              />
            </GridItem>
          </GridContainer>
          {updateRecipes && (
            <GridContainer>
              <GridItem>
                <ConfirmNestedRecipes
                  recipes={recipes}
                  onUpdateRecipes={setRecipes}
                  classes={classes}
                />
              </GridItem>
            </GridContainer>
          )}
        </>
      )}
      {recipes.length > 0 && (
        <>
          <GridContainer>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updateDishes}
                    onChange={() => setUpdateDishes(!updateDishes)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  t(
                    'form.ingredients.womModal.updateDishes',
                    '$$Zaktualizuj dania'
                  ) +
                  ` (${
                    !updateDishes ? 0 : dishes.filter(el => el.picked).length
                  }/${dishes.length})`
                }
              />
            </GridItem>
          </GridContainer>
          {updateDishes && (
            <GridContainer>
              <GridItem>
                <ConfirmNestedDishes
                  dishes={dishes}
                  onUpdateDishes={setDishes}
                  classes={classes}
                />
              </GridItem>
            </GridContainer>
          )}
        </>
      )}
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Button onClick={onCancel}>{t('form.cancel')}</Button>
        </GridItem>
        <GridItem xs={12} sm={6} style={{ textAlign: 'right' }}>
          <Button
            color="success"
            onClick={() => {
              let data = {};

              if (updateRecipes) {
                data.recipes = recipes
                  .filter(el => el.picked)
                  .map(el => ({
                    id: el.id,
                    newWorkingOnMachining: el.newWorkingOnMachining,
                  }));
              }

              if (updateDishes) {
                data.dishes = dishes
                  .filter(el => el.picked)
                  .map(el => ({ id: el.id }));
              }

              onSave(data);
            }}
          >
            {t('common.shared.save')}
          </Button>
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default ConfirmNestedUpdatesModal;

const ChevronIcon = ({ title = '', fill = 'currentColor', ...props }) => (
  <svg
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <path
      d="M5.15474 4.49077C4.71819 4.95467 4.28166 4.98456 3.84511 4.49063L0.135633 0.788988C-0.0452108 0.608527 -0.0452108 0.31599 0.135633 0.13553C0.226387 0.0455451 0.344808 -2.43187e-05 0.462708 -2.43187e-05C0.581244 -2.43187e-05 0.699694 0.0449677 0.789869 0.13553L4.49981 3.83732L8.20946 0.13553C8.30021 0.0455451 8.41875 -2.43187e-05 8.5372 -2.43187e-05C8.65576 -2.43187e-05 8.7743 0.0449677 8.86439 0.13553C9.0452 0.31599 9.0452 0.608527 8.86439 0.788988L5.15474 4.49077Z"
      fill={fill}
    />
  </svg>
);

export default ChevronIcon;

import { useTranslation } from 'react-i18next';
import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

import EXTERNAL_URLS from 'constants/externalUrls';
import FAKTUROWNIA_INTEGRATION_TYPES from 'constants/fakturowniaIntegrationTypes';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';

const useStyles = makeStyles({
  inputGridItem: {
    height: '65px',
  },
  integrationTypeLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  noRightMargin: {
    marginRight: '0',
  },
  infoIcon: {
    marginLeft: '4px',
    fontSize: '16px',
  },
  gridItemsWrapper: {
    width: '100%',
    margin: '10px',
    border: '1px solid #cfc9c9',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    padding: '11px 14px 9px 0',
  },
});

const FakturowniaModule = ({
  classes: propClasses,
  handleModuleUpdate,
  module,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const integrationOptions = [
    {
      label: t(
        'module.Fakturownia.integrationOption.invoice',
        'Generuj faktury'
      ),
      value: FAKTUROWNIA_INTEGRATION_TYPES.invoice,
    },
    {
      label: t(
        'module.Fakturownia.integrationOption.advance',
        'Generuj zamówienia i faktury zaliczkowe'
      ),
      value: FAKTUROWNIA_INTEGRATION_TYPES.advance,
    },
    {
      label: t(
        'module.Fakturownia.integrationOption.receipt',
        'Generuj paragony'
      ),
      value: FAKTUROWNIA_INTEGRATION_TYPES.receipt,
    },
  ];

  const invoiceDatesOptions = [
    {
      label: t(
        'module.Fakturownia.invoiceDatesOptions.orderDate',
        'Data złożenia zamówienia'
      ),
      value: 'ORDER_DATE',
    },
    {
      label: t(
        'module.Fakturownia.invoiceDatesOptions.lastBagDate',
        'Data ostatniej torby'
      ),
      value: 'LAST_BAG_DATE',
    },
  ];

  const dietIntegrationModes = [
    {
      label: t('module.Fakturownia.dietIntegrationMode.DIET'),
      value: 'DIET',
    },
    {
      label: t('module.Fakturownia.dietIntegrationMode.BAGS'),
      value: 'BAGS',
    },
  ];

  const isIntegrationTypeDisabled =
    module.integrationType === FAKTUROWNIA_INTEGRATION_TYPES.disabled;

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'FakturowniaModule',
        })}
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                className={classes.noRightMargin}
                control={
                  <Checkbox
                    checked={module.allowInvoicesForClient}
                    onChange={() =>
                      handleModuleUpdate({
                        ...module,
                        allowInvoicesForClient: !module.allowInvoicesForClient,
                      })
                    }
                    checkedIcon={
                      <CheckIcon className={propClasses.checkedIcon} />
                    }
                    icon={<CheckIcon className={propClasses.uncheckedIcon} />}
                    classes={{
                      checked: propClasses.checked,
                      root: propClasses.checkRoot,
                    }}
                  />
                }
                label={t(
                  'brands.newBrandForm.showOption',
                  'Pokaż opcję wystawiania dokumentów księgowych na firmę'
                )}
              />
              <Tooltip
                title={
                  <h4>
                    {t(
                      'module.Fakturownia.addonsCustomName.showOptionInvoiceTooltip',
                      'Paragonów z NIP i/lub faktur.'
                    )}
                  </h4>
                }
                placement="top"
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                classes={{
                  label: classes.integrationTypeLabel,
                }}
                control={
                  <Checkbox
                    checked={
                      module.integrationType ===
                        FAKTUROWNIA_INTEGRATION_TYPES.advance ||
                      module.integrationType ===
                        FAKTUROWNIA_INTEGRATION_TYPES.invoice ||
                      module.integrationType ===
                        FAKTUROWNIA_INTEGRATION_TYPES.receipt
                    }
                    onChange={() => {
                      handleModuleUpdate({
                        ...module,
                        integrationType: isIntegrationTypeDisabled
                          ? FAKTUROWNIA_INTEGRATION_TYPES.invoice
                          : FAKTUROWNIA_INTEGRATION_TYPES.disabled,
                      });
                    }}
                    checkedIcon={
                      <CheckIcon className={propClasses.checkedIcon} />
                    }
                    icon={<CheckIcon className={propClasses.uncheckedIcon} />}
                    classes={{
                      checked: propClasses.checked,
                      root: propClasses.checkRoot,
                    }}
                  />
                }
                label={
                  <>
                    Fakturownia.pl
                    <InfoWithTooltip
                      title={t('brands.newBrandForm.fakturownia.systemConfig')}
                      link={EXTERNAL_URLS.invoiceConfig}
                      size="s"
                    />
                  </>
                }
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <GridContainer>
                <div className={classes.gridItemsWrapper}>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t('brands.newBrandForm.host', 'Host ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="host"
                      value={module.host}
                      maxLength={64}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          host: e.target.value,
                        })
                      }
                    />
                  </GridItem>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t('brands.newBrandForm.token', 'Token ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="token"
                      value={module.token}
                      maxLength={64}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          token: e.target.value,
                        })
                      }
                    />
                  </GridItem>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t('brands.newBrandForm.department', 'Departament ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="department"
                      value={module.department}
                      maxLength={64}
                      handleChange={e => {
                        if (!isNaN(e.target.value)) {
                          handleModuleUpdate({
                            ...module,
                            department: e.target.value,
                          });
                        }
                      }}
                    />
                  </GridItem>
                </div>

                <div className={classes.gridItemsWrapper}>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'module.Fakturownia.integrationType.label',
                        'Tryb integracji'
                      )}
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={propClasses}
                      mapBy="label"
                      trackBy="value"
                      name="integrationType"
                      value={module.integrationType}
                      options={integrationOptions}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          integrationType: e.target.value,
                        })
                      }
                      size={12}
                      disabled={isIntegrationTypeDisabled}
                    />
                  </GridItem>
                  {module.integrationType ===
                    FAKTUROWNIA_INTEGRATION_TYPES.receipt && (
                    <>
                      <GridItem className={classes.inputGridItem} md={12}>
                        <FormLabel className={propClasses.labelHorizontal}>
                          {t(
                            'brands.newBrandForm.minAmountInvoice',
                            'Kwota od której mają generować się faktury dla firm, zamiast paragonów z NIP-em'
                          )}
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={propClasses}
                          name="minAmountInvoice"
                          value={module.minAmountInvoice}
                          maxLength={64}
                          handleChange={e => {
                            if (!isNaN(e.target.value)) {
                              handleModuleUpdate({
                                ...module,
                                minAmountInvoice: e.target.value,
                              });
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem className={classes.inputGridItem} md={12}>
                        <FormControlLabel
                          className={classes.noRightMargin}
                          control={
                            <Checkbox
                              checked={module.autoPrint}
                              onChange={() =>
                                handleModuleUpdate({
                                  ...module,
                                  autoPrint: !module.autoPrint,
                                })
                              }
                              checkedIcon={
                                <CheckIcon
                                  className={propClasses.checkedIcon}
                                />
                              }
                              icon={
                                <CheckIcon
                                  className={propClasses.uncheckedIcon}
                                />
                              }
                              classes={{
                                checked: propClasses.checked,
                                root: propClasses.checkRoot,
                              }}
                            />
                          }
                          label={t(
                            'module.Fakturownia.integrationOption.autoPrint',
                            'Drukuj paragony automatycznie'
                          )}
                        />
                        <Tooltip
                          title={
                            <h5>
                              {t(
                                'module.Fakturownia.addonsCustomName.printReceiptAutomaticallyTooltip',
                                'W przypadku odpowiedniego połączenia fakturownia.pl z drukarką fiskalną, paragony będą drukować się automatycznie i na bieżąco.'
                              )}
                            </h5>
                          }
                          placement="top"
                        >
                          <InfoIcon className={classes.infoIcon} />
                        </Tooltip>
                      </GridItem>
                    </>
                  )}
                </div>

                {module.integrationType ===
                  FAKTUROWNIA_INTEGRATION_TYPES.invoice && (
                  <div className={classes.gridItemsWrapper}>
                    <GridItem className={classes.inputGridItem} md={12}>
                      <FormLabel className={propClasses.labelHorizontal}>
                        {t(
                          'module.Fakturownia.integrationType.invoiceIssueDateType',
                          'Data Wystawienia faktury'
                        )}
                      </FormLabel>
                      <SelectInput
                        noGrid
                        classes={propClasses}
                        mapBy="label"
                        trackBy="value"
                        name="invoiceIssueDateType"
                        value={module.invoiceIssueDateType}
                        options={invoiceDatesOptions}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            invoiceIssueDateType: e.target.value,
                          })
                        }
                        size={12}
                        disabled={isIntegrationTypeDisabled}
                      />
                    </GridItem>

                    <GridItem className={classes.inputGridItem} md={12}>
                      <FormLabel className={propClasses.labelHorizontal}>
                        {t(
                          'module.Fakturownia.integrationType.invoiceSellDateType',
                          'Data sprzedaży na fakturze'
                        )}
                      </FormLabel>
                      <SelectInput
                        noGrid
                        classes={propClasses}
                        mapBy="label"
                        trackBy="value"
                        name="invoiceSellDateType"
                        value={module.invoiceSellDateType}
                        options={invoiceDatesOptions}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            invoiceSellDateType: e.target.value,
                          })
                        }
                        size={12}
                        disabled={isIntegrationTypeDisabled}
                      />
                    </GridItem>
                  </div>
                )}

                <div className={classes.gridItemsWrapper}>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'module.Fakturownia.dietIntegrationMode.label',
                        'Tryb pozycji diety'
                      )}
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={propClasses}
                      mapBy="label"
                      trackBy="value"
                      name="dietIntegrationMode"
                      value={module.dietIntegrationMode}
                      options={dietIntegrationModes}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          dietIntegrationMode: e.target.value,
                        })
                      }
                      size={12}
                      disabled={isIntegrationTypeDisabled}
                    />
                  </GridItem>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.productName',
                        'Nazwa pozycji produktu (diety)'
                      )}

                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'brands.newBrandForm.typeOfDiet',
                              'Wartość % VAT jest pobierana z cennika rodzaju diety.'
                            )}
                          </h4>
                        }
                        placement="top"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </FormLabel>
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={module.dietItemNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            dietItemNameEnabled: !module.dietItemNameEnabled,
                          })
                        }
                        checkedIcon={
                          <CheckIcon className={propClasses.checkedIcon} />
                        }
                        icon={
                          <CheckIcon className={propClasses.uncheckedIcon} />
                        }
                        classes={{
                          checked: propClasses.checked,
                          root: propClasses.checkRoot,
                        }}
                      />
                      <FormTextInputNoGrid
                        classes={propClasses}
                        name="dietItemName"
                        value={module.dietItemName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            dietItemName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'module.Fakturownia.addonsCustomName.label',
                        'Nazwa pozycji dodatki'
                      )}
                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'module.Fakturownia.addonsCustomName.toltip',
                              'Wartość % VAT jest pobierana z dodatków.'
                            )}
                          </h4>
                        }
                        placement="top"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </FormLabel>
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={module.addonsCustomNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            addonsCustomNameEnabled:
                              !module.addonsCustomNameEnabled,
                          })
                        }
                        checkedIcon={
                          <CheckIcon className={propClasses.checkedIcon} />
                        }
                        icon={
                          <CheckIcon className={propClasses.uncheckedIcon} />
                        }
                        classes={{
                          checked: propClasses.checked,
                          root: propClasses.checkRoot,
                        }}
                      />
                      <FormTextInputNoGrid
                        classes={propClasses}
                        name="addonsCustomName"
                        value={module.addonsCustomName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            addonsCustomName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem className={classes.inputGridItem} xs={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.dishNameOnInvoice',
                        'Nazwy dań na fakturze'
                      )}
                    </FormLabel>
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={module.dishesCustomNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            dishesCustomNameEnabled:
                              !module.dishesCustomNameEnabled,
                          })
                        }
                        checkedIcon={
                          <CheckIcon className={propClasses.checkedIcon} />
                        }
                        icon={
                          <CheckIcon className={propClasses.uncheckedIcon} />
                        }
                        classes={{
                          checked: propClasses.checked,
                          root: propClasses.checkRoot,
                        }}
                      />
                      <FormTextInputNoGrid
                        classes={propClasses}
                        name="dishesCustomName"
                        value={module.dishesCustomName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            dishesCustomName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem className={classes.inputGridItem} xs={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.mealTypeNameOnInvoice',
                        'Nazwy dodatkowych posiłków na fakturze'
                      )}
                    </FormLabel>
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        className={classes.checkbox}
                        checked={module.mealTypesCustomNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            mealTypesCustomNameEnabled:
                              !module.mealTypesCustomNameEnabled,
                          })
                        }
                        checkedIcon={
                          <CheckIcon className={propClasses.checkedIcon} />
                        }
                        icon={
                          <CheckIcon className={propClasses.uncheckedIcon} />
                        }
                        classes={{
                          checked: propClasses.checked,
                          root: propClasses.checkRoot,
                        }}
                      />
                      <FormTextInputNoGrid
                        classes={propClasses}
                        name="mealTypesCustomName"
                        value={module.mealTypesCustomName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            mealTypesCustomName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>
                </div>
              </GridContainer>
            </GridItem>

            <GridItem md={6}>
              <GridContainer>
                <div className={classes.gridItemsWrapper}>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.deliveryItem',
                        'Nazwa pozycji dostawy'
                      )}
                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'brands.newBrandForm.zoneDelivery',
                              'Wartość % VAT jest pobierana z cennika dostawy '
                            )}
                          </h4>
                        }
                        placement="top"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="deliveryItemName"
                      value={module.deliveryItemName}
                      maxLength={255}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          deliveryItemName: e.target.value,
                        })
                      }
                    />
                  </GridItem>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.dietDayMod',
                        'Nazwa pozycji "modyfikacji dnia diety"'
                      )}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="bagModificationName"
                      value={module.bagModificationName}
                      maxLength={255}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          bagModificationName: e.target.value,
                        })
                      }
                    />
                  </GridItem>
                </div>
                <div className={classes.gridItemsWrapper}>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'module.Fakturownia.bagModificationVat.label',
                        'Stawka VAT pozycji "modyfikacji dnia diety"'
                      )}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="bagModificationVat"
                      value={module.bagModificationVat}
                      maxLength={255}
                      handleChange={e => {
                        if (!isNaN(e.target.value)) {
                          handleModuleUpdate({
                            ...module,
                            bagModificationVat: e.target.value,
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormLabel className={propClasses.labelHorizontal}>
                      {t(
                        'module.Fakturownia.shopDishVat.label',
                        'Stawka VAT dla dań sklepowych'
                      )}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={propClasses}
                      name="dishesVat"
                      value={module.dishesVat}
                      maxLength={255}
                      handleChange={e => {
                        if (!isNaN(e.target.value)) {
                          handleModuleUpdate({
                            ...module,
                            dishesVat: e.target.value,
                          });
                        }
                      }}
                    />
                  </GridItem>
                </div>
                <div className={classes.gridItemsWrapper}>
                  <GridItem className={classes.inputGridItem} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={module.showMoneyboxOnInvoice}
                          onChange={() =>
                            handleModuleUpdate({
                              ...module,
                              showMoneyboxOnInvoice:
                                !module.showMoneyboxOnInvoice,
                            })
                          }
                          checkedIcon={
                            <CheckIcon className={propClasses.checkedIcon} />
                          }
                          icon={
                            <CheckIcon className={propClasses.uncheckedIcon} />
                          }
                          classes={{
                            checked: propClasses.checked,
                            root: propClasses.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brands.newBrandForm.fakturownia.addCommentWithAmountOfUsedMoneyboxPoints',
                        'W komentarzu do faktury podaj liczbę zużytych punktów do skarbonki'
                      )}
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={module.mergeSamePositions}
                          onChange={() =>
                            handleModuleUpdate({
                              ...module,
                              mergeSamePositions: !module.mergeSamePositions,
                            })
                          }
                          checkedIcon={
                            <CheckIcon className={propClasses.checkedIcon} />
                          }
                          icon={
                            <CheckIcon className={propClasses.uncheckedIcon} />
                          }
                          classes={{
                            checked: propClasses.checked,
                            root: propClasses.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brands.newBrandForm.mergeSamePositions',
                        'Scalaj tak samo nazwane pozycje w jedną'
                      )}
                    />
                  </GridItem>
                </div>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

export default FakturowniaModule;

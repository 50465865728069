import React from 'react';
import AdminTable from 'layouts/AdminTable';

import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LogView from 'components/History/LogView';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  adminTableTitleParent: { display: 'flex', alignItems: 'center' },
  tooltipIcon: {
    color: 'RGB(130,130,130)',
  },
  displayFlex: {
    display: 'flex',
  },
  tooltip: { marginLeft: '10px' },
  gridItem: { marginLeft: '-15px' },
  marginTop: { marginTop: '20px' },
  spanTooltip: { fontSize: '14px', lineHeight: '22px' },
}));

const FormFiltersView = ({ props }) => {
  const {
    t,
    packages,
    classes: propClasses,
    state,
    isLoading,
    isEdit,
    filterLineDietsId,
    handleInputChange,
    handleSelect,
    getImage,
    removeImage,
    handleToggle,
    handleSubmit,
  } = props;
  const classes = useStyles();
  return (
    <>
      {isLoading && (
        <AdminTable
          title={
            <span className={classes.adminTableTitleParent}>
              {isEdit ? t('form.editFilter') : t('form.addNewFilter')}

              <span>
                <Tooltip
                  title={
                    <span className={classes.spanTooltip}>
                      {t('list.dietsLineFiltersList.tooltip')}
                    </span>
                  }
                >
                  <Info className={classes.tooltipIcon} size={20} />
                </Tooltip>
              </span>
            </span>
          }
        >
          <FormTextInput
            label={t('form.field.filterName') + '*'}
            classes={propClasses}
            name="name"
            value={state.name}
            handleChange={handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={t('form.field.order') + '*'}
            classes={propClasses}
            name="position"
            value={state.position}
            type="number"
            handleChange={handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={t('form.field.description')}
            classes={propClasses}
            name="description"
            value={state.description}
            handleChange={handleInputChange}
            inputSize={6}
            multiline={true}
            rows={3}
            rowsMax={20}
            maxLength={2000}
          />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('form.field.active.label')}
                <div className={classes.tooltip}>
                  <Tooltip
                    title={
                      <div>
                        <span className={classes.spanTooltip}>
                          {t('form.activeInfo.filterLineDiets')}
                        </span>
                        <span className={classes.spanTooltip}>
                          <ul
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'form.activeInfo.filterLineDiets.point'
                              ),
                            }}
                          />
                        </span>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    name="active"
                    onClick={handleToggle}
                    checked={state.active}
                    checkedIcon={<Check className={propClasses.checkedIcon} />}
                    icon={<Check className={propClasses.uncheckedIcon} />}
                    classes={{
                      checked: propClasses.checked,
                      root: propClasses.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: propClasses.label,
                }}
                label={''}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <SelectInput
                name="packages"
                multiple
                classes={propClasses}
                label={
                  <div className={classes.displayFlex}>
                    <span>{t('form.field.filterLineDiets')} *</span>
                    <div className={classes.tooltip}>
                      <Tooltip
                        title={
                          <div>
                            <span className={classes.spanTooltip}>
                              {t('form.filterLineDietsPackageInfo')}
                            </span>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
                mapBy="name"
                trackBy="@id"
                value={state.packages}
                options={packages.map(pack => {
                  return pack.active
                    ? pack
                    : {
                        ...pack,
                        name: `${pack.name} (${t('form.inactive')})`,
                      };
                })}
                handleChange={handleSelect}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.marginTop}>
            <GridItem xs={12} sm={2}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('form.field.filterPhoto')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={5} className={classes.gridItem}>
              <FormImageUpload
                classes={propClasses}
                stateName="image"
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={state.imageUrl}
              />
            </GridItem>
            <GridItem xs={12} sm={5} className={classes.gridItem}>
              <FormImageUpload
                buttonText={t('form.field.selectIcon')}
                isIcon
                classes={propClasses}
                stateName="mainImage"
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={state.mainImageUrl}
              />
            </GridItem>
          </GridContainer>
          <FormControlButtons
            classes={propClasses}
            discardText={t('form.cancel')}
            submitText={t('form.save')}
            cancelPath="/admin/filter-line-diets"
            handleSubmit={handleSubmit}
          />
        </AdminTable>
      )}
      {isEdit && <LogView iri={`/package-line-filters/${filterLineDietsId}`} />}
    </>
  );
};

export default FormFiltersView;

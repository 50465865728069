import types from 'helpers/constants';

const initialState = {
  orderStatuses: [],
  loading: false,
};

function MealTypes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ORDERSTATUSES:
      return {
        ...state,
        orderStatuses: action.payload.orderStatuses,
      };
    case types.START_LOADING_ORDER_STATUSES:
      return {
        ...state,
        loading: true,
      };
    case types.FINISH_LOADING_ORDER_STATUSES:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default MealTypes;

import React, { Component } from 'react';
import PreparationInstructionComponent from './PreparationInstructionComponent';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { withTranslation } from 'react-i18next';

class PreparationInstructions extends Component {
  state = {
    step1: {
      step: 1,
      title: '',
      description: '',
      pdfImage: null,
    },
    step2: {
      step: 2,
      title: '',
      description: '',
      pdfImage: null,
    },
    step3: {
      step: 3,
      title: '',
      description: '',
      pdfImage: null,
    },
    step4: {
      step: 4,
      title: '',
      description: '',
      pdfImage: null,
    },
    step5: {
      step: 5,
      title: '',
      description: '',
      pdfImage: null,
    },
    step6: {
      step: 6,
      title: '',
      description: '',
      pdfImage: null,
    },
  };

  componentDidMount() {
    if (this.props.dish.clientSteps) {
      const steps = this.props.dish.clientSteps.reduce((prev, curr) => {
        return {
          ...prev,
          [`step${curr.step}`]: {
            ...curr,
            pdfImage: curr.pdfImage ? curr.pdfImage['@id'] : null,
            pdfImageUrl: curr.pdfImage ? curr.pdfImage.contentUrl : null,
          },
        };
      }, {});
      this.setState({ ...steps });
    }
  }

  handleTextChange = step => event => {
    const innerKey = event.target.name;
    const outerKey = `step${step}`;
    const value = event.target.value;

    this.setState(
      {
        [outerKey]: {
          ...this.state[outerKey],
          [innerKey]: value,
        },
      },
      () => this.props.setClientsSteps(this.state)
    );
  };

  getImage = (stateName, data) => {
    const currentState = {
      ...this.state[stateName],
      pdfImage: data['@id'] || null,
      pdfImageUrl: data.contentUrl,
    };
    this.setState({ [stateName]: currentState }, () =>
      this.props.setClientsSteps(this.state)
    );
  };

  removeImage = stateName => {
    this.setState(
      prevState => ({
        [stateName]: {
          ...prevState[stateName],
          pdfImage: null,
          pdfImageUrl: null,
        },
      }),
      () => this.props.setClientsSteps(this.state)
    );
  };

  render() {
    const steps = Object.values(this.state);

    return (
      <>
        <GridContainer>
          {steps.map((step, index) => {
            return (
              <GridItem xs={6}>
                <PreparationInstructionComponent
                  label={`${this.props.t('common.step', 'Step')} ${step.step}`}
                  classes={this.props.classes}
                  stateName={'productionImage'}
                  getImage={this.getImage}
                  removeImage={this.removeImage}
                  handleChange={this.handleTextChange(step.step)}
                  step={this.state[`step${step.step}`]}
                  t={this.props.t}
                />
              </GridItem>
            );
          })}
        </GridContainer>
      </>
    );
  }
}

export default withTranslation()(PreparationInstructions);

import React from 'react';
import FormImageUpload from '../../components/FormImageUpload/FormImageUpload';
import Card from '../../components/Card/Card';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import LettersCounter from 'views/PagesCreator/LettersCounter';

const PreparationInstructionComponent = props => {
  const previewUrl = props.step.pdfImageUrl;
  return (
    <Card>
      <FormImageUpload
        label={props.label}
        classes={props.classes}
        stateName={`step${props.step.step}`}
        getImage={props.getImage}
        removeImage={props.removeImage}
        previewUrl={previewUrl}
      />
      <div style={{ margin: '18px' }}>
        <FormTextInput
          noGrid
          classes={props.classes}
          name="title"
          value={props.step.title}
          handleChange={props.handleChange}
          label={props.t('form.title', 'Title')}
          maxLength={160}
        />
        <FormTextInput
          noGrid
          classes={props.classes}
          name="description"
          value={props.step.description}
          handleChange={props.handleChange}
          rows={3}
          rowsMax={20}
          maxLength={5000}
          label={
            <LettersCounter
              label={props.t('form.field.description', 'Description')}
              wordsCount={props.step.description.length}
              colorsConfig={[
                {
                  minValue: 2,
                  maxValue: 200,
                  color: 'green',
                },
                {
                  minValue: 200,
                  maxValue: 249,
                  color: 'orange',
                },
                { minValue: 249, color: 'red' },
              ]}
            />
          }
          multiline
        />
      </div>
    </Card>
  );
};

export default PreparationInstructionComponent;

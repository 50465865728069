import React from 'react';
import { connect } from 'react-redux';
import AdminTable from 'layouts/AdminTable';
import Moment from 'moment';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { fetchMealTypes } from 'actions/MealTypes';
import { fetchVariants } from 'actions/Variants';
import { fetchDiets } from 'actions/Diets';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import SummaryContent from './SummaryContent';
import axios from 'helpers/gastro';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { isGranted } from '../../../helpers/helpers';
import { ROLE_SHOW_MENU_SUMMARY } from '../../../helpers/roles';
import { useEffect } from 'react';
import { useState } from 'react';
import TOAST_DURATIONS from 'helpers/toastDurations';

const SummaryV2 = ({
  variants: givenVariants,
  diets,
  classes,
  t,
  match,
  brand,
  openToast,
  variants,
  history,
  fetchMealTypes,
  fetchVariants,
  fetchDiets,
}) => {
  const [state, setState] = useState({
    date: new Moment().startOf('week'),
    variant: '',
    lockedExport: false,
    loading: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchMealTypes(), fetchVariants(), fetchDiets()]);

      if (typeof match.params.date !== 'undefined') {
        await setState(prevState => {
          return {
            ...prevState,
            date: new Moment(match.params.date).startOf('week'),
          };
        });
      }

      if (typeof match.params.variant !== 'undefined') {
        const variant = variants.find(
          variant => variant.id === match.params.variant
        );
        if (variant) {
          await setState(prevState => {
            return {
              ...prevState,
              variant,
            };
          });
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const selectedDate = state?.date?.format('YYYY-MM-DD');
    history.replace(
      `/admin/seccond-menu-summary/${selectedDate}/${state.variant?.id}`
    );
  }, [state.variant, state.date, history]);

  const getLastDay = () => {
    return new Moment(state.date.format('YYYY-MM-DD')).endOf('week');
  };

  const getArrayOfDays = () => {
    let array = [];

    array.push(state?.date?.format('YYYY-MM-DD'));
    for (let i = 1; i < 7; i++) {
      const date = new Moment(state?.date?.format('YYYY-MM-DD')).add('days', i);
      const key = date.locale('en').format('dddd').toLowerCase();

      if (brand[key] !== 0) {
        array.push(date.format('YYYY-MM-DD'));
      }
    }

    return array;
  };

  const modifyDate = async (add = true) => {
    await setState(prevState => {
      return {
        ...prevState,
        date: prevState.date.add(add ? 7 : -7, 'days'),
      };
    });
    match.params.date = state.date.format('YYYY-MM-DD');
    if (state.variant !== '') {
      history.replace(
        `/admin/seccond-menu-summary/${state.date.format('YYYY-MM-DD')}/${
          state.variant.id
        }`
      );
    } else {
      history.replace(
        `/admin/seccond-menu-summary/${state.date.format('YYYY-MM-DD')}`
      );
    }
  };

  const handleSelectChange = (event, value) => {
    setState(prev => {
      return {
        ...prev,
        variant: value,
      };
    });
  };

  const exportToPDF = date => {
    setState(prevState => ({ ...prevState, lockedExport: true }));

    const params = {
      'date[after]': getArrayOfDays()[0],
      'date[before]': getArrayOfDays()[6],
      variant: state.variant.id,
    };

    axios
      .get('reports/menu-planner', {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/pdf' },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Planowanie-menu-${getArrayOfDays()[0]}-${
              getArrayOfDays()[6]
            }_(${moment().format('YYYY-MM-DD_HH.mm')}).pdf`
          );
          document.body.appendChild(link);
          link.click();
          setState(prevState => ({ ...prevState, lockedExport: false }));
        },
        error => {
          openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          setState(prevState => ({ ...prevState, lockedExport: false }));
        }
      );
  };
  const exportToXLSX = date => {
    setState(prevState => ({ ...prevState, lockedExport: true }));

    const params = {
      'date[after]': getArrayOfDays()[0],
      'date[before]': getArrayOfDays()[6],
      variant: state.variant.id,
      weekView: true,
    };

    axios
      .get('reports/menu-planner', {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/vnd.ms-excel' },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Planowanie-menu-${getArrayOfDays()[0]}-${
              getArrayOfDays()[6]
            }_(${moment().format('YYYY-MM-DD_HH.mm')}).xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setState(prevState => ({ ...prevState, lockedExport: false }));
        },
        error => {
          openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          setState(prevState => ({ ...prevState, lockedExport: false }));
        }
      );
  };

  const filteredDiets = state.variant
    ? diets.filter(diet => state.variant.diets.includes(diet['@id']))
    : [];
  return isGranted(ROLE_SHOW_MENU_SUMMARY) ? (
    <AdminTable title={t('form.menuPlanning.menuSummary')}>
      <GridContainer>
        <GridItem md={4} style={{ textAlign: 'right' }}>
          <Button
            color="info"
            round
            onClick={() => {
              modifyDate(false);
            }}
            disabled={state.loading}
          >
            <KeyboardArrowLeft /> {t('form.menuPlanning.prevWeek')}
          </Button>
        </GridItem>
        <GridItem md={4} style={{ textAlign: 'center' }}>
          {state.loading && (
            <GridItem md={12} style={{ textAlign: 'center' }}>
              <CircularProgress />
            </GridItem>
          )}
          {!state.loading && state.date && (
            <h4>
              {state.date.format('DD')}-{getLastDay().format('DD')}{' '}
              {getLastDay().format('MMMM')} {getLastDay().format('YYYY')}
            </h4>
          )}
          <FormSelectSingle
            classes={classes}
            options={givenVariants.map(v => {
              if (!v.active) {
                v.displayName = v.name + t('form.menuPlanning.inactive');
              } else {
                v.displayName = v.name;
              }

              return v;
            })}
            mapBy="displayName"
            trackBy="@id"
            value={state.variant}
            handleChange={handleSelectChange}
            label={t('form.menuPlanning.selectVariant')}
          />
        </GridItem>
        <GridItem md={4} style={{ textAlign: 'left' }}>
          <Button
            color="info"
            round
            onClick={() => {
              modifyDate(true);
            }}
            disabled={state.loading}
          >
            {t('form.menuPlanning.nextWeek')}
            <KeyboardArrowRight />
          </Button>
        </GridItem>
      </GridContainer>
      {filteredDiets.length > 0 && !state.loading && (
        <GridContainer>
          <GridItem md={12} style={{ textAlign: 'center' }}>
            {state.lockedExport && (
              <GridItem md={12} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </GridItem>
            )}
            {!state.lockedExport && (
              <Button
                color="primary"
                round
                onClick={() => {
                  exportToPDF();
                }}
                disabled={state.loading}
              >
                {t('form.menuPlanning.saveWeekPdf')}
              </Button>
            )}
            {!state.lockedExport && (
              <Button
                color="primary"
                round
                onClick={() => {
                  exportToXLSX();
                }}
                disabled={state.loading}
              >
                {t('form.menuPlanning.saveWeekExcel')}
              </Button>
            )}
          </GridItem>
        </GridContainer>
      )}
      <div style={{ overflow: 'auto' }}>
        <SummaryContent
          date={state.date}
          variant={state.variant}
          filteredDiets={filteredDiets}
          dates={getArrayOfDays()}
          loading={state.loading}
        />
      </div>
    </AdminTable>
  ) : null;
};

const mapStateToProps = state => {
  return {
    variants: state.Variants.variants,
    diets: state.Diets.diets,
    brand: state.Brands.brand,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMealTypes: () => dispatch(fetchMealTypes()),
  fetchVariants: () => dispatch(fetchVariants()),
  fetchDiets: () => dispatch(fetchDiets()),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(buttonsStyle)(SummaryV2))
);

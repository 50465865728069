import styled from 'styled-components';

import { grayColor, navyColor } from 'assets/jss/material-dashboard-pro-react';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: ${`1px solid ${grayColor[4]}`};
`;

const BoxSectionTitle = styled.h3`
  color: ${props => props.color};
  margin: 0;
  font-weight: 500;
`;

const BoxSectionContainer = styled.div`
  width: 100%;
  margin: ${props => props?.margin ?? 0};
  padding: 15px;
  border: ${`1px solid ${grayColor[4]}`};
  border-radius: 5px;
`;

const BoxSection = ({
  title = '',
  children,
  style = {},
  headerRightCornerComponent,
}) => {
  return (
    <BoxSectionContainer margin={style?.margin}>
      <Header>
        <BoxSectionTitle color={navyColor}>{title}</BoxSectionTitle>
        {headerRightCornerComponent}
      </Header>
      {children}
    </BoxSectionContainer>
  );
};

export default BoxSection;

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';

import { isGranted } from 'helpers/helpers';
import {
  ROLE_EDIT_FEED,
  ROLE_EXPORT_FEED,
  ROLE_CREATE_FEED,
  ROLE_REMOVE_FEED,
} from 'helpers/roles';

import PRODUCT_FEED_FORMATS from '../consts/productFeedFormats';

const columnConfig = t => [
  {
    title: '$*productFeedForm.domain',
    accessor: 'domain',
    name: 'domain',
    filterable: true,
    sortable: true,
  },
  {
    title: '$*productFeedForm.format',
    accessor: row => {
      const formatOption = PRODUCT_FEED_FORMATS.find(
        ({ value }) => value === row.format
      );
      return formatOption?.label;
    },
    name: 'format',
    Filter: ({ onChange, filter }) => (
      <select
        style={{ height: '30px', width: '100%', paddingLeft: '0' }}
        onChange={e => onChange(e.target.value)}
        value={filter?.value || ''}
      >
        <option value="">{t('clients.all')}</option>
        {PRODUCT_FEED_FORMATS.map(({ label, value }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    ),
    sortable: false,
  },
  {
    title: '$*productFeedForm.enableDiets',
    accessor: row => <BooleanView value={row.enableDiets} />,
    name: 'enableDiets',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
  },
  {
    title: '$*productFeedForm.enableAddons',
    accessor: row => <BooleanView value={row.enableAddons} />,
    name: 'enableAddons',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
  },
  {
    title: '$*productFeedForm.link',
    accessor: row => (
      <a
        href={`${process.env.REACT_APP_API_ENDPOINT}v2/frontend/feed/${row?.id}.xml`}
        target="_blank"
        rel="noreferrer"
      >
        {t('$*common.lookHere', '$$Zobacz tutaj')}
      </a>
    ),
    name: 'feedLink',
    filterable: false,
    sortable: false,
  },
];

const ProductFeedModule = ({ classes, t, history }) => {
  const canEdit = isGranted(ROLE_EDIT_FEED);
  const canCreate = isGranted(ROLE_CREATE_FEED);
  const canRemove = isGranted(ROLE_REMOVE_FEED);
  const canExport = isGranted(ROLE_EXPORT_FEED);

  return (
    <Card>
      <CardBody>
        {canCreate && (
          <FormControlButtons
            classes={classes}
            handleSubmit={() => history.push(`${window.location.pathname}/add`)}
            submitText={`${t('common.mainMenu.listAdd')} +`}
          />
        )}
        <DataGrid
          actions={true}
          remove={canRemove}
          export={canExport}
          paginationTop={true}
          paginationBottom={false}
          url="/feeds"
          editPath={canEdit ? `${window.location.pathname}/edit` : null}
          reportName={'feed'}
          columns={columnConfig(t)}
          role="FEED"
        />
      </CardBody>
    </Card>
  );
};

export default connect(
  ({ Brands: { brandKeys } }) => ({ brandKeys }),
  null
)(withStyles(extendedFormsStyle)(withTranslation()(ProductFeedModule)));

import ImageUpload from 'components/CustomUpload/ImageUpload';
import GridItem from 'components/Grid/GridItem';
import gastro from 'helpers/gastro';
import { useEffect, useState } from 'react';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { isGranted } from 'helpers/helpers';
const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}/uploaded-media/`;

const RowImageUpload = ({
  row,
  updateFunction,
  customButton,
  roleEdit,
  customButtonAddIcon,
  customButtonRemoveIcon,
  isEdit,
  setIsEdit,
}) => {
  const [dish, setDish] = useState(row);
  const responsibleWidthDialogModal = useMediaQuery('(max-width:1300px)');

  useEffect(() => {
    setDish(row);
  }, []);

  useEffect(() => {
    if (
      dish.clientImage !== row?.clientImage ||
      (dish.clientImage === null && isEdit)
    ) {
      updateFunction(dish);
    }

    if (isEdit && dish.clientImage === null) {
      setIsEdit(false);
    }
  }, [dish]);

  const uploadImage = async (state, image) => {
    let data = new FormData();
    data.append('file', image);
    const response = await gastro.post('/media', data);
    getImage(state, response.data);
  };

  const getImage = (stateName, data) => {
    setDish(prev => {
      return {
        ...prev,
        contentUrl: data.contentUrl,
        clientImageIri: data['@id'],
        clientImage: data.id,
      };
    });
  };

  const removeImage = stateName => {
    setDish(prev => {
      return {
        ...prev,
        contentUrl: null,
        clientImageIri: null,
      };
    });
  };

  return (
    <GridItem xs={12}>
      <ImageUpload
        isIcon={true}
        isIconButton={true}
        changeButton={false}
        responsibleWidthDialogModal={responsibleWidthDialogModal}
        stateName={'clientImage'}
        getImage={uploadImage}
        removeImage={removeImage}
        file={null}
        previewUrl={
          dish?.contentUrl
            ? `${uploadedContentBaseURL}${dish?.contentUrl}`
            : null
        }
        changeButtonProps={{
          color: 'info',
          round: false,
        }}
        customImage={isGranted(roleEdit) && customButton}
        customButtonAddIcon={customButtonAddIcon}
        customButtonRemoveIcon={customButtonRemoveIcon}
        isDisabled={false}
      />
    </GridItem>
  );
};

export default RowImageUpload;

export const columnConfigFattyAcids = [
  {
    title: 'form.field.ingredient.n6',
    type: 'number',
    name: 'n6',
    unit: 'g',
  },
  {
    title: 'form.field.ingredient.n3',
    type: 'number',
    name: 'n3',
    unit: 'g',
  },
  {
    title: 'form.field.ingredient.relationN3N6',
    type: 'number',
    name: 'n3ToN6Rate',
    unit: 'g',
  },
  {
    title: 'form.field.ingredient.epa',
    type: 'number',
    name: 'epa',
    unit: 'g',
  },
  {
    title: 'form.field.ingredient.dpa',
    type: 'number',
    name: 'dpa',
    unit: 'g',
  },
  {
    title: 'form.field.ingredient.dha',
    type: 'number',
    name: 'dha',
    unit: 'g',
  },
  {
    title: 'form.field.ingredient.cholesterol',
    type: 'number',
    name: 'cholesterol',
    unit: 'mg',
  },
];

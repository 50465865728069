import React, { useEffect, useState } from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import { get, put } from '../../../helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../../components/DialogLoader';
import Banner from './Banner';
import Description from './Description';
import Diets from './Diets';
import BestDishes from './BestDishes';
import isEmpty from 'lodash/isEmpty';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import { withToast } from 'material-ui-toast-redux';
import PresentationUrl from 'components/CMS/PresentationUrl';
import HtmlContent from './HtmlContent';
import CardWrapper from 'components/Card/CardWrapper';
import { commonInitialBenefitState } from './commonInitialBenefitState';
import TOAST_DURATIONS from 'helpers/toastDurations';

const commonInitialState = {
  enabled: false,
  content: '',
};

const initialBannerState = {
  ...commonInitialState,
  heading: '',
  desktop: null,
  mobile: null,
};

const initialDescriptionState = { ...commonInitialState, title: '' };

const initialBenefitsState = {
  benefit1: {
    no: 1,
    ...commonInitialBenefitState,
  },
  benefit2: {
    no: 2,
    ...commonInitialBenefitState,
  },
  benefit3: {
    no: 3,
    ...commonInitialBenefitState,
  },
};

const initialDietsState = {
  ...commonInitialState,
  title: '',
  showRating: false,
  showPrice: false,
};

const initialBestDishesState = initialDietsState;

const getBannerSubmitData = banner => {
  return {
    ...banner,
    desktop: banner.desktop?.['@id'] ?? null,
    mobile: banner.mobile?.['@id'] ?? null,
  };
};

const HomeCmsPage = ({ classes, t, selectedBrand, openToast }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [enabledStateWithApi, setEnabledStateWithApi] = useState(false);
  const [zones, setZones] = useState({ enabled: false });
  const [benefits, setBenefits] = useState(initialBenefitsState);
  const [id, setId] = useState('');
  const [bannerOne, setBannerOne] = useState(initialBannerState);
  const [bannerTwo, setBannerTwo] = useState(initialBannerState);
  const [bannerThree, setBannerThree] = useState(initialBannerState);
  const [bannerFour, setBannerFour] = useState(initialBannerState);
  const [description, setDescription] = useState(initialDescriptionState);
  const [diets, setDiets] = useState(initialDietsState);
  const [bestDishes, setBestDishes] = useState(initialBestDishesState);
  const [module, setModule] = useState({});
  const [htmlContent, setHtmlContent] = useState(commonInitialState);

  // const toggleZonesShow = () => {
  //   setZones(prev => ({ ...prev, enabled: !prev.enabled }));
  // };

  useEffect(() => {
    const fetchData = async () => {
      const { homePage } = await get(`brands/${selectedBrand}/home-page`);
      const modulesConfig = await get(`brands/${selectedBrand}/modules`);
      setModule(modulesConfig);
      setId(homePage?.['@id'] ?? '');
      setEnabled(homePage?.enabled ?? false);
      setEnabledStateWithApi(homePage?.enabled ?? false);
      setZones(homePage?.zones ?? { enabled: false });
      setBannerOne(homePage?.bannerOne ?? initialBannerState);
      setBannerTwo(homePage?.bannerTwo ?? initialBannerState);
      setBannerThree(homePage?.bannerThree ?? initialBannerState);
      setBannerFour(homePage?.bannerFour ?? initialBannerState);
      setDescription(homePage?.description ?? initialDescriptionState);
      setDiets(homePage?.diets ?? initialDietsState);
      setBestDishes(homePage?.bestDishes ?? initialBestDishesState);
      setHtmlContent({
        enabled: homePage?.contentEnabled ?? false,
        content: homePage?.content ?? '',
      });

      if (homePage?.benefits?.length > 0) {
        const benefits = homePage.benefits
          .sort((a, b) => a.no - b.no)
          .filter(
            benefit => !(!benefit.title && !benefit.content && !benefit.image)
          )
          .reduce((prev, curr) => {
            return {
              ...prev,
              [`benefit${curr.no}`]: {
                ...curr,
                image: curr.image ? curr.image['@id'] : null,
                imageUrl: curr.image ? curr.image.contentUrl : null,
              },
            };
          }, {});

        setBenefits(benefits);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { homePage } = await get(`brands/${selectedBrand}/home-page`);
      setEnabledStateWithApi(homePage?.enabled ?? false);
    };
    fetchData();
  }, [isSubmitting]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    put(`brands/${selectedBrand}/modules`, {
      configuration: {
        SeoCmsMainSite: module.configuration.SeoCmsMainSite,
      },
    }).then(response => {
      openToast({
        messages: [
          t('status.success.save', 'Zmiany zostały pomyślnie zapisane.'),
        ],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      setIsSubmitting(false);
    });

    try {
      await put(`brands/${selectedBrand}/home-page`, {
        homePage: {
          ...(!isEmpty(id) ? { '@id': id } : {}),
          enabled,
          bannerOne: getBannerSubmitData(bannerOne),
          bannerTwo: getBannerSubmitData(bannerTwo),
          bannerThree: getBannerSubmitData(bannerThree),
          bannerFour: getBannerSubmitData(bannerFour),
          description,
          benefits: Object.values(benefits),
          diets,
          bestDishes,
          zones,
          content: htmlContent.content,
          contentEnabled: htmlContent.enabled,
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!enabled) {
      setHtmlContent(prev => ({ ...prev, enabled: false }));
    }
  }, [enabled]);

  useEffect(() => {
    if (htmlContent.enabled) {
      setEnabled(true);
    }
  }, [htmlContent.enabled]);

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <CardWrapper title={t('form.basicInfo')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setEnabled(!enabled)}
                    checked={enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={<div>{t('cms.homePage.showHomePage')} </div>}
              />
            </GridItem>
            {enabled && (
              <GridItem xs={12}>
                <PresentationUrl
                  subpage="/"
                  cmsShowActionButton={enabledStateWithApi}
                />
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </CardWrapper>

      {enabled && <HtmlContent state={htmlContent} setState={setHtmlContent} />}
      {!htmlContent.enabled && enabled && (
        <>
          <Banner number={1} state={bannerOne} setState={setBannerOne} />
          <Description
            state={description}
            setState={setDescription}
            benefitsState={benefits}
            setBenefitsState={setBenefits}
          />
          <Diets state={diets} setState={setDiets} />
          <BestDishes state={bestDishes} setState={setBestDishes} />
          <Banner number={2} state={bannerTwo} setState={setBannerTwo} />
          <Banner number={3} state={bannerThree} setState={setBannerThree} />
          <Banner number={4} state={bannerFour} setState={setBannerFour} />
        </>
      )}

      {/* <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('cms.homePage.zones.title')}</h1>

              <FormControlLabel
                control={
                  <Checkbox
                    onClick={toggleZonesShow}
                    checked={zones.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={<div>{t('cms.homePage.zones.show')} </div>}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card> */}

      {/* SEO */}
      {module?.configuration?.SeoCmsMainSite && enabled && (
        <SEOSettingsCms
          state={module}
          setState={setModule}
          section="SeoCmsMainSite"
        />
      )}
      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(HomeCmsPage);

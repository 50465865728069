import roles from 'helpers/roles';

export const onSelect = {
  [roles.ROLE_EDIT_COST]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_REMOVE_COST,
    roles.ROLE_CREATE_COST,
  ],
  [roles.ROLE_SHOW_COST]: [roles.ROLE_SHOW_DIET, roles.ROLE_SHOW_VARIANT],
  [roles.ROLE_CREATE_COST]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_REMOVE_COST,
    roles.ROLE_EDIT_COST,
  ],
  [roles.ROLE_REMOVE_COST]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_CREATE_COST,
    roles.ROLE_EDIT_COST,
  ],
  [roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_SHOW_INGREDIENT]: [roles.ROLE_SHOW_DICTIONARY],
  [roles.ROLE_SHOW_DISH]: [roles.ROLE_SHOW_DICTIONARY],
  [roles.ROLE_SHOW_OVERPRODUCTION]: [
    roles.ROLE_SHOW_MENU_PLANNING,
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_SIZE,
    roles.ROLE_SHOW_SUB_BRAND,
  ],
  [roles.ROLE_SHOW_REPORT_OVERPRODUCTION]: [roles.ROLE_SHOW_SUB_BRAND],
  [roles.ROLE_CREATE_OVERPRODUCTION]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_SIZE,
    roles.ROLE_SHOW_SUB_BRAND,
  ],
  [roles.ROLE_EDIT_OVERPRODUCTION]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_SIZE,
    roles.ROLE_SHOW_SUB_BRAND,
  ],
  [roles.ROLE_SHOW_MENU_SUMMARY]: [
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_VARIANT,
    roles.ROLE_SHOW_DIET,
  ],
  [roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS]: [roles.ROLE_SHOW_VARIANT],
  [roles.ROLE_SHOW_REPORT_OVERPRODUCTION]: [
    roles.ROLE_SHOW_REPORT_DISH_STICKERS,
  ],

  [roles.ROLE_SHOW_MODULE_GOOGLE_ANALYTICS]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_GOOGLE_TAG_MANAGER]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_HOTJAR]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_GOOGLE_ANALYTICS]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_GOOGLE_TAG_MANAGER]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_FACEBOOK_PIXEL]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_FACEBOOK_PIXEL]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_HOTJAR]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_MY_LEAD]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_SALES_MANAGO]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_SALES_MANAGO]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_MY_LEAD]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_GET_RESPONSE]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_GET_RESPONSE]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_IPRESSO]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_USER_COM]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_USER_COM]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_IPRESSO]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_CLICK_ON_METRICS]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_POLLSTER_TRACK]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_CLICK_ON_METRICS]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_POLLSTER_TRACK]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_BITRIX_24]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_BITRIX_24]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_TRUSTED_SHOPS]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_TRUSTED_SHOPS]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_SHOW_MODULE_THULIUM]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_THULIUM]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_IMPORT_DISH_TRANSLATION]: [roles.ROLE_SHOW_IMPORTER_TASK],
  [roles.ROLE_EXPORT_DISH_TRANSLATION]: [roles.ROLE_SHOW_IMPORTER_TASK],
  [roles.ROLE_IMPORT_INGREDIENT_TRANSLATION]: [roles.ROLE_SHOW_IMPORTER_TASK],
  [roles.ROLE_EXPORT_INGREDIENT_TRANSLATION]: [roles.ROLE_SHOW_IMPORTER_TASK],
  [roles.ROLE_IMPORT_INGREDIENT_PRICE]: [roles.ROLE_SHOW_IMPORTER_TASK],
  [roles.ROLE_EXPORT_INGREDIENT_PRICE]: [roles.ROLE_SHOW_IMPORTER_TASK],
  [roles.ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA]: [
    roles.ROLE_SHOW_MODULE_SETTLEMENTS,
  ],
  [roles.ROLE_EXPORT_STATISTICS_ECOMMERCE_CALCULATION_DATA]: [
    roles.ROLE_SHOW_MODULE_SETTLEMENTS,
    roles.ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA,
  ],
  [roles.ROLE_SHOW_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_EDIT_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_REMOVE_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_CREATE_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_CREATE_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_CREATE_KWG_GROUP]: [roles.ROLE_SHOW_KWG_GROUP],
  [roles.ROLE_EDIT_KWG_GROUP]: [roles.ROLE_SHOW_KWG_GROUP],
  [roles.ROLE_SHOW_MODULE_SCAN_AND_SORT]: [roles.ROLE_SHOW_BRAND],
  [roles.ROLE_EDIT_MODULE_SCAN_AND_SORT]: [
    roles.ROLE_SHOW_BRAND,
    roles.ROLE_SHOW_MODULE_SCAN_AND_SORT,
  ],
  [roles.ROLE_CREATE_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_CREATE_PACKAGE_LINE_FILTER]: [roles.ROLE_SHOW_PACKAGE],
  [roles.ROLE_CREATE_KWG_GROUP]: [roles.ROLE_SHOW_KWG_GROUP],
  [roles.ROLE_EDIT_KWG_GROUP]: [roles.ROLE_SHOW_KWG_GROUP],
  [roles.ROLE_REMOVE_KWG_GROUP]: [roles.ROLE_SHOW_KWG_GROUP],
};

export const onDeselect = {
  [roles.ROLE_EDIT_COST]: [roles.ROLE_REMOVE_COST, roles.ROLE_CREATE_COST],
  [roles.ROLE_CREATE_COST]: [roles.ROLE_REMOVE_COST, roles.ROLE_EDIT_COST],
  [roles.ROLE_REMOVE_COST]: [roles.ROLE_CREATE_COST, roles.ROLE_EDIT_COST],
  [roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_SHOW_MEAL_TYPE]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_MENU_SUMMARY,
  ],
  [roles.ROLE_SHOW_SIZE]: [roles.ROLE_SHOW_OVERPRODUCTION],
  [roles.ROLE_SHOW_DIET]: [roles.ROLE_SHOW_COST, roles.ROLE_SHOW_MENU_SUMMARY],
  [roles.ROLE_SHOW_VARIANT]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_SHOW_MENU_SUMMARY,
    roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS,
  ],
  [roles.ROLE_SHOW_DICTIONARY]: [
    roles.ROLE_SHOW_INGREDIENT,
    roles.ROLE_SHOW_DISH,
  ],
  [roles.ROLE_SHOW_SUB_BRAND]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
  ],
  [roles.ROLE_SHOW_OVERPRODUCTION]: [
    roles.ROLE_CREATE_OVERPRODUCTION,
    roles.ROLE_EDIT_OVERPRODUCTION,
    roles.ROLE_REMOVE_OVERPRODUCTION,
  ],
  [roles.ROLE_SHOW_MENU_PLANNING]: [roles.ROLE_SHOW_OVERPRODUCTION],
  [roles.ROLE_SHOW_REPORT_DISH_STICKERS]: [
    roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
  ],
  [roles.ROLE_SHOW_IMPORTER_TASK]: [
    roles.ROLE_IMPORT_DISH_TRANSLATION,
    roles.ROLE_EXPORT_DISH_TRANSLATION,
    roles.ROLE_IMPORT_INGREDIENT_TRANSLATION,
    roles.ROLE_EXPORT_INGREDIENT_TRANSLATION,
    roles.ROLE_IMPORT_INGREDIENT_PRICE,
    roles.ROLE_EXPORT_INGREDIENT_PRICE,
  ],
  [roles.ROLE_SHOW_MODULE_SETTLEMENTS]: [
    roles.ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA,
    roles.ROLE_EDIT_MODULE_SETTLEMENTS,
    roles.ROLE_EXPORT_STATISTICS_ECOMMERCE_CALCULATION_DATA,
  ],
  [roles.ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA]: [
    roles.ROLE_EXPORT_STATISTICS_ECOMMERCE_CALCULATION_DATA,
  ],
  [roles.ROLE_SHOW_MODULE_SCAN_AND_SORT]: [
    roles.ROLE_EDIT_MODULE_SCAN_AND_SORT,
  ],
  [roles.ROLE_SHOW_KWG_GROUP]: [
    roles.ROLE_EDIT_KWG_GROUP,
    roles.ROLE_CREATE_KWG_GROUP,
    roles.ROLE_REMOVE_KWG_GROUP,
  ],
  [roles.ROLE_SHOW_KWG_GROUP]: [
    roles.ROLE_EDIT_KWG_GROUP,
    roles.ROLE_CREATE_KWG_GROUP,
  ],
  [roles.ROLE_SHOW_MODULE_SCAN_AND_SORT]: [
    roles.ROLE_EDIT_MODULE_SCAN_AND_SORT,
  ],
};

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import FormTextInput from 'components/FormTextInput/FormTextInput';

const GoogleAnalyticsModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'GoogleAnalyticsModule',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e => {
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                    useEcommerce: !module.enabled,
                    useEnhancedEcommerce: !module.enabled,
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={'Google Analytics'}
          />
          <FormTextInput
            label={t('brands.newBrandForm.GACode', 'Kod Google Analytics')}
            classes={classes}
            name="googleAnalytics"
            value={module.code}
            maxLength={30}
            handleChange={e =>
              handleModuleUpdate({ ...module, code: e.target.value })
            }
            inputSize={12}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default GoogleAnalyticsModule;

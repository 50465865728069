import types from 'helpers/constants';

const initialState = {
  diets: [],
  diet: {},
  loading: true,
};

function Diets(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DIETS:
      return {
        ...state,
        diets: action.payload.diets,
        loading: false,
      };
    case types.CLEAR_DIETS:
      return {
        ...state,
        diets: [],
      };
    case types.FETCH_DIET:
      return {
        ...state,
        diet: action.payload,
        loading: false,
      };
    case types.REMOVE_DIET:
      return {
        ...state,
        diets: state.diets.filter(
          mealType => mealType.id !== action.payload.dietId
        ),
      };
    default:
      return state;
  }
}

export default Diets;

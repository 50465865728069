import React from 'react';
import { TextField } from '@mui/material';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import TableBody from '@material-ui/core/TableBody';
import { useTranslation } from 'react-i18next';

const ConfirmNestedRecipes = ({ classes, recipes, onUpdateRecipes }) => {
  const { t } = useTranslation();
  const updateRecipe = (id, data) => {
    const newRecipes = [...recipes];
    const index = newRecipes.findIndex(el => el.id === id);
    newRecipes[index] = { ...newRecipes[index], ...data };

    onUpdateRecipes(newRecipes);
  };

  const pickAll = () => {
    const alreadyPickedAll = isPickedAll();
    const newRecipes = recipes.map(el => ({
      ...el,
      picked: !alreadyPickedAll,
    }));

    onUpdateRecipes(newRecipes);
  };

  const isPickedAll = () => recipes.filter(el => !el.picked).length === 0;

  return (
    <Table style={{ minWidth: 700 }}>
      <TableHead style={{ background: '#eaeaea' }}>
        <TableRow>
          <TableCell variant="head" style={{ width: 40 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPickedAll()}
                  onChange={pickAll}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={false}
            />
          </TableCell>
          <TableCell variant="head">{t('form.field.recipe')}</TableCell>
          <TableCell variant="head" style={{ maxWidth: 115 }}>
            {t('form.field.wom')}
            <br />
            {t('common.current', '$$Aktualny')}
          </TableCell>
          <TableCell variant="head" style={{ maxWidth: 115 }}>
            {t('form.field.wom')}
            <br />
            {t('common.new', '$$Nowy')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {recipes.map(
          ({ picked, id, name, workingOnMachining, newWorkingOnMachining }) => (
            <TableRow key={`recipe_${id}`}>
              <TableCell variant="body">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={picked}
                      onChange={() => updateRecipe(id, { picked: !picked })}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={false}
                />
              </TableCell>
              <TableCell variant="body">{name}</TableCell>
              <TableCell variant="body">{workingOnMachining}%</TableCell>
              <TableCell variant="body" style={{ maxWidth: 115 }}>
                <TextField
                  id="newWorkingOnMachining"
                  type="number"
                  value={!picked ? workingOnMachining : newWorkingOnMachining}
                  onChange={e =>
                    updateRecipe(id, {
                      newWorkingOnMachining: parseInt(e.target.value),
                    })
                  }
                  InputProps={{
                    endAdornment: '%',
                  }}
                  size="small"
                  disabled={!picked}
                  variant="standard"
                />
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default ConfirmNestedRecipes;

import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import { combineStyles } from 'helpers/helpers';
import FormIconUpload from 'components/FormImageUpload/FormIconUpload';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

const ClientPanelCalendarIcon = ({
  iconKey,
  classes,
  t,
  module,
  handleModuleUpdate,
  subTitle,
  removeIcon,
}) => {
  const labelTranslationKey = `module.configClientPanel.icons.${iconKey}`;
  const label = `${t(labelTranslationKey)}`;

  const additionalText = subTitle
    ? `${t(`module.configClientPanel.icons.${iconKey}-sub`)}`
    : null;

  const iconIri = module[iconKey];

  const previewUrl = module['@resources']?.[iconIri]?.contentUrl;

  const updateModule = (_, payload) => {
    handleModuleUpdate({
      ...module,
      [iconKey]: payload['@id'],
      '@resources': {
        ...module['@resources'],
        [payload['@id']]: payload,
      },
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormIconUpload
        classes={classes}
        stateName="image"
        getImage={updateModule}
        removeImage={() => {
          handleModuleUpdate({ ...module, [iconKey]: null });
        }}
        removeIcon={removeIcon}
        previewUrl={previewUrl}
        label={label}
        additionalText={additionalText}
      />
    </div>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(ClientPanelCalendarIcon);

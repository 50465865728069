import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import { fetchMealType } from 'actions/MealTypes';

import { fetchMealSizes } from 'actions/MealSizes';
import { openSweetToast } from 'actions/App';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LogView from 'components/History/LogView';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import PRODUCT_FEED_FORMATS from '../consts/productFeedFormats';

import { get, put, post } from 'helpers/apiHelpers';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import TOAST_DURATIONS from 'helpers/toastDurations';

const initialData = {
  domain: '',
  enableDiets: false,
  enableAddons: false,
  format: { value: 'GOOGLE_MERCHANT', label: 'Google Merchant' },
};

const ProductFeedForm = ({ classes, history, match, location, openToast }) => {
  const { t } = useTranslation();

  const typeId = match.params.id;
  const isEdit = location.pathname.includes('edit');

  const [data, setData] = useState(initialData);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    initializeForm();
  }, []);

  const initializeForm = async () => {
    if (isEdit) {
      try {
        const fetchedData = await get(`feeds/${typeId}`);

        const formatOption = PRODUCT_FEED_FORMATS.find(
          ({ value }) => value === fetchedData.format
        );
        const { domain, enableAddons, enableDiets } = fetchedData ?? {};

        setData({ domain, enableAddons, enableDiets, format: formatOption });
      } catch (e) {
        console.log(e);
      }

      setIsInitializing(false);
    }

    return setIsInitializing(false);
  };

  const handleInputChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleToggle = event => {
    setData({ ...data, [event.target.name]: !data?.[event.target.name] });
  };

  const handleSelect = (event, obj) => {
    setData({ ...data, [event.target.name]: obj });
  };

  const validateForm = () => {
    return !!(data?.domain ?? '').replaceAll(' ', '');
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return openToast({
        messages: [t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const formattedData = { ...data, format: data?.format?.value };

    try {
      isEdit
        ? await put(`/feeds/${typeId}`, formattedData)
        : await post('/feeds', formattedData);

      openToast({
        messages: [t('success.changesSaved', 'Zmiany zostały zapisane')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      history.push('/admin/product-feed');
    } catch (e) {
      return openToast({
        messages: [t('form.somethingWentWrong'), t('form.tryAgainLater')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  if (isInitializing) return <></>;

  return (
    <>
      <AdminTable
        title={
          isEdit
            ? t('$*productFeedForm.editProductFeed', '$$Edytuj Product Feed')
            : t('$*productFeedForm.addProductFeed', '$$Dodaj Product Feed')
        }
      >
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={t('$*productFeedForm.domain', '$$Domena') + ' *'}
              classes={classes}
              name="domain"
              value={data?.domain}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
          <GridItem sm={2} style={{ marginTop: '8px' }}>
            <FormControlLabel
              style={{ minWidth: '180px' }}
              control={
                <Checkbox
                  name="enableDiets"
                  onClick={handleToggle}
                  checked={!!data.enableDiets}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={t('$*productFeedForm.enableDiets', '$$Diety włączone')}
            />
          </GridItem>
          <GridItem sm={2} style={{ marginTop: '8px' }}>
            <FormControlLabel
              style={{ minWidth: '180px' }}
              control={
                <Checkbox
                  name="enableAddons"
                  onClick={handleToggle}
                  checked={!!data.enableAddons}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={t('$*productFeedForm.enableAddons', '$$Dodatki włączone')}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={4}>
            <SelectInput
              label={t('$*productFeedForm.format', '$$Format')}
              classes={classes}
              options={PRODUCT_FEED_FORMATS}
              value={data?.format}
              mapBy="label"
              trackBy="value"
              handleChange={handleSelect}
              name={'format'}
              id={'format'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
          </GridItem>
        </GridContainer>
      </AdminTable>

      {!isEdit && (
        <FormControlStickyButton
          t={t}
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath={'/admin/product-feed'}
          handleSubmit={handleSubmit}
          history={false}
        />
      )}
      {isEdit && (
        <LogView
          t={t}
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath={'/admin/product-feed'}
          handleSubmit={handleSubmit}
          iri={`/feeds/${typeId}`}
        />
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSizes: state.MealSizes.mealSizes,
    mealType: state.MealTypes.mealType,
  };
};

const mapDispatchToProps = {
  fetchMealSizes,
  fetchMealType,
  openSweetToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToast(withStyles(combinedStyles)(ProductFeedForm)));

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';

import Dialog from '@material-ui/core/Dialog';
import { Image } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import { Tooltip } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';

import ModalButton from 'components/CustomButtons/Button';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

const ImagePreviewCustomAction = ({ image }) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

  return (
    <div>
      <Dialog
        open={isOpened}
        onClose={() => setIsOpened(false)}
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogContent>
          <ModalButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              cursor: 'pointer',
            }}
            color={'transparent'}
            justIcon
            round
            onClick={() => setIsOpened(false)}
          >
            &times;
          </ModalButton>
        </DialogContent>
        <DialogContent>
          <img
            style={{ width: '100%' }}
            src={`${uploadedContentBaseURL}${image?.contentUrl}`}
            alt="dishImage"
          />
        </DialogContent>
      </Dialog>
      <Tooltip
        onClick={setIsOpened}
        title={t('$*common.imagePreview.tooltip')}
        placement={'top'}
      >
        <Image />
      </Tooltip>
    </div>
  );
};

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withStyles(combinedStyles)(ImagePreviewCustomAction);

import React from 'react';
import { connect } from 'react-redux';

const FullAddressAccessor = ({ slug, mainDomain }) => {
  return <div>{`https://${mainDomain}/${slug.slug}`}</div>;
};

export default connect(({ Brands: { brand: { mainDomain } } }) => ({
  mainDomain,
}))(FullAddressAccessor);

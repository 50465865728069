import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import React from 'react';
import { Caching } from '../../modules';
import CardWrapper from 'components/Card/CardWrapper';

const CachingSettings = ({ classes, setModules, modules }) => {
  return (
    <CardWrapper title={'Caching'}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <Divider style={{ marginBottom: '20px' }} />
            <Caching
              classes={classes}
              handleModuleUpdate={module => {
                setModules(prevState => ({
                  ...prevState,
                  Caching: module,
                }));
              }}
              module={modules.Caching}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default CachingSettings;

import Moment from 'moment';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';

const columnConfig = t => [
  {
    title: '$*cateringsFlow.tasksListDetails.table.brands',
    name: 'brands',
    accessor: row => row.brands.map(({ name }) => <div>{name}</div>),
    filterable: false,
    sortable: false,
    headerStyles: { display: 'inline-block', margin: '0 0 20px 0' },
  },
  {
    title: '$*cateringsFlow.tasksListDetails.table.multiplier',
    name: 'multiplier',
    accessor: 'multiplier',
    filterable: false,
    sortable: false,
  },
  {
    title: '$*cateringsFlow.tasksListDetails.table.subrands',
    name: 'subBrands',
    accessor: row => row.subBrands.map(({ name }) => <div>{name}</div>),
    filterable: false,
    sortable: false,
  },
  {
    title: '$*cateringsFlow.tasksListDetails.table.dateFrom',
    name: 'dateFrom',
    accessor: row => {
      return Moment(row?.dateFrom).format('DD.MM.YYYY');
    },
    filterable: false,
    sortable: false,
  },
  {
    title: '$*cateringsFlow.tasksListDetails.table.dateTo',
    name: 'dateTo',
    accessor: row => {
      return Moment(row?.dateTo).format('DD.MM.YYYY');
    },
    filterable: false,
    sortable: false,
  },
  {
    title: '$*cateringsFlow.tasksListDetails.table.includeSubscriptions',
    name: 'includeSubscriptions',
    accessor: row => {
      return <BooleanView value={row?.includeSubscriptions} />;
    },
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;

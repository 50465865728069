import CATERINGS_FLOW_STATUSES from './cateringsFlowStatuses';

const UNCHANCHABLE_STATUS = 'CREATED';

const STATUS_COLORS = {
  [UNCHANCHABLE_STATUS]: {
    color: '#000000',
    backgroundColor: '#ffffff',
    rowBackground: '#ffffff', // 85% lighter color approximation
    border: '1px solid #A9A9A9',
  },
  [CATERINGS_FLOW_STATUSES.WAITING]: {
    color: '#000000',
    backgroundColor: '#ffffff',
    rowBackground: '#ffffff', // 85% lighter color approximation
    border: '1px solid #A9A9A9',
  },
  [CATERINGS_FLOW_STATUSES.IN_PROGRESS]: {
    color: '#ffffff',
    backgroundColor: '#828282',
    rowBackground: '#dcdcdc', // 85% lighter color approximation
  },
  [CATERINGS_FLOW_STATUSES.DONE_WAITING]: {
    color: '#ffffff',
    backgroundColor: '#00ACC2',
    rowBackground: '#b3e5fc', // 85% lighter color approximation
  },
  [CATERINGS_FLOW_STATUSES.DONE]: {
    color: '#ffffff',
    backgroundColor: '#3FA64D',
    rowBackground: '#c8e6c9', // 85% lighter color approximation
  },
  [CATERINGS_FLOW_STATUSES.EARN_EXTRA]: {
    color: '#ffffff',
    backgroundColor: '#fe881c',
    rowBackground: '#ffe0b2', // 85% lighter color approximation
  },
};

export default STATUS_COLORS;

import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './Brands';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_BRAND,
  ROLE_EDIT_BRAND,
  ROLE_SHOW_BRAND,
} from 'helpers/roles';

import BrandSettings from '../BrandConfiguration/BrandSettings';

class Brands extends Component {
  render() {
    const props = this.props;

    const EditForm = withRole(ROLE_EDIT_BRAND, BrandSettings);
    const CreateForm = withRole(ROLE_CREATE_BRAND, BrandSettings);
    const ListSecured = withRole(ROLE_SHOW_BRAND, List);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm isEdit={false} {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => (
              <EditForm isEdit={true} {...props} {...parentProps} />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Brands);

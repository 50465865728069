import React from 'react';
import Button from '../CustomButtons/Button';

const BUTTON_STYLES = {
  marginLeft: 0,
  marginRight: 0,
  borderRadius: 0,
  padding: '8px',
  flex: '1',
};

class ButtonSwitch extends React.Component {
  getOptionProps = value => {
    let props = {
      onClick: () => {
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(value);
        }
      },
    };

    if (value === this.props.value) {
      props.color = this.props.selectedColor || 'primary';
    }

    return props;
  };

  render() {
    return (
      <>
        {this.props.options.map((option, key) => (
          <Button
            key={key}
            {...this.getOptionProps(option.value)}
            style={BUTTON_STYLES}
          >
            {option.label}
          </Button>
        ))}
      </>
    );
  }
}

export default ButtonSwitch;

import React from 'react';
import Datetime from 'react-datetime';
import AdminTable from 'layouts/AdminTable';
import ReactTable from 'react-table';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';

import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import AccessDenied from 'components/Security/AccessDenied';
import GridContainer from 'components/Grid/GridContainer';
import { DialogLoader } from 'components/DialogLoader';

import useWasteLogic from './useWasteLogic';
import columnConfig from './constants/columnConfig';
import ChooseFromMenuPlanningModal from './ChooseFromMenuPlanningModal';

import { isDatepickerValidDay } from 'helpers/dateHelpers';
import { isGranted, combineStyles } from 'helpers/helpers';

import { ROLE_SHOW_WASTE, ROLE_CREATE_WASTE } from 'helpers/roles';
import TOAST_DURATIONS from 'helpers/toastDurations';

const WasteComponent = ({ classes, openToast }) => {
  const { t } = useTranslation();

  const {
    state: {
      date,
      pages,
      alert,
      recipes,
      pageSize,
      modalOpen,
      isFetching,
      ingredients,
      multinational,
      selectInBrands,
      isTableLoading,
      overProductions,
      defaultPageSize,
      currentTotalItems,
      mealTypesFromConfig,
      overProductionsToSave,
      isFetchingRecipesAndIngredients,
    } = {},
    setDate = () => {},
    saveRow = () => {},
    setAlert = () => {},
    changeRow = () => {},
    validateRow = () => {},
    saveAllRows = () => {},
    setPageSize = () => {},
    setModalOpen = () => {},
    getTotalItems = () => {},
    changeSubBrand = () => {},
    setOverProductions = () => {},
    handleChangeAmount = () => {},
    fetchOverProductionsOnFetchData = () => {},
  } = useWasteLogic({ openToast });

  if (!isGranted(ROLE_SHOW_WASTE)) {
    return <AccessDenied />;
  }

  return isGranted(ROLE_SHOW_WASTE) ? (
    <AdminTable title={t('common.waste.waste')}>
      <GridContainer>
        {alert}
        <DialogLoader
          loaderState={isFetching || isFetchingRecipesAndIngredients}
          text={t('common.shared.isLoading')}
        />
        <ChooseFromMenuPlanningModal
          date={date}
          classes={classes}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          selectInBrands={selectInBrands}
          menuPlannerOptions={{
            recipes,
            ingredients,
          }}
          overProductions={overProductions}
          setOverProductions={setOverProductions}
          mealTypesFromConfig={mealTypesFromConfig}
        />
        <GridItem md={3}>
          <FormLabel
            className={classes.labelHorizontal}
            style={{ marginBottom: '5px' }}
          >
            {t('common.shared.selectDate')}
          </FormLabel>
          <FormControl fullWidth>
            <Datetime
              isValidDate={isDatepickerValidDay}
              timeFormat={false}
              closeOnSelect={true}
              value={date}
              onChange={ev => {
                setDate(ev);
                setOverProductions([]);
              }}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem md={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isFetching &&
                `${overProductions.length} ${t('dataGrid.pageOf')} ${
                  overProductions.length >= currentTotalItems
                    ? overProductions.length
                    : currentTotalItems
                } ${t('dataGrid.results')}`}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isGranted(ROLE_CREATE_WASTE) && (
                <div
                  style={{ marginRight: '5px' }}
                  onClick={() =>
                    !selectInBrands.length &&
                    openToast({
                      messages: [
                        t('info.chooseBrand', 'Najpierw wybierz marki'),
                      ],
                      type: 'info',
                      autoHideDuration: TOAST_DURATIONS.SM,
                    })
                  }
                >
                  <Button
                    disabled={!selectInBrands.length}
                    color={'primary'}
                    onClick={() => setModalOpen(true)}
                  >
                    {t('common.waste.addWaste')}
                  </Button>
                </div>
              )}
              {isGranted(ROLE_CREATE_WASTE) && (
                <Button
                  color={'success'}
                  onClick={saveAllRows}
                  disabled={!overProductionsToSave.length}
                >
                  {t('common.shared.saveAll')}
                </Button>
              )}
            </div>
          </div>
        </GridItem>
        <GridItem md={12}>
          <ReactTable
            manual
            data={overProductions}
            pages={pages}
            loading={isTableLoading}
            columns={columnConfig({
              t,
              classes,
              saveRow,
              setAlert,
              changeRow,
              validateRow,
              multinational,
              changeSubBrand,
              overProductions,
              setOverProductions,
              handleChangeAmount,
            })}
            pageSize={pageSize}
            pageSizeOptions={[1, 5, 10, 20, 50, 100]}
            defaultPageSize={defaultPageSize}
            onPageSizeChange={pageSize => {
              getTotalItems(pageSize);
              setPageSize(pageSize);
            }}
            showPaginationBottom={false}
            onFetchData={(requestData, table) =>
              fetchOverProductionsOnFetchData({ requestData, table })
            }
            showPaginationTop
            className="-striped -highlight"
            ofText={t('dataGrid.pageOf')}
            pageText={t('dataGrid.page')}
            rowsText={t('dataGrid.results')}
            nextText={t('dataGrid.nextPage')}
            noDataText={t('dataGrid.notFound')}
            loadingText={t('dataGrid.loading')}
            previousText={t('dataGrid.prevPage')}
          />
        </GridItem>
      </GridContainer>
    </AdminTable>
  ) : (
    <AccessDenied />
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

export default withStyles(combinedStyles)(withToast(WasteComponent));

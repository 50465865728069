import React from 'react';
import Button from 'components/CustomButtons/Button';
import ModalButton from 'components/CustomButtons/Button';
import { withToast } from 'material-ui-toast-redux';
import { VisibilityOff } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withTranslation } from 'react-i18next';

class PlannedInactiveElements extends React.Component {
  state = {
    inactiveMenuModal: false,
  };

  closeInactiveMenuModal = () =>
    this.setState(prevState => ({ ...prevState, inactiveMenuModal: false }));

  openInactiveMenuModal = () =>
    this.setState(prevState => ({ ...prevState, inactiveMenuModal: true }));

  render() {
    const {
      variants,
      diets,
      inactiveMenuPlaning,
      hasInactivePlanning,
    } = this.props;

    if (!hasInactivePlanning) {
      return null;
    }

    let variantsNames = {};
    let dietsNames = {};

    variants.forEach(el => {
      variantsNames[el['@id']] = el.name;
    });
    diets.forEach(el => {
      dietsNames[el['@id']] = el.name;
    });

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.inactiveMenuModal}
          onClose={this.closeInactiveMenuModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              disabled={this.state.disableButtons}
              round
              onClick={this.closeInactiveMenuModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {this.props.t(
                      'menuPlanner.action.warning.inactiveModal.fields.dish'
                    )}
                  </TableCell>
                  <TableCell>
                    {this.props.t(
                      'menuPlanner.action.warning.inactiveModal.fields.variant'
                    )}
                  </TableCell>
                  <TableCell>
                    {this.props.t(
                      'menuPlanner.action.warning.inactiveModal.fields.diet'
                    )}
                  </TableCell>
                  <TableCell>
                    {this.props.t(
                      'menuPlanner.action.warning.inactiveModal.fields.remove'
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inactiveMenuPlaning.map((el, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{el.dish.nameForClient}</TableCell>
                      <TableCell>{variantsNames[el.variant]}</TableCell>
                      <TableCell>{dietsNames[el.diet]}</TableCell>
                      <TableCell>
                        <Button
                          color="info"
                          disabled={this.state.disableButtons}
                          onClick={() => {
                            this.props.onRemoveMenu(el['@id']);
                          }}
                        >
                          {this.props.t(
                            'menuPlanner.action.warning.inactiveModal.fields.remove'
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
        <BottomNavigationAction
          label={this.props.t(
            'menuPlanner.action.warning.inactiveModal.actionName'
          )}
          showLabel={true}
          icon={<VisibilityOff style={{ color: '#f65b52' }} />}
          onClick={this.openInactiveMenuModal}
        />
      </>
    );
  }
}

export default withTranslation()(
  withStyles(sweetAlertStyle)(withToast(PlannedInactiveElements))
);

import React from 'react';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import FormTextInput from '../FormTextInput/FormTextInput';
import LabelWithTooltip from '../../views/PagesCreator/LabelWithTooltip';
import LettersCounter from '../../views/PagesCreator/LettersCounter';
import CardBody from 'components/Card/CardBody';
import PresentationUrl from './PresentationUrl';
import CardWrapper from 'components/Card/CardWrapper';

const BasicInfoCard = ({
  t,
  classes,
  urlSlug,
  metaTitle,
  friendlyName,
  handleChange,
  metaDescription,
  subpage,
}) => {
  return (
    <CardWrapper title={t('form.basicInfo')}>
      <CardBody>
        {friendlyName !== undefined && (
          <FormTextInput
            label={t('Przyjazna nazwa - widoczna na stronie')}
            name="friendlyName"
            value={friendlyName}
            classes={classes}
            inputSize={12}
            maxLength={64}
            handleChange={handleChange}
          />
        )}
        <PresentationUrl
          subpage={subpage}
          editInput={true}
          value={urlSlug}
          handleChange={handleChange}
        />
        <FormTextInput
          label={
            <LabelWithTooltip
              label={t('views.addons.addonSubpage.metaTitle')}
              tooltip={t('pages.title.tooltip')}
            />
          }
          name="metaTitle"
          value={metaTitle}
          classes={classes}
          inputSize={12}
          maxLength={60}
          handleChange={handleChange}
        />
        <FormTextInput
          label={
            <LettersCounter
              label={t('pages.metaDesc')}
              wordsCount={metaDescription.length}
            />
          }
          rows={2}
          name="metaDescription"
          value={metaDescription}
          classes={classes}
          multiline
          maxLength={300}
          inputSize={12}
          handleChange={handleChange}
        />
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle);

export default compose(
  withTranslation(),
  withStyles(combinedStyles)
)(BasicInfoCard);

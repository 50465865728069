import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { trimEmptyStringsFromQuery } from '../../helpers/helpers';
import { get } from '../../helpers/apiHelpers';
import Button from 'components/CustomButtons/Button';

class List extends Component {
  state = {
    loading: false,
  };

  refresh = () => {
    this.setState({ loading: true });
    return get('/ecommerce-bags-view/refresh').then(() =>
      this.setState({ loading: false })
    );
  };

  render() {
    return (
      <AdminTable title={' '}>
        <Button
          onClick={() => this.refresh()}
          disabled={this.state.loading}
          color="info"
        >
          {this.props.t('$*common.refresh.data')}
        </Button>
        <DataGrid
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/ecommerce-bags-view"
          reportName={'bagdetail'}
          columns={columnConfig(this.props.t)}
          role="BAG"
          minRows={20}
          defaultHiddenColumns={[
            'isSubscription',
            'orderId',
            'deliveryTime',
            'city',
            'zoneName',
            'clientDietName',
            'clientVariantName',
            'clientCalorificName',
            'clientOptionChangeMenu',
            'clientUseEcoContainers',
            'hasAddons',
          ]}
          defaultFiltered={[
            {
              id: 'date',
              value: {
                after: new moment().format('YYYY-MM-DD'),
                before: new moment().add(7, 'days').format('YYYY-MM-DD'),
              },
            },
            {
              id: 'active',
              value: true,
            },
            {
              id: 'systemStatus',
              value: 'PAID',
            },
          ]}
          manipulateQuery={(requestData, query) => {
            query._orX = [];

            if (query.user) {
              query._orX.push([
                {
                  clientFirstName: query.user.split(' '),
                  clientLastName: query.user.split(' '),
                },
              ]);
              delete query.user;
            }
            if (query.hasOwnProperty('date')) {
              const { after, before } = query['date'];
              if (after) {
                query['date[after]'] = after;
              }

              if (before) {
                query['date[before]'] = before;
              }

              delete query['date'];
            }

            if (query.hasOwnProperty('deliveredAt')) {
              const { after, before } = query['deliveredAt'];
              if (after) {
                query['deliveredAt[after]'] = after;
              }

              if (before) {
                query['deliveredAt[before]'] = before;
              }

              delete query['deliveredAt'];
            }

            if (query.hasOwnProperty('address')) {
              const search = query['address'];
              delete query['address'];
              let splicedSearch = search.split(' ');
              splicedSearch = trimEmptyStringsFromQuery(splicedSearch);

              query._orX.push([
                {
                  city: splicedSearch,
                  street: splicedSearch,
                  postCode: splicedSearch,
                  buildNumber: splicedSearch,
                  placeNumber: splicedSearch,
                  pickUpPoint: splicedSearch,
                },
              ]);

              delete query.address;
            }

            if (query.hasOwnProperty('realFoodCost')) {
              const filters = query.realFoodCost;
              delete query.realFoodCost;
              if (filters.valueFrom) {
                query['realFoodCost[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['realFoodCost[lte]'] = filters.valueTo;
              }
            }
            if (query.hasOwnProperty('priceAfterDiscount')) {
              const filters = query['priceAfterDiscount'];
              delete query['priceAfterDiscount'];
              if (filters.valueFrom) {
                query['priceAfterDiscount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['priceAfterDiscount[lte]'] = filters.valueTo;
              }
            }

            if (
              query?.priceFromMoneyBox?.valueFrom ||
              query?.priceFromMoneyBox?.valueTo
            ) {
              if (query.priceFromMoneyBox.valueFrom) {
                query['priceFromMoneyBox[gte]'] =
                  query.priceFromMoneyBox.valueFrom;
              }
              if (query.priceFromMoneyBox.valueTo) {
                query['priceFromMoneyBox[lte]'] =
                  query.priceFromMoneyBox.valueTo;
              }
            }

            if (query?.income?.valueFrom || query?.income?.valueTo) {
              if (query.income.valueFrom) {
                query['priceFromMoneyBox[gte]'] = query.income.valueFrom;
              }
              if (query.income.valueTo) {
                query['priceFromMoneyBox[lte]'] = query.income.valueTo;
              }
            }
            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);

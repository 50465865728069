import React from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import InputsIndex from './InputsIndex';
import produce from 'immer';
import { Divider } from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';

const OtherConfiguration = ({ shopModule, setShopModule }) => {
  const { t } = useTranslation();

  const getValueHandlerByStateKey = (getter, setter, stateKey) => {
    const fieldProps = {
      value: getter?.[stateKey],
      handler: () => {
        setter(
          produce(draft => {
            draft[stateKey] = !draft[stateKey];
          })
        );
      },
    };

    return fieldProps;
  };

  const fields = [
    [
      {
        label: t(
          '$*shop.otherSettings.showDishesGroupedByMealType',
          '$$Pokazuj dania w podziale na typy posiłków'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'showDishesGroupedByMealType'
        ),
      },
      {
        label: t(
          '$*shop.otherSettings.showMealTypeSelector',
          '$$Pokazuj wybierak typów posiłków'
        ),
        tooltip: t(
          '$*shop.otherSettings.showMealTypeSelector.tooltip',
          '$$Ta opcja działa, tylko jeśli dania pokazywane są w podziale na typy posiłków.'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(
          shopModule,
          setShopModule,
          'showMealTypeSelector'
        ),
      },
      {
        label: t('$*shop.otherSettings.showCalendar', '$$Pokazuj kalendarz'),
        tooltip: t(
          '$*shop.otherSettings.showCalendar.tooltip',
          '$$Jeżeli ta opcja jest odznaczona to kalendarz zostanie wyświetlony tylko wtedy, gdy liczba dostępnych dni jest wyższa od jednego. Zakres dni na kalendarzu jest dostępny na 60 dni w przód.'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey(shopModule, setShopModule, 'showCalendar'),
      },
    ],
  ];

  const [fieldsLeft] = fields;

  return (
    <CardWrapper title={t('$*shop.otherSettings', '$$Inne konfiguracje')}>
      <CardBody>
        <Divider style={{ marginBottom: '20px' }} />
        <GridContainer>
          <GridItem xs={6}>
            {fieldsLeft.map(field => (
              <GridContainer>
                <GridItem xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputsIndex {...field} />
                  </div>
                </GridItem>
              </GridContainer>
            ))}
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default OtherConfiguration;

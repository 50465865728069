import types from 'helpers/constants';

const initialState = {
  containers: [],
  loading: true,
};

function Containers(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CONTAINERS:
      return {
        ...state,
        containers: action.payload.containers,
        loading: false,
      };
    default:
      return state;
  }
}

export default Containers;

import BooleanView from '../../components/DataGrid/ValueViews/BooleanView';
import YesNoFilter from '../../components/Grid/Filter/YesNo';
import React from 'react';

const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

const columnConfig = [
  {
    title: 'languageSettings.languageName',
    accessor: 'name',
    name: 'name',
    filterable: true,
    sortable: true,
  },
  {
    title: 'languageSettings.languageNameShort',
    accessor: 'isoCode',
    name: 'isoCode',
    filterable: true,
    sortable: true,
  },
  {
    title: 'languageSettings.flag',
    accessor: row => (
      <>
        {row.image ? (
          <img
            src={`${uploadedContentBaseURL}${row.image?.contentUrl}`}
            alt=""
            width="23px"
            height="17px"
          />
        ) : null}
      </>
    ),
    name: 'flag',
    filterable: false,
    sortable: false,
  },
  {
    title: 'columns.active',
    accessor: row => <BooleanView value={row.enabled} />,
    name: 'enabled',
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
];

export default columnConfig;

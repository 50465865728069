import React, { Fragment } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const createModalContent = (item, t) => {
  const modificationsTranslations = {
    pickUpPoint: t('bagModificationModal.pickUpPoint'),
    address: t('bagModificationModal.address'),
    calorific: t('bagModificationModal.calorific'),
    diet: t('bagModificationModal.dietVariant'),
    variant: t('bagModificationModal.variant'),
    date: t('bagModificationModal.date'),
    useEcoContainers: t('bagModificationModal.useEcoContainers'),
    optionChangeMenu: t('bagModificationModal.optionChangeMenu'),
    buyAddons: t('bagModificationModal.buyAddons'),
    nullText: t('bagModificationModal.nullText'),
  };

  const modifications = Object.keys(item.payload);

  return modifications.map((modification, index) => {
    const getDisplayValue = (field, payload) => {
      if (payload === null) {
        return 'brak';
      }

      switch (field) {
        case 'date':
        case 'useEcoContainers':
          return payload;
        case 'optionChangeMenu':
          return payload;
        case 'address':
          return `${payload.city} ${payload.street} ${payload.buildNumber} ${payload.postCode}`;
        case 'diet':
        case 'calorific':
        case 'variant':
          return payload.name;
        case 'pickUpPoint':
          return payload.value;
        case 'buyAddons':
          return payload.map(purchasedAddon => {
            return (
              <p>
                {purchasedAddon.addon.name} x{purchasedAddon.quantity}
              </p>
            );
          });
        default:
          console.error(field, payload);
          return 'Error';
      }
    };

    if (item.payload[modification].hasOwnProperty('from')) {
      const displayValueFrom = getDisplayValue(
        modification,
        item.payload[modification].from
      );
      const displayValueTo = getDisplayValue(
        modification,
        item.payload[modification].to
      );

      if (displayValueFrom !== displayValueTo) {
        return (
          <Fragment key={index}>
            <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
              {modificationsTranslations[modification]
                ? modificationsTranslations[modification]
                : modification}
            </h3>
            {typeof displayValueFrom !== 'boolean' &&
            typeof displayValueTo !== 'boolean' ? (
              <div>
                {displayValueFrom !== 'brak' && <p>Z: {displayValueFrom}</p>}
                {displayValueTo !== 'brak' && <p>Na: {displayValueTo}</p>}
              </div>
            ) : (
              <h6>
                {displayValueTo
                  ? t('bagModificationModal.added')
                  : t('bagModificationModal.subtracted')}
              </h6>
            )}
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment key={index}>
          <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
            {modificationsTranslations[modification]
              ? modificationsTranslations[modification]
              : modification}
          </h3>
          <div>{getDisplayValue(modification, item.payload[modification])}</div>
        </Fragment>
      );
    }
    return null;
  });
};

const Component = props => {
  const { t } = useTranslation();
  const { item, items, fillModal, orderId } = props;
  const date = Moment(item.bag.date).format('YYYY-MM-DD');
  if (item.payload.optionChangeMenu && Object.keys(item.payload).length === 1)
    return `${t('bagModificationModal.menuSelectionForTheDay')} (${date})`;

  const getModalContent = async () => {
    let modalContent = items
      .sort((a, b) => a.id - b.id)
      .map(el => (
        <>
          {!el.payload.date && (
            <div style={{ margin: '20px 0' }}>
              {Moment(el.bag.date).format('DD-MM-YYYY')}
            </div>
          )}
          <div
            style={{
              margin: '15px 0 0',
              borderBottom: items.length > 1 ? '1px solid #e2e2e2' : 'none',
              paddingBottom: '20px',
              textAlign: 'left',
              minWidth: '300px',
            }}
          >
            {createModalContent(el, t)}
          </div>
        </>
      ));
    fillModal(
      <div>
        <h1>
          {t('bagModificationModal.orderDetails')} #{orderId}
        </h1>
        {items.length > 0 && (
          <h2>
            {t('bagModificationModal.diet')} #{props.items[0].bag.clientDiet.id}
          </h2>
        )}
        {modalContent}
      </div>
    );
  };
  return items ? (
    <span
      style={{ color: '#89229b', cursor: 'pointer' }}
      onClick={getModalContent}
    >
      {items.length > 1
        ? `${t('bagModificationModal.dietMultipleDayModification')} (#${
            items[0].bag.clientDiet.id
          })`
        : `${t('bagModificationModal.dietDayModification')} (${date})`}
    </span>
  ) : (
    <span>{t('bagModificationModal.dietDayModification')}</span>
  );
};

export default connect(({ Diets: { diets }, Variants: { variants } }) => ({
  diets,
  variants,
}))(Component);

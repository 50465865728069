import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import { Tooltip } from '@material-ui/core/index';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import SizeCell from './SizeCell';
import { combineStyles } from 'helpers/helpers';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import TranslatableReader from 'components/Translatable/TranslatableReader';

class SizeRow extends React.Component {
  state = {
    checked: false,
  };

  render() {
    const {
      ingredient,
      mealTypeSizes,
      dishSizes,
      onChange,
      shouldRescale,
      setShouldRescale,
      checkedDishToResize,
      mealTypeIngredientsSum,
      type,
      setDishToResize,
      classes,
      t,
    } = this.props;

    const hasIngredient = typeof ingredient !== 'undefined';
    let name;
    let unit;
    let isOfTypeRecipe;
    let rowIngredient;
    let currentDishId;

    const sumOfIngredients =
      mealTypeIngredientsSum?.[type['@id']]?.[
        ingredient.recipe?.id ?? ingredient.ingredient?.id
      ] || 0;

    const isMarkedRed =
      sumOfIngredients === 0 && mealTypeIngredientsSum !== null;

    if (hasIngredient) {
      isOfTypeRecipe = ingredient.ingredient === null;

      rowIngredient = isOfTypeRecipe
        ? ingredient.recipe
        : ingredient.ingredient;

      const recipeIngredientWeightCell = (
        <div>
          {t('mealTypes.sizeRow.portion', 'Porcja')}
          <div style={{ fontSize: '12px' }}>
            (
            <Tooltip
              title={
                <span style={{ fontSize: '12px' }}>
                  {t(
                    'mealTypes.sizeRow.dryWightBeforeProcessing',
                    'Gramatura "sucha", przed obróbką'
                  )}
                </span>
              }
              placement={'top'}
            >
              <span style={{ cursor: 'help' }}>
                {parseFloat(rowIngredient.weight).toFixed(2)}g
              </span>
            </Tooltip>
            /
            <Tooltip
              title={
                <span style={{ fontSize: '12px' }}>
                  {t(
                    'mealTypes.sizeRow.weightAfterProcessing',
                    'Gramatura po obróbce, uwzględniając WOT i WOM'
                  )}
                </span>
              }
              placement={'top'}
            >
              <span style={{ cursor: 'help' }}>
                {parseFloat(rowIngredient.weightAfterProcessing).toFixed(2)}g
              </span>
            </Tooltip>
            )
          </div>
        </div>
      );

      const wotMultiplier = (rowIngredient.thermalProcessing || 100) / 100;
      const womMultiplier = (rowIngredient.workingOnMachining || 100) / 100;

      const ingredientIngredientWeightCell = rowIngredient.unit && (
        <div>
          {rowIngredient.unit.value}
          <div style={{ fontSize: '12px' }}>
            (
            <Tooltip
              title={
                <span style={{ fontSize: '12px' }}>
                  {t(
                    'mealTypes.sizeRow.dryWeightBeforeProcessing',
                    'Gramatura "sucha", przed obróbką'
                  )}
                </span>
              }
              placement={'top'}
            >
              <span style={{ cursor: 'help' }}>{rowIngredient.weight}g</span>
            </Tooltip>
            /
            <Tooltip
              title={
                <span style={{ fontSize: '12px' }}>
                  {t(
                    'mealTypes.sizeRow.weightAfterProcessing',
                    'Gramatura po obróbce, uwzględniając WOT i WOM'
                  )}
                </span>
              }
              placement={'top'}
            >
              <span style={{ cursor: 'help' }}>
                {(rowIngredient.weight * wotMultiplier * womMultiplier).toFixed(
                  2
                )}
                g
              </span>
            </Tooltip>
            )
          </div>
        </div>
      );

      currentDishId = rowIngredient['@id'];
      name = !isOfTypeRecipe ? rowIngredient.name : rowIngredient.workName;
      unit = !isOfTypeRecipe
        ? ingredientIngredientWeightCell
        : recipeIngredientWeightCell;
    }

    return (
      <TableRow
        style={{
          background: isMarkedRed ? 'rgba(244, 67, 54, 0.3)' : 'inherit',
        }}
      >
        <TableCell>
          <TranslatableReader value={name} ingredient={ingredient} />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={checkedDishToResize.includes(currentDishId)}
            onChange={e => {
              setDishToResize(currentDishId);
            }}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        </TableCell>
        <TableCell>{unit}</TableCell>

        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => {
          return (
            hasIngredient && (
              <SizeCell
                key={mealTypeSizeKey}
                size={mealTypeSize.size}
                dishSizes={dishSizes}
                rowIngredient={rowIngredient}
                onChange={onChange}
                shouldRescale={shouldRescale}
                setShouldRescale={setShouldRescale}
              />
            )
          );
        })}
      </TableRow>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(SizeRow);

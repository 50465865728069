import { useEffect, useState } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';

//styles
import { get } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';

//components
import Card from 'components/Card/Card';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import SelectAll from 'components/SelectAll';
import GridContainer from 'components/Grid/GridContainer';

import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { DeleteForever } from '@material-ui/icons';

const EmployeesReportRow = ({
  row,
  classes,
  onRowRemove,
  onRowUpdate,
  canBeRemoved,
}) => {
  const { t } = useTranslation();

  const [kitchens, setKitchens] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchKitchens();
    fetchEmployees();
    fetchDepartments();
  }, []);

  const fetchKitchens = async () => {
    try {
      const response = await get('/kitchens');

      setKitchens(response?.['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await get('/employees/list', {
        partial: false,
        productionWorker: true,
      });

      setEmployees(
        response?.['hydra:member'].map(
          ({ '@id': iri, firstName, lastName }) => ({
            '@id': iri,
            value: `${firstName} ${lastName}`,
          })
        ) ?? []
      );
    } catch (error) {
      console.log({ error });
    }
  };
  const fetchDepartments = async () => {
    try {
      const response = await get('/departments');

      setDepartments(response?.['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleChangeDate = async (event, field) => {
    onRowUpdate({ ...row, [field]: event.format('YYYY-MM-DD') });
  };

  const handleKitchenChange = async selectedKitchens => {
    await onRowUpdate({
      ...row,
      kitchen: selectedKitchens,
      employee: [],
      department: [],
    });
    // fetchDepartments();
    // fetchEmployees();
  };

  const handleDepartmentChange = async selectedDepartments => {
    await onRowUpdate({
      ...row,
      department: selectedDepartments,
      employee: [],
    });
    // fetchEmployees();
  };

  const handleEmployeeChange = selectedEmployees => {
    onRowUpdate({
      ...row,
      employee: selectedEmployees,
    });
  };

  return (
    <Card style={{ marginTop: '20px' }}>
      <CardBody>
        <GridContainer justify="flex-start" style={{ position: 'relative' }}>
          {canBeRemoved && (
            <DeleteForever
              onClick={onRowRemove}
              style={{
                position: 'absolute',
                left: '-10px',
                top: '-10px',
                zIndex: 22,
                background: '#989898',
                color: '#fff',
                borderRadius: '20px',
                padding: 2,
                cursor: 'pointer',
              }}
            />
          )}
          <>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '2px' }}
              >
                {t('reports.selectKitchens', 'Wybierz kuchnie')}
              </FormLabel>
              <SelectAll
                className="input-select--kitchen"
                options={kitchens}
                trackBy={'@id'}
                mapBy={'value'}
                optionSelected={row?.kitchen}
                handleChange={handleKitchenChange}
              />
            </GridItem>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '2px' }}
              >
                {t('reports.selectDepartments', 'Wybierz działy')}
              </FormLabel>
              <SelectAll
                className="input-select--department"
                options={departments}
                trackBy={'@id'}
                mapBy={'value'}
                optionSelected={row?.department}
                handleChange={handleDepartmentChange}
              />
            </GridItem>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '2px' }}
              >
                {t('reports.selectEmployees', 'Wybierz pracowników')}
              </FormLabel>
              <SelectAll
                className="input-select--employee"
                options={employees}
                trackBy={'@id'}
                mapBy={'value'}
                optionSelected={row?.employee}
                handleChange={handleEmployeeChange}
              />
            </GridItem>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {t('reports.dateFrom')} *
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  className="input-datetime--date-from"
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={new moment(row?.dateFrom)}
                  onChange={ev => handleChangeDate(ev, 'dateFrom')}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {t('reports.dateTo')} *
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  className="input-datetime--date-to"
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={new moment(row?.dateTo)}
                  onChange={ev => handleChangeDate(ev, 'dateTo')}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
          </>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  company: state.Auth.user.company,
  brandsOptions: state.Auth.user.brands,
  zoneCategoriesOptions: state.ZoneCategories.zoneCategories,
});

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(EmployeesReportRow);

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
//material-ui core
import Fab from '@material-ui/core/Fab';
// components
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';
// icons
import Close from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

const PAYMENT_MODES = {
  SINGLE: 'single',
  SUBSCRIPTION: 'subscription',
};

const PaymentModesCopyModule = ({ module, classes, handleModuleUpdate }) => {
  const { t } = useTranslation();

  const languages = Object.keys(module?.single)
    .map(key => ({
      label: key,
      value: key,
    }))
    .filter(({ value }) => !value.includes('@'));

  const [language, setLanguage] = useState(languages[0]?.value);

  const subscriptionPaymentModeCopy = module?.subscription?.[language];
  const singlePaymentModeCopy = module?.single?.[language];

  const handleCopyChange = (changedCopy, mode) => {
    let newPaymentModesCopy = { ...module };

    newPaymentModesCopy[mode][language] = changedCopy;

    handleModuleUpdate(newPaymentModesCopy);
  };

  const handleLanguageChange = event => {
    const newLanguage = event.target.value;

    setLanguage(newLanguage);
  };

  if (!languages || languages?.length === 0) {
    return null;
  }

  return (
    <>
      <GridItem sm={12}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <span style={{ whiteSpace: 'nowrap', marginRight: '15px' }}>
            {t('$*paymentModesCopyModule.selectLanguage', '$$Wybierz język:')}
          </span>
          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name="language"
            value={languages?.find(({ value }) => value === language)}
            options={languages}
            disabled={false}
            handleChange={handleLanguageChange}
            customStyle={{ width: '50px' }}
          />
        </div>
      </GridItem>
      <GridItem>
        <GridContainer justify={'flex-end'}>
          <GridItem sm={12} style={{ marginBottom: '25px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>
                {t(
                  '$*paymentModesCopyModule.single',
                  '$$Podpunkty dla dostawy jednorazowej'
                )}
              </h4>
              <Fab
                color="secondary"
                variant="extended"
                className={classes.fab}
                onClick={() =>
                  handleCopyChange(
                    [...singlePaymentModeCopy, ''],
                    PAYMENT_MODES.SINGLE
                  )
                }
              >
                <AddIcon />
              </Fab>
            </div>
          </GridItem>
          {singlePaymentModeCopy.map((copy, index) => {
            return (
              <>
                <GridItem sm={12}>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      value: singlePaymentModeCopy[index],
                      onChange: ev => {
                        let newCopy = [...singlePaymentModeCopy];
                        let currentValue = ev.target.value;

                        newCopy[index] = currentValue;

                        handleCopyChange(newCopy, PAYMENT_MODES.SINGLE);
                      },
                    }}
                  />
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      handleCopyChange(
                        singlePaymentModeCopy.filter(
                          (copy, copyIndex) => copyIndex !== index
                        ),
                        PAYMENT_MODES.SINGLE
                      )
                    }
                    color="danger"
                    className="remove"
                    style={{ marginLeft: '-45px' }}
                  >
                    <Close />
                  </Button>
                </GridItem>
              </>
            );
          })}
        </GridContainer>
      </GridItem>
      <GridItem>
        <GridContainer justify={'flex-end'}>
          <GridItem sm={12} style={{ marginBottom: '25px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>
                {t(
                  '$*paymentModesCopyModule.subscription',
                  '$$Podpunkty dla dostawy subskrypcyjnej'
                )}
              </h4>
              <Fab
                color="secondary"
                variant="extended"
                className={classes.fab}
                onClick={() =>
                  handleCopyChange(
                    [...subscriptionPaymentModeCopy, ''],
                    PAYMENT_MODES.SUBSCRIPTION
                  )
                }
              >
                <AddIcon />
              </Fab>
            </div>
          </GridItem>
          {subscriptionPaymentModeCopy.map((copy, index) => {
            return (
              <>
                <GridItem sm={12}>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      value: subscriptionPaymentModeCopy[index],
                      onChange: ev => {
                        let newCopy = [...subscriptionPaymentModeCopy];
                        let currentValue = ev.target.value;

                        newCopy[index] = currentValue;

                        handleCopyChange(newCopy, PAYMENT_MODES.SUBSCRIPTION);
                      },
                    }}
                  />
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      handleCopyChange(
                        subscriptionPaymentModeCopy.filter(
                          (copy, copyIndex) => copyIndex !== index
                        ),
                        PAYMENT_MODES.SUBSCRIPTION
                      )
                    }
                    color="danger"
                    className="remove"
                    style={{ marginLeft: '-45px' }}
                  >
                    <Close />
                  </Button>
                </GridItem>
              </>
            );
          })}
        </GridContainer>
      </GridItem>
    </>
  );
};

export default PaymentModesCopyModule;

const calcRecipeCountInDishTaskV1 = ({ dishMetadata, recipeId }) => {
  return Object.keys(dishMetadata.ingredientsUsage).reduce((acc, curr) => {
    const ingredientInPortion =
      dishMetadata.ingredientsUsage?.[curr]?.ingredients?.[`r:${recipeId}`] ??
      0;
    const dishSizeCount = dishMetadata.ingredientsUsage?.[curr]?.total ?? 0;
    return acc + dishSizeCount * ingredientInPortion;
  }, 0);
};

const calcRecipeWeightInDishTaskV1 = ({ dishMetadata, recipeId }) => {
  const ingredientMetadata = dishMetadata?.ingredientsMetadata?.ingredients?.[
    `r:${recipeId}`
  ] ?? { weight: 0 };
  return (
    calcRecipeCountInDishTaskV1({ dishMetadata, recipeId }) *
    (ingredientMetadata.weight ?? 0)
  );
};

export const calcRecipeWeightInDishTask = ({
  dishTaskMetadata,
  recipeId,
  regularAmount,
  updatedAmount,
}) => {
  if (!recipeId || !dishTaskMetadata) {
    return 0.0;
  }

  if (dishTaskMetadata.hasOwnProperty('v1')) {
    if (updatedAmount) {
      const amountWeightPerOneGrams =
        calcRecipeWeightInDishTaskV1({
          dishMetadata: dishTaskMetadata.v1,
          recipeId,
        }) / regularAmount;
      return amountWeightPerOneGrams * updatedAmount;
    }

    return calcRecipeWeightInDishTaskV1({
      dishMetadata: dishTaskMetadata.v1,
      recipeId,
    });
  }

  return 0;
};

export const calcRecipeCountInDishTask = ({
  dishTaskMetadata,
  recipeId,
  regularAmount,
  updatedAmount,
}) => {
  if (!recipeId || !dishTaskMetadata) {
    return 0.0;
  }

  if (dishTaskMetadata.hasOwnProperty('v1')) {
    if (updatedAmount) {
      const amountWeightPerOneGrams =
        calcRecipeCountInDishTaskV1({
          dishMetadata: dishTaskMetadata.v1,
          recipeId,
        }) / regularAmount;
      return amountWeightPerOneGrams * updatedAmount;
    }

    return calcRecipeCountInDishTaskV1({
      dishMetadata: dishTaskMetadata.v1,
      recipeId,
    });
  }

  return 0;
};

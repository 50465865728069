import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import { get } from 'helpers/apiHelpers';
import { isGranted } from 'helpers/helpers';
import withRole from 'components/Security/withRole';
import { ROLE_SHOW_KITCHEN, ROLE_EDIT_KITCHEN } from 'helpers/roles';

import TasksListDetails from 'views/CateringsFlow/common/TasksListDetails/TasksListDetails';
import RecipiesList from 'views/CateringsFlow/Kitchen/components/KitchenTasksList/KitchenTasksList';

const KitchenTaskPreview = () => {
  const match = useRouteMatch();
  const [tasksListDetails, setTasksListDetails] = useState({});

  const ListSecured = withRole(ROLE_SHOW_KITCHEN, RecipiesList);

  useEffect(() => {
    fetchTask();
  }, []);

  const fetchTask = async () => {
    try {
      const response = await get(`/task-lists/${match?.params?.id}`);

      setTasksListDetails(response);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <TasksListDetails
        {...tasksListDetails}
        status={tasksListDetails?.taskListStatuses?.cookRecipe}
        isDetailsModalAvaliable={isGranted(ROLE_EDIT_KITCHEN)}
      />
      <ListSecured tasksListId={match?.params?.id} />
    </>
  );
};

export default KitchenTaskPreview;

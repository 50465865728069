import types from 'helpers/constants';

const initialState = {
  addresses: [],
  loading: true,
};

function Addresses(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USERADDRESSES:
      return {
        ...state,
        addresses: action.payload.addresses,
        loading: false,
      };
    case types.REMOVE_ADDRESS:
      return {
        ...state,
        addresses: state.addresses.filter(
          addresse => addresse.id !== action.payload.addressId
        ),
      };
    default:
      return state;
  }
}

export default Addresses;

export const mapApiResponseToStateObject = response => {
  if (response === null || response === undefined) {
    return {};
  }

  const toString = val => (val === null ? '' : val);
  return {
    name: response.name,
    workName: response.workName,
    shoppingName: response.shoppingName,
    weight: response.weight,
    calories: response.calories,
    fat: toString(response.fat),
    carbohydrates: toString(response.carbohydrates),
    monosaccharide: toString(response.sugar),
    proteins: toString(response.protein),
    dietaryFiber: toString(response.roughage),
    salt: toString(response.salt),
    nkt: toString(response.saturatedFattyAcids),
    note: response.note,
    wot: toString(response.thermalProcessing),
    wom: toString(response.workingOnMachining),
    priceAverage: toString(response?.priceAverage?.toFixed(4)),
    priceAverageWeighted: toString(response?.priceAverageWeighted?.toFixed(4)),
    pricePerKg: toString((response.price * 10).toFixed(4)),
    pricePerUnit: ((response.price * response.weight) / 100).toFixed(4),
    glycemicIndex: toString(response.glycemicIndex),
    unit: response.unit ? response.unit['@id'] : null,
    categories: response.categories,
    allergens: response.allergens,
    wholesaler: response.wholesaler ? response.wholesaler['@id'] : null,
    inventoryAmount: response.inventoryAmount,
    hermesCardIndex: response.hermesCardIndex,
    contractor: response.contractor,
    homeIngredient: response.homeIngredient,
    fattyAcids: response.fattyAcids,
    minerals: response.minerals,
    vitamins: response.vitamins,
    allert: response.allert,
    products: response.products,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

const translateActionTypes = t => ({
  INSERT: t('actionTypes.insert', 'UTWORZENIE'),
  UPDATE: t('actionTypes.update', 'AKTUALIZACJA'),
  REMOVE: t('actionTypes.remove', 'USUNIĘCIE'),
  ASSOCIATE: t('actionTypes.associate', 'POWIĄZANIE'),
  DISSOCIATE: t('actionTypes.dissociate', 'ODWIĄZANIE'),
});

export const ActionTypeCell = ({ value }) => {
  const { t } = useTranslation();
  const actionTypes = translateActionTypes(t);

  return <>{actionTypes[value] || value}</>;
};

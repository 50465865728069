import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchWholesalers = () => {
  return dispatch =>
    get('/wholesalers', { pagination: false }).then(wholesalers => {
      dispatch({
        type: types.FETCH_WHOLESALERS,
        payload: {
          wholesalers: wholesalers['hydra:member'],
        },
      });
    });
};

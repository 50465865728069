import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';

const FormIngredientsProduct = ({ t, classes, state }) => {
  return (
    <GridItem xs={12}>
      <CardWrapper title={t('common.mainMenu.products')}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={10}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('form.field.name')}</TableCell>
                    <TableCell>{t('form.field.stock')}</TableCell>
                    <TableCell>{t('form.field.lastPriceGross')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state?.products?.map(product => (
                    <TableRow>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.stock}</TableCell>
                      <TableCell>
                        {product.lastPriceGross?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </GridItem>
  );
};

export default FormIngredientsProduct;

import isEmpty from 'lodash/isEmpty';

import Moment from 'moment';

import DateFilter from 'components/Grid/Filter/Date';
import SelectFilter from 'components/Grid/Filter/Select';
import EmployeePicker from 'views/CateringsFlow/common/EmployeePicker/EmployeePicker';

const columnConfig = ({ t, kitchens, employees, departments }) => {
  return [
    {
      title: 'ID',
      name: 'id',
      accessor: 'id',
      width: 100,
      sortable: true,
      filterable: true,
    },
    {
      title: '$*recipe.productionHistory.date',
      accessor: row =>
        new Moment(row?.taskList?.generatedAt).format('YYYY-MM-DD HH:mm:ss'),
      name: 'taskList.generatedAt',
      Filter: ({ onChange, filter }) => {
        return <DateFilter onChange={onChange} value={filter?.value} />;
      },
      sortable: true,
      filterable: true,
    },
    {
      title: '$*recipe.productionHistory.employee',
      name: 'assignedEmployee.id',
      accessor: row => {
        return (
          <EmployeePicker
            row={row}
            employees={[]}
            isPickingEnabled={false}
            initialEmployee={
              isEmpty(row?.assignedEmployee)
                ? {}
                : {
                    contentUrl: row?.assignedEmployee?.image?.contentUrl,
                    label: `${row?.assignedEmployee?.firstName} ${row?.assignedEmployee?.lastName}`,
                    value: row?.assignedEmployee?.['@id'],
                  }
            }
          />
        );
      },
      width: 85,
      sortable: false,
      filterable: true,
      Filter: ({ onChange, filter }) => {
        const options = (employees ?? []).map(employee => {
          return {
            '@id': employee?.['@id'],
            name: employee?.value,
          };
        });

        return (
          <SelectFilter
            options={options}
            value={filter?.value}
            onChange={onChange}
            multiple={false}
          />
        );
      },
    },
    {
      title: '$*recipe.productionHistory.amountToPrepare',
      accessor: row =>
        `${(
          ((row?.amountToMake ?? 0) *
            (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
            1000 ?? 0
        ).toFixed(2)} kg`,
      name: 'task.amountToMake',
      sortable: true,
      filterable: false,
    },
    {
      title: '$*recipe.productionHistory.difference',
      accessor: row => {
        return `${(
          ((row?.amountOfDone - row?.amountToMake ?? 0) *
            (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
            1000 ?? 0
        ).toFixed(2)} kg`;
      },
      name: 'difference',
      sortable: false,
      filterable: false,
    },
    {
      title: '$*recipe.productionHistory.amountPrepared',
      accessor: row =>
        `${(
          ((row?.amountOfDone ?? 0) *
            (row?.recipe?.macrosAfterProcessing?.weight ?? 0)) /
            1000 ?? 0
        ).toFixed(2)} kg`,
      name: 'task.amountOfDone',
      sortable: true,
      filterable: false,
    },
    {
      title: '$*recipe.productionHistory.timeSpent',
      accessor: row => {
        const timesSpent = (row?.timeEntries ?? []).map(
          ({ startAt, endAt }) => {
            return new Date(endAt).getTime() - new Date(startAt).getTime();
          }
        );
        const summedTimesSpent = (timesSpent ?? []).reduce(
          (acc, item) => acc + item,
          0
        );

        let seconds = Math.floor((summedTimesSpent / 1000) % 60),
          minutes = Math.floor((summedTimesSpent / (1000 * 60)) % 60),
          hours = Math.floor((summedTimesSpent / (1000 * 60 * 60)) % 24);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return `${hours > 0 ? hours + ' h' : ''} ${
          minutes > 0 ? minutes + ' min' : ''
        } ${seconds > 0 ? seconds + ' s' : ''}`;
      },
      name: 'timeSpent',
      sortable: false,
      filterable: false,
    },
    {
      title: '$*recipe.productionHistory.kitchen',
      accessor: row => {
        return row?.recipe?.kitchen?.value;
      },
      name: 'recipe.kitchen.id',
      sortable: true,
      filterable: true,
      Filter: ({ onChange, filter }) => {
        const options = (kitchens ?? []).map(kitchen => {
          return {
            '@id': kitchen?.['@id'],
            name: kitchen?.value,
          };
        });

        return (
          <SelectFilter
            options={options}
            value={filter?.value}
            onChange={onChange}
            multiple={false}
          />
        );
      },
    },
    {
      title: '$*recipe.productionHistory.department',
      accessor: row => {
        return row?.recipe?.department?.value;
      },
      name: 'recipe.department.id',
      sortable: true,
      filterable: true,
      Filter: ({ onChange, filter }) => {
        const options = (departments ?? []).map(department => {
          return {
            '@id': department?.['@id'],
            name: department?.value,
          };
        });

        return (
          <SelectFilter
            options={options}
            value={filter?.value}
            onChange={onChange}
            multiple={false}
          />
        );
      },
    },
  ];
};

export default columnConfig;

import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import TOAST_DURATIONS from 'helpers/toastDurations';

const withErrorHandler = WrappedComponent => {
  class WithErrorHandler extends React.Component {
    state = {
      hasErrors: false,
      errors: {},
    };

    errorHandlerResetState = () => {
      this.setState({
        hasErrors: false,
        errors: {},
      });
    };

    errorHandlerCatch = error => {
      const {
        data: { violations = [] },
        status,
      } = error.response;
      const { t } = this.props;
      if (status === 500) {
        return this.props.openToast({
          messages: [
            t('errors.serverNotResponding', 'Serwer nie odpowiada'),
            t(
              'errors.tryAgainLater',
              'Spóbuj ponownie później, przepraszamy za problemy'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      const tempErrors =
        violations.reduce((acc, { message, propertyPath }) => {
          return { ...acc, [propertyPath]: message };
        }, {}) || {};

      this.setState({
        hasErrors: true,
        errors: tempErrors,
      });
    };

    render() {
      const { hasErrors, errors } = this.state;

      return (
        <WrappedComponent
          hasErrors={hasErrors}
          errors={errors}
          errorHandlerResetState={this.errorHandlerResetState}
          errorHandlerCatch={this.errorHandlerCatch}
          {...this.props}
        />
      );
    }
  }

  WithErrorHandler.displayName = `WithErrorHandler(${WrappedComponent.name})`;

  return withToast(withTranslation()(WithErrorHandler));
};

export default withErrorHandler;

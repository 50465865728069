import React from 'react';
import { store } from 'store';

import AccessDenied from './AccessDenied';

const Empty = () => <></>;

const withRole = (role, Component, returnNull) => {
  const roles = store.getState().Auth.user.roles;

  if (!roles.includes(role)) {
    if (returnNull === true) {
      return Empty;
    } else {
      return AccessDenied;
    }
  }

  return Component;
};

export default withRole;

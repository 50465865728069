import React, { Component } from 'react';
import { orderStyles } from '../../styles';

import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_NOTE,
  ROLE_NOTE_CLIENT,
  ROLE_SHOW_NOTE,
} from 'helpers/roles';
import { post, get } from 'helpers/apiHelpers';

import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import DataGrid from 'components/DataGrid/DataGrid';
import notesColumnConfig from './notesColumnConfig';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import TOAST_DURATIONS from 'helpers/toastDurations';

class NotesModal extends Component {
  state = {
    content: '',
    editModalData: {},
  };

  addNote = e => {
    const { t } = this.props;

    if (!this.state.content || this.state.content.trim().length === 0) {
      return this.props.openToast({
        messages: [t('errors.contentRequired', 'Treść nie może być pusta')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    e.preventDefault();
    post('/notes', {
      content: this.state.content,
      key: this.props.client['@id'],
    }).then(() => {
      this.setState({ content: '' });
      this.closeModal();
      this.props.openToast({
        messages: [t('success.noteAdded', 'Notatka została dodana')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    });
  };

  closeModal = () => {
    get(`notes`, { key: this.props.client['@id'] }).then(notes => {
      this.props.setNotes(notes['hydra:member']);
      this.props.setNotesModalStatus(false);
      this.setState({ content: '' });
    });
  };

  render() {
    const { classes, notesModalStatus, t } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={notesModalStatus}
        onClose={() => this.closeModal()}
      >
        <DialogContent>
          <ModalButton
            style={orderStyles.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={() => this.closeModal()}
          >
            <Close />
          </ModalButton>
          <GridContainer>
            {(isGranted(ROLE_CREATE_NOTE) || isGranted(ROLE_NOTE_CLIENT)) && (
              <GridItem
                md={
                  isGranted(ROLE_SHOW_NOTE) || isGranted(ROLE_NOTE_CLIENT)
                    ? 3
                    : 12
                }
              >
                <div style={{ marginLeft: '5px', ...orderStyles.box }}>
                  <b>{t('notes.newNote', 'Nowa notatka')}</b>
                  <FormTextInput
                    multiline
                    rowsMax={10}
                    label={t('notes.noteContent', 'Treść notatki')}
                    classes={classes}
                    name="phone"
                    value={this.state.content}
                    handleChange={ev =>
                      this.setState({ content: ev.target.value })
                    }
                    inputSize={12}
                    maxLength={400}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalButton
                      color={'primary'}
                      onClick={ev => this.addNote(ev)}
                    >
                      {t('notes.addNote', 'Dodaj notatkę')}
                    </ModalButton>
                  </div>
                </div>
              </GridItem>
            )}
            {(isGranted(ROLE_SHOW_NOTE) || isGranted(ROLE_NOTE_CLIENT)) && (
              <GridItem
                md={
                  isGranted(ROLE_CREATE_NOTE) || isGranted(ROLE_NOTE_CLIENT)
                    ? 9
                    : 12
                }
              >
                <div
                  style={{
                    marginLeft: '5px',
                    ...orderStyles.box,
                    paddingBottom: '10px',
                  }}
                >
                  <b>{t('notes.allNotes', 'Wszystkie notatki')}</b>
                </div>
                <DataGrid
                  export={true}
                  refreshOnFetch
                  actions={true}
                  remove={true}
                  editAction={async row => {
                    this.props.setNotesModalStatus(false);
                    this.props.setEditModalStatus(true);
                    this.props.setEditModalData(row);
                  }}
                  paginationTop={false}
                  paginationBottom={false}
                  url={`notes`}
                  reportName={'notes'}
                  query={this.props.client['@id']}
                  columns={notesColumnConfig}
                  role="NOTE"
                  hasRoleToModifyNotes={isGranted(ROLE_NOTE_CLIENT)}
                />
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

export default NotesModal;

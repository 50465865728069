import React from 'react';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { PauseCircleFilled, PlayCircleFilled, Loop } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { put } from 'helpers/apiHelpers';

class SubscriptionPause extends React.Component {
  state = {
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  toggleSubscriptionState = () => {
    this.setLoading(true);
    const { row } = this.props;
    put(`/subscription/${row.id}`, { enabled: !row.active })
      .then(() => {
        if (typeof this.props.onUpdate === 'function') {
          this.props.onUpdate();
        }
        this.setLoading(false);
      })
      .catch(() => {
        this.setLoading(false);
      });
  };

  render() {
    const { t, row } = this.props;

    if (row.markedToRemove) {
      return null;
    }

    if (!row.active) {
      return (
        <Tooltip
          onClick={this.toggleSubscriptionState}
          title={
            <div>
              <h5>
                {t('subscription.dialog.pause.activate.tooltip.title.h5')}
              </h5>
              <h6>
                {t('subscription.dialog.pause.activate.tooltip.title.h6')}
              </h6>
            </div>
          }
          placement={'top'}
        >
          <div>
            {this.state.loading === true ? (
              <Loop className={'rotating'} />
            ) : (
              <PlayCircleFilled style={{ color: 'green' }} />
            )}
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        onClick={this.toggleSubscriptionState}
        title={
          <div>
            <h5>{t('subscription.dialog.pause.pause.tooltip.title.h5')}</h5>
            <h6>{t('subscription.dialog.pause.pause.tooltip.title.h6')}</h6>
          </div>
        }
        placement={'top'}
      >
        <div>
          {this.state.loading === true ? (
            <Loop className={'rotating'} />
          ) : (
            <PauseCircleFilled style={{ color: 'orange' }} />
          )}
        </div>
      </Tooltip>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(SubscriptionPause))
);

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const MinAndMaksOrderLengthSectionSettings = ({
  classes,
  t,
  state,
  setState,
  handleInputChange,
}) => {
  return (
    <>
      <GridItem md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.customDietLengthEnabled}
              onChange={() =>
                setState(prevState => ({
                  ...prevState,
                  customDietLengthEnabled: !state.customDietLengthEnabled,
                }))
              }
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('brandCfg.minimumAndMaximum')}
        />
        <label
          htmlFor="orderLength"
          style={{
            cursor: 'pointer',
            display: 'block',
            color: 'rgba(0, 0, 0, 0.46)',
          }}
          onClick={() => {
            setState(prevState => ({
              ...prevState,
              daysSelectorType: 'RANGE',
            }));
          }}
        >
          {t('brandCfg.setupMinMax')}
        </label>
        <GridContainer>
          <GridItem md={4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.minimum')}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'daysMin'}
              value={state.daysMin}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem md={4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.maximum')}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'daysMax'}
              value={state.daysMax}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem md={4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.defValue')}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'daysDefault'}
              value={state.daysDefault}
              onChange={handleInputChange}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </>
  );
};

export default MinAndMaksOrderLengthSectionSettings;

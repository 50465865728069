import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ClearIcon from '@material-ui/icons/Clear';

import {
  dangerColor,
  goldColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react';

import { currencyToSymbol } from 'utils/currencies';

import Select from 'components/CustomSelect/Select';

import ADDONS_TYPES from '../../consts/addonsTypes';

const BoxSectionTitle = styled.h3`
  color: ${props => props.color};
  margin: 0;
  font-weight: 500;
`;

const BoxSectionContainer = styled.div`
  width: 100%;
  margin: ${props => props?.margin ?? 0};
  padding: 15px;
  border: ${`1px solid ${grayColor[4]}`};
  border-radius: 5px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: ${`1px solid ${grayColor[4]}`};
`;

const IconWrapper = styled.div`
  color: ${dangerColor[0]};
  cursor: pointer;
`;

const SelectsWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

const MAX_ADDONS_AMOUNT = 15;

const AddonBox = ({
  type,
  price,
  label = '',
  style = {},
  amount = 1,
  onRemoveClick = () => {},
  onAddonAmountChange = () => {},
  onAddonDeliveryTypeChange = () => {},
} = {}) => {
  const { t } = useTranslation();

  const currency = useSelector(
    state => state?.Brands.brand.multinational.currency
  );

  const getAmountOptions = () => {
    let amountOptions = [];

    for (let index = 0; index < MAX_ADDONS_AMOUNT; index++) {
      amountOptions.push({
        value: index + 1,
        label: `${index + 1} ${t('orders.addons.quantity', '$$szt.')}`,
      });
    }

    return amountOptions;
  };

  const getDeliveryTypeLabel = type => {
    switch (type) {
      case ADDONS_TYPES.FOR_DIET:
        return t('orders.addons.forDiet');

      case ADDONS_TYPES.FOR_BAG:
        return t('orders.addons.forBag');

      default:
        return '';
    }
  };

  return (
    <BoxSectionContainer margin={style?.margin}>
      <HeaderWrapper>
        <BoxSectionTitle color={goldColor}>
          {`${label} (${price} ${currencyToSymbol(currency)})`}
        </BoxSectionTitle>
        <IconWrapper onClick={onRemoveClick}>
          <ClearIcon style={{ width: '17px' }} />
        </IconWrapper>
      </HeaderWrapper>
      <SelectsWrapper>
        <div style={{ width: '35%' }}>
          <Select
            label={t('orders.select', '$$Wybierz')}
            value={{
              value: amount,
              label: `${amount} ${t('orders.addons.quantity', '$$szt.')}`,
            }}
            options={getAmountOptions()}
            handleChange={e => {
              console.log({ e });
              onAddonAmountChange(e);
            }}
          />
        </div>
        <div style={{ width: '65%', marginLeft: '15px' }}>
          <Select
            label={t('orders.select', '$$Wybierz')}
            value={{
              value: type,
              label: getDeliveryTypeLabel(type),
            }}
            options={[
              {
                label: t('orders.addons.forDiet'),
                value: ADDONS_TYPES.FOR_DIET,
              },
              { label: t('orders.addons.forBag'), value: ADDONS_TYPES.FOR_BAG },
            ]}
            handleChange={onAddonDeliveryTypeChange}
          />
        </div>
      </SelectsWrapper>
    </BoxSectionContainer>
  );
};

export default AddonBox;

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ReactTable from 'react-table';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './styles.css';
import STATUS_COLORS from 'views/CateringsFlow/consts/cateringsFlowStatusesColors';

const SubTasksList = ({
  data,
  columns,
  getTrProps,
  getTdProps,
  row,
  multinational,
}) => {
  const { t } = useTranslation();

  const wrapColumnPropertyWithPlug = (property, plug) => {
    if (typeof property === 'function') {
      return row => property(row, plug);
    } else {
      return property;
    }
  };

  let accessorPlug = {
    t: t,
    multinational: multinational,
  };

  let formattedColumnConfig = columns.map(column => {
    const config = {
      ...column,
      id: column.name,
      Header: () => {
        if (column.title && column.Header) {
          console.warn(
            'The column should only have a title or only a header defined. Otherwise, the title will overwrite the headline'
          );
        }
        return (
          <span style={{ fontSize: '12px' }}>
            {column.title ? t(column.title) : column.Header}
          </span>
        );
      },
      accessor: wrapColumnPropertyWithPlug(column.accessor, accessorPlug),
    };
    if (config.wrapFilter) {
      config.Filter = wrapColumnPropertyWithPlug(column.Filter, accessorPlug);
    }
    return config;
  });

  return (
    <div style={{ marginLeft: '35px' }}>
      <div>
        {!isEmpty(data) ? (
          <ReactTable
            className="subTasksList"
            data={data ?? []}
            columns={formattedColumnConfig}
            defaultPageSize={data.length}
            showPagination={false}
            resizable={false}
            filterable={false}
            getTdProps={(state, rowInfo, column, instance) => {
              let props = {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
              if (typeof getTdProps === 'function') {
                let givenProps = getTdProps(
                  state,
                  rowInfo,
                  column,
                  instance,
                  this
                );
                props = { ...props, ...givenProps };
              }

              return props;
            }}
            getTrProps={(state, rowInfo, column) => {
              let props = {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
                style: {
                  background:
                    STATUS_COLORS[rowInfo?.original?.status]?.rowBackground,
                },
              };

              if (typeof getTrProps === 'function') {
                let givenProps = getTrProps(state, rowInfo, column, this);
                props = { ...props, ...givenProps };
              }

              return props;
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const enhance = compose(
  connect(
    ({
      Brands: {
        brand: { multinational },
      },
      Auth: {
        user: { id },
      },
    }) => ({ multinational, id })
  )
);

export default enhance(SubTasksList);

import React from 'react';
import { withTranslation } from 'react-i18next';

const DescriptionLettersCounter = ({ t, wordsCount }) => {
  let color;
  switch (true) {
    case wordsCount <= 155 && wordsCount > 55:
      color = 'green';
      break;
    case wordsCount <= 160 && wordsCount > 155:
      color = 'orange';
      break;
    case wordsCount > 160:
      color = 'red';
      break;
    default:
      break;
  }

  return (
    <span>
      {t('seo.metaDesc')} |
      <span style={{ color }}>
        {' '}
        {t('brandCfg.charCount')}: {wordsCount}
      </span>
    </span>
  );
};

export default withTranslation()(DescriptionLettersCounter);

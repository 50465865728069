import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Tooltip,
  FormLabel,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import GridItem from 'components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import SelectInput from 'components/FormSelect/SelectInput';
import ShortAddressFormConfirmModal from '../components/ShortAddressFormConfirmModal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_USE_MINI_ADDRESS_FORM } from 'helpers/roles';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import { withToast } from 'material-ui-toast-redux';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';

const ConfigClientPanelModule = ({
  state: {
    showPricePreview,
    allowDietScroll,
    allowVariantAutoScroll,
    allowOrderTypeScroll,
    allowDietTimeScroll,
    allowCalendarScroll,
    allowCalorificScroll,
  },
  setState,
  module,
  classes,
  handleModuleUpdate,
  setEnabledPaymentTypes,
  openToast,
}) => {
  const { t } = useTranslation();
  const [isShortAddressFormModalOpened, setIsShortAddressFormModalOpened] =
    useState(false);

  useEffect(() => {
    if (!module.isOneTimePayPaymentMode && module.isSubscriptionPaymentMode) {
      handleModuleUpdate({ ...module, defaultPaymentMode: 1 });
    }

    if (module.isOneTimePayPaymentMode && !module.isSubscriptionPaymentMode) {
      handleModuleUpdate({ ...module, defaultPaymentMode: 2 });
    }
  }, [module.isOneTimePayPaymentMode, module.isSubscriptionPaymentMode]);

  const hanleChecboxVariantsValidator = () => {
    if (module.enableVariantStep && allowVariantAutoScroll) {
      openToast({
        messages: [
          t(
            'module.configClientPanel.enableVariantStep.toast',
            'Wyłączenie widoczności wariantu automatycznie dezaktywuje autoscroll do poziomu wariantu'
          ),
        ],
        type: 'warning',
        autoHideDuration: TOAST_DURATIONS.XL,
      });

      setState(prevState => {
        return {
          ...prevState,
          allowVariantAutoScroll: false,
        };
      });
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'ConfigClientPanelModule',
        })}
      </div>
    );
  }

  const isUseLessFieldsEnabled = isGranted(ROLE_EDIT_USE_MINI_ADDRESS_FORM);

  return (
    <CardWrapper
      title={`${t(
        'brandCfg.newOrderForm',
        'Konfiguracja formularza nowego zamówienia'
      )} *`}
    >
      <CardBody>
        <GridContainer>
          <GridItem md={12} lg={12}>
            <Divider style={{ marginBottom: '20px' }} />
            <GridContainer>
              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowVariantAutoScroll}
                      onChange={() => {
                        if (!module.enableVariantStep) {
                          openToast({
                            messages: [
                              t(
                                'module.configClientPanel.allowVariantAutoScroll.toast',
                                'Aby aktywacja scrollowania było możliwe, należy włączyć widoczność wariantu.'
                              ),
                            ],
                            type: 'warning',
                            autoHideDuration: TOAST_DURATIONS.XL,
                          });
                          return;
                        }
                        setState(prevState => {
                          return {
                            ...prevState,
                            allowVariantAutoScroll: !allowVariantAutoScroll,
                          };
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t(
                    'brands.newBrandForm.variantAutoScroll',
                    'Autoscroll do poziomu wariantu'
                  )}
                />
              </GridItem>
              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowDietScroll}
                      onChange={() => {
                        setState(prevState => ({
                          ...prevState,
                          allowDietScroll: !allowDietScroll,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t(
                    'brands.newBrandForm.allowDietScroll',
                    'Autoscroll do poziomu diety'
                  )}
                />
              </GridItem>

              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowOrderTypeScroll}
                      onChange={() => {
                        setState(prevState => ({
                          ...prevState,
                          allowOrderTypeScroll: !prevState.allowOrderTypeScroll,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          'brands.newBrandForm.allowOrderTypeScroll',
                          'Autoscroll do typu zamówienia'
                        )}
                      </span>
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <h4>
                              {t(
                                'brands.newBrandForm.allowOrderTypeScroll.tooltip'
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize="small"
                            style={{ color: 'grey', display: 'block' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
              </GridItem>
              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowDietTimeScroll}
                      onChange={() => {
                        setState(prevState => ({
                          ...prevState,
                          allowDietTimeScroll: !prevState.allowDietTimeScroll,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          'brands.newBrandForm.allowDietTimeScroll',
                          'Autoscroll do czasu trwania diety'
                        )}
                      </span>
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <h4>
                              {t(
                                'brands.newBrandForm.allowDietTimeScroll.tooltip'
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize="small"
                            style={{ color: 'grey', display: 'block' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
              </GridItem>
              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowCalendarScroll}
                      onChange={() => {
                        setState(prevState => ({
                          ...prevState,
                          allowCalendarScroll: !prevState.allowCalendarScroll,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          'brands.newBrandForm.allowCalendarScroll',
                          'Autoscroll do kalendarza'
                        )}
                      </span>
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <h4>
                              {t(
                                'brands.newBrandForm.allowCalendarScroll.tooltip'
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize="small"
                            style={{ color: 'grey', display: 'block' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
              </GridItem>

              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowCalorificScroll}
                      onChange={() => {
                        setState(prevState => ({
                          ...prevState,
                          allowCalorificScroll: !prevState.allowCalorificScroll,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t(
                    'brands.newBrandForm.allowCalorificScroll',
                    'Autoscroll do kaloryczności'
                  )}
                />
              </GridItem>

              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.showMenuPreviewOnOrderForm}
                      onChange={() =>
                        handleModuleUpdate({
                          ...module,
                          showMenuPreviewOnOrderForm:
                            !module.showMenuPreviewOnOrderForm,
                        })
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t(
                    'module.configClientPanel.showMenuPreviewOnOrderForm',
                    'Pokaż menu i opis diety na modalu'
                  )}
                />
              </GridItem>

              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showPricePreview}
                      onChange={() => {
                        setState(prevState => ({
                          ...prevState,
                          showPricePreview: !showPricePreview,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t(
                    'module.configClientPanel.showPricePreview',
                    'Pokazuj podgląd cen'
                  )}
                />
              </GridItem>

              <GridItem md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.enableVariantStep}
                      onChange={() => {
                        hanleChecboxVariantsValidator();
                        return handleModuleUpdate({
                          ...module,
                          enableVariantStep: !module.enableVariantStep,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t('module.configClientPanel.enableVariantStep')}
                      </span>
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <h4>
                              {t(
                                'module.configClientPanel.enableVariantStep.tooltip'
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize="small"
                            style={{ color: 'grey', display: 'block' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
              </GridItem>

              <GridItem md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.enableAdditionalMealTypesStep}
                      onChange={() => {
                        hanleChecboxVariantsValidator();
                        return handleModuleUpdate({
                          ...module,
                          enableAdditionalMealTypesStep:
                            !module.enableAdditionalMealTypesStep,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          'module.configClientPanel.enableAdditionalMealTypesStep'
                        )}
                      </span>
                    </div>
                  }
                />
              </GridItem>
              <GridItem md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.useAddressesWithLessFields}
                      onChange={() => {
                        if (isUseLessFieldsEnabled) {
                          handleModuleUpdate({
                            ...module,
                            useAddressesWithLessFields:
                              !module.useAddressesWithLessFields,
                          });
                        }
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                      disabled={!isUseLessFieldsEnabled}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          'brands.newBrandForm.useMiniFormAddress',
                          'Użyj skróconej wersji formularza adresu dostawy'
                        )}
                      </span>
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <h4>
                              {t(
                                'brands.newBrandForm.useMiniFormAddress.tooltip'
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize="small"
                            style={{ color: 'grey', display: 'block' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
              </GridItem>

              <GridItem md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.allowSelectMenuBeforeOrderDiet}
                      onChange={() =>
                        handleModuleUpdate({
                          ...module,
                          allowSelectMenuBeforeOrderDiet:
                            !module.allowSelectMenuBeforeOrderDiet,
                        })
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', marginTop: '5px' }}>
                      <div>
                        {t(
                          'brands.newBrandForm.allowSelectMenuBeforeOrderDiet',
                          'Pokaż krok "Wybór menu"'
                        )}
                      </div>
                      <div>
                        <Tooltip
                          title={
                            <div>
                              <h4>
                                {t(
                                  'brands.newBrandForm.allowSelectMenuBeforeOrderDiet.tooltip',
                                  "Krok widoczny tylko gdy wybór menu jest w trybie 'Zmiana menu wdłg. cen diet'"
                                )}
                              </h4>
                            </div>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize={'small'}
                            style={{ color: 'grey', margin: '0 0 0 5px' }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
              </GridItem>

              <GridItem md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.showDiscountCodeInputInBasket}
                      onChange={() => {
                        handleModuleUpdate({
                          ...module,
                          showDiscountCodeInputInBasket:
                            !module.showDiscountCodeInputInBasket,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          '$*brands.newBrandForm.showDiscountCodeInputInBasket',
                          'Pokazuj pole na kod rabatowy w koszyku'
                        )}
                      </span>
                    </div>
                  }
                />
              </GridItem>
              <GridItem md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module.showKnowAboutUs}
                      onChange={() => {
                        handleModuleUpdate({
                          ...module,
                          showKnowAboutUs: !module.showKnowAboutUs,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {t(
                          'showDiscountCodeInputInBasket',
                          'Pokazuj pole "Skąd o nas wiesz" na formularzu rejestracyjnym'
                        )}
                      </span>
                    </div>
                  }
                />
              </GridItem>
              <GridItem md={12}>
                <FormTextInput
                  label={t(
                    'brands.newBrandForm.initialBasketId',
                    'ID domyślnego koszyka'
                  )}
                  classes={classes}
                  name="initialBasketId"
                  value={module.initialBasketId}
                  disabled={false}
                  maxLength={36}
                  multiline={false}
                  handleChange={event => {
                    handleModuleUpdate({
                      ...module,
                      initialBasketId: event.target.value,
                    });
                  }}
                  inputSize={6}
                />
              </GridItem>
              <GridItem md={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {t(
                    '$*brandConfiguration.configClientPanelModule.calculatorMode.label',
                    '$$Wybierz tryb kalkulatora kaloryczności'
                  )}
                </FormLabel>
                <SelectInput
                  noGrid
                  classes={classes}
                  mapBy="label"
                  trackBy="value"
                  name="calculatorMode"
                  value={module.calculatorMode}
                  options={[
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.calculatorMode.calculatorCaterings',
                        '$$Kalkulator caterings'
                      ),
                      value: 'IN_APP_HARRIS_BENEDICT',
                    },
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.calculatorMode.calculatorExternal',
                        '$$Link do zewnętrzenej strony'
                      ),
                      value: 'LINK',
                    },
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.calculatorMode.disabled',
                        '$$Wyłączone'
                      ),
                      value: 'DISABLED',
                    },
                  ]}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      calculatorMode: e.target.value,
                    })
                  }
                  size={12}
                />

                {module.calculatorMode === 'LINK' && (
                  <FormTextInput
                    label={t('brandCfg.calcAddr')}
                    classes={classes}
                    name="calculatorLink"
                    value={module.calculatorLink}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        calculatorLink: e.target.value,
                      })
                    }
                    inputSize={12}
                    maxLength={255}
                  />
                )}
              </GridItem>
              <GridItem md={12}>
                <FormLabel className={classes.labelHorizontal}>
                  {t(
                    '$*brandConfiguration.configClientPanelModule.allowStickyButton',
                    '$$Wybierz tryb przycisku "Dalej" na formularzu zamówienia.'
                  )}
                </FormLabel>
                <SelectInput
                  noGrid
                  classes={classes}
                  mapBy="label"
                  trackBy="value"
                  name="allowStickyNextButton"
                  value={module.allowStickyNextButton}
                  options={[
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.allowStickyButtonTrue',
                        '$$Pływający przycisk'
                      ),
                      value: true,
                    },
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.allowStickyButtonFalse',
                        '$$Przycisk na dole strony'
                      ),
                      value: false,
                    },
                  ]}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      allowStickyNextButton: e.target.value,
                    })
                  }
                  size={12}
                />

                {module.calculatorMode === 'LINK' && (
                  <FormTextInput
                    label={t('brandCfg.calcAddr')}
                    classes={classes}
                    name="calculatorLink"
                    value={module.calculatorLink}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        calculatorLink: e.target.value,
                      })
                    }
                    inputSize={12}
                    maxLength={255}
                  />
                )}
              </GridItem>
              <GridItem md={12}>
                <FormLabel className={classes.labelHorizontal}>
                  {t(
                    '$*brandConfiguration.configClientPanelModule.paymentForDeliveryMode.label',
                    '$$Wybierz tryb płatności na dostawę'
                  )}
                </FormLabel>
                <SelectInput
                  noGrid
                  classes={classes}
                  mapBy="label"
                  trackBy="value"
                  name="paymentForDeliveryMode"
                  value={module.paymentForDeliveryMode}
                  options={[
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.paymentForDeliveryMode.PER_BAG',
                        '$$Tryb płatności za torbę - płatność naliczana jest za każdą torbę doręczoną w ramach diety'
                      ),
                      value: 'PER_BAG',
                    },
                    {
                      label: t(
                        '$*brandConfiguration.configClientPanelModule.paymentForDeliveryMode.REAL_DELIVERY',
                        '$$Tryb płatności za dostawę - płatność naliczana jest za każdą dostawę w ramach diety (koszt dostawy naliczany jest do pierwszego dnia bloku bloku dostaw który został zakupiony)'
                      ),
                      value: 'REAL_DELIVERY',
                    },
                  ]}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      paymentForDeliveryMode: e.target.value,
                    })
                  }
                  size={12}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <ShortAddressFormConfirmModal
            isOpened={isShortAddressFormModalOpened}
            handleClose={() => setIsShortAddressFormModalOpened(false)}
            handleConfirm={() => {
              handleModuleUpdate({
                ...module,
                useAddressesWithLessFields: !module.useAddressesWithLessFields,
              });
              setIsShortAddressFormModalOpened(false);
            }}
          />
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const enhance = compose(
  connect(({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
    selectedBrand,
    paymentTypes,
  })),
  withToast
);

export default enhance(ConfigClientPanelModule);

export const rootDefaultState = {
  alert: null,
  dateFrom: null,
  dateTo: null,
  applyToAllActiveDiets: true,
  updateDietDefaults: false,
  updateBags: false,
  updateSubscriptions: false,
  resetSubscriptionIntents: false,
  updateSubscriptionIntents: false,
  mapping: [],
};

export const mappingDefaultState = () => ({
  from: {
    diet: null,
    variant: null,
    calorific: null,
  },
  to: {
    diet: null,
    variant: null,
    calorific: null,
  },
});

export default rootDefaultState;

import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ShopAvailabilityCell from './ShopAvailabilityCell';
import { withTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

class ShopAvailabilityRow extends React.Component {
  render() {
    const {
      dish,
      typeSizes,
      dishSizes,
      handleSizeShopAvailabilityChange,
      handleSizeShopAvailabilityChangeRow,
      classes,
      t,
    } = this.props;

    const isAllSizesChecked = dishSizes.every(
      ({ allowedInShop }) => allowedInShop
    );

    return (
      <TableRow>
        <TableCell>
          <div>
            {t(
              'mealForm.shopAvailabilitySize',
              '$$Włącz do stałej oferty sklepu'
            )}
          </div>
          <div>
            <label
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '-14px',
                fontSize: '12px',
                cursor: 'pointer',
              }}
            >
              <Checkbox
                checked={isAllSizesChecked}
                onChange={() => {
                  handleSizeShopAvailabilityChangeRow(dishSizes);
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
              <span>
                {isAllSizesChecked
                  ? t(
                      'mealForm.shopAvailability.unselectAllSizes',
                      '$$Odznacz wszystko'
                    )
                  : t(
                      'mealForm.shopAvailability.selectAllSizes',
                      '$$Zaznacz wszystko'
                    )}
              </span>
            </label>
          </div>
        </TableCell>

        <TableCell />
        <TableCell />
        {typeSizes.map((size, sizeKey) => {
          return (
            <ShopAvailabilityCell
              key={sizeKey}
              dish={dish}
              classes={classes}
              size={size.size}
              dishSizes={dishSizes}
              handleSizeShopAvailabilityChange={
                handleSizeShopAvailabilityChange
              }
            />
          );
        })}
      </TableRow>
    );
  }
}

export default withTranslation()(ShopAvailabilityRow);

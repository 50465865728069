import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from '../../../helpers/helpers';
import { ROLE_CREATE_NOTIFICATION } from '../../../helpers/roles';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import { withTranslation } from 'react-i18next';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes } = this.props;
    return (
      <AdminTable title={this.props.t('list.messagesList')}>
        {isGranted(ROLE_CREATE_NOTIFICATION) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={this.props.t('form.menuPlanning.addMessages') + ' +'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/notifications"
          reportName={'notification'}
          columns={columnConfig}
          role="NOTIFICATION"
          minRows={20}
        />
      </AdminTable>
    );
  }
}

export default withTranslation()(withStyles(buttonsStyle)(List));

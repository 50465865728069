import React, { useCallback, useEffect, useState } from 'react';

import { sortByKey } from 'helpers/helpers';

import FormTextInput from 'components/FormTextInput/FormTextInput';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const SuperVariantBetaPricesTable = props => {
  const { t, classes, mealTypes, calories, onChange, multinational } = props;

  const [value, setValue] = useState(calories ?? []);

  const updateValueRow = useCallback(
    (calorificKey, mealType, changedField, newValue) => {
      setValue(prevValue => {
        return prevValue.map(el => {
          if (el.key === calorificKey) {
            el.superVariantMealType = el.superVariantMealType.map(
              superVariantMealType => {
                if (
                  (superVariantMealType.mealType?.['@id'] ??
                    superVariantMealType.mealType) ===
                  (mealType?.['@id'] ?? mealType)
                ) {
                  return {
                    ...superVariantMealType,
                    [changedField]: newValue,
                  };
                }
                return superVariantMealType;
              }
            );
          }
          return el;
        });
      });
    },
    [setValue]
  );

  useEffect(() => {
    const rawMealTypes = mealTypes.map(
      mealType => mealType?.['@id'] ?? mealType
    );
    const notCorrectCalories = value.filter(
      el => mealTypes.length !== el.superVariantMealType.length
    );

    if (notCorrectCalories.length === 0) {
      return;
    }
    setValue(prevValue => {
      return prevValue.map(prevCalorific => {
        if (mealTypes.length < prevCalorific.superVariantMealType.length) {
          prevCalorific.superVariantMealType = prevCalorific.superVariantMealType.filter(
            el => {
              return rawMealTypes.includes(el.mealType);
            }
          );
        }

        if (mealTypes.length > prevCalorific.superVariantMealType) {
          mealTypes.forEach(mealType => {
            if (
              !prevCalorific.superVariantMealType.find(
                el => el.mealType === (mealType?.['@id'] ?? mealType)
              )
            ) {
              prevCalorific.superVariantMealType.push({
                mealType,
                resignPrice: 0,
              });
            }
          });
        }

        return prevCalorific;
      });
    });
  }, [mealTypes, value]);

  useEffect(() => {
    setValue(value);
  }, [JSON.stringify(calories)]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  const pickMealType = (mealType, calorific) => {
    return calorific.superVariantMealType.find(
      el =>
        (el.mealType?.['@id'] ?? el.mealType) ===
        (mealType?.['@id'] ?? mealType)
    );
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>

          {mealTypes.map(el => (
            <TableCell key={`head-${el['@id']}`}>{el.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortByKey(value, 'position').map((calorific, index) => (
          <TableRow key={`ii-${index}`}>
            <TableCell rowSpan={1}>
              {calorific.name}{' '}
              {calorific.workName && calorific.name !== calorific.workName && (
                <>({calorific.workName})</>
              )}
            </TableCell>
            <TableCell>
              {t(
                'form.field.variant.superVariant.beta.value',
                'Wartość rezygnacji z typu posiłku'
              ) + ` [${multinational.currency}]`}
            </TableCell>
            {mealTypes.map((el, mIndex) => (
              <TableCell key={`super-variant-beta-price-${index}-${mIndex}`}>
                <FormTextInput
                  classes={classes}
                  name="minPrice"
                  type="number"
                  value={pickMealType(el, calorific)?.resignPrice ?? 0}
                  handleChange={ev =>
                    updateValueRow(
                      calorific.key,
                      el?.['@id'] ?? el,
                      'resignPrice',
                      parseFloat(ev.target.value)
                    )
                  }
                  inputSize={12}
                  maxLength={6}
                  inputProps={{ min: 0 }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SuperVariantBetaPricesTable;

import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button';
const FormIngredientHeaderSection = ({ state, t, match }) => {
  return (
    <h2
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {state.edit ? t('form.ingredientEdit') : t('form.ingredientCreation')}{' '}
      {state.edit && (
        <Button
          component={Link}
          color="primary"
          variant="contained"
          to={`/admin/ingredients/add?fromSavedIngredient/${match.params.id}`}
        >
          {t('form.copyToNewIngredient', 'Skopiuj do nowego składnika')}
        </Button>
      )}
    </h2>
  );
};

export default FormIngredientHeaderSection;

import { useMemo, useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { combineStyles } from 'helpers/helpers';
import { connect } from 'react-redux';
import { fetchUser } from 'actions/Users';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { FakeUserIcon } from 'components';
import NavTabs from 'components/NavTabs/NavTabs';
import GeneralInfo from './FormTabs/GeneralInfo/GeneralInfo';
import List from './FormTabs/Addresses/List';
import Moneybox from './FormTabs/Moneybox/Moneybox';
import withRole from 'components/Security/withRole';
import {
  ROLE_SHOW_ADDRESS,
  ROLE_SHOW_MONEY_BOX_HISTORY,
  ROLE_SHOW_ORDER,
  ROLE_SHOW_CLIENT_CALL,
  ROLE_NOTE_CLIENT,
  ROLE_SHOW_CLIENT_METRIC,
} from 'helpers/roles';
import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import Orders from './FormTabs/Orders/Orders';
import Diets from './FormTabs/Diets/Diets';
import Subscription from './FormTabs/Subscription/Subscription';
import Bags from './FormTabs/Bags/Bags';
import Rates from './FormTabs/Rates/Rates';
import History from './FormTabs/History/History';
import Bmi from './FormTabs/Bmi/Bmi';
import Calls from './FormTabs/Calls/Calls';
import Notes from 'components/Notes/Notes';
import { useTranslation } from 'react-i18next';
import { isGranted } from 'helpers/helpers';
import { fetchBrandConfigModulesPack } from 'actions/Brands';
import AntiPreferences from './FormTabs/AntiPreferences/AntiPreferences';

const UserForm = ({
  user,
  match,
  classes,
  history,
  location,
  selectedBrand,
  fetchUser = () => {},
  fetchBrandConfigModulesPack = () => {},
}) => {
  const { t } = useTranslation();

  const [userFullName, setUserFullName] = useState('');

  useEffect(() => {
    fetchBrandConfigModulesPack(selectedBrand, ['ConfigClientPanel']);
  }, []);

  const getUserFullName = name => {
    setUserFullName(name);
  };

  const userId = useMemo(() => match.params.id, [match]);
  const isEdit = useMemo(() => match.path.includes('edit'), [match]);

  const MoneyBox = withRole(ROLE_SHOW_MONEY_BOX_HISTORY, Moneybox);
  const DietsList = withRole(ROLE_SHOW_ORDER, Diets);
  const SubscriptionList = withRole(ROLE_SHOW_ORDER, Subscription);
  const BagsList = withRole(ROLE_SHOW_ORDER, Bags);
  const RatesList = withRole(ROLE_SHOW_ORDER, Rates);
  const CallsList = withRole(ROLE_SHOW_CLIENT_CALL, Calls);
  const OrdersList = withRole(ROLE_SHOW_ORDER, Orders);
  const AddressList = withRole(ROLE_SHOW_ADDRESS, List);

  return (
    <>
      <NavTabs
        title={
          isEdit ? (
            <div className={classes.user}>
              <span>{userFullName}</span>
              <FakeUserIcon email={user.email} domain={user.homeDomain} />
            </div>
          ) : (
            t('clients.clientCreation')
          )
        }
        changeTabOnMount={{
          bool: location.search.includes('openAddresses'),
          index: 1,
          timeout: 1000,
        }}
        tabs={
          isEdit
            ? [
                {
                  tabButton: t('clients.basicInfo'),
                  tabContent: (
                    <GeneralInfo
                      classes={classes}
                      cancelPath={'/admin/clients'}
                      getUserFullName={getUserFullName}
                    />
                  ),
                },
                {
                  tabButton: t('clients.addresses'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <AddressList
                          classes={classes}
                          currentPathname={`/admin/clients/edit/${userId}`}
                          isChangeUrlLoader={true}
                        />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.moneybox'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <MoneyBox
                          userMoneybox={user.moneyBox}
                          fetchUser={fetchUser}
                          classes={classes}
                        />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.diets'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <DietsList userId={userId} />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.orders'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <OrdersList
                          history={history}
                          classes={classes}
                          userId={userId}
                        />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.subscriptions'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <SubscriptionList userId={userId} />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.bags'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <BagsList userId={userId} />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.comments'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <RatesList userId={userId} t={t} />
                      </CardBody>
                    </Card>
                  ),
                },
                {
                  tabButton: t('clients.historyActivity'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <History
                          userId={userId}
                          history={history}
                          classes={classes}
                        />
                      </CardBody>
                    </Card>
                  ),
                },
                ...(isGranted(ROLE_SHOW_CLIENT_METRIC)
                  ? [
                      {
                        tabButton: t('clients.bmi'),
                        tabContent: (
                          <Card>
                            <CardBody>
                              <Bmi
                                userId={userId}
                                history={history}
                                classes={classes}
                              />
                            </CardBody>
                          </Card>
                        ),
                      },
                    ]
                  : []),
                {
                  tabButton: t('clients.antiPreferences'),
                  tabContent: (
                    <AntiPreferences userId={userId} classes={classes} />
                  ),
                },
                {
                  tabButton: t('clients.connections', 'Połączenia'),
                  tabContent: (
                    <Card>
                      <CardBody>
                        <CallsList userId={userId} />
                      </CardBody>
                    </Card>
                  ),
                },
                ...(isGranted(ROLE_NOTE_CLIENT)
                  ? [
                      {
                        tabButton: t('clients.notes', 'Notatki'),
                        tabContent: (
                          <Card>
                            <CardBody>
                              <Notes
                                classes={classes}
                                urlParam={`/clients/${userId}`}
                                notePermission={ROLE_NOTE_CLIENT}
                              />
                            </CardBody>
                          </Card>
                        ),
                      },
                    ]
                  : []),
              ]
            : [
                {
                  tabButton: t('clients.basicInfo'),
                  tabContent: <GeneralInfo classes={classes} />,
                },
              ]
        }
      />
    </>
  );
};

const styles = {
  user: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: '-0.25rem 0',
  },
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle, styles);

const mapStateToProps = state => ({
  user: state.Users.user,
  selectedBrand: state.Auth.selectedBrand,
});

const mapDispatchToProps = {
  fetchUser,
  fetchBrandConfigModulesPack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combinedStyles)(withRouter(UserForm)));

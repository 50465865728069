import types from 'helpers/constants';

const initialState = {
  subBrands: [],
  loading: true,
};

function SubBrands(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SUBBRANDS:
      return {
        ...state,
        loading: false,
        subBrands: action.payload.subBrands,
      };
    default:
      return state;
  }
}

export default SubBrands;

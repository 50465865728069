import React from 'react';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import InfoBannerCard from 'components/CMS/InfoBannerCard';

const Banner = ({ t, number, state, setState, page }) => {
  const getImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setState(prev => ({
        ...prev,
        bannerDesktop: data?.['@id'],
        bannerDesktopUrl: data?.contentUrl,
        desktop: data,
      }));
    }

    if (name === 'infoBannerMobile') {
      setState(prev => ({
        ...prev,
        bannerMobile: data?.['@id'],
        bannerMobileUrl: data?.contentUrl,
        mobile: data,
      }));
    }
  };

  const removeImage = name => {
    if (name === 'infoBannerDesktop') {
      setState(prev => ({
        ...prev,
        bannerDesktop: null,
        bannerDesktopUrl: null,
        desktop: null,
      }));
    }

    if (name === 'infoBannerMobile') {
      setState(prev => ({
        ...prev,
        bannerMobile: null,
        bannerMobileUrl: null,
        mobile: null,
      }));
    }
  };
  const handleBannerTextChange = ({ target }) => {
    if (target?.name === 'bannerHeader') {
      const heading = target?.value;

      setState(prev => ({ ...prev, heading }));
    }

    if (target?.name === 'bannerText') {
      const content = target?.value;

      setState(prev => ({ ...prev, content }));
    }
  };

  const handleCheckboxChange = () => {
    setState(prev => ({ ...prev, enabled: !prev.enabled }));
  };

  return (
    <InfoBannerCard
      title={`${t('cms.homePage.bannerTitle')} ${number}`}
      showBannerTitle={`${t('cms.homePage.showInfoBanner')} ${number}`}
      getImage={getImage}
      bannerText={state.content}
      removeImage={removeImage}
      bannerHeader={state.heading}
      handleInputChange={handleBannerTextChange}
      infoBannerCheckbox={state.enabled}
      infoBannerMobileUrl={
        page === 'blog' ? state?.bannerMobileUrl : state?.mobile?.contentUrl
      }
      infoBannerDesktopUrl={
        page === 'blog' ? state?.bannerDesktopUrl : state?.desktop?.contentUrl
      }
      handleCheckboxChange={handleCheckboxChange}
    />
  );
};

const enhance = compose(withTranslation());

export default enhance(Banner);

import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { get } from 'helpers/apiHelpers';

import { withStyles } from '@material-ui/core/styles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import CustomizedTable from './InfoTable';
import { connect } from 'react-redux';
import { fetchDiets } from '../../actions/Diets';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class Preview extends Component {
  state = {
    code: {},
    isLoading: true,
  };

  previewId = this.props.match.params.id;

  componentDidMount() {
    this.props.fetchDiets();
    get(`discount-codes/${this.previewId}`).then(response =>
      this.setState({ code: response, isLoading: false })
    );
  }

  render() {
    const { classes, t } = this.props;

    return (
      <form
        style={{
          opacity: this.state.isLoading ? 0 : 1,
          transition: '0.3s all',
        }}
      >
        <AdminTable
          title={t('promoCodes.preview.prev', 'Podgląd kodu rabatowego')}
        >
          <h3>
            {t('orders.discountCode', 'Kod rabatowy')}:{' '}
            <b>{this.state.code.code}</b>
            {/* promoCodes.preview.discCode */}
          </h3>
          {this.state.code.onlyAdminCanUse ? (
            <h4>
              <b>
                {t(
                  'promoCodes.preview.onlyAdmin',
                  'Tego kodu może użyć tylko administrator'
                )}
              </b>
            </h4>
          ) : null}
          <GridContainer>
            <GridItem sm={12}>
              <CustomizedTable
                code={this.state.code}
                diets={this.props.diets}
                previewId={this.previewId}
              />
            </GridItem>
          </GridContainer>
          <FormControlButtons
            classes={classes}
            discardText={t('promoCodes.preview.return', 'Wróć do listy kodów')}
            cancelPath="/admin/discount-codes"
          />
        </AdminTable>
      </form>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => ({
  diets: state.Diets.diets,
});

const mapDispatchToProps = dispatch => ({
  fetchDiets: () => dispatch(fetchDiets()),
});

const enhance = compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
);

export default enhance(Preview);

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GeneralInfo from './FormTabs/GeneralInfo/GeneralInfo';

import { useTranslation } from 'react-i18next';
import { fetchBrandConfigModulesPack } from 'actions/Brands';

const AddUserForm = ({
  classes,
  selectedBrand,
  fetchBrandConfigModulesPack = () => {},
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchBrandConfigModulesPack(selectedBrand, ['ConfigClientPanel']);
  }, []);

  return (
    <>
      <h2>{t('clients.clientCreation', '$$Tworzenie klienta')}</h2>
      <GeneralInfo classes={classes} cancelPath={'/admin/clients'} />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  selectedBrand: state.Auth.selectedBrand,
});

const mapDispatchToProps = {
  fetchBrandConfigModulesPack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combinedStyles)(withRouter(AddUserForm)));

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CodeServingCell from './CodeServingCell';

class CodeServingRow extends React.Component {
  render() {
    const { mealTypeSizes, dishSizes, t } = this.props;

    return (
      <TableRow>
        <TableCell>{t('common.dishSizeCode', 'Kod porcji')}</TableCell>
        <TableCell />
        <TableCell />
        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => (
          <CodeServingCell
            key={mealTypeSizeKey}
            size={mealTypeSize.size}
            dishSizes={dishSizes}
          />
        ))}
      </TableRow>
    );
  }
}

export default CodeServingRow;

import Moment from 'moment';

export const dateTimeCell = (date, noTime) => {
  const _format = noTime
    ? `${Moment.localeData().longDateFormat('L')}`
    : `${Moment.localeData().longDateFormat(
        'L'
      )} ${Moment.localeData().longDateFormat('LTS')}`;
  return new Moment(date).format(_format);
};

import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const LabelWithTooltip = ({ label, tooltip }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ marginLeft: '10px' }}>
        <Tooltip title={<h4>{tooltip}</h4>} placement="right">
          <Info fontSize="small" style={{ color: 'grey', display: 'block' }} />
        </Tooltip>
      </div>
    </div>
  );
};

export default LabelWithTooltip;

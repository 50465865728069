import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardBody from 'components/Card/CardBody';
import DataGrid from 'components/DataGrid/DataGrid';
import PdfDialog from 'components/PdfDialog/PdfDialog';

import columnConfig from './columnConfig';
import CardWrapper from 'components/Card/CardWrapper';

const EmployeeWorkHistory = ({ userId, classes }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState({});
  const [isPDFPreviewOpened, setIsPDFPreviewOpened] = useState(false);

  return (
    <>
      <CardWrapper title={t('$*employees.workHistory', '$$Historia pracy')}>
        <CardBody>
          <DataGrid
            actions={false}
            url={`/employees/${userId}/time-entries`}
            minRows={20}
            columns={columnConfig(t)}
            reportName={'tasks'}
            remove={true}
            paginationTop={true}
            paginationBottom={false}
          />
        </CardBody>
      </CardWrapper>
      <PdfDialog
        fileUrl={selectedItem?.task?.['@id']}
        isOpened={isPDFPreviewOpened}
        closeModal={() => {
          setSelectedItem({});
          setIsPDFPreviewOpened(false);
        }}
      />
    </>
  );
};

export default EmployeeWorkHistory;

export const columnVitamins = [
  {
    title: 'form.field.ingredient.vitaminA',
    type: 'number',
    name: 'vitaminA',
    unit: 'µg',
  },
  {
    title: 'form.field.ingredient.thiamine',
    type: 'number',
    name: 'vitaminB1',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.riboflavin',
    type: 'number',
    name: 'vitaminB2',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.niacin',
    type: 'number',
    name: 'vitaminB3',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.folates',
    type: 'number',
    name: 'vitaminB9',
    unit: 'µg',
  },
  {
    title: 'form.field.ingredient.vitaminB12',
    type: 'number',
    name: 'vitaminB12',
    unit: 'µg',
  },
  {
    title: 'form.field.ingredient.vitaminB6',
    type: 'number',
    name: 'vitaminB6',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.vitaminC',
    type: 'number',
    name: 'vitaminC',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.vitaminD',
    type: 'number',
    name: 'vitaminD',
    unit: 'µg',
  },
  {
    title: 'form.field.ingredient.vitaminE',
    type: 'number',
    name: 'vitaminE',
    unit: 'mg',
  },
  {
    title: 'form.field.ingredient.carotene',
    type: 'number',
    name: 'carotene',
    unit: 'µg',
  },
];

import React from 'react';
import { useTranslation } from 'react-i18next';

import DataGrid from 'components/DataGrid/DataGrid';
import historyColumnConfig from 'views/Employees/historyColumnConfig';

import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CardWrapper from 'components/Card/CardWrapper';

const EmployeeActivityHistory = ({ classes, match }) => {
  const { t } = useTranslation();

  return (
    <CardWrapper title={t('clients.historyActivity', '$$Historia aktywności')}>
      <CardBody>
        <GridContainer>
          <GridItem sm={12}>
            <DataGrid
              refreshOnFetch
              actions={false}
              paginationTop={true}
              paginationBottom={false}
              export={true}
              minRows={18}
              url="/entity-logs"
              role={'EMPLOYEE'}
              columns={historyColumnConfig()}
              manipulateQuery={(requestData, query) => {
                query['actor.id[]'] = match.params.id;
                query['actor.id[_operator]'] = 'eq';
                if (query.loggedAt) {
                  const filters = query.loggedAt;
                  delete query.loggedAt;
                  if (filters.after) {
                    query['loggedAt[after]'] = filters.after;
                  }
                  if (filters.before) {
                    query['loggedAt[before]'] = filters.before;
                  }
                }
                return query;
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default EmployeeActivityHistory;

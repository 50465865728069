import { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router';

import List from 'views/MealsNew/List/Meals';

import MealFormComponent from './MealFormComponent';
import MealFormContainer from './MealFormContainer';
import Settings from './Settings';

import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_DISH,
  ROLE_EDIT_DISH,
  ROLE_SHOW_MODULE_DISH_PDF_GENERATOR,
  ROLE_SHOW_DISH,
} from 'helpers/roles';

const WrappedMealForm = MealFormContainer(MealFormComponent);

const MealRoute = ({ props }) => {
  const { history, match } = props;

  const ListSecured = withRole(ROLE_SHOW_DISH, List);
  const SettingsSecured = withRole(
    ROLE_SHOW_MODULE_DISH_PDF_GENERATOR,
    Settings
  );
  const CreateForm = withRole(ROLE_CREATE_DISH, WrappedMealForm);
  const EditForm = withRole(ROLE_EDIT_DISH, WrappedMealForm);
  useEffect(() => {
    if (
      match.pathname !== history.location.pathname &&
      !window.location.pathname.includes('/admin/meals/edit/') &&
      !window.location.pathname.includes('/admin/meals/add/')
    ) {
      history.push(match.path);
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <CreateForm {...props} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => {
            return <EditForm {...props} {...parentProps} />;
          }}
        />
        <Route
          path={`${match.url}/settings`}
          render={parentProps => {
            return <SettingsSecured {...props} {...parentProps} />;
          }}
        />
      </Switch>
    </Router>
  );
};

export default MealRoute;

import Datetime from 'react-datetime';
import React from 'react';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { Add, Remove } from '@material-ui/icons';

const RatesMenuSectionSettings = ({
  classes,
  t,
  state,
  handleRatingTimeChange,
  descriptionDays,
  handleRatingDayChange,
  setState,
  myStyle,
}) => {
  return (
    <div className="new-brand-form-rating__wrapper">
      <div className="new-brand-form-rating__header">
        <p>Ocena Menu</p>
      </div>

      {/* <div className="new-brand-form-rating__input-wrapper"> */}
      <div
        className={`new-brand-form-rating__input-wrapper ${myStyle.inputDateTime}`}
      >
        <p>możliwa do godziny:</p>

        <Datetime
          dateFormat={false}
          value={state.ratingDateAndTimeNewView.time}
          onChange={handleRatingTimeChange}
          inputProps={{
            placeholder: t('zones.select'),
            readOnly: true,
            zIndex: 1,
          }}
          locale="pl"
        />

        <div style={{ marginTop: '-2px', marginLeft: '10px' }}>
          <FormTextInput
            classes={classes}
            value={descriptionDays()}
            handleChange={(e, obj) => {
              handleRatingDayChange(e, e.target);
            }}
            multiline={true}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <Remove
            style={{
              cursor: 'pointer',
              border: '1px solid black',
              borderRadius: '50%',
            }}
            onClick={() => {
              if (state.ratingDateAndTimeNewView.day > 0) {
                setState(prevState => ({
                  ...prevState,
                  ratingDateAndTimeNewView: {
                    day: prevState.ratingDateAndTimeNewView.day - 1,
                    time: prevState.ratingDateAndTimeNewView.time,
                  },
                }));
              }
            }}
          />
          <Add
            style={{
              cursor: 'pointer',
              marginLeft: '10px',
              border: '1px solid black',
              borderRadius: '50%',
            }}
            onClick={() => {
              if (state.ratingDateAndTimeNewView.day < 21) {
                setState(prevState => ({
                  ...prevState,

                  ratingDateAndTimeNewView: {
                    day: prevState.ratingDateAndTimeNewView.day + 1,
                    time: prevState.ratingDateAndTimeNewView.time,
                  },
                }));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RatesMenuSectionSettings;

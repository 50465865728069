import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ClearIcon from '@material-ui/icons/Clear';

import {
  dangerColor,
  goldColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react';

import { currencyToSymbol } from 'utils/currencies';

import Select from 'components/CustomSelect/Select';
import MaterialButton from '@material-ui/core/Button';
import AdditionalMealTypeModalCalendar from './AdditionalMealTypeModalCalendar';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  boxSectionTitle: {
    color: goldColor,
    margin: '0',
    fontWeight: '500',
  },
  boxSectionContainer: {
    width: '100%',
    padding: '15px',
    border: `1px solid ${grayColor[4]}`,
    borderRadius: '5px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    borderBottom: `1px solid ${grayColor[4]}`,
  },
  iconWrapper: {
    color: dangerColor[0],
    cursor: 'pointer',
  },
  iconWrapperSecondary: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    marginBottom: '5px',
    color: goldColor,
    borderColor: goldColor,
  },
  clearIcon: {
    width: '17px',
  },
  dateWithAmount: {
    width: '100%',
    display: 'flex',
  },
  date: {
    width: '50%',
    border: `1px solid ${grayColor[4]}`,
    borderRadius: '5px',
    textAlign: 'center',
    padding: '10px 10px 5px 10px',
    margin: '10px 0 0 0',
  },
  amount: {
    width: '50%',
    textAlign: 'center',
    margin: '10px 10px 0 10px',
  },
});

const MAX_ADDONS_AMOUNT = 15;

const AdditionalMealTypeBox = ({
  price,
  label = '',
  value = '',
  dateWithAmount = [],
  onRemoveClick = () => {},
  onRemoveDay = () => {},
  calendarSettings,
  diet,
  updateDiet,
  selectedDietAdditionalCalendar,
  setSelectedDietAdditionalCalendar,
  isOpened,
  setIsOpened,
  editAdditionalMealTypes,
  setEditAdditionalMealTypes,
} = {}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const currency = useSelector(
    state => state?.Brands.brand.multinational.currency
  );

  const getAmountOptions = () => {
    let amountOptions = [];

    for (let index = 0; index < MAX_ADDONS_AMOUNT; index++) {
      amountOptions.push({
        value: index + 1,
        label: `${index + 1} ${t('orders.addons.quantity', '$$szt.')}`,
      });
    }

    return amountOptions;
  };

  return (
    <>
      <div className={classes.boxSectionContainer}>
        <div className={classes.headerWrapper}>
          <h3 className={classes.boxSectionTitle}>
            {label} ({price} {currencyToSymbol(currency)})
          </h3>
          <div>
            <MaterialButton
              className={classes.editButton}
              variant="outlined"
              fullWidth
              onClick={() => {
                setEditAdditionalMealTypes(value);
                setIsOpened(true);
              }}
            >
              {t('orders.additionalMealTypes.edit', 'Edytuj')}
            </MaterialButton>
          </div>

          <div
            className={classes.iconWrapper}
            onClick={() => onRemoveClick(value)}
          >
            <ClearIcon className={classes.clearIcon} />
          </div>
        </div>

        <div>
          {dateWithAmount &&
            dateWithAmount.map(el => {
              return (
                <div className={classes.dateWithAmount}>
                  <div className={classes.date}>
                    {moment(el.date).format('DD.MM.YYYY')}
                  </div>
                  <div className={classes.amount}>
                    <Select
                      label={t('orders.select', '$$Wybierz')}
                      value={{
                        value: el.amount,
                        label: `${el.amount} ${t(
                          'orders.addons.quantity',
                          '$$szt.'
                        )}`,
                      }}
                      options={getAmountOptions()}
                      handleChange={e => {
                        const newAmount = e.value;
                        const dietReferences = { ...diet };
                        dietReferences.selectedAdditionalMealTypes.forEach(
                          mealTypes => {
                            if (mealTypes.value === value)
                              mealTypes.dateWithAmount.forEach(date => {
                                if (date.date === el.date) {
                                  date.amount = newAmount;
                                }
                              });
                          }
                        );
                        updateDiet({ ...dietReferences });
                      }}
                    />
                  </div>
                  <div
                    className={classNames(
                      classes.iconWrapper,
                      classes.iconWrapperSecondary
                    )}
                    onClick={() => onRemoveDay(el.date, value)}
                  >
                    <ClearIcon className={classes.clearIcon} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <AdditionalMealTypeModalCalendar
        diet={diet}
        calendarSettings={calendarSettings}
        isOpened={isOpened}
        onClose={setIsOpened}
        onSubmit={() => {}}
        value={value}
        label={label}
        updateDiet={updateDiet}
        selectedDietAdditionalCalendar={selectedDietAdditionalCalendar}
        setSelectedDietAdditionalCalendar={setSelectedDietAdditionalCalendar}
        editAdditionalMealTypes={editAdditionalMealTypes}
        setEditAdditionalMealTypes={setEditAdditionalMealTypes}
      />
    </>
  );
};

export default AdditionalMealTypeBox;

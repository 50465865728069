import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './List';
import Form from './Form';
import EditForm from './EditForm';
import Preview from './Preview';
class Roles extends Component {
  render() {
    const props = this.props;
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <List {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <Form {...props} />}
          />
          <Route
            path={`${this.props.match.url}/preview/:id`}
            render={parentProps => <Preview {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Roles);

import React from 'react';
import { useTranslation } from 'react-i18next';

const deafultConfig = [
  { minValue: 55, maxValue: 155, color: 'green' },
  { minValue: 155, maxValue: 160, color: 'orange' },
  { minValue: 160, color: 'red' },
];

const LettersCounter = ({
  label,
  wordsCount,
  colorsConfig = deafultConfig,
}) => {
  const { t } = useTranslation();

  const getColor = () => {
    const colorConfig = colorsConfig.find(({ minValue, maxValue }) =>
      maxValue
        ? wordsCount > minValue && wordsCount <= maxValue
        : wordsCount > minValue
    );
    return colorConfig?.color ?? 'gray';
  };

  return (
    <span>
      {`${label} | `}(
      <span style={{ color: getColor() }}>
        {' '}
        {t('brandCfg.charCount')}: {wordsCount}
      </span>
      )
    </span>
  );
};

export default LettersCounter;

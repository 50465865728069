import React from 'react';
import MIME_TYPES from './constants/mimeTypes';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

class BoxLabelsDateRange extends React.Component {
  getFileName =
    title =>
    ({ date }) => {
      let fileName = `${title
        .replace(this.props.t('reports.generatePrefix'), '')
        .replaceAll(' ', '_')}-${date}_(${moment().format(
        'YYYY-MM-DD_HH.mm'
      )})`;

      return fileName.charAt(0).toUpperCase() + fileName.slice(1);
    };

  render() {
    const { t } = this.props;
    const title = t('reports.reportBoxLabelsDateRange');
    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/bag-stickers-date-range"
        pdfUrl="reports/bag-stickers-date-range-pdf"
        mimeType={MIME_TYPES.xlsx}
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_BAG_STICKERS}
        pdfAvailable
        excelAvailable
        dateType="range"
        csvAvailable
        isSalesMarketLangViewEnabled={true}
        reportConfiguration={{
          report: 'BagStickers',
          fields: [
            {
              size: 12,
              field: 'allowPrivateDataInReport',
              name: 'allowPrivateDataInReport',
              inputType: 'checkbox',
            },
            {
              size: 12,
              field: 'codeSeparator',
              inputType: 'text',
            },
            {
              size: 12,
              field: 'addonsColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'addonsCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'groupAddressStreet',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <Tooltip
                    style={{ cursor: 'help' }}
                    title={
                      <div>
                        <h4>
                          {this.props.t(
                            'configuration.reports.report.DishCardCompanyConfiguration.sortBy.help'
                          )}
                        </h4>
                      </div>
                    }
                    placement="bottom"
                  >
                    <span>
                      {this.props.t(
                        'configuration.reports.report.DishCardCompanyConfiguration.sortBy.title'
                      )}
                    </span>
                  </Tooltip>
                ),
              },
              inputType: 'divider',
            },
            {
              size: 4,
              field: 'sortByContainerType',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByCalorificWorkName',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByChangedMenu',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByVariantName',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByDietWorkName',
              inputType: 'number',
            },
          ],
        }}
        useZoneCategories={true}
        archiveReportNames={['BAG_STICKERS']}
      />
    );
  }
}

export default withTranslation()(BoxLabelsDateRange);

import React from 'react';
import { withTranslation } from 'react-i18next';

const PromptContent = ({ t }) => {
  return (
    <div style={{ textAlign: 'left' }}>
      <p>{t('variants.save.prompt.paragraph1')}</p>
      <p>{t('variants.save.prompt.paragraph2')}</p>
      <p style={{ fontWeight: 'bold' }}>
        {t('variants.save.prompt.paragraph3')}
      </p>
      <ul>
        <li>{t('variants.save.prompt.listElement1')}</li>
        <li>{t('variants.save.prompt.listElement2')}</li>
        <li>{t('variants.save.prompt.listElement3')}</li>
      </ul>
    </div>
  );
};

export default withTranslation()(PromptContent);

import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from 'views/MenuPlanner/MenuMessages/List';
import Form from 'views/MenuPlanner/MenuMessages/Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_NOTIFICATION,
  ROLE_EDIT_NOTIFICATION,
  ROLE_SHOW_NOTIFICATION,
} from 'helpers/roles';

class MenuMessages extends Component {
  render() {
    const props = this.props;
    const ListSecured = withRole(ROLE_SHOW_NOTIFICATION, List);
    const CreateForm = withRole(ROLE_CREATE_NOTIFICATION, Form);
    const EditForm = withRole(ROLE_EDIT_NOTIFICATION, Form);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(MenuMessages);

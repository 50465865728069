import React, { Component } from 'react';
import { Route, Router, Switch, withRouter } from 'react-router';

import List from './List';
import Form from './Form';

class Roles extends Component {
  render() {
    const props = this.props;
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <List {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <Form {...props} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Roles);

import { forwardRef, useRef } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  isValidPhoneNumber as isValidPhoneNumberMin,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { shallowEqual, useSelector } from 'react-redux';

import ru from 'react-phone-number-input/locale/ru';
import fi from 'react-phone-number-input/locale/fi';
import en from 'react-phone-number-input/locale/en';
import pl from 'react-phone-number-input/locale/pl';
import it from 'react-phone-number-input/locale/it';
import de from 'react-phone-number-input/locale/de';
import ua from 'react-phone-number-input/locale/ua';
import es from 'react-phone-number-input/locale/es';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

import { isValidPhoneNumber as isValidPhoneNumberMobile } from 'react-phone-number-input/mobile';
import makeStyles from '@material-ui/styles/makeStyles';

const _labels = {
  ru,
  fi,
  en,
  pl,
  it,
  de,
  ua,
  es,
};

const getLabels = language => {
  const _lang = language.toLowerCase();
  if (Object.keys(_labels).includes(_lang)) {
    return _labels[_lang];
  } else {
    return _labels['en'];
  }
};

const useStyles = makeStyles({
  phoneInput: {
    paddingRight: '10px',
  },
});

export const ReactPhoneNumberAdapter = ({
  value,
  label,
  required,
  onChange,
  ...props
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const classes = useStyles();

  const countrySelectValue = useRef(null);

  const { multinational } = useSelector(
    ({
      Brands: {
        brand: { multinational },
      },
    }) => ({
      multinational,
    }),
    shallowEqual
  );

  const getErrorMessage = () => {
    const { number, countryCode } = value;

    if (
      required &&
      (!number || (typeof number === 'string' && number.length === 0))
    ) {
      return t('formValidation.required', 'Pole jest wymagane');
    }

    if (typeof number !== 'string') {
      return '';
    }

    const parsedValue = parsePhoneNumber(number);
    const countryEquals =
      parsedValue?.country && parsedValue?.country === countryCode;

    const isValid =
      parsedValue?.country === 'PL'
        ? isValidPhoneNumberMobile(number)
        : isValidPhoneNumberMin(number);

    return countryEquals && isValid
      ? ''
      : t(
          'formValidation.phoneInvalidInRegion',
          'Telefon nieprawidłowy w wybranym regionie'
        );
  };

  const errorMessage = getErrorMessage();

  return (
    multinational && (
      <PhoneInput
        className={classes.phoneInput}
        data-cy="my-phone-input"
        international
        withCountryCallingCode
        initialValueFormat
        error={!!errorMessage}
        labels={getLabels(language)}
        label={label}
        value={value.number}
        onChange={number => {
          onChange(
            number,
            // onChange is triggered after onCountryChange
            countrySelectValue.current ||
              value.countryCode ||
              multinational.defaultRegion
          );
        }}
        country={value.countryCode}
        defaultCountry={multinational.defaultRegion}
        onCountryChange={countryCode => {
          // onCountryChange triggers onChange
          countrySelectValue.current = countryCode;
        }}
        countryCallingCodeEditable={false}
        inputComponent={MuiTextFieldAdapter}
        helperText={errorMessage}
        {...props}
      />
    )
  );
};

const MuiTextFieldAdapter = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} />;
});

const defaultState = {
  name: '',
  shortName: '',
  position: '',
  code: '',
  errors: {},
  mealCategory: null,
  mealCategoryOptions: [],
};

export default defaultState;

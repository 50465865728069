import React from 'react';

import Datetime from 'react-datetime';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';

import '../CustomStyles/dateFilter.css';

class DateFilter extends React.Component {
  state = {
    date: this.props.value || null,
  };

  onChange = () => {
    this.props.onChange(this.state.date);
  };

  render() {
    return (
      <div
        style={{ display: 'flex', width: '100%' }}
        className="dateTimeFilterStyles"
      >
        <Datetime
          timeFormat={false}
          dateFormat={'YYYY-MM-DD'}
          fullWidth
          closeOnSelect={true}
          value={this.state.date === null ? null : new Moment(this.state.date)}
          onChange={async date => {
            if (
              this.state.after !== null &&
              Moment(date).format('YYYY-MM-DD') ===
                Moment(this.state.date).format('YYYY-MM-DD')
            ) {
              await this.setState({ date: null });
              return this.onChange();
            } else {
              await this.setState({ date: date.format('YYYY-MM-DD') });
              this.onChange();
            }
          }}
          inputProps={{
            placeholder: this.props.t('common.shared.selectDate'),
            readOnly: true,
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(DateFilter);

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableCell from '@material-ui/core/TableCell';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';
import menuPlannerUtlis from './utils';
import makeStyles from '@material-ui/styles/makeStyles';
import PlannerSummaryItem from './PlannerSummaryItem';
import { connect } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';

const Assumption = ({
  currentValue,
  expectedValue,
  margin,
  t,
  macroName,
  dietName,
}) => {
  if (null === currentValue || null === expectedValue || null === margin) {
    return null;
  }

  const isValid =
    currentValue <= expectedValue + margin &&
    currentValue >= expectedValue - margin;

  if (isValid) {
    return null;
  }

  return (
    <Tooltip
      style={{ cursor: 'help' }}
      title={
        <Card
          style={{
            backgroundColor: '#f65b52',
            color: '#fff',
            padding: '10px',
            fontWeight: 400,
          }}
        >
          <span style={{ fontSize: '14px' }}>
            {t(
              `menuPlanner.plannerCardSummary.waring.assumption.${macroName}`,
              { currentValue, expectedValue, margin, dietName }
            )}
          </span>
        </Card>
      }
      placement="right"
    >
      <WarningIcon
        style={{
          fontSize: '1.5em',
          color: '#f65b52',
          marginLeft: '3px',
          verticalAlign: 'middle',
        }}
      />
    </Tooltip>
  );
};

const useStyles = makeStyles({
  tableTd: { padding: '8px' },
  card: {
    maxWidth: '350px',
    margin: '0px auto',
    position: 'relative',
    overflow: 'initial',
  },
  cardContent: { padding: '16px', fontSize: '0.8rem' },
});

const PlannerBoxSummary = ({
  assumptionOfCarbohydrates,
  assumptionOfFats,
  assumptionOfProtein,
  assumptionOfRoughage,
  t,
  calorific,
  menuPlanners,
  dietName,
  currency,
}) => {
  const currencySymbol = currencyToSymbol(currency);

  const add = (a, b) => {
    return a + b;
  };

  const classes = useStyles();
  const getTotalMenuPlanners = property =>
    menuPlannerUtlis.getTotalMenuPlanners(property, calorific, menuPlanners);

  const getAverageRate = () => {
    let i = 0;
    let containsSizeWithoutRate = false;
    const sum = menuPlanners
      .map(row => {
        const mealTypeId = row.mealType.split('/').pop();
        const key = calorific + '_' + mealTypeId;

        if (
          typeof row.sizes === 'undefined' ||
          row.sizes === null ||
          typeof row.sizes[key] === 'undefined' ||
          row.sizes[key] === null
        ) {
          return 0;
        }
        if (
          row.sizes[key].rateCount === null ||
          row.sizes[key].rateCount === 0
        ) {
          containsSizeWithoutRate = true;
        }
        i++;
        return row.sizes[key].rateCount > 0
          ? row.sizes[key].rate / row.sizes[key].rateCount
          : 0;
      })
      .reduce(add, 0);

    if (containsSizeWithoutRate) {
      return '-';
    }

    return i > 0 ? (sum / i).toFixed(2) : '-';
  };

  const getTotalPercentage = (element, elementConstant, subtractElement) =>
    menuPlannerUtlis.getMacroInPercent(
      element,
      elementConstant,
      calorific,
      menuPlanners
    );

  const getRoughagePercentage = () => {
    const result = (
      ((getTotalMenuPlanners('roughage') * menuPlannerUtlis.constant.roughage) /
        getTotalMenuPlanners('calories')) *
      100
    ).toFixed(2);

    isNaN(result);
    return isNaN(result) ? 0 : result;
  };

  return (
    <TableCell className={classes.tableTd}>
      {parseInt(getTotalMenuPlanners('calories')) !== 0 && (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.calories')}
              value={<>{getTotalMenuPlanners('calories')} kcal </>}
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.protein')}
              value={
                <>
                  {getTotalMenuPlanners('protein')}g
                  <Tooltip
                    title={t(
                      'menuPlanner.plannerCardSummary.protein.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      (
                      {getTotalPercentage(
                        'protein',
                        menuPlannerUtlis.constant.protein
                      )}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage(
                        'protein',
                        menuPlannerUtlis.constant.protein
                      )
                    )}
                    expectedValue={assumptionOfProtein.assumption}
                    margin={assumptionOfProtein.margin}
                    macroName={'protein'}
                    dietName={dietName}
                    t={t}
                  />
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.carbohydrates')}
              value={
                <>
                  {getTotalMenuPlanners('carbohydrates')}g
                  <Tooltip
                    title={t(
                      'menuPlanner.plannerCardSummary.carbohydrates.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      (
                      {getTotalPercentage(
                        'carbohydrates',
                        menuPlannerUtlis.constant.carbohydrates
                      )}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage(
                        'carbohydrates',
                        menuPlannerUtlis.constant.carbohydrates
                      )
                    )}
                    expectedValue={assumptionOfCarbohydrates.assumption}
                    margin={assumptionOfCarbohydrates.margin}
                    macroName={'carbohydrates'}
                    dietName={dietName}
                    t={t}
                  />
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.carbohydratesNet')}
              value={
                <>
                  {Math.max(
                    0,
                    getTotalMenuPlanners('carbohydrates') -
                      getTotalMenuPlanners('roughage')
                  ).toFixed(2)}
                  g
                  <Tooltip
                    title={t(
                      'menuPlanner.plannerCardSummary.carbohydratesNet.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      (
                      {Math.max(
                        0,
                        getTotalPercentage(
                          'carbohydrates',
                          menuPlannerUtlis.constant.carbohydrates
                        )
                      ).toFixed(2)}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage(
                        'carbohydrates',
                        menuPlannerUtlis.constant.carbohydrates
                      )
                    )}
                    expectedValue={assumptionOfCarbohydrates.assumption}
                    margin={assumptionOfCarbohydrates.margin}
                    macroName={'carbohydrates'}
                    dietName={dietName}
                    t={t}
                  />
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.roughage')}
              value={
                <>
                  {getTotalMenuPlanners('roughage')}g
                  <Tooltip
                    title={t(
                      'menuPlanner.plannerCardSummary.protein.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      ({getRoughagePercentage()}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(getRoughagePercentage())}
                    expectedValue={assumptionOfRoughage.assumption}
                    margin={assumptionOfRoughage.margin}
                    macroName={'roughage'}
                    dietName={dietName}
                    t={t}
                  />
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.fat')}
              value={
                <>
                  {getTotalMenuPlanners('fat')}g
                  <Tooltip
                    title={t('menuPlanner.plannerCardSummary.fat.percentage')}
                    placement="top"
                  >
                    <span>
                      (
                      {getTotalPercentage('fat', menuPlannerUtlis.constant.fat)}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage('fat', menuPlannerUtlis.constant.fat)
                    )}
                    expectedValue={assumptionOfFats.assumption}
                    margin={assumptionOfFats.margin}
                    macroName={'fat'}
                    dietName={dietName}
                    t={t}
                  />
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.totalWeight')}
              value={
                <>
                  {getTotalMenuPlanners('weightAfterWOM')}g (
                  {getTotalMenuPlanners('weight')}g)
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.price')}
              value={
                <>
                  {getTotalMenuPlanners('price')} {currencySymbol}
                </>
              }
            />

            <PlannerSummaryItem
              title={t('menuPlanner.plannerCardSummary.new.rate')}
              value={getAverageRate()}
            />
          </CardContent>
        </Card>
      )}
    </TableCell>
  );
};

const mapStateToProps = state => {
  return {
    currency: state.Brands.brand.multinational.currency,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(PlannerBoxSummary)
);

import types from 'helpers/constants';

const initialState = {
  departments: [],
  loading: true,
};

function Departments(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload.departments,
        loading: false,
      };
    case types.REMOVE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter(
          allergen => allergen.id !== action.payload.removedItemId
        ),
      };
    case types.LOADING_DEPARTMENTS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default Departments;

const defaultState = {
  name: '',
  city: '',
  postCode: '',
  postCodeHelpText: '',
  postCodeIsValid: false,
  street: '',
  buildNumber: '',
  placeNumber: '',
  floor: '',
  neighbourhood: '',
  gate: '',
  keyToGate: '',
  keyToIntercom: '',
  comment: '',
  zone: '',
  zoneObj: null,
  defaultOptions: [
    { name: 'Tak', value: 2 },
    { name: 'Nie', value: 1 },
  ],
  default: 2,
  selectedDeliveryType: '',
  driver: '',
  drivers: [],
  deliveryType: null,
  deliveryHours: [],
  deliveryHourFrom: '',
  selectedHour: null,
  customDeliveryHours: false,
  user: '',
  addressLine1: '',
  addressLine2: '',
};

export default defaultState;

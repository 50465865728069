import React, { Component } from 'react';
import { combineStyles, isGranted, postCodeValidator } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import defaultState from './defaultState';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormLabel from '@material-ui/core/FormLabel';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';

import { get, put } from 'helpers/apiHelpers';
import { ROLE_CREATE_BRAND } from '../../helpers/roles';
import DataGrid from '../../components/DataGrid/DataGrid';
import columnConfig from '../Brands/columnConfig';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { difference, isEmpty, set as _set } from 'lodash';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { saveCompany } from 'actions/Company';
import FakturowniaWarehouseModule from 'views/BrandConfiguration/modules/FakturowniaWarehouseModule';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';
class MyCompany extends Component {
  state = {
    ...defaultState,
    brands: [],
    featuredBrands: [],
    brandsAfterChanges: [],
    modules: [],
  };

  componentDidMount() {
    const index = this.props.user.company.lastIndexOf('/') + 1;
    const companyId = this.props.user.company.slice(
      index,
      this.props.user.company.length
    );
    get(this.props.user.company).then(response => {
      const dishIngredientsDecimalPlaces =
        response.dietarySetup.dishIngredientsDecimalPlaces;
      this.setState({
        name: response.name,
        shortName: response.shortName,
        mainDomain: response.mainDomain,
        email: response.email,
        phone: response.phone,
        street: response.street,
        city: response.city,
        showFooterCaterings: response.showFooterCaterings,
        postCode: response.postCode,
        vatNumber: response.vatNumber,
        regon: response.regon,
        hotJarVersion: response.hotJar.version,
        hotJarId: response.hotJar.id,
        allowHotJar: response.hotJar.enabled,
        allowZohoSalesIQ: response.zohoSalesIQ.enabled,
        zohoSalesIQWidgetCode: response.zohoSalesIQ.widgetCode,
        defaultConfigFromBrand: response.defaultConfigFromBrand,
        dishIngredientsDecimalPlaces:
          dishIngredientsDecimalPlaces === 0
            ? this.props.t('common.lack')
            : dishIngredientsDecimalPlaces ?? 2,
        loadAllVariantsInitially:
          !!response.dietarySetup.loadAllVariantsInitially,
        alteredDietWorkName: response.dietarySetup.alteredDietWorkName,
        maxDishesToProducePerDay: response.maxDishesToProducePerDay,
        maxDishesToProducePerDayInBrand:
          response.maxDishesToProducePerDayInBrand ?? {},
        maxDishesToProducePerDayEnabled:
          response.maxDishesToProducePerDayEnabled,
      });
    });

    get(`/company/${companyId}/modules`).then(res => {
      this.setState({
        modules: res.configuration,
      });
    });

    get('/brands').then(response => {
      const activeBrands = [];
      response['hydra:member'].map(el => {
        if (el.highlightOnReports) {
          return activeBrands.push(el['@id']);
        }
        return null;
      });

      this.setState(prevState => ({
        ...prevState,
        brands: response['hydra:member'],
        featuredBrands: activeBrands,
        brandsAfterChanges: activeBrands,
        newValuesFalse: [],
        newValuesTrue: [],
      }));
    });
  }

  newSize = () => this.props.history.push(`/admin/brands/add`);

  handleInputChange = event => {
    this.setState(state => _set(state, event.target.name, event.target.value));
  };
  handleMaxDishesToProducePerDayInBrandChange = (event, brandId) => {
    this.setState(prevState => ({
      ...prevState,
      maxDishesToProducePerDayInBrand: {
        ...prevState.maxDishesToProducePerDayInBrand,
        [brandId]: isNaN(parseInt(event.target.value))
          ? null
          : parseInt(event.target.value),
      },
    }));
  };
  handlePhoneChange = (number, countryCode) => {
    this.setState(prevState => ({
      ...prevState,
      phone: {
        ...prevState.phone,
        number,
        countryCode,
      },
    }));
  };

  validateEmail = () => {
    return this.state.email.includes('@');
  };

  validateForm = () => {
    return this.state.name && this.state.maxDishesToProducePerDay >= 0;
  };

  handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...this.state.modules[type], ...data };
    this.setState(prev => ({
      ...prev,
      modules: {
        ...prev.modules,
        [type]: module,
      },
    }));
  };

  handleBrands = (e, selected) => {
    this.setState({
      brandsAfterChanges: e.target.value,
    });
    //return values to change flag on true, this method returns url and id -> /brands/1
    // this method sets the brand highlight on report
    const newValuesFalse = difference(
      this.state.featuredBrands,
      e.target.value
    );
    //return values to change flag on false, this method returns url and id -> /brands/1
    const newValuesTrue = difference(e.target.value, this.state.featuredBrands);
    if (!isEmpty(newValuesTrue)) {
      this.setState(prevState => ({
        ...prevState,
        newValuesTrue,
      }));
    }

    if (!isEmpty(newValuesFalse)) {
      this.setState(prevState => ({
        ...prevState,
        newValuesFalse,
      }));
    }
    return e;
  };

  async handleHighlightBrands() {
    if (!isEmpty(this.state.newValuesTrue)) {
      await Promise.all(
        this.state.newValuesTrue.map(el =>
          put(el, { highlightOnReports: true })
        )
      );
    }

    if (!isEmpty(this.state.newValuesFalse)) {
      await Promise.all(
        this.state.newValuesFalse.map(el =>
          put(el, { highlightOnReports: false })
        )
      );
    }
  }

  handleSubmit = () => {
    const index = this.props.user.company.lastIndexOf('/') + 1;
    const companyId = this.props.user.company.slice(
      index,
      this.props.user.company.length
    );
    if (!this.validateEmail()) {
      this.props.openToast({
        messages: [
          this.props.t(
            'myCompany.toast.incorrectEmail',
            'Nieprawidłowy adres email'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return false;
    }

    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [
          this.props.t(
            'myCompany.toast.complete',
            'Wypełnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    put(`company/${companyId}/modules`, {
      configuration: {
        FakturowniaWarehouse: this.state.modules['FakturowniaWarehouse'],
      },
    });

    const data = {
      name: this.state.name,
      shortName: this.state.shortName,
      mainDomain: this.state.mainDomain,
      email: this.state.email.toLowerCase(),
      phone: this.state.phone,
      street: this.state.street,
      city: this.state.city,
      postCode: this.state.postCode,
      vatNumber: this.state.vatNumber,
      regon: this.state.regon,
      defaultConfigFromBrand: this.state.defaultConfigFromBrand,
      showFooterCaterings: this.state.showFooterCaterings,
      maxDishesToProducePerDay: parseInt(this.state.maxDishesToProducePerDay),
      maxDishesToProducePerDayEnabled:
        this.state.maxDishesToProducePerDayEnabled,
      maxDishesToProducePerDayInBrand:
        this.state.maxDishesToProducePerDayInBrand,
      hotJar: {
        version: this.state.hotJarVersion,
        id: this.state.hotJarId,
        enabled: this.state.allowHotJar,
      },
      zohoSalesIQ: {
        enabled: this.state.allowZohoSalesIQ,
        widgetCode: this.state.zohoSalesIQWidgetCode,
      },
      dietarySetup: {
        dishIngredientsDecimalPlaces:
          this.state.dishIngredientsDecimalPlaces ===
          this.props.t('common.lack')
            ? 0
            : this.state.dishIngredientsDecimalPlaces,
        loadAllVariantsInitially: this.state.loadAllVariantsInitially,
        alteredDietWorkName: this.state.alteredDietWorkName,
      },
    };

    Promise.all([
      this.props.saveCompany(this.props.user.company, data),
      this.handleHighlightBrands(),
    ]).then(
      () =>
        this.props.openToast({
          messages: [
            this.props.t(
              'myCompany.toast.updated',
              'Zaktualizowano dane firmy'
            ),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        }),
      error => {
        if (error.response.data.violations[0].propertyPath === 'email') {
          this.props.openToast({
            messages: [`Email: ${error.response.data.violations[0].message}`],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          return false;
        } else {
          this.props.openToast({
            messages: [
              'Coś poszło nie tak. Upewnij się, że formularz został poprawnie wypełniony', //myCompany.toast.wentWrong
            ],
            type: 'error',
            autoHideDuration: TOAST_DURATIONS.SM,
          });
          return false;
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer style={{ marginTop: '15px' }}>
        <GridItem xs={12}>
          <CardWrapper title={this.props.t('myCompany.companyInfo')}>
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <FormTextInput
                    label={this.props.t('myCompany.fullname') + '*'}
                    classes={classes}
                    name="name"
                    value={this.state.name}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.shortName')}
                    classes={classes}
                    name="shortName"
                    value={this.state.shortName}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.mainDomain')}
                    classes={classes}
                    name="mainDomain"
                    value={this.state.mainDomain}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                    maxLength={64}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.email')}
                    classes={classes}
                    name="email"
                    value={this.state.email}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <ReactPhoneNumberAdapter
                    label={this.props.t('myCompany.phone')}
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handlePhoneChange}
                  />
                  <FormLabel className={classes.labelHorizontal}>
                    <div style={{ display: 'flex' }}>
                      <span>{this.props.t('myCompany.defBrand')}</span>
                      <Tooltip
                        title={
                          <h4>{this.props.t('myCompany.defBrandTooltip')}</h4>
                        }
                        placement="right"
                      >
                        <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                      </Tooltip>
                    </div>
                  </FormLabel>
                  <SelectInput
                    noGrid
                    // label={'Domyślna konfiguracja z marki *'}
                    classes={classes}
                    mapBy="name"
                    trackBy="@id"
                    value={this.state.defaultConfigFromBrand}
                    options={this.props.brands}
                    handleChange={(ev, obj) => {
                      this.setState({
                        defaultConfigFromBrand: obj['@id'],
                      });
                    }}
                    size={12}
                  />
                </GridItem>
                <GridItem md={6}>
                  <FormTextInput
                    label={this.props.t('myCompany.street')}
                    classes={classes}
                    name="street"
                    value={this.state.street}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.city')}
                    classes={classes}
                    name="city"
                    value={this.state.city}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.postcode')}
                    classes={classes}
                    error={!postCodeValidator(this.state.postCode)}
                    success={postCodeValidator(this.state.postCode)}
                    value={this.state.postCode}
                    onChange={this.handleInputChange}
                    name="postCode"
                    inputSize={12}
                  />
                  <NumberFormat
                    label={this.props.t('myCompany.taxId')}
                    classes={classes}
                    customInput={FormTextInput}
                    value={this.state.vatNumber}
                    format="##########"
                    onChange={this.handleInputChange}
                    name="vatNumber"
                    inputSize={12}
                  />
                  <NumberFormat
                    label={this.props.t('myCompany.regon')}
                    classes={classes}
                    customInput={FormTextInput}
                    value={this.state.regon}
                    format="##########"
                    onChange={this.handleInputChange}
                    name="regon"
                    inputSize={12}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allowHotJar}
                        onChange={e => {
                          this.setState({
                            allowHotJar: !this.state.allowHotJar,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={this.props.t('myCompany.useHojtar')}
                  />
                  <FormTextInput
                    label="Hotjar Id"
                    classes={classes}
                    name="hotJarId"
                    value={this.state.hotJarId}
                    maxLength={64}
                    handleChange={this.handleInputChange}
                    inputSize={6}
                  />
                  <FormTextInput
                    label="Hotjar version"
                    classes={classes}
                    name="hotJarVersion"
                    value={this.state.hotJarVersion}
                    maxLength={64}
                    handleChange={this.handleInputChange}
                    inputSize={6}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allowZohoSalesIQ}
                        onChange={e => {
                          this.setState({
                            allowZohoSalesIQ: !this.state.allowZohoSalesIQ,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={this.props.t('myCompany.useZohoSalesIQ')}
                  />
                  <FormTextInput
                    label="Zoho SalesIQ widgetCode"
                    classes={classes}
                    name="zohoSalesIQWidgetCode"
                    value={this.state.zohoSalesIQWidgetCode}
                    maxLength={500}
                    handleChange={this.handleInputChange}
                    inputSize={9}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
        <GridItem xs={12}>
          <CardWrapper title={this.props.t('myCompany.brands')}>
            <GridItem md={12}>
              {isGranted(ROLE_CREATE_BRAND) && (
                <FormControlButtons
                  classes={classes}
                  handleSubmit={this.newSize}
                  submitText={this.props.t('myCompany.addBrand') + '+'}
                />
              )}
              <DataGrid
                actions={true}
                // editPath={`${window.location.pathname}/edit`}
                remove={true}
                export={true}
                paginationTop={true}
                paginationBottom={false}
                url="/brands"
                reportName={'brand'}
                columns={columnConfig}
                role="BRAND"
              />
            </GridItem>
          </CardWrapper>
        </GridItem>
        <GridItem xs={12}>
          <CardWrapper title={this.props.t('myCompany.dietConfiguration')}>
            <CardBody>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('myCompany.dishIngredientsDecimalPlaces')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple={false}
                    classes={classes}
                    mapBy="value"
                    trackBy="value"
                    value={this.state.dishIngredientsDecimalPlaces}
                    options={Array.from(Array(10), (x, index) => ({
                      label: index,
                      value: index === 0 ? this.props.t('common.lack') : index,
                    }))}
                    handleChange={(e, { value }) => {
                      this.setState({
                        dishIngredientsDecimalPlaces:
                          value === 0 ? this.props.t('common.lack') : value,
                      });
                    }}
                    size={12}
                  />
                </GridItem>
                <GridItem md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.loadAllVariantsInitially}
                        onChange={e => {
                          this.setState({
                            loadAllVariantsInitially:
                              !this.state.loadAllVariantsInitially,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={this.props.t(
                      'myCompany.menuPlanner.selectAllVariantsInitialy'
                    )}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormTextInput
                    label={this.props.t('myCompany.alteredDietWorkName') + '*'}
                    classes={classes}
                    name="alteredDietWorkName"
                    value={this.state.alteredDietWorkName}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>

        <GridItem xs={12}>
          <CardWrapper
            title={this.props.t(
              'myCompany.highlitghtBrandOnReport',
              'Wyróżnij markę na raportach'
            )}
          >
            <CardBody>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t(
                      'myCompany.dishCardReport',
                      "Raport 'Karty Dań'"
                    )}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple
                    classes={classes}
                    mapBy="name"
                    trackBy="@id"
                    value={this.state.brandsAfterChanges}
                    options={this.state.brands}
                    const
                    handleChange={this.handleBrands}
                    size={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>

        <GridItem xs={12}>
          <CardWrapper
            title={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.maxDishesToProducePerDayEnabled}
                    onChange={e => {
                      this.setState({
                        maxDishesToProducePerDayEnabled:
                          !this.state.maxDishesToProducePerDayEnabled,
                      });
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <>
                    {this.props.t('myCompany.maxDishesToProducePerDayEnabled')}
                    <Tooltip
                      title={
                        <h4>
                          {this.props.t(
                            'myCompany.maxDishesToProducePerDay.tooltip'
                          )}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                    </Tooltip>
                  </>
                }
              />
            }
          >
            {this.state.maxDishesToProducePerDayEnabled && (
              <CardBody>
                <GridContainer>
                  <GridItem md={6}>
                    <FormTextInput
                      label={
                        this.props.t(
                          'myCompany.maxDishesToProducePerDay',
                          'Maksymalna ilość dań do produkcji na jeden dzień w całej firmie'
                        ) + '*'
                      }
                      classes={classes}
                      name="maxDishesToProducePerDay"
                      type="number"
                      value={this.state.maxDishesToProducePerDay}
                      noAutoComplete={true}
                      handleChange={this.handleInputChange}
                    />
                  </GridItem>
                  <GridItem md={6}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {this.props.t('employees.brand')}
                          </TableCell>
                          <TableCell>
                            {this.props.t(
                              'myCompany.maxDishesToProducePerDayInBrand',
                              'Maksymalna ilość dań do produkcji na jeden dzień w jednej marce'
                            )}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.brands.map(brand => (
                          <TableRow key={brand['@id']}>
                            <TableCell>{brand.name}</TableCell>
                            <TableCell>
                              <FormTextInput
                                classes={classes}
                                name={`maxDishesToProducePerDayInBrand[${brand.id}]`}
                                type="number"
                                value={
                                  this.state.maxDishesToProducePerDayInBrand[
                                    brand.id
                                  ] ?? ''
                                }
                                noAutoComplete={true}
                                handleChange={ev =>
                                  this.handleMaxDishesToProducePerDayInBrandChange(
                                    ev,
                                    brand.id
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            )}
          </CardWrapper>
        </GridItem>
        <GridItem xs={12}>
          <CardWrapper>
            <CardBody>
              <GridItem md={6} style={{ marginLeft: '-15px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showFooterCaterings}
                      onChange={e => {
                        this.setState({
                          showFooterCaterings: !this.state.showFooterCaterings,
                        });
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={this.props.t(
                    'myCompany.menuPlanner.showCateringsFooter'
                  )}
                />
              </GridItem>
            </CardBody>
          </CardWrapper>
        </GridItem>
        <GridItem xs={12}>
          <FakturowniaWarehouseModule
            classes={classes}
            handleModuleUpdate={this.handleModuleUpdate}
            module={this.state.modules['FakturowniaWarehouse']}
          />
        </GridItem>

        <GridItem xs={12}>
          <FormControlButtons
            classes={classes}
            submitText={this.props.t('common.shared.save')}
            handleSubmit={this.handleSubmit}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
    brands: state.Auth.user.brands,
  };
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, { saveCompany })
);

export default enhance(MyCompany);

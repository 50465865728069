import { useState, useEffect } from 'react';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
  fetchPaymentTypes,
  selectDefaultPaymentType,
  setEnabledPaymentTypes,
} from 'actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import { modulesNames } from '../consts';
import PaymentMethodView from './PaymentMethod.view';
import TOAST_DURATIONS from 'helpers/toastDurations';

const PaymentMethodContainer = ({
  classes,
  initialPaymentTypes,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
  openToast,
  fetchPaymentTypes,
}) => {
  const { t } = useTranslation();

  const [paymentTypes, setPaymentTypes] = useState(initialPaymentTypes);
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedBrand) {
      fetchPaymentTypes(selectedBrand);
    }
  }, [selectedBrand]);

  useEffect(() => {
    setPaymentTypes(initialPaymentTypes);
  }, [initialPaymentTypes]);

  useEffect(() => {
    (async () => {
      const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
        modulesNames.PayU,
        modulesNames.Stripe,
        modulesNames.BlueMedia,
        modulesNames.PayNow,
        modulesNames.AdditionalPayment,
        modulesNames.ItemPaymentTypes,
        modulesNames.ConfigClientPanel,
        modulesNames.PayPo,
      ]);

      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    try {
      await updateBrandConfigModulesPack(selectedBrand, {
        ...modules,
        ItemPaymentTypes: paymentTypes,
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }
  };

  const defaultPaymentOptions = [
    {
      label: t('module.configClientPanel.oneTimePayment'),
      value: 2,
    },
    {
      label: t('module.configClientPanel.subscription'),
      value: 1,
    },
  ];

  return (
    <PaymentMethodView
      classes={classes}
      modules={modules}
      setModules={setModules}
      isLoading={isLoading}
      handleModuleUpdate={handleModuleUpdate}
      handleSubmit={handleSubmit}
      openToast={openToast}
      defaultPaymentOptions={defaultPaymentOptions}
      paymentTypes={paymentTypes}
      setPaymentTypes={setPaymentTypes}
    />
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      initialPaymentTypes: paymentTypes,
      selectedBrand,
    }),
    {
      fetchBrandConfigModulesPack,
      updateBrandConfigModulesPack,
      fetchPaymentTypes,
      selectDefaultPaymentType,
      setEnabledPaymentTypes,
    }
  ),
  withToast,
  withStyles(combinedStyles)
);
export default enhance(PaymentMethodContainer);

import styled from 'styled-components';

const TreeWrapper = styled.div`
  margin-top: 20px;
`;

const TreeNodeHeader = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${props => (props.indent ? '15px' : 0)};
  padding-bottom: 5px;
  color: rgba(0, 0, 0, 0.46);
`;

const S = {
  TreeWrapper,
  TreeNodeHeader,
};

export default S;

import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import ContractorsList from './ContractorsList';
import ContractorsForm from './ContractorsForm';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_CONTRACTOR,
  ROLE_EDIT_CONTRACTOR,
  ROLE_SHOW_CONTRACTOR,
} from 'helpers/roles';

const Contractors = props => {
  const { history, match } = props;
  const ProtectedContractorsList = withRole(
    ROLE_SHOW_CONTRACTOR,
    ContractorsList
  );
  const ProtectedCreateForm = withRole(ROLE_CREATE_CONTRACTOR, ContractorsForm);
  const ProtectedEditForm = withRole(ROLE_EDIT_CONTRACTOR, ContractorsForm);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ProtectedContractorsList {...props} {...parentProps} />
          )}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <ProtectedCreateForm {...props} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => (
            <ProtectedEditForm {...props} {...parentProps} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(Contractors);

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { Divider } from '@material-ui/core';
import React from 'react';
import CardWrapper from 'components/Card/CardWrapper';

const CssStyleComponents = ({ classes, state, handleInputChange }) => {
  return (
    <CardWrapper title="CSS">
      <CardBody>
        <GridContainer>
          <GridItem md={6}>
            <Divider style={{ marginBottom: '20px' }} />
            <FormTextInput
              multiline
              classes={classes}
              name="customCSS"
              value={state.customCSS}
              maxLength={5000}
              rows={10}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};
export default CssStyleComponents;

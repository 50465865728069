import TRANSLATIONS_MODE from './constants';
import TranslationsTable from './TranslationsTable';

const ApiTranslations = () => {
  return (
    <TranslationsTable catalog={TRANSLATIONS_MODE.api} subCatalog="messages" />
  );
};

export default ApiTranslations;

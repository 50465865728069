import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

import EXTERNAL_URLS from 'constants/externalUrls';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import { infoTooltipSpacing } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles({
  root: { display: 'flex', alignItems: 'center' },
  title: { marginRight: infoTooltipSpacing },
});

const ListHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}>{t('basket.list')}</span>
      <InfoWithTooltip
        link={EXTERNAL_URLS.baskets}
        title={t('basket.headerTooltip')}
        size="s"
      />
    </div>
  );
};

export default ListHeader;

import roles from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';
import MIME_TYPES from './constants/mimeTypes';
const SelectionsV3Codes = () => {
  const { t } = useTranslation();

  return (
    <BaseBrandReport
      title={t('reports.reportsSelections')}
      excelUrl="/reports/selections"
      mimeType={MIME_TYPES.xlsx}
      fileName={getReportFileName(t('reports.reportsSelections'))}
      role={roles.ROLE_SHOW_REPORT_SELECTIONS}
      excelAvailable={true}
      archiveReportNames={['V3_SELECTIONS']}
      dateType="single"
      isSalesMarketLangViewEnabled={true}
    />
  );
};

export default SelectionsV3Codes;

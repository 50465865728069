import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

import PayUInfoWithTooltip from 'views/BrandConfiguration/PayUInfoWithTooltip';

const useStyles = makeStyles({
  payUTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
});

const useGetPaymentTypesTranslations = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  return {
    PAYU_CARD: {
      title: (
        <div className={classes.payUTitle}>
          PayU <PayUInfoWithTooltip />
        </div>
      ),
      paymentTypeStandard: 'PAYU_CARD',
      paymentTypeSubscription: 'PAYU_CARD',
      option: 'cardPaymentsEnabled',
      method: t('paymentType.payUCard', 'Zapisanie karty - PayU'),
      tooltip: t(
        'paymentType.payUCard.tooltip',
        'Do działania metody niezbędne jest posiadanie aktywnych płatności cyklicznych w PayU'
      ),
      designation: 'RGB(0,172,193)',
    },
    PAYU_PAY_BY_LINKS: {
      title: 'PayU',
      paymentTypeStandard: 'PAYU_PAY_BY_LINKS',
      option: 'payByLink',
      method: t('paymentType.payUPBL', 'Szybkie przelewy - PayU'),
      designation: 'RGB(0,172,193)',
    },
    PAYU_PAYPO: {
      title: 'PayU',
      paymentTypeStandard: 'PAYU_PAYPO',
      option: 'payPo',
      method: t('paymentType.payUPayPo', 'Płatności odroczone PayPo - PayU'),
      designation: 'RGB(0,172,193)',
    },
    PAYU_GOOGLE_PAY: {
      title: 'PayU',
      paymentTypeStandard: 'PAYU_GOOGLE_PAY',
      option: 'googlePayEnabled',
      method: t('paymentType.payUGooglePay', 'Google Pay - PayU'),
      designation: 'RGB(0,172,193)',
    },
    PAYU: {
      title: 'PayU',
      paymentTypeStandard: 'PAYU',
      method: t('paymentType.payU', 'PayU'),
      designation: 'RGB(0,172,193)',
    },
    PAYU_BLIK: {
      title: 'PayU',
      paymentTypeStandard: 'PAYU_BLIK',
      option: 'blik',
      method: t('paymentType.payUBlik', 'Szybki Blik - PayU'),
      designation: 'RGB(0,172,193)',
    },
    PAYU_SANDBOX: {
      title: 'PayU',
      paymentTypeStandard: 'PAYU_SANDBOX',
      option: 'sandbox',
      method: t('paymentType.payUSandbox', 'Sandbox'),
      designation: 'RGB(0,172,193)',
    },
    STRIPE_CARD: {
      title: 'Stripe',
      paymentTypeStandard: 'STRIPE_CARD',
      paymentTypeSubscription: 'STRIPE_CARD',
      option: 'cardPayments',
      method: t('paymentType.stripeCard', 'Zapisanie karty - Stripe'),
      designation: 'RGB(76,175,80)',
    },
    STRIPE_LINK: {
      title: 'Stripe',
      paymentTypeStandard: 'STRIPE_LINK',
      method: t('paymentType.stripeLink', 'Stripe'),
      designation: 'RGB(76,175,80)',
    },
    PAYPO: {
      title: 'PayPo',
      paymentTypeStandard: 'PAYPO',
      method: t('paymentType.payPo', 'PayPo'),
      designation: 'RGB(204,0,0)',
    },
    PAYNOW: {
      title: 'PayNow',
      paymentTypeStandard: 'PAYNOW',
      method: t('paymentType.payNow', 'PayNow'),
      designation: 'RGB(255,152,0)',
    },
    PAYNOW_SANDBOX: {
      title: 'PayNow',
      paymentTypeStandard: 'PAYNOW_SANDBOX',
      option: 'sandbox',
      method: t('paymentType.payNowSandbox', 'Sandbox'),
      designation: 'RGB(255,152,0)',
    },
  };
};

export default useGetPaymentTypesTranslations;

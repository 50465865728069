import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Button from 'components/CustomButtons/Button';

const DetailsDialog = ({
  title,
  buyLink,
  description,
  isDialogOpened,
  setIsDialogOpened,
}) => {
  const { t } = useTranslation();

  const handleClose = () => setIsDialogOpened(false);

  return (
    <Dialog open={isDialogOpened} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <div
        style={{
          border: '1px solid rgba(203, 161, 50, 1)',
          width: '90%',
          margin: '0 auto 20px auto',
        }}
      ></div>
      <DialogContent>
        <p style={{ fontWeight: 'bold' }}>
          {t('$*additionalOffer.detailsDialog.description', '$$Opis:')}
        </p>
        <div
          style={{ fontSize: '1.2em', lineHeight: '1.4' }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {/* DEV-2310 - DELETION PRICE LIST */}
      </DialogContent>
      <DialogActions>
        {buyLink && (
          <a href={buyLink} target="_blank" rel="noreferrer">
            <Button color="success">{t('form.buy', '$$Kup teraz')}</Button>
          </a>
        )}
        <Button onClick={handleClose}>{t('form.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsDialog;

import { useTranslation } from 'react-i18next';

const ModuleNotLoaded = ({ moduleName }) => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: '15px 0', fontWeight: 'bold' }}>
      {t('brands.newBrandForm.moduleNotLoaded', {
        defaultValue: '{{moduleName}} not loaded.',
        moduleName,
      })}
    </div>
  );
};

export default ModuleNotLoaded;

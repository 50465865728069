import React, { Fragment } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const createModalContent = (item, t) => {
  const buyBagItems = item.payload.bags;

  return buyBagItems.map((buyBagItem, index) => {
    return (
      <Fragment key={index}>
        <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
          {t('diets.diet', 'Dieta')}
        </h3>
        <p>{buyBagItem.diet.name}</p>
        <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
          {t('clients.variant', 'Wariant')}
        </h3>
        <p>{buyBagItem.variant.name}</p>
        <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
          {t('clients.calories', 'Kaloryczność')}
        </h3>
        <p>{buyBagItem.calorific.name}</p>
        <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
          {t(
            'orders.buyBag.subscriptionRenewalFromDate',
            'Odnowienie subskrypcji od'
          )}
        </h3>
        <p>
          {Moment(buyBagItem.clientDiet.subscriptionStartsAt).format(
            Moment.localeData().longDateFormat('L')
          )}
        </p>
        {buyBagItem?.date && (
          <>
            <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
              {t(
                'orders.buyBag.subscriptionRenewalForDate',
                'Odnowienie subskrypcji na'
              )}
            </h3>
            <p>
              {Moment(buyBagItem.date).format(
                Moment.localeData().longDateFormat('L')
              )}
            </p>
          </>
        )}
      </Fragment>
    );
  });
};

const Component = props => {
  const { items, fillModal, t } = props;
  const idOfRenewedOrder = props.items?.[0]?.payload?.bags?.[0]?.clientDiet?.id;
  const getModalContent = async () => {
    let modalContent = items
      .sort((a, b) => a.id - b.id)
      .map(el => (
        <>
          {
            !el.payload.bags.map(buyBag => (
              <div style={{ margin: '20px 0' }}>
                {Moment(buyBag.date).format('DD-MM-YYYY')}
              </div>
            ))
          }
          <div
            style={{
              margin: '15px 0 0',
              borderBottom: items.length > 1 ? '1px solid #e2e2e2' : 'none',
              paddingBottom: '20px',
              textAlign: 'left',
              minWidth: '300px',
            }}
          >
            {createModalContent(el, t)}
          </div>
        </>
      ));

    fillModal(
      <div>
        <h1>
          {t('orders.buyBag.subscriptionRenewalWithId', {
            defaultValue: 'Odnowienie subskrypcji diety #{{orderId}}',
            replace: { orderId: idOfRenewedOrder },
          })}
        </h1>
        {modalContent}
      </div>
    );
  };
  return (
    <span
      style={{ color: '#89229b', cursor: 'pointer' }}
      onClick={getModalContent}
    >
      {t('orders.buyBag.subscriptionRenewal', 'Odnowienie subskrypcji')}
    </span>
  );
};

const enhance = compose(
  connect(({ Diets: { diets }, Variants: { variants } }) => ({
    diets,
    variants,
  })),
  withTranslation()
);

export default enhance(Component);

import React, { Component } from 'react';
import { combineStyles } from 'helpers/helpers';
import { post, put } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import Moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
//actions
import { fetchZone } from 'actions/Zones';
import { fetchBrandConfigModulesPack } from 'actions/Brands';
import { fetchDeliveryTypes } from 'actions/DeliveryTypes';
import { fetchZoneCategories } from 'actions/ZoneCategories';
import { fetchDrivers } from 'actions/Drivers';
//styles
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
// components
import PostCodeModal from './components/PostCodeModal';
import BasicInfoCard from 'components/CMS/BasicInfoCard';
import InfoBannerCard from 'components/CMS/InfoBannerCard';
import DescriptionCard from 'components/CMS/DescriptionCard';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { withTranslation } from 'react-i18next';
import Settings from './Settings';
import NavTabs from 'components/NavTabs/NavTabs';
import TOAST_DURATIONS from 'helpers/toastDurations';

class Form extends Component {
  state = {
    deliveryHours: [],
    deliveryInterval: '',
    selectedDeliveryType: '',
    name: '',
    shortName: '',
    priority: '',
    category: null,
    postCodePreview: false,
    postCode: '',
    postCodes: [],
    postCodeModalStatus: false,
    drivers: [],
    urlSlug: '',
    metaTitle: '',
    metaDescription: '',
    friendlyName: '',
    deliveryZonesDescription: '',
    bannerEnabled: false,
    bannerHeader: '',
    bannerText: '',
    infoBannerDesktop: null,
    infoBannerDesktopUrl: null,
    infoBannerMobile: null,
    infoBannerMobileUrl: null,
    bannerId: null,
    useAddressesWithLessFields: true,
    zoneCosts: [],
  };

  editId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  async componentDidMount() {
    await this.props.fetchDeliveryTypes();
    await this.props.fetchDrivers();
    await this.props.fetchZoneCategories();
    const {
      configuration: {
        ConfigClientPanel: { useAddressesWithLessFields } = {},
      } = {},
    } = await this.props.fetchBrandConfigModulesPack(this.props.selectedBrand, [
      'ConfigClientPanel',
    ]);

    if (this.isEdit) {
      this.props.fetchZone(this.editId).then(response => {
        const newState = {
          name: response.name,
          shortName: response.shortName,
          priority: response.priority,
          postCodes: (response?.postCodes ?? []).map((postCode, index) => ({
            ...postCode,
            index,
          })),
          selectedDeliveryType: response.deliveryType
            ? response.deliveryType['@id']
            : '',
          deliveryHours: response.deliveryHours,
          drivers: response.drivers.map(driver => driver['@id']),
          category: response.category?.['@id'],
          urlSlug: response.pageSettings.slug ?? '',
          metaTitle: response.seo.title ?? '',
          metaDescription: response.seo.description ?? '',
          deliveryZonesDescription: response.pageSettings.content ?? '',
          friendlyName: response.pageSettings.title ?? '',
          bannerEnabled: response.banner ? response.banner.enabled : false,
          bannerHeader: response.banner ? response.banner.heading : '',
          bannerText: response.banner ? response.banner.content : '',
          useAddressesWithLessFields: useAddressesWithLessFields,
          zoneCosts: response.zoneCosts,
        };

        if (response.banner) {
          newState.bannerId = response.banner['@id'];

          if (response.banner.desktop) {
            newState.infoBannerDesktop = response.banner.desktop['@id'];
            newState.infoBannerDesktopUrl = response.banner.desktop?.contentUrl;
          }

          if (response.banner.mobile) {
            newState.infoBannerMobile = response.banner.mobile['@id'];
            newState.infoBannerMobileUrl = response.banner.mobile?.contentUrl;
          }
        }

        return this.setState(newState);
      });
    } else {
      this.setState({
        useAddressesWithLessFields: useAddressesWithLessFields,
      });
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  postCodeValidator = regex => stringToValidate => {
    if (stringToValidate.includes('*')) {
      return true;
    }
    if (null === regex) {
      return true;
    }
    const validator = new RegExp(`${regex}$`);
    return validator.test(stringToValidate);
  };

  validateZones = () => {
    return this.state.postCodes.length > 0;
  };

  validateAssignedDrivers = () => {
    return this.state.drivers.length > 0;
  };

  validateCodes = () => {
    return !this.state.postCodes.some(postCode => postCode.code === '');
  };

  validateForm = () => {
    return (
      this.state.name &&
      this.state.shortName &&
      this.state.priority !== '' &&
      this.state.selectedDeliveryType
    );
  };

  handleSubmit = () => {
    if (!this.validateCodes()) {
      return this.props.openToast({
        messages: [this.props.t('zones.codeCantBeEmpty')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (!this.validateZones()) {
      return this.props.openToast({
        messages: [this.props.t('zones.zoneMust')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (!this.validateAssignedDrivers()) {
      return this.props.openToast({
        messages: [this.props.t('zones.zoneMustAssignedDrivers')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('zones.fillAllFields')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const data = {
      name: this.state.name,
      shortName: this.state.shortName,
      priority: parseInt(this.state.priority),
      postCodes: this.state.postCodes,
      deliveryType: this.state.selectedDeliveryType,
      deliveryHours: this.state.deliveryHours.map(hour => ({
        ...hour,
        hourFrom: hour.hourFrom ? new Moment(hour.hourFrom).format() : null,
        hourTo: hour.hourTo ? new Moment(hour.hourTo).format() : null,
      })),
      drivers: this.state.drivers,
      category: this.state.category,
      seo: {
        title: this.state.metaTitle,
        description: this.state.metaDescription,
      },
      pageSettings: {
        slug: this.state.urlSlug,
        content: this.state.deliveryZonesDescription,
        title: this.state.friendlyName,
      },
      banner: {
        enabled: this.state.bannerEnabled,
        heading: this.state.bannerHeader,
        content: this.state.bannerText,
        desktop: this.state.infoBannerDesktop,
        mobile: this.state.infoBannerMobile,
      },
      zoneCosts: this.state.zoneCosts.map(zoneCost => ({
        ...zoneCost,
        price: parseFloat(zoneCost.price) || 0,
        cost: parseFloat(zoneCost.cost) || 0,
        vat: parseFloat(zoneCost.vat) || 0,
      })),
    };

    if (this.state.bannerId) {
      data.banner['@id'] = this.state.bannerId;
    }

    const action = this.isEdit
      ? put(`/zones/${this.editId}`, data)
      : post('/zones', data);

    action.then(
      () =>
        this.props.history.push('/admin/zones', {
          successfullyAddedZone: true,
        }),
      ({ response }) => {
        this.handleErrors(response);
      }
    );
  };

  handleErrors = response => {
    const formatedViolations = (response?.data?.violations ?? []).map(
      violation => {
        return this.formatViolation(violation);
      }
    );

    return this.props.openToast({
      messages: [
        this.props.t('zones.smthWentWrong'),
        this.props.t('zones.checkForm'),
        '________________',
        ...formatedViolations,
      ],
      type: 'error',
      autoHideDuration: TOAST_DURATIONS.SM,
    });
  };

  formatViolation = violation => {
    const { message, propertyPath } = violation;

    const splitedErrors = propertyPath.split('.');

    // POST CODE ERROR
    const postCodePart = splitedErrors[0];

    if (postCodePart.includes('postCodes')) {
      const postCodeIndex = postCodePart.substring(
        postCodePart.indexOf('[') + 1,
        postCodePart.lastIndexOf(']')
      );

      const invalidPostCode = this.state.postCodes[postCodeIndex] ?? {};
      return `${invalidPostCode?.code}: ${message}`;
    }

    return '';
  };

  togglePostCodeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      postCodeModalStatus: !prevState.postCodeModalStatus,
    }));
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  setZoneSubpageData = (value, name) => {
    this.setState({ [name]: value });
  };

  handleBannerCheckboxChange = () => {
    this.setState(prevState => ({
      ...prevState,
      bannerEnabled: !this.state.bannerEnabled,
    }));
  };

  render() {
    const { classes, t, multinational } = this.props;
    const selectedSupportedRegion = multinational?.supportedRegions?.[0];
    const selectedCountry = this.props.countryInformations.find(
      countryInf => countryInf.code === selectedSupportedRegion
    );

    const postCodeValidator = this.postCodeValidator(
      selectedCountry.postCodeRegexp
    );

    return (
      <>
        <NavTabs
          title={
            this.isEdit
              ? t('$*views.zones.zoneEdit', '$$Edycja strefy')
              : t('$*views.zones.zoneAdd', '$$Dodanie strefy')
          }
          tabs={[
            {
              tabButton: t('$*views.zones.zone', '$$Strefa'),
              tabContent: (
                <Settings
                  state={this.state}
                  setState={this.setState.bind(this)}
                  isEdit={this.isEdit}
                  handleChange={this.handleChange}
                  drivers={this.props.drivers}
                  zoneCategories={this.props.zoneCategories}
                  deliveryTypes={this.props.deliveryTypes}
                  togglePostCodeModal={this.togglePostCodeModal}
                />
              ),
            },
            {
              tabButton: t('$*views.zones.zoneSubpage', '$$Podstrona strefy'),
              tabContent: (
                <>
                  <BasicInfoCard
                    urlSlug={this.state.urlSlug}
                    metaTitle={this.state.metaTitle}
                    metaDescription={this.state.metaDescription}
                    handleChange={this.handleChange}
                    subpage="/zones/"
                  />
                  <InfoBannerCard
                    infoBannerCheckbox={this.state.bannerEnabled}
                    bannerHeader={this.state.bannerHeader}
                    bannerText={this.state.bannerText}
                    handleInputChange={this.handleChange}
                    handleCheckboxChange={this.handleBannerCheckboxChange}
                    getImage={this.getImage}
                    removeImage={this.removeImage}
                    infoBannerMobileUrl={this.state.infoBannerMobileUrl}
                    infoBannerDesktopUrl={this.state.infoBannerDesktopUrl}
                  />
                  <DescriptionCard
                    value={this.state.deliveryZonesDescription}
                    setValue={e =>
                      this.setZoneSubpageData(e, 'deliveryZonesDescription')
                    }
                    title={t('$*views.zones.zoneDescription', '$$Opis strefy')}
                  />
                </>
              ),
            },
          ]}
        />
        <PostCodeModal
          postCodeModalStatus={this.state.postCodeModalStatus}
          togglePostCodeModal={this.togglePostCodeModal}
          postCodes={this.state.postCodes}
          shortFormEnabled={this.state.useAddressesWithLessFields}
          setMergedAddresses={mergedAddresses => {
            this.setState(prevState => ({
              ...prevState,
              postCodes: mergedAddresses,
            }));
          }}
          postCodeValidator={postCodeValidator}
        />
        <FormControlButtons
          classes={classes}
          discardText={t('zones.cancel')}
          submitText={t('zones.save')}
          cancelPath="/admin/zones"
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  zone: state.Zones.zone,
  selectedBrand: state.Auth.selectedBrand,
  deliveryTypes: state.DeliveryTypes.deliveryTypes,
  drivers: state.Drivers.drivers,
  multinational: state.Brands.brand.multinational,
  countryInformations: state.Brands.countryInformations,
  zoneCategories: state.ZoneCategories.zoneCategories,
});

const mapDispatchToProps = dispatch => ({
  fetchDeliveryTypes: () => dispatch(fetchDeliveryTypes()),
  fetchZone: id => dispatch(fetchZone(id)),
  fetchDrivers: () => dispatch(fetchDrivers()),
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
  fetchBrandConfigModulesPack: (selectedBrand, config) =>
    dispatch(fetchBrandConfigModulesPack(selectedBrand, config)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Form);

import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Addons = ({ addons }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Grid contaoner spacing={2}>
      {addons.length === 0 && (
        <Button disabled={true}>
          {t('ecommerceBags.fields.bag.addons.empty')}
        </Button>
      )}
      {addons.length > 0 && (
        <Button onClick={() => setOpen(!open)}>
          {open ? t('$*common.hide') : t('$*common.show')}{' '}
          {t('$*orderFormTabs.addons').toLowerCase()} (
          {addons.map(el => el.quantity).reduce((curr, prev) => prev + curr, 0)}
          )
        </Button>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {addons.map((el, i) => (
            <ListItem key={`addon_${i}`}>
              <ListItemIcon>{el.quantity} X</ListItemIcon>
              <ListItemText primary={el.addon} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Grid>
  );
};

export default Addons;

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ArrowDown from 'assets/img/arrow-down.svg';
import ArrowRight from 'assets/img/arrow-right.svg';
import ArrowDiagonal from 'assets/img/arrow-diagonal.svg';

import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import makeStyles from '@material-ui/styles/makeStyles';
import { Trans } from 'react-i18next';
import { infoTooltipSpacing } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles({
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: infoTooltipSpacing,
  },
  divider: {
    marginBottom: '20px',
  },
  mutedText: {
    color: 'gray',
  },
  dayOfWeek: {
    padding: '10px 5px',
    minWidth: '120px',
  },
});

const SlotsSectionSettings = ({
  classes: propsClasses,
  t,
  daysOfWeek,
  dayFields,
  translateDays,
  translateFields,
  returnTableCell,
}) => {
  const classes = useStyles();

  return (
    <>
      <h3 className={classes.heading}>
        {t('brandCfg.timeSettings')}
        <InfoWithTooltip
          title={t('brandCfg.timeSettingsTooltip')}
          link={EXTERNAL_URLS.timeSettings}
        />
      </h3>
      <Divider className={classes.divider} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="250px">
              <Trans
                i18nKey="brandCfg.timeSettingsTableHeader"
                components={{
                  arrowDown: <img src={ArrowDown} alt="arrow" />,
                  arrowRight: <img src={ArrowRight} alt="arrow" />,
                  arrowDiagonal: <img src={ArrowDiagonal} alt="arrow" />,
                  br: <br />,
                }}
              />
            </TableCell>
            {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
              return (
                <TableCell padding="none" key={dayOfWeekIndex}>
                  {translateDays(t)[dayOfWeek]}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dayFields.map((field, fieldIndex) => {
            return (
              <TableRow key={fieldIndex}>
                <TableCell>
                  {translateFields(t)[field]}{' '}
                  {field === 'newOrderSubscription' && (
                    <span className={classes.mutedText}>
                      {t(
                        'brands.newBrandForm.translateHelpText.newOrderSubscription'
                      )}
                    </span>
                  )}{' '}
                  {field === 'changeDate' && (
                    <span className={classes.mutedText}>
                      {t('brands.newBrandForm.translateHelpText.changeDate')}
                    </span>
                  )}
                </TableCell>
                {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
                  return (
                    <TableCell padding={'none'} key={dayOfWeekIndex}>
                      <div className={classes.dayOfWeek}>
                        {returnTableCell(propsClasses, dayOfWeek, field)}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default SlotsSectionSettings;

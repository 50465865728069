import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NavLink } from 'react-router-dom';

import { remove } from 'helpers/apiHelpers';
import { isGranted, getNumberFromString } from 'helpers/helpers';
import {
  ROLE_EDIT_WASTE,
  ROLE_CREATE_WASTE,
  ROLE_REMOVE_WASTE,
} from 'helpers/roles';
import { currencyToSymbol } from 'utils/currencies';

import { CheckBox, RemoveCircle } from '@material-ui/icons';

import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/FormSelect/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';

import ProcessingSelect from '../components/ProcessingSelect';
import LossWeightInput from '../components/LossWeightInput';

const columnConfig = ({
  t,
  classes,
  multinational,
  overProductions,
  saveRow = () => {},
  setAlert = () => {},
  changeRow = () => {},
  validateRow = () => {},
  changeSubBrand = () => {},
  handleChangeAmount = () => {},
  setOverProductions = () => {},
}) => {
  const sourceOptions = [
    {
      name: t('common.waste.sourcePacking', 'Pakownia'),
      value: 'packing_room',
    },
    {
      name: t('common.waste.sourceColdroom', 'Chłodnia'),
      value: 'cold_store',
    },
  ];

  const columns = [
    {
      title: 'ID',
      accessor: 'id',
      name: 'id',
      filterable: false,
      sortable: false,
      width: 50,
    },
    {
      title: t('columns.name'),
      name: 'name',
      accessor: option => {
        return (
          <div>
            {option.recipe || option.ingredient ? (
              <NavLink
                to={
                  !option.ingredient
                    ? `/admin/recipes/edit/${getNumberFromString(
                        option.recipe['@id']
                      )}`
                    : `/admin/ingredients/edit/${getNumberFromString(
                        option.ingredient['@id']
                      )}`
                }
                target={'_blank'}
              >
                <h4>
                  {option.recipe
                    ? option.recipe.workName
                    : option.ingredient.name}
                </h4>
              </NavLink>
            ) : null}
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 250,
    },
    {
      title: t('columns.date'),
      accessor: row => dateTimeCell(row.date, true),
      name: 'date',
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.unit'),
      name: 'unit',
      filterable: false,
      accessor: ({ recipe, ingredient, ...row }) =>
        recipe || ingredient
          ? recipe
            ? `Porcja`
            : ingredient.unit.value
            ? ingredient.unit.value
            : ingredient.unit
          : null,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.qty') + '*',
      name: 'total',
      accessor: row => (
        <div style={{ paddingTop: '9px' }}>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              disabled: row['@id'] && !isGranted(ROLE_EDIT_WASTE),
              type: 'number',
              placeholder: t('columns.typeValue'),
              name: 'amount',
              value: row.amount,
              onChange: ev => {
                handleChangeAmount(row, ev, 'amount');
              },
            }}
          />
        </div>
      ),
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.wasteWeight'),
      name: 'lossWeight',
      accessor: row => (
        <LossWeightInput row={row} classes={classes} changeRow={changeRow} />
      ),
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: t('columns.afterProcessing'),
      name: 'weightIsAfterMachining',
      accessor: ({ ...row }) => {
        return (
          <ProcessingSelect
            t={t}
            row={row}
            classes={classes}
            changeRow={changeRow}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: t('columns.wasteValue'),
      name: 'lossCash',
      accessor: ({ amount, ingredient, recipe, ...row }) => {
        return (
          (
            ((amount * (!ingredient ? 1 : ingredient.weight)) /
              (!ingredient ? 1 : 100)) *
            (!ingredient ? recipe.price : ingredient.price)
          ).toFixed(2) + ` ${currencyToSymbol(multinational.currency)}`
        );
      },
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.comment'),
      name: 'comment',
      accessor: ({ comment, ...row }) => {
        return (
          <div style={{ paddingTop: '6px' }}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                disabled: row['@id'] && !isGranted(ROLE_EDIT_WASTE),
                placeholder: t('common.shared.typeComment'),
                name: 'comment',
                value: comment,
                onChange: ev => {
                  changeRow(row, ev.target.value, 'comment');
                },
              }}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      title: t('columns.wasteSource'),
      name: 'source',
      accessor: ({ source, ...row }) => {
        return (
          <SelectInput
            id="source"
            size={12}
            name="source"
            value={source}
            mapBy="name"
            trackBy="value"
            classes={classes}
            options={sourceOptions}
            handleChange={ev => changeSubBrand(row, ev, 'source')}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: '',
      name: 'action',
      accessor: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            {isGranted(ROLE_REMOVE_WASTE) && (
              <Button
                simple
                color={'danger'}
                onClick={() => {
                  const onConfirm = () => {
                    const findBy = row.temporaryId
                      ? row.temporaryId
                      : row['@id'];
                    const key = row.temporaryId ? 'temporaryId' : '@id';

                    const filteredOverProductions = overProductions.filter(
                      overProduction => overProduction[key] !== findBy
                    );
                    row['@id'] && remove(row['@id']);
                    setOverProductions(filteredOverProductions);
                    setAlert(null);
                  };
                  const onCancel = () => setAlert(null);
                  !row['@id'] ? onConfirm() : renderAlert(onConfirm, onCancel);
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  {t('common.shared.delete')}
                </span>
                <RemoveCircle />
              </Button>
            )}
            {isGranted(row['@id'] ? ROLE_EDIT_WASTE : ROLE_CREATE_WASTE) && (
              <Button
                simple
                color={'success'}
                disabled={!validateRow(row) || (row['@id'] && !row.hasChanged)}
                onClick={() => saveRow(row)}
              >
                <div>
                  <span style={{ marginRight: '5px' }}>
                    {t('common.shared.save')}
                  </span>
                  <CheckBox />
                </div>
              </Button>
            )}
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
  ];

  const renderAlert = (onConfirm, onCancel) => {
    const alert = (
      <SweetAlert
        warning
        title={t('common.shared.areYouSure')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('common.shared.yes')}
        cancelBtnText={t('common.shared.no')}
        showCancel
      />
    );

    setAlert(alert);
  };

  return columns.map(column => {
    return {
      ...column,
      id: column.name,
      Header: column.title,
      accessor: column.accessor,
      sortable: column.sortable,
    };
  });
};

export default columnConfig;

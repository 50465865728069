import React, { useState } from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button';
import { Dialog } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { remove } from 'helpers/apiHelpers';

import GroupIcon from '@material-ui/icons/Group';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_CONTRACTOR, ROLE_REMOVE_CONTRACTOR } from 'helpers/roles';

import columnConfig from './columnConfig';
import { useTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

const ContractorsList = ({ classes, openToast }) => {
  const history = useHistory();
  const newContractor = () => history.push(`${window.location.pathname}/add`);
  const { t } = useTranslation();

  const [state, setState] = useState({
    checkedRowIds: [],
    isConfirmModalOpened: false,
    openedModal: false,
    refreshList: false,
  });

  const massDeleteHandleMultipleCheckboxes = array => {
    setState(prevState => ({ ...prevState, checkedRowIds: array }));
  };

  const massDeleteHandleSingleCheckbox = id => {
    const checkedIdsUpdated = [...state.checkedRowIds];
    const checkedIdIndex = checkedIdsUpdated.indexOf(id);
    if (checkedIdIndex >= 0) {
      checkedIdsUpdated.splice(checkedIdIndex, 1);
    } else {
      checkedIdsUpdated.push(id);
    }
    setState(prevState => ({ ...prevState, checkedRowIds: checkedIdsUpdated }));
  };

  const refreshList = () => {
    setState(prevState => ({
      ...prevState,
      refreshList: !prevState.refreshList,
    }));
  };

  const massDeleteSubmit = () => {
    setState(prevState => ({
      ...prevState,
      isConfirmModalOpened: false,
      isDeleting: false,
    }));

    let url;
    url = '/contractors?';
    state.checkedRowIds.forEach((id, index) => {
      if (index === 0) {
        url = url.concat(`id[]=${id}`);
      } else {
        url = url.concat(`&id[]=${id}`);
      }
    });

    url = url.concat('&removeAllAction=true');

    remove(url)
      .then(() => {
        openToast({
          messages: [
            t(
              'contractors.massdelete.success',
              'Wybrane elementy zostały usunięte'
            ),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.LG,
        });
      })
      .catch(e => {
        openToast({
          messages: [
            t(
              'contractors.massdelete.error',
              'Usunięcie wybranych elementów nie powiodło się'
            ),
          ],
          type: 'warning',
          autoHideDuration: TOAST_DURATIONS.LG,
        });
      })
      .finally(() => {
        setState(prevState => ({
          ...prevState,
          isDeleting: false,
          checkedRowIds: [],
        }));
        refreshList();
      });
  };

  return (
    <AdminTable
      title={t('contractors.cotractorsList', 'Lista kontrahentów')}
      iconComponent={<GroupIcon />}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginLeft: '4px',
        }}
      >
        {isGranted(ROLE_REMOVE_CONTRACTOR) && (
          <Button
            data-cy="__remove_contractor_button"
            color="danger"
            onClick={() =>
              setState(prevState => ({
                ...prevState,
                isConfirmModalOpened: true,
              }))
            }
            disabled={state?.checkedRowIds?.length === 0}
          >
            {t('dictionary.deleteConstractor', 'Usuń Kontrahentów')}
          </Button>
        )}
        {isGranted(ROLE_CREATE_CONTRACTOR) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={newContractor}
            submitText={t('contractors.addNew', 'Dodaj kontrahenta') + ' +'}
          />
        )}
      </div>
      <DataGrid
        refresh={state.refreshList}
        massDelete={true}
        massDeleteItemsIds={state.checkedRowIds}
        massDeleteHandleSingleCheckbox={massDeleteHandleSingleCheckbox}
        massDeleteHandleMultipleCheckboxes={massDeleteHandleMultipleCheckboxes}
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/contractors"
        reportName={'contractor'}
        columns={columnConfig}
        role="CONTRACTOR"
      />
      <Dialog fullWidth={true} open={state.isConfirmModalOpened}>
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={t('clients.areYouSure')}
          onConfirm={massDeleteSubmit}
          onCancel={() =>
            setState(prevState => ({
              ...prevState,
              isConfirmModalOpened: !prevState.isConfirmModalOpened,
            }))
          }
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      </Dialog>
    </AdminTable>
  );
};

const enhance = compose(withStyles(buttonsStyle), withToast);

export default enhance(ContractorsList);

import { Router, Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';

import withRole from 'components/Security/withRole';
import { ROLE_SHOW_KITCHEN } from 'helpers/roles';

import GeneratedTasks from '../common/GeneratedTasks/GeneratedTasks';
import KitchenTaskPreview from './components/KitchenTaskPreview/KitchenTaskPreview';

const Kitchen = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const ListSecured = withRole(ROLE_SHOW_KITCHEN, GeneratedTasks);
  const PreviewSecured = withRole(ROLE_SHOW_KITCHEN, KitchenTaskPreview);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ListSecured
              {...parentProps}
              preview={`${location.pathname}/preview`}
              defaultHiddenColumns={[
                'taskListStatuses.packDiet',
                'taskListStatuses.packDish',
              ]}
              role={'KITCHEN'}
              separatingConfigsParam={'fe=kitchen'}
            />
          )}
        />
        <Route
          path={`${match.url}/preview/:id`}
          render={parentProps => <PreviewSecured {...parentProps} />}
        />
      </Switch>
    </Router>
  );
};

export default Kitchen;

import React from 'react';
import FormContainer from './components/Form.container';

const Form = ({
  match,
  location,
  openToast,
  t,
  fetchMealSizes,
  classes,
  history,
  fetchMealType,
  openSweetToast,
}) => {
  return (
    <FormContainer
      match={match}
      location={location}
      openToast={openToast}
      t={t}
      fetchMealSizes={fetchMealSizes}
      classes={classes}
      history={history}
      fetchMealType={fetchMealType}
      openSweetToast={openSweetToast}
    />
  );
};

export default Form;

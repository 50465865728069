import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SizeRow from './Size/SizeRowAdvanced';
import Danger from 'components/Typography/Danger';
import PriceSumRow from './PriceSum/PriceSumRowAdvanced';

class TypeBlockAdvanced extends React.Component {
  state = {
    copyMealTypeIdFrom: '',
  };

  sortByPosition = collection => {
    return collection.sort((a, b) => {
      return a.size.position - b.size.position;
    });
  };

  handleChange = (e, typeId) => {
    const {
      target: { name, value },
    } = e;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));

    this.props.handleCopySizeChange(e, typeId);
  };

  render() {
    const {
      type,
      dishSizes,
      selectedIngredients,
      handleRemoveIngredient,
      onChange,
      classes,
      mealTypeIngredientsSum,
      shouldRescale,
      setShouldRescale,
      checkedDishToResize,
      setDishToResize,
    } = this.props;

    const dishSizesByType = dishSizes.filter(dishSize => {
      return dishSize.mealType['@id'] === type['@id'];
    });

    return (
      <>
        <div style={{ overflow: 'auto', maxWidth: 'calc(100vw - 100px)' }}>
          <Table style={{ marginBottom: '15px' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: 'lightgrey' }}>
                <TableCell style={{ minWidth: '215px' }}>
                  {this.props.t('form.field.name')}
                </TableCell>
                {this.sortByPosition(type.sizes).map((size, sizeKey) => {
                  return (
                    <TableCell key={sizeKey}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>{size.size.name}</div>
                      </div>
                    </TableCell>
                  );
                })}
                <TableCell>{this.props.t('columns.unit')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedIngredients.length === 0 ? (
                <TableRow>
                  <TableCell>
                    <Danger>
                      <b>{this.props.t('form.emptyIngredients')}</b>
                    </Danger>
                  </TableCell>
                  <TableCell />
                  {type.sizes.map((size, sizeKey) => (
                    <TableCell key={sizeKey}>-</TableCell>
                  ))}
                </TableRow>
              ) : (
                selectedIngredients.map(selectedIngredient => {
                  const key = !selectedIngredient.recipe
                    ? selectedIngredient.ingredient['@id']
                    : selectedIngredient.recipe['@id'];
                  return (
                    <SizeRow
                      key={key}
                      ingredient={selectedIngredient}
                      mealTypeSizes={type.sizes}
                      type={type}
                      handleRemoveIngredient={() =>
                        handleRemoveIngredient(selectedIngredient)
                      }
                      dishSizes={dishSizesByType}
                      onChange={onChange}
                      mealTypeIngredientsSum={mealTypeIngredientsSum}
                      shouldRescale={shouldRescale}
                      setShouldRescale={setShouldRescale}
                      checkedDishToResize={checkedDishToResize}
                      setDishToResize={setDishToResize}
                    />
                  );
                })
              )}

              {selectedIngredients.length > 0 && (
                <PriceSumRow
                  classes={classes}
                  mealTypeSizes={type.sizes}
                  dishSizes={dishSizesByType}
                  name={this.props.t('form.ingredientsDetails.price')}
                />
              )}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
}

export default TypeBlockAdvanced;

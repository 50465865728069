import React, { useState } from 'react';
import DataGrid from '../DataGrid/DataGrid';
import AdminTable from '../../layouts/AdminTable';
import { useTranslation } from 'react-i18next';
import FormSelectSingle from '../FormSelect/FormSelectSingle';
import { withTheme } from '@material-ui/core';

const RecordFieldHistory = ({ iri, defaultField, fields }) => {
  const { t } = useTranslation();
  const [historyField, setHistoryField] = useState(defaultField);
  const classes = withTheme();

  return (
    <AdminTable
      title={
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <div>{t('form.history')}&nbsp;</div>
          <FormSelectSingle
            classes={classes}
            options={fields}
            value={historyField}
            trackBy="val"
            mapBy="name"
            handleChange={({ target: { value } }) => setHistoryField(value)}
            labelSm={3}
            selectSm={4}
          />
        </div>
      }
    >
      <DataGrid
        actions={false}
        remove={false}
        export={true}
        hideArchive={true}
        paginationTop={true}
        paginationBottom={false}
        refresh={historyField}
        url={iri}
        reportName={'ingredient'}
        columns={[
          {
            title: 'recordFieldHistory.actor',
            accessor: 'actor',
            name: 'actor',
            filterable: true,
          },
          {
            title: 'recordFieldHistory.changedAt',
            accessor: 'changedAt',
            name: 'changedAt',
            filterable: true,
          },
          {
            title: 'recordFieldHistory.before',
            accessor: 'before',
            name: 'before',
            filterable: true,
          },
          {
            title: 'recordFieldHistory.after',
            accessor: 'after',
            name: 'after',
            filterable: true,
          },
        ]}
        manipulateQuery={(table, query) => {
          query.recordFieldHistory = historyField;

          return query;
        }}
      />
    </AdminTable>
  );
};

export default RecordFieldHistory;

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import { Divider } from '@material-ui/core';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';
import { usePanelConfigurationContext } from './PanelConfiguration.context';

import { Info } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import CardWrapper from 'components/Card/CardWrapper';
import TOAST_DURATIONS from 'helpers/toastDurations';

const ClientPanelGeneralSettings = ({ t, openToast }) => {
  const {
    handleClientCanCanceledDietDay,
    handleCheckboxChange,
    brandConfig,
    configClientPanelModule,
    setConfigClientPanelModule,
    setBrandConfig,
  } = usePanelConfigurationContext();

  return (
    <CardWrapper
      title={t(
        'brands.newBrandForm.availableOptions',
        'Opcje zmian w panelu klienta'
      )}
    >
      <CardBody>
        <Divider style={{ marginBottom: '20px' }} />
        <GridContainer>
          <GridItem md={6}>
            <GridContainer>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allChangesWithoutCosts}
                  onClick={() => {
                    handleCheckboxChange('allChangesWithoutCosts');
                  }}
                  label={t(
                    'brands.newBrandForm.freeChanges',
                    'Wszystkie poniższe zmiany są bezpłatne'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allowChangeMultipleDays}
                  onClick={() => {
                    handleCheckboxChange('allowChangeMultipleDays');
                  }}
                  label={t(
                    'brands.newBrandForm.manyDays',
                    'Klient ma możliwość zmiany wielu dni diety jednocześnie'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allowChangeAddress}
                  onClick={() => {
                    handleCheckboxChange('allowChangeAddress');
                  }}
                  label={t(
                    'brands.newBrandForm.addressChange',
                    'Klient ma możliwość zmiany adresu'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allowChangeDeliveryDate}
                  onClick={() => {
                    handleClientCanCanceledDietDay();
                    handleCheckboxChange('allowChangeDeliveryDate');
                  }}
                  label={t(
                    'brands.newBrandForm.dateChange',
                    'Klient ma możliwość zmiany daty dostawy'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allowChangeDiet}
                  onClick={() => {
                    handleCheckboxChange('allowChangeDiet');
                  }}
                  label={t(
                    'brands.newBrandForm.dietChange',
                    'Klient ma możliwość zmiany diety'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={configClientPanelModule.allowClientOwnDietName}
                  onClick={() => {
                    handleCheckboxChange(
                      'allowClientOwnDietName',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brands.newBrandForm.allowClientOwnDietName',
                    'Zezwól klientowi na własne nazwy diet'
                  )}
                />
              </GridItem>

              <GridItem md={12}>
                <LabeledCheckbox
                  checked={configClientPanelModule?.allowFilterTags}
                  onClick={() => {
                    handleCheckboxChange(
                      'allowFilterTags',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brandCfg.clientPanelDesign.allowFilterTags',
                    'Klient ma możliwość filtrowania dań po tagach dla klienta'
                  )}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem md={6}>
            <GridContainer>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allowChangeVariant}
                  onClick={() => {
                    handleCheckboxChange('allowChangeVariant', setBrandConfig, {
                      allowChangeCalorific: brandConfig.allowChangeVariant
                        ? undefined
                        : true,
                    });
                  }}
                  label={t(
                    'brands.newBrandForm.variantChange',
                    'Klient ma możliwość zmiany wariantu'
                  )}
                />
              </GridItem>

              <GridItem md={12}>
                <LabeledCheckbox
                  checked={brandConfig.allowChangeCalorific}
                  disabled={brandConfig.allowChangeVariant}
                  onClick={() => {
                    handleCheckboxChange('allowChangeCalorific');
                  }}
                  label={t(
                    'brands.newBrandForm.calorificChange',
                    'Klient ma możliwość zmiany kaloryczności'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={
                    configClientPanelModule?.allowClientPayWithMoneboxEverywhere
                  }
                  onClick={() => {
                    handleCheckboxChange(
                      'allowClientPayWithMoneboxEverywhere',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brands.newBrandForm.allowClientPayWithMoneboxEverywhere',
                    'Klient ma możliwość płacenia punktami ze skarbonki za zmiany w panelu klienta'
                  )}
                />
              </GridItem>

              <GridItem md={12}>
                <LabeledCheckbox
                  checked={configClientPanelModule?.clientCanCancelSubscription}
                  onClick={() => {
                    handleCheckboxChange(
                      'clientCanCancelSubscription',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'module.configClientPanel.clientCanCancelSubscription',
                    'Klient ma możliwość samodzielnego anulowania subskrypcji'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={configClientPanelModule?.allowCancelBags}
                  onClick={() => {
                    if (!brandConfig.allowChangeDeliveryDate) {
                      openToast({
                        messages: [
                          t(
                            'brands.newBrandForm.cancelDayDiet',
                            'Brak dostępu do kalendarza automatycznie wyklucza możliwość samodzielnej rezygnacji z dnia diety przez klienta'
                          ),
                        ],
                        type: 'warning',
                        autoHideDuration: TOAST_DURATIONS.LG,
                      });
                      return;
                    }
                    handleCheckboxChange(
                      'allowCancelBags',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'module.configClientPanel.allowCancelBags',
                    'Klient ma możliwość samodzielnej rezygnacji z dnia diety'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <LabeledCheckbox
                  checked={configClientPanelModule?.clientCanRemoveAccount}
                  onClick={() => {
                    handleCheckboxChange(
                      'clientCanRemoveAccount',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'module.configClientPanel.clientCanRemoveAccount',
                    'Klient ma możliwość samodzielnego skasowania konta'
                  )}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridItem md={12}>
          <Divider style={{ marginBottom: '20px' }} />
          <GridContainer>
            <GridItem md={6}>
              <GridItem md={12} style={{ marginLeft: '-30px' }}>
                <LabeledCheckbox
                  checked={
                    configClientPanelModule.validateBagChangesWithSlots || false
                  }
                  onClick={() => {
                    handleCheckboxChange(
                      'validateBagChangesWithSlots',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brands.newBrandForm.validateBagChangesWithSlots',
                    'Uwzględnij walidację slotów czasowych przy zmianach torby'
                  )}
                />
              </GridItem>
              <GridItem md={12} style={{ marginLeft: '-30px' }}>
                <LabeledCheckbox
                  checked={configClientPanelModule.hideSmallCalendar || false}
                  onClick={() => {
                    handleCheckboxChange(
                      'hideSmallCalendar',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brands.newBrandForm.hideSmallCalendar',
                    'Ukryj kalendarz'
                  )}
                />
                <Tooltip
                  title={
                    <div>
                      <h4>
                        {t(
                          'brands.newBrandForm.hideSmallCalendar.tooltip',
                          'Ukrycie kalendarza kierowane jest do np. sklepów z posiłkami dedykowanymi sprzedaży wigilijnej, gdzie dostawa odbywa się tylko jednego dnia.'
                        )}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info
                    style={{ color: 'RGB(138,138,138)', marginLeft: '-10px' }}
                    fontSize={'small'}
                  />
                </Tooltip>
              </GridItem>
            </GridItem>
            <GridItem md={6}>
              <GridItem md={12} style={{ marginLeft: '-15px' }}>
                <LabeledCheckbox
                  checked={
                    configClientPanelModule?.showInfoWithPhotoAboutDeliveredBag
                  }
                  onClick={() => {
                    handleCheckboxChange(
                      'showInfoWithPhotoAboutDeliveredBag',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brandCfg.clientPanelDesign.showInfoWithPhotoAboutDeliveredBag',
                    'Wyświetlaj w panelu klienta informację ze zdjęciem o dostarczonej torbie'
                  )}
                />
              </GridItem>
              <GridItem md={12} style={{ marginLeft: '-15px' }}>
                <LabeledCheckbox
                  checked={configClientPanelModule?.allowRenewDiet}
                  onClick={() => {
                    handleCheckboxChange(
                      'allowRenewDiet',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    'brandCfg.clientPanelDesign.allowRenewDiet',
                    'Pokazuj przycisk "Przedłuż dietę"'
                  )}
                />
              </GridItem>
            </GridItem>
          </GridContainer>
        </GridItem>
      </CardBody>
    </CardWrapper>
  );
};

const enhance = compose(withTranslation(), withToast);

export default enhance(ClientPanelGeneralSettings);

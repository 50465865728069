import { Router, Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';

import DishesPackhouseTaskPreview from './components/DishesPackhouseTaskPreview/DishesPackhouseTaskPreview';
import withRole from 'components/Security/withRole';
import { ROLE_SHOW_DISH_PACKHOUSE } from 'helpers/roles';

import GeneratedTasks from '../common/GeneratedTasks/GeneratedTasks';

const DishesPackhouseComponent = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const ListSecured = withRole(ROLE_SHOW_DISH_PACKHOUSE, GeneratedTasks);
  const PreviewSecured = withRole(
    ROLE_SHOW_DISH_PACKHOUSE,
    DishesPackhouseTaskPreview
  );

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ListSecured
              {...parentProps}
              role={'DISH_PACKHOUSE'}
              preview={`${location.pathname}/preview`}
              defaultHiddenColumns={[
                'taskListStatuses.cookRecipe',
                'taskListStatuses.packDiet',
              ]}
              separatingConfigsParam={'fe=dishes'}
            />
          )}
        />
        <Route
          path={`${match.url}/preview/:id`}
          render={parentProps => <PreviewSecured {...parentProps} />}
        />
      </Switch>
    </Router>
  );
};

export default DishesPackhouseComponent;

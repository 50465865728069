// how get fonts
// https://www.faviator.xyz/playground
// in google console
// const fonts = $$('.input-google-fonts option').reduce((acc, item) => {
//   return [
//     ...acc, {
//       label: item.innerHTML,
//       value: item.value
//     }
//   ]
// }, [])
// JSON.stringify(fonts)
// copy and change from string to object

const googleFonts = [
  { label: 'ABeeZee', value: 'ABeeZee' },
  { label: 'Abel', value: 'Abel' },
  { label: 'Abhaya Libre', value: 'Abhaya Libre' },
  { label: 'Abril Fatface', value: 'Abril Fatface' },
  { label: 'Aclonica', value: 'Aclonica' },
  { label: 'Acme', value: 'Acme' },
  { label: 'Actor', value: 'Actor' },
  { label: 'Adamina', value: 'Adamina' },
  { label: 'Advent Pro', value: 'Advent Pro' },
  { label: 'Aguafina Script', value: 'Aguafina Script' },
  { label: 'Akronim', value: 'Akronim' },
  { label: 'Aladin', value: 'Aladin' },
  { label: 'Aldrich', value: 'Aldrich' },
  { label: 'Alef', value: 'Alef' },
  { label: 'Alegreya', value: 'Alegreya' },
  { label: 'Alegreya SC', value: 'Alegreya SC' },
  { label: 'Alegreya Sans', value: 'Alegreya Sans' },
  { label: 'Alegreya Sans SC', value: 'Alegreya Sans SC' },
  { label: 'Aleo', value: 'Aleo' },
  { label: 'Alex Brush', value: 'Alex Brush' },
  { label: 'Alfa Slab One', value: 'Alfa Slab One' },
  { label: 'Alice', value: 'Alice' },
  { label: 'Alike', value: 'Alike' },
  { label: 'Alike Angular', value: 'Alike Angular' },
  { label: 'Allan', value: 'Allan' },
  { label: 'Allerta', value: 'Allerta' },
  { label: 'Allerta Stencil', value: 'Allerta Stencil' },
  { label: 'Allura', value: 'Allura' },
  { label: 'Almendra', value: 'Almendra' },
  { label: 'Almendra Display', value: 'Almendra Display' },
  { label: 'Almendra SC', value: 'Almendra SC' },
  { label: 'Amarante', value: 'Amarante' },
  { label: 'Amaranth', value: 'Amaranth' },
  { label: 'Amatic SC', value: 'Amatic SC' },
  { label: 'Amethysta', value: 'Amethysta' },
  { label: 'Amiko', value: 'Amiko' },
  { label: 'Amiri', value: 'Amiri' },
  { label: 'Amita', value: 'Amita' },
  { label: 'Anaheim', value: 'Anaheim' },
  { label: 'Andada', value: 'Andada' },
  { label: 'Andika', value: 'Andika' },
  { label: 'Angkor', value: 'Angkor' },
  { label: 'Annie Use Your Telescope', value: 'Annie Use Your Telescope' },
  { label: 'Anonymous Pro', value: 'Anonymous Pro' },
  { label: 'Antic', value: 'Antic' },
  { label: 'Antic Didone', value: 'Antic Didone' },
  { label: 'Antic Slab', value: 'Antic Slab' },
  { label: 'Anton', value: 'Anton' },
  { label: 'Arapey', value: 'Arapey' },
  { label: 'Arbutus', value: 'Arbutus' },
  { label: 'Arbutus Slab', value: 'Arbutus Slab' },
  { label: 'Architects Daughter', value: 'Architects Daughter' },
  { label: 'Archivo', value: 'Archivo' },
  { label: 'Archivo Black', value: 'Archivo Black' },
  { label: 'Archivo Narrow', value: 'Archivo Narrow' },
  { label: 'Aref Ruqaa', value: 'Aref Ruqaa' },
  { label: 'Arima Madurai', value: 'Arima Madurai' },
  { label: 'Arimo', value: 'Arimo' },
  { label: 'Arizonia', value: 'Arizonia' },
  { label: 'Armata', value: 'Armata' },
  { label: 'Arsenal', value: 'Arsenal' },
  { label: 'Artifika', value: 'Artifika' },
  { label: 'Arvo', value: 'Arvo' },
  { label: 'Arya', value: 'Arya' },
  { label: 'Asap', value: 'Asap' },
  { label: 'Asap Condensed', value: 'Asap Condensed' },
  { label: 'Asar', value: 'Asar' },
  { label: 'Asset', value: 'Asset' },
  { label: 'Assistant', value: 'Assistant' },
  { label: 'Astloch', value: 'Astloch' },
  { label: 'Asul', value: 'Asul' },
  { label: 'Athiti', value: 'Athiti' },
  { label: 'Atma', value: 'Atma' },
  { label: 'Atomic Age', value: 'Atomic Age' },
  { label: 'Aubrey', value: 'Aubrey' },
  { label: 'Audiowide', value: 'Audiowide' },
  { label: 'Autour One', value: 'Autour One' },
  { label: 'Average', value: 'Average' },
  { label: 'Average Sans', value: 'Average Sans' },
  { label: 'Averia Gruesa Libre', value: 'Averia Gruesa Libre' },
  { label: 'Averia Libre', value: 'Averia Libre' },
  { label: 'Averia Sans Libre', value: 'Averia Sans Libre' },
  { label: 'Averia Serif Libre', value: 'Averia Serif Libre' },
  { label: 'B612', value: 'B612' },
  { label: 'B612 Mono', value: 'B612 Mono' },
  { label: 'Bad Script', value: 'Bad Script' },
  { label: 'Bahiana', value: 'Bahiana' },
  { label: 'Bahianita', value: 'Bahianita' },
  { label: 'Bai Jamjuree', value: 'Bai Jamjuree' },
  { label: 'Baloo', value: 'Baloo' },
  { label: 'Baloo Bhai', value: 'Baloo Bhai' },
  { label: 'Baloo Bhaijaan', value: 'Baloo Bhaijaan' },
  { label: 'Baloo Bhaina', value: 'Baloo Bhaina' },
  { label: 'Baloo Chettan', value: 'Baloo Chettan' },
  { label: 'Baloo Da', value: 'Baloo Da' },
  { label: 'Baloo Paaji', value: 'Baloo Paaji' },
  { label: 'Baloo Tamma', value: 'Baloo Tamma' },
  { label: 'Baloo Tammudu', value: 'Baloo Tammudu' },
  { label: 'Baloo Thambi', value: 'Baloo Thambi' },
  { label: 'Balthazar', value: 'Balthazar' },
  { label: 'Bangers', value: 'Bangers' },
  { label: 'Barlow', value: 'Barlow' },
  { label: 'Barlow Condensed', value: 'Barlow Condensed' },
  { label: 'Barlow Semi Condensed', value: 'Barlow Semi Condensed' },
  { label: 'Barriecito', value: 'Barriecito' },
  { label: 'Barrio', value: 'Barrio' },
  { label: 'Basic', value: 'Basic' },
  { label: 'Battambang', value: 'Battambang' },
  { label: 'Baumans', value: 'Baumans' },
  { label: 'Bayon', value: 'Bayon' },
  { label: 'Belgrano', value: 'Belgrano' },
  { label: 'Bellefair', value: 'Bellefair' },
  { label: 'Belleza', value: 'Belleza' },
  { label: 'BenchNine', value: 'BenchNine' },
  { label: 'Bentham', value: 'Bentham' },
  { label: 'Berkshire Swash', value: 'Berkshire Swash' },
  { label: 'Beth Ellen', value: 'Beth Ellen' },
  { label: 'Bevan', value: 'Bevan' },
  { label: 'Bigelow Rules', value: 'Bigelow Rules' },
  { label: 'Bigshot One', value: 'Bigshot One' },
  { label: 'Bilbo', value: 'Bilbo' },
  { label: 'Bilbo Swash Caps', value: 'Bilbo Swash Caps' },
  { label: 'BioRhyme', value: 'BioRhyme' },
  { label: 'BioRhyme Expanded', value: 'BioRhyme Expanded' },
  { label: 'Biryani', value: 'Biryani' },
  { label: 'Bitter', value: 'Bitter' },
  { label: 'Black And White Picture', value: 'Black And White Picture' },
  { label: 'Black Han Sans', value: 'Black Han Sans' },
  { label: 'Black Ops One', value: 'Black Ops One' },
  { label: 'Blinker', value: 'Blinker' },
  { label: 'Bokor', value: 'Bokor' },
  { label: 'Bonbon', value: 'Bonbon' },
  { label: 'Boogaloo', value: 'Boogaloo' },
  { label: 'Bowlby One', value: 'Bowlby One' },
  { label: 'Bowlby One SC', value: 'Bowlby One SC' },
  { label: 'Brawler', value: 'Brawler' },
  { label: 'Bree Serif', value: 'Bree Serif' },
  { label: 'Bubblegum Sans', value: 'Bubblegum Sans' },
  { label: 'Bubbler One', value: 'Bubbler One' },
  { label: 'Buda', value: 'Buda' },
  { label: 'Buenard', value: 'Buenard' },
  { label: 'Bungee', value: 'Bungee' },
  { label: 'Bungee Hairline', value: 'Bungee Hairline' },
  { label: 'Bungee Inline', value: 'Bungee Inline' },
  { label: 'Bungee Outline', value: 'Bungee Outline' },
  { label: 'Bungee Shade', value: 'Bungee Shade' },
  { label: 'Butcherman', value: 'Butcherman' },
  { label: 'Butterfly Kids', value: 'Butterfly Kids' },
  { label: 'Cabin', value: 'Cabin' },
  { label: 'Cabin Condensed', value: 'Cabin Condensed' },
  { label: 'Cabin Sketch', value: 'Cabin Sketch' },
  { label: 'Caesar Dressing', value: 'Caesar Dressing' },
  { label: 'Cagliostro', value: 'Cagliostro' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Calligraffitti', value: 'Calligraffitti' },
  { label: 'Cambay', value: 'Cambay' },
  { label: 'Cambo', value: 'Cambo' },
  { label: 'Candal', value: 'Candal' },
  { label: 'Cantarell', value: 'Cantarell' },
  { label: 'Cantata One', value: 'Cantata One' },
  { label: 'Cantora One', value: 'Cantora One' },
  { label: 'Capriola', value: 'Capriola' },
  { label: 'Cardo', value: 'Cardo' },
  { label: 'Carme', value: 'Carme' },
  { label: 'Carrois Gothic', value: 'Carrois Gothic' },
  { label: 'Carrois Gothic SC', value: 'Carrois Gothic SC' },
  { label: 'Carter One', value: 'Carter One' },
  { label: 'Catamaran', value: 'Catamaran' },
  { label: 'Caudex', value: 'Caudex' },
  { label: 'Caveat', value: 'Caveat' },
  { label: 'Caveat Brush', value: 'Caveat Brush' },
  { label: 'Cedarville Cursive', value: 'Cedarville Cursive' },
  { label: 'Ceviche One', value: 'Ceviche One' },
  { label: 'Chakra Petch', value: 'Chakra Petch' },
  { label: 'Changa', value: 'Changa' },
  { label: 'Changa One', value: 'Changa One' },
  { label: 'Chango', value: 'Chango' },
  { label: 'Charm', value: 'Charm' },
  { label: 'Charmonman', value: 'Charmonman' },
  { label: 'Chathura', value: 'Chathura' },
  { label: 'Chau Philomene One', value: 'Chau Philomene One' },
  { label: 'Chela One', value: 'Chela One' },
  { label: 'Chelsea Market', value: 'Chelsea Market' },
  { label: 'Chenla', value: 'Chenla' },
  { label: 'Cherry Cream Soda', value: 'Cherry Cream Soda' },
  { label: 'Cherry Swash', value: 'Cherry Swash' },
  { label: 'Chewy', value: 'Chewy' },
  { label: 'Chicle', value: 'Chicle' },
  { label: 'Chivo', value: 'Chivo' },
  { label: 'Chonburi', value: 'Chonburi' },
  { label: 'Cinzel', value: 'Cinzel' },
  { label: 'Cinzel Decorative', value: 'Cinzel Decorative' },
  { label: 'Clicker Script', value: 'Clicker Script' },
  { label: 'Coda', value: 'Coda' },
  { label: 'Coda Caption', value: 'Coda Caption' },
  { label: 'Codystar', value: 'Codystar' },
  { label: 'Coiny', value: 'Coiny' },
  { label: 'Combo', value: 'Combo' },
  { label: 'Comfortaa', value: 'Comfortaa' },
  { label: 'Coming Soon', value: 'Coming Soon' },
  { label: 'Concert One', value: 'Concert One' },
  { label: 'Condiment', value: 'Condiment' },
  { label: 'Content', value: 'Content' },
  { label: 'Contrail One', value: 'Contrail One' },
  { label: 'Convergence', value: 'Convergence' },
  { label: 'Cookie', value: 'Cookie' },
  { label: 'Copse', value: 'Copse' },
  { label: 'Corben', value: 'Corben' },
  { label: 'Cormorant', value: 'Cormorant' },
  { label: 'Cormorant Garamond', value: 'Cormorant Garamond' },
  { label: 'Cormorant Infant', value: 'Cormorant Infant' },
  { label: 'Cormorant SC', value: 'Cormorant SC' },
  { label: 'Cormorant Unicase', value: 'Cormorant Unicase' },
  { label: 'Cormorant Upright', value: 'Cormorant Upright' },
  { label: 'Courgette', value: 'Courgette' },
  { label: 'Cousine', value: 'Cousine' },
  { label: 'Coustard', value: 'Coustard' },
  { label: 'Covered By Your Grace', value: 'Covered By Your Grace' },
  { label: 'Crafty Girls', value: 'Crafty Girls' },
  { label: 'Creepster', value: 'Creepster' },
  { label: 'Crete Round', value: 'Crete Round' },
  { label: 'Crimson Pro', value: 'Crimson Pro' },
  { label: 'Crimson Text', value: 'Crimson Text' },
  { label: 'Croissant One', value: 'Croissant One' },
  { label: 'Crushed', value: 'Crushed' },
  { label: 'Cuprum', value: 'Cuprum' },
  { label: 'Cute Font', value: 'Cute Font' },
  { label: 'Cutive', value: 'Cutive' },
  { label: 'Cutive Mono', value: 'Cutive Mono' },
  { label: 'DM Sans', value: 'DM Sans' },
  { label: 'DM Serif Display', value: 'DM Serif Display' },
  { label: 'DM Serif Text', value: 'DM Serif Text' },
  { label: 'Damion', value: 'Damion' },
  { label: 'Dancing Script', value: 'Dancing Script' },
  { label: 'Dangrek', value: 'Dangrek' },
  { label: 'Darker Grotesque', value: 'Darker Grotesque' },
  { label: 'David Libre', value: 'David Libre' },
  { label: 'Dawning of a New Day', value: 'Dawning of a New Day' },
  { label: 'Days One', value: 'Days One' },
  { label: 'Dekko', value: 'Dekko' },
  { label: 'Delius', value: 'Delius' },
  { label: 'Delius Swash Caps', value: 'Delius Swash Caps' },
  { label: 'Delius Unicase', value: 'Delius Unicase' },
  { label: 'Della Respira', value: 'Della Respira' },
  { label: 'Denk One', value: 'Denk One' },
  { label: 'Devonshire', value: 'Devonshire' },
  { label: 'Dhurjati', value: 'Dhurjati' },
  { label: 'Didact Gothic', value: 'Didact Gothic' },
  { label: 'Diplomata', value: 'Diplomata' },
  { label: 'Diplomata SC', value: 'Diplomata SC' },
  { label: 'Do Hyeon', value: 'Do Hyeon' },
  { label: 'Dokdo', value: 'Dokdo' },
  { label: 'Domine', value: 'Domine' },
  { label: 'Donegal One', value: 'Donegal One' },
  { label: 'Doppio One', value: 'Doppio One' },
  { label: 'Dorsa', value: 'Dorsa' },
  { label: 'Dosis', value: 'Dosis' },
  { label: 'Dr Sugiyama', value: 'Dr Sugiyama' },
  { label: 'Duru Sans', value: 'Duru Sans' },
  { label: 'Dynalight', value: 'Dynalight' },
  { label: 'EB Garamond', value: 'EB Garamond' },
  { label: 'Eagle Lake', value: 'Eagle Lake' },
  { label: 'East Sea Dokdo', value: 'East Sea Dokdo' },
  { label: 'Eater', value: 'Eater' },
  { label: 'Economica', value: 'Economica' },
  { label: 'Eczar', value: 'Eczar' },
  { label: 'El Messiri', value: 'El Messiri' },
  { label: 'Electrolize', value: 'Electrolize' },
  { label: 'Elsie', value: 'Elsie' },
  { label: 'Elsie Swash Caps', value: 'Elsie Swash Caps' },
  { label: 'Emblema One', value: 'Emblema One' },
  { label: 'Emilys Candy', value: 'Emilys Candy' },
  { label: 'Encode Sans', value: 'Encode Sans' },
  { label: 'Encode Sans Condensed', value: 'Encode Sans Condensed' },
  { label: 'Encode Sans Expanded', value: 'Encode Sans Expanded' },
  { label: 'Encode Sans Semi Condensed', value: 'Encode Sans Semi Condensed' },
  { label: 'Encode Sans Semi Expanded', value: 'Encode Sans Semi Expanded' },
  { label: 'Engagement', value: 'Engagement' },
  { label: 'Englebert', value: 'Englebert' },
  { label: 'Enriqueta', value: 'Enriqueta' },
  { label: 'Erica One', value: 'Erica One' },
  { label: 'Esteban', value: 'Esteban' },
  { label: 'Euphoria Script', value: 'Euphoria Script' },
  { label: 'Ewert', value: 'Ewert' },
  { label: 'Exo', value: 'Exo' },
  { label: 'Exo 2', value: 'Exo 2' },
  { label: 'Expletus Sans', value: 'Expletus Sans' },
  { label: 'Fahkwang', value: 'Fahkwang' },
  { label: 'Fanwood Text', value: 'Fanwood Text' },
  { label: 'Farro', value: 'Farro' },
  { label: 'Farsan', value: 'Farsan' },
  { label: 'Fascinate', value: 'Fascinate' },
  { label: 'Fascinate Inline', value: 'Fascinate Inline' },
  { label: 'Faster One', value: 'Faster One' },
  { label: 'Fasthand', value: 'Fasthand' },
  { label: 'Fauna One', value: 'Fauna One' },
  { label: 'Faustina', value: 'Faustina' },
  { label: 'Federant', value: 'Federant' },
  { label: 'Federo', value: 'Federo' },
  { label: 'Felipa', value: 'Felipa' },
  { label: 'Fenix', value: 'Fenix' },
  { label: 'Finger Paint', value: 'Finger Paint' },
  { label: 'Fira Code', value: 'Fira Code' },
  { label: 'Fira Mono', value: 'Fira Mono' },
  { label: 'Fira Sans', value: 'Fira Sans' },
  { label: 'Fira Sans Condensed', value: 'Fira Sans Condensed' },
  { label: 'Fira Sans Extra Condensed', value: 'Fira Sans Extra Condensed' },
  { label: 'Fjalla One', value: 'Fjalla One' },
  { label: 'Fjord One', value: 'Fjord One' },
  { label: 'Flamenco', value: 'Flamenco' },
  { label: 'Flavors', value: 'Flavors' },
  { label: 'Fondamento', value: 'Fondamento' },
  { label: 'Fontdiner Swanky', value: 'Fontdiner Swanky' },
  { label: 'Forum', value: 'Forum' },
  { label: 'Francois One', value: 'Francois One' },
  { label: 'Frank Ruhl Libre', value: 'Frank Ruhl Libre' },
  { label: 'Freckle Face', value: 'Freckle Face' },
  { label: 'Fredericka the Great', value: 'Fredericka the Great' },
  { label: 'Fredoka One', value: 'Fredoka One' },
  { label: 'Freehand', value: 'Freehand' },
  { label: 'Fresca', value: 'Fresca' },
  { label: 'Frijole', value: 'Frijole' },
  { label: 'Fruktur', value: 'Fruktur' },
  { label: 'Fugaz One', value: 'Fugaz One' },
  { label: 'GFS Didot', value: 'GFS Didot' },
  { label: 'GFS Neohellenic', value: 'GFS Neohellenic' },
  { label: 'Gabriela', value: 'Gabriela' },
  { label: 'Gaegu', value: 'Gaegu' },
  { label: 'Gafata', value: 'Gafata' },
  { label: 'Galada', value: 'Galada' },
  { label: 'Galdeano', value: 'Galdeano' },
  { label: 'Galindo', value: 'Galindo' },
  { label: 'Gamja Flower', value: 'Gamja Flower' },
  { label: 'Gentium Basic', value: 'Gentium Basic' },
  { label: 'Gentium Book Basic', value: 'Gentium Book Basic' },
  { label: 'Geo', value: 'Geo' },
  { label: 'Geostar', value: 'Geostar' },
  { label: 'Geostar Fill', value: 'Geostar Fill' },
  { label: 'Germania One', value: 'Germania One' },
  { label: 'Gidugu', value: 'Gidugu' },
  { label: 'Gilda Display', value: 'Gilda Display' },
  { label: 'Give You Glory', value: 'Give You Glory' },
  { label: 'Glass Antiqua', value: 'Glass Antiqua' },
  { label: 'Glegoo', value: 'Glegoo' },
  { label: 'Gloria Hallelujah', value: 'Gloria Hallelujah' },
  { label: 'Goblin One', value: 'Goblin One' },
  { label: 'Gochi Hand', value: 'Gochi Hand' },
  { label: 'Gorditas', value: 'Gorditas' },
  { label: 'Gothic A1', value: 'Gothic A1' },
  { label: 'Goudy Bookletter 1911', value: 'Goudy Bookletter 1911' },
  { label: 'Graduate', value: 'Graduate' },
  { label: 'Grand Hotel', value: 'Grand Hotel' },
  { label: 'Gravitas One', value: 'Gravitas One' },
  { label: 'Great Vibes', value: 'Great Vibes' },
  { label: 'Grenze', value: 'Grenze' },
  { label: 'Griffy', value: 'Griffy' },
  { label: 'Gruppo', value: 'Gruppo' },
  { label: 'Gudea', value: 'Gudea' },
  { label: 'Gugi', value: 'Gugi' },
  { label: 'Gurajada', value: 'Gurajada' },
  { label: 'Habibi', value: 'Habibi' },
  { label: 'Halant', value: 'Halant' },
  { label: 'Hammersmith One', value: 'Hammersmith One' },
  { label: 'Hanalei', value: 'Hanalei' },
  { label: 'Hanalei Fill', value: 'Hanalei Fill' },
  { label: 'Handlee', value: 'Handlee' },
  { label: 'Hanuman', value: 'Hanuman' },
  { label: 'Happy Monkey', value: 'Happy Monkey' },
  { label: 'Harmattan', value: 'Harmattan' },
  { label: 'Headland One', value: 'Headland One' },
  { label: 'Heebo', value: 'Heebo' },
  { label: 'Henny Penny', value: 'Henny Penny' },
  { label: 'Herr Von Muellerhoff', value: 'Herr Von Muellerhoff' },
  { label: 'Hi Melody', value: 'Hi Melody' },
  { label: 'Hind', value: 'Hind' },
  { label: 'Hind Guntur', value: 'Hind Guntur' },
  { label: 'Hind Madurai', value: 'Hind Madurai' },
  { label: 'Hind Siliguri', value: 'Hind Siliguri' },
  { label: 'Hind Vadodara', value: 'Hind Vadodara' },
  { label: 'Holtwood One SC', value: 'Holtwood One SC' },
  { label: 'Homemade Apple', value: 'Homemade Apple' },
  { label: 'Homenaje', value: 'Homenaje' },
  { label: 'IBM Plex Mono', value: 'IBM Plex Mono' },
  { label: 'IBM Plex Sans', value: 'IBM Plex Sans' },
  { label: 'IBM Plex Sans Condensed', value: 'IBM Plex Sans Condensed' },
  { label: 'IBM Plex Serif', value: 'IBM Plex Serif' },
  { label: 'IM Fell DW Pica', value: 'IM Fell DW Pica' },
  { label: 'IM Fell DW Pica SC', value: 'IM Fell DW Pica SC' },
  { label: 'IM Fell Double Pica', value: 'IM Fell Double Pica' },
  { label: 'IM Fell Double Pica SC', value: 'IM Fell Double Pica SC' },
  { label: 'IM Fell English', value: 'IM Fell English' },
  { label: 'IM Fell English SC', value: 'IM Fell English SC' },
  { label: 'IM Fell French Canon', value: 'IM Fell French Canon' },
  { label: 'IM Fell French Canon SC', value: 'IM Fell French Canon SC' },
  { label: 'IM Fell Great Primer', value: 'IM Fell Great Primer' },
  { label: 'IM Fell Great Primer SC', value: 'IM Fell Great Primer SC' },
  { label: 'Iceberg', value: 'Iceberg' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'Imprima', value: 'Imprima' },
  { label: 'Inconsolata', value: 'Inconsolata' },
  { label: 'Inder', value: 'Inder' },
  { label: 'Indie Flower', value: 'Indie Flower' },
  { label: 'Inika', value: 'Inika' },
  { label: 'Inknut Antiqua', value: 'Inknut Antiqua' },
  { label: 'Irish Grover', value: 'Irish Grover' },
  { label: 'Istok Web', value: 'Istok Web' },
  { label: 'Italiana', value: 'Italiana' },
  { label: 'Italianno', value: 'Italianno' },
  { label: 'Itim', value: 'Itim' },
  { label: 'Jacques Francois', value: 'Jacques Francois' },
  { label: 'Jacques Francois Shadow', value: 'Jacques Francois Shadow' },
  { label: 'Jaldi', value: 'Jaldi' },
  { label: 'Jim Nightshade', value: 'Jim Nightshade' },
  { label: 'Jockey One', value: 'Jockey One' },
  { label: 'Jolly Lodger', value: 'Jolly Lodger' },
  { label: 'Jomhuria', value: 'Jomhuria' },
  { label: 'Josefin Sans', value: 'Josefin Sans' },
  { label: 'Josefin Slab', value: 'Josefin Slab' },
  { label: 'Joti One', value: 'Joti One' },
  { label: 'Jua', value: 'Jua' },
  { label: 'Judson', value: 'Judson' },
  { label: 'Julee', value: 'Julee' },
  { label: 'Julius Sans One', value: 'Julius Sans One' },
  { label: 'Junge', value: 'Junge' },
  { label: 'Jura', value: 'Jura' },
  { label: 'Just Another Hand', value: 'Just Another Hand' },
  { label: 'Just Me Again Down Here', value: 'Just Me Again Down Here' },
  { label: 'K2D', value: 'K2D' },
  { label: 'Kadwa', value: 'Kadwa' },
  { label: 'Kalam', value: 'Kalam' },
  { label: 'Kameron', value: 'Kameron' },
  { label: 'Kanit', value: 'Kanit' },
  { label: 'Kantumruy', value: 'Kantumruy' },
  { label: 'Karla', value: 'Karla' },
  { label: 'Karma', value: 'Karma' },
  { label: 'Katibeh', value: 'Katibeh' },
  { label: 'Kaushan Script', value: 'Kaushan Script' },
  { label: 'Kavivanar', value: 'Kavivanar' },
  { label: 'Kavoon', value: 'Kavoon' },
  { label: 'Kdam Thmor', value: 'Kdam Thmor' },
  { label: 'Keania One', value: 'Keania One' },
  { label: 'Kelly Slab', value: 'Kelly Slab' },
  { label: 'Kenia', value: 'Kenia' },
  { label: 'Khand', value: 'Khand' },
  { label: 'Khmer', value: 'Khmer' },
  { label: 'Khula', value: 'Khula' },
  { label: 'Kirang Haerang', value: 'Kirang Haerang' },
  { label: 'Kite One', value: 'Kite One' },
  { label: 'Knewave', value: 'Knewave' },
  { label: 'KoHo', value: 'KoHo' },
  { label: 'Kodchasan', value: 'Kodchasan' },
  { label: 'Kosugi', value: 'Kosugi' },
  { label: 'Kosugi Maru', value: 'Kosugi Maru' },
  { label: 'Kotta One', value: 'Kotta One' },
  { label: 'Koulen', value: 'Koulen' },
  { label: 'Kranky', value: 'Kranky' },
  { label: 'Kreon', value: 'Kreon' },
  { label: 'Kristi', value: 'Kristi' },
  { label: 'Krona One', value: 'Krona One' },
  { label: 'Krub', value: 'Krub' },
  { label: 'Kumar One', value: 'Kumar One' },
  { label: 'Kumar One Outline', value: 'Kumar One Outline' },
  { label: 'Kurale', value: 'Kurale' },
  { label: 'La Belle Aurore', value: 'La Belle Aurore' },
  { label: 'Lacquer', value: 'Lacquer' },
  { label: 'Laila', value: 'Laila' },
  { label: 'Lakki Reddy', value: 'Lakki Reddy' },
  { label: 'Lalezar', value: 'Lalezar' },
  { label: 'Lancelot', value: 'Lancelot' },
  { label: 'Lateef', value: 'Lateef' },
  { label: 'Lato', value: 'Lato' },
  { label: 'League Script', value: 'League Script' },
  { label: 'Leckerli One', value: 'Leckerli One' },
  { label: 'Ledger', value: 'Ledger' },
  { label: 'Lekton', value: 'Lekton' },
  { label: 'Lemon', value: 'Lemon' },
  { label: 'Lemonada', value: 'Lemonada' },
  { label: 'Libre Barcode 128', value: 'Libre Barcode 128' },
  { label: 'Libre Barcode 128 Text', value: 'Libre Barcode 128 Text' },
  { label: 'Libre Barcode 39', value: 'Libre Barcode 39' },
  { label: 'Libre Barcode 39 Extended', value: 'Libre Barcode 39 Extended' },
  {
    label: 'Libre Barcode 39 Extended Text',
    value: 'Libre Barcode 39 Extended Text',
  },
  { label: 'Libre Barcode 39 Text', value: 'Libre Barcode 39 Text' },
  { label: 'Libre Baskerville', value: 'Libre Baskerville' },
  { label: 'Libre Caslon Display', value: 'Libre Caslon Display' },
  { label: 'Libre Caslon Text', value: 'Libre Caslon Text' },
  { label: 'Libre Franklin', value: 'Libre Franklin' },
  { label: 'Life Savers', value: 'Life Savers' },
  { label: 'Lilita One', value: 'Lilita One' },
  { label: 'Lily Script One', value: 'Lily Script One' },
  { label: 'Limelight', value: 'Limelight' },
  { label: 'Linden Hill', value: 'Linden Hill' },
  { label: 'Literata', value: 'Literata' },
  { label: 'Liu Jian Mao Cao', value: 'Liu Jian Mao Cao' },
  { label: 'Lobster', value: 'Lobster' },
  { label: 'Lobster Two', value: 'Lobster Two' },
  { label: 'Londrina Outline', value: 'Londrina Outline' },
  { label: 'Londrina Shadow', value: 'Londrina Shadow' },
  { label: 'Londrina Sketch', value: 'Londrina Sketch' },
  { label: 'Londrina Solid', value: 'Londrina Solid' },
  { label: 'Long Cang', value: 'Long Cang' },
  { label: 'Lora', value: 'Lora' },
  { label: 'Love Ya Like A Sister', value: 'Love Ya Like A Sister' },
  { label: 'Loved by the King', value: 'Loved by the King' },
  { label: 'Lovers Quarrel', value: 'Lovers Quarrel' },
  { label: 'Luckiest Guy', value: 'Luckiest Guy' },
  { label: 'Lusitana', value: 'Lusitana' },
  { label: 'Lustria', value: 'Lustria' },
  { label: 'M PLUS 1p', value: 'M PLUS 1p' },
  { label: 'M PLUS Rounded 1c', value: 'M PLUS Rounded 1c' },
  { label: 'Ma Shan Zheng', value: 'Ma Shan Zheng' },
  { label: 'Macondo', value: 'Macondo' },
  { label: 'Macondo Swash Caps', value: 'Macondo Swash Caps' },
  { label: 'Mada', value: 'Mada' },
  { label: 'Magra', value: 'Magra' },
  { label: 'Maiden Orange', value: 'Maiden Orange' },
  { label: 'Maitree', value: 'Maitree' },
  { label: 'Major Mono Display', value: 'Major Mono Display' },
  { label: 'Mako', value: 'Mako' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Mallanna', value: 'Mallanna' },
  { label: 'Mandali', value: 'Mandali' },
  { label: 'Manuale', value: 'Manuale' },
  { label: 'Marcellus', value: 'Marcellus' },
  { label: 'Marcellus SC', value: 'Marcellus SC' },
  { label: 'Marck Script', value: 'Marck Script' },
  { label: 'Margarine', value: 'Margarine' },
  { label: 'Markazi Text', value: 'Markazi Text' },
  { label: 'Marko One', value: 'Marko One' },
  { label: 'Marmelad', value: 'Marmelad' },
  { label: 'Martel', value: 'Martel' },
  { label: 'Martel Sans', value: 'Martel Sans' },
  { label: 'Marvel', value: 'Marvel' },
  { label: 'Mate', value: 'Mate' },
  { label: 'Mate SC', value: 'Mate SC' },
  { label: 'Maven Pro', value: 'Maven Pro' },
  { label: 'McLaren', value: 'McLaren' },
  { label: 'Meddon', value: 'Meddon' },
  { label: 'MedievalSharp', value: 'MedievalSharp' },
  { label: 'Medula One', value: 'Medula One' },
  { label: 'Meera Inimai', value: 'Meera Inimai' },
  { label: 'Megrim', value: 'Megrim' },
  { label: 'Meie Script', value: 'Meie Script' },
  { label: 'Merienda', value: 'Merienda' },
  { label: 'Merienda One', value: 'Merienda One' },
  { label: 'Merriweather', value: 'Merriweather' },
  { label: 'Merriweather Sans', value: 'Merriweather Sans' },
  { label: 'Metal', value: 'Metal' },
  { label: 'Metal Mania', value: 'Metal Mania' },
  { label: 'Metamorphous', value: 'Metamorphous' },
  { label: 'Metrophobic', value: 'Metrophobic' },
  { label: 'Michroma', value: 'Michroma' },
  { label: 'Milonga', value: 'Milonga' },
  { label: 'Miltonian', value: 'Miltonian' },
  { label: 'Miltonian Tattoo', value: 'Miltonian Tattoo' },
  { label: 'Mina', value: 'Mina' },
  { label: 'Miniver', value: 'Miniver' },
  { label: 'Miriam Libre', value: 'Miriam Libre' },
  { label: 'Mirza', value: 'Mirza' },
  { label: 'Miss Fajardose', value: 'Miss Fajardose' },
  { label: 'Mitr', value: 'Mitr' },
  { label: 'Modak', value: 'Modak' },
  { label: 'Modern Antiqua', value: 'Modern Antiqua' },
  { label: 'Mogra', value: 'Mogra' },
  { label: 'Molengo', value: 'Molengo' },
  { label: 'Molle', value: 'Molle' },
  { label: 'Monda', value: 'Monda' },
  { label: 'Monofett', value: 'Monofett' },
  { label: 'Monoton', value: 'Monoton' },
  { label: 'Monsieur La Doulaise', value: 'Monsieur La Doulaise' },
  { label: 'Montaga', value: 'Montaga' },
  { label: 'Montez', value: 'Montez' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Montserrat Alternates', value: 'Montserrat Alternates' },
  { label: 'Montserrat Subrayada', value: 'Montserrat Subrayada' },
  { label: 'Moul', value: 'Moul' },
  { label: 'Moulpali', value: 'Moulpali' },
  { label: 'Mountains of Christmas', value: 'Mountains of Christmas' },
  { label: 'Mouse Memoirs', value: 'Mouse Memoirs' },
  { label: 'Mr Bedfort', value: 'Mr Bedfort' },
  { label: 'Mr Dafoe', value: 'Mr Dafoe' },
  { label: 'Mr De Haviland', value: 'Mr De Haviland' },
  { label: 'Mrs Saint Delafield', value: 'Mrs Saint Delafield' },
  { label: 'Mrs Sheppards', value: 'Mrs Sheppards' },
  { label: 'Mukta', value: 'Mukta' },
  { label: 'Mukta Mahee', value: 'Mukta Mahee' },
  { label: 'Mukta Malar', value: 'Mukta Malar' },
  { label: 'Mukta Vaani', value: 'Mukta Vaani' },
  { label: 'Muli', value: 'Muli' },
  { label: 'Mystery Quest', value: 'Mystery Quest' },
  { label: 'NTR', value: 'NTR' },
  { label: 'Nanum Brush Script', value: 'Nanum Brush Script' },
  { label: 'Nanum Gothic', value: 'Nanum Gothic' },
  { label: 'Nanum Gothic Coding', value: 'Nanum Gothic Coding' },
  { label: 'Nanum Myeongjo', value: 'Nanum Myeongjo' },
  { label: 'Nanum Pen Script', value: 'Nanum Pen Script' },
  { label: 'Neucha', value: 'Neucha' },
  { label: 'Neuton', value: 'Neuton' },
  { label: 'New Rocker', value: 'New Rocker' },
  { label: 'News Cycle', value: 'News Cycle' },
  { label: 'Niconne', value: 'Niconne' },
  { label: 'Niramit', value: 'Niramit' },
  { label: 'Nixie One', value: 'Nixie One' },
  { label: 'Nobile', value: 'Nobile' },
  { label: 'Nokora', value: 'Nokora' },
  { label: 'Norican', value: 'Norican' },
  { label: 'Nosifer', value: 'Nosifer' },
  { label: 'Notable', value: 'Notable' },
  { label: 'Nothing You Could Do', value: 'Nothing You Could Do' },
  { label: 'Noticia Text', value: 'Noticia Text' },
  { label: 'Noto Sans', value: 'Noto Sans' },
  { label: 'Noto Sans HK', value: 'Noto Sans HK' },
  { label: 'Noto Sans JP', value: 'Noto Sans JP' },
  { label: 'Noto Sans KR', value: 'Noto Sans KR' },
  { label: 'Noto Sans SC', value: 'Noto Sans SC' },
  { label: 'Noto Sans TC', value: 'Noto Sans TC' },
  { label: 'Noto Serif', value: 'Noto Serif' },
  { label: 'Noto Serif JP', value: 'Noto Serif JP' },
  { label: 'Noto Serif KR', value: 'Noto Serif KR' },
  { label: 'Noto Serif SC', value: 'Noto Serif SC' },
  { label: 'Noto Serif TC', value: 'Noto Serif TC' },
  { label: 'Nova Cut', value: 'Nova Cut' },
  { label: 'Nova Flat', value: 'Nova Flat' },
  { label: 'Nova Mono', value: 'Nova Mono' },
  { label: 'Nova Oval', value: 'Nova Oval' },
  { label: 'Nova Round', value: 'Nova Round' },
  { label: 'Nova Script', value: 'Nova Script' },
  { label: 'Nova Slim', value: 'Nova Slim' },
  { label: 'Nova Square', value: 'Nova Square' },
  { label: 'Numans', value: 'Numans' },
  { label: 'Nunito', value: 'Nunito' },
  { label: 'Nunito Sans', value: 'Nunito Sans' },
  { label: 'Odor Mean Chey', value: 'Odor Mean Chey' },
  { label: 'Offside', value: 'Offside' },
  { label: 'Old Standard TT', value: 'Old Standard TT' },
  { label: 'Oldenburg', value: 'Oldenburg' },
  { label: 'Oleo Script', value: 'Oleo Script' },
  { label: 'Oleo Script Swash Caps', value: 'Oleo Script Swash Caps' },
  { label: 'Open Sans', value: 'Open Sans' },
  { label: 'Open Sans Condensed', value: 'Open Sans Condensed' },
  { label: 'Oranienbaum', value: 'Oranienbaum' },
  { label: 'Orbitron', value: 'Orbitron' },
  { label: 'Oregano', value: 'Oregano' },
  { label: 'Orienta', value: 'Orienta' },
  { label: 'Original Surfer', value: 'Original Surfer' },
  { label: 'Oswald', value: 'Oswald' },
  { label: 'Over the Rainbow', value: 'Over the Rainbow' },
  { label: 'Overlock', value: 'Overlock' },
  { label: 'Overlock SC', value: 'Overlock SC' },
  { label: 'Overpass', value: 'Overpass' },
  { label: 'Overpass Mono', value: 'Overpass Mono' },
  { label: 'Ovo', value: 'Ovo' },
  { label: 'Oxygen', value: 'Oxygen' },
  { label: 'Oxygen Mono', value: 'Oxygen Mono' },
  { label: 'PT Mono', value: 'PT Mono' },
  { label: 'PT Sans', value: 'PT Sans' },
  { label: 'PT Sans Caption', value: 'PT Sans Caption' },
  { label: 'PT Sans Narrow', value: 'PT Sans Narrow' },
  { label: 'PT Serif', value: 'PT Serif' },
  { label: 'PT Serif Caption', value: 'PT Serif Caption' },
  { label: 'Pacifico', value: 'Pacifico' },
  { label: 'Padauk', value: 'Padauk' },
  { label: 'Palanquin', value: 'Palanquin' },
  { label: 'Palanquin Dark', value: 'Palanquin Dark' },
  { label: 'Pangolin', value: 'Pangolin' },
  { label: 'Paprika', value: 'Paprika' },
  { label: 'Parisienne', value: 'Parisienne' },
  { label: 'Passero One', value: 'Passero One' },
  { label: 'Passion One', value: 'Passion One' },
  { label: 'Pathway Gothic One', value: 'Pathway Gothic One' },
  { label: 'Patrick Hand', value: 'Patrick Hand' },
  { label: 'Patrick Hand SC', value: 'Patrick Hand SC' },
  { label: 'Pattaya', value: 'Pattaya' },
  { label: 'Patua One', value: 'Patua One' },
  { label: 'Pavanam', value: 'Pavanam' },
  { label: 'Paytone One', value: 'Paytone One' },
  { label: 'Peddana', value: 'Peddana' },
  { label: 'Peralta', value: 'Peralta' },
  { label: 'Permanent Marker', value: 'Permanent Marker' },
  { label: 'Petit Formal Script', value: 'Petit Formal Script' },
  { label: 'Petrona', value: 'Petrona' },
  { label: 'Philosopher', value: 'Philosopher' },
  { label: 'Piedra', value: 'Piedra' },
  { label: 'Pinyon Script', value: 'Pinyon Script' },
  { label: 'Pirata One', value: 'Pirata One' },
  { label: 'Plaster', value: 'Plaster' },
  { label: 'Play', value: 'Play' },
  { label: 'Playball', value: 'Playball' },
  { label: 'Playfair Display', value: 'Playfair Display' },
  { label: 'Playfair Display SC', value: 'Playfair Display SC' },
  { label: 'Podkova', value: 'Podkova' },
  { label: 'Poiret One', value: 'Poiret One' },
  { label: 'Poller One', value: 'Poller One' },
  { label: 'Poly', value: 'Poly' },
  { label: 'Pompiere', value: 'Pompiere' },
  { label: 'Pontano Sans', value: 'Pontano Sans' },
  { label: 'Poor Story', value: 'Poor Story' },
  { label: 'Poppins', value: 'Poppins' },
  { label: 'Port Lligat Sans', value: 'Port Lligat Sans' },
  { label: 'Port Lligat Slab', value: 'Port Lligat Slab' },
  { label: 'Pragati Narrow', value: 'Pragati Narrow' },
  { label: 'Prata', value: 'Prata' },
  { label: 'Preahvihear', value: 'Preahvihear' },
  { label: 'Press Start 2P', value: 'Press Start 2P' },
  { label: 'Pridi', value: 'Pridi' },
  { label: 'Princess Sofia', value: 'Princess Sofia' },
  { label: 'Prociono', value: 'Prociono' },
  { label: 'Prompt', value: 'Prompt' },
  { label: 'Prosto One', value: 'Prosto One' },
  { label: 'Proza Libre', value: 'Proza Libre' },
  { label: 'Puritan', value: 'Puritan' },
  { label: 'Purple Purse', value: 'Purple Purse' },
  { label: 'Quando', value: 'Quando' },
  { label: 'Quantico', value: 'Quantico' },
  { label: 'Quattrocento', value: 'Quattrocento' },
  { label: 'Quattrocento Sans', value: 'Quattrocento Sans' },
  { label: 'Questrial', value: 'Questrial' },
  { label: 'Quicksand', value: 'Quicksand' },
  { label: 'Quintessential', value: 'Quintessential' },
  { label: 'Qwigley', value: 'Qwigley' },
  { label: 'Racing Sans One', value: 'Racing Sans One' },
  { label: 'Radley', value: 'Radley' },
  { label: 'Rajdhani', value: 'Rajdhani' },
  { label: 'Rakkas', value: 'Rakkas' },
  { label: 'Raleway', value: 'Raleway' },
  { label: 'Raleway Dots', value: 'Raleway Dots' },
  { label: 'Ramabhadra', value: 'Ramabhadra' },
  { label: 'Ramaraja', value: 'Ramaraja' },
  { label: 'Rambla', value: 'Rambla' },
  { label: 'Rammetto One', value: 'Rammetto One' },
  { label: 'Ranchers', value: 'Ranchers' },
  { label: 'Rancho', value: 'Rancho' },
  { label: 'Ranga', value: 'Ranga' },
  { label: 'Rasa', value: 'Rasa' },
  { label: 'Rationale', value: 'Rationale' },
  { label: 'Ravi Prakash', value: 'Ravi Prakash' },
  { label: 'Red Hat Display', value: 'Red Hat Display' },
  { label: 'Red Hat Text', value: 'Red Hat Text' },
  { label: 'Redressed', value: 'Redressed' },
  { label: 'Reem Kufi', value: 'Reem Kufi' },
  { label: 'Reenie Beanie', value: 'Reenie Beanie' },
  { label: 'Revalia', value: 'Revalia' },
  { label: 'Rhodium Libre', value: 'Rhodium Libre' },
  { label: 'Ribeye', value: 'Ribeye' },
  { label: 'Ribeye Marrow', value: 'Ribeye Marrow' },
  { label: 'Righteous', value: 'Righteous' },
  { label: 'Risque', value: 'Risque' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Roboto Condensed', value: 'Roboto Condensed' },
  { label: 'Roboto Mono', value: 'Roboto Mono' },
  { label: 'Roboto Slab', value: 'Roboto Slab' },
  { label: 'Rochester', value: 'Rochester' },
  { label: 'Rock Salt', value: 'Rock Salt' },
  { label: 'Rokkitt', value: 'Rokkitt' },
  { label: 'Romanesco', value: 'Romanesco' },
  { label: 'Ropa Sans', value: 'Ropa Sans' },
  { label: 'Rosario', value: 'Rosario' },
  { label: 'Rosarivo', value: 'Rosarivo' },
  { label: 'Rouge Script', value: 'Rouge Script' },
  { label: 'Rozha One', value: 'Rozha One' },
  { label: 'Rubik', value: 'Rubik' },
  { label: 'Rubik Mono One', value: 'Rubik Mono One' },
  { label: 'Ruda', value: 'Ruda' },
  { label: 'Rufina', value: 'Rufina' },
  { label: 'Ruge Boogie', value: 'Ruge Boogie' },
  { label: 'Ruluko', value: 'Ruluko' },
  { label: 'Rum Raisin', value: 'Rum Raisin' },
  { label: 'Ruslan Display', value: 'Ruslan Display' },
  { label: 'Russo One', value: 'Russo One' },
  { label: 'Ruthie', value: 'Ruthie' },
  { label: 'Rye', value: 'Rye' },
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Sahitya', value: 'Sahitya' },
  { label: 'Sail', value: 'Sail' },
  { label: 'Saira', value: 'Saira' },
  { label: 'Saira Condensed', value: 'Saira Condensed' },
  { label: 'Saira Extra Condensed', value: 'Saira Extra Condensed' },
  { label: 'Saira Semi Condensed', value: 'Saira Semi Condensed' },
  { label: 'Saira Stencil One', value: 'Saira Stencil One' },
  { label: 'Salsa', value: 'Salsa' },
  { label: 'Sanchez', value: 'Sanchez' },
  { label: 'Sancreek', value: 'Sancreek' },
  { label: 'Sansita', value: 'Sansita' },
  { label: 'Sarabun', value: 'Sarabun' },
  { label: 'Sarala', value: 'Sarala' },
  { label: 'Sarina', value: 'Sarina' },
  { label: 'Sarpanch', value: 'Sarpanch' },
  { label: 'Satisfy', value: 'Satisfy' },
  { label: 'Sawarabi Gothic', value: 'Sawarabi Gothic' },
  { label: 'Sawarabi Mincho', value: 'Sawarabi Mincho' },
  { label: 'Scada', value: 'Scada' },
  { label: 'Scheherazade', value: 'Scheherazade' },
  { label: 'Schoolbell', value: 'Schoolbell' },
  { label: 'Scope One', value: 'Scope One' },
  { label: 'Seaweed Script', value: 'Seaweed Script' },
  { label: 'Secular One', value: 'Secular One' },
  { label: 'Sedgwick Ave', value: 'Sedgwick Ave' },
  { label: 'Sedgwick Ave Display', value: 'Sedgwick Ave Display' },
  { label: 'Sevillana', value: 'Sevillana' },
  { label: 'Seymour One', value: 'Seymour One' },
  { label: 'Shadows Into Light', value: 'Shadows Into Light' },
  { label: 'Shadows Into Light Two', value: 'Shadows Into Light Two' },
  { label: 'Shanti', value: 'Shanti' },
  { label: 'Share', value: 'Share' },
  { label: 'Share Tech', value: 'Share Tech' },
  { label: 'Share Tech Mono', value: 'Share Tech Mono' },
  { label: 'Shojumaru', value: 'Shojumaru' },
  { label: 'Short Stack', value: 'Short Stack' },
  { label: 'Shrikhand', value: 'Shrikhand' },
  { label: 'Siemreap', value: 'Siemreap' },
  { label: 'Sigmar One', value: 'Sigmar One' },
  { label: 'Signika', value: 'Signika' },
  { label: 'Signika Negative', value: 'Signika Negative' },
  { label: 'Simonetta', value: 'Simonetta' },
  { label: 'Single Day', value: 'Single Day' },
  { label: 'Sintony', value: 'Sintony' },
  { label: 'Sirin Stencil', value: 'Sirin Stencil' },
  { label: 'Six Caps', value: 'Six Caps' },
  { label: 'Skranji', value: 'Skranji' },
  { label: 'Slabo 13px', value: 'Slabo 13px' },
  { label: 'Slabo 27px', value: 'Slabo 27px' },
  { label: 'Slackey', value: 'Slackey' },
  { label: 'Smokum', value: 'Smokum' },
  { label: 'Smythe', value: 'Smythe' },
  { label: 'Sniglet', value: 'Sniglet' },
  { label: 'Snippet', value: 'Snippet' },
  { label: 'Snowburst One', value: 'Snowburst One' },
  { label: 'Sofadi One', value: 'Sofadi One' },
  { label: 'Sofia', value: 'Sofia' },
  { label: 'Song Myung', value: 'Song Myung' },
  { label: 'Sonsie One', value: 'Sonsie One' },
  { label: 'Sorts Mill Goudy', value: 'Sorts Mill Goudy' },
  { label: 'Source Code Pro', value: 'Source Code Pro' },
  { label: 'Source Sans Pro', value: 'Source Sans Pro' },
  { label: 'Source Serif Pro', value: 'Source Serif Pro' },
  { label: 'Space Mono', value: 'Space Mono' },
  { label: 'Spartan', value: 'Spartan' },
  { label: 'Special Elite', value: 'Special Elite' },
  { label: 'Spectral SC', value: 'Spectral SC' },
  { label: 'Spectral', value: 'Spectral' },
  { label: 'Spicy Rice', value: 'Spicy Rice' },
  { label: 'Spinnaker', value: 'Spinnaker' },
  { label: 'Spirax', value: 'Spirax' },
  { label: 'Squada One', value: 'Squada One' },
  { label: 'Sree Krushnadevaraya', value: 'Sree Krushnadevaraya' },
  { label: 'Sriracha', value: 'Sriracha' },
  { label: 'Srisakdi', value: 'Srisakdi' },
  { label: 'Staatliches', value: 'Staatliches' },
  { label: 'Stalemate', value: 'Stalemate' },
  { label: 'Stalinist One', value: 'Stalinist One' },
  { label: 'Stardos Stencil', value: 'Stardos Stencil' },
  { label: 'Stint Ultra Condensed', value: 'Stint Ultra Condensed' },
  { label: 'Stint Ultra Expanded', value: 'Stint Ultra Expanded' },
  { label: 'Stoke', value: 'Stoke' },
  { label: 'Strait', value: 'Strait' },
  { label: 'Stylish', value: 'Stylish' },
  { label: 'Sue Ellen Francisco', value: 'Sue Ellen Francisco' },
  { label: 'Suez One', value: 'Suez One' },
  { label: 'Sumana', value: 'Sumana' },
  { label: 'Sunflower', value: 'Sunflower' },
  { label: 'Sunshiney', value: 'Sunshiney' },
  { label: 'Supermercado One', value: 'Supermercado One' },
  { label: 'Sura', value: 'Sura' },
  { label: 'Suranna', value: 'Suranna' },
  { label: 'Suravaram', value: 'Suravaram' },
  { label: 'Suwannaphum', value: 'Suwannaphum' },
  { label: 'Swanky and Moo Moo', value: 'Swanky and Moo Moo' },
  { label: 'Syncopate', value: 'Syncopate' },
  { label: 'Tajawal', value: 'Tajawal' },
  { label: 'Tangerine', value: 'Tangerine' },
  { label: 'Taprom', value: 'Taprom' },
  { label: 'Tauri', value: 'Tauri' },
  { label: 'Taviraj', value: 'Taviraj' },
  { label: 'Teko', value: 'Teko' },
  { label: 'Telex', value: 'Telex' },
  { label: 'Tenali Ramakrishna', value: 'Tenali Ramakrishna' },
  { label: 'Tenor Sans', value: 'Tenor Sans' },
  { label: 'Text Me One', value: 'Text Me One' },
  { label: 'Thasadith', value: 'Thasadith' },
  { label: 'The Girl Next Door', value: 'The Girl Next Door' },
  { label: 'Tienne', value: 'Tienne' },
  { label: 'Tillana', value: 'Tillana' },
  { label: 'Timmana', value: 'Timmana' },
  { label: 'Tinos', value: 'Tinos' },
  { label: 'Titan One', value: 'Titan One' },
  { label: 'Titillium Web', value: 'Titillium Web' },
  { label: 'Trade Winds', value: 'Trade Winds' },
  { label: 'Trirong', value: 'Trirong' },
  { label: 'Trocchi', value: 'Trocchi' },
  { label: 'Trochut', value: 'Trochut' },
  { label: 'Trykker', value: 'Trykker' },
  { label: 'Tulpen One', value: 'Tulpen One' },
  { label: 'Ubuntu', value: 'Ubuntu' },
  { label: 'Ubuntu Condensed', value: 'Ubuntu Condensed' },
  { label: 'Ubuntu Mono', value: 'Ubuntu Mono' },
  { label: 'Ultra', value: 'Ultra' },
  { label: 'Uncial Antiqua', value: 'Uncial Antiqua' },
  { label: 'Underdog', value: 'Underdog' },
  { label: 'Unica One', value: 'Unica One' },
  { label: 'UnifrakturCook', value: 'UnifrakturCook' },
  { label: 'UnifrakturMaguntia', value: 'UnifrakturMaguntia' },
  { label: 'Unkempt', value: 'Unkempt' },
  { label: 'Unlock', value: 'Unlock' },
  { label: 'Unna', value: 'Unna' },
  { label: 'VT323', value: 'VT323' },
  { label: 'Vampiro One', value: 'Vampiro One' },
  { label: 'Varela', value: 'Varela' },
  { label: 'Varela Round', value: 'Varela Round' },
  { label: 'Vast Shadow', value: 'Vast Shadow' },
  { label: 'Vesper Libre', value: 'Vesper Libre' },
  { label: 'Vibur', value: 'Vibur' },
  { label: 'Vidaloka', value: 'Vidaloka' },
  { label: 'Viga', value: 'Viga' },
  { label: 'Voces', value: 'Voces' },
  { label: 'Volkhov', value: 'Volkhov' },
  { label: 'Vollkorn', value: 'Vollkorn' },
  { label: 'Vollkorn SC', value: 'Vollkorn SC' },
  { label: 'Voltaire', value: 'Voltaire' },
  { label: 'Waiting for the Sunrise', value: 'Waiting for the Sunrise' },
  { label: 'Wallpoet', value: 'Wallpoet' },
  { label: 'Walter Turncoat', value: 'Walter Turncoat' },
  { label: 'Warnes', value: 'Warnes' },
  { label: 'Wellfleet', value: 'Wellfleet' },
  { label: 'Wendy One', value: 'Wendy One' },
  { label: 'Wire One', value: 'Wire One' },
  { label: 'Work Sans', value: 'Work Sans' },
  { label: 'Yanone Kaffeesatz', value: 'Yanone Kaffeesatz' },
  { label: 'Yantramanav', value: 'Yantramanav' },
  { label: 'Yatra One', value: 'Yatra One' },
  { label: 'Yellowtail', value: 'Yellowtail' },
  { label: 'Yeon Sung', value: 'Yeon Sung' },
  { label: 'Yeseva One', value: 'Yeseva One' },
  { label: 'Yesteryear', value: 'Yesteryear' },
  { label: 'Yrsa', value: 'Yrsa' },
  { label: 'ZCOOL KuaiLe', value: 'ZCOOL KuaiLe' },
  { label: 'ZCOOL QingKe HuangYou', value: 'ZCOOL QingKe HuangYou' },
  { label: 'ZCOOL XiaoWei', value: 'ZCOOL XiaoWei' },
  { label: 'Zeyada', value: 'Zeyada' },
  { label: 'Zhi Mang Xing', value: 'Zhi Mang Xing' },
  { label: 'Zilla Slab', value: 'Zilla Slab' },
  { label: 'Zilla Slab Highlight', value: 'Zilla Slab Highlight' },
];

export default googleFonts;

import roles from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';

const Containers = ({ isDetailed }) => {
  const { t } = useTranslation();

  return (
    <BaseBrandReport
      archiveReportNames={isDetailed ? ['CONTAINERS_DETAILED'] : ['CONTAINERS']}
      title={isDetailed ? t('reports.contDet') : t('reports.cont')}
      excelUrl="/reports/containers"
      pdfUrl="/reports/containers"
      pdfAvailable={true}
      excelAvailable={true}
      role={
        isDetailed
          ? roles.ROLE_SHOW_REPORT_CONTAINERS_DETAIL
          : roles.ROLE_SHOW_REPORT_CONTAINERS
      }
      fileName={getReportFileName(t('reports.cont'))}
      dateType="range"
      useZoneCategories={true}
      additionalParams={{ isDetailed }}
    />
  );
};

export default Containers;

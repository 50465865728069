import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { GetApp } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Dialog from '@material-ui/core/Dialog';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogContent from '@material-ui/core/DialogContent';

import ModalButton from 'components/CustomButtons/Button';
import { DialogLoader } from 'components/DialogLoader';
import GridContainer from 'components/Grid/GridContainer';

const FoodCostGridModal = ({
  isOpened = false,
  onClose = () => {},
  exportFoodCost = () => {},
  fetchFoodCosts = () => {},
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [foodCosts, setFoodCosts] = useState([]);

  const arrayDiffCost = [];

  useEffect(() => {
    if (isOpened) {
      updateFoodCosts();
    }
  }, [isOpened]);

  const updateFoodCosts = async () => {
    setIsLoading(true);
    const newFoodCosts = await fetchFoodCosts();
    setFoodCosts(newFoodCosts);
    setIsLoading(false);
  };

  return (
    <Dialog maxWidth={'lg'} open={isOpened} onClose={onClose}>
      {isLoading ? (
        <DialogLoader
          loaderState={isLoading}
          text={t('common.shared.isLoading')}
        />
      ) : (
        <>
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={onClose}
            >
              &times;
            </ModalButton>
            <ModalButton
              style={{
                position: 'absolute',
                top: '10px',
                right: '50px',
                cursor: 'pointer',
                background: 'rgb(56, 125, 31)',
                color: '#fff',
              }}
              color={'transparent'}
              round
              onClick={exportFoodCost}
            >
              <GetApp /> {t('reportArchive.download')}
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('menuPlanner.action.warning.foodCost.fields.diet')}
                    </TableCell>
                    <TableCell>
                      {t('menuPlanner.action.warning.foodCost.fields.variant')}
                    </TableCell>
                    <TableCell>
                      {t(
                        'menuPlanner.action.warning.foodCost.fields.calorific'
                      )}
                    </TableCell>
                    <TableCell width={280} style={{ textAlign: 'center' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          {t(
                            'menuPlanner.action.warning.foodCost.fields.foodCost'
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4}>
                          {t(
                            'menuPlanner.action.warning.foodCost.fields.realFoodCost'
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {t(
                            'menuPlanner.action.warning.foodCost.fields.expectedFoodCost'
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {t(
                            'menuPlanner.action.warning.foodCost.fields.diffFoodCost'
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(foodCosts).map((el, index) => {
                    arrayDiffCost.push(el.diffCost);
                    return (
                      <TableRow key={index}>
                        <TableCell variant={'head'}>{el.diet}</TableCell>
                        <TableCell variant={'head'}>{el.variant}</TableCell>
                        <TableCell>{el.calorific}</TableCell>
                        <TableCell>
                          <Grid container style={{ textAlign: 'center' }}>
                            <Grid item xs={4}>
                              {el.foodCost?.toFixed(2)}
                            </Grid>
                            <Grid item xs={4}>
                              {el.expectedFoodCost}
                            </Grid>
                            <Grid item xs={4} style={{ fontWeight: 600 }}>
                              {Math.round(
                                (el.foodCost - el.expectedFoodCost) * 100
                              ) /
                                100 >
                                0 && (
                                <span style={{ color: '#f44336' }}>
                                  +
                                  {(el.foodCost - el.expectedFoodCost).toFixed(
                                    2
                                  )}
                                </span>
                              )}
                              {Math.round(
                                (el.foodCost - el.expectedFoodCost) * 100
                              ) /
                                100 <=
                                0 && (
                                <span style={{ color: '#387d1f' }}>
                                  {(el.foodCost - el.expectedFoodCost).toFixed(
                                    2
                                  )}
                                </span>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <GridContainer>
                        <Grid xs={4} style={{ textAlign: 'center' }}>
                          Podsumowanie:
                        </Grid>
                        <Grid xs={4}></Grid>
                        <Grid xs={4} style={{ textAlign: 'center' }}>
                          {arrayDiffCost
                            .reduce((sum, cost) => sum + cost, 0)
                            .toFixed(2)}
                        </Grid>
                      </GridContainer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default FoodCostGridModal;

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { combineStyles } from 'helpers/helpers';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { grayColor } from 'assets/jss/material-dashboard-pro-react';

import makeStyles from '@material-ui/styles/makeStyles';
import { FormControlLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Datetime from 'react-datetime';
import { Info } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import RegularCheckbox from 'components/Checkbox/RegularCheckbox';
import { useEffect } from 'react';
import { MAX_DAYS_COUNT } from '../consts';

const useStyles = makeStyles(
  combineStyles(extendedFormsStyle, buttonsStyle, {
    disabled: {
      backgroundColor: grayColor[19],
    },
    formControlLabel: { marginTop: '15px' },
    formLabel: { marginBottom: '5px' },
    formLabelWrapper: {
      display: 'flex',
      marginTop: '8px',
    },
    gridContainer: { marginTop: '15px' },
    tooltipIcon: { marginLeft: '5px', color: grayColor[20] },
  })
);

const MenuCopyingOtherDate = ({
  setIsMenuOnOtherDate,
  isMenuOnOtherDate,
  dateRangeTo,
  dateRangeFrom,
  otherDateRangeFrom,
  setOtherDateRangeFrom,
  otherDateRangeTo,
  setOtherDateRangeTo,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const handleToggleCopyingMenuOnOtherDate = () => {
    setIsMenuOnOtherDate(!isMenuOnOtherDate);
    setOtherDateRangeFrom(null);
    setOtherDateRangeTo(null);
  };

  const handleSetOtherDate = (selectedDate, dateRangeTo, dateRangeFrom) => {
    if (selectedDate && dateRangeTo && dateRangeFrom) {
      const daysToAdd = moment(dateRangeTo)
        .startOf('day')
        .diff(moment(dateRangeFrom).startOf('day'), 'days');
      setOtherDateRangeFrom(moment(selectedDate).format('YYYY-MM-DD'));
      setOtherDateRangeTo(
        moment(selectedDate, 'YYYY-MM-DD')
          .add(daysToAdd, 'days')
          .format('YYYY-MM-DD')
      );
    }
  };

  useEffect(() => {
    handleSetOtherDate(otherDateRangeFrom, dateRangeTo, dateRangeFrom);

    const daysDifference = moment(dateRangeFrom)
      .startOf('day')
      .diff(moment(otherDateRangeFrom).startOf('day'), 'days');

    if (daysDifference > 0) {
      setOtherDateRangeFrom(dateRangeFrom);
      setOtherDateRangeTo(dateRangeTo);
    }
  }, [dateRangeFrom, dateRangeTo]);

  return (
    <>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <RegularCheckbox
            name="field"
            onClick={handleToggleCopyingMenuOnOtherDate}
            checked={isMenuOnOtherDate}
          />
        }
        label={t('form.copyMenuPlanning.otherDays.checkbox')}
      />

      {isMenuOnOtherDate && (
        <div>
          <p>
            {t('form.copyMenuPlanning.otherDays.sectionTitle', {
              count: MAX_DAYS_COUNT,
            })}
          </p>
          <GridContainer className={classes.gridContainer}>
            <GridItem>
              <FormLabel
                className={classNames(
                  classes.labelHorizontal,
                  classes.formLabel
                )}
              >
                {t('form.copyMenuPlanning.otherDays.dateFrom')}*
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={current => {
                    return current.isSameOrAfter(
                      moment(dateRangeFrom).startOf('day')
                    );
                  }}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={moment(otherDateRangeFrom)}
                  onChange={e => {
                    handleSetOtherDate(e, dateRangeTo, dateRangeFrom);
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <div>
              <Tooltip
                title={<h4>{t('form.copyMenuPlanning.otherDays.tooltip')}</h4>}
                placement="right"
              >
                <div className={classes.formLabelWrapper}>
                  <FormLabel
                    className={classNames(
                      classes.labelHorizontal,
                      classes.formLabel
                    )}
                  >
                    {t('form.copyMenuPlanning.otherDays.dateTo')}
                  </FormLabel>
                  <Info className={classes.tooltipIcon} fontSize="small" />
                </div>
              </Tooltip>

              <FormControl fullWidth>
                <Datetime
                  open={false}
                  className={classes.disabled}
                  disabled={true}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={moment(otherDateRangeTo)}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </div>
          </GridContainer>
        </div>
      )}
    </>
  );
};

export default MenuCopyingOtherDate;

import React from 'react';
import { TableCell } from '@material-ui/core';
import { connect } from 'react-redux';
import CustomInput from '../../../../components/CustomInput/CustomInput';

class PriceSumCell extends React.Component {
  render() {
    const { dishSizes, size } = this.props;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    return (
      <TableCell>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          maxLength={160}
          inputProps={{
            placeholder: '',
            value: dishSizeBySize.costInShop,
            onChange: e => this.props.handlePriceChange(e, dishSizeBySize),
          }}
        />
      </TableCell>
    );
  }
}

export default connect(state => ({
  currency: state.Brands.brand.multinational.currency,
}))(PriceSumCell);

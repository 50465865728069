import React from 'react';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';

import Check from '@material-ui/icons/Check';

import CustomInput from 'components/CustomInput/CustomInput.jsx';
import FormLabel from '@material-ui/core/FormLabel';

import { withTranslation } from 'react-i18next';

const CheckSizes = ({ classes, sizes, t, handleToggle, handleCalories }) => {
  if (!sizes) {
    return null;
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <FormLabel className={classes.labelHorizontal}>
          {t('form.sizesAndCalories')}
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
          }}
        >
          <div
            style={{
              display: 'inline-block',
              width: '180px',
            }}
          />
          <div
            style={{
              display: 'inline-block',
            }}
          >
            {t('form.caloriesValue')}
          </div>
        </div>
        {sizes.map((size, key) => {
          return (
            <div key={key}>
              <FormControlLabel
                style={{ minWidth: '180px' }}
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => handleToggle(key, size.disabled)}
                    checked={!!size.checked}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={`${t('form.size')} ${size.name}`}
              />
              <CustomInput
                formControlProps={{
                  id: size.id,
                }}
                inputProps={{
                  type: 'number',
                  onChange: event => handleCalories(key, event),
                  disabled: size.checked !== true,
                  value: size.checked ? size.calories : '',
                }}
              />
            </div>
          );
        })}
      </GridItem>
    </GridContainer>
  );
};

export default withTranslation()(CheckSizes);

import React from 'react';
import roles from 'helpers/roles';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class IncomingSubscriptions extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportIncomingSubscriptions');

    return (
      <BrandDateSubbrandsReport
        title={title}
        url="reports/incoming-subscription"
        mimeType="application/vnd.ms-excel"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION}
        useDateRange={true}
        useSubBrands={false}
        showMultiplier={false}
        archiveReportNames={['INCOMING_SUBSCRIPTION']}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(IncomingSubscriptions);

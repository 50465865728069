import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import React from 'react';
import { modulesNames } from '../../consts';
import CardWrapper from 'components/Card/CardWrapper';

const OrderLengthPeriodSettings = ({
  t,
  classes,
  modules,
  handleModuleUpdate,
}) => {
  return (
    <CardWrapper
      title={t(
        'brandCfg.maxDietLength',
        '$$Okres na jaki można złożyć zamówienie'
      )}
    >
      <CardBody>
        <Divider />
        <GridContainer>
          <GridItem md={2}>
            <FormLabel className={classes.labelHorizontal}>
              {t(
                'brandCfg.maxiumNumbersOfDaysAhead',
                '$$Maksymalna ilość dni w przód'
              )}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'maximumDaysAllowedToOrderInFuture'}
              value={
                modules?.[modulesNames.ConfigClientPanel]
                  ?.maximumDaysAllowedToOrderInFuture
              }
              onChange={e => {
                handleModuleUpdate({
                  ...modules?.[modulesNames.ConfigClientPanel],
                  maximumDaysAllowedToOrderInFuture:
                    e.target.value === '' ? null : e.target.value,
                });
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default OrderLengthPeriodSettings;

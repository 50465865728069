import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { post } from 'helpers/apiHelpers';
import { Check } from '@material-ui/icons';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { withToast } from 'material-ui-toast-redux';
import { compose } from 'redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

const Caching = ({ classes, handleModuleUpdate, module, openToast }) => {
  const { t } = useTranslation();

  const successCleared = () => {
    openToast({
      messages: [t('cache.clear.success')],
      type: 'success',
      autoHideDuration: TOAST_DURATIONS.SM,
    });
  };
  const errorOnClear = () => {
    openToast({
      messages: [t('cache.clear.error')],
      type: 'error',
      autoHideDuration: TOAST_DURATIONS.SM,
    });
  };

  const clearCache = () => {
    post('/brand/clear-cache')
      .then(({ status }) => {
        status ? successCleared() : errorOnClear();
      })
      .catch(() => {
        errorOnClear();
      });
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'Caching',
        })}
      </div>
    );
  }

  return (
    <GridContainer>
      {alert}
      <GridItem sm={12}>
        <GridContainer>
          <GridItem sm={12} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.enabled}
                  onChange={e =>
                    handleModuleUpdate({ ...module, enabled: !module.enabled })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('$*common.enabled')}
            />
          </GridItem>
          <GridItem sm={12} md={3}>
            <Button
              style={{ margin: '5px' }}
              color={'success'}
              className={classes.marginLeft}
              onClick={clearCache}
              type={'button'}
            >
              {t('cache.clear')}
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem sm={12}>
        <GridContainer>
          <GridItem sm={12} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.baseInvalidationEnabled}
                  onChange={e =>
                    handleModuleUpdate({
                      ...module,
                      baseInvalidationEnabled: !module.baseInvalidationEnabled,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('cache.baseInvalidationEnabled')}
            />
          </GridItem>
          <GridItem sm={12} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.costsInvalidationEnabled}
                  onChange={e =>
                    handleModuleUpdate({
                      ...module,
                      costsInvalidationEnabled:
                        !module.costsInvalidationEnabled,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('cache.costsInvalidationEnabled')}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

const enhance = compose(withToast);

export default enhance(Caching);

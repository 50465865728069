const defaultState = {
  vat: 0,
  cost: 0,
  name: '',
  weight: 0,
  volume: 0,
  position: null,
};

export default defaultState;

import React from 'react';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { AttachMoney } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import OrdersContainer from '../../Orders/Orders.container';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';

class SubscriptionOrders extends React.Component {
  state = {
    subscriptionOrdersModal: false,
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  closeModal = () =>
    this.setState(prevState => ({
      ...prevState,
      subscriptionOrdersModal: false,
    }));

  openModal = () =>
    this.setState(prevState => ({
      ...prevState,
      subscriptionOrdersModal: true,
    }));

  customActions = row => {
    const actions = [];

    if (row.invoicePDF !== null) {
      actions.push({
        action: props => window.open(props.row.invoicePDF, '_blank'),
        icon: <VerticalAlignBottom />,
        disabled: row.invoicePDF === null,
        color: row.invoicePDF ? 'primary' : 'tumblr',
        simple: true,
        round: false,
      });
    }

    return actions;
  };

  render() {
    return (
      <>
        <Dialog
          fullScreen
          open={this.state.subscriptionOrdersModal}
          onClose={this.closeModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <OrdersContainer
              defaultQuery={{ subscription: this.props.row.id }}
              showAddButton={false}
              customActions={this.customActions}
              customActionsWidth={50}
            />
          </DialogContent>
        </Dialog>
        <Tooltip
          onClick={this.openModal}
          title={this.props.t('subscription.dialog.orders.tooltip.title')}
          placement={'top'}
        >
          <AttachMoney />
        </Tooltip>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(SubscriptionOrders))
);

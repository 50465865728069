import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import {
  Chart as ChartJS,
  Title,
  Legend,
  Tooltip,
  BarElement,
  LineElement,
  LinearScale,
  PointElement,
  BarController,
  CategoryScale,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { get } from 'helpers/apiHelpers';

import DateRangeFilter from 'components/Grid/Filter/DateRange';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title
);

const DietsPackedPerDayChart = ({ dietId }) => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState({ before: null, after: null });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        type: 'line',
        label: t(
          'views.diets.dietFlowStatistics.packingTime',
          '$$Czas pakowania'
        ),
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data: [],
        yAxisID: 'y1',
      },
      {
        type: 'bar',
        label: t(
          'views.diets.dietFlowStatistics.amountOfDiets',
          '$$Ilość diet'
        ),
        backgroundColor: 'rgb(75, 192, 192)',
        data: [],
        borderColor: 'white',
        borderWidth: 2,
        yAxisID: 'y',
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          label: (context, index) => {
            if (context?.dataset?.type === 'line') {
              return `${context.dataset.label}: ${context.formattedValue} min`;
            }

            return `${context.dataset.label}: ${context.formattedValue}`;
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          callback: (label, index, labels) => {
            return `${label} szt.`;
          },
        },
      },
      y1: {
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: (label, index, labels) => {
            return `${label} min`;
          },
        },
      },
    },
  };

  useEffect(() => {
    getDietsHistory();
  }, [dateRange]);

  const getDietsHistory = () => {
    get(`stats/pack-diet`, {
      dietId,
      dateFrom: dateRange?.after
        ? Moment(new Date(dateRange?.after)).format('YYYY-MM-DD')
        : null,
      dateTo: dateRange?.before
        ? Moment(new Date(dateRange?.before)).format('YYYY-MM-DD')
        : null,
    }).then(res => getData(res?.['hydra:member']));
  };

  const getData = data => {
    const newChartData = {
      labels: data.map(({ date }) => date),
      datasets: [
        {
          type: 'line',
          label: t(
            'views.diets.dietFlowStatistics.packingTime',
            '$$Czas pakowania'
          ),
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: data.map(({ seconds }) => (seconds / 60 ?? 0).toFixed(2)),
          yAxisID: 'y1',
        },
        {
          type: 'bar',
          label: t(
            'views.diets.dietFlowStatistics.amountOfDiets',
            '$$Ilość diet'
          ),
          backgroundColor: 'rgb(75, 192, 192)',
          data: data.map(({ amount }) => amount),
          borderColor: 'white',
          borderWidth: 2,
          yAxisID: 'y',
        },
      ],
    };

    setChartData(newChartData);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ marginBottom: '0', marginRight: '10px' }}>
          {t('flowStatistics.selectDateRange', '$$Wybierz zakres dat:')}
        </p>
        <DateRangeFilter onChange={setDateRange} value={dateRange} />
      </div>
      <Chart type="bar" options={options} data={chartData} />
    </>
  );
};

export default DietsPackedPerDayChart;

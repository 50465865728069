import React from 'react';
import { connect } from 'react-redux';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_MASS_MAIL,
  ROLE_EDIT_MASS_MAIL,
  ROLE_EXPORT_MASS_MAIL,
} from 'helpers/roles';
import Moment from 'moment';
import { withToast } from 'material-ui-toast-redux';
import { compose } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useState } from 'react';
import Button from 'components/CustomButtons/Button';
import Form from './Form';
import TOAST_DURATIONS from 'helpers/toastDurations';
const List = ({ history, brand, classes, openToast }) => {
  const canCreateNewEmail = true;
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [choicesRow, setChoicesRow] = useState({});
  const customActions = row => {
    return [
      {
        action: () => {
          setIsOpenModal(true);
          setChoicesRow(row);
        },
        icon: <VisibilityIcon />,
        color: 'info',
        simple: true,
        round: false,
      },
    ];
  };

  const newEmail = () => {
    if (canCreateNewEmail) {
      return history.push(`${window.location.pathname}/add`);
    } else {
      return openToast({
        messages: [
          t('email.setupEmailModule'),
          `${!brand.emailApiEnabled ? t('email.emailModuleOff') : ''}`,
          `${!brand.emailApiToken ? t('email.noToken') : ''}`,
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.MD,
      });
    }
  };

  return (
    <>
      <AdminTable title={t('email.massMail')}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isGranted(ROLE_CREATE_MASS_MAIL) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={newEmail}
              submitText={t('email.addEmail') + ' +'}
            />
          )}
        </div>
        <DataGrid
          actions={isGranted(ROLE_EDIT_MASS_MAIL)}
          customActions={customActions}
          remove={true}
          export={isGranted(ROLE_EXPORT_MASS_MAIL)}
          paginationTop={true}
          paginationBottom={false}
          url="mass-mails"
          reportName={'massmail'}
          columns={columnConfig(t)}
          minRows={20}
          role="MASS-SMS"
          canRemoveChecker={(canRemove, row) =>
            canRemove && row.status === 'PLANED'
          }
          manipulateQuery={(requestData, query) => {
            if (query.processAt) {
              query['processAt[after]'] = Moment(query.processAt).format(
                'YYYY-MM-DD 00:00:01'
              );
              query['processAt[before]'] = Moment(query.processAt).format(
                'YYYY-MM-DD 23:59:59'
              );
            }
            return query;
          }}
        />
      </AdminTable>
      <Dialog
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.modalHeader}>
          {t('$*common.massMails.modal.title', 'Szczegóły listy')}
        </DialogTitle>
        <DialogContent>
          <>
            <Form
              t={t}
              history={history}
              openToast={openToast}
              choicesRow={choicesRow}
            />
            <DataGrid
              paginationTop={true}
              paginationBottom={false}
              url="emails"
              columns={[
                {
                  title: 'ID',
                  accessor: 'id',
                  name: 'id',
                  filterable: false,
                  width: 150,
                },
                {
                  title: t('common.massMails.modal.customer', 'Klient'),
                  accessor: row => {
                    return `${row.client.firstName} ${row.client.lastName}`;
                  },
                  name: 'fullName',
                  filterable: true,
                  sortable: false,
                  width: 250,
                },
                {
                  title: t('common.massMails.modal.mail', 'Adres email'),
                  accessor: 'data',
                  name: 'data',
                  filterable: false,
                  sortable: false,
                  width: 250,
                },
                {
                  title: t('common.massMails.modal.status', 'Status'),
                  accessor: row => {
                    if (row.status === 'DELIVERED')
                      return t(
                        'common.massMails.modal.status.success',
                        'Powodzenie'
                      );
                    if (row.status === 'FAILED')
                      return t(
                        'common.massMails.modal.status.failed',
                        'Niepowodzenie'
                      );
                  },
                  name: 'status',
                  filterable: true,
                  sortable: true,
                  Filter: ({ onChange, filter }) => {
                    return (
                      <select
                        style={{ height: '30px', width: '100%', padding: '0' }}
                        onChange={e => onChange(e.target.value)}
                        value={filter?.value || ''}
                      >
                        <option value={''}>
                          {t('common.massMails.modal.status.all', 'Wszystkie')}
                        </option>
                        <option value={'DELIVERED'}>
                          {t(
                            'common.massMails.modal.status.success',
                            'Powodzenie'
                          )}
                        </option>
                        <option value={'FAILED'}>
                          {t(
                            'common.massMails.modal.status.failed',
                            'Niepowodzenie'
                          )}
                        </option>
                      </select>
                    );
                  },
                },
              ]}
              minRows={20}
              role="MASS-SMS"
              manipulateQuery={(requestData, query) => {
                query['massMail.id'] = choicesRow.id;

                if (query['fullName']) {
                  const splicedName = query['fullName'].split(' ');
                  let _andX = [
                    [
                      {
                        'client.firstName': splicedName[0],
                        'client.lastName': splicedName[0],
                      },
                    ],
                  ];

                  if (splicedName.length > 1) {
                    _andX[0][0]['client.lastName'] = splicedName[1];
                  }

                  const operator = splicedName.length > 1 ? '_andX' : '_orX';

                  delete query['client.firstName'];
                  query[operator] = _andX;
                }

                return query;
              }}
            />
          </>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
          style={{
            display: 'flex',
            justifyItems: 'end',
            margin: '0 20px 20px 0',
          }}
        >
          <Button onClick={() => setIsOpenModal(false)}>
            {t('common.close', 'Zamknij')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    brand: state.Brands.brand,
  };
};

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withTranslation(),
  withStyles(buttonsStyle)
);

export default enhance(List);

import React from 'react';
import CardBody from '../../../components/Card/CardBody';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import CardWrapper from 'components/Card/CardWrapper';

const DietAssumptions = ({
  t,
  classes,
  state,
  handleInputChange,
  fillDefaultsMargins,
}) => {
  return (
    <CardWrapper title={t('diets.assumptions')}>
      <CardBody>
        <p>{t('diets.assumptions.description')}</p>
        <GridContainer>
          <GridItem xs={12} lg={6}>
            <GridContainer>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.protein') + ' %'}
                  classes={classes}
                  name="proteinAssumption"
                  value={state.proteinAssumption}
                  type="number"
                  handleChange={handleInputChange}
                  onBlur={() =>
                    state.proteinAssumption &&
                    fillDefaultsMargins('proteinMargin')
                  }
                  inputSize={12}
                />
              </GridItem>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.margin') + ' %'}
                  classes={classes}
                  name="proteinMargin"
                  value={state.proteinMargin}
                  type="number"
                  handleChange={handleInputChange}
                  inputSize={12}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.carbohydrates') + ' %'}
                  classes={classes}
                  name="carbohydratesAssumption"
                  value={state.carbohydratesAssumption}
                  type="number"
                  handleChange={handleInputChange}
                  onBlur={() =>
                    state.carbohydratesAssumption &&
                    fillDefaultsMargins('carbohydratesMargin')
                  }
                  inputSize={12}
                />
              </GridItem>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.margin') + ' %'}
                  classes={classes}
                  name="carbohydratesMargin"
                  value={state.carbohydratesMargin}
                  type="number"
                  handleChange={handleInputChange}
                  inputSize={12}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.fats') + ' %'}
                  classes={classes}
                  name="fatsAssumption"
                  value={state.fatsAssumption}
                  type="number"
                  handleChange={handleInputChange}
                  onBlur={() =>
                    state.fatsAssumption && fillDefaultsMargins('fatsMargin')
                  }
                  inputSize={12}
                />
              </GridItem>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.margin') + ' %'}
                  classes={classes}
                  name="fatsMargin"
                  value={state.fatsMargin}
                  type="number"
                  handleChange={handleInputChange}
                  inputSize={12}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.roughage') + ' %'}
                  classes={classes}
                  name="roughageAssumption"
                  value={state.roughageAssumption}
                  type="number"
                  handleChange={handleInputChange}
                  onBlur={() => {
                    state.roughageAssumption &&
                      fillDefaultsMargins('roughageMargin');
                  }}
                  inputSize={12}
                />
              </GridItem>
              <GridItem xs={6} lg={6}>
                <FormTextInput
                  label={t('common.margin') + ' %'}
                  classes={classes}
                  name="roughageMargin"
                  value={state.roughageMargin}
                  type="number"
                  handleChange={handleInputChange}
                  inputSize={12}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(DietAssumptions);

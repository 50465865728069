import { useRef, useState } from 'react';

import useIntersectionObserver from 'hooks/common/useIntersectionObserver.js';

const ObservableSection = ({ id, preload = false, enabled, children }) => {
  const ref = useRef();
  const [isIntersected, setIsIntersected] = useState(preload);

  useIntersectionObserver({
    target: ref,
    onIntersect: () => setIsIntersected(true),
    enabled,
  });

  if (isIntersected) {
    return children;
  }

  return (
    <div style={{ minHeight: '100px', position: 'relative', margin: '20px 0' }}>
      <div id={id} style={{ position: 'absolute', top: '-40' }} />
      <div ref={ref}>{isIntersected && children}</div>
    </div>
  );
};

export default ObservableSection;

import types from 'helpers/constants';

const initialState = {
  units: [],
  loading: true,
};

function Units(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_UNITS:
      return {
        ...state,
        units: action.payload.units,
        loading: false,
      };
    case types.REMOVE_UNIT:
      return {
        ...state,
        units: state.units.filter(
          unit => unit.id !== action.payload.removedItemId
        ),
      };
    case types.LOADING_UNITS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default Units;

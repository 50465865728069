import getReportFileName from './getReportFileName';

const getReportFileNameWithMultiplier = ({ parameters }, title) => {
  const { multiplier, dateFrom, dateTo } = parameters[0];

  if (parameters.length === 1) {
    const result = [
      getReportFileName(title)({
        dateFrom,
        dateTo,
      }),
    ];

    if (multiplier) {
      result.unshift(multiplier + 'x');
    }

    return result.join('');
  }

  return getReportFileName(title)({});
};

export default getReportFileNameWithMultiplier;

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';

import Counter from 'components/Counter/Counter';
import RegularCheckbox from 'components/Checkbox/RegularCheckbox';

import { isDateInArray, isTheSameDays } from 'helpers/dateHelpers';

import { withToast } from 'material-ui-toast-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { calendarStyles } from 'views/Orders/styles';
import Calendar from './Calendar';
import TOAST_DURATIONS from 'helpers/toastDurations';

const CalendarSection = ({
  diet = {},
  classes,
  openToast,
  updateDiet = () => {},
  calendarSettings = {},
  setSelectedOptions,
  brandInfo,
} = {}) => {
  const { t } = useTranslation();
  const { dietLength, selectedDays } = diet ?? {};
  const {
    disabledDays = [],
    saturdaySettings = {},
    sundaySettings = {},
    maxDietLength = null,
    minDietLength = null,
  } = calendarSettings ?? {};

  const [sundayInclude, setSundayInclude] = useState(false);
  const [saturdayInclude, setSaturdayInclude] = useState(false);

  // Length & Calendar
  const [month, setMonth] = useState(moment().month());
  const [secondMonth, setSecondMonth] = useState(moment().month() + 1);

  useEffect(() => {
    if (!saturdayInclude) {
      updateDiet({
        ...diet,
        selectedDays: selectedDays?.filter(day => day.day() !== 6),
      });
    }
  }, [saturdayInclude]);

  useEffect(() => {
    if (!saturdayInclude) {
      updateDiet({
        ...diet,
        selectedDays: selectedDays?.filter(day => day.day() !== 0),
      });
    }
  }, [sundayInclude]);

  const selectDietDay = day => {
    const referenceOfDiet = { ...diet };
    referenceOfDiet.selectedAdditionalMealTypes.forEach(mealType => {
      mealType.date = mealType?.date?.filter(date => {
        return date.format('YYYY-MM-DD') !== day.format('YYYY-MM-DD');
      });
      mealType.dateWithAmount = mealType?.dateWithAmount?.filter(date => {
        return date.date.format('YYYY-MM-DD') !== day.format('YYYY-MM-DD');
      });
    });
    if (dietLength === 0) {
      return openToast({
        messages: [
          t('orders.firstSelectTime', '$$Najpierw wybierz czas trwania diety'),
        ],
        type: 'info',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (!saturdayInclude && day.day() === 6) {
      if (saturdaySettings.shouldBeDeliveredInBlock !== 0) {
        return openToast({
          messages: [
            t(
              'orders.selectSaturday',
              '$$Aby móc wybierać soboty, zaznacz "Uwzględnij soboty"'
            ),
          ],
          type: 'info',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    }

    if (!sundayInclude && day.day() === 0) {
      if (sundaySettings.shouldBeDeliveredInBlock !== 0) {
        return openToast({
          messages: [
            t(
              'orders.selectSunday',
              '$$Aby móc wybierać niedziele, zaznacz "Uwzględnij niedziele"'
            ),
          ],
          type: 'info',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }
    }

    if (
      disabledDays
        .map(d => new Date(d).getTime())
        .some(disabledDay => disabledDay === new Date(day).setHours(0, 0, 0, 0))
    ) {
      return openToast({
        messages: [t('orders.dayNotSupported')],
        type: 'warning',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    const today = new Date(moment()).setHours(0, 0, 0, 0);

    if (day.isBefore(today) && !isInSelectedDays(day)) {
      openToast({
        messages: [t('orders.selectedDayInPast', '$$Zaznaczono przeszłą datę')],
        type: 'warning',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    if (selectedDays?.length === 0) {
      let days = [];

      const startDate = new Date(new moment({ ...day }));
      const endDate = new Date(new moment({ ...day }).add(dietLength, 'd'));

      const disabledDaysMap = disabledDays.map(disabledDay =>
        moment(disabledDay).format('YYYYMMDD')
      );

      while (startDate < endDate) {
        if (startDate.getDay() === 6 && !saturdayInclude) {
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        } else if (startDate.getDay() === 0 && !sundayInclude) {
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        } else if (
          disabledDaysMap.some(d => d === moment(startDate).format('YYYYMMDD'))
        ) {
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        } else {
          days.push(new moment(startDate));
          startDate.setDate(startDate.getDate() + 1);
        }
      }

      return updateDiet({
        ...diet,
        selectedDays: days,
        selectedAdditionalMealTypes:
          referenceOfDiet.selectedAdditionalMealTypes,
      });
    }
    const isDayInSelectedDays = isDateInArray(day, selectedDays);

    const daysToChange = isDayInSelectedDays
      ? selectedDays.filter(el => !isTheSameDays(el, day))
      : selectedDays.concat(day);

    return updateDiet({
      ...diet,
      selectedDays: daysToChange,
      dietLength: daysToChange.length
        ? daysToChange.length
        : brandInfo.daysDefault,
    });
  };

  const isInSelectedDays = day => {
    let bool = false;

    selectedDays.forEach(dayInSelectedArray => {
      if (day.format('L') === dayInSelectedArray.format('L')) {
        bool = true;
      }
    });

    return bool;
  };

  const getDatesRange = (firstDay, range) => {
    let days = [];
    const startDate = new Date(new moment({ ...firstDay }));
    const endDate = new Date(new moment({ ...firstDay }).add(range, 'd'));

    const disabledDaysMap = disabledDays.map(disabledDay =>
      moment(disabledDay).format('YYYYMMDD')
    );

    while (startDate < endDate) {
      if (startDate.getDay() === 6 && !saturdayInclude) {
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      } else if (startDate.getDay() === 0 && !sundayInclude) {
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      } else if (
        disabledDaysMap.some(d => d === moment(startDate).format('YYYYMMDD'))
      ) {
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      } else {
        days.push(new moment(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }
    }

    return days;
  };

  const handleClickDecrement = () => {
    diet.selectedAdditionalMealTypes.map(el => {
      el.dateWithAmount.pop();
    });

    if (dietLength - 1 >= minDietLength) {
      if (selectedDays.length === dietLength) {
        let sortedDates = [...selectedDays];
        sortedDates.sort((dateA, dateB) => dateA - dateB);
        sortedDates.pop();

        return updateDiet({
          ...diet,
          dietLength: dietLength - 1,
          selectedDays: sortedDates,
        });
      }

      return updateDiet({ ...diet, dietLength: dietLength - 1 });
    }
  };
  const handleClickIncrement = () => {
    if (!isEmpty(selectedDays)) {
      let sortedDates = [...selectedDays];
      sortedDates.sort((dateA, dateB) => dateA - dateB);
      let popedDate = sortedDates.pop();
      let lastTwoDays = getDatesRange(popedDate, 2);

      return updateDiet({
        ...diet,
        dietLength: dietLength + 1,
        selectedDays: [...sortedDates, ...lastTwoDays],
      });
    }

    return updateDiet({ ...diet, dietLength: dietLength + 1 });
  };

  const handleDietLengthInputChange = ({ target }) => {
    const parsedValue = parseInt(target?.value);
    let sortedDates = [...selectedDays];
    if (selectedDays.length > 0) {
      sortedDates.sort((dateA, dateB) => dateA - dateB);
      let popedDate = sortedDates[0];
      let lastTwoDays = getDatesRange(popedDate, parsedValue);

      sortedDates = lastTwoDays;
    }
    if (isNaN(parsedValue)) {
      return updateDiet({ ...diet, dietLength: 0 });
    }
    if (
      typeof parsedValue === 'number' &&
      !isNaN(parsedValue) &&
      parsedValue <= maxDietLength &&
      parsedValue >= minDietLength
    ) {
      return updateDiet({
        ...diet,
        dietLength: parsedValue,
        selectedDays: [...sortedDates],
      });
    }
  };

  const clearSelectedDays = () => {
    setSelectedOptions([]);
    if (!isEmpty(selectedDays)) {
      updateDiet({
        ...diet,
        selectedDays: [],
        selectedAdditionalMealTypes: [],
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '15px',
          justifyContent: 'space-between',
        }}
      >
        <p className={classes.labelBlack}>
          {t('orders.duration', '$$Czas trwania')}*
        </p>
        <div style={{ marginLeft: '15px' }}>
          <Counter
            min={minDietLength}
            max={maxDietLength}
            count={dietLength}
            handleChangeValue={handleDietLengthInputChange}
            handleClickDecrement={handleClickDecrement}
            handleClickIncrement={handleClickIncrement}
          />
        </div>
      </div>
      <div data-cy="new-order_diet-length_saturday-checkbox">
        <FormControlLabel
          control={
            <RegularCheckbox
              name="active"
              checked={saturdayInclude}
              onClick={() => {
                setSaturdayInclude(!saturdayInclude);
                updateDiet({ ...diet, saturdayInclude: !saturdayInclude });
              }}
            />
          }
          label={t('orders.allowSaturdays', 'Uwzględnij soboty')}
        />
      </div>
      <div data-cy="new-order_diet-length_sunday-checkbox">
        <FormControlLabel
          control={
            <RegularCheckbox
              name="active"
              dataCy="new-order_diet-length_saturday-checkbox"
              checked={sundayInclude}
              onClick={() => {
                setSundayInclude(!sundayInclude);
                updateDiet({ ...diet, sundayInclude: !sundayInclude });
              }}
            />
          }
          label={t('orders.allowSundays', 'Uwzględnij niedziele')}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '15px',
          justifyContent: 'space-between',
        }}
      >
        <p
          data-cy="new-order_diet-length_choices-diet-length"
          className={classes.labelBlack}
        >
          {`${t('orders.selectedDays', '$$Wybrane dni')}: ${
            selectedDays?.length
          } / ${dietLength}`}
        </p>
        <p
          data-cy="new-order_diet-length_clear-form"
          className={classes.labelBlack}
          onClick={clearSelectedDays}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {t('orders.clearSelectedDays', '$$Wyczyść')}
        </p>
      </div>
      <div>
        <p
          data-cy="new-order_diet-length_previous-month"
          style={calendarStyles.arrow}
        >
          <KeyboardArrowLeft
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setMonth(month - 1);
              setSecondMonth(secondMonth - 1);
            }}
          />
          <KeyboardArrowRight
            data-cy="new-order_diet-length_next-month"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setMonth(month + 1);
              setSecondMonth(secondMonth + 1);
            }}
          />
        </p>
        <Calendar
          diet={diet}
          selectDietDay={selectDietDay}
          disabledDays={disabledDays}
          saturdayInclude={saturdayInclude}
          sundayInclude={sundayInclude}
          month={month}
          secondMonth={secondMonth}
        />
      </div>
    </>
  );
};

export default withToast(CalendarSection);

import { grayColor } from 'assets/jss/material-dashboard-pro-react';
import Button from 'components/CustomButtons/Button';

import emojiConfig from './emojiConfig';
const EmojiButton = ({ closeEmojiModal, handleChange, prevState }) => {
  return (
    <div
      style={{
        border: `1px solid ${grayColor[4]}`,
        background: 'RGB(255,255,255)',
        padding: '10px 10px',
        borderRadius: '20px',
        position: 'absolute',
        zIndex: '1',
      }}
    >
      <button
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '-10px',
          right: '-5px',
          textAlign: 'center',
          width: '25px',
          height: '25px',
          borderRadius: '50px',
          border: `1px solid ${grayColor[4]}`,
          background: 'RGB(255,255,255)',
          color: 'RGB(185,185,185)',
        }}
        onClick={() => {
          closeEmojiModal(false);
        }}
      >
        X
      </button>
      {emojiConfig.map(emoji => {
        return (
          <Button
            color={'white'}
            name={'keyToIntercom'}
            onClick={e => {
              e.preventDefault();
              closeEmojiModal(false);
              handleChange(prevState.keyToIntercom + emoji.symbol);
            }}
          >
            {emoji.symbol}
          </Button>
        );
      })}
    </div>
  );
};

export default EmojiButton;

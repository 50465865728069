import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import TableBody from '@material-ui/core/TableBody';
import { useTranslation } from 'react-i18next';

const ConfirmNestedDishes = ({ classes, dishes, onUpdateDishes }) => {
  const { t } = useTranslation();
  const updateDish = (id, data) => {
    const newDishes = [...dishes];
    const index = newDishes.findIndex(el => el.id === id);
    newDishes[index] = { ...newDishes[index], ...data };

    onUpdateDishes(newDishes);
  };

  const pickAll = () => {
    const alreadyPickedAll = isPickedAll();
    const newDishes = dishes.map(el => ({ ...el, picked: !alreadyPickedAll }));

    onUpdateDishes(newDishes);
  };

  const isPickedAll = () => dishes.filter(el => !el.picked).length === 0;

  return (
    <Table style={{ minWidth: 700 }}>
      <TableHead style={{ background: '#eaeaea' }}>
        <TableRow>
          <TableCell variant="head" style={{ width: 40 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPickedAll()}
                  onChange={pickAll}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={false}
            />
          </TableCell>
          <TableCell variant="head">{t('comments.dish')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dishes.map(({ picked, id, workingName, nameForClient }) => (
          <TableRow key={`dish_${id}`}>
            <TableCell variant="body">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={picked}
                    onChange={() => updateDish(id, { picked: !picked })}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={false}
              />
            </TableCell>
            <TableCell variant="body">{workingName || nameForClient}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ConfirmNestedDishes;

import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ContainerCell from './CaloriesSumCell';

class CaloriesSumRow extends React.Component {
  render() {
    const { classes, mealTypeSizes, dishSizes, rowType, t } = this.props;

    return (
      <TableRow>
        <TableCell>
          {rowType === 'sum'
            ? t('mealParams.overalCalorific', 'Kaloryczność łącznie')
            : t('mealParams.requiredCalorific', 'Wymagana kaloryczność')}
        </TableCell>
        <TableCell />
        <TableCell />
        {mealTypeSizes.map((mealTypeSize, mealTypeSizeKey) => {
          return (
            <ContainerCell
              rowType={rowType}
              key={mealTypeSizeKey}
              classes={classes}
              size={mealTypeSize.size}
              mealTypeSize={mealTypeSize}
              dishSizes={dishSizes}
            />
          );
        })}
      </TableRow>
    );
  }
}

export default CaloriesSumRow;

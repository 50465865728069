import { CheckCircle, HighlightOff } from '@material-ui/icons';
import React from 'react';

const TrueState = () => <CheckCircle style={{ color: 'green' }} />;
const FalseState = () => <HighlightOff style={{ color: 'red' }} />;
const Boolean = ({ value }) => (
  <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
    {value === true ? <TrueState /> : <FalseState />}
  </div>
);

export default Boolean;

import styled from 'styled-components';

const Preview = styled.div`
  font-family: ${({ fontFamily }) => `'${fontFamily}'`};
  font-size: 18px;
  line-height: normal;
  padding-top: 8px;
`;

export default Preview;

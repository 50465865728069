import React from 'react';
import Button from 'components/CustomButtons/Button';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { VisibilityRounded } from '@material-ui/icons';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import SummaryContent from '../../Summary/SummaryContent';
import { withTranslation } from 'react-i18next';

class PreviewTemplate extends React.Component {
  state = {
    previewModal: false,
    variant: '',
  };

  closePreviewModal = () =>
    this.setState(prevState => ({ ...prevState, previewModal: false }));

  openPreviewModal = () =>
    this.setState(prevState => ({ ...prevState, previewModal: true }));

  handleSelectChange = (event, value) => {
    this.setState(() => {
      return {
        variant: value,
      };
    });
  };

  render() {
    let { variants: givenVariants, diets, classes } = this.props;
    const filteredDiets = this.state.variant
      ? diets.filter(diet => this.state.variant.diets.includes(diet['@id']))
      : [];

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.previewModal}
          onClose={this.openPreviewModal}
          fullWidth={true}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closePreviewModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <FormSelectSingle
              style={{ maxWidth: ' 320px' }}
              classes={classes}
              options={givenVariants}
              mapBy="name"
              trackBy="@id"
              value={this.state.variant}
              handleChange={this.handleSelectChange}
              label={this.props.t(
                'menuPlanner.action.template.preview.selectVariant'
              )}
            />
          </DialogContent>
          <DialogContent>
            <SummaryContent
              date={this.props.date}
              variant={this.state.variant}
              filteredDiets={filteredDiets}
              dates={[this.props.date.format('YYYY-MM-DD')]}
              loading={false}
            />
          </DialogContent>
        </Dialog>
        <Button
          color="primary"
          round
          justIcon
          simple
          onClick={this.openPreviewModal}
        >
          <VisibilityRounded />
        </Button>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => {
  return {
    menuTemplates: state.MenuTemplates.menuTemplates,
    variants: state.Variants.variants,
    diets: state.Diets.diets,
    brand: state.Brands.brand,
  };
};

export default withTranslation()(
  withStyles(combinedStyles)(connect(mapStateToProps, null)(PreviewTemplate))
);

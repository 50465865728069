import React from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { FormTextInput } from 'components';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import CardWrapper from 'components/Card/CardWrapper';

const BestDishes = ({ t, classes, state, setState }) => {
  const toggleEnabled = () => {
    setState(prev => ({ ...prev, enabled: !prev.enabled }));
  };

  const handleTitleChange = ({ target }) => {
    const title = target?.value;

    setState(prev => ({ ...prev, title }));
  };

  const handleContentChange = event => {
    const content = event.target?.value;

    setState(prev => ({ ...prev, content }));
  };

  // const togglePrice = () => {
  //   setState(prev => ({ ...prev, showPrice: !prev.showPrice }));
  // };

  const toggleRating = () => {
    setState(prev => ({ ...prev, showRating: !prev.showRating }));
  };

  return (
    <CardWrapper title={t('cms.homePage.bestDishes.title')}>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleEnabled}
                  checked={state.enabled}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={<div>{t('cms.homePage.bestDishes.show')} </div>}
            />
          </GridItem>
          <GridItem md={6}>
            <FormTextInput
              label={
                <div style={{ display: 'flex' }}>
                  <span>{t('cms.homePage.sectionTitle')}</span>
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                          <h4>
                            {t('brandCfg.etc')}{' '}
                            {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
              classes={classes}
              name="title"
              value={state.title}
              handleChange={handleTitleChange}
              inputSize={12}
              maxLength={1000}
              rows={2}
              rowsMax={10}
              multiline={true}
            />
          </GridItem>
          <GridItem md={12}>
            <div>{t('cms.homePage.sectionContent')}</div>
            <SimpleWysiwyg
              handleChange={handleContentChange}
              value={state.content}
            />
          </GridItem>
          <GridItem md={12}>
            <div style={{ margin: '15px 0 ' }}>
              {t('cms.homePage.infoVisibility')}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleRating}
                  checked={state.showRating}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={<div>{t('cms.homePage.bestDishes.rating')} </div>}
            />
          </GridItem>
          {/* <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleBestDishesPrice}
                  checked={data.showPrice}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={<div>{t('cms.homePage.bestDishes.price')} </div>}
            />
          </GridItem> */}
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(BestDishes);

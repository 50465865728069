import React, { useMemo } from 'react';
import SystemFlagBottomAction from 'components/Core/SystemFlag/SystemFlagBottomAction';
import { FACTORY_NAMES } from 'helpers/systemFlagManager';
import { useTranslation } from 'react-i18next';

const HideMenuForClients = ({ date }) => {
  const { t } = useTranslation();

  return useMemo(
    () => (
      <SystemFlagBottomAction
        label={t('systemFlag.HIDE_MENU_PLANNER')}
        factoryName={FACTORY_NAMES.HIDE_MENU_PLANNER}
        factoryParams={{ date: date }}
        confirmation={{
          enabledMessage: t(
            'menuPlanner.hideMenuForClients.enabledMessage',
            'Wprowadzona zmiana spowoduje wyświetlenie opublikowanego menu w panelu klienta.'
          ),
          disabledMessage: t(
            'menuPlanner.hideMenuForClients.disabledMessage',
            'Wprowadzona zmiana spowoduje, że menu przestanie być widoczne dla klientów w panelu klienta.'
          ),
        }}
      />
    ),
    [date]
  );
};

export default HideMenuForClients;

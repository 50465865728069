import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Goodspeed } from '../modules';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import React from 'react';
import { modulesNames } from '../consts';
import { DialogLoader } from 'components/DialogLoader';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import CardWrapper from 'components/Card/CardWrapper';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';

const DeliveryOptionsView = ({ props }) => {
  const {
    classes,
    t,
    zones,
    state,
    setState,
    modules,
    isLoading,
    handleModuleUpdate,
    handleSubmit,
  } = props;
  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <CardWrapper title={t('brands.newBrandForm.deliveryOptions')}>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.deliveryToDoorEnabled}
                        onChange={() => {
                          setState(prevState => ({
                            ...prevState,
                            deliveryToDoorEnabled: !state.deliveryToDoorEnabled,
                          }));
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brands.newBrandForm.toDoor')}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.pickUpPointEnabled}
                        onChange={() => {
                          setState(prevState => ({
                            ...prevState,
                            pickUpPointEnabled: !state.pickUpPointEnabled,
                          }));
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brands.newBrandForm.atPoint')}
                  />
                </GridItem>
                <GridItem md={3}>
                  <FormTextInput
                    label={t('brandCfg.pickupPointsAddr')}
                    classes={classes}
                    name="pickUpPointInformationLink"
                    value={state.pickUpPointInformationLink}
                    maxLength={255}
                    handleChange={e => {
                      setState(prevState => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                    inputSize={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>

          <CardWrapper
            title={'Goodspeed'}
            tooltip={
              <InfoWithTooltip
                title={t('common.brandConfiguration.goodspeed.tooltip')}
                link={EXTERNAL_URLS.goodspeedIntegration}
              />
            }
          >
            <CardBody>
              <Goodspeed
                zones={zones}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.Goodspeed]}
              />
            </CardBody>
          </CardWrapper>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default DeliveryOptionsView;

import React from 'react';
import Moment from 'moment';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import DateFilter from 'components/Grid/Filter/Date';
import { Tooltip } from '@material-ui/core';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    width: 80,
  },
  {
    title: 'subscription.clientDiet.active',
    accessor: row => <BooleanView value={row.active} />,
    name: 'active',
    width: 100,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: false,
  },

  {
    title: 'subscription.active',
    accessor: row => <BooleanView value={row.subscription.active} />,

    name: 'subscription.active',
    width: 100,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: false,
  },

  {
    title: 'subscription.addons',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.subscription.addons.length > 0} />,
    name: 'addons',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: false,
  },

  {
    title: 'subscription.clientDiet.optionChangeMenu',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.optionChangeMenu} />,
    name: 'optionChangeMenu',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: false,
  },

  {
    title: 'clients.firstDeliveryDate',
    accessor: row => {
      return row.firstDeliveryDate
        ? new Moment(row.firstDeliveryDate).format('YYYY-MM-DD')
        : '-';
    },
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    name: 'firstDeliveryDate',
    sortable: false,
    filterable: false,
  },

  {
    title: 'subscription.price',
    name: 'priceAfterDiscount',
    sortable: false,
    filterable: false,
    accessor: row => (
      <div style={{ textAlign: 'right', paddingRight: 8 }}>
        <Tooltip
          interactive
          title={
            <span style={{ fontSize: '12px' }}>
              {t('subscription.priceBeforeDiscount')}:{' '}
              {row.priceBeforeDiscount.toFixed(2)}
              <br />
              {t('subscription.priceDiscount')}: {row.priceDiscount.toFixed(2)}
              <br />
              {t('subscription.priceAfterDiscount')}:{' '}
              {row.priceAfterDiscount.toFixed(2)}
            </span>
          }
          placement={'right'}
        >
          <span style={{ cursor: 'help' }}>
            {row.priceAfterDiscount.toFixed(2)}
          </span>
        </Tooltip>
      </div>
    ),
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },

  {
    title: 'subscription.clientDiet.calorific',
    accessor: row => row.calorific.name,

    name: 'calorific.name',
  },
  {
    title: 'subscription.clientDiet.diet',
    accessor: row => row.diet.name,
    name: 'diet.name',
  },
  {
    title: 'subscription.clientDiet.variant',
    accessor: row => row.variant.name,
    name: 'variant.name',
  },
];

export default columnConfig;

import React from 'react';

const columnConfig = (t, roles, brands) => {
  return [
    {
      title: 'ID',
      accessor: 'id',
      width: 100,
      filterable: false,
      sortable: false,
    },
    {
      title: 'employees.firstName',
      accessor: 'firstName',
      name: 'firstName',
    },
    {
      title: 'employees.lastName',
      accessor: 'lastName',
      name: 'lastName',
    },
    {
      title: 'employees.role',
      accessor: row => row.permissions.map(role => role.name).join(', '),
      name: 'permissions.id',
      sortable: false,
      Filter: ({ onChange, filter }) => (
        <select
          style={{ height: '30px', width: '100%' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value="">{t('clients.all')}</option>
          {roles.map(item => {
            return <option value={item.id}>{item.name}</option>;
          })}
        </select>
      ),
    },
    {
      title: 'employees.brand',
      accessor: row => row.brands.map(brand => brand.name).join(', '),
      name: 'brands.id',
      sortable: false,
      Filter: ({ onChange, filter }) => (
        <select
          style={{ height: '30px', width: '100%' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value="">{t('common.all')}</option>
          {brands.map(item => {
            return <option value={item.id}>{item.name}</option>;
          })}
        </select>
      ),
    },
    {
      title: 'employees.phone',
      accessor: 'phone.number',
      name: 'phone.number',
      sortable: false,
    },
    {
      title: 'employees.email',
      accessor: 'email',
      name: 'email',
    },
  ];
};

export default columnConfig;

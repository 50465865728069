import React from 'react';
import Moment from 'moment';

import { ModalLogBlock } from 'components/History/LogView';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/Grid/Filter/DateRange';

const translateActionTypes = t => ({
  INSERT: t('actionTypes.insert', 'UTWORZENIE'),
  UPDATE: t('actionTypes.update', 'AKTUALIZACJA'),
  REMOVE: t('actionTypes.remove', 'USUNIĘCIE'),
  ASSOCIATE: t('actionTypes.associate', 'POWIĄZANIE'),
  DISSOCIATE: t('actionTypes.dissociate', 'ODWIĄZANIE'),
});

export const ActionTypeCell = ({ value }) => {
  const { t } = useTranslation();
  const actionTypes = translateActionTypes(t);

  return <>{actionTypes[value] || value}</>;
};

const columnConfig = () => {
  return [
    {
      sortable: true,
      filterable: false,
      title: 'ID',
      accessor: 'id',
      width: 100,
    },
    {
      title: 'clients.date',
      Filter: ({ onChange, filter }) => {
        return <DateRangeFilter onChange={onChange} value={filter?.value} />;
      },
      accessor: row => {
        return row.loggedAt
          ? new Moment(row.loggedAt).format('YYYY-MM-DD HH:mm:ss')
          : '-';
      },
      name: 'loggedAt',
      sortable: true,
      filterable: true,
      width: 155,
    },
    {
      sortable: false,
      filterable: false,
      Cell: ActionTypeCell,
      title: 'clients.actType',
      accessor: 'actionType',
      name: 'actionType',
      width: 125,
    },
    {
      sortable: false,
      filterable: false,
      title: 'clients.changedObject',
      accessor: 'objectName',
      name: 'objectName',
    },
    {
      title: 'clients.objectId',
      accessor: 'objectId',
      name: 'objectId',
    },
    {
      sortable: false,
      filterable: false,
      title: 'common.details',
      accessor: row => <ModalLogBlock row={row} />,
      name: 'activityDetails',
      width: 160,
    },
  ];
};

export default columnConfig;

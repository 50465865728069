import React from 'react';

import { Edit, DeleteForever } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button';

const Actions = ({ handleEdit, handleRemove }) => {
  return (
    //button actions
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      {handleEdit ? (
        <Button
          justIcon
          round
          simple
          onClick={handleEdit}
          color="warning"
          className="edit"
        >
          <Edit />
        </Button>
      ) : null}
      {/* use this button to remove the data row */}
      {handleRemove ? (
        <Button
          justIcon
          round
          simple
          onClick={handleRemove}
          color="danger"
          className="remove"
        >
          <DeleteForever />
        </Button>
      ) : null}
    </div>
  );
};

export default Actions;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/CustomSelect/Select';

import AddonBox from './AddonBox';
import ADDONS_TYPES from '../../consts/addonsTypes';

const AddonsSection = ({
  diet = {},
  availableAddons = [],
  updateDiet = () => {},
}) => {
  const { t } = useTranslation();

  const { selectedAddons } = diet ?? {};
  const [selectedOptions, setSelectedOptions] = useState([]);

  const selectAddon = newAddons => {
    if (newAddons?.length > selectedAddons?.length) {
      const newAddonsToSelect = newAddons.filter(
        addon =>
          !selectedAddons.find(
            selectedAddon => selectedAddon.value === addon.value
          )
      );

      setSelectedOptions(newAddons);
      return updateDiet({
        ...diet,
        selectedAddons: [
          ...selectedAddons,
          ...newAddonsToSelect.map(newAddonToSelect => ({
            ...newAddonToSelect,
            amount: 1,
            type: ADDONS_TYPES.FOR_DIET,
          })),
        ],
      });
    }

    if (newAddons?.length < selectedAddons?.length) {
      setSelectedOptions(newAddons);
      return updateDiet({
        ...diet,
        selectedAddons: selectedAddons.filter(selectedAddon =>
          newAddons.find(addon => addon?.value === selectedAddon.value)
        ),
      });
    }
  };

  const onRemoveClick = id => {
    setSelectedOptions(selectedOptions.filter(option => option?.value !== id));
    return updateDiet({
      ...diet,
      selectedAddons: selectedAddons.filter(
        selectedAddon => selectedAddon?.value !== id
      ),
    });
  };

  const onAddonAmountChange = (id, newAmount) => {
    return updateDiet({
      ...diet,
      selectedAddons: selectedAddons.map(selectedAddon => {
        if (selectedAddon.value === id) {
          return { ...selectedAddon, amount: newAmount?.value };
        }

        return selectedAddon;
      }),
    });
  };

  const onAddonDeliveryTypeChange = (id, newType) => {
    return updateDiet({
      ...diet,
      selectedAddons: selectedAddons.map(selectedAddon => {
        if (selectedAddon.value === id) {
          return { ...selectedAddon, type: newType?.value };
        }

        return selectedAddon;
      }),
    });
  };

  return (
    <div style={{ marginTop: '15px' }} data-cy="new-order_addons-select">
      <Select
        label={t('orders.select', '$$Wybierz')}
        value={selectedOptions}
        multiple
        options={availableAddons}
        handleChange={selectAddon}
      />
      {selectedAddons?.map(selectedAddon => (
        <div style={{ margin: '15px 0' }}>
          <AddonBox
            {...selectedAddon}
            onRemoveClick={() => onRemoveClick(selectedAddon?.value)}
            onAddonAmountChange={newAmount =>
              onAddonAmountChange(selectedAddon.value, newAmount)
            }
            onAddonDeliveryTypeChange={newType =>
              onAddonDeliveryTypeChange(selectedAddon.value, newType)
            }
          />
        </div>
      ))}
    </div>
  );
};

export default AddonsSection;

const add = (a, b) => a + b;

export const proteinConstant = 4;
export const carbohydratesConstant = 4;
export const fatConstant = 9;
export const roughageConstant = 2;

export const getMacroInPercent = (
  type,
  elementConstant,
  calorific,
  menuPlanners
) => {
  const macros = {
    calories: getTotalMenuPlanners('calories', calorific, menuPlanners),
    fat: getTotalMenuPlanners('fat', calorific, menuPlanners),
    protein: getTotalMenuPlanners('protein', calorific, menuPlanners),
    carbohydrates: getTotalMenuPlanners(
      'carbohydrates',
      calorific,
      menuPlanners
    ),
     };



  const resultMacros =
    macros.fat * fatConstant +
    macros.protein * proteinConstant +
    macros.carbohydrates * carbohydratesConstant;

  const resultMacrosCalories = macros.calories
    ? resultMacros / macros.calories
    : 0;

  const resultPercent =
    resultMacros === 0 || resultMacrosCalories === 0
      ? 0
      : (
          ((macros[type] * elementConstant) / resultMacros) *
          100 *
          resultMacrosCalories
        ).toFixed(2);

  return resultPercent;
};

export const getTotalMenuPlanners = (property, calorific, menuPlanners) => {
  return menuPlanners
    .map(row => {
      const mealTypeId = row.mealType.split('/').pop();
      const key = calorific + '_' + mealTypeId;
      if (
        typeof row.sizes === 'undefined' ||
        row.sizes === null ||
        typeof row.sizes[key] === 'undefined' ||
        row.sizes[key] === null
      ) {
        return 0;
      }
      if (property === 'price') {
        return row.sizes[key][property];
      } else {
        return row.sizes[key]['macrosAfterProcessing'][property];
      }
    })

    .reduce(add, 0)
    .toFixed(2);
};

const utils = {
  getTotalMenuPlanners,
  getMacroInPercent,
  constant: {
    protein: proteinConstant,
    carbohydrates: carbohydratesConstant,
    fat: fatConstant,
    roughage: roughageConstant,
  },
};

export default utils;

import { currencyToSymbol } from 'utils/currencies';

const columnConfig = (t, currency) => {
  return [
    {
      title: 'ID',
      accessor: 'id',
      name: 'id',
      width: 50,
    },
    {
      title: 'form.field.name',
      name: 'name',
      accessor: 'name',
    },
    {
      title: 'form.field.ingredient',
      name: 'ingredient.name',
      accessor: 'ingredient.name',
    },
    {
      title: 'form.field.stock',
      name: 'stock',
      accessor: 'stock',
      filterable: true,
    },
    {
      title: t('form.field.lastPriceGross', {
        defaultValue: 'Ostatnia cena brutto [{{value}}/kg]',
        value: currency,
      }),
      name: 'lastPriceGross',
      accessor: (row, plug) =>
        !row.lastPriceGross
          ? plug.t('form.field.noPrice', 'Brak ceny')
          : `${row.lastPriceGross.toFixed(2)} ${currencyToSymbol(
              plug.multinational.currency
            )}`,
      filterable: true,
    },
  ];
};

export default columnConfig;

import React from 'react';
import AdminTable from 'layouts/AdminTable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import LogView from 'components/History/LogView';
import CheckSizes from '../CheckSizes';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';

const FormView = ({
  t,
  classes,
  state,
  setState,
  typeId,
  isEdit,
  handleInputChange,
  handleToggle,
  handleCalories,
  handleSubmit,
}) => {
  return (
    <>
      <AdminTable
        title={isEdit ? t('form.editMealType') : t('form.addNewMealType')}
      >
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={t('form.field.typeName') + '*'}
              classes={classes}
              name="name"
              value={state.name}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
          <GridItem sm={3}>
            <FormTextInput
              label={t('form.field.shortName') + '*'}
              classes={classes}
              name="shortName"
              value={state.shortName}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
          <GridItem sm={2} style={{ marginTop: '8px' }}>
            <FormTextInput
              label={t('form.field.order') + '*'}
              classes={classes}
              name="position"
              value={state.position}
              type="number"
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={t('form.field.code') + '*'}
              classes={classes}
              name="code"
              value={state.code}
              handleChange={handleInputChange}
              errors={state.errors}
            />
          </GridItem>
          <GridItem sm={4}>
            <FormSelectSingle
              classes={classes}
              label={t('common.mainMenu.mealCategory', 'Kategoria posiłków')}
              options={state.mealCategoryOptions}
              value={state.mealCategory}
              mapBy={'value'}
              handleChange={({ target: { value } }) =>
                setState(prevState => ({
                  ...prevState,
                  mealCategory:
                    (value?.['@id'] ?? value) ===
                    (prevState.mealCategory?.['@id'] ?? prevState.mealCategory)
                      ? null
                      : value,
                }))
              }
              name={'mealCategories'}
              id={'mealCategories'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={4}>
            <FormSelectSingle
              classes={classes}
              label={t(
                'common.mainMenu.mealType.googleFit',
                'Typ posiłków Google Fit'
              )}
              options={[
                {
                  value: t(
                    'common.mainMenu.mealType.googleFit.notset',
                    'Niewybrany'
                  ),
                  key: 'NOTSET',
                },
                {
                  value: t(
                    'common.mainMenu.mealType.googleFit.unknown',
                    'Nieznany'
                  ),
                  key: 'UNKNOWN',
                },
                {
                  value: t(
                    'common.mainMenu.mealType.googleFit.breakfast',
                    'Śniadanie'
                  ),
                  key: 'BREAKFAST',
                },
                {
                  value: t('common.mainMenu.mealType.googleFit.Lunch', 'Obiad'),
                  key: 'LUNCH',
                },
                {
                  value: t(
                    'common.mainMenu.mealType.googleFit.snack',
                    'Przekąska'
                  ),
                  key: 'SNACK',
                },
                {
                  value: t(
                    'common.mainMenu.mealType.googleFit.Dinner',
                    'Kolacja'
                  ),
                  key: 'DINNER',
                },
              ]}
              value={state?.mealTypesGoogleFit}
              mapBy="value"
              trackBy="key"
              handleChange={(e, obj) =>
                setState(prevState => ({
                  ...prevState,
                  mealTypesGoogleFit: obj,
                }))
              }
              name={'mealTypesGoogleFit'}
              id={'mealTypesGoogleFit'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
          </GridItem>
        </GridContainer>
        <CheckSizes
          classes={classes}
          handleToggle={handleToggle}
          handleInputChange={handleInputChange}
          handleCalories={handleCalories}
          sizes={state.sizes}
        />
      </AdminTable>
      {!isEdit && (
        <FormControlStickyButton
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/types"
          handleSubmit={handleSubmit}
        />
      )}
      {isEdit && (
        <LogView
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/types"
          handleSubmit={handleSubmit}
          iri={`/meal-types/${typeId}`}
        />
      )}
    </>
  );
};

export default FormView;

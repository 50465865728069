import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchMessage = id => {
  return dispatch => {
    return get(`/notifications/${id}`).then(response => {
      dispatch({
        type: types.FETCH_DRIVER,
        payload: {
          name: response.name,
          zones: response.zones,
        },
      });
      return response;
    });
  };
};

import React from 'react';
import Button from '@material-ui/core/Button';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { openSweetToast } from '../../actions/App';
import { connect } from 'react-redux';

class Filters extends React.Component {
  showFilter = async () => {
    this.props.openSweetToast({
      style: { display: 'block' },
      content: (
        <div style={{ textAlign: 'left' }}>
          <pre>{JSON.stringify(this.props.filters, null, 2)}</pre>
        </div>
      ),
      showCancel: false,
    });
  };

  render() {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}
      >
        <Button onClick={this.showFilter}>
          {this.props.t('$*common.show')}
        </Button>
      </div>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

Filters.propTypes = {};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  openSweetToast,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(combinedStyles)(Filters))
);

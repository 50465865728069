import { get, remove } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchRoles = () => {
  return dispatch => {
    return get('/roles', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_ROLES,
        payload: {
          roles: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchRole = id => {
  return dispatch => {
    return get(`/roles/${id}`).then(response => {
      dispatch({
        type: types.FETCH_ROLE,
        payload: {
          name: response.name,
          shortName: response.shortName,
          permissions: response.permissions,
        },
      });
      return response;
    });
  };
};

export const removeRole = id => {
  return dispatch =>
    remove(`/roles/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_ROLE,
        payload: {
          roleId: id,
        },
      });
    });
};

import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip, FormControlLabel, Grid } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import { withToast } from 'material-ui-toast-redux';

import { post } from 'helpers/apiHelpers';

import Button from 'components/CustomButtons/Button';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import TOAST_DURATIONS from 'helpers/toastDurations';

const ThuliumModule = ({ classes, handleModuleUpdate, module, openToast }) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);

  const handleClientsSync = async () => {
    setIsSyncing(true);
    try {
      await post('/client-sync');
      openToast({
        messages: [
          t(
            'brands.newBrandForm.thulium.syncContacts.successMessage',
            'Żądanie zostało wysłane. Synchronizacja klientów to proces który może chwilę potrwać'
          ),
        ],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      setIsSyncing(false);
    } catch (e) {
      setIsSyncing(false);
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'Thulium',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e =>
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={'Thulium'}
          />
          <FormTextInput
            label={t('brands.newBrandForm.thulium.url', 'API Url')}
            classes={classes}
            name="url"
            value={module.url}
            maxLength={100}
            handleChange={e =>
              handleModuleUpdate({ ...module, url: e.target.value })
            }
            inputSize={12}
          />
          <FormTextInput
            label={t('brands.newBrandForm.thulium.login', 'API Login')}
            classes={classes}
            name="login"
            value={module.login}
            maxLength={30}
            handleChange={e =>
              handleModuleUpdate({ ...module, login: e.target.value })
            }
            inputSize={12}
          />
          <FormTextInput
            label={t('brands.newBrandForm.thulium.password', 'API Password')}
            classes={classes}
            name="password"
            value={module.password}
            maxLength={30}
            handleChange={e =>
              handleModuleUpdate({ ...module, password: e.target.value })
            }
            inputSize={12}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button disabled={isSyncing} onClick={handleClientsSync}>
              {t('brands.newBrandForm.thulium.syncContacts', 'Synchronizuj')}
            </Button>
            <Tooltip
              title={
                <h4>
                  {t(
                    'brands.newBrandForm.thulium.syncContacts.tooltip',
                    '$$Synchronizacja klientów może chwilę zająć'
                  )}
                </h4>
              }
              placement="top"
            >
              <Info fontSize={'small'} style={{ color: 'gray' }} />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withToast(ThuliumModule);

import { get, remove } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchDishes = ({
  pageSize = 30,
  page = 0,
  sorted = [],
  filtered = [],
  ...rest
}) => {
  const filters = filtered.reduce((curr, next) => {
    curr[next.id] = next.value;
    return curr;
  }, {});

  return dispatch =>
    get('/dishes', {
      itemsPerPage: pageSize,
      page: page + 1,
      ...filters,
      id: filters.id,
      nameForClient: filters.name,
      ingredientName: filters.ingredients,
      tags: filters.tags,
      partial: false,
      ...rest,
    }).then(response => {
      dispatch({
        type: types.FETCH_DISHES,
        payload: {
          dishes: response['hydra:member'],
          pages: Math.ceil(response['hydra:totalItems'] / pageSize),
        },
      });

      return response['hydra:member'];
    });
};

export const removeDish = id => {
  return dispatch =>
    remove(`/dishes/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_DISH,
        payload: {
          removedItemId: id,
        },
      });
    });
};

export const fetchDish = id => {
  return dispatch => {
    return get(`/dishes/${id}`).then(response => {
      dispatch({
        type: types.FETCH_DISH,
        payload: {
          dish: response,
        },
      });
      return response;
    });
  };
};

import React from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_DIET } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  info: {
    position: 'relative',
    left: '10px',
    fontSize: '10px',
    transform: 'scale(2)',
  },
});

const List = ({ history, classes: propsClasses, t }) => {
  const newSize = () => history.push(`${window.location.pathname}/add`);

  const classes = useStyles();

  return (
    <AdminTable title={t('list.dietList')}>
      {isGranted(ROLE_CREATE_DIET) && (
        <FormControlButtons
          classes={propsClasses}
          handleSubmit={newSize}
          submitText={t('form.addDiet') + ' +'}
        />
      )}
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="diets"
        reportName={'diet'}
        columns={columnConfig(t)}
        role="DIET"
        actionsHeaderAdditionalContent={
          <InfoWithTooltip
            className={classes.info}
            link={EXTERNAL_URLS.deactivateDiet}
            title={t('diets.deactivateInfo')}
            size="s"
          />
        }
      />
    </AdminTable>
  );
};

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);

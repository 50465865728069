import Moment from 'moment';
import DateFilter from 'components/Grid/Filter/Date';
import Filters from 'components/Archive/Filters';
import { isGranted } from 'helpers/helpers';
import { ROLE_SHOW_REPORT_ARCHIVE } from 'helpers/roles';
import DownloadButton from 'components/Archive/DownloadButton';
import React from 'react';
import reportNames from './reportNames';
import Select from 'components/Grid/Filter/Select';

const columnConfig = ({ user, t }) => {
  return [
    {
      title: 'reportArchive.userRaw',
      name: 'userRaw',
      accessor: 'userRaw',
      filterable: true,
      sortable: false,
    },
    {
      title: 'reportArchive.type',
      name: 'type',
      accessor: row => t('reportArchive.type.' + row.type),
      filterable: true,
      Filter: ({ onChange }) => {
        const options = [
          { '@id': 'EXPORT', name: t('reportArchive.type.EXPORT') },
          { '@id': 'REPORT', name: t('reportArchive.type.REPORT') },
          { '@id': 'CUSTOM', name: t('reportArchive.type.CUSTOM') },
        ];

        return <Select options={options} onChange={onChange} />;
      },
      sortable: false,
      width: 100,
    },
    {
      title: 'reportArchive.reportName',
      name: 'reportName',
      accessor: row => {
        const key = reportNames?.[row.type]?.[row.reportName];

        if (!key) {
          return row.reportName;
        }

        return `${t('reportArchive.type.' + row.type)} - ${t(key)}`;
      },
      filterable: true,
      Filter: ({ onChange }) => {
        const options = [];

        Object.keys(reportNames).forEach(type => {
          Object.keys(reportNames[type]).forEach(el => {
            options.push({
              '@id': el,
              name: `${t('reportArchive.type.' + type)} - ${t(
                reportNames[type][el]
              )}`,
            });
          });
        });
        options.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );

        return <Select options={options} onChange={onChange} />;
      },
      sortable: false,
    },
    {
      title: 'reportArchive.generatedAt',
      name: 'generatedAt',
      accessor: row => Moment(row.generatedAt).format('llll'),
      filterable: true,
      sortable: false,
      Filter: ({ onChange, filter }) => {
        return <DateFilter onChange={onChange} value={filter?.value} />;
      },
    },
    {
      title: 'reportArchive.filters',
      name: 'filters',
      accessor: row => <Filters filters={row.filters} />,
      filterable: false,
      sortable: false,
      width: 70,
    },
    {
      title: 'reportArchive.download',
      name: 'download',
      accessor: row => (
        <DownloadButton
          row={row}
          accessGranted={
            row?.user?.id === parseInt(user.id.split('/').pop()) ||
            isGranted(ROLE_SHOW_REPORT_ARCHIVE)
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 70,
    },
  ];
};

export default columnConfig;

import types from 'helpers/constants';

const initialState = {
  recipes: [],
  loading: true,
  recipe: {},
};

function Recipes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_RECIPES:
      return {
        ...state,
        recipes: action.payload.recipes,
        pages: action.payload.pages,
        loading: false,
      };
    case types.FETCH_RECIPE:
      return {
        ...state,
        recipe: action.payload.ingredient,
      };
    case types.REMOVE_RECIPE:
      return {
        ...state,
        recipes: state.recipes.filter(
          recipe => recipe.id !== action.payload.removedItemId
        ),
      };
    case types.LOADING_RECIPES:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default Recipes;

import React from 'react';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { Close, Info } from '@material-ui/icons';
import { FormTextInput } from 'components';
import FormIconUpload from 'components/FormImageUpload/FormIconUpload';
import { Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const LinkManagment = ({
  classes,
  t,
  handleInputChange,
  updateModule,
  handleDeleteLink,
  section,
  index,
  item,
  removeIcon,
  onClickUp,
  onClickDown,
  disabledButton,
}) => {
  return (
    <GridContainer>
      <GridItem md={3}>
        <FormTextInput
          value={item.link}
          name={`footerLink[${section}][${index}].link`}
          classes={classes}
          handleChange={handleInputChange}
          placeholder={t(
            'brandCfg.clientPanelDesign.linkMenagment.link.placeholder',
            'Wklej link do strony WWW'
          )}
        />
      </GridItem>
      <GridItem md={3}>
        <FormTextInput
          value={item.name}
          name={`footerLink[${section}][${index}].name`}
          classes={classes}
          handleChange={handleInputChange}
          placeholder={t(
            'brandCfg.clientPanelDesign.linkMenagment.name.placeholder',
            'Wpisz nazwę'
          )}
        />
      </GridItem>
      <div>
        <FormIconUpload
          margin={false}
          padding={false}
          btnOnlyIcon={true}
          classes={classes}
          stateName="image"
          getImage={updateModule}
          section={section}
          index={index}
          removeImage={() => {}}
          removeIcon={removeIcon}
          previewUrl={item.icon}
        />
      </div>
      <GridItem md={1} style={{ marginTop: '10px' }}>
        <Button
          style={{ padding: '5px 15px' }}
          color={'danger'}
          onClick={index => {
            handleDeleteLink(index);
          }}
        >
          <Close />
          {t(
            'brandCfg.clientPanelDesign.footer.upSection.deleteLink',
            'Usuń link'
          )}
        </Button>
      </GridItem>
      <GridItem md={2} style={{ marginLeft: '50px', marginTop: '10px' }}>
        <Button
          disabled={disabledButton}
          style={{ padding: '5px 15px' }}
          onClick={() => onClickUp(index, section)}
        >
          <ArrowUpward />
        </Button>
        <Button
          disabled={disabledButton}
          style={{ padding: '5px 15px' }}
          onClick={() => onClickDown(index, section)}
        >
          <ArrowDownward />
        </Button>
        <Tooltip
          title={
            <h4>
              {t(
                'brandCfg.clientPanelDesign.footer.informationTooltip',
                'Podczas edycji elementów miany kolejności zostają zablokowane. Aby odblokować możliwość zmiany kolejności należy zapisać zmiany.'
              )}
            </h4>
          }
          placement="top"
        >
          <Info fontSize={'small'} />
        </Tooltip>
      </GridItem>
    </GridContainer>
  );
};

export default LinkManagment;

import { get, put, remove } from './apiHelpers';

export const FACTORY_NAMES = {
  HIDE_MENU_PLANNER: 'hideMenuPlanner',
};

const urlFactory = {
  hideMenuPlanner: ({ date }) => `/core/system-flag/hide-menu-planner/${date}`,
};

export const isFired = (factoryName, params) =>
  get(urlFactory[factoryName](params));

export const enable = (factoryName, params) =>
  put(urlFactory[factoryName](params), {});

export const disable = (factoryName, params) =>
  remove(urlFactory[factoryName](params));

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import classNames from 'classnames';

const RegularCheckbox = ({
  classes,
  onClick = () => {},
  checked = false,
  name,
  dataCy,
  disabled = false,
  customClasses,
}) => {
  return (
    <Checkbox
      tabIndex={-1}
      name={name}
      data-cy={dataCy}
      onClick={onClick}
      checked={checked}
      disabled={disabled}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{
        checked: classes.checked,
        root: classNames(classes.checkRoot, customClasses?.root),
      }}
    />
  );
};

export default withStyles(extendedFormsStyle)(RegularCheckbox);

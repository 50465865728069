import types from 'helpers/constants';

const initialState = {
  mealSizes: [],
  mealSize: {},
  loading: true,
};

function MealSizes(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MEALSIZES:
      return {
        ...state,
        mealSizes: action.payload.mealSizes,
        pages: action.payload.pages,
        loading: false,
      };
    case types.CLEAR_MEALSIZES:
      return {
        ...state,
        mealSizes: [],
      };
    case types.FETCH_MEALSIZE:
      return {
        ...state,
        loading: false,
        mealSize: action.payload,
      };
    case types.REMOVE_MEALSIZE:
      return {
        ...state,
        mealSizes: state.mealSizes.filter(
          mealSize => mealSize.id !== action.payload.mealSizeId
        ),
      };
    default:
      return state;
  }
}

export default MealSizes;

import React, { Component } from 'react';
import { orderStyles } from './styles';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_NOTE } from 'helpers/roles';
import { put } from 'helpers/apiHelpers';

import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import TOAST_DURATIONS from 'helpers/toastDurations';

class NotesEditModal extends Component {
  state = {
    data: {},
    hasChanged: false,
  };

  componentDidMount() {
    this.setState({ data: this.props.editModalData });
  }

  addNote = e => {
    const { t } = this.props;
    if (!this.state.data.content) {
      return this.props.openToast({
        messages: [t('errors.contentRequired', 'Treść nie może być pusta')],
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    }

    e.preventDefault();
    put(this.state.data['@id'], {
      key: this.state.data.key,
      content: this.state.data.content,
    }).then(newMessage => {
      this.setState({ data: {} });
      this.closeModal();
      this.props.openToast({
        messages: [t('success.noteUpdate', 'Notatka została zaktualizowana')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    });
  };

  closeModal = () => {
    this.props.setEditModalStatus(false);
    this.props.getNotesAndCloseModal();
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        fullWidth={false}
        open={this.props.editModalStatus}
        onClose={() => this.closeModal()}
      >
        <DialogContent>
          <ModalButton
            style={orderStyles.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={() => this.closeModal()}
          >
            <Close />
          </ModalButton>
          <GridContainer>
            {isGranted(ROLE_CREATE_NOTE) && (
              <GridItem md={12}>
                <div style={{ marginLeft: '5px', ...orderStyles.box }}>
                  <b>{t('notes.editNote', 'Edycja notatki')}</b>
                  <FormTextInput
                    multiline
                    label={t('notes.noteContent', 'Treść notatki')}
                    classes={classes}
                    name="phone"
                    value={this.state.data.content}
                    handleChange={ev => {
                      const newValue = ev.target.value;
                      this.setState(prevState => ({
                        ...prevState,
                        hasChanged: true,
                        data: {
                          ...prevState.data,
                          content: newValue,
                        },
                      }));
                    }}
                    inputSize={12}
                    maxLength={400}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalButton
                      color={'primary'}
                      disabled={!this.state.hasChanged}
                      onClick={ev => this.addNote(ev)}
                    >
                      {!this.state.hasChanged
                        ? t('cmmon.noChangesApplied', 'Nie dokonano zmian')
                        : t('common.saveChanges', 'Zapisz zmiany')}
                    </ModalButton>
                  </div>
                </div>
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

export default NotesEditModal;

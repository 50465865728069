import styled from 'styled-components';

const Label = styled.div`
  color: #ffffff;
  width: ${props => props?.width ?? '120px'};
  height: ${props => props.height ?? '36px'};
  margin: ${props => props.margin ?? '0'};
  padding: 5px;
  display: flex;
  font-size: 14px;
  min-height: 36px;
  text-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.isMatched
      ? '#3FA64D'
      : '#828282'};
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: ${props => props.size};
  height: ${props => props.size};

  & .circle {
    stroke: black;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export { Label, StyledSpinner };

import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchKitchens = () => {
  return dispatch =>
    get('/kitchens', { pagination: false }).then(kitchens => {
      dispatch({
        type: types.FETCH_KITCHENS,
        payload: {
          kitchens: kitchens['hydra:member'],
        },
      });
    });
};

import { dateTimeCell } from '../../components/Grid/Cells/DateTimeCell';
import React from 'react';
import DateFilter from '../../components/Grid/Filter/Date';
import BooleanView from '../../components/DataGrid/ValueViews/BooleanView';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const columnConfig = t => [
  {
    title: 'addressRequest.requestedAt',
    accessor: row => dateTimeCell(row.requestedAt, true),
    name: 'requestedAt',
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
  },
  {
    title: 'addressRequest.address.postCode',
    accessor: 'address.postCode',
    name: 'address.postCode',
  },
  {
    title: 'addressRequest.address.city',
    accessor: 'address.city',
    name: 'address.city',
  },
  {
    title: 'addressRequest.address.street',
    accessor: 'address.street',
    name: 'address.street',
  },
  {
    title: 'addressRequest.address.building',
    accessor: 'address.building',
    name: 'address.building',
  },
  {
    title: 'addressRequest.email',
    accessor: 'email',
    name: 'email',
  },
  {
    Header: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {t('addressRequest.isNotified', 'Powiadomiono')}
        <Tooltip
          title={
            <h4>
              {t(
                'addressRequest.isNotifiedTooltip',
                'Jeżeli podany adres zaczął być obsługiwany to wysłane zostanie powiadomienie mailowe.'
              )}
            </h4>
          }
          placement="top"
        >
          <Info style={{ fontSize: '14px', marginLeft: '4px' }} />
        </Tooltip>
      </div>
    ),
    accessor: row => <BooleanView value={row.notifiedAt !== null} />,
    name: 'notifiedAt',
    width: 75,
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;
